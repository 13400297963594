import React from 'react';

const Decks = () => {
  return (
    <>
      Decks
    </>
  )
}

export default Decks;
