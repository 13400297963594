import React from 'react';
import { NavLink } from 'react-router-dom';
import useStores from '../../hooks/useStores';
import {
  AppRoute,
  ColorFamiliesRoutes,
  CommonLanguageRoutes,
  DPHRoutes,
  DisclaimerRoutes,
  GSTAccessoriesDashboardRoute,
  GSTAccessoriesOtherRoutes,
  RestOfRoutes,
  SeriesCategoriesRoutes,
  SpanishDisclaimersRoutes,
  VehicleDataRoutes,
} from '../../models/routes.model';
import { BRAND_TOYOTA, Language, REGION_GST } from '../../models/user.model';
import { hideRouteByBrand, testVDActive } from './SideMenu';
import styles from './sideMenu.module.scss';

const isSmallText = (path: string) => {
  switch (path) {
    case '/decks':
    case '/settings':
    case '/help': {
      return styles.smallText;
    }
  }
  return undefined;
};

type RouteBuilderProps = {
  brand?: string;
  routes: AppRoute[];
  canViewDraft?: boolean;
  accessible?: boolean;
  redirect?: boolean;
  canViewArchive?: boolean;
};

const DefaultRouteBuilder = ({
  brand,
  routes,
  canViewDraft,
  redirect = false,
  accessible = false,
  canViewArchive,
}: RouteBuilderProps) =>
  !accessible ? (
    <></>
  ) : (
    <>
      {routes.map((route, index) => {
        const hideRouteLink = route.hide || hideRouteByBrand(route.path, brand);
        if (hideRouteLink) {
          return null;
        }
        return (
          <li key={index} className={isSmallText(route.path)}>
            {!redirect ? (
              <NavLink exact to={route.path}>
                {route.text}
              </NavLink>
            ) : (
              <a href={`${route.path}`} rel="noopener noreferrer">
                {route.text}
              </a>
            )}
            {route.childRoutes && (
              <ul>
                {route.childRoutes.map(
                  (childRoute, childIndex) =>
                    !childRoute.hide &&
                    (childRoute.text !== 'Draft' || canViewDraft) &&
                    (childRoute.text !== 'Archive' || canViewArchive) &&
                    (!redirect ? (
                      <li key={childIndex}>
                        <NavLink exact to={childRoute.path}>
                          {childRoute.text}
                        </NavLink>
                      </li>
                    ) : (
                      <li key={childIndex}>
                        <a href={`${childRoute.path}`} rel="noopener noreferrer">
                          {childRoute.text}
                        </a>
                      </li>
                    ))
                )}
              </ul>
            )}
          </li>
        );
      })}
    </>
  );

interface VehicleDataRouteBuilderProps extends RouteBuilderProps {
  teamParam: string;
  text: string;
  redirect?: boolean;
}

const VehicleDataRouteBuilder = ({
  routes,
  teamParam,
  text,
  accessible = false,
}: VehicleDataRouteBuilderProps) =>
  !accessible ? (
    <></>
  ) : (
    <>
      {routes.map(
        (route, index) =>
          !route.hide && (
            <li key={index} className={isSmallText(route.path)}>
              <NavLink
                to={route.path.replace(':team', teamParam)}
                isActive={(match, location) => testVDActive(location.pathname, teamParam)}
              >
                {text}
              </NavLink>
            </li>
          )
      )}
    </>
  );

const ExpandedView = () => {
  const {
    userStore: { modules, brand, langPermissions, region },
  } = useStores();

  return (
    <section data-testid="expanded-view-comp">
      <h1 className={styles.title}>VAPI</h1>
      <section className={styles.listContainer}>
        <ul>
          {/* Route back to accessories dasboard if gst user  */}
          <DefaultRouteBuilder
            accessible={region === REGION_GST}
            routes={GSTAccessoriesDashboardRoute}
            canViewDraft={modules.Accessories.canView}
            canViewArchive
            redirect
          />

          <DefaultRouteBuilder
            accessible={modules.CommonLanguage.canView}
            routes={CommonLanguageRoutes}
            canViewDraft={modules.CommonLanguage.canViewDraft}
            canViewArchive
          />
          <VehicleDataRouteBuilder
            accessible={modules.ProductTeam.canView}
            routes={VehicleDataRoutes}
            teamParam="product-team"
            text="Product Team"
          />
          <VehicleDataRouteBuilder
            accessible={modules.AgencyTeam.canView}
            routes={VehicleDataRoutes}
            teamParam="agency-team"
            text="Agency"
          />
          <VehicleDataRouteBuilder
            accessible={brand === BRAND_TOYOTA && langPermissions[Language.ES]?.canView}
            routes={VehicleDataRoutes}
            teamParam="agency-spanish"
            text="Spanish"
          />
          <DefaultRouteBuilder
            accessible={brand === BRAND_TOYOTA && langPermissions[Language.ES]?.canView}
            routes={SpanishDisclaimersRoutes}
          />
          <DefaultRouteBuilder
            accessible={modules.Disclaimers.canView && langPermissions[Language.EN]?.canEdit}
            routes={DisclaimerRoutes}
            canViewDraft={modules.Disclaimers.canViewDraft}
            canViewArchive={region !== REGION_GST}
          />

          <DefaultRouteBuilder
            accessible={modules.AgencyTeam.canView}
            routes={SeriesCategoriesRoutes}
          />
          <DefaultRouteBuilder
            accessible={modules.AgencyTeam.canView}
            routes={ColorFamiliesRoutes}
          />
          <DefaultRouteBuilder accessible={modules.Dph?.canView} routes={DPHRoutes} />
          <DefaultRouteBuilder
            accessible={region !== REGION_GST}
            brand={brand}
            routes={RestOfRoutes}
          />
          <DefaultRouteBuilder
            accessible={region === REGION_GST}
            routes={GSTAccessoriesOtherRoutes}
            redirect
          />
        </ul>
      </section>
    </section>
  );
};

export default ExpandedView;
