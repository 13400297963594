import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SingleLineRTEditor,
  SuggestionType,
} from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox';
import IconTextButton from '../../../../components/IconTextButton';
import Input from '../../../../components/Input';
import useChecklist from '../../../../hooks/useChecklist';
import useInputValidation from '../../../../hooks/useInputValidation';
import useStores from '../../../../hooks/useStores';
import { InteriorColorLangItemMap } from '../../../../models/colors.model';
import { RefItem } from '../../../../models/refItem.model';
import { BRAND_TDPR } from '../../../../models/user.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../models/vehicleModel.model';
import {
  getInteriorColorGradeListForChecklist,
  hasChangesInteriorColor,
} from '../../../../utils/colorUtils';
import useFieldStatusIntColor from '../../tabModules/colors/hooks/useFieldStatusIntColor';
import SyncTdPRButton from '../SyncTdPRButton/SyncTdPRButton';
import AllModels from './AllModels';
import styles from './addEditInteriorColorModal.module.scss';

interface AddEditInteriorColorModalProps {
  onSaveMap: (
    colorMap: InteriorColorLangItemMap,
    addIntColor: boolean,
    acceptChanges?: boolean
  ) => Promise<void>;
  onDeleteMap: (color: InteriorColorLangItemMap) => void;
  close: () => void;
  addIntColor: boolean;
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  grades: RefItem[];
  allLangs: string[];
  editableLangs: string[];
  defaultLang: string;
  hasEnglishWritePerms: boolean;
  selectedInteriorColorMap?: InteriorColorLangItemMap;
  readOnly?: boolean;
  disclaimerTokens?: SuggestionType[];
  allowDisclaimerTokens?: boolean;
  compareInteriorColor: (color: InteriorColorLangItemMap) => void;
  brand: string;
  showAcceptChanges: boolean;
  setLastUpdated: React.Dispatch<React.SetStateAction<Date>>;
}

const AddEditInteriorColorModal = ({
  onSaveMap,
  onDeleteMap,
  close,
  addIntColor,
  vehicleModels,
  grades,
  allowDisclaimerTokens,
  disclaimerTokens,
  selectedInteriorColorMap,
  readOnly,
  editableLangs,
  allLangs,
  defaultLang,
  hasEnglishWritePerms,
  compareInteriorColor,
  brand,
  showAcceptChanges,
  setLastUpdated,
}: AddEditInteriorColorModalProps) => {
  const COLOR_CODE_MAX_LENGTH = 4;
  const [acceptChanges, setAcceptChanges] = useState(false);
  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } = useChecklist();
  const { colorsStore, teamStore } = useStores();
  const [defIntcolorMap] = useState<InteriorColorLangItemMap | undefined>(
    selectedInteriorColorMap ? JSON.parse(JSON.stringify(selectedInteriorColorMap)) : undefined
  );

  colorsStore.defaultInteriorColorItem = selectedInteriorColorMap
    ? selectedInteriorColorMap.langs[defaultLang]
    : undefined;

  const {
    onEdit: onEditLimitedDataStatus,
    onSave: onSaveLimitedDataStatus,
    hasChanges: hasChangesLimitedDataStatus,
  } = useFieldStatusIntColor();

  const hasChangesIntColor = useCallback(
    () =>
      hasChangesInteriorColor({
        selectedInteriorColorMap,
        editableLangs,
        defIntcolorMap,
        gradeList: checklist,
        defaultLang,
      }),
    [selectedInteriorColorMap, editableLangs, defIntcolorMap, checklist, defaultLang]
  );

  useEffect(() => {
    setChecklist(
      getInteriorColorGradeListForChecklist({
        defaultInteriorColorItem: colorsStore.defaultInteriorColorItem,
        vehicleModels,
        grades,
        brand,
      })
    );
  }, [setChecklist, colorsStore.defaultInteriorColorItem, vehicleModels, grades, brand]);

  const { defaultInteriorColorItem } = colorsStore;

  const [selectType, setSelectType] = useState(true);

  const { isInvalid, validate, errorMessages } = useInputValidation([
    (value: string) =>
      value.length < COLOR_CODE_MAX_LENGTH
        ? 'Color code must be at least 4 characters at length.'
        : '',
    (value: string) =>
      !value.length ? 'Please finish filling out all items of the new color' : '',
  ]);

  const handleOnDelete = () => {
    if (selectedInteriorColorMap) {
      onDeleteMap(selectedInteriorColorMap);
      close();
    }
  };

  const handleName = (name: string, lang: string) => {
    if (selectedInteriorColorMap) {
      onEditLimitedDataStatus();
      selectedInteriorColorMap.langs[lang].name = name;
    }
  };

  const handleMsrp = (msrp: string) => {
    if (selectedInteriorColorMap) {
      onEditLimitedDataStatus();
      editableLangs.forEach(lang => {
        selectedInteriorColorMap.langs[lang].isExtraCost = msrp;
      });
    }
  };

  const handleModelCode = (code: string) => {
    if (!isInvalid) {
      if (selectedInteriorColorMap) {
        onEditLimitedDataStatus();
        editableLangs.forEach(lang => {
          selectedInteriorColorMap.langs[lang].code = code;
        });
      }
    } else {
      toast.error(errorMessages[0]);
    }
  };

  const isAcceptChangesVisible =
    !readOnly &&
    !!editableLangs.filter(lang => !!selectedInteriorColorMap?.langs[lang].changedAttributes.length)
      .length &&
    showAcceptChanges;

  const displayLimitedDataStatus =
    teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';

  const handleOnSaveMap = () => {
    if (selectedInteriorColorMap) {
      (hasChangesIntColor() || (isAcceptChangesVisible && acceptChanges)) &&
        onSaveMap(selectedInteriorColorMap, addIntColor, isAcceptChangesVisible && acceptChanges);
      displayLimitedDataStatus &&
        !addIntColor &&
        hasChangesLimitedDataStatus(checklist) &&
        onSaveLimitedDataStatus(() => {
          setLastUpdated(new Date());
        });
      close();
    }
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    if (selectedInteriorColorMap) {
      editableLangs.forEach(lang => {
        const color = selectedInteriorColorMap.langs[lang];
        if (color) {
          changed.push(...color.changedAttributes);
        }
      });
    }
    return changed;
  };

  const showSyncTDPR = brand === BRAND_TDPR && !readOnly && !!defaultInteriorColorItem?.fromTMNA;

  const isDisabled = () => {
    if (displayLimitedDataStatus) {
      return !hasChangesIntColor() && !hasChangesLimitedDataStatus(checklist);
    }

    return !hasChangesIntColor();
  };

  return (
    <>
      <ModalHeader onClose={close}>{addIntColor ? 'Add' : 'Edit'} Interior Color</ModalHeader>
      <ModalBody>
        {selectType && (
          <div className={styles.dropdownWrapper}>
            {showSyncTDPR && selectedInteriorColorMap && (
              <SyncTdPRButton
                changedAttributes={changedAttributes()}
                id={defaultInteriorColorItem?.id || ''}
                onClick={() => {
                  compareInteriorColor(selectedInteriorColorMap);
                  close();
                }}
              />
            )}
            <div className={styles.nameInputModelCodeContainer}>
              <div className={styles.nameFieldContainer}>
                {allLangs.map(lang => {
                  const colorItem = selectedInteriorColorMap
                    ? selectedInteriorColorMap.langs[lang]
                    : undefined;
                  if (allowDisclaimerTokens) {
                    return (
                      <SingleLineRTEditor
                        key={`${colorItem?.id}-${lang}-SingleLineRTEditor`}
                        disabled={readOnly || !editableLangs.includes(lang)}
                        value={colorItem ? colorItem.name : ''}
                        onBlur={value => handleName(value.trim(), lang)}
                        suggestionTypes={disclaimerTokens}
                        editorStyles={cx(
                          styles.name,
                          !editableLangs.includes(lang) && styles.disabledColorRTE,
                          colorItem && colorItem.changedAttributes.includes('name')
                            ? styles.errorTextRte
                            : ''
                        )}
                        placeholder="Name"
                        forceBorder
                      />
                    );
                  }
                  return (
                    <Input
                      key={`${colorItem?.id}-${lang}-Input`}
                      className={cx(
                        styles.name,
                        colorItem && colorItem.changedAttributes.includes('name')
                          ? styles.errorText
                          : ''
                      )}
                      defaultValue={colorItem ? colorItem.name : ''}
                      onChange={e => handleName(e.currentTarget.value.trim(), lang)}
                      name="name"
                      disabled={readOnly}
                      placeholder="Name"
                    />
                  );
                })}
              </div>

              <Input
                className={cx(
                  styles.modelCode,
                  defaultInteriorColorItem &&
                    defaultInteriorColorItem.changedAttributes.includes('code')
                    ? styles.errorText
                    : ''
                )}
                defaultValue={defaultInteriorColorItem ? defaultInteriorColorItem.code : ''}
                onBlur={e => handleModelCode(e.currentTarget.value.toUpperCase().trim())}
                name="code"
                disabled={readOnly || !hasEnglishWritePerms}
                placeholder="Interior ID"
                maxLength={COLOR_CODE_MAX_LENGTH}
                isInvalid={isInvalid}
                validate={validate}
              />
            </div>

            <span
              className={cx(
                styles.currencyInput,
                allLangs.length > 1 && styles.multiLangCurrencyInput
              )}
            >
              <Input
                className={cx(
                  styles.msrp,
                  defaultInteriorColorItem &&
                    defaultInteriorColorItem.changedAttributes.includes('isExtraCost')
                    ? styles.errorText
                    : ''
                )}
                defaultValue={defaultInteriorColorItem ? defaultInteriorColorItem.isExtraCost : ''}
                onChange={e => handleMsrp(e.currentTarget.value.trim())}
                name="msrp"
                disabled={readOnly || !hasEnglishWritePerms}
                placeholder="MSRP-Optional"
              />
            </span>

            {isAcceptChangesVisible && (
              <div className={cx([styles.acceptChanges])}>
                <Checkbox
                  id={`acceptChanges-${defaultInteriorColorItem?.id}`}
                  checked={acceptChanges}
                  onChange={e => {
                    setAcceptChanges(!!e.target.checked);
                  }}
                >
                  <span className={styles.checkboxLabel}>Accept Changes</span>
                </Checkbox>
              </div>
            )}
          </div>
        )}

        {!selectType && (
          <AllModels
            selectedInteriorColorMap={selectedInteriorColorMap}
            checklist={checklist}
            isAllSelected={isAllSelected}
            selectAll={selectAll}
            selectItem={selectItem}
          />
        )}

        {defaultInteriorColorItem && defaultInteriorColorItem.rejectNotes ? (
          <div className={styles.rejectNotes}>
            <IconTextButton icon="purpleCircle" smallIcon text="Reject Notes" disabled />
            <div className={styles.rejectNotesText}>{defaultInteriorColorItem.rejectNotes}</div>
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        {selectType && (
          <>
            <Button
              variant="transparent"
              onClick={() => close()}
              className={cx({
                [styles.cancelCta]: !addIntColor,
              })}
            >
              Cancel
            </Button>
            {!addIntColor && hasEnglishWritePerms && (
              <Button
                variant="primary"
                onClick={() => handleOnDelete()}
                disabled={
                  defaultInteriorColorItem &&
                  (defaultInteriorColorItem.name === '' || defaultInteriorColorItem.code === '')
                }
              >
                Delete
              </Button>
            )}
            {hasEnglishWritePerms ? (
              <Button
                variant="primary"
                onClick={() => setSelectType(false)}
                disabled={
                  defaultInteriorColorItem && (defaultInteriorColorItem.name === '' || isInvalid)
                }
              >
                Next
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => handleOnSaveMap()}
                disabled={(() => {
                  for (const lang of editableLangs) {
                    if (
                      selectedInteriorColorMap &&
                      (selectedInteriorColorMap.langs[lang].name === '' ||
                        selectedInteriorColorMap.langs[lang].code === '')
                    ) {
                      return true;
                    }
                  }
                  return false;
                })()}
              >
                Save
              </Button>
            )}
          </>
        )}

        {!selectType && (
          <>
            <Button variant="transparent" onClick={() => setSelectType(true)}>
              Back
            </Button>
            <Button variant="primary" onClick={() => handleOnSaveMap()} disabled={isDisabled()}>
              {addIntColor ? 'Add' : 'Save'} Color
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default observer(AddEditInteriorColorModal);
