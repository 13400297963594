import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input } from 'vapi-ui-common';
import IconTextButton from '../../../../../../../../components/IconTextButton';
import useStores from '../../../../../../../../hooks/useStores';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../../models/vehicleModel.model';
import styles from '../../../../modelsModal.module.scss';
import Label from '../Label';

interface TrimTitleProps {
  vmId: string;
  seriesName: string;
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  children?: JSX.Element | JSX.Element[];
}

const TrimTitle = observer(({ vmId, seriesName, model, children }: TrimTitleProps) => {
  const {
    modelTabStore: {
      languages: checkboxLanguages,
      readOnly,
      getTrimTitle,
      setTrimTitle,
      getGrade,
      getLangVehicleModel,
    },
    userStore: { langPermissions },
    teamStore: {
      team: { languages: teamLanguages },
    },
  } = useStores();

  const handleTrimTitleGenerator = () => {
    teamLanguages.forEach(language => {
      if (!langPermissions[language]?.canEdit) return;
      const grade = getGrade(vmId, language);
      const trimTitle = `${seriesName} ${grade.value ? grade.value : ''}`;
      setTrimTitle(vmId, language, trimTitle);
    });
  };

  return (
    <div className={styles.column}>
      <div className={styles.label}>
        <Label htmlFor={`amm-trim-title-${vmId}`}>Trim Title</Label>
        {!readOnly && (
          <IconTextButton
            title="Populate trim title using Series Name, Grade"
            icon="random"
            text=""
            className={styles.iconButton}
            onClick={handleTrimTitleGenerator}
          >
            <FontAwesomeIcon icon={faCompressArrowsAlt} />
          </IconTextButton>
        )}
      </div>
      <div className={cx(styles.content)}>
        {teamLanguages.map(language => {
          if (checkboxLanguages[language].selected && langPermissions[language]?.canView) {
            let changedAttributes: string[] = [];
            if (langPermissions[language]?.canEdit) {
              changedAttributes =
                getLangVehicleModel(model, language).getVal('changedAttributes') ?? [];
            }
            return (
              <Input
                required
                id={`amm-trim-title-${vmId}-${language}`}
                key={`amm-trim-title-${vmId}-${language}`}
                value={getTrimTitle(vmId, language)}
                disabled={!langPermissions[language]?.canEdit || readOnly}
                onChange={e => setTrimTitle(vmId, language, e.currentTarget.value)}
                placeholder={checkboxLanguages[language].description}
                className={cx(
                  { [styles.errorText]: changedAttributes.includes('trimTitle') },
                  styles.input
                )}
              />
            );
          }

          return null;
        })}
      </div>
      {children}
    </div>
  );
});

export default TrimTitle;
