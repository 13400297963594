import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Input, Modal, areRichTextValuesDifferent, convertToRichTextObject } from 'vapi-ui-common';
import DropdownEditItem from '../../../../../../components/DropdownEdit/DropdownEditItem';
import IconTextButton from '../../../../../../components/IconTextButton';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { TableCell, TableRowWithShadow } from '../../../../../../components/Table';
import TableDragIcon from '../../../../../../components/Table/components/TableDragIcon';
import Tooltip from '../../../../../../components/Tooltip';
import useFieldStatusModelUpdate from '../../../../../../hooks/useFieldStatusModelUpdate';
import { useRefItemsMapSort } from '../../../../../../hooks/useLanguageMapSort';
import useStores from '../../../../../../hooks/useStores';
import { RefItemLangMap } from '../../../../../../models/category.model';
import { SpecItem, SpecsLangMap } from '../../../../../../models/specs.model';
import { BRAND_TDPR, Language } from '../../../../../../models/user.model';
import SpecLinkModal from '../../../../../../routes/vehicleData/components/SpecLinkModal';
import RichTextCell from '../../../../../../routes/vehicleData/components/tableCells/RichTextCell';
import { isSpecRefRequired } from '../../../../../../utils/refItemUtils';
import { handleOnSortNumberUpdate } from '../../../../../../utils/sortUtils';
import { updateSpecFeatureStatus } from '../../../../../../webservices/vehicleSpecsApi';
import SyncTdPRButton from '../../../../components/SyncTdPRButton/SyncTdPRButton';
import ContextMenuCell from '../../../../components/tableCells/ContextMenuCell';
import DropdownEditorCell from '../../../../components/tableCells/DropdownEditorCell';
import FlagsCell from '../../../../components/tableCells/FlagsCell';
import LinkCell from '../../../../components/tableCells/LinkCell';
import { ISpecRow } from './ISpecRow';
import styles from './SpecsFormRow.module.scss';

interface SpecsFormRowProps extends ISpecRow {
  specLangMap: SpecsLangMap;
  index: number;
  draggableProvided: DraggableProvided;
}

const SpecsFormRow: React.FC<SpecsFormRowProps> = ({
  index,
  specLangMap,
  draggableProvided,
  // spec,
  saveSpecLangMap,
  sortMode,
  deleteSpecLangMap,
  copySpecLangMap,
  addCategoryItem,
  updateCategoryItem,
  addSpecTypeItem,
  updateSpecTypeItem,
  handleCompareFeatureHighlighted,
  showSpecType,
  showSpecLinkModal,
  showLink,
  readOnly,
  disclaimerTokens,
  brand,
  compareSpec,
}) => {
  const [openSpecLinkModal, setSpecLinkModal] = useState(false);
  const { commonLanguageStore, specsStore, teamStore, userStore } = useStores();
  const defaultSpec = specLangMap.langs[specsStore.defaultLang];
  const selectedLangs = specsStore.allLangs.filter(lang => specsStore.selectedLangsMap[lang]);
  const index2 = specsStore.filteredSpecLangMaps.findIndex(
    x => x.langs[specsStore.defaultLang].id === defaultSpec.id
  );
  const hasEnglishWritePerms = !!specsStore.langWriteMap.EN?.canEdit;

  const { onClick: onClickLimitedDataStatus } = useFieldStatusModelUpdate({
    store: defaultSpec,
    callBack: updateSpecFeatureStatus,
  });

  /** Feature change handlers */
  const handleOnHyperLinkChange = (value: string) => {
    if (defaultSpec.link !== value) {
      specsStore.allLangs.forEach(lang => {
        specLangMap.langs[lang].link = value;
      });
      saveSpecLangMap(specLangMap);
    }
  };

  const handleOnCategorySelect = (
    categoryMap: RefItemLangMap,
    compareChangeMessageRequest: boolean,
    lang: string
  ) => {
    const newCategory = categoryMap[lang];
    const currentCategory = specLangMap.langs[lang].category;
    if (
      newCategory.value &&
      (currentCategory.value !== newCategory.value || currentCategory.id !== newCategory.id)
    ) {
      specsStore.editableLangs.forEach(lang => {
        specLangMap.langs[lang].category = categoryMap[lang];
      });
      saveSpecLangMap(specLangMap, compareChangeMessageRequest);
    }
  };

  const handleOnDescriptionChange = (value: string, lang: string) => {
    const spec = specLangMap.langs[lang];
    if (areRichTextValuesDifferent(spec.description, value)) {
      spec.description = value;
      saveSpecLangMap(specLangMap, false, lang);
    }
  };

  const handleOnSpecTypeChange = (
    specTypeMap: RefItemLangMap,
    compareChangeMessageRequest: boolean,
    lang: string
  ) => {
    const newSpecType = specTypeMap[lang];
    const currentSpecType = specLangMap.langs[lang].specType;
    if (currentSpecType.value !== newSpecType.value || currentSpecType.id !== newSpecType.id) {
      specsStore.editableLangs.forEach(lang => {
        specLangMap.langs[lang].specType = specTypeMap[lang];
      });
      saveSpecLangMap(specLangMap, compareChangeMessageRequest);
    }
  };

  const handleOnNotesChange = (value: string) => {
    let shouldUpdate = false;
    specsStore.editableLangs.forEach(lang => {
      if (specLangMap.langs[lang].notes !== value) {
        specLangMap.langs[lang].notes = value;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveSpecLangMap(specLangMap, false);
    }
  };

  const handleInProgressChange = () => {
    specsStore.editableLangs.forEach(lang => {
      specLangMap.langs[lang].isInProgress = !specLangMap.langs[lang].isInProgress;
    });
    saveSpecLangMap(specLangMap, false);
  };

  const handleOnComLangChange = (spec: SpecItem, comLangId: string) => {
    if (comLangId !== spec.comLangId) {
      spec.comLangId = comLangId;
      saveSpecLangMap(specLangMap);
    }
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    specsStore.editableLangs.forEach(lang => {
      const spec = specLangMap.langs[lang];
      if (spec) {
        changed.push(...spec.changedAttributes);
      }
    });
    return changed;
  };

  const data = commonLanguageStore.data
    .filter(item => item.id === defaultSpec.comLangId)
    .map(el => el.description);

  const comLangDescription = data.length ? convertToRichTextObject(data[0]).text : '';
  const isAcceptChangesVisible =
    !readOnly &&
    !!specsStore.editableLangs.filter(lang => {
      let hasChange = false;
      for (const change of specLangMap.langs[lang].changedAttributes) {
        if (change !== 'new' && change !== 'delete') {
          hasChange = true;
          break;
        }
      }
      return hasChange;
    }).length;

  const showSyncTdPR =
    brand === BRAND_TDPR && defaultSpec.fromTMNA && !readOnly && selectedLangs.length >= 1;

  const disabled = !hasEnglishWritePerms || readOnly;

  const { sortedList: sortedCat } = useRefItemsMapSort(specsStore.categoriesMap, Language.EN);
  const { sortedList: sortedSpecs } = useRefItemsMapSort(specsStore.specTypeMap, Language.EN);

  return (
    <TableRowWithShadow
      onFillRowHeightChange={(rowHeight: number) => {
        specsStore.setRowHeight(specLangMap, rowHeight);
      }}
      innerRef={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      className={cx(styles.tableRow)}
    >
      {!readOnly && sortMode && (
        <>
          <TableCell {...draggableProvided.dragHandleProps} border center>
            <TableDragIcon />
          </TableCell>
          <TableCell border center>
            <input
              className={cx(inputStyles.input, inputStyles.smallInput)}
              value={defaultSpec.sortOrder}
              onBlur={newIndex => {
                handleOnSortNumberUpdate(
                  specsStore.filteredSpecLangMaps,
                  newIndex.target.value,
                  index2
                );
                specsStore.filteredSpecLangMaps = specsStore.filteredSpecLangMaps.slice();
              }}
              onChange={e =>
                (defaultSpec.sortOrder =
                  parseInt(e.currentTarget.value, 10) > 0
                    ? parseInt(e.currentTarget.value, 10)
                    : '')
              }
            />
          </TableCell>
        </>
      )}
      {!readOnly &&
        !sortMode &&
        teamStore.team.allowAddDeleteData &&
        (brand !== BRAND_TDPR || !(defaultSpec.isTDPR || defaultSpec.isUSVI)) && (
          <ContextMenuCell
            itemType={'Spec'}
            description={convertToRichTextObject(defaultSpec.description).text}
            deleteItem={defaultSpec.isRequired ? undefined : () => deleteSpecLangMap(specLangMap)}
            copyItem={
              userStore.isTdpr
                ? defaultSpec.isTDPR || defaultSpec.isUSVI
                  ? undefined
                  : () => copySpecLangMap(specLangMap)
                : () => copySpecLangMap(specLangMap)
            }
          />
        )}
      {!readOnly &&
        !sortMode &&
        brand === BRAND_TDPR &&
        (defaultSpec.isTDPR || defaultSpec.isUSVI) && <TableCell></TableCell>}
      {teamStore.team.showAcceptChanges && isAcceptChangesVisible && (
        <TableCell>
          <div id={`acceptChanges-${defaultSpec.id}`} className={styles.acceptChangesThumbsUp}>
            <IconTextButton
              id={`acceptChanges-checkbox-${defaultSpec.id}`}
              icon="thumbsUp"
              text=""
              onClick={() => {
                saveSpecLangMap(specLangMap, false, undefined, true);
              }}
            />
          </div>
        </TableCell>
      )}
      {teamStore.team.showAcceptChanges &&
        !isAcceptChangesVisible &&
        specsStore.hasChangedAttributes() && <TableCell></TableCell>}
      <TableCell className={styles.categoryColumn} spanClass={styles.flexColumn}>
        {showSyncTdPR && (
          <SyncTdPRButton
            selectedLangs={selectedLangs}
            id={defaultSpec.id}
            changedAttributes={changedAttributes()}
            onClick={() => {
              compareSpec(specLangMap);
            }}
            className={styles.tdprButton}
          />
        )}
        {selectedLangs.map(lang => {
          const spec = specLangMap.langs[lang];
          const currentCategory = spec.category;
          const currentCategoryValue: string = currentCategory.value;

          if (lang === Language.EN) {
            return (
              <DropdownEditorCell
                error={(() => {
                  if (!specsStore.langWriteMap[lang]?.canEdit || currentCategoryValue) {
                    // if you dont have write permissions or the current feature category has a value
                    return false;
                  }
                  return true;
                })()}
                disabled={
                  readOnly ||
                  !specsStore.langWriteMap[lang]?.canEdit ||
                  (isSpecRefRequired(spec.isRequired, spec.category) && brand !== BRAND_TDPR)
                }
                multiLang={specsStore.allLangs.filter(lang => specsStore.selectedLangsMap[lang])}
                onAdd={
                  !teamStore.team.canAddFromDropdown
                    ? undefined
                    : value => {
                        const payload: { [lang: string]: string } = { [lang]: value };
                        specsStore.editableLangs.forEach(lang => {
                          if (!payload[lang]) {
                            payload[lang] = value;
                          }
                        });
                        addCategoryItem(payload);
                      }
                }
                value={currentCategoryValue}
                renderList={onClose => (
                  <>
                    {sortedCat?.map(item => {
                      const categoryMap = item.categoryMap;
                      const category = item.category;

                      return (
                        <DropdownEditItem
                          key={category.id}
                          value={category.value}
                          isSelected={currentCategoryValue === category.value}
                          onEdit={
                            category.isRequired && brand !== BRAND_TDPR
                              ? undefined
                              : (from, to) => {
                                  if (to.length) {
                                    const payload: { [lang: string]: string } = { [lang]: to };
                                    if (!category.value) {
                                      addCategoryItem(payload, category.id);
                                    } else {
                                      updateCategoryItem(categoryMap, payload);
                                    }
                                  }
                                }
                          }
                          onClose={() => onClose()}
                          onSelect={() => {
                            handleOnCategorySelect(
                              categoryMap,
                              currentCategoryValue !== category.value,
                              lang
                            );
                            onClose();
                          }}
                        />
                      );
                    })}
                  </>
                )}
              />
            );
          }
          return (
            <div key={currentCategoryValue}>
              <Input
                defaultValue={currentCategoryValue}
                onBlur={e => {
                  const value = e.currentTarget.value.trim();
                  if (value.length && value !== currentCategoryValue) {
                    const payload: { [lang: string]: string } = { [lang]: value };
                    if (!currentCategoryValue) {
                      // if the category doesnt have a value then we are going to add it
                      addCategoryItem(payload, currentCategory.id);
                    } else {
                      updateCategoryItem(
                        specsStore.categoriesMap.categories[currentCategory.id],
                        payload
                      );
                    }
                  }
                }}
                name="name"
                disabled={readOnly || !currentCategory.id}
                placeholder=""
                className={
                  spec.changedAttributes.includes('categoryId') || !currentCategoryValue
                    ? styles.errorText
                    : ''
                }
              />
            </div>
          );
        })}
      </TableCell>
      {showSpecType && (
        <TableCell
          className={styles.categoryColumn}
          spanClass={cx(
            styles.flexColumn,
            (showSyncTdPR || defaultSpec.fromTMNA) && !readOnly && styles.alignCategorySubCategory
          )}
        >
          {/* align the specType Dropdown with the Cat dropdown with a NAT CTA */}
          {selectedLangs.map(lang => {
            const spec = specLangMap.langs[lang];
            const currentSpecType = spec.specType;
            const currentSpecTypeValue: string = currentSpecType.value;

            if (lang === Language.EN) {
              return (
                <DropdownEditorCell
                  error={(() => {
                    if (!specsStore.langWriteMap[lang]?.canEdit || currentSpecTypeValue) {
                      return false;
                    }
                    return true;
                  })()}
                  disabled={
                    readOnly ||
                    !specsStore.langWriteMap[lang]?.canEdit ||
                    (isSpecRefRequired(spec.isRequired, spec.specType, spec.category) &&
                      brand !== BRAND_TDPR)
                  }
                  multiLang={specsStore.allLangs.filter(lang => specsStore.selectedLangsMap[lang])}
                  onAdd={
                    !teamStore.team.canAddFromDropdown
                      ? undefined
                      : value => {
                          const payload: { [lang: string]: string } = { [lang]: value };
                          specsStore.editableLangs.forEach(lang => {
                            if (!payload[lang]) {
                              payload[lang] = value;
                            }
                          });
                          addSpecTypeItem(payload);
                        }
                  }
                  value={currentSpecTypeValue}
                  renderList={onClose => (
                    <>
                      {sortedSpecs?.map(item => {
                        const specTypeLangMap = item.categoryMap;
                        const specType = item.category;

                        return (
                          <DropdownEditItem
                            key={specType.id}
                            value={specType.value}
                            isSelected={currentSpecTypeValue === specType.value}
                            onEdit={
                              specType.isRequired && brand !== BRAND_TDPR
                                ? undefined
                                : (from, to) => {
                                    if (to.length) {
                                      const payload: { [lang: string]: string } = { [lang]: to };
                                      if (!specType.value) {
                                        // if the subcategory doesnt have a value then we are going to have to add it in the backend
                                        addSpecTypeItem(payload, specType.id);
                                      } else {
                                        updateSpecTypeItem(specTypeLangMap, payload);
                                      }
                                    }
                                  }
                            }
                            onClose={() => onClose()}
                            onSelect={() => {
                              handleOnSpecTypeChange(
                                specTypeLangMap,
                                currentSpecTypeValue !== specType.value,
                                lang
                              );
                              onClose();
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                />
              );
            }
            return (
              <div key={currentSpecTypeValue}>
                <Input
                  defaultValue={currentSpecTypeValue}
                  onBlur={e => {
                    const value = e.currentTarget.value.trim();
                    if (value.length && value !== currentSpecTypeValue) {
                      const payload: { [lang: string]: string } = { [lang]: value };
                      if (!currentSpecTypeValue) {
                        addSpecTypeItem(payload, currentSpecType.id);
                      } else {
                        updateSpecTypeItem(
                          specsStore.specTypeMap.categories[currentSpecType.id],
                          payload
                        );
                      }
                    }
                  }}
                  name="name"
                  disabled={readOnly || !currentSpecType.id}
                  placeholder=""
                  className={
                    spec.changedAttributes.includes('specTypeId') || !currentSpecTypeValue
                      ? styles.errorText
                      : ''
                  }
                />
              </div>
            );
          })}
        </TableCell>
      )}
      {showSpecLinkModal && !sortMode && (
        <TableCell colType="flags" className={styles.linkSpec}>
          <Tooltip
            toggleElement={
              <div>
                <FontAwesomeIcon
                  onClick={() => setSpecLinkModal(!readOnly && !openSpecLinkModal)}
                  className={cx(styles.iconFlag, defaultSpec.comLangId && styles.iconBlue)}
                  icon={faLink}
                />
              </div>
            }
            popoverElement={
              <div className={styles.modelPopover}>
                <h4>Linked Common Language</h4>
                <div className={styles.ClDescription}>{comLangDescription}</div>
              </div>
            }
            hide={!comLangDescription}
          />
        </TableCell>
      )}
      <Modal size="xl" open={openSpecLinkModal} onClose={() => setSpecLinkModal(false)}>
        <SpecLinkModal
          tabName="Link Spec"
          comLangId={defaultSpec.comLangId}
          onAddItem={comLangId => handleOnComLangChange(defaultSpec, comLangId)}
          onClose={() => setSpecLinkModal(false)}
        />
      </Modal>
      {showLink && (
        <LinkCell disabled={disabled} link={defaultSpec.link} onClick={handleOnHyperLinkChange} />
      )}
      {selectedLangs.map(lang => {
        const spec = specLangMap.langs[lang];
        return (
          <RichTextCell
            required={!spec.description || spec.changedAttributes.includes('description')}
            colType="description"
            suggestionTypes={disclaimerTokens}
            disabled={readOnly || !specsStore.langWriteMap[lang]?.canEdit}
            value={spec.description}
            onBlur={value => {
              handleOnDescriptionChange(value, lang);
            }}
          />
        );
      })}
      <FlagsCell
        allowGradeApplicability={teamStore.team.allowGradeApplicability}
        disabled={disabled}
        badgeText={defaultSpec.isTDPR ? 'TDPR' : defaultSpec.isUSVI ? 'USVI' : ''}
        index={`${index}-flagcell`}
        notes={defaultSpec.notes}
        rejectNotes={defaultSpec.rejectNotes}
        highlighted={false}
        displayHighlighted={false}
        inProgress={defaultSpec.isInProgress}
        toggleInProgress={() => {
          if (!disabled) {
            handleInProgressChange();
          }
        }}
        toggleHighlighted={() => {}}
        toggleCompareArrow={() => {
          if (!disabled) {
            handleCompareFeatureHighlighted(specLangMap);
          }
        }}
        allowCompareFeature={teamStore.team.allowCompareFeatures}
        isCompareHighlighted={!!defaultSpec.compareFeatureId}
        onNotesChange={notes => {
          handleOnNotesChange(notes);
        }}
        displayLimitedDataStatus={
          teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true'
        }
        limitedDataStatus={defaultSpec.fieldStatus?.status}
        onClickLimitedDataStatus={onClickLimitedDataStatus}
        hideInProgress={process.env.REACT_APP_LIMITED_DATA === 'true' && brand !== BRAND_TDPR}
      />
    </TableRowWithShadow>
  );
};

export default observer(SpecsFormRow);
