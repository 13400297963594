import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownList,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'vapi-ui-common';
import IconTextButton from '../../../../../../components/IconTextButton';
import Input from '../../../../../../components/Input';
import { DashboardDetailYear } from '../../../../../../models/vehicleData.model';
import styles from './addModelYearModal.module.scss';

interface AddModelYearModalProps {
  close: () => void;
  modelYearItem: DashboardDetailYear[];
  seriesName?: string;
  seriesId: string;
  onAddItem: (seriesId: string, modelYear: number) => void;
  onDuplicateItem: (seriesId: string, fromModelYear: number, toModelYear: number) => void;
}

const AddModelYearModal = observer(
  ({
    close,
    modelYearItem,
    seriesName,
    seriesId,
    onAddItem,
    onDuplicateItem,
  }: AddModelYearModalProps) => {
    const [options, showOptions] = useState(true);
    const [duplicate, setDuplicate] = useState(false);
    const [create, setCreate] = useState(false);
    const modelYears: string[] = [];
    modelYearItem.forEach(item => {
      if (!item.isDraft) {
        modelYears.push(item.year.toString());
      }
    });
    const [currentModelYear, setCurrentModelYear] = useState('');
    const [duplicateFromModelYear, setDuplicateFromModelYear] = useState(modelYears[0] || '');

    return (
      <>
        <ModalHeader onClose={close}>Add Model to {seriesName}</ModalHeader>
        <ModalBody>
          {options && (
            <>
              <span className={styles.duplicateCta}>
                <IconTextButton
                  icon="duplicate"
                  text="Duplicate Model Year"
                  className={styles.content}
                  onClick={() => {
                    setDuplicate(true);
                    setCreate(false);
                    showOptions(false);
                  }}
                />
              </span>
              <span className={styles.seperator}>or</span>
              <span className={styles.createCta}>
                <IconTextButton
                  icon="plus"
                  text="Create New"
                  className={styles.content}
                  onClick={() => {
                    setDuplicate(false);
                    setCreate(true);
                    showOptions(false);
                  }}
                />
              </span>
            </>
          )}

          {duplicate && (
            <>
              <div className={styles.dropdownWrapper}>
                <label htmlFor="duplicateModelYear" className={styles.modelYearLabel}>
                  Duplicate Model Year
                </label>
                <Dropdown className={styles.modelYearDropdown}>
                  <DropdownList
                    list={modelYears}
                    value={duplicateFromModelYear}
                    onSelect={(item: string) => setDuplicateFromModelYear(item)}
                  />
                </Dropdown>
              </div>
            </>
          )}

          {(duplicate || create) && (
            <>
              <div>
                <label htmlFor="YearTitle" className={styles.modelYearLabel}>
                  Year Title
                </label>
                <Input
                  className={styles.yearTitle}
                  onChange={e => setCurrentModelYear(e.currentTarget.value.trim())}
                />
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {(duplicate || create) && (
            <Button
              variant="transparent"
              onClick={() => {
                setDuplicate(false);
                setCreate(false);
                showOptions(true);
              }}
              className={styles.backCta}
            >
              Back
            </Button>
          )}

          <Button variant="transparent" onClick={() => close()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (Number(currentModelYear)) {
                close();
                if (duplicate) {
                  onDuplicateItem(
                    seriesId,
                    Number(duplicateFromModelYear),
                    Number(currentModelYear)
                  );
                } else {
                  onAddItem(seriesId, Number(currentModelYear));
                }
              }
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </>
    );
  }
);

export default AddModelYearModal;
