import React from 'react';
import {
  ActionButton,
  ActionItemModal,
  convertToRichTextObject,
  Modal,
  TableCell,
} from 'vapi-ui-common';
import { CommonLanguageItem } from '../../models/commonLanguage.model';
import { DisclaimersItem } from '../../models/disclaimers.model';
import styles from './actionButtonsTableCell.module.scss';

export interface IRowActionButtons {
  readOnly: boolean | undefined;
  item: DisclaimersItem | CommonLanguageItem;
  setOpenDeleteModal: (open: boolean) => void;
  openDeleteModal: boolean;
  setOpenArchiveModal: (open: boolean) => void;
  openArchiveModal: boolean;
  onDeleteItem: () => void;
  onArchiveItem: () => void;
  itemType: 'Spec' | 'Disclaimer';
}

const ActionButtonsTableCell = ({
  readOnly,
  item,
  setOpenDeleteModal,
  openDeleteModal,
  setOpenArchiveModal,
  openArchiveModal,
  onDeleteItem,
  onArchiveItem,
  itemType,
}: IRowActionButtons) => {
  return (
    <TableCell large className={styles.actionButtons} data-testid="action-buttons-table-cell">
      {!readOnly && (
        <>
          {!item.lastPublishedDate && (
            <>
              <ActionButton icon="trash" onClick={() => setOpenDeleteModal(true)} />
              <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <ActionItemModal
                  description={convertToRichTextObject(item.description).text}
                  onClose={() => setOpenDeleteModal(false)}
                  onConfirm={() => onDeleteItem()}
                  itemType={itemType}
                />
              </Modal>
            </>
          )}
          {item.lastPublishedDate && (
            <>
              <ActionButton icon="archive" onClick={() => setOpenArchiveModal(true)} />
              <Modal open={openArchiveModal} onClose={() => setOpenArchiveModal(false)}>
                <ActionItemModal
                  description={convertToRichTextObject(item.description).text}
                  onClose={() => setOpenArchiveModal(false)}
                  onConfirm={() => onArchiveItem()}
                  action="archive"
                  itemType={itemType}
                />
              </Modal>
            </>
          )}
        </>
      )}
    </TableCell>
  );
};

export default ActionButtonsTableCell;
