import React, { useState } from 'react';
import { DateComponent, Modal, RTEhtml } from 'vapi-ui-common';
import { ChangeLogChanges } from '../../../../components/ChangeLogTable';
import IconTextButton from '../../../../components/IconTextButton';
import Notes from '../../../../components/Notes';
import Popover from '../../../../components/Popover';
import { DescriptionText, TableCell, TableRow } from '../../../../components/Table';
import { DisclaimersChangeLogItem } from '../../../../models/disclaimers.model';
import RevertChangeModal from '../DisclaimersTable/components/RevertChangeModal';
import styles from './changeLogRow.module.scss';
import { REGION_GST } from '../../../../models/user.model';

interface DisclaimersChangeLogRowProps {
  log: DisclaimersChangeLogItem;
  onRevertChange: (log: DisclaimersChangeLogItem) => void;
  region?: string;
}

const DisclaimersChangeLogRow = ({ log, onRevertChange, region }: DisclaimersChangeLogRowProps) => {
  const [openRevertModal, setOpenRevertModal] = useState(false);

  const gstTeam = region === REGION_GST;

  return (
    <TableRow zebra key={log.uid}>
      <TableCell large className={styles.shortCode}>
        {log.token}
      </TableCell>
      <TableCell large className={styles.description}>
        <RTEhtml content={log.description || ''}></RTEhtml>
      </TableCell>
      {gstTeam && (
        <TableCell large className={styles.description}>
          <RTEhtml content={log.description_es || ''}></RTEhtml>
        </TableCell>
      )}
      <TableCell large className={styles.changeType}>
        {log.changeType === 'Token' ? 'Short Code' : log.changeType}
      </TableCell>
      <TableCell large className={styles.changes}>
        <DescriptionText size="lg">
          <ChangeLogChanges changeItem={log} />
        </DescriptionText>
      </TableCell>
      <TableCell large className={styles.modifiedBy}>
        {log.modifiedBy}
      </TableCell>
      <TableCell large className={styles.modifiedDate}>
        <DateComponent format="MM/DD/YYYY hh:mm:ss A">{log.modifiedDate}</DateComponent>
      </TableCell>
      <TableCell large className={styles.notes}>
        {log.notes && (
          <Popover
            toggleElement={<IconTextButton smallIcon icon="circle" text="Notes" />}
            popoverElement={<Notes readOnly notes={log.notes} />}
            align="right"
          />
        )}
      </TableCell>
      <TableCell large className={styles.canRevert}>
        {log.canRevert && (
          <IconTextButton icon="undo" text="Revert" onClick={() => setOpenRevertModal(true)} />
        )}
        <Modal open={openRevertModal} onClose={() => setOpenRevertModal(false)}>
          <RevertChangeModal
            onRevertChange={onRevertChange}
            close={() => setOpenRevertModal(false)}
            changeLogItem={log}
          />
        </Modal>
      </TableCell>
    </TableRow>
  );
};

export default DisclaimersChangeLogRow;
