import { action, computed, observable } from 'mobx';
import { convertToRichTextObject } from 'vapi-ui-common';
import { IDValueType } from '../../../models/common.model';
import { OptionsReviewMap } from '../../../models/options.model';
import { sortBy, toLowerCase } from '../../../utils';
import BaseReviewStore from './baseReviewStore';

class OptionsReviewStore extends BaseReviewStore {
  @observable categories: IDValueType[] = [];
  @observable nameFilters: string[] = [];
  @observable map: OptionsReviewMap = {};

  @computed get filteredReviewItems() {
    const items = Object.values(this.map)
      .slice()
      .sort(sortBy(this.sortField, this.reverse, this.sortField === 'modifiedDate'));
    const lowerSearchText = toLowerCase(this.searchText);
    return items.filter(option => {
      if (this.isInProgressFilter && !option.isInProgress) {
        return false;
      }
      if (
        this.categoryFilters.length &&
        !this.categoryFilters.includes(option.category.before) &&
        !this.categoryFilters.includes(option.category.after)
      ) {
        return false;
      }
      if (lowerSearchText) {
        const valuesToCheck: string[] = [
          option.category.before,
          option.category.after,
          convertToRichTextObject(option.name.before).text,
          convertToRichTextObject(option.name.after).text,
          convertToRichTextObject(option.description.before).text,
          convertToRichTextObject(option.description.after).text,
          option.rejectNotes,
        ];
        for (let val of valuesToCheck) {
          if (toLowerCase(val).includes(lowerSearchText)) {
            return true;
          }
        }
      }

      return !lowerSearchText;
    });
  }

  @action reset() {
    super.reset();
    this.categories = [];
    this.nameFilters = [];
    this.map = {};
  }
}

export default OptionsReviewStore;
