import cx from 'clsx';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { animated, useTransition } from 'react-spring';
import useComponentVisible from '../../hooks/useComponentVisible';
import styles from './popover.module.scss';

export interface PopoverProps {
  popoverClass?: string;
  toggleElement: React.ReactElement;
  popoverElement?: React.ReactElement;
  popoverText?: string;
  align?: 'right' | 'left';
  popoverElementClass?: string;
  defaultOpen?: boolean;
  left?: number;
  attachTo?: Element;
  onShow?: () => void;
  onHide?: () => void;
}

const Popover = ({
  popoverClass,
  toggleElement,
  popoverElement,
  popoverText,
  popoverElementClass,
  align,
  defaultOpen = false,
  left,
  attachTo,
  onShow = () => undefined,
  onHide = () => undefined,
}: PopoverProps) => {
  const {
    ref: refPopOverContainer,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(defaultOpen);
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [x, setX] = useState<number>();
  const [y, setY] = useState<number>();

  const transitions = useTransition(isComponentVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  });

  const setPosition = useCallback(() => {
    if (!ref?.current || !attachTo) return;
    const { offsetTop, offsetLeft, offsetHeight } = ref.current;
    setX(offsetLeft);
    setY(offsetTop + offsetHeight);
  }, [ref, setX, setY, attachTo]);

  useEffect(() => {
    setPosition();
  }, [setPosition]);

  const onOpenPopover = useCallback(() => {
    onShow();
    setIsComponentVisible(true);
  }, [setIsComponentVisible, onShow]);

  const onClosePopover = useCallback(() => {
    onHide();
    setIsComponentVisible(false);
  }, [setIsComponentVisible, onHide]);

  const toggle = useMemo(() => {
    return React.cloneElement(toggleElement, {
      onClick: onOpenPopover,
    });
  }, [toggleElement, onOpenPopover]);

  const popOver = useMemo(() => {
    if (!popoverElement) return null;
    return React.cloneElement(popoverElement, {
      closePopover: onClosePopover,
    });
  }, [popoverElement, onClosePopover]);

  const popOverContainer = useMemo(() => {
    return transitions.map(({ item, key, props }) => {
      if (!item) return null;

      const style = attachTo ? { ...props, top: y, left: x } : props;

      return (
        <animated.div
          ref={refPopOverContainer}
          key={key}
          style={style}
          className={cx(
            {
              [styles.popoverElement]: true,
              [styles.popoverDefault]: true,
            },
            styles[`left-${left}`],
            styles[`align-${align}`],
            popoverElementClass
          )}
        >
          {popOver}
          {popoverText}
        </animated.div>
      );
    });
  }, [
    left,
    align,
    popoverElementClass,
    popoverText,
    popOver,
    transitions,
    y,
    x,
    refPopOverContainer,
    attachTo,
  ]);

  return (
    <div ref={ref} className={cx([popoverClass, styles.popover])} data-testid="popover-comp">
      {toggle}

      {attachTo && createPortal(popOverContainer, attachTo)}
      {!attachTo && popOverContainer}
    </div>
  );
};

export default Popover;
