import {
  OPPOSITE_TEAM_QUERY_SORT,
  OPPOSITE_TEAM_SORT,
  STATUS_DRAFT,
  STATUS_PUBLISHED,
} from '../constants/vehicleData/VDConstants';
import { CommonLanguageVehicle } from '../models/commonLanguage.model';
import { TMNASyncReviewResponse } from '../models/review.model';
import { ISortList, VDSortableEntity } from '../models/sort.model';
import { Language } from '../models/user.model';
import {
  AdminVehicleData,
  DashboardDetail,
  DashboardDetailYear,
  VDInProgressToPublish,
  VehicleTeam,
  VersionResponse,
} from '../models/vehicleData.model';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-admin';

// Data
//
const DATA_PATH = '/data';
export const getDashboardDetails = (brand: string, team: VehicleTeam, isSpanish: boolean) => {
  const param = isSpanish ? 'lang=ES' : '';
  return API.get<DashboardDetail>(
    `${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/dashboard?${param}`
  );
};

export const getVehicleData = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  year: string,
  version?: string,
  lang: Language = Language.EN // TODO remove this and update references accordingly
) => {
  let params = '';

  if (version === 'DRAFT' || !version) {
    params = STATUS_DRAFT;
  } else if (version === 'PUBLISHED') {
    params = STATUS_PUBLISHED;
  } else {
    params = `version=${version}`;
  }
  return API.get<AdminVehicleData>(
    `${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/${seriesId}/${year}/${lang}?${params}`
  );
};

export const addDraft = (
  brand: string,
  team: VehicleTeam,
  payload: { seriesId: string; modelYear: number; lang: string }
) => {
  return API.post<{ status: string; seriesId: string; modelYear: number }>(
    `${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}`,
    payload
  );
};

export const duplicateVehicleData = (
  brand: string,
  team: VehicleTeam,
  payload: { seriesId: string; fromModelYear: number; toModelYear: number; lang: string }
) => {
  return API.put(`${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/duplicate`, payload);
};

export const editVehicleData = (
  brand: string,
  team: VehicleTeam,
  payload: { seriesId: string; modelYear: number; lang: string }
) => {
  return API.put<AdminVehicleData>(`${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}`, payload);
};

export const deleteDraft = (
  brand: string,
  team: VehicleTeam,
  payload: { seriesId: string; modelYear: number; lang: string }
) => {
  return API.delete(`${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}`, { data: payload });
};

export const getAvailableModels = (brand: string, team: VehicleTeam = VehicleTeam.PRODUCT_TEAM) => {
  return API.get<CommonLanguageVehicle[]>(
    `${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/available-models`
  );
};

// Publish Draft
export const publishDraft = (
  brand: string,
  team: string,
  payload: {
    seriesId: string;
    modelYear: number;
    lang?: string;
    publishType?: string;
    inProgressToPublish?: VDInProgressToPublish;
  }
) => {
  return API.post<{
    message: string;
    inProgressESPublishedBeforeEN?: boolean;
  }>(`${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/publish`, payload);
};

export const submitDraft = (
  brand: string,
  team: string,
  payload: { seriesId: string; modelYear: number; langs: string[] }
) => {
  return API.post(`${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/submit`, payload);
};

// Reset Draft
export const resetDraft = (
  brand: string,
  team: string,
  seriesId: string,
  year: number,
  langs: { langs: string[] }
) => {
  return API.post(
    `${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/${seriesId}/${year}/reset-draft`,
    langs
  );
};

export const getChangesCount = (brand: string, team: string, seriesId: string, year: number) => {
  return API.get<number>(
    `${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/${seriesId}/${year}/reset-draft-count`
  );
};

// Accept Changes for Vehicle Data review
export const acceptChangesForVDReview = (
  brand: string,
  team: string,
  version: string,
  payload: { seriesId: string; modelYear: number }
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.post(`${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/review/submit?${params}`, payload);
};

// Accept Changes for Common Language review
export const acceptChangesForCLReview = (
  brand: string,
  team: string,
  version: string,
  seriesId: string,
  modelYear: number
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.post(
    `${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/${seriesId}/${modelYear}/review-cl/submit?${params}`
  );
};

// Versions
//
export const getTeamVersions = (
  brand: string,
  team: string,
  seriesId: string,
  year: string,
  language: string
) => {
  return API.get<VersionResponse[]>(
    `${RESOURCE_PATH}/versions/${brand}/${team}/${seriesId}/${year}/${language}`
  );
};

// Sorting
export const getSortList = (
  brand: string,
  team: string,
  seriesId: string,
  year: string,
  dataType: VDSortableEntity,
  version?: string
) => {
  let params = '';
  if (!version || version === 'DRAFT') {
    params = STATUS_DRAFT;
  } else if (version === OPPOSITE_TEAM_SORT) {
    params = OPPOSITE_TEAM_QUERY_SORT;
  } else {
    params = `version=${version}`;
  }
  return API.get<ISortList>(
    `${RESOURCE_PATH}/sortList/${dataType}/${brand}/${team}/${seriesId}/${year}?${params}`
  );
};

export const updateSortList = (
  brand: string,
  team: string,
  seriesId: string,
  year: string,
  dataType: VDSortableEntity,
  payload: ISortList
) => {
  return API.put<ISortList>(
    `${RESOURCE_PATH}/sortList/${dataType}/${brand}/${team}/${seriesId}/${year}`,
    payload
  );
};

export const getDocumentLink = (
  brand: string,
  payload: { seriesId: string; modelYear: number; version: number; lang: string }
) => {
  return API.post(`${RESOURCE_PATH}/data/${brand}/${VehicleTeam.AGENCY_TEAM}/download`, payload);
};

export const uploadDocument = (brand: string, lang: string, resultJSON: object) => {
  return API.post(
    `${RESOURCE_PATH}/data/${brand}/${VehicleTeam.AGENCY_TEAM}/upload/${lang}`,
    resultJSON
  );
};

export const syncSpanishUpdates = (brand: string, team: string, seriesId: string, year: string) => {
  return API.post<DashboardDetailYear>(
    `${RESOURCE_PATH}/spanish-sync/${brand}/${team}/${seriesId}/${year}`
  );
};

export const compareItem = (
  brand: string,
  team: string,
  seriesId: string,
  year: string,
  entityType: string,
  itemId: string,
  parentSeriesId: string = ''
) => {
  const body: any = { entityType, itemId };
  if (parentSeriesId) {
    body.parentSeriesId = parentSeriesId;
  }
  return API.post<TMNASyncReviewResponse>(
    `${RESOURCE_PATH}/compare/${brand}/${team}/${seriesId}/${year}`,
    body
  );
};
