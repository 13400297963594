import React, { useState } from 'react';
import { ActionButton, RTEditorProps } from 'vapi-ui-common';
import styles from './dropdownEditItem.module.scss';

export interface DropdownEditItemProps extends RTEditorProps {
  value: string;
  isSelected: boolean;
  onSelect: () => void;
  onEdit?: (oldValue: string, newValue: string) => void;
  onClose?: () => void;
}

const DropdownEditItem = ({
  value,
  isSelected,
  onEdit,
  onSelect,
  onClose = () => void 0,
}: DropdownEditItemProps) => {
  const [editOpen, setEditOpen] = useState(false);
  const [itemVal, setItemVal] = useState(value);
  const [inputVal, setInputVal] = useState(value);

  const handleOnEdit = () => {
    if (inputVal && inputVal.trim() && value !== inputVal.trim()) {
      setItemVal(inputVal.trim());
      onEdit && onEdit(value, inputVal.trim());
      setEditOpen(false);
    }
  };

  return (
    <li className={isSelected ? styles.selected : ''} data-testid="dropdown-edit-item-comp">
      {!editOpen ? (
        <>
          <div
            className={styles.value}
            onClick={() => {
              onSelect();
              onClose();
            }}
          >
            {itemVal}
          </div>
          {onEdit && (
            <div className={styles.editBtn}>
              <ActionButton
                icon="edit"
                onClick={() => {
                  setEditOpen(true);
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className={styles.inputGroup}>
          <input
            className={styles.input}
            type="text"
            value={inputVal}
            onChange={e => setInputVal(e.currentTarget.value)}
          />
          <button className={styles.inputBtn} onClick={() => handleOnEdit()}>
            Save
          </button>
        </div>
      )}
    </li>
  );
};

export default DropdownEditItem;
