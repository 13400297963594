import React from 'react';
import { Language } from '../../models/user.model';
import styles from './syncUpdatesPopover.module.scss';

export interface ISyncUpdatesPopover {
  lastSyncDates: { [lang: string]: string };
  lastPublishedDates: { [lang: string]: string };
}

const SyncUpdatesPopoverContent = ({ lastSyncDates, lastPublishedDates }: ISyncUpdatesPopover) => {
  const getDateString = (date: string) => {
    const parsedDate = new Date(date).toLocaleDateString();
    return parsedDate === 'Invalid Date' ? 'N/A' : parsedDate;
  };

  const syncDates = () => {
    const syncDateDivs: React.ReactNode[] = [];
    const langs = Object.keys(lastSyncDates);
    for (const lang of langs) {
      if (langs.length === 1) {
        syncDateDivs.push(<div>{`Last Synced: ${getDateString(lastSyncDates[lang])}`}</div>);
      } else {
        syncDateDivs.push(
          <div key={lang}>{`${
            lang === Language.EN ? 'TMNA' : 'Spanish'
          } Last Synced: ${getDateString(lastSyncDates[lang])}`}</div>
        );
      }
    }
    return syncDateDivs;
  };

  const publishedDates = () => {
    const publishedDateDivs: React.ReactNode[] = [];
    const langs = Object.keys(lastPublishedDates);
    for (const lang of langs) {
      if (langs.length === 1) {
        publishedDateDivs.push(
          <div>{`Last NAT English Published: ${getDateString(lastPublishedDates[lang])}`}</div>
        );
      } else {
        publishedDateDivs.push(
          <div key={lang}>{`${
            lang === Language.EN ? 'TMNA' : 'Spanish'
          } Last Published: ${getDateString(lastPublishedDates[lang])}`}</div>
        );
      }
    }

    return publishedDateDivs;
  };

  return (
    <section className={styles.dateSection} data-testid="sync-updates-popover-content">
      {syncDates()}
      {publishedDates()}
    </section>
  );
};

export default SyncUpdatesPopoverContent;
