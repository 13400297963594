import { observer } from 'mobx-react-lite';
import React from 'react';
import { toast } from 'react-toastify';
import { ActionBar, Status } from 'vapi-ui-common';
import { TableTabs } from '../../../../components/Table/components/TableTabs';
import { uiBlock } from '../../../../components/UiBlocker/uiBlock';
import useStores from '../../../../hooks/useStores';
import { ExteriorColorsChangeTypeMap } from '../../../../models/colors.model';
import ActionBarFiltersSection from '../../../../routes/vehicleData/components/ActionBarFiltersSection';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import ColorsFilters from '../../../../routes/vehicleData/tabModules/colors/components/ColorsFilters';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { updateReviewExteriorColors } from '../../../../webservices/vehicleColorsApi';
import ColorsExteriorReviewTable from './ColorsExteriorReviewTable';
import { toGqlBrand, toGqlTeam, toGqlFilter } from '../../../../utils/graphqlUtils';

const ColorsExteriorReview = ({
  seriesId,
  year,
  version = '',
  vehicleModels,
}: ProductDataControllerProps) => {
  const {
    colorsReviewStore,
    colorsExteriorReviewStore,
    teamStore,
    userStore: { brand },
  } = useStores();

  const handleOnMapApplyChange = async (map: ExteriorColorsChangeTypeMap, value: boolean) => {
    map.isApplied = value;
    saveMap(map);
  };

  const handleOnMapNotesChange = async (map: ExteriorColorsChangeTypeMap, value: string) => {
    map.rejectNotes = value;
    saveMap(map);
  };

  const saveMap = async (map: ExteriorColorsChangeTypeMap) => {
    try {
      if (colorsExteriorReviewStore.isMapValid(map, teamStore.team.param)) {
        uiBlock.start();
        const response = await updateReviewExteriorColors({
          brand: toGqlBrand(brand),
          team: toGqlTeam(teamStore.team.param),
          seriesId,
          modelYear: Number(year),
          filter: toGqlFilter(version ?? Status.DRAFT),
          payload: colorsExteriorReviewStore.getReviewInputPayload(map, teamStore.team.param),
        });
        map.revId = response.revId;
        toast.success('Successfully updated feature');
      } else {
        toast.error('Please fill in the required fields for the feature');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating feature');
    }

    uiBlock.stop();
  };

  return (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly
          searchText={colorsExteriorReviewStore.searchText}
          onSearchTextChange={text => (colorsExteriorReviewStore.searchText = text)}
          buttons={[]}
          renderFilter={onClose => (
            <ColorsFilters
              onClose={onClose}
              isInProgressFilter={colorsExteriorReviewStore.isInProgressFilter}
              setIsInProgressFilter={value =>
                (colorsExteriorReviewStore.isInProgressFilter = value)
              }
              isExtraCostColorFilter={colorsExteriorReviewStore.isExtraCostColorFilter}
              setIsExtraCostColorFilter={value =>
                (colorsExteriorReviewStore.isExtraCostColorFilter = value)
              }
              isReviewPage={true}
            />
          )}
        />
      </ActionBar>
      <TableTabs
        style={{ zIndex: 1 }}
        tabs={colorsReviewStore.tabs}
        currentTab={colorsReviewStore.selectedTab}
        onSelectTab={value => (colorsReviewStore.selectedTab = value)}
      />
      <ColorsExteriorReviewTable
        vehicleModels={vehicleModels}
        handleOnApplyChange={handleOnMapApplyChange}
        handleOnNotesChange={handleOnMapNotesChange}
      />
    </>
  );
};

export default observer(ColorsExteriorReview);
