import { action, computed, observable, observe } from 'mobx';
import {
  Series,
  SeriesInfo,
  SeriesInfoItem,
  SpanishDraftMap,
  UserSeries,
} from '../../models/vehicleData.model';

export class VehicleDataStoreTest {
  @observable dashboardSeries: SeriesInfoItem[] = [];
  @observable dashboardSearchText = '';
  @observable series = {} as Series;
  @observable userSeries: UserSeries[] = [];
  @observable searchText = '';
  @observable spanishDraftMap: SpanishDraftMap = {};

  constructor() {
    observe(this, change => {
      if (change.name === 'dashboardSeries') {
        this.resetDraftMap();
      }
    });
  }

  resetDraftMap = () => {
    let spanishDraftMap = {} as SpanishDraftMap;
    for (const series of this.dashboardSeries) {
      spanishDraftMap[series.id] = {};
      series.modelYears.forEach(year => {
        if (!spanishDraftMap[series.id][year.year]) {
          spanishDraftMap[series.id][year.year] = false;
        }
        if (year.isDraft) {
          spanishDraftMap[series.id][year.year] = true;
        }
      });
    }
    this.spanishDraftMap = spanishDraftMap;
  };

  addDraft = (seriesId: string, modelYear: number) => {
    this.dashboardSeries = this.dashboardSeries.map(item =>
      item.id === seriesId
        ? {
            ...item,
            modelYears: [
              ...item.modelYears,
              {
                year: modelYear,
                isDraft: true,
                datePublished: '',
                version: 1,
              },
            ],
          }
        : item
    );
  };

  deleteDraft = (seriesId: string, modelYear: number) => {
    this.dashboardSeries = this.dashboardSeries.map(item =>
      item.id === seriesId
        ? {
            ...item,
            modelYears: item.modelYears.filter(modelObj => modelObj.year !== modelYear),
          }
        : item
    );
  };

  @computed get filteredDashboardData() {
    const loweredSearchText = this.searchText.toLowerCase();
    return this.dashboardSeries.filter(
      item => item.seriesName.toLowerCase().indexOf(loweredSearchText) !== -1
    );
  }

  @action reset() {
    this.dashboardSeries = [];
    this.dashboardSearchText = '';
    this.series = {} as Series;
    this.userSeries = [];
    this.searchText = '';
  }

  findSeriesByName = (name: string) =>
    Object.values(this.series).find(
      (serie: SeriesInfo) => serie.name.toLowerCase() === name.toLowerCase()
    );
}
