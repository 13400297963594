import React from 'react';
import { HeaderRow, LeftTableTH, Thead } from '../../../../components/Table';
import styles from './seriesCategoryHeader.module.scss';

const SeriesCategoryHeader = () => {
  return (
    <Thead>
      <HeaderRow>
        <LeftTableTH className={styles.seriesColumn}>Series Category</LeftTableTH>
        <LeftTableTH className={styles.seriesColumnES}>Series Category - ES</LeftTableTH>
        <LeftTableTH className={styles.seriesColumnSeriesId}>Series Category - ID</LeftTableTH>
      </HeaderRow>
    </Thead>
  );
};

export default SeriesCategoryHeader;
