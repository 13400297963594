import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { useState } from 'react';
import { ActionItemModal, ActionItemType, Dropdown, DropdownContent, Modal } from 'vapi-ui-common';
import { TableCell } from '../../../../../components/Table';
import useComponentVisible from '../../../../../hooks/useComponentVisible';
import styles from './contextMenuCell.module.scss';

interface ContextMenuCellProps {
  description: string;
  itemType: ActionItemType;
  deleteItem?(): void;
  copyItem?(): void;
  splitItem?(): void;
  splitText?: string;
  deleteText?: string;
}

const ContextMenuCell = ({
  description,
  itemType,
  deleteItem,
  copyItem,
  splitItem,
  splitText,
  deleteText = 'Delete',
}: ContextMenuCellProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleOnCopy = () => {
    setIsComponentVisible(false);
    copyItem && copyItem();
  };

  const handleOnDelete = () => {
    setIsComponentVisible(false);
    deleteItem && setShowDeleteModal(true);
  };

  const handleOnSplit = () => {
    setIsComponentVisible(false);
    splitItem && splitItem();
  };

  return (
    <TableCell>
      <div ref={ref}>
        <Dropdown>
          <FontAwesomeIcon
            className={cx(styles.dragIcon, isComponentVisible && styles.open)}
            icon={faEllipsisV}
            onClick={() => setIsComponentVisible(true)}
          />
          <DropdownContent open={isComponentVisible}>
            {copyItem && (
              <div className={styles.listItem} onClick={() => handleOnCopy()}>
                Copy
              </div>
            )}
            {deleteItem && (
              <div className={styles.listItem} onClick={() => handleOnDelete()}>
                {deleteText}
              </div>
            )}
            {splitItem && (
              <div className={styles.listItem} onClick={() => handleOnSplit()}>
                {splitText}
              </div>
            )}
          </DropdownContent>
        </Dropdown>

        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <ActionItemModal
            itemType={itemType}
            onConfirm={() => deleteItem && deleteItem()}
            description={description}
            onClose={() => setShowDeleteModal(false)}
          />
        </Modal>
      </div>
    </TableCell>
  );
};

export default ContextMenuCell;
