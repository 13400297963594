import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Checkbox from '../../../../components/Checkbox';
import { Radio, RadioGroup } from '../../../../components/Radio';
import useStores from '../../../../hooks/useStores';
import { GradeApplicabilityItem, GradeApplicabilityText } from '../../../../models/features.model';
import styles from './styles.module.scss';

const isGradeEmpty = (gradeAppItem: GradeApplicabilityItem) => {
  //flags to be used in the isEmptyGrade check
  const hasApplicabilityText =
    gradeAppItem.applicabilityText && Object.keys(gradeAppItem.applicabilityText).length !== 0;
  const onlyOneDEFAULTApplicabilityText =
    gradeAppItem.applicabilityText &&
    Object.keys(gradeAppItem.applicabilityText).length === 1 &&
    !!gradeAppItem.applicabilityText['default'];

  //used to flag if we should show the floating radio group
  const isEmptyGrade =
    gradeAppItem.isComparable && (!hasApplicabilityText || onlyOneDEFAULTApplicabilityText);
  return {
    isEmptyGrade,
    hasApplicabilityText,
  };
};

type Props = {
  id: string;
  gradeAppItem: GradeApplicabilityItem;
  onSave?: (gradeAppItem: GradeApplicabilityItem) => void;
  readOnly?: boolean;
  showValidation?: boolean;
  runValidation: () => { isValid: boolean; validation: { [id: string]: boolean } };
};

const GradeInfo = ({
  id,
  gradeAppItem,
  readOnly,
  onSave = () => {},
  showValidation,
  runValidation,
}: Props) => {
  const [showSubTree, setShowSubTree] = useState(true);
  const { validation } = runValidation();
  const [validationOBJ, setValidation] = useState(validation);
  const { vehicleModelsStore } = useStores();

  const toggleSubTree = (e: React.MouseEvent) => {
    setShowSubTree(!showSubTree);
    e.stopPropagation();
  };

  const handleDeleteText = (key: string, parent: GradeApplicabilityText) => {
    delete parent[key];
    onSave(gradeAppItem);
  };

  const handleUpdateText = (id: string, text: string) => {
    gradeAppItem.applicabilityText[id].text = text;
    onSave(gradeAppItem);
  };

  const addEmptyApplicabilityText = () => {
    delete gradeAppItem.applicabilityText['default'];
    gradeAppItem.applicabilityText[uuidv4()] = {
      text: '',
      availability: gradeAppItem.applicabilityText['default']?.availability || '',
    };
    onSave(gradeAppItem);
  };
  const handleValidation = () => {
    const { validation } = runValidation();
    setValidation(validation);
  };

  const addEmptyDefaultApplicabilityText = () => {
    gradeAppItem.applicabilityText['default'] = {
      text: '',
      availability: '',
    };
  };
  const { isEmptyGrade, hasApplicabilityText } = isGradeEmpty(gradeAppItem);
  //check to see if there's a place for the floating radio group to be.
  if (isEmptyGrade && !hasApplicabilityText) {
    //if not, then add it.
    addEmptyDefaultApplicabilityText();
  }

  return (
    <div className={cx(styles.flexy, styles.spaceAround)}>
      <Checkbox
        id={`${id}isHighlighted`}
        checked={gradeAppItem.highlighted}
        onChange={e => {
          gradeAppItem.highlighted = e.currentTarget.checked;
          handleValidation()
          onSave(gradeAppItem);
        }}
        data-key="isHighlightedCheckbox"
        disabled={readOnly}
      ></Checkbox>

      <div
        className={styles.gradeRow}
        data-key="GradeRow"
        data-name={vehicleModelsStore.getGradeById(id)}
      >
        <Checkbox
          id={`${id}isComparable`}
          checked={gradeAppItem.isComparable}
          onChange={e => {
            gradeAppItem.isComparable = e.currentTarget.checked;
            handleValidation()
            onSave(gradeAppItem);
          }}
          className={cx(styles.gradeCheckbox, styles.checkbox)}
          data-key="isApplicableCheckbox"
          disabled={readOnly}
        >
          {vehicleModelsStore.getGradeById(id)}
        </Checkbox>

        <i
          className={cx(styles.arrow, showSubTree ? styles.down : styles.up)}
          onClick={toggleSubTree}
          data-key="toggle subtree button"
        ></i>
        {showSubTree && (
          <div className={styles.applicabilityLabels} data-key="Applicability Headers">
            <div className={styles.s}>S</div>
            <div>A</div>
          </div>
        )}

        {/* S/A Radio buttons */}
        {isEmptyGrade && (
          <div className={cx(styles.defaultApplicability, !showSubTree && styles.inlineRadios)}>
            <RadioGroup
              className={cx(
                styles.flexy,
                showValidation && !validationOBJ[`${id}_default`] && styles.error
              )}
              name={`default-${id}`}
              selectedValue={gradeAppItem.applicabilityText['default'].availability}
              onChange={e => {
                gradeAppItem.applicabilityText['default'].availability = e;
                handleValidation();
              }}
            >
              <Radio
                key={`default-S-availability`}
                className={styles.actionBarRadio}
                value="s"
                label=""
                disabled={readOnly}
              />
              <Radio key={`default-A-availability`} label="" value="a" disabled={readOnly} />
            </RadioGroup>
          </div>
        )}

        {/* Applicability Text */}
        <div className={styles.gutters}>
          {showSubTree &&
            gradeAppItem.applicabilityText &&
            Object.keys(gradeAppItem.applicabilityText).map((textId: string) => {
              if (textId === 'default') {
                return false;
              }
              return (
                <div className={cx(styles.flexy, styles.applicabilityTextSection)} key={textId}>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className={styles.deleteButton}
                    onClick={e => handleDeleteText(textId, gradeAppItem.applicabilityText)}
                  />
                  <span className={cx(styles.tenRight, styles.applicabilityText)}>
                    Applicability Text
                  </span>
                  <input
                    className={styles.tenRight}
                    defaultValue={gradeAppItem.applicabilityText[textId].text || ''}
                    onChange={e => {
                      handleUpdateText(textId, e.currentTarget.value.trim());
                    }}
                    data-key="ApplicabilityText"
                    disabled={readOnly}
                  ></input>
                  {/* these need to be visible even if there is no text. */}
                  <pre>{validationOBJ[`${id}_${textId}`]}</pre>
                  <RadioGroup
                    className={styles.flexy}
                    name={textId}
                    selectedValue={gradeAppItem.applicabilityText[textId].availability}
                    onChange={e => {
                      gradeAppItem.applicabilityText[textId].availability = e;
                      handleValidation();
                    }}
                  >
                    <Radio
                      key={`${textId}S-availability`}
                      className={cx(
                        styles.actionBarRadio,
                        showValidation && !validationOBJ[`${id}_${textId}`] && styles.error
                      )}
                      value="s"
                      label=""
                      disabled={readOnly}
                    />
                    <Radio
                      key={`${textId}A-availability`}
                      label=""
                      value="a"
                      disabled={readOnly}
                      className={cx(showValidation && !validationOBJ[`${id}_${textId}`] && styles.error)}
                    />
                  </RadioGroup>
                </div>
              );
            })}

          {showSubTree && !readOnly && (
            <div
              className={cx(styles.subText, styles.crowdUp, styles.clickable)}
              onClick={addEmptyApplicabilityText}
              data-key="AddEmptyApplicabilityText"
            >
              <b className={styles.applicabilityTextPlus}>+</b> Applicability Text
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default observer(GradeInfo);
