import { observer } from 'mobx-react-lite';
import React from 'react';
import IconTextButton from '../../../../../components/IconTextButton';
import { HeaderCell, HeaderRow, Thead } from '../../../../../components/Table';
import { BRAND_LEXUS, Brand } from '../../../../../models/user.model';
import styles from '../seriesSettings.module.scss';

type SeriesSettingsHeaderRowProps = {
  brand: Brand;
  toggleAddSeries: (add: boolean) => void;
  readOnly?: boolean;
  canAddNewSeriesSettings: boolean;
};

const SeriesSettingsHeaderRow: React.FC<SeriesSettingsHeaderRowProps> = ({
  brand,
  toggleAddSeries,
  readOnly,
  canAddNewSeriesSettings,
}) => {
  const isLexus = brand === BRAND_LEXUS;

  return (
    <Thead>
      <HeaderRow>
        <HeaderCell cellClassName={styles.codeRedHeader}>Code Red</HeaderCell>
        <HeaderCell>Series Name</HeaderCell>
        <HeaderCell>Fuel Type</HeaderCell>
        <HeaderCell>Series Category</HeaderCell>

        {isLexus && <HeaderCell cellClassName={styles.convertibleHeader}>Convertible?</HeaderCell>}

        {!isLexus && <HeaderCell cellClassName={styles.seatingHeader}>Seating</HeaderCell>}

        {!isLexus && (
          <HeaderCell cellClassName={styles.mileageHeader}>Estimated Mileage</HeaderCell>
        )}

        {!isLexus && <HeaderCell>MPGe</HeaderCell>}

        <HeaderCell align="right" cellClassName={styles.actionsHeader}>
          {!readOnly && canAddNewSeriesSettings && (
            <IconTextButton
              icon="plus"
              text="Add Series"
              smallIcon
              onClick={() => {
                toggleAddSeries(true);
              }}
            />
          )}
        </HeaderCell>
      </HeaderRow>
    </Thead>
  );
};

export default observer(SeriesSettingsHeaderRow);
