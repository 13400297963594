import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ActionBarDivider, areRichTextValuesDifferent } from 'vapi-ui-common';
import IconTextButton from '../../../../components/IconTextButton';
import Spinner from '../../../../components/Spinner';
import { TwoTableWrapper } from '../../../../components/Table';
import { TableTabs } from '../../../../components/Table/components/TableTabs';
import SortButton from '../../../../components/sortModule/SortButton';
import { Language } from '../../../../gql/generated';
import useDebounce from '../../../../hooks/useDebounce';
import useQuery from '../../../../hooks/useQuery';
import useStores from '../../../../hooks/useStores';
import {
  OptionsPackage,
  OptionsPackageSpec,
  OptionsPackageVariety,
  OptionsTabType,
} from '../../../../models/optionsLexus.model';
import { VDSortableEntity } from '../../../../models/sort.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import ActionBarVehicleData from '../../../../routes/vehicleData/components/ActionBarVehicleData';
import LeftTable from '../../../../routes/vehicleData/components/LeftTable';
import ModelTable from '../../../../routes/vehicleData/components/ModelTable';
import SettingsCell from '../../../../routes/vehicleData/components/ModelTable/components/SettingsCell';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { tokensXForm } from '../../../../utils/disclaimersUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { toGqlBrand, toGqlTeam } from '../../../../utils/graphqlUtils';
import { getSortPayload } from '../../../../utils/sortUtils';
import { updateSortList } from '../../../../webservices/vehicleAdminApi';
import {
  addOptionLexus,
  addPackageLexus,
  addSpec,
  addVariety,
  deleteOption,
  deleteSpec,
  deleteVariety,
  updateOptionLexus,
  updatePackageLexus,
  updateSpec,
  updateVariety,
} from '../../../../webservices/vehicleOptionsApi';
import OptionsLexusDividerRow from './components/OptionsLexusDividerRow';
import OptionsLexusFilters from './components/OptionsLexusFilters';
import OptionsLexusHeaderRow from './components/OptionsLexusHeaderRow';
import OptionsLexusPackageRowsContainer from './components/OptionsLexusPackageRow/OptionsLexusPackageRowsContainer';
import PackageTableRow from './components/PackageTableRow';
import { PackageTotalCell, PackageTotalRow } from './components/totalRows';

const OptionsLexusController = ({
  isPublished,
  readOnly,
  team,
  seriesId,
  year,
  vehicleModels,
  versionInfo,
}: ProductDataControllerProps) => {
  const {
    optionsLexusStore,
    teamStore,
    userStore: { brand },
    disclaimersStore: { tokens },
  } = useStores();

  const query = useQuery();
  const { debounce } = useDebounce({ delay: 2000 });
  const optionsTabParam = (query.get('optionsTab') || 'packages') as OptionsTabType;
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [tabDisplay, setTabDisplay] = useState(
    optionsTabParam === 'options' ? 'Options' : 'Packages'
  );
  const [optionsTab, setOptionsTab] = useState<OptionsTabType>(optionsTabParam);
  const [sortMode, setSortMode] = useState(false);
  const [rerenderPackages, setRerenderPackages] = useState(false);
  const history = useHistory();

  const disclaimerTokens = tokensXForm(tokens);
  const version = versionInfo.EN ? versionInfo.EN.toString() : undefined;

  useEffect(() => {
    setIsLoaded(false);
    optionsLexusStore.reset();

    (async () => {
      try {
        await optionsLexusStore.fetchData(
          brand,
          team,
          seriesId,
          year,
          vehicleModels,
          optionsTab,
          true,
          version
        );
        optionsLexusStore.showTooltips =
          optionsTab === 'options' && teamStore.team.allowOptionTooltips;
        setHasLoadingError(false);
      } catch (e) {
        toast.error('Error loading options data');
        setHasLoadingError(true);
      }

      setIsLoaded(true);
    })();
  }, [
    optionsTab,
    brand,
    teamStore,
    optionsLexusStore,
    seriesId,
    team,
    vehicleModels,
    year,
    version,
  ]);

  const showFormFieldError = (isOption: boolean) => {
    isOption
      ? toast.error('Please finish filling out all items of the new option.')
      : toast.error('Please finish filling out all items of the new package.');
  };

  const updateOptionItem = async (option: OptionsPackage) => {
    try {
      debounce(async () => {
        try {
          const response = await trackPromise(
            optionsTab === 'options'
              ? updateOptionLexus({
                  brand: toGqlBrand(brand),
                  team: toGqlTeam(team),
                  seriesId: seriesId,
                  modelYear: parseInt(year),
                  payload: option.getUpdateOptionPayload(),
                })
              : updatePackageLexus({
                  brand: toGqlBrand(brand),
                  team: toGqlTeam(team),
                  seriesId: seriesId,
                  modelYear: parseInt(year),
                  payload: option.getUpdatePackagePayload(),
                })
          );
          option.revId = response.revId;
          toast.success('Option updated successfully');
        } catch (e) {
          handleErrorResponse(e, 'Option failed update');
          showFormFieldError(option.isOption);
        }
      }, option.uid);
    } catch (e) {
      handleErrorResponse(e, 'Option failed update');
      showFormFieldError(option.isOption);
    }
  };

  const addOptionItem = async (option: OptionsPackage) => {
    try {
      debounce(async () => {
        try {
          const response = await trackPromise(
            optionsTab === 'options'
              ? addOptionLexus({
                  brand: toGqlBrand(brand),
                  team: toGqlTeam(team),
                  seriesId: seriesId,
                  modelYear: parseInt(year),
                  payload: option.getCreateOptionPayload(),
                })
              : addPackageLexus({
                  brand: toGqlBrand(brand),
                  team: toGqlTeam(team),
                  seriesId: seriesId,
                  modelYear: parseInt(year),
                  payload: option.getCreatePackagePayload(),
                })
          );
          option.id = response.id;
          option.revId = response.revId;
          toast.success('Option added successfully');
        } catch (e) {
          handleErrorResponse(e, 'Option failed add');
        }
      }, option.uid);
    } catch (e) {
      handleErrorResponse(e, 'Option failed add');
    }
  };

  const saveOptionItem = async (option: OptionsPackage) => {
    if (option.id) {
      updateOptionItem(option);
    } else {
      addOptionItem(option);
    }
  };

  const deleteOptionItem = async (option: OptionsPackage) => {
    try {
      if (option.id) {
        await trackPromise(
          deleteOption({
            brand: toGqlBrand(brand),
            team: toGqlTeam(team),
            seriesId: seriesId,
            modelYear: parseInt(year),
            language: Language.En,
            deleteVehicleOptionId: option.id,
            optionTab: optionsTab,
          })
        );
      }

      optionsLexusStore.deleteItem(option);
      setRerenderPackages(!rerenderPackages);
      toast.success('Option deleted sucessfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting option');
    }
  };

  const saveSpecItem = async (option: OptionsPackage, spec: OptionsPackageSpec) => {
    const isAdding = !spec.id;
    const actionText = isAdding ? 'added' : 'updated';

    try {
      debounce(async () => {
        try {
          const response = isAdding
            ? await trackPromise(
                addSpec({
                  brand: toGqlBrand(brand),
                  team: toGqlTeam(team),
                  seriesId: seriesId,
                  modelYear: parseInt(year),
                  payload: spec.getCreatePayload(option.id, option.revId),
                  optionTab: optionsTab,
                })
              )
            : await trackPromise(
                updateSpec({
                  brand: toGqlBrand(brand),
                  team: toGqlTeam(team),
                  seriesId: seriesId,
                  modelYear: parseInt(year),
                  payload: spec.getUpdatePayload(option.id, option.revId),
                  optionTab: optionsTab,
                })
              );
          spec.id = response.id;
          spec.revId = response.revId;
          toast.success(`Spec ${actionText} successfully`);
        } catch (e) {
          handleErrorResponse(e, `Error option ${actionText} spec`);
        }
      }, spec.uid);
      setRerenderPackages(!rerenderPackages);
    } catch (e) {
      handleErrorResponse(e, `Error option ${actionText} spec`);
    }
  };

  const deleteSpecItem = async (option: OptionsPackage, spec: OptionsPackageSpec) => {
    try {
      if (spec.id) {
        await trackPromise(
          deleteSpec({
            brand: toGqlBrand(brand),
            team: toGqlTeam(team),
            seriesId: seriesId,
            modelYear: parseInt(year),
            parentId: option.id,
            specId: spec.id,
            optionTab: optionsTab,
          })
        );
      }

      optionsLexusStore.deleteSpecItem(option, spec);
      setRerenderPackages(!rerenderPackages);
      toast.success('Option spec deleted successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting option spec');
    }
  };

  const saveVarietyItem = async (option: OptionsPackage, variety: OptionsPackageVariety) => {
    const isAdding = !variety.id;
    const actionText = isAdding ? 'added' : 'updated';
    try {
      debounce(async () => {
        try {
          const response = isAdding
            ? await trackPromise(
                addVariety({
                  brand: toGqlBrand(brand),
                  team: toGqlTeam(team),
                  seriesId: seriesId,
                  modelYear: parseInt(year),
                  payload: variety.getCreatePayload(option.id, option.revId),
                })
              )
            : await trackPromise(
                updateVariety({
                  brand: toGqlBrand(brand),
                  team: toGqlTeam(team),
                  seriesId: seriesId,
                  modelYear: parseInt(year),
                  payload: variety.getUpdatePayload(option.id, option.revId),
                })
              );

          variety.id = response.id;
          variety.revId = response.revId;
          toast.success(`Variety ${actionText} successfully`);
        } catch (e) {
          handleErrorResponse(e, `Error variety ${actionText}`);
        }
      }, variety.uid);
    } catch (e) {
      handleErrorResponse(e, `Error variety ${actionText}`);
    }
  };

  const deleteVarietyItem = async (option: OptionsPackage, variety: OptionsPackageVariety) => {
    try {
      if (variety.id) {
        await trackPromise(
          deleteVariety({
            brand: toGqlBrand(brand),
            team: toGqlTeam(team),
            seriesId: seriesId,
            modelYear: parseInt(year),
            parentId: option.id,
            specId: variety.id,
          })
        );
        optionsLexusStore.deleteVarietyItem(option, variety);
      } else {
        optionsLexusStore.deleteVarietyItem(option, variety);
      }
      setRerenderPackages(!rerenderPackages);
      toast.success('Variety deleted successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting variety');
    }
  };

  const addEmptyOptionItem = () => {
    optionsLexusStore.addItem(vehicleModels, optionsTab === 'options');
    setRerenderPackages(!rerenderPackages);
    addOptionItem(optionsLexusStore.options[0]);
  };

  const addEmptySpecItem = (option: OptionsPackage) => {
    optionsLexusStore.addSpecItem(option, vehicleModels);
    setRerenderPackages(!rerenderPackages);
  };

  const addEmptyVarietyItem = (option: OptionsPackage) => {
    const newVariety = optionsLexusStore.addVarietyItem(option, vehicleModels);
    setRerenderPackages(!rerenderPackages);
    saveVarietyItem(option, newVariety);
  };

  const onStopSorting = async () => {
    setSortMode(false);
    try {
      const payload = getSortPayload(optionsLexusStore.filteredOptions);
      await trackPromise(
        updateSortList(
          brand,
          team,
          seriesId,
          year,
          optionsTabParam === 'options' ? VDSortableEntity.OPTIONS : VDSortableEntity.PACKAGES,
          payload
        )
      );
      setIsLoaded(false);
      await optionsLexusStore.fetchData(
        brand,
        team,
        seriesId,
        year,
        vehicleModels,
        optionsTab,
        true,
        version
      );
      setIsLoaded(true);
    } catch (e) {
      handleErrorResponse(e, 'Error updating feature sort');
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = optionsLexusStore.filteredOptions.splice(result.source.index, 1);
    optionsLexusStore.filteredOptions.splice(result.destination.index, 0, removed);
    optionsLexusStore.filteredOptions.forEach((item: { sortOrder: number }, index: number) => {
      item.sortOrder = index + 1;
    });
    return optionsLexusStore.filteredOptions;
  };

  const getActionBarButtons = () => {
    const actionBarButtons: React.ReactNode[] = [];
    if (!isPublished) {
      actionBarButtons.push(
        <IconTextButton
          icon="plus"
          text={optionsTabParam === 'options' ? 'Add Option' : 'Add Package'}
          onClick={() => addEmptyOptionItem()}
        />
      );
      actionBarButtons.push(
        sortMode ? (
          <SortButton toggled onClick={onStopSorting}>
            Stop Sorting
          </SortButton>
        ) : (
          <SortButton
            onClick={() => {
              optionsLexusStore.clearFilters();
              setSortMode(true);
            }}
          >
            Sort
          </SortButton>
        )
      );
    }

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  const hasNoApplicability = (index: any, specs: any) => {
    let isApplicabilityNotValid = true;

    for (let i = 0; i < specs.length; i++) {
      if (specs[i].models[index].setting) {
        isApplicabilityNotValid = false;
        break;
      }
    }

    return isApplicabilityNotValid;
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBarVehicleData
        readOnly={readOnly}
        toggleViewModelCodes={() =>
          (optionsLexusStore.viewModelCodes = !optionsLexusStore.viewModelCodes)
        }
        viewModelCodes={optionsLexusStore.viewModelCodes}
        searchText={optionsLexusStore.searchText}
        onSearchTextChange={text =>
          optionsLexusStore.onFilter(() => (optionsLexusStore.searchText = text))
        }
        renderButtons={getActionBarButtons()}
        renderFilter={onClose => (
          <OptionsLexusFilters
            onClose={onClose}
            isInProgressFilter={optionsLexusStore.isInProgressFilter}
            setIsInProgressFilter={value =>
              optionsLexusStore.onFilter(() => (optionsLexusStore.isInProgressFilter = value))
            }
            isReviewNotesFilter={optionsLexusStore.isReviewNotesFilter}
            setIsReviewNotesFilter={
              team === VehicleTeam.AGENCY_TEAM && version == null
                ? value =>
                    optionsLexusStore.onFilter(
                      () => (optionsLexusStore.isReviewNotesFilter = value)
                    )
                : undefined
            }
          />
        )}
      />
      <TableTabs
        tabs={['Packages', 'Options']}
        currentTab={tabDisplay}
        onSelectTab={value => {
          const valLower = value.toLowerCase();
          const version = query.get('version') ? `&version=${query.get('version')}` : '';
          setTabDisplay(value);
          setOptionsTab(valLower as OptionsTabType);
          history.push(
            `?team=${query.get('team')}${version}&tab=${query.get('tab')}&optionsTab=${valLower}`
          );
          optionsLexusStore.clearFilters();
        }}
        style={{ zIndex: 1 }}
      />
      {hasLoadingError ? null : (
        <TwoTableWrapper>
          <DragDropContext onDragEnd={onDragEnd}>
            <LeftTable>
              <OptionsLexusHeaderRow viewModelCodes={true} sortMode={sortMode} />
              <OptionsLexusPackageRowsContainer
                options={optionsLexusStore.filteredOptions}
                rerender={rerenderPackages}
                sortMode={sortMode}
                saveVariety={saveVarietyItem}
                deleteVariety={deleteVarietyItem}
                disabled={readOnly}
                optionsTab={optionsTab}
                allowTokens={teamStore.team.allowDisclaimerTokens}
                tokens={disclaimerTokens}
                showTooltip={optionsLexusStore.showTooltips}
                addOption={addOptionItem}
                addEmptySpec={addEmptySpecItem}
                addEmptyVariety={addEmptyVarietyItem}
                deleteOption={deleteOptionItem}
                updateOption={updateOptionItem}
                saveSpec={saveSpecItem}
                deleteSpec={deleteSpecItem}
              />
            </LeftTable>
          </DragDropContext>
          <ModelTable
            showFeatureSplits={false}
            viewModelCodes={optionsLexusStore.viewModelCodes}
            models={vehicleModels}
            headerStyle={{ top: 0 }}
            renderRows={() => (
              <>
                {optionsLexusStore.filteredOptions.map(option => {
                  return (
                    <React.Fragment key={option.uid}>
                      {option.specs.map((spec, index) => (
                        <React.Fragment key={spec.uid}>
                          <PackageTableRow rowHeight={spec.rowHeight}>
                            {vehicleModels.map(
                              (model, index) =>
                                model.show &&
                                spec.models[index] && (
                                  <SettingsCell
                                    key={`${option.uid}${model.uid}`}
                                    disabled={readOnly}
                                    model={spec.models[index]}
                                    oddRow={false}
                                    rowSpan={1}
                                    allowTokens={teamStore.team.allowDisclaimerTokens}
                                    disclaimerTokens={disclaimerTokens}
                                    onUpdate={value => {
                                      const setting = spec.models[index].setting || '';
                                      if (value !== setting) {
                                        spec.models[index].setting = value;
                                        saveSpecItem(option, spec);
                                      }
                                    }}
                                  />
                                )
                            )}
                          </PackageTableRow>
                        </React.Fragment>
                      ))}
                      {option.specs.length === 0 && (
                        <PackageTableRow rowHeight={option.rowHeight} />
                      )}
                      {option.isOption && (
                        <PackageTotalRow isRequired rowHeight={option.packageTotals.rowHeight}>
                          {option.packageTotals.applicability.map((model, index) => (
                            <PackageTotalCell
                              key={model.uid}
                              value={model.value}
                              disabled={
                                readOnly ||
                                !option.specs.length ||
                                hasNoApplicability(index, option.specs)
                              }
                              suggestionTypes={disclaimerTokens}
                              onBlur={value => {
                                const modelValue = model.value || '';
                                if (areRichTextValuesDifferent(value, modelValue)) {
                                  model.value = value;
                                  saveOptionItem(option);
                                }
                              }}
                            />
                          ))}
                        </PackageTotalRow>
                      )}
                      {!option.isOption && !option.packageVarieties.length && (
                        <>
                          <PackageTotalRow rowHeight={option.packageTotals.rowHeight}>
                            {option.packageTotals.applicability.map((model, index) => (
                              <PackageTotalCell
                                key={model.id}
                                value={model.value}
                                disabled={
                                  readOnly ||
                                  !option.specs.length ||
                                  hasNoApplicability(index, option.specs)
                                }
                                suggestionTypes={disclaimerTokens}
                                onBlur={value => {
                                  const modelValue = model.value || '';
                                  if (areRichTextValuesDifferent(value, modelValue)) {
                                    model.value = value;
                                    saveOptionItem(option);
                                  }
                                }}
                              />
                            ))}
                          </PackageTotalRow>
                          <PackageTotalRow isRequired rowHeight={option.packageTotalReqs.rowHeight}>
                            {option.packageTotalReqs.applicability.map((model, index) => (
                              <PackageTotalCell
                                key={model.id}
                                value={model.value}
                                disabled={
                                  readOnly ||
                                  !option.specs.length ||
                                  hasNoApplicability(index, option.specs)
                                }
                                suggestionTypes={disclaimerTokens}
                                onBlur={value => {
                                  const modelValue = model.value || '';
                                  if (areRichTextValuesDifferent(value, modelValue)) {
                                    model.value = value;
                                    saveOptionItem(option);
                                  }
                                }}
                              />
                            ))}
                          </PackageTotalRow>
                        </>
                      )}
                      {option.packageVarieties.map(variety => (
                        <PackageTotalRow isRequired key={variety.uid}>
                          {variety.applicability.map((model, index) => (
                            <PackageTotalCell
                              key={model.id}
                              value={model.value}
                              disabled={readOnly}
                              suggestionTypes={disclaimerTokens}
                              onBlur={value => {
                                const modelValue = model.value || '';
                                if (areRichTextValuesDifferent(value, modelValue)) {
                                  model.value = value;
                                  saveVarietyItem(option, variety);
                                }
                              }}
                            />
                          ))}
                        </PackageTotalRow>
                      ))}
                      <OptionsLexusDividerRow />
                    </React.Fragment>
                  );
                })}
              </>
            )}
          />
        </TwoTableWrapper>
      )}
    </>
  );
};

export default observer(OptionsLexusController);
