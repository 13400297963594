import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { HeaderCell, Table, TableRow, Thead } from '../../../../components/Table';
import { REGION_GST } from '../../../../models/user.model';

interface DisclaimersTableProps {
  isArchived?: boolean;
  onSort: (key: string) => void;
  renderRows: () => ReactNode;
  region?: string;
}

const DisclaimersTable = observer(
  ({ isArchived, onSort, renderRows, region }: DisclaimersTableProps) => {
    const gstTeam = region === REGION_GST;
    return (
      <Table fullWidth>
        <Thead>
          <TableRow>
            <HeaderCell onClick={() => onSort('token')}>ID</HeaderCell>
            <HeaderCell onClick={() => onSort('description')}>Description</HeaderCell>
            {gstTeam && <HeaderCell>Spanish Description</HeaderCell>}
            <HeaderCell>Notes</HeaderCell>
            <HeaderCell onClick={() => onSort('lastPublishedDate')}>
              {isArchived ? 'Archived' : 'Published'}
            </HeaderCell>
            {/* <HeaderCell>Models</HeaderCell> */}
            <HeaderCell></HeaderCell>
            {isArchived && <HeaderCell>Version</HeaderCell>}
          </TableRow>
        </Thead>

        <tbody>{renderRows()}</tbody>
      </Table>
    );
  }
);

export default DisclaimersTable;
