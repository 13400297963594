import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import { CategoryLanguageMap, CategoryResponse } from '../models/category.model';
import { VehicleTeam } from '../models/vehicleData.model';
import API from '../webservices/api';

export const CATEGORIES_PATH = '/categories';

export const getCategories = (
  resourcePath: string,
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<CategoryResponse[]>(
    `${resourcePath}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}/${language}?${params}`
  );
};

export const addCategories = (
  resourcePath: string,
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: string[] | { [lang: string]: string },
  id?: string
) => {
  const body: any = {
    categories: payload,
  };
  if (id) {
    body.id = id;
  }
  return API.post<CategoryLanguageMap>(
    `${resourcePath}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}`,
    body
  );
};

export const updateCategory = (
  resourcePath: string,
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  id: string,
  name: string,
  lang?: string
) => {
  return API.put(
    `${resourcePath}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}${
      lang ? `/${lang}` : ''
    }/${id}`,
    {
      name,
    }
  );
};
