import { useCallback } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { LIMITED_DATA_STATUS } from '../../../../../constants/vehicleData/VDConstants';
import useStores from '../../../../../hooks/useStores';
import { ColorItem } from '../../../../../models/colors.model';
import { VehicleTeam } from '../../../../../models/vehicleData.model';
import { handleErrorResponse } from '../../../../../utils/errorHandlingUtils';
import { updateExteriorColorStatus } from '../../../../../webservices/vehicleColorsApi';
import { toGqlBrand, toGqlTeam } from '../../../../../utils/graphqlUtils';
import { UpdateExtColorStatusInput } from '../../../../../gql/generated';

interface IUseFieldStatusExtColorProps {
  extColor: ColorItem;
}

const useFieldStatusExtColor = ({ extColor }: IUseFieldStatusExtColorProps) => {
  const {
    userStore: { brand },
    limitedDataStatusStore: { setDisabled },
  } = useStores();
  const { team, seriesId, year: modelYear } = useParams<{
    team: VehicleTeam;
    seriesId: string;
    year: string;
  }>();

  const onSave = useCallback(
    async (payload: UpdateExtColorStatusInput) => {
      try {
        setDisabled(true);
        const res = await trackPromise(
          updateExteriorColorStatus({
            brand: toGqlBrand(brand),
            team: toGqlTeam(team),
            seriesId,
            modelYear: Number(modelYear),
            payload,
          })
        );

        extColor.fieldStatus = res;
        toast.success(`Exterior Color Field Status updated successfully`);
        setDisabled(false);
      } catch (e) {
        setDisabled(false);
        handleErrorResponse(e, 'Exterior Color Field Status failed update');
      }
    },
    [extColor, brand, modelYear, seriesId, setDisabled, team]
  );

  const onClick = useCallback(() => {
    const fieldStatus = extColor.fieldStatus ?? {};
    const payload: UpdateExtColorStatusInput = { ...(fieldStatus as UpdateExtColorStatusInput) };

    if (payload.status === LIMITED_DATA_STATUS.IN_PROGRESS) {
      payload.status = LIMITED_DATA_STATUS.READY_TO_PUBLISH;
      return onSave(payload);
    }

    if (payload.status === LIMITED_DATA_STATUS.READY_TO_PUBLISH) {
      payload.status = LIMITED_DATA_STATUS.IN_PROGRESS;
      return onSave(payload);
    }
  }, [extColor, onSave]);

  return {
    onClick,
  };
};

export default useFieldStatusExtColor;
