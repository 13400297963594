import cx from 'clsx';
import { ChangeLogBooleans } from '../../../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import { ChangeLogBase, ChangeLogTypes } from '../../../../../../models/changeLog.model';
import React from 'react';
import { FlexContainer, MultiLineRTEditor } from 'vapi-ui-common';
import styles from './optionsLexusReviewChanges.module.scss';

interface OptionsLexusReviewChangesProps {
  changes: ChangeLogBase;
}

const OptionsLexusReviewChanges = ({ changes }: OptionsLexusReviewChangesProps) => {
  const LabeledChanges = (props: {
    label: string;
    before: string;
    after: string;
    autoHeight?: boolean;
  }) => {
    const { label, before, after, autoHeight } = props;

    return (
      <FlexContainer className={cx(styles.container, autoHeight && styles.autoHeight)}>
        <label style={{ marginRight: '4px' }}>{label}:</label>
        <div className={styles.changesContainer}>
          {before && (
            <MultiLineRTEditor
              editorStyles={styles.beforeEditor}
              disabled
              value={before}
              fullHeight
            />
          )}
          <MultiLineRTEditor disabled value={after} fullHeight />
        </div>
      </FlexContainer>
    );
  };

  switch (changes.changeType) {
    case ChangeLogTypes.PACKAGE_ADDED: {
      return <div>Package Added</div>;
    }

    case ChangeLogTypes.PACKAGE_DELETED: {
      return <div>Package Deleted</div>;
    }

    case ChangeLogTypes.OPTION_ADDED: {
      return <div>Option Added</div>;
    }

    case ChangeLogTypes.OPTION_DELETED: {
      return <div>Option Deleted</div>;
    }

    case ChangeLogTypes.OPTION_SPEC_ADDED:
    case ChangeLogTypes.PACKAGE_SPEC_ADDED: {
      return <div>Spec Added</div>;
    }

    case ChangeLogTypes.OPTION_SPEC_DELETED:
    case ChangeLogTypes.PACKAGE_SPEC_DELETED: {
      return <div>Spec Deleted</div>;
    }

    case ChangeLogTypes.OPTION_SPEC_EXCLUSIVE_CHANGED:
    case ChangeLogTypes.PACKAGE_SPEC_EXCLUSIVE_CHANGED: {
      return (
        <>
          <div>"Exclusive to Package"</div>
          <div>{changes.after ? 'Flag Added' : 'Flag Removed'}</div>
        </>
      );
    }

    case ChangeLogTypes.OPTION_SPEC_TOMS_CHANGED:
    case ChangeLogTypes.PACKAGE_SPEC_TOMS_CHANGED: {
      return <LabeledChanges label="TOMS" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.OPTION_SPEC_SMS_CHANGED:
    case ChangeLogTypes.PACKAGE_SPEC_SMS_CHANGED: {
      return <LabeledChanges label="SMS" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.OPTION_SPEC_SPECS_CHANGED:
    case ChangeLogTypes.PACKAGE_SPEC_SPECS_CHANGED: {
      return <LabeledChanges label="Specs" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.CODE: {
      return <LabeledChanges label="Code" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.NAME: {
      return <LabeledChanges label="Name" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.PACKAGE_REQUIRED_CHANGED: {
      return <LabeledChanges label="Required" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.PACKAGE_VARIETY_REQUIRED_CHANGED: {
      return (
        <LabeledChanges label="Required" before={changes.before} after={changes.after} autoHeight />
      );
    }

    case ChangeLogTypes.PACKAGE_VARIETY_NAME_CHANGED: {
      return (
        <LabeledChanges label="Name" before={changes.before} after={changes.after} autoHeight />
      );
    }

    case ChangeLogTypes.PACKAGE_VARIETY_CODE_CHANGED: {
      return (
        <LabeledChanges label="Code" before={changes.before} after={changes.after} autoHeight />
      );
    }

    case ChangeLogTypes.PACKAGE_VARIETY_CONFLICTS_CHANGED: {
      return (
        <LabeledChanges
          label="Conflicts"
          before={changes.before}
          after={changes.after}
          autoHeight
        />
      );
    }

    case ChangeLogTypes.DESCRIPTION: {
      return <LabeledChanges label="Description" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.PACKAGE_CONFLICTS_CHANGED: {
      return <LabeledChanges label="Conflicts" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.PACKAGE_VARIETY_ADDED: {
      return <div>Variety Added</div>;
    }

    case ChangeLogTypes.PACKAGE_VARIETY_DELETED: {
      return <div>Variety Deleted</div>;
    }

    case ChangeLogTypes.IN_PROGRESS: {
      return (
        <ChangeLogBooleans
          before={changes.before.toString()}
          after={changes.after.toString()}
          changeType="In Progress"
        />
      );
    }

    case ChangeLogTypes.PACKAGE_TOTAL_MODEL_APPLICABILITY:
    case ChangeLogTypes.PACKAGE_TOTAL_REQ_MODEL_APPLICABILITY:
    case ChangeLogTypes.OPTION_TOTAL_MODEL_APPLICABILITY:
    case ChangeLogTypes.OPTION_SPEC_MODEL_APPLICABILITY:
    case ChangeLogTypes.OPTION_SPEC_MODEL_APPLICABILITY_CHANGED:
    case ChangeLogTypes.PACKAGE_VARIETY_MODEL_APPLICABILITY:
    case ChangeLogTypes.PACKAGE_SPEC_MODEL_APPLICABILITY_CHANGED:
    case ChangeLogTypes.PACKAGE_SPEC_MODEL_APPLICABILITY: {
      return (
        <>
          {changes.applicabilityChanges &&
            changes.applicabilityChanges.map((modelChange, index) => (
              <FlexContainer key={index}>
                <span>{modelChange.title}: </span>
                <div className={styles.changesContainer}>
                  {modelChange.before && (
                    <MultiLineRTEditor
                      editorStyles={styles.beforeEditor}
                      disabled
                      value={modelChange.before}
                      fullHeight
                    />
                  )}
                  <MultiLineRTEditor disabled value={modelChange.after} fullHeight />
                </div>
              </FlexContainer>
            ))}
        </>
      );
    }

    default: {
      return null;
    }
  }
};

export default OptionsLexusReviewChanges;
