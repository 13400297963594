import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { ConfirmModal } from 'vapi-ui-common';
import { LIMITED_DATA_STATUS } from '../../constants/vehicleData/VDConstants';
import DropdownListSelect from '../DropdownListSelect';
import IconTextButton from '../IconTextButton';
import styles from './limitedDataStatus.module.scss';

export enum LD_STATUS {
  READY_TO_PUBLISH = LIMITED_DATA_STATUS.READY_TO_PUBLISH,
  IN_PROGRESS = LIMITED_DATA_STATUS.IN_PROGRESS,
}

export type OptionItem = {
  dropdownText: string;
  confirmModalHeaderText: string;
  confirmModalBodyText: string;
  confirmModalButtonText: string;
};

export type OptionsMap = {
  [key in LD_STATUS]: OptionItem;
};

export interface LimitedDataStatusHeaderProps {
  hideLimitedDataStatus?: boolean;
  className?: string;
  readonly?: boolean;
  updateAllFieldStatuses?: (payload: { status: LD_STATUS }) => void;
  optionsMap: OptionsMap;
}

const LimitedDataStatusHeader = ({
  hideLimitedDataStatus,
  className,
  readonly,
  updateAllFieldStatuses,
  optionsMap,
}: LimitedDataStatusHeaderProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [status, setStatus] = useState<LD_STATUS>(LD_STATUS.READY_TO_PUBLISH);

  const onSelect = useCallback(
    value => {
      switch (value) {
        case optionsMap[LD_STATUS.READY_TO_PUBLISH].dropdownText: {
          setStatus(LD_STATUS.READY_TO_PUBLISH);
          break;
        }
        case optionsMap[LD_STATUS.IN_PROGRESS].dropdownText: {
          setStatus(LD_STATUS.IN_PROGRESS);
          break;
        }
      }

      setShowConfirmModal(true);
    },
    [setShowConfirmModal, setStatus, optionsMap]
  );

  const onConfirm = useCallback(
    () => updateAllFieldStatuses && updateAllFieldStatuses({ status }),
    [status, updateAllFieldStatuses]
  );

  const onClose = useCallback(() => setShowConfirmModal(false), [setShowConfirmModal]);

  if (hideLimitedDataStatus) return null;

  if (readonly || !updateAllFieldStatuses)
    return <span className={cx(className)}>Publish Status</span>;

  return (
    <>
      <DropdownListSelect
        list={Object.values(optionsMap).map(opt => opt.dropdownText)}
        onSelect={onSelect}
      >
        <div
          className={cx(styles.limitedDataStatusHeader, className)}
          data-testid="limited-data-status-header"
        >
          <span>Publish Status</span>
          <IconTextButton icon="chevronDown" text="" smallIcon />
        </div>
      </DropdownListSelect>

      <ConfirmModal
        headerText={optionsMap[status].confirmModalHeaderText}
        bodyText={optionsMap[status].confirmModalBodyText}
        confirmButtonText={optionsMap[status].confirmModalButtonText}
        open={showConfirmModal}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default observer(LimitedDataStatusHeader);
