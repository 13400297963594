import cx from 'clsx';
import React from 'react';
import { Button, ButtonProps } from 'vapi-ui-common';
import styles from './sortButton.module.scss';

interface SortButtonProps extends ButtonProps {}

const SortButton = ({ toggled, ...rest }: SortButtonProps) => {
  return <Button className={cx(styles.sortButton, toggled && styles.toggled)} {...rest} />;
};

export default SortButton;
