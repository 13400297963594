import { action, observable } from 'mobx';
import { BnPChangeTypeMap, BnPReviewItem } from '../../../models/buildAndPrice.model';
import { ReviewChangeRequest, ReviewChangeTypeMap } from '../../../models/review.model';
import { VehicleTeam } from '../../../models/vehicleData.model';
import BaseReviewStore from './baseReviewStore';
import { UpdateBnpReviewInput } from '../../../gql/generated';

class BuildAndPriceReviewStore extends BaseReviewStore {
  @observable reviewItems: BnPReviewItem[] = [];

  getMapPayload(map: BnPChangeTypeMap, team: VehicleTeam): ReviewChangeRequest {
    return {
      id: map.id,
      revId: map.revId,
      isAccepted: map.isApplied || team === VehicleTeam.AGENCY_TEAM,
      isApplied: map.isApplied,
      changeTypeId: '',
      rejectNotes: map.rejectNotes,
      itemKey: map.itemKey,
      categoryKey: map.categoryKey,
    };
  }

  getReviewInputPayload(map: BnPChangeTypeMap, team: VehicleTeam): UpdateBnpReviewInput {
    return {
      revId: map.revId,
      name: map.itemKey,
      category: map.categoryKey,
      isAccepted: map.isApplied || team === VehicleTeam.AGENCY_TEAM,
      isApplied: map.isApplied,
      rejectNotes: map.rejectNotes,
    };
  }

  isMapValid = (map: ReviewChangeTypeMap, team: VehicleTeam): boolean => {
    return team === VehicleTeam.AGENCY_TEAM || map.isApplied || !!map.rejectNotes;
  };

  @action reset() {
    this.reviewItems = [];
  }
}

export default BuildAndPriceReviewStore;
