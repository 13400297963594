import apolloClient from '../apolloClient';
import {
  AddBnpCategorySplitDocument,
  AddBnpCategorySplitMutation,
  AddBnpCategorySplitMutationVariables,
  DeleteBnpCategorySplitDocument,
  DeleteBnpCategorySplitMutation,
  DeleteBnpCategorySplitMutationVariables,
  ReadBnpChangesDocument,
  ReadBnpChangesQuery,
  ReadBnpChangesQueryVariables,
  ReadBnpItemsDocument,
  ReadBnpItemsQuery,
  ReadBnpItemsQueryVariables,
  ReadBnpReviewDocument,
  ReadBnpReviewQuery,
  ReadBnpReviewQueryVariables,
  RevertBnpChangeDocument,
  RevertBnpChangeMutation,
  RevertBnpChangeMutationVariables,
  UpdateBnpCategoryApplicabilityDocument,
  UpdateBnpCategoryApplicabilityMutation,
  UpdateBnpCategoryApplicabilityMutationVariables,
  UpdateBnpItemDescriptionDocument,
  UpdateBnpItemDescriptionMutation,
  UpdateBnpItemDescriptionMutationVariables,
  UpdateBnpItemNameDocument,
  UpdateBnpItemNameMutation,
  UpdateBnpItemNameMutationVariables,
  UpdateBnpItemTitleCopyDocument,
  UpdateBnpItemTitleCopyMutation,
  UpdateBnpItemTitleCopyMutationVariables,
  UpdateBnpReviewDocument,
  UpdateBnpReviewMutation,
  UpdateBnpReviewMutationVariables,
  UpdateBnpStatusDocument,
  UpdateBnpStatusMutation,
  UpdateBnpStatusMutationVariables,
} from '../gql/generated';
import {
  BnPFieldStatusResponse,
  BnPItemsResponse,
  BnPItemsReviewResponse,
  BnPReviewRequest,
} from '../models/buildAndPrice.model';
import { ChangeLogResponse } from '../models/changeLog.model';

// BnP
export const getItems = async (
  variables: ReadBnpItemsQueryVariables
): Promise<BnPItemsResponse> => {
  const { data } = await apolloClient.query<ReadBnpItemsQuery, ReadBnpItemsQueryVariables>({
    query: ReadBnpItemsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readBnpItems.body;
};

export const updateFieldStatus = async (
  variables: UpdateBnpStatusMutationVariables
): Promise<BnPFieldStatusResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateBnpStatusMutation,
    UpdateBnpStatusMutationVariables
  >({
    mutation: UpdateBnpStatusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update field status');
  }

  return data.updateBnpStatus.body;
};

export const updateName = async (
  variables: UpdateBnpItemNameMutationVariables
): Promise<BnPFieldStatusResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateBnpItemNameMutation,
    UpdateBnpItemNameMutationVariables
  >({
    mutation: UpdateBnpItemNameDocument,
    variables,
  });

  if (!data) {
    throw new Error('Faild to update name');
  }

  return data.updateBnpItemName.body;
};

export const updateDescription = async (
  variables: UpdateBnpItemDescriptionMutationVariables
): Promise<BnPItemsResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateBnpItemDescriptionMutation,
    UpdateBnpItemDescriptionMutationVariables
  >({
    mutation: UpdateBnpItemDescriptionDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update description');
  }

  return data.updateBnpItemDescription.body;
};

export const updateGradeDetail = async (
  variables: UpdateBnpItemTitleCopyMutationVariables
): Promise<BnPItemsResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateBnpItemTitleCopyMutation,
    UpdateBnpItemTitleCopyMutationVariables
  >({
    mutation: UpdateBnpItemTitleCopyDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update grade detail');
  }

  return data.updateBnpItemTitleCopy.body;
};

export const updateApplicability = async (
  variables: UpdateBnpCategoryApplicabilityMutationVariables
): Promise<BnPItemsResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateBnpCategoryApplicabilityMutation,
    UpdateBnpCategoryApplicabilityMutationVariables
  >({
    mutation: UpdateBnpCategoryApplicabilityDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update applicability');
  }

  return data.updateBnpCategoryApplicability.body;
};

// Splits
export const addCategorySplit = async (
  variables: AddBnpCategorySplitMutationVariables
): Promise<BnPItemsResponse> => {
  const { data } = await apolloClient.mutate<
    AddBnpCategorySplitMutation,
    AddBnpCategorySplitMutationVariables
  >({
    mutation: AddBnpCategorySplitDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add category split');
  }

  return data.addBnpCategorySplit.body;
};

export const deleteCategorySplit = async (
  variables: DeleteBnpCategorySplitMutationVariables
): Promise<BnPItemsResponse> => {
  const { data } = await apolloClient.mutate<
    DeleteBnpCategorySplitMutation,
    DeleteBnpCategorySplitMutationVariables
  >({
    mutation: DeleteBnpCategorySplitDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete category split');
  }

  return data.deleteBnpCategorySplit.body;
};

// ChangeLog
export const getChangeLog = async (
  variables: ReadBnpChangesQueryVariables
): Promise<ChangeLogResponse[]> => {
  const { data } = await apolloClient.query<ReadBnpChangesQuery, ReadBnpChangesQueryVariables>({
    query: ReadBnpChangesDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readBnpChanges.body;
};

export const revertChange = async (
  variables: RevertBnpChangeMutationVariables
): Promise<boolean> => {
  const { data } = await apolloClient.mutate<
    RevertBnpChangeMutation,
    RevertBnpChangeMutationVariables
  >({
    mutation: RevertBnpChangeDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to revert change');
  }

  return data.revertBnpChange.body;
};

// Review
export const getReviewItems = async (
  variables: ReadBnpReviewQueryVariables
): Promise<BnPItemsReviewResponse[]> => {
  const { data } = await apolloClient.query<ReadBnpReviewQuery, ReadBnpReviewQueryVariables>({
    query: ReadBnpReviewDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readBnpReview.body;
};

export const updateReviewBnP = async (
  variables: UpdateBnpReviewMutationVariables
): Promise<BnPReviewRequest> => {
  const { data } = await apolloClient.mutate<
    UpdateBnpReviewMutation,
    UpdateBnpReviewMutationVariables
  >({
    mutation: UpdateBnpReviewDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update review');
  }

  return data.updateBnpReview.body;
};
