import { computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { BRAND_TOYOTA } from './user.model';

export enum ChangeLogTypes {
  DEFAULT = '',
  VDSTATUS = 'Status',

  // Features
  FEATURE_ADDED = 'Feature Added',
  FEATURE_DELETED = 'Feature Deleted',
  COMPARE_FEATURE_ADDED = 'Compare Feature Added',
  COMPARE_FEATURE_DELETED = 'Compare Feature Deleted',
  COMPARE_FEATURE_STATUS = 'Compare Feature Status',
  ALL_COMPARE_FEATURE_STATUS = 'All Compare Features Status',
  PARENT_ID = 'Parent ID',
  CATEGORY = 'Category',
  SUB_CATEGORY = 'Sub Category',
  LINK = 'Link',
  DESCRIPTION = 'Description',
  DESCRIPTION_ES = 'Description ES',
  TRIM_TITLE = 'Trim Title',
  LONG_DESCRIPTION = 'Long Description',
  SHORT_DESCRIPTION = 'Short Description',
  NOTES = 'Notes',
  IN_PROGRESS = 'In Progress',
  HIGHLIGHTED = 'Highlighted',
  TOOLTIP = 'Tooltip',
  MODEL_APPLICABILITY = 'Model Applicability',
  GRADE_APPLICABILITY = 'Grade Applicability',
  CATEGORY_CHANGED = 'Category Changed',
  SUB_CATEGORY_CHANGED = 'Sub-Category Changed',
  SPLIT_ADDED = 'Split Added',
  SPLIT_DELETED = 'Split Deleted',
  SPLIT_DESCRIPTION = 'Split Description',
  SPLIT_SHORT_DESCRIPTION = 'Split Short Description',
  SPLIT_LONG_DESCRIPTION = 'Split Long Description',
  SPLIT_HIGHLIGHTED = 'Split Highlighted',
  FEATURE_STATUS = 'Feature Status',
  MODEL_APPLICABILITY_STATUS = 'Model Applicability Status',
  GRADE_APPLICABILITY_STATUS = 'Grade Applicability Status',
  ALL_FEATURE_STATUS = 'All Features Status',
  KEY_FEATURE = 'Key Features',
  // SPECS
  SPEC_ADDED = 'Spec Added',
  SPEC_DELETED = 'Spec Deleted',
  SPEC_TYPE = 'Spec Type',
  SPEC_TYPE_CHANGED = 'Spec Type Changed',
  SPEC_STATUS = 'Spec Status',
  ALL_SPEC_STATUS = 'All Specs Status',
  // OPTIONS
  OPTION_ADDED = 'Option Added',
  OPTION_DELETED = 'Option Deleted',
  OPTION_STATUS = 'Option Status',
  NAME = 'Name',
  CODE = 'Code',
  OPTION_EXTRA_COST = 'Extra Cost',
  ALL_OPTION_STATUS = 'All Options Status',
  // OPTIONS PACKAGES
  PACKAGE_ADDED = 'Package Added',
  PACKAGE_DELETED = 'Package Deleted',
  PACKAGE_CONFLICTS_CHANGED = 'Conflicts',
  PACKAGE_REQUIRED_CHANGED = 'Required',
  PACKAGE_TOTAL_MODEL_APPLICABILITY = 'Package Total Model Applicability',
  PACKAGE_TOTAL_REQ_MODEL_APPLICABILITY = 'Package Total Req Model Applicability',
  PACKAGE_VARIETY_NAME_CHANGED = 'Package Variety Name',
  PACKAGE_VARIETY_CODE_CHANGED = 'Package Variety Code',
  PACKAGE_VARIETY_REQUIRED_CHANGED = 'Package Variety Required',
  PACKAGE_VARIETY_CONFLICTS_CHANGED = 'Package Variety Conflicts',
  PACKAGE_VARIETY_ADDED = 'Package Variety Added',
  PACKAGE_VARIETY_DELETED = 'Package Variety Deleted',
  PACKAGE_VARIETY_MODEL_APPLICABILITY = 'Package Variety Model Applicability',
  // OPTIONS PACKAGE SPEC
  PACKAGE_SPEC_ADDED = 'Package Spec Added',
  PACKAGE_SPEC_DELETED = 'Package Spec Deleted',
  PACKAGE_SPEC_TOMS_CHANGED = 'Package Spec Toms',
  PACKAGE_SPEC_SMS_CHANGED = 'Package Spec SMS',
  PACKAGE_SPEC_SPECS_CHANGED = 'Package Spec Specs',
  PACKAGE_SPEC_EXCLUSIVE_CHANGED = 'Package Spec Exclusive',
  PACKAGE_SPEC_MODEL_APPLICABILITY = 'Package Spec Model Applicability',
  PACKAGE_SPEC_MODEL_APPLICABILITY_CHANGED = 'Package Spec Model Applicability Changed',
  // OPTIONS OPTION
  OPTION_TOTAL_MODEL_APPLICABILITY = 'Option Total Model Applicability',
  // OPTIONS OPTIONS SPEC
  OPTION_SPEC_ADDED = 'Option Spec Added',
  OPTION_SPEC_DELETED = 'Option Spec Deleted',
  OPTION_SPEC_TOMS_CHANGED = 'Option Spec Toms',
  OPTION_SPEC_SMS_CHANGED = 'Option Spec SMS',
  OPTION_SPEC_SPECS_CHANGED = 'Option Spec Specs',
  OPTION_SPEC_EXCLUSIVE_CHANGED = 'Option Spec Exclusive',
  OPTION_SPEC_MODEL_APPLICABILITY = 'Option Spec Model Applicability',
  OPTION_SPEC_MODEL_APPLICABILITY_CHANGED = 'Option Spec Model Applicability Changed',
  // COLORS
  INT_COLOR_ADDED = 'Interior Color Added',
  INT_COLOR_DELETED = 'Interior Color Deleted',
  INT_COLOR_NAME = 'Interior Color Name',
  INT_COLOR_CODE = 'Interior Color Code',
  INT_COLOR_GRADE_APPLICABILITY = 'Interior Color Grade Applicability',
  EXT_COLOR_ADDED = 'Exterior Color Added',
  EXT_COLOR_DELETED = 'Exterior Color Deleted',
  EXT_COLOR_NAME = 'Exterior Color Name',
  EXT_COLOR_CODE = 'Exterior Color Code',
  EXT_COLOR_HEX_CODE = 'Exterior Color Hex Code',
  EXT_COLOR_NOTES = 'Exterior Color Notes',
  EXT_COLOR_IN_PROGRESS = 'Exterior Color In Progress',
  EXT_COLOR_EXTRA_COST = 'Exterior Color Extra Cost',
  EXT_COLOR_STATUS = 'Exterior Color Status',
  INT_COLOR_EXTRA_COST = 'Interior Color Extra Cost',
  EXT_COLOR_APPLICABILITY = 'Exterior Color Applicability',
  INT_COLOR_MODEL_APPLICABILITY = 'Interior Color Model Applicability',
  ALL_COLOR_STATUS = 'All Colors Status',
  // COLORS LEXUS
  INTERIOR_TYPE_CHANGED = 'Interior Type Changed',
  GROUP_CHANGED = 'Group Changed',
  MATERIAL_CHANGED = 'Material Changed',
  ORNAMENT_CHANGED = 'Ornament Changed',
  MATERIALS = 'Materials',
  INTERIOR = 'Interior',
  INTERIOR_ID = 'Interior ID',
  ORNAMENT_1 = 'Ornament #1',
  ORNAMENT_1_ID = 'Ornament #1 ID',
  ORNAMENT_2 = 'Ornament #2',
  ORNAMENT_2_ID = 'Ornament #2 ID',
  SEAT_ACCENT = 'Seat Accent',
  CC_ARMREST = 'CC / Armrest',
  HEADLINER = 'Headliner',
  IP = 'IP',
  CARPET = 'Carpet',
  INT_COLOR_GROUP_APPLICABILITY = 'Interior Color Group Applicability',
  GROUP_NAME_CHANGED = 'Group Name Changed',
  GROUP_MODELS_CHANGED = 'Group Models Changed',
  GROUP_ADDED = 'Group Added',
  GROUP_DELETED = 'Group Deleted',
  // MODELS
  MODEL_NAME = 'Model Name',
  MODEL_ADDED = 'Model Added',
  MODEL_DELETED = 'Model Deleted',
  GRADE = 'Grade',
  GRADE_CHANGED = 'Grade Changed',
  DRIVE = 'Drive',
  ENGINE = 'Engine',
  TRANSMISSION = 'Transmission',
  HYBRID = 'Hybrid',
  BED = 'Bed',
  CAB = 'Cab',
  SEATING = 'Seating',
  KATASHIKI = 'Katashiki',
  MSRP = 'MSRP',
  CBU_NAP = 'CBU/NAP',
  HORSEPOWER = 'Horsepower',
  IS_NOT_PUBLISHABLE = 'Is Not Publishable',
  FUEL_TYPE = 'Fuel Type',
  ALTERNATIVE_OFFERS_DESCRIPTION = 'Alternative Offers Description',
  PACKAGE_TRIM = 'Package/Trim',
  REQUIRED_PACKAGE_CODE = 'Required Package Code(s)',
  SPECIAL_EDITION = 'Special Edition',
  TITLE = 'Title',
  COPY = 'Copy',
  // MODELS LIMITED DATA STATUS
  TRIM_TITLE_STATUS = 'Trim Title Status',
  GRADE_STATUS = 'Grade Status',
  DRIVE_STATUS = 'Drive Status',
  CAB_STATUS = 'Cab Status',
  BED_STATUS = 'Bed Status',
  DESCRIPTION_STATUS = 'Description Status',
  HORSEPOWER_STATUS = 'Horsepower Status',
  TRANSMISSION_STATUS = 'Transmission Status',
  ENGINE_STATUS = 'Engine Status',
  SEATING_STATUS = 'Seating Status',
  PUBLISHED_STATUS = 'Published Status',
  ALL_MODEL_STATUS = 'All Models Status',
  // MODELS TdPR
  IS_USVI = 'For USVI',
  IS_TDPR = 'For TdPR',
  TDPR_CODE = 'TdPR Code',
  // COMMON LANGUAGE
  SPEC_ARCHIVED = 'Spec Archived',
  SPEC_UNARCHIVED = 'Spec UnArchived',
  CATEGORY_TYPE = 'Category type',
  MODELS_ADDED = 'Models Added',
  MODELS_REMOVED = 'Models Removed',
  DRAFT_RESET = 'Draft Reset',
  VEHICLES = 'Vehicles',
  // DISCLAIMERS
  DISC_ADDED = 'Disclaimer Added',
  DISC_ARCHIVED = 'Disclaimer Archived',
  DISC_UNARCHIVED = 'Disclaimer UnArchived',
  TOKEN = 'Token',
  SERIES_SETTING = 'Series Association',
  GO_LIVE_DATE = 'Go Live Date',
  // SERIES SETTINGS
  SERIES_ADDED = 'Series Added',
  SERIES_DELETED = 'Series Deleted',
  FUEL_TYPES = 'Fuel Types',
  SERIES_CATEGORIES = 'Series Categories',
  ESTIMATED_MILEAGE = 'Estimated Mileage',
  MPGE = 'MPGE',
  RANGE = 'Range',
  // BNP
  CATEGORY_APPLICABILITY = 'Category Applicability',
  BNP_SPLIT_ADDED = 'BnP Split Added',
  BNP_SPLIT_DELETED = 'BnP Split Deleted',
  BNP_SPLIT_DESCRIPTION = 'BnP Split Description',
  BNP_STATUS = 'BnP Status',
  BEDS_STATUS = 'Beds Status',
  CABS_STATUS = 'Cabs Status',
  SEATS_STATUS = 'Seats Status',
}

export const MODEL_APPLICABILITY_CHANGE_TYPES = [
  ChangeLogTypes.MODEL_APPLICABILITY,
  ChangeLogTypes.PACKAGE_SPEC_MODEL_APPLICABILITY,
  ChangeLogTypes.PACKAGE_VARIETY_MODEL_APPLICABILITY,
  ChangeLogTypes.PACKAGE_TOTAL_MODEL_APPLICABILITY,
  ChangeLogTypes.PACKAGE_TOTAL_REQ_MODEL_APPLICABILITY,
  ChangeLogTypes.OPTION_TOTAL_MODEL_APPLICABILITY,
  ChangeLogTypes.OPTION_SPEC_MODEL_APPLICABILITY,
  ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY,
  ChangeLogTypes.INT_COLOR_GROUP_APPLICABILITY,
  ChangeLogTypes.GROUP_MODELS_CHANGED,
  ChangeLogTypes.MODEL_APPLICABILITY_STATUS,
];

export const SYNCED_CHANGE_TYPES_MAP: { [k in ChangeLogTypes]?: true } = {
  [ChangeLogTypes.MODEL_APPLICABILITY]: true,
  [ChangeLogTypes.LINK]: true,
  [ChangeLogTypes.IN_PROGRESS]: true,
  [ChangeLogTypes.FUEL_TYPE]: true,
  [ChangeLogTypes.FUEL_TYPES]: true,
  [ChangeLogTypes.GO_LIVE_DATE]: true,
  [ChangeLogTypes.HORSEPOWER]: true,
  [ChangeLogTypes.KATASHIKI]: true,
  [ChangeLogTypes.CODE]: true,
  [ChangeLogTypes.SEATING]: true,
  [ChangeLogTypes.ESTIMATED_MILEAGE]: true,
  [ChangeLogTypes.MPGE]: true,
  [ChangeLogTypes.RANGE]: true,
  [ChangeLogTypes.EXT_COLOR_APPLICABILITY]: true,
  [ChangeLogTypes.EXT_COLOR_CODE]: true,
  [ChangeLogTypes.EXT_COLOR_HEX_CODE]: true,
  [ChangeLogTypes.EXT_COLOR_IN_PROGRESS]: true,
  [ChangeLogTypes.EXT_COLOR_EXTRA_COST]: true,
  [ChangeLogTypes.INT_COLOR_CODE]: true,
  [ChangeLogTypes.INT_COLOR_EXTRA_COST]: true,
  [ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY]: true,
  [ChangeLogTypes.GRADE]: true,
  [ChangeLogTypes.TDPR_CODE]: true,
  [ChangeLogTypes.IS_TDPR]: true,
  [ChangeLogTypes.IS_USVI]: true,
  [ChangeLogTypes.CATEGORY]: true,
  [ChangeLogTypes.SUB_CATEGORY]: true,
  [ChangeLogTypes.SPEC_TYPE]: true,
};

export interface ModelApplicabilityChanges {
  title: string;
  before?: string;
  after?: string;
}

export interface ChangeLogBase {
  uid?: string;
  id: string;
  revId: string;
  before: string;
  after: string;
  beforeValue: string;
  afterValue: string;
  beforeValues?: string[];
  afterValues?: string[];
  modelsAdded?: string[];
  modelsRemoved?: string[];
  modifiedDate: string;
  modifiedBy: string;
  changeType: ChangeLogTypes;
  extColorAppBefore?: string[];
  extColorAppAfter?: string[];
  applicabilityChanges?: ModelApplicabilityChanges[];
  featureSplitId?: string;
  description?: string;
  compareType?: string;
  bnpSplitId?: string;
}

export class ChangeLogItem implements ChangeLogBase {
  changeLogId = uuidv4();
  id = '';
  revId = '';
  parentId = '';
  parentRevId = '';
  parentName = '';
  name = '';
  category = '';
  description = '';
  shortDescription = '';
  notes = '';
  changeType = '' as ChangeLogTypes;
  changeTypeDisplayText = '';
  before = '';
  beforeValue = '';
  beforeValues: string[] = [];
  after = '';
  afterValue = '';
  afterValues: string[] = [];
  changes: ChangeLogChange[] = [];
  modelsAdded: string[] = [];
  modelsRemoved: string[] = [];
  modifiedDate = '';
  modifiedBy = '';
  canRevert = false;
  extColorAppBefore: string[] = [];
  extColorAppAfter: string[] = [];
  applicabilityChanges: ModelApplicabilityChanges[] = [];
  featureSplitId = '';
  compareType = '';
  language = '';
  bnpSplitId = '';

  constructor(item?: ChangeLogResponse, isDraft = false, language = '') {
    if (item) {
      this.id = item.id;
      this.revId = item.revId;
      this.name = item.name;
      this.parentId = item.parentId;
      this.parentRevId = item.parentRevId;
      this.parentName = item.parentName;
      this.category = item.category;
      this.description = item.description;
      this.shortDescription = item.shortDescription;
      this.notes = item.notes;
      this.after = item.after;
      this.before = item.before;
      this.afterValue = item.afterValue;
      this.beforeValue = item.beforeValue;
      this.modifiedDate = item.modifiedDate;
      this.modifiedBy = item.modifiedBy;
      this.changeType = item.changeType;
      this.changeTypeDisplayText = item.changeType;
      this.canRevert =
        isDraft &&
        this.changeType !== ChangeLogTypes.FEATURE_DELETED &&
        this.changeType !== ChangeLogTypes.SPLIT_DELETED &&
        this.changeType !== ChangeLogTypes.SPEC_DELETED &&
        this.changeType !== ChangeLogTypes.OPTION_DELETED &&
        this.changeType !== ChangeLogTypes.PACKAGE_DELETED &&
        this.changeType !== ChangeLogTypes.PACKAGE_SPEC_DELETED &&
        this.changeType !== ChangeLogTypes.OPTION_SPEC_DELETED &&
        this.changeType !== ChangeLogTypes.PACKAGE_VARIETY_DELETED &&
        this.changeType !== ChangeLogTypes.INT_COLOR_DELETED &&
        this.changeType !== ChangeLogTypes.EXT_COLOR_DELETED &&
        this.changeType !== ChangeLogTypes.DRAFT_RESET &&
        this.changeType !== ChangeLogTypes.INT_COLOR_GRADE_APPLICABILITY &&
        this.changeType !== ChangeLogTypes.VDSTATUS &&
        this.changeType !== ChangeLogTypes.COMPARE_FEATURE_DELETED &&
        this.changeType !== ChangeLogTypes.PARENT_ID &&
        this.changeType !== ChangeLogTypes.CATEGORY_APPLICABILITY &&
        this.changeType !== ChangeLogTypes.BNP_SPLIT_ADDED &&
        this.changeType !== ChangeLogTypes.BNP_SPLIT_DELETED;
      // temp fix for handling bad data; a bnp change log item without a category value should not be revertable
      if (this.changeType === ChangeLogTypes.BNP_SPLIT_DESCRIPTION && !this.category) {
        this.canRevert = false;
      }
      this.extColorAppBefore = item.extColorAppBefore || [];
      this.extColorAppAfter = item.extColorAppAfter || [];
      this.featureSplitId = item.featureSplitId ? item.featureSplitId : '';
      this.compareType = item.compareType || '';
      this.language = language;
      this.bnpSplitId = item.bnpSplitId || '';
    }
  }

  @computed get payload(): ChangeLogRequest {
    switch (this.changeType) {
      case ChangeLogTypes.PACKAGE_SPEC_ADDED:
      case ChangeLogTypes.PACKAGE_VARIETY_ADDED:
      case ChangeLogTypes.OPTION_SPEC_ADDED: {
        return {
          id: this.id,
          revId: this.revId,
          parentId: this.parentId,
          parentRevId: this.parentRevId,
          parentName: this.parentName,
          changeType: this.changeType,
          from: this.after,
          to: this.after,
        };
      }
      case ChangeLogTypes.PACKAGE_SPEC_DELETED:
      case ChangeLogTypes.PACKAGE_SPEC_EXCLUSIVE_CHANGED:
      case ChangeLogTypes.PACKAGE_SPEC_MODEL_APPLICABILITY:
      case ChangeLogTypes.PACKAGE_SPEC_MODEL_APPLICABILITY_CHANGED:
      case ChangeLogTypes.PACKAGE_SPEC_SMS_CHANGED:
      case ChangeLogTypes.PACKAGE_SPEC_TOMS_CHANGED:
      case ChangeLogTypes.PACKAGE_SPEC_SPECS_CHANGED:
      case ChangeLogTypes.PACKAGE_VARIETY_NAME_CHANGED:
      case ChangeLogTypes.PACKAGE_VARIETY_CODE_CHANGED:
      case ChangeLogTypes.PACKAGE_VARIETY_REQUIRED_CHANGED:
      case ChangeLogTypes.PACKAGE_VARIETY_CONFLICTS_CHANGED:
      case ChangeLogTypes.PACKAGE_VARIETY_DELETED:
      case ChangeLogTypes.PACKAGE_VARIETY_MODEL_APPLICABILITY:
      case ChangeLogTypes.OPTION_SPEC_DELETED:
      case ChangeLogTypes.OPTION_SPEC_EXCLUSIVE_CHANGED:
      case ChangeLogTypes.OPTION_SPEC_MODEL_APPLICABILITY:
      case ChangeLogTypes.OPTION_SPEC_MODEL_APPLICABILITY_CHANGED:
      case ChangeLogTypes.OPTION_SPEC_SMS_CHANGED:
      case ChangeLogTypes.OPTION_SPEC_TOMS_CHANGED:
      case ChangeLogTypes.OPTION_SPEC_SPECS_CHANGED: {
        return {
          id: this.id,
          revId: this.revId,
          parentId: this.parentId,
          parentRevId: this.parentRevId,
          parentName: this.parentName,
          changeType: this.changeType,
          from: this.after,
          to: this.before,
        };
      }
      case ChangeLogTypes.FEATURE_ADDED:
      case ChangeLogTypes.SPLIT_ADDED:
      case ChangeLogTypes.SPEC_ADDED:
      case ChangeLogTypes.OPTION_ADDED:
      case ChangeLogTypes.PACKAGE_ADDED:
      case ChangeLogTypes.INT_COLOR_ADDED:
      case ChangeLogTypes.EXT_COLOR_ADDED:
      case ChangeLogTypes.COMPARE_FEATURE_ADDED: {
        return {
          id: this.id,
          featureSplitId: this.featureSplitId,
          changeType: this.changeType,
          from: this.after,
          to: this.after,
        };
      }
      case ChangeLogTypes.PACKAGE_DELETED: {
        return {
          id: this.id,
          changeType: this.changeType,
          from: this.before,
          to: this.before,
        };
      }
      case ChangeLogTypes.SUB_CATEGORY:
      case ChangeLogTypes.SPEC_TYPE:
        return {
          id: this.id,
          featureSplitId: this.featureSplitId,
          changeType: this.changeType,
          from: this.after !== undefined && this.after !== null ? this.after : '',
          to: this.before !== undefined && this.before !== null ? this.before : '',
        };
      case ChangeLogTypes.BNP_SPLIT_DESCRIPTION:
        return {
          id: this.id,
          changeType: this.changeType,
          from: this.after,
          to: this.before,
          bnpSplitId: this.bnpSplitId,
        };
      default:
        return {
          id: this.id,
          featureSplitId: this.featureSplitId,
          changeType: this.changeType,
          from: this.after,
          to: this.before,
        };
    }
  }
}

export interface ChangeLogResponse extends ChangeLogBase {
  name: string;
  category: string;
  description: string;
  notes: string;
  [key: string]: any;
}

export interface ChangeLogRequest {
  id: string;
  revId?: string;
  parentId?: string;
  parentRevId?: string;
  parentName?: string;
  featureSplitId?: string;
  changeType: ChangeLogTypes;
  from?: string;
  to?: string;
  name?: string;
  category?: string;
  bnpSplitId?: string;
}

export type ChangeLogChange = {
  added?: boolean;
  removed?: boolean;
  value?: string;
};

export class ChangeLogModelItem implements ChangeLogBase {
  id = '';
  uid = '';
  revId = '';
  before = '';
  after = '';
  beforeValue = '';
  afterValue = '';
  modelsAdded: string[] = [];
  modelsRemoved: string[] = [];
  modifiedDate = '';
  modifiedBy = '';
  changeType = '' as ChangeLogTypes;
  bed = '';
  code = '';
  transmission = '';
  isDeleted = false;
  engine = '';
  cab = '';
  grade = '';
  gradeValue = '';
  isHybrid = false;
  drive = '';
  description = '';
  extColorAppBefore: string[] = [];
  extColorAppAfter: string[] = [];
  canRevert = false;
  horsepower = '';
  language = '';

  constructor(item: ChangeLogModelResponse, version: string, brand: string, language: string) {
    if (item) {
      Object.assign(this, item);
      this.uid = uuidv4();
      this.language = language;
      if (brand === BRAND_TOYOTA) {
        this.description =
          item.description ||
          [item.gradeValue, item.bed, item.cab, item.engine, item.drive, item.transmission]
            .filter(item => item !== undefined)
            .join(' ');
      }

      this.canRevert =
        (!version || version.toUpperCase() === 'DRAFT') &&
        this.changeType !== ChangeLogTypes.MODEL_DELETED &&
        this.changeType !== ChangeLogTypes.DRAFT_RESET;
    }
  }

  getPayload(): ChangeLogModelRequest {
    return {
      id: this.id,
      revId: this.revId,
      code: this.code,
      changeType: this.changeType,
      from: this.after,
      to: this.before,
    };
  }
}

export interface ChangeLogModelResponse extends ChangeLogBase {
  bed: string;
  code: string;
  transmission: string;
  isDeleted: false;
  engine: string;
  cab: string;
  grade: string;
  gradeValue: string;
  isHybrid: false;
  drive: string;
}

export type ChangeLogModelRequest = {
  id: string;
  revId: string;
  code: string;
  changeType: ChangeLogTypes;
  from: string;
  to: string;
};

export interface ColorApplicability {
  interiorColorId: string;
  grade: string;
}

export interface ColorApplicabilityLexus {
  groupId: string;
  interiorColorId: string;
}

export interface ChangeLogLangMap {
  [lang: string]: ChangeLogItem[];
}

export interface AppliedChangesResponse {
  applied: {
    [k in ChangeLogTypes]?: {
      [lang: string]: any;
    };
  };
}
