import { observer } from 'mobx-react-lite';
import React, { createContext } from 'react';
import { HeaderCell, Table, TableRow, Thead } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';
import { BnPChangeTypeMap } from '../../../../../../models/buildAndPrice.model';
import BnPReviewCategory from './BnPReviewCategory';
import styles from './bnPReviewTable.module.scss';

interface BnPReviewRowItemContextProps {
  category: BnPChangeTypeMap;
  rowSpan: number;
  index: number;
}

export const BnPReviewRowContext = createContext<BnPReviewRowItemContextProps | null>(null);

const BnPReviewTable = () => {
  const { bnpReviewStore } = useStores();

  return (
    <>
      <Table fullWidth fixed className={styles.table}>
        <Thead>
          <TableRow>
            <HeaderCell cellClassName={styles.nameCell}>Name</HeaderCell>
            <HeaderCell cellClassName={styles.acceptCell}>Apply & Accept?</HeaderCell>
            <HeaderCell cellClassName={styles.categoryCell}>Category</HeaderCell>
            <HeaderCell cellClassName={styles.descriptionCell}>Description</HeaderCell>
            <HeaderCell cellClassName={styles.applicabilityCell}>Applicable Models</HeaderCell>
          </TableRow>
        </Thead>

        <tbody>
          {bnpReviewStore.reviewItems.map(reviewItem => {
            return (
              <React.Fragment key={`${reviewItem.itemKey}-BnPReviewCategory`}>
                <BnPReviewCategory categoryMap={reviewItem.categories} />

                {/* Separator */}
                <TableRow className={styles.grayRow}>
                  <td colSpan={4}></td>
                </TableRow>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default observer(BnPReviewTable);
