import moment from 'moment-timezone';
import React from 'react';
import {
  GO_LIVE_DATE_TIME,
  GoLiveDateTime,
  goLiveDateTimeMap,
} from '../../../../../utils/dateUtils';
import styles from '../modelsModal.module.scss';

interface Props {
  goLiveDate: string;
  timePicker?: boolean;
}

const GoLiveDateText = ({ goLiveDate, timePicker }: Props) => {
  const gld = new Date(goLiveDate);

  if (!moment(gld).isValid()) {
    return null;
  }

  const time: GoLiveDateTime = goLiveDateTimeMap[gld.getHours() as GO_LIVE_DATE_TIME];

  return (
    <div className={styles.goLiveDateContainer}>
      <span className={styles.noWrap}>
        <span className={styles.mgLeft}>{gld.toLocaleDateString()}</span>
        {timePicker && <span className={styles.mgLeft}>{`${time.text} CST`}</span>}
      </span>
    </div>
  );
};

export default GoLiveDateText;
