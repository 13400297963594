import { useEffect, useState } from 'react';
import { IDValueType } from '../models/common.model';
import { RefItemsMap, CategoriesMap } from '../models/category.model';
import { RefItemLangMap, CategoryLangMap } from '../models/category.model';
import { RefItem } from '../models/refItem.model';
import { getSortedListByString } from '../utils/sortUtils';

interface RefItemObj {
  categoryMap: RefItemLangMap;
  category: RefItem;
  name: string;
}

export const useRefItemsMapSort = (refItemsMap: RefItemsMap, language: string) => {
  const [sortedList, setSortedList] = useState<RefItemObj[] | null>(null);

  const categoryValues = refItemsMap.order
    .map(index => refItemsMap.categories[index][language].value)
    .join();

  useEffect(() => {
    setSortedList(
      getSortedListByString(
        refItemsMap.order.map(id => {
          const categoryMap = refItemsMap.categories[id];
          const category = categoryMap[language];
          const name = category.value;

          return {
            categoryMap,
            category,
            name,
          };
        }),
        'name'
      )
    );
  }, [categoryValues, language, refItemsMap]);

  return {
    sortedList,
  };
};

interface CategoriesObj {
  categoryMap: CategoryLangMap;
  category: IDValueType<string>;
  name: string;
}

export const useCategoriesMapSort = (categoriesMap: CategoriesMap, language: string) => {
  const [sortedList, setSortedList] = useState<CategoriesObj[] | null>(null);

  const categoryValues = categoriesMap.order
    .map(index => categoriesMap.categories[index][language].value)
    .join();

  useEffect(() => {
    setSortedList(
      getSortedListByString(
        categoriesMap.order.map(id => {
          const categoryMap = categoriesMap.categories[id];
          const category = categoryMap[language];
          const name = category.value;

          return {
            categoryMap,
            category,
            name,
          };
        }),
        'name'
      )
    );
  }, [categoryValues, language, categoriesMap]);

  return {
    sortedList,
  };
};
