import cx from 'clsx';
import { TableCell, TableRow } from '../../../../../../components/Table';
import { TableRowProps } from '../../../../../../components/Table/components/TableRow/TableRow';
import React from 'react';
import { SingleLineRTEditor, SuggestionType } from 'vapi-ui-common';
import styles from './totalRow.module.scss';

interface PackageTotalProps extends TableRowProps {
  isRequired?: boolean;
  isDelete?: boolean;
}

const PackageTotalRow = ({ isRequired, isDelete, ...rest }: PackageTotalProps) => {
  return (
    <TableRow
      className={cx(
        isRequired ? styles.packageTotalRequirements : styles.packageTotal,
        isDelete && styles.deleted
      )}
      {...rest}
    />
  );
};

interface PackageTotalCellProps {
  value: string;
  disabled?: boolean;
  suggestionTypes?: SuggestionType[];
  onBlur?: (value: string) => void;
}

const PackageTotalCell = (props: PackageTotalCellProps) => (
  <TableCell colType="applicability">
    <SingleLineRTEditor disableToolbar editorStyles={styles.packageTotalInput} {...props} />
  </TableCell>
);
export { PackageTotalRow, PackageTotalCell };
