import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { BnPReviewRowContext } from '..';
import { ChangeLogBeforeAfter } from '../../../../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import useModelApplicabilityMap, {
  ModelApplicabilityMap,
  ModelApplicabilityModel,
} from '../../../../../../../hooks/useModelApplicability';
import useStores from '../../../../../../../hooks/useStores';
import { BnpCategorySplitReview } from '../../../../../../../models/buildAndPrice.model';
import { KeyValueType } from '../../../../../../../models/common.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../models/vehicleModel.model';
import { getModelApplicabilityMetadataMap } from '../../../../../../../utils/vehicleDataUtils';
import VDTableCell from '../../../../../components/tableCells/VDTableCell';
import styles from '../bnPReviewTable.module.scss';

interface ApplicableModelCellProps {
  split?: BnpCategorySplitReview;
}

const ApplicableModelCell = ({ split }: ApplicableModelCellProps) => {
  const context = useContext(BnPReviewRowContext);
  const {
    vehicleModelsStore: { vehicleModels },
  } = useStores();
  const {
    generateModelApplicabilityMap,
    isCheckedGrade,
    allCheckedInfo,
  } = useModelApplicabilityMap();

  if (!context) {
    return null;
  }

  const {
    category: {
      itemKey,
      applicability: { before, after, hasChanged },
      categoryKey,
    },
  } = context;

  const isCheckedModel = (
    model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>,
    applicability: KeyValueType<string>
  ) => {
    if (split) {
      return applicability[model.id] === split.id;
    }
    return !!applicability[model.id] && applicability[model.id] !== 'default';
  };

  const applicabilityMapBefore = generateModelApplicabilityMap({
    isCheckedModel: model => isCheckedModel(model, before),
  });
  const applicabilityMapAfter = generateModelApplicabilityMap({
    isCheckedModel: model => isCheckedModel(model, after),
  });

  const toModelListCodes = (
    includeModels: ModelApplicabilityModel[],
    excludedModels: ModelApplicabilityModel[],
    numChecked: number
  ) => {
    if (!numChecked && !excludedModels.length) {
      return 'All';
    }

    return includeModels
      .filter(({ isChecked }) => !numChecked || !isChecked)
      .map(({ model }) => model.getVal('code'))
      .join();
  };

  const getDefaultGradesList = (
    applicabilityMap: ModelApplicabilityMap,
    applicability: KeyValueType<string>
  ) => {
    const { numCheckedModels } = allCheckedInfo(applicabilityMap);
    if (itemKey === 'grade' || !numCheckedModels) {
      return 'All';
    }

    const gradesMap: ModelApplicabilityMap = {};
    Object.values(applicabilityMap).forEach(value => {
      const { grade } = value;
      if (!isCheckedGrade(applicabilityMap, grade.id)) {
        gradesMap[grade.id] = value;
      }
    });

    const metadataList = Object.values(
      getModelApplicabilityMetadataMap(
        gradesMap,
        { applicability, categoryValue: categoryKey, name: itemKey },
        vehicleModels,
        [],
        true
      )
    ).filter(
      value =>
        !value.modelApplicability.numChecked ||
        value.includedModels.filter(({ isChecked }) => !isChecked).length
    );

    if (!metadataList.length) {
      return <div>None</div>;
    }

    return metadataList.map(value => {
      const { grade, numChecked } = value.modelApplicability;
      return (
        <li key={grade.id}>
          {grade.value} ({toModelListCodes(value.includedModels, value.excludedModels, numChecked)})
        </li>
      );
    });
  };

  const getDefaultApplicableModelsMap = (
    applicabilityMap: ModelApplicabilityMap,
    applicability: KeyValueType<string>
  ) => {
    const { numCheckedModels } = allCheckedInfo(applicabilityMap);

    const gradesMap: ModelApplicabilityMap = {};
    Object.values(applicabilityMap).forEach(value => {
      const { grade } = value;
      if (!numCheckedModels || !isCheckedGrade(applicabilityMap, grade.id)) {
        gradesMap[grade.id] = value;
      }
    });

    const metadataList = Object.values(
      getModelApplicabilityMetadataMap(
        gradesMap,
        { applicability, categoryValue: categoryKey, name: itemKey },
        vehicleModels,
        [],
        true
      )
    ).filter(
      value =>
        !value.modelApplicability.numChecked ||
        value.includedModels.filter(({ isChecked }) => !isChecked).length
    );

    const applicableModelsMap: KeyValueType<true> = {};
    for (const value of metadataList) {
      const { numChecked } = value.modelApplicability;
      for (const mdl of value.includedModels) {
        if (!numChecked || !mdl.isChecked || !numCheckedModels) {
          applicableModelsMap[mdl.model.getVal('code')] = true;
        }
      }
    }

    return applicableModelsMap;
  };

  const toSplitModelListCodes = (
    includedModels: ModelApplicabilityModel[],
    excludedModels: ModelApplicabilityModel[],
    gradeId: string,
    applicabilityMap: ModelApplicabilityMap
  ) => {
    const gradeChecked = isCheckedGrade(applicabilityMap, gradeId);
    if (gradeChecked && !excludedModels.length) {
      return 'All';
    }

    return includedModels
      .filter(({ isChecked }) => gradeChecked || isChecked)
      .map(({ model }) => model.getVal('code'))
      .join();
  };

  const getSplitGradesList = (
    applicabilityMap: ModelApplicabilityMap,
    applicability: KeyValueType<string>
  ) => {
    const gradesMap: ModelApplicabilityMap = {};
    Object.values(applicabilityMap).forEach(value => {
      const { grade, numChecked } = value;
      if (numChecked) {
        gradesMap[grade.id] = value;
      }
    });
    const metadataList = Object.values(
      getModelApplicabilityMetadataMap(
        gradesMap,
        { applicability, categoryValue: categoryKey, name: itemKey },
        vehicleModels,
        [],
        true
      )
    ).filter(
      value =>
        isCheckedGrade(applicabilityMap, value.modelApplicability.grade.id) ||
        value.includedModels.filter(({ isChecked }) => isChecked).length
    );

    if (!metadataList.length) {
      return <div>None</div>;
    }

    return metadataList.map(value => {
      const { grade } = value.modelApplicability;
      return (
        <li key={grade.id}>
          {grade.value} (
          {toSplitModelListCodes(
            value.includedModels,
            value.excludedModels,
            grade.id,
            applicabilityMap
          )}
          )
        </li>
      );
    });
  };

  const getSplitApplicableModelsMap = (
    applicabilityMap: ModelApplicabilityMap,
    applicability: KeyValueType<string>
  ) => {
    const gradesMap: ModelApplicabilityMap = {};
    Object.values(applicabilityMap).forEach(value => {
      const { grade, numChecked } = value;
      if (numChecked) {
        gradesMap[grade.id] = value;
      }
    });
    const metadataList = Object.values(
      getModelApplicabilityMetadataMap(
        gradesMap,
        { applicability, categoryValue: categoryKey, name: itemKey },
        vehicleModels,
        [],
        true
      )
    ).filter(
      value =>
        isCheckedGrade(applicabilityMap, value.modelApplicability.grade.id) ||
        value.includedModels.filter(({ isChecked }) => isChecked).length
    );

    const applicableModelsMap: KeyValueType<true> = {};
    for (const value of metadataList) {
      const { grade } = value.modelApplicability;
      const gradeChecked = isCheckedGrade(applicabilityMap, grade.id);
      for (const mdl of value.includedModels) {
        if (mdl.isChecked || gradeChecked) {
          applicableModelsMap[mdl.model.getVal('code')] = true;
        }
      }
    }

    return applicableModelsMap;
  };

  const getHasChanged = () => {
    if (!hasChanged || itemKey === 'grade') {
      return false;
    }

    const beforeMap: KeyValueType<true> = split
      ? getSplitApplicableModelsMap(applicabilityMapBefore, before)
      : getDefaultApplicableModelsMap(applicabilityMapBefore, before);
    const afterMap: KeyValueType<true> = split
      ? getSplitApplicableModelsMap(applicabilityMapAfter, after)
      : getDefaultApplicableModelsMap(applicabilityMapAfter, after);
    const beforeCodes = Object.keys(beforeMap);
    for (const code of beforeCodes) {
      if (!afterMap[code]) {
        return true;
      }
    }
    const afterCodes = Object.keys(afterMap);
    for (const code of afterCodes) {
      if (!beforeMap[code]) {
        return true;
      }
    }

    return false;
  };

  const getBefore = () => {
    if (!getHasChanged()) {
      return '';
    }

    if (split) {
      return split.isNew
        ? ''
        : () => (
            <ul className={styles.noMargin}>
              {getSplitGradesList(applicabilityMapBefore, before)}
            </ul>
          );
    }

    return () => (
      <ul className={styles.noMargin}>{getDefaultGradesList(applicabilityMapBefore, before)}</ul>
    );
  };

  const getAfter = () => {
    if (split) {
      return () => (
        <ul className={styles.noMargin}>{getSplitGradesList(applicabilityMapAfter, after)}</ul>
      );
    }

    return () => (
      <ul className={styles.noMargin}>{getDefaultGradesList(applicabilityMapAfter, after)}</ul>
    );
  };

  return (
    <VDTableCell colType="dropdown" className={styles.applicabilityCell}>
      <ChangeLogBeforeAfter before={getBefore()} after={getAfter()} styleAfter={getHasChanged()} />
    </VDTableCell>
  );
};

export default observer(ApplicableModelCell);
