import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { BnPReviewRowContext } from '..';
import { ChangeLogBeforeAfter } from '../../../../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import { BnpCategorySplitReview } from '../../../../../../../models/buildAndPrice.model';
import VDTableCell from '../../../../../components/tableCells/VDTableCell';
import styles from '../bnPReviewTable.module.scss';

interface GradeOrDescriptionCellProps {
  split?: BnpCategorySplitReview;
}

const GradeOrDescriptionCell = ({ split }: GradeOrDescriptionCellProps) => {
  const context = useContext(BnPReviewRowContext);

  if (!context) {
    return null;
  }

  const {
    category: { itemLabel, copy, title, description },
  } = context;

  return (
    <>
      {itemLabel === 'Grade' && (
        <VDTableCell colType="dropdown" className={styles.descriptionCell}>
          <div className={styles.grade}>
            <label role="presentation">Title:</label>
            <div className={styles.changes}>
              <ChangeLogBeforeAfter
                before={title.before}
                after={title.after}
                styleAfter={title.hasChanged}
              />
            </div>
          </div>
          <div className={styles.grade}>
            <label role="presentation">Copy:</label>
            <div className={styles.changes}>
              <ChangeLogBeforeAfter
                before={copy.before}
                after={copy.after}
                styleAfter={copy.hasChanged}
              />
            </div>
          </div>
        </VDTableCell>
      )}

      {itemLabel !== 'Grade' && !split && (
        <VDTableCell colType="dropdown" className={styles.descriptionCell}>
          <ChangeLogBeforeAfter
            before={description.before}
            after={description.after}
            styleAfter={description.hasChanged}
          />
        </VDTableCell>
      )}

      {itemLabel !== 'Grade' && split && (
        <VDTableCell colType="dropdown" className={styles.descriptionCell}>
          <ChangeLogBeforeAfter
            before={split.description.before}
            after={split.description.after}
            styleAfter={split.description.hasChanged}
          />
        </VDTableCell>
      )}
    </>
  );
};

export default observer(GradeOrDescriptionCell);
