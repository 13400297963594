import React from 'react';
import styles from './KeyFeature.module.scss';

const KeyFeatureDialogHeader = () => {
  return (
    <header className={styles.header} data-testid="key-feature-dialog-header">
      <div className={styles.headerContent} data-testid="key-feature-dialog-header-content">
        <span className={styles.headerText} data-testid="key-feature-dialog-header-content-text">
          Key Feature Description(s)
        </span>
      </div>
    </header>
  );
};

export default KeyFeatureDialogHeader;
