import { observer } from 'mobx-react-lite';
import React from 'react';
import { Table } from '../../../../components/Table/Table';
import { CFColorItem, ColorFamilyItem } from '../../../../models/colorFamilies.model';
import { RefItem } from '../../../../models/refItem.model';
import CFColorRow from '../CFColorRow';
import CFColorsTableHeaderRow from '../CFColorsTableHeaderRow';

interface CFColorsTableProps {
  readOnly: boolean;
  colors: CFColorItem[];
  colorFamilies: ColorFamilyItem[];
  saveColorItem: (color: CFColorItem) => void;
  deleteColorItem: (color: CFColorItem) => void;
  setValidationMessage: (value: string) => void;
  onAddMaterial?: (value: string) => void;
  onUpdateMaterial?: (material: RefItem) => void;
  materials?: RefItem[];
  hideMaterial?: boolean;
  onSort?: (field: string, isReverse: boolean) => void;
}

const CFColorsTable = ({
  readOnly,
  colors,
  colorFamilies,
  saveColorItem,
  deleteColorItem,
  onAddMaterial,
  onUpdateMaterial,
  materials = [],
  hideMaterial = false,
  onSort = () => {},
  setValidationMessage,
}: CFColorsTableProps) => {
  return (
    <>
      <CFColorsTableHeaderRow hideMaterial={hideMaterial} onSort={onSort} />
      <Table fullWidth>
        {colors.map(interiorColor => (
          <CFColorRow
            key={interiorColor.uid}
            readOnly={readOnly}
            colorFamilies={colorFamilies}
            color={interiorColor}
            deleteColorItem={deleteColorItem}
            saveColorItem={saveColorItem}
            materials={materials}
            onAddMaterial={onAddMaterial}
            onUpdateMaterial={onUpdateMaterial}
            hideMaterial={hideMaterial}
            setValidationMessage={setValidationMessage}
          />
        ))}
      </Table>
    </>
  );
};

export default observer(CFColorsTable);
