import React, { createContext, forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'vapi-ui-common';
import {
  AllCheckedInfo,
  ModelApplicabilityMap,
} from '../../../../../../hooks/useModelApplicability';
import useSplitModelApplicability from '../../../../../../hooks/useSplitModelApplicability';
import { BnPCategoryItem, BnpCategorySplit } from '../../../../../../models/buildAndPrice.model';
import { Language } from '../../../../../../models/user.model';
import DeleteModal from './DeleteModal';
import SaveModal from './SaveModal';

interface SplitModelApplicabilityModalProps {}
interface SplitModelApplicabilityModalContextProps {
  isCheckedGrade: (gradeId: string) => boolean;
  setCheckedAll: (isChecked: boolean) => void;
  allCheckedInfo: () => AllCheckedInfo;
  setIsSaving: (isSaving: boolean) => void;
  onClose: () => void;
  lang?: Language;
  splitModelApplicabilityMap: ModelApplicabilityMap;
  category: BnPCategoryItem; // current category shown on splitmodelapplicabilitymodal
  isSaving: boolean;
  split?: BnpCategorySplit;
}

export interface SplitModelApplicabilityModalRefProps {
  openCreate: (category: BnPCategoryItem, lang: Language) => void;
  openEdit: (category: BnPCategoryItem, split: BnpCategorySplit, lang: Language) => void;
  openDelete: (category: BnPCategoryItem, split: BnpCategorySplit, lang: Language) => void;
}

export const SplitModelApplicabilityModalContext = createContext<SplitModelApplicabilityModalContextProps | null>(
  null
);

const SplitModelApplicabilityModal = forwardRef((props: SplitModelApplicabilityModalProps, ref) => {
  const [lang, setLang] = useState<Language>();
  const [category, setCategory] = useState<BnPCategoryItem>();
  const [split, setSplit] = useState<BnpCategorySplit>();
  const [show, setShow] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const {
    splitModelApplicabilityMap,
    isCheckedGrade,
    allCheckedInfo,
    setCheckedAll,
  } = useSplitModelApplicability({ category, split });

  const open = (category: BnPCategoryItem, lang: Language) => {
    setShow(true);
    setCategory(category);
    setLang(lang);
  };

  useImperativeHandle(
    ref,
    (): SplitModelApplicabilityModalRefProps => ({
      openCreate(category: BnPCategoryItem, lang: Language) {
        open(category, lang);
      },
      openEdit(category: BnPCategoryItem, split: BnpCategorySplit, lang: Language) {
        open(category, lang);
        setSplit(split);
      },
      openDelete(category: BnPCategoryItem, split: BnpCategorySplit, lang: Language) {
        open(category, lang);
        setSplit(split);
        setIsDelete(true);
      },
    })
  );

  if (!category) {
    return null;
  }

  const onClose = () => {
    setShow(false);
    setIsSaving(false);
    setIsDelete(false);
    setSplit(undefined);
    setCategory(undefined);
  };

  return (
    <SplitModelApplicabilityModalContext.Provider
      value={{
        isCheckedGrade,
        allCheckedInfo,
        setCheckedAll,
        setIsSaving,
        onClose,
        lang,
        category,
        splitModelApplicabilityMap,
        split,
        isSaving,
      }}
    >
      <Modal open={show} size="auto" onClose={onClose}>
        {!isDelete && <SaveModal />}
        {isDelete && <DeleteModal />}
      </Modal>
    </SplitModelApplicabilityModalContext.Provider>
  );
});

export default SplitModelApplicabilityModal;
