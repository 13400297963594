import React, { useCallback } from 'react';
import 'react-calendar/dist/Calendar.css';
import { DropdownList } from 'vapi-ui-common';
import {
  GO_LIVE_DATE_TIME,
  GoLiveDateTime,
  goLiveDateTimeMap,
} from '../../../../../utils/dateUtils';
import styles from '../modelsModal.module.scss';
import GoLiveDateTimePickerList from './GoLiveDateTimePickerList';

interface IGoLiveDateTimePicker {
  currentTime?: GO_LIVE_DATE_TIME;
  onChange: (time: GoLiveDateTime) => void;
  timePicker?: boolean;
  disabled?: boolean;
}

const GoLiveDateTimePicker = ({
  currentTime = GO_LIVE_DATE_TIME.AM_5,
  onChange,
  timePicker,
  disabled,
}: IGoLiveDateTimePicker) => {
  const contentElement = useCallback(
    (ref: any, open: boolean, closeDropdown: () => void) => (
      <GoLiveDateTimePickerList
        open={open}
        closeDropdown={closeDropdown}
        currentTime={goLiveDateTimeMap[currentTime]}
        onChange={onChange}
        ref={ref}
      />
    ),
    [onChange, currentTime]
  );

  if (!timePicker) return null;

  return (
    <div className={styles.timePicker}>
      <DropdownList
        value={goLiveDateTimeMap[currentTime].text}
        className={styles.time}
        btnClass={styles.cta}
        contentElement={contentElement}
        disabled={disabled}
      />
      <span className={styles.zone}>Central Time Zone</span>
    </div>
  );
};

export default GoLiveDateTimePicker;
