import apolloClient from '../apolloClient';
import { DphMap, DphRequest } from '../models/dph.model';
import {
  CreateDphCategoryDocument,
  CreateDphCategoryMutation,
  CreateDphCategoryMutationVariables,
  DeleteDphCategoryDocument,
  DeleteDphCategoryMutation,
  DeleteDphCategoryMutationVariables,
  ReadDphCategoriesDocument,
  ReadDphCategoriesQuery,
  ReadDphCategoriesQueryVariables,
  PublishDphDocument,
  PublishDphMutation,
  PublishDphMutationVariables,
  UpdateDphCategoryDocument,
  UpdateDphCategoryMutation,
  UpdateDphCategoryMutationVariables,
} from '../gql/generated';

export const getDph = async (variables: ReadDphCategoriesQueryVariables): Promise<DphMap> => {
  const { data } = await apolloClient.query<
    ReadDphCategoriesQuery,
    ReadDphCategoriesQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: ReadDphCategoriesDocument,
    variables,
  });

  return data.readDPHCategories.body;
};

export const addDph = async (
  variables: CreateDphCategoryMutationVariables
): Promise<DphRequest> => {
  const { data } = await apolloClient.mutate<
    CreateDphCategoryMutation,
    CreateDphCategoryMutationVariables
  >({
    mutation: CreateDphCategoryDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add DPH');
  }

  return data.createDPHCategory.body;
};

export const updateDph = async (
  variables: UpdateDphCategoryMutationVariables
): Promise<DphRequest> => {
  const { data } = await apolloClient.mutate<
    UpdateDphCategoryMutation,
    UpdateDphCategoryMutationVariables
  >({
    mutation: UpdateDphCategoryDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update DPH');
  }

  return data.updateDPHCategory.body;
};

export const deleteDph = async (variables: DeleteDphCategoryMutationVariables): Promise<DphMap> => {
  const { data } = await apolloClient.mutate<
    DeleteDphCategoryMutation,
    DeleteDphCategoryMutationVariables
  >({
    mutation: DeleteDphCategoryDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete DPH');
  }

  return data.deleteDPHCategory.body;
};

export const publishDph = async (variables: PublishDphMutationVariables): Promise<any> => {
  const { data } = await apolloClient.mutate<PublishDphMutation, PublishDphMutationVariables>({
    mutation: PublishDphDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to publish DPH');
  }

  return data.publishDPH.body;
};
