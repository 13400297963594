import React, { useCallback } from 'react';
import { LIMITED_DATA_STATUS } from '../constants/vehicleData/VDConstants';
import { KeyValueType } from '../models/common.model';
import { FeatureLangMap } from '../models/features.model';
import { OptionLangMap } from '../models/options.model';
import { SpecsLangMap } from '../models/specs.model';
import { Language } from '../models/user.model';
import { getTable } from '../utils/limitedDataStatusUtils';
import { ModelDirectory } from '../models/vehicleModel.model';
import ExpandList from '../components/ExpandList';

const useModelCodesTable = (
  modelMapStore: (FeatureLangMap | SpecsLangMap | OptionLangMap)[],
  language: Language,
  models: ModelDirectory,
  tableId: number = 0,
  checkboxTables: KeyValueType<boolean>[] = [],
  onClickAll: (tableIds: number[]) => () => void = (tableIds: number[]) => () => ({}),
  onClickRow: (tableId: number, id: string) => () => void = (
    tableId: number,
    id: string
  ) => () => ({})
) => {
  const getModelCodesTable = useCallback(() => {
    const ids: string[] = [];
    const headers = ['Model Codes', 'Description', 'Publish Status'];
    const boldColumns = [false, false];
    const isRichTexts = [false, true];
    const list = modelMapStore.reduce(
      (total: (string | JSX.Element)[][][], obj: FeatureLangMap | SpecsLangMap | OptionLangMap) => {
        const data = obj.langs[language];
        const modelsId = Object.keys(data.fieldStatus.modelApplicability);
        const textCells = [];

        if (
          data.fieldStatus.status === LIMITED_DATA_STATUS.READY_TO_PUBLISH ||
          data.fieldStatus.status === LIMITED_DATA_STATUS.PUBLISHED
        ) {
          const modelCodes: string[] = [];

          modelsId.forEach(uid => {
            if (
              data.fieldStatus.modelApplicability[uid] === LIMITED_DATA_STATUS.IN_PROGRESS &&
              models[uid]
            ) {
              modelCodes.push(models[uid].getVal('code'));
            }
          });

          if (modelCodes.length) {
            textCells.push([<ExpandList min={6} list={modelCodes} />]);
            textCells.push([data.description]);
            ids.push(data.fieldStatus.id);
            total.push(textCells);
          }
        } else if (data.fieldStatus.status === LIMITED_DATA_STATUS.IN_PROGRESS) {
          textCells.push(['All Models']);
          textCells.push([data.description]);
          ids.push(data.fieldStatus.id);
          total.push(textCells);
        }

        return total;
      },
      []
    );

    return getTable(
      [ids],
      headers,
      [list],
      boldColumns,
      isRichTexts,
      [tableId],
      checkboxTables,
      onClickAll,
      onClickRow
    );
  }, [modelMapStore, language, models, checkboxTables, tableId, onClickAll, onClickRow]);

  return { getModelCodesTable };
};

export default useModelCodesTable;
