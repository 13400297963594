import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, ModalBody, ModalFooter } from 'vapi-ui-common';
import { capitalizeFirstLetter } from '../../../../../../../utils/compareFeaturesUtils';
import styles from './unlinkCompareModal.module.scss';

interface UnlinkCompareModalProps {
  closePopover?: () => void;
  unlinkCompareFeatureItem: (shouldDelete: boolean) => void;
  type: string;
  description: string;
}

const UnlinkCompareModal = observer(
  ({ closePopover, unlinkCompareFeatureItem, type, description }: UnlinkCompareModalProps) => {
    const close = () => {
      closePopover && closePopover();
    };
    return (
      <div className={styles.container}>
        <div className={styles.modalHeader}>
          Unlink <span>{capitalizeFirstLetter(type)}</span>
        </div>
        <ModalBody>
          <div className={styles.bodyText}>
            Are you sure you want to unlink {type} '
            {description.length > 16 ? description.substr(0, 25) + '...' : description}'
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            onClick={() => {
              close();
              unlinkCompareFeatureItem(false);
            }}
          >
            Unlink
          </Button>
          <Button variant="transparent" onClick={() => close()}>
            Cancel
          </Button>
        </ModalFooter>
      </div>
    );
  }
);

export default UnlinkCompareModal;
