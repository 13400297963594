import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { cleanUpRte } from 'vapi-ui-common';
import { removeNulls } from '../utils';
import { BaseReviewItem, IDValueType, KeyValueType } from './common.model';
import { FeatureSettings } from './features.model';
import { ReviewChangeMap, ReviewChangeResponse, ReviewChangeTypeMap } from './review.model';
import { ISortList } from './sort.model';
import { Language } from './user.model';
import { LIMITED_DATA_STATUS } from '../constants/vehicleData/VDConstants';
import { GradeFieldStatus } from './vehicleModel.model';

export enum CompareType {
  Feature = 'feature',
  Spec = 'spec',
}
export class CompareFeatureItem {
  uid = '';
  id = '';
  revId = '';
  @observable compareType = CompareType.Feature;
  @observable parentId = '';
  @observable gradeApplicability = {} as CompareGradeApplicabilityMap;
  @observable category = {} as IDValueType;
  @observable subCategory = {} as IDValueType;
  @observable description = '';
  @observable isInProgress = false;
  @observable notes = '';
  @observable sortOrder: string | number = 0;
  @observable rejectNotes = '';
  @observable changedAttributes: string[] = [];
  @observable changedGradeIds: string[] = [];
  @observable fieldStatus: CompareFieldStatusRequest = {
    id: '',
    status: LIMITED_DATA_STATUS.READY_TO_PUBLISH,
    gradeApplicability: {},
  };

  constructor(compareFeature?: CompareFeatureResponse) {
    this.uid = uuidv4();
    if (compareFeature) {
      Object.assign(this, removeNulls(compareFeature));
      this.changedAttributes.forEach(attr => {
        if (attr.includes('gradeApplicability#')) {
          const splt = attr.split('#');
          this.changedGradeIds.push(splt[1]);
        }
      });
    }
  }

  getPayload = () => {
    const gradeApplicability = JSON.parse(
      JSON.stringify(this.gradeApplicability)
    ) as CompareGradeApplicabilityMap;

    const gradeApplicabilityPayload: CompareGradeApplicabilityMap = {};

    for (const [key, gradeApp] of Object.entries(gradeApplicability)) {
      if (gradeApp.applicability) {
        gradeApp.text = gradeApp.text === '' ? null : gradeApp.text;
        gradeApplicabilityPayload[key] = gradeApp;
      }
    }

    return {
      id: this.id,
      revId: this.revId,
      parentId: this.parentId,
      compareType: this.compareType,
      categoryId: this.category.id,
      subCategoryId: this.subCategory.id,
      description: cleanUpRte(this.description),
      isInProgress: this.isInProgress,
      notes: this.notes,
      gradeApplicability: gradeApplicabilityPayload,
    } as CompareFeatureRequest;
  };

  isValid = () => !!(this.category.id && this.description && this.subCategory.id);
}

export type CompareFieldStatusRequest = GradeFieldStatus;

export class CompareFeaturesReviewItem extends BaseReviewItem {
  @observable subCategory = {} as IDValueType;
  @observable gradeApplicability = {} as CompareGradeApplicabilityMap;

  constructor(compareFeature?: CompareFeaturesReviewResponse, change?: ReviewChangeResponse) {
    super();
    this.uid = uuidv4();
    if (compareFeature) {
      Object.assign(this, removeNulls(compareFeature));
    }
    this.destructureReviewChangeResponse(change);
    // this.createOtherChanges(compareFeature);
  }
}

export interface CompareFeaturesReviewResponse {
  id: string;
  revId: string;
  isAccepted: boolean;
  isApplied: boolean;
  parentId?: string;
  compareType: CompareType;
  category?: string;
  categoryId: string;
  subCategory?: string;
  subCategoryId: string;
  description: string;
  notes?: string;
  rejectNotes?: string;
  isInProgress: boolean;
  gradeApplicability: CompareGradeApplicabilityMap;
  isDeleted: boolean;
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
}

export interface CompareFeaturesReviewMap {
  [id: string]: CompareFeaturesChangeTypeMap;
}

export interface CompareFeaturesChangeTypeMap extends ReviewChangeTypeMap {
  category: ReviewChangeMap<string>;
  subCategory: ReviewChangeMap<string>;
  description: ReviewChangeMap<string>;
  gradeApplicability: ReviewChangeMap<CompareGradeApplicabilityMap>;
}

export type CompareFeatureReviewType =
  | 'category'
  | 'subCategory'
  | 'description'
  | 'gradeApplicability'
  | 'added'
  | 'deleted';

export interface CompareFeatureDocumentResponse extends IDoubleSortList {
  compareFeatures: CompareFeatureResponse[];
}

export interface IDoubleSortList {
  doubleSortList: HighlightSortList;
}

export interface HighlightSortList {
  [grade: string]: ISortList;
}

export interface CompareFeatureResponse {
  id: string;
  revId: string;
  gradeApplicability: CompareGradeApplicabilityMap;
  categoryId: string;
  subCategoryId: string;
  description: string;
  isInProgress: boolean;
  notes: string | undefined;
  parentId: string | undefined;
  compareType: CompareType;
  changedAttributes?: string[];
  fieldStatus: GradeFieldStatus;
}

export interface CompareFeatureRequest extends CompareFeatureResponse {}

export interface CompareGradeApplicabilityMap {
  [gradeId: string]: CompareGradeApplicability;
}

export interface CompareGradeApplicability {
  highlighted: boolean;
  text?: string | null;
  applicability: FeatureSettings | null;
}

export interface CompareGradeSettingsCell {
  id: string;
  setting: FeatureSettings;
  highlighted: boolean;
  defaultText: string;
  value: string;
  featureId: string;
  highlightSortOrder?: number;
  applicabilityTextMap: ApplicabilityTextLangMap;
}

export interface CompareFeaturesMap {
  compareFeatures: {
    [id: string]: CompareFeatureLangMap;
  };
  order: string[];
}

export interface CompareFeatureLangMap {
  langs: {
    [lang: string]: CompareFeatureItem;
  };
  data?: CompareFeatureResponse;
}

export interface ApplicabilityTextLangMap {
  text: { [k in Language]?: { text: string; canEdit: boolean; syncValueChange?: boolean } };
}
