import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { convertToRichTextObject } from 'vapi-ui-common';
import { removeNulls } from '../utils';
import { ChangeLogBase } from './changeLog.model';
import { IDValueType, KeyValueType } from './common.model';
import { ReviewChangeBaseItem, ReviewChangeResponse } from './review.model';
import {
  CreateExtColorLexusInput,
  CreateIntColorLexusInput,
  UpdateExtColorLexusInput,
  UpdateIntColorLexusInput,
} from '../gql/generated';

export class ColorsLexusExteriorItem {
  uid = uuidv4();
  id = '';
  revId = '';
  @observable name = '';
  @observable code = '';
  @observable hexCode = '';
  @observable isExtraCost = '';
  @observable notes = '';
  @observable rejectNotes = '';
  @observable isInProgress = false;
  @observable colorApplicability: ExteriorAccessibilityGroup[] = [];
  @observable changes: ReviewChangeBaseItem[] = [];
  @observable otherChanges: ChangeLogBase[] = [];
  @observable sortOrder = 0;

  constructor(response?: VDExteriorColorLexus) {
    if (response) {
      const { colorApplicability, changes, ...rest } = response;
      Object.assign(this, removeNulls(rest));
    }
  }

  isValid = () => {
    return !!(this.name && this.code);
  };

  getCreatePayload = (): CreateExtColorLexusInput => {
    const colorApplicability: {
      interiorColorId: string;
      groupId: string;
    }[] = [];

    this.colorApplicability.forEach(appl => {
      appl.interiors.forEach(interior => {
        if (interior.value) {
          colorApplicability.push({
            interiorColorId: interior.id,
            groupId: appl.group.id,
          });
        }
      });
    });

    return {
      name: convertToRichTextObject(this.name).text,
      code: this.code,
      hexCode: this.hexCode,
      notes: this.notes,
      isExtraCost: this.isExtraCost,
      isInProgress: this.isInProgress,
      colorApplicability,
    };
  };

  getUpdatePayload = (): UpdateExtColorLexusInput => {
    return {
      id: this.id,
      revId: this.revId,
      ...this.getCreatePayload(),
    };
  };
}

export class InteriorGroupItem {
  uid = uuidv4();
  id = '';
  revId = '';
  @observable name = '';
  @observable models = {} as InteriorGroupModels;
  @observable isDeleted = false;

  isValid = () => {
    return this.name !== '' && !this.models.length;
  };

  getPayload = (): InteriorGroupRequest => {
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      models: this.models,
    };
  };
}

export class ExteriorAccessibilityGroup {
  uid = uuidv4();
  @observable group = {} as InteriorGroupItem;
  @observable interiors: IDValueType<boolean>[] = [];
}

export type InteriorColorTypesLexus = {
  groups: InteriorGroupItem[];
  interiorTypes: IDValueType[];
  materials: IDValueType[];
  ornaments: IDValueType[];
};

export type GroupItem = {
  [groupId: string]: InteriorGroupResponseItem;
};

export class InteriorGroup {
  uid = uuidv4();
  @observable group = new InteriorGroupItem();
  @observable materials: InteriorMaterial[] = [];
}

export class InteriorMaterial {
  uid = uuidv4();
  @observable material = new IDValueType('', '');
  @observable categories: InteriorCategory[] = [];

  getPayload = (categoryUid: string): VDInteriorCategoryRequest | undefined => {
    const category = this.categories.find(item => item.uid === categoryUid);
    if (category) {
      return {
        ...category.getPayload(),
        material: this.material.value,
        materialId: this.material.id,
      };
    }
  };
}

export type InteriorLexusTypes = {
  groups: InteriorGroupItem[];
  materials: IDValueType[];
  ornaments: IDValueType[];
  interiorTypes: IDValueType[];
};

export class InteriorCategory {
  uid = uuidv4();
  id = '';
  revId = '';
  @observable material = new IDValueType('', '');
  @observable interiorType = new IDValueType('', '');
  @observable interiorType_subId = '';
  @observable ornamentType1 = new IDValueType('', '');
  @observable ornamentType1_subId = '';
  @observable ornamentType2 = new IDValueType('', '');
  @observable ornamentType2_subId = '';
  @observable seatAccent = '';
  @observable armRest = '';
  @observable headliner = '';
  @observable ip = '';
  @observable carpet = '';
  @observable rejectNotes = '';
  @observable groups: InteriorGroupItem[] = [];
  @observable changes: ReviewChangeBaseItem[] = [];
  @observable otherChanges: ChangeLogBase[] = [];
  @observable isExtraCost = '';

  getPayload = () => {
    const { uid, groups, material, interiorType, ornamentType1, ornamentType2, ...rest } = this;
    const mapGroupApplicability = {} as LexusIntColorGroupModelApplicability;
    groups.forEach(group => {
      mapGroupApplicability[group.id] = group.models;
    });
    Object.assign(this, removeNulls(rest));
    return {
      material: material.value,
      materialId: material.id,
      interiorType: interiorType.value,
      interiorTypeId: interiorType.id,
      ornamentType1: ornamentType1.value,
      ornamentType1Id: ornamentType1.id,
      ornamentType2: ornamentType2.value,
      ornamentType2Id: ornamentType2.id,
      groupApplicability: mapGroupApplicability,
      ...rest,
    };
  };

  getCreatePayload = (): CreateIntColorLexusInput => {
    return {
      materialId: this.material.id,
      interiorTypeId: this.interiorType.id,
      interiorType_subId: this.interiorType_subId,
      ornamentType1Id: this.ornamentType1.id,
      ornamentType1_subId: this.ornamentType1_subId,
      ornamentType2Id: this.ornamentType2.id,
      ornamentType2_subId: this.ornamentType2_subId,
      isExtraCost: this.isExtraCost,
      seatAccent: this.seatAccent,
      armRest: this.armRest,
      headliner: this.headliner,
      ip: this.ip,
      carpet: this.carpet,
      groupApplicability: this.groups.reduce<LexusIntColorGroupModelApplicability>((acc, group) => {
        acc[group.id] = group.models;
        return acc;
      }, {}),
    };
  };

  getUpdatePayload = (): UpdateIntColorLexusInput => {
    return {
      id: this.id,
      revId: this.revId,
      ...this.getCreatePayload(),
    };
  };
}

export interface VDExteriorColorLexus {
  id: string;
  revId: string;
  name: string;
  code: string;
  hexCode: string;
  notes: string;
  isExtraCost: string;
  isInProgress: boolean;
  colorApplicability: {
    interiorColorId: string;
    groupId: string;
  }[];
  changes?: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
}

export interface VDExteriorColorLexusRequest extends VDExteriorColorLexus {}

export interface VDInteriorColorLexus {
  id: string;
  revId: string;
  materialId: string;
  material: string;
  interiorTypeId: string;
  interiorType: string;
  interiorType_subId?: string;
  ornamentType1Id: string;
  ornamentType1: string;
  ornamentType1_subId?: string;
  ornamentType2Id: string;
  ornamentType2: string;
  ornamentType2_subId?: string;
  seatAccent: string;
  armRest: string;
  headliner: string;
  ip: string;
  carpet: string;
  groupApplicability: LexusIntColorGroupModelApplicability; // string[];
  isDeleted?: boolean;
  rejectNotes?: string;
  changes?: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
  isExtraCost: string;
}

export interface VDInteriorCategoryRequest {
  id: string;
  revId: string;
  material: string;
  materialId: string;
  interiorTypeId: string;
  interiorType: string;
  interiorType_subId: string;
  ornamentType1Id: string;
  ornamentType1: string;
  ornamentType1_subId: string;
  ornamentType2Id: string;
  ornamentType2: string;
  ornamentType2_subId: string;
  seatAccent: string;
  armRest: string;
  headliner: string;
  ip: string;
  carpet: string;
  groupApplicability: LexusIntColorGroupModelApplicability; // string[];
}

export type HeightSyncItem = {
  rowHeight: number | undefined;
};

export class ColorLexusInteriorReviewItem {
  uid = uuidv4();
  id = '';
  revId = '';
  @observable material = '';
  @observable interiorType = '';
  @observable interiorType_subId = '';
  @observable ornamentType1 = '';
  @observable ornamentType1_subId = '';
  @observable ornamentType2Id = '';
  @observable ornamentType2 = '';
  @observable ornamentType2_subId = '';
  @observable seatAccent = '';
  @observable armRest = '';
  @observable headliner = '';
  @observable ip = '';
  @observable carpet = '';
  @observable changes: ReviewChangeBaseItem[] = [];
  @observable otherChanges: ReviewChangeBaseItem[] = [];
  @observable isExtraCost = '';

  constructor(response?: VDInteriorColorLexus) {
    if (response) {
      Object.assign(this, response);
    }
  }
}

export interface InteriorGroupModels {
  [modelId: string]: boolean;
}

export interface InteriorGroupRequest extends InteriorGroupResponseItem {}

export interface InteriorGroupResponseItem {
  id: string;
  revId: string;
  name: string;
  models: InteriorGroupModels;
  isDeleted?: boolean;
}

export type LexusIntColorGroupModelApplicability = {
  [group: string]: { [id: string]: boolean };
};

export interface LexusIntColorModelCodes {
  id: string;
  name: string;
  selected: boolean;
  uniqueKey: string;
}

export interface LexusIntColorGroupsList {
  id: string;
  name: string;
  selected: boolean;
  items: LexusIntColorModelCodes[];
}
