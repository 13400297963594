import cx from 'clsx';
import React from 'react';
import { MultiLineRTEditor, SingleLineRTEditor, SuggestionType } from 'vapi-ui-common';
import { TableCell } from '../../../../../components/Table';
import { TableColType } from '../../../../../components/Table/components/TableCell/tableTypes';
import styles from './richTextCell.module.scss';

interface RichTextCellProps {
  colType: TableColType;
  value: string;
  border?: boolean;
  cellStyles?: string;
  disabled?: boolean;
  placeholder?: string;
  singleLine?: boolean;
  suggestionTypes?: SuggestionType[];
  onBlur?(value: string): void;
  required?: boolean;
  children?: React.ReactNode;
  wrapContent?: boolean;
}

const RichTextCell = ({
  colType,
  border,
  required,
  cellStyles,
  singleLine,
  onBlur,
  children,
  wrapContent,
  ...editorProps
}: RichTextCellProps) => {
  const Component = singleLine ? SingleLineRTEditor : MultiLineRTEditor;

  const handleOnBlur = (value: string) => {
    onBlur && onBlur(value);
  };

  return (
    <TableCell className={cellStyles} colType={colType} border={border} noSpan>
      {wrapContent && (
        <div>
          <Component
            required={required}
            editorStyles={cx(styles.editor)}
            focusStyles={styles.border}
            emptyStyles={styles.border}
            onBlur={handleOnBlur}
            {...editorProps}
          />
          {children}
        </div>
      )}

      {!wrapContent && (
        <>
          <Component
            required={required}
            editorStyles={cx(styles.editor)}
            focusStyles={styles.border}
            emptyStyles={styles.border}
            onBlur={handleOnBlur}
            {...editorProps}
          />
          {children}
        </>
      )}
    </TableCell>
  );
};

export default RichTextCell;
