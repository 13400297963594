import { computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { ChangeLogBase, ChangeLogTypes } from './changeLog.model';

export class DisclaimersItem {
  uid = '';
  id = '';
  revId = '';
  @observable token = '';
  @observable description = '';
  @observable description_es = '';
  @observable notes = '';
  @observable lastPublishedVersion: number | undefined;
  @observable publishedDate = '';
  @observable lastPublishedDate = '';
  @observable vehicles: DisclaimersVehicle[] = [];

  constructor(disclaimer?: DisclaimersResponse) {
    this.uid = uuidv4();
    if (disclaimer) {
      Object.assign(this, disclaimer);
    }
  }

  @computed get isValid() {
    return this.description !== '' && this.token !== '';
  }

  getPayload(): DisclaimersRequest {
    const { id, revId, token, description, description_es, notes } = this; // vehicles
    return { id, revId, token, description, description_es, notes }; // vehicles
  }
}

export interface DisclaimersResponse {
  id: string;
  revId: string;
  token: string;
  description: string;
  description_es?: string;
  notes: string;
  lastPublishedVersion?: number;
  publishedDate?: string;
  lastPublishedDate?: string;
  // vehicles?: DisclaimersVehicle[];
}

export interface DisclaimersRequest {
  id: string;
  revId: string;
  token: string;
  description: string;
  description_es?: string;
  notes: string;
  // vehicles: DisclaimersVehicle[];
}

export interface DisclaimerIdRequest {
  id: string;
  revId: string;
}

export interface DisclaimerChangeLogRequest {
  id: string;
  revId: string;
  before: string;
  after: string;
  changeType: ChangeLogTypes;
}

export interface DisclaimersConfig {
  readOnly?: boolean;
  isArchived?: boolean;
}

export interface DisclaimersChangeLogResponse {
  currentChangeCount?: number;
  lastResetDate?: string;
  changes: DisclaimersChangeLogResponseItem[];
}

export interface DisclaimersChangeLogResponseItem {
  id: string;
  revId: string;
  description: string;
  description_es?: string;
  notes: string;
  changes: DisclaimersChangeLogResponseChange[];
  token: string;
}

export interface DisclaimersChangeLogResponseChange {
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
  changeType: string;
  modelsAdded?: string[];
  modelsRemoved?: string[];
  modifiedDate: string;
  modifiedBy: string;
}

export interface DisclaimerChangeLogResponse {
  changeType: ChangeLogTypes;
  before?: string;
  after?: string;
  modifiedBy: string;
  modifiedDate: string;
}

export interface DisclaimerVersionInfoResponse {
  version: number;
  publishedBy?: string;
  publishedDate?: string;
  changes?: DisclaimerChangeLogResponse[];
  lastResetDate: string | null;
  englishVersion?: number;
}

export interface DisclaimersChangeLogItem extends ChangeLogBase {
  uid: string;
  id: string;
  revId: string;
  description: string;
  description_es?: string;
  notes: string;
  changeType: ChangeLogTypes;
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
  changes: DisclaimersChangeLogChange[];
  modelsAdded?: string[];
  modelsRemoved?: string[];
  modifiedDate: string;
  modifiedBy: string;
  canRevert: boolean;
  token?: string;
}

export interface DisclaimersChangeLogChange {
  added?: boolean;
  removed?: boolean;
  value?: string;
}

export interface DisclaimersVehicle {
  modelYear: string;
  seriesId: string;
  seriesName: string;
  group?: string;
}

export interface DisclaimersVehicleChecklist {
  name: string;
  selected: boolean;
  modelYear: string;
  items: Array<{ id: string; name: string; selected: boolean; year: string }>;
}
