import { observer } from 'mobx-react-lite';
import React from 'react';
import { IDValueType, KeyValueType } from '../../../../../../../models/common.model';
import { RefItem } from '../../../../../../../models/refItem.model';
import { SeriesSettingsItem } from '../../../../../../../models/seriesSettings.model';
import { BRAND_LEXUS, BRAND_TDPR, BRAND_TOYOTA } from '../../../../../../../models/user.model';
import { SeriesCategories } from '../../../../../../../models/vehicleData.model';
import {
  UpdateModelStatusRequest,
  /* Brand, */ VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../models/vehicleModel.model';
import { FieldStatus } from '../../../../../../../stores/vehicleData/modelTabStore';
import ModelsEditPanelLexus from './ModelsEditPanelLexus';
import ModelsEditPanelToyota from './ModelsEditPanelToyota';

interface ModelsEditPanelProps {
  brand: string;
  seriesName: string;
  modelMap: KeyValueType<VehicleModelItem<VehicleModelToyota | VehicleModelLexus>>;
  grades: RefItem[];
  fuelTypes: IDValueType[];
  category: SeriesCategories;
  onClose: () => void;
  onSave?: (
    modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>
  ) => void;
  onAddGrade?: (grade: string) => void;
  onUpdateGrade?: (gradeId: string, gradeValue: string) => void;
  seriesSettings: SeriesSettingsItem[];
  rowClass?: string;
  readOnly?: boolean;
  compareModel: (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => void;
  fieldStatus?: FieldStatus;
  updateFieldStatus?: (payload: UpdateModelStatusRequest) => void;
}

const ModelsEditPanel = ({ brand, compareModel, modelMap, ...rest }: ModelsEditPanelProps) => {
  if (brand === BRAND_LEXUS) {
    return <ModelsEditPanelLexus {...rest} model={modelMap[Object.keys(modelMap)[0]]} />;
  }
  if (brand === BRAND_TOYOTA || brand === BRAND_TDPR) {
    return (
      <ModelsEditPanelToyota
        brand={brand}
        compareModel={compareModel}
        modelMap={modelMap}
        {...rest}
      />
    );
  }
  return null;
};

export default observer(ModelsEditPanel);
