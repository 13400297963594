import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ActionBar } from 'vapi-ui-common';
import Spinner from '../../../../components/Spinner';
import useStores from '../../../../hooks/useStores';
import { Brand } from '../../../../models/user.model';
import ActionBarFiltersSection from '../../../../routes/vehicleData/components/ActionBarFiltersSection';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { processVDVersion } from '../../../../utils/vehicleDataUtils';
import SeriesSettingsReviewTable from './components/SeriesSettingsReviewTable';

const SeriesSettingsReview = ({ seriesId, year, version = '' }: ProductDataControllerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    seriesSettingsReviewStore,
    teamStore,
    userStore: { brand },
  } = useStores();

  useEffect(() => {
    seriesSettingsReviewStore.reset();

    (async () => {
      try {
        await seriesSettingsReviewStore.fetchData(
          brand as Brand,
          teamStore.team.param,
          seriesId,
          year,
          processVDVersion(version),
          true
        );
      } catch (err) {
        console.log(err);
        toast.error('Error loading Features review data');
      }
      setIsLoaded(true);
    })();
  }, [seriesSettingsReviewStore, brand, seriesId, teamStore, version, year]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly
          searchText={seriesSettingsReviewStore.searchText}
          onSearchTextChange={text => (seriesSettingsReviewStore.searchText = text)}
          buttons={[]}
          hideFilter
          renderFilter={onClose => <></>}
        />
      </ActionBar>
      <SeriesSettingsReviewTable />
    </>
  );
};

export default observer(SeriesSettingsReview);
