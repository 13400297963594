import { action, computed, observable } from 'mobx';
import { convertToRichTextObject } from 'vapi-ui-common';
import { ExteriorColorsReviewMap } from '../../../models/colors.model';
import { sortBy, toLowerCase } from '../../../utils';
import BaseColorsReviewStore from './baseColorsReviewStore';
import { ReviewChangeTypeMap } from '../../../models/review.model';
import { VehicleTeam } from '../../../models/vehicleData.model';
import { UpdateExtColorReviewInput } from '../../../gql/generated';

class ColorsExteriorReviewStore extends BaseColorsReviewStore {
  @observable map: ExteriorColorsReviewMap = {};

  @computed get filteredReviewItems() {
    const items = Object.values(this.map)
      .slice()
      .sort(sortBy(this.sortField, this.reverse, this.sortField === 'modifiedDate'));
    const lowerSearchText = toLowerCase(this.searchText);
    return items.filter(color => {
      if (this.isInProgressFilter && !color.isInProgress) {
        return false;
      }
      if (this.isExtraCostColorFilter && !color.isExtraCost.before && !color.isExtraCost.after) {
        return false;
      }
      if (lowerSearchText) {
        const valuesToCheck: string[] = [
          convertToRichTextObject(color.hexCode).text,
          convertToRichTextObject(color.name.before).text,
          convertToRichTextObject(color.name.after).text,
          color.rejectNotes,
        ];
        for (let val of valuesToCheck) {
          if (toLowerCase(val).includes(lowerSearchText)) {
            return true;
          }
        }
      }

      return !lowerSearchText;
    });
  }

  @action reset() {
    super.reset();
    this.map = {};
  }

  getReviewInputPayload = (
    map: ReviewChangeTypeMap,
    team: VehicleTeam
  ): UpdateExtColorReviewInput => {
    return {
      id: map.id,
      revId: map.revId,
      changeTypeId: '',
      isAccepted: map.isApplied || team === VehicleTeam.AGENCY_TEAM,
      isApplied: map.isApplied,
      rejectNotes: map.rejectNotes,
    };
  };
}

export default ColorsExteriorReviewStore;
