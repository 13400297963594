import cx from 'clsx';
import * as differ from 'diff';
import { diff as jsonDiff } from 'json-diff';
import React from 'react';
import { FlexContainer, MultiLineRTEditor, processRteForChangeLog } from 'vapi-ui-common';
import Checkbox from '../../../components/Checkbox';
import useStores from '../../../hooks/useStores';
import { ChangeLogBase, ChangeLogTypes } from '../../../models/changeLog.model';
import { KeyValueType } from '../../../models/common.model';
import { CompareGradeApplicabilityMap, CompareType } from '../../../models/compareFeatures.model';
import { GradeApplicability } from '../../../models/features.model';
import { convertOptionsPropToText } from '../../../utils/optionsLexusUtils';
import xformBoolean from '../../../utils/xformBoolean';
import styles from './changeLogChanges.module.scss';

interface ChangeLogChangesProps {
  changeItem: ChangeLogBase;
  inline?: boolean;
  long?: boolean;
  field?: string;
}

const ChangeLogChanges = ({ changeItem, inline, long, field }: ChangeLogChangesProps) => {
  switch (changeItem.changeType) {
    case ChangeLogTypes.MODEL_ADDED:
    case ChangeLogTypes.FEATURE_ADDED:
    case ChangeLogTypes.SPEC_ADDED:
    case ChangeLogTypes.OPTION_ADDED:
    case ChangeLogTypes.PACKAGE_ADDED:
    case ChangeLogTypes.PACKAGE_SPEC_ADDED:
    case ChangeLogTypes.PACKAGE_VARIETY_ADDED:
    case ChangeLogTypes.OPTION_SPEC_ADDED:
    case ChangeLogTypes.INT_COLOR_ADDED:
    case ChangeLogTypes.EXT_COLOR_ADDED:
    case ChangeLogTypes.DISC_ADDED: {
      return <ConditionalFC long={long} truthyValue={'Added'} />;
    }

    case ChangeLogTypes.SPLIT_ADDED: {
      return (
        <ConditionalFC
          long={long}
          truthyValue={
            <span>
              Split added
              <ChangeLogBeforeAfter
                before={changeItem.before !== undefined ? String(changeItem.before) : ''}
                after={changeItem.after !== undefined ? String(changeItem.after) : ''}
              />
            </span>
          }
          falsyValue={<span>- -</span>}
        />
      );
    }

    case ChangeLogTypes.MODEL_DELETED:
    case ChangeLogTypes.FEATURE_DELETED:
    case ChangeLogTypes.SPEC_DELETED:
    case ChangeLogTypes.OPTION_DELETED:
    case ChangeLogTypes.PACKAGE_DELETED:
    case ChangeLogTypes.PACKAGE_SPEC_DELETED:
    case ChangeLogTypes.PACKAGE_VARIETY_DELETED:
    case ChangeLogTypes.OPTION_SPEC_DELETED:
    case ChangeLogTypes.INT_COLOR_DELETED:
    case ChangeLogTypes.EXT_COLOR_DELETED: {
      return <ConditionalFC long={long} truthyValue={'Deleted'} />;
    }

    case ChangeLogTypes.SPEC_ARCHIVED:
    case ChangeLogTypes.DISC_ARCHIVED: {
      return <ConditionalFC long={long} truthyValue={'Archived'} />;
    }

    case ChangeLogTypes.SPEC_UNARCHIVED:
    case ChangeLogTypes.DISC_UNARCHIVED: {
      return <ConditionalFC long={long} truthyValue={'Unarchived'} />;
    }

    case ChangeLogTypes.DRAFT_RESET: {
      return <ConditionalFC long={long} truthyValue={'Draft Reset'} />;
    }

    case ChangeLogTypes.CATEGORY:
    case ChangeLogTypes.CATEGORY_TYPE:
    case ChangeLogTypes.SPEC_TYPE:
    case ChangeLogTypes.SUB_CATEGORY:
    case ChangeLogTypes.SUB_CATEGORY_CHANGED:
    case ChangeLogTypes.CATEGORY_CHANGED:
    case ChangeLogTypes.SPEC_TYPE_CHANGED:
    case ChangeLogTypes.GROUP_CHANGED:
    case ChangeLogTypes.GROUP_ADDED:
    case ChangeLogTypes.GROUP_DELETED:
    case ChangeLogTypes.MATERIALS:
    case ChangeLogTypes.MATERIAL_CHANGED:
    case ChangeLogTypes.ORNAMENT_1:
    case ChangeLogTypes.ORNAMENT_2:
    case ChangeLogTypes.ORNAMENT_CHANGED:
    case ChangeLogTypes.INTERIOR:
    case ChangeLogTypes.INTERIOR_TYPE_CHANGED:
    case ChangeLogTypes.FUEL_TYPE:
    case ChangeLogTypes.GRADE:
    case ChangeLogTypes.GRADE_CHANGED: {
      return (
        <ConditionalFC
          long={long}
          truthyValue={
            <span>
              {changeItem.changeType} changed from
              <span className={styles.changeFrom}> {changeItem.beforeValue} </span>
              to
              <span className={styles.changeTo}> {changeItem.afterValue}</span>
            </span>
          }
          falsyValue={
            <ChangeLogBeforeAfter before={changeItem.beforeValue} after={changeItem.afterValue} />
          }
        />
      );
    }

    case ChangeLogTypes.ESTIMATED_MILEAGE:
    case ChangeLogTypes.MPGE:
    case ChangeLogTypes.RANGE:
    case ChangeLogTypes.NOTES:
    case ChangeLogTypes.LINK:
    case ChangeLogTypes.DRIVE:
    case ChangeLogTypes.ENGINE:
    case ChangeLogTypes.TRANSMISSION:
    case ChangeLogTypes.BED:
    case ChangeLogTypes.CAB:
    case ChangeLogTypes.SEATING:
    case ChangeLogTypes.KATASHIKI:
    case ChangeLogTypes.MSRP:
    case ChangeLogTypes.CBU_NAP:
    case ChangeLogTypes.INT_COLOR_CODE:
    case ChangeLogTypes.TDPR_CODE:
    case ChangeLogTypes.INT_COLOR_EXTRA_COST:
    case ChangeLogTypes.EXT_COLOR_CODE:
    case ChangeLogTypes.EXT_COLOR_HEX_CODE:
    case ChangeLogTypes.EXT_COLOR_EXTRA_COST:
    case ChangeLogTypes.EXT_COLOR_NOTES:
    case ChangeLogTypes.GROUP_NAME_CHANGED:
    case ChangeLogTypes.INTERIOR_ID:
    case ChangeLogTypes.ORNAMENT_1_ID:
    case ChangeLogTypes.ORNAMENT_2_ID:
    case ChangeLogTypes.SEAT_ACCENT:
    case ChangeLogTypes.CC_ARMREST:
    case ChangeLogTypes.IP:
    case ChangeLogTypes.CARPET:
    case ChangeLogTypes.PACKAGE_CONFLICTS_CHANGED:
    case ChangeLogTypes.PACKAGE_REQUIRED_CHANGED:
    case ChangeLogTypes.PACKAGE_SPEC_SMS_CHANGED:
    case ChangeLogTypes.PACKAGE_SPEC_TOMS_CHANGED:
    case ChangeLogTypes.OPTION_SPEC_SMS_CHANGED:
    case ChangeLogTypes.OPTION_SPEC_TOMS_CHANGED:
    case ChangeLogTypes.HORSEPOWER:
    case ChangeLogTypes.ALTERNATIVE_OFFERS_DESCRIPTION:
    case ChangeLogTypes.PACKAGE_TRIM:
    case ChangeLogTypes.REQUIRED_PACKAGE_CODE:
    case ChangeLogTypes.OPTION_EXTRA_COST:
    case ChangeLogTypes.VDSTATUS:
    case ChangeLogTypes.VEHICLES: {
      return <ChangeLogBeforeAfter before={changeItem.before} after={changeItem.after} />;
    }

    case ChangeLogTypes.SPECIAL_EDITION:
      return (
        <ChangeLogBooleans
          before={changeItem.before != null ? changeItem.before.toString() : ''}
          after={changeItem.after != null ? changeItem.after.toString() : ''}
          changeType="Special Edition"
        />
      );

    case ChangeLogTypes.OPTION_SPEC_SPECS_CHANGED: {
      return (
        <ChangeLogBeforeAfter
          before={convertOptionsPropToText(changeItem.before)}
          after={convertOptionsPropToText(changeItem.after)}
        />
      );
    }

    case ChangeLogTypes.PACKAGE_VARIETY_NAME_CHANGED:
    case ChangeLogTypes.PACKAGE_VARIETY_CODE_CHANGED:
    case ChangeLogTypes.PACKAGE_VARIETY_REQUIRED_CHANGED:
    case ChangeLogTypes.PACKAGE_VARIETY_CONFLICTS_CHANGED: {
      return (
        <ChangeLogBeforeAfter inline={inline} before={changeItem.before} after={changeItem.after} />
      );
    }

    case ChangeLogTypes.HYBRID: {
      return (
        <ChangeLogBeforeAfter
          before={changeItem.before !== undefined ? String(changeItem.before) : ''}
          after={changeItem.after !== undefined ? String(changeItem.after) : ''}
        />
      );
    }

    case ChangeLogTypes.TOOLTIP:
    case ChangeLogTypes.PACKAGE_SPEC_EXCLUSIVE_CHANGED:
    case ChangeLogTypes.OPTION_SPEC_EXCLUSIVE_CHANGED: {
      return (
        <ChangeLogBeforeAfter before={String(changeItem.before)} after={String(changeItem.after)} />
      );
    }

    case ChangeLogTypes.CODE:
    case ChangeLogTypes.DESCRIPTION_ES:
    case ChangeLogTypes.NAME:
    case ChangeLogTypes.MODEL_NAME:
    case ChangeLogTypes.PACKAGE_SPEC_SPECS_CHANGED:
    case ChangeLogTypes.DESCRIPTION:
    case ChangeLogTypes.TRIM_TITLE:
    case ChangeLogTypes.LONG_DESCRIPTION:
    case ChangeLogTypes.SHORT_DESCRIPTION:
    case ChangeLogTypes.SPLIT_DESCRIPTION:
    case ChangeLogTypes.SPLIT_SHORT_DESCRIPTION:
    case ChangeLogTypes.SPLIT_LONG_DESCRIPTION:
    case ChangeLogTypes.TITLE:
    case ChangeLogTypes.COPY:
    case ChangeLogTypes.BNP_SPLIT_DESCRIPTION: {
      return <ChangeLogRichTextDiff before={changeItem.before} after={changeItem.after} />;
    }

    case ChangeLogTypes.EXT_COLOR_NAME:
    case ChangeLogTypes.INT_COLOR_NAME:
    case ChangeLogTypes.HEADLINER:
    case ChangeLogTypes.TOKEN: {
      return <ChangeLogDiff before={changeItem.before} after={changeItem.after} />;
    }

    case ChangeLogTypes.MODEL_APPLICABILITY:
    case ChangeLogTypes.PACKAGE_TOTAL_MODEL_APPLICABILITY:
    case ChangeLogTypes.PACKAGE_TOTAL_REQ_MODEL_APPLICABILITY:
    case ChangeLogTypes.PACKAGE_VARIETY_MODEL_APPLICABILITY:
    case ChangeLogTypes.PACKAGE_SPEC_MODEL_APPLICABILITY:
    case ChangeLogTypes.OPTION_TOTAL_MODEL_APPLICABILITY:
    case ChangeLogTypes.OPTION_SPEC_MODEL_APPLICABILITY:
    case ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY:
    case ChangeLogTypes.GROUP_MODELS_CHANGED:
    case ChangeLogTypes.INT_COLOR_GROUP_APPLICABILITY: {
      return (
        <>
          {changeItem.applicabilityChanges &&
            changeItem.applicabilityChanges.map((modelChange, index) => (
              <FlexContainer key={index}>
                <span className={styles.changeModelTitle}>{modelChange.title}: </span>
                <div className={styles.applicabilityChanges}>
                  {modelChange.before && (
                    <MultiLineRTEditor
                      editorStyles={styles.changeRemoved}
                      disabled
                      value={modelChange.before}
                      fullHeight
                    />
                  )}
                  <MultiLineRTEditor disabled value={modelChange.after} fullHeight />
                </div>
              </FlexContainer>
            ))}
        </>
      );
    }
    case ChangeLogTypes.GRADE_APPLICABILITY: {
      const before: any = changeItem.before;
      const after: any = changeItem.after;
      let before2 = before;
      if (typeof before !== 'object') {
        before2 = {};
      }

      return (
        <>
          {changeItem?.compareType === CompareType.Feature ||
          changeItem?.compareType === CompareType.Spec ? (
            <ChangeLogCompareFeatureGradeApplicabilityDiff before={before2} after={after} />
          ) : (
            <ChangeLogGradeApplicabilityDiff before={before2} after={after} />
          )}
        </>
      );
    }
    case ChangeLogTypes.INT_COLOR_GRADE_APPLICABILITY: {
      return (
        <ChangeLogBeforeAfter
          before={changeItem.before ? JSON.stringify(changeItem.before).replace(/[{}"]/g, '') : ''}
          after={changeItem.after ? JSON.stringify(changeItem.after).replace(/[{}"]/g, '') : ''}
        />
      );
    }

    case ChangeLogTypes.MODELS_ADDED: {
      return (
        <>
          {changeItem.modelsAdded &&
            changeItem.modelsAdded.map((item, key) => <p key={key}>{item}</p>)}
        </>
      );
    }

    case ChangeLogTypes.MODELS_REMOVED: {
      return (
        <>
          {changeItem.modelsRemoved &&
            changeItem.modelsRemoved.map((item, key) => (
              <p key={key} className={styles.changeRemoved}>
                {item}
              </p>
            ))}
        </>
      );
    }

    case ChangeLogTypes.FUEL_TYPES:
    case ChangeLogTypes.EXT_COLOR_APPLICABILITY: {
      return <ExteriorColorApplicability changeItem={changeItem} />;
    }

    case ChangeLogTypes.IS_NOT_PUBLISHABLE: {
      return (
        <ChangeLogBooleans
          changeType={'Code Red'}
          before={String(changeItem.before)}
          after={String(changeItem.after)}
        />
      );
    }
    case ChangeLogTypes.HIGHLIGHTED:
    case ChangeLogTypes.SPLIT_HIGHLIGHTED:
    case ChangeLogTypes.IN_PROGRESS:
    case ChangeLogTypes.IS_USVI:
    case ChangeLogTypes.IS_TDPR:
    case ChangeLogTypes.EXT_COLOR_IN_PROGRESS: {
      return (
        <ChangeLogBooleans
          changeType={changeItem.changeType}
          before={String(changeItem.before)}
          after={String(changeItem.after)}
        />
      );
    }
    case ChangeLogTypes.SERIES_SETTING: {
      return (
        <ChangeLogBeforeAfter
          before={changeItem.before !== undefined ? String(changeItem.before) : ''}
          after={changeItem.after !== undefined ? String(changeItem.after) : ''}
        />
      );
    }
    case ChangeLogTypes.GO_LIVE_DATE: {
      return (
        <ChangeLogBeforeAfter
          before={changeItem.before ? new Date(changeItem.before).toLocaleDateString() : ''}
          after={changeItem.after ? new Date(changeItem.after).toLocaleDateString() : ''}
        />
      );
    }
    case ChangeLogTypes.PARENT_ID: {
      const type = changeItem?.compareType === CompareType.Feature ? 'Feature ' : 'Spec';
      return (
        <ChangeLogBeforeAfter before="" after={!changeItem.after ? ` Unlinked from ${type}` : ''} />
      );
    }
    case ChangeLogTypes.BNP_SPLIT_ADDED:
    case ChangeLogTypes.BNP_SPLIT_DELETED:
      return (
        <MultiLineRTEditor
          editorStyles={styles.defaultFontSize}
          disabled
          value={changeItem.after}
          fullHeight
        />
      );
    case ChangeLogTypes.CATEGORY_APPLICABILITY:
      const descriptionToModelCodesMap = (changeItem.after as any) as {
        [description: string]: string[];
      };
      return (
        <>
          {Object.entries(descriptionToModelCodesMap).map(([description, modelCodes]) => {
            return <div>{`${description}: ${modelCodes.join(', ')}`}</div>;
          })}
        </>
      );
    default:
      return null;
  }
};

export const ChangeLogBeforeAfter = ({
  before,
  after,
  inline,
  styleAfter = false,
}: {
  before: string | boolean | (() => JSX.Element);
  after: string | boolean | (() => JSX.Element);
  inline?: boolean;
  styleAfter?: boolean;
}) => {
  const beforeValue = () => {
    if (typeof before !== 'function') {
      const value = xformBoolean(before);
      return value && <p className={styles.changeRemoved}>{value}</p>;
    }

    const value = before();
    return value && <div className={styles.changeRemoved}>{value}</div>;
  };

  const afterValue = () => {
    if (typeof after !== 'function') {
      const value = xformBoolean(after);
      return value && <p className={styleAfter ? styles.changeAdded : ''}>{value}</p>;
    }

    const value = after();
    return value && <div className={styleAfter ? styles.changeAdded : ''}>{value}</div>;
  };

  return inline ? (
    <div className={styles.inlineChanges}>
      {before && <div className={styles.changeRemoved}>{before}</div>}
      <div className={cx({ [styles.changeAdded]: styleAfter })}>{after}</div>
    </div>
  ) : (
    <>
      {beforeValue()}
      {afterValue()}
    </>
  );
};

export const ChangeLogBeforeAfterRichText = ({
  before,
  after,
}: {
  before: string;
  after: string;
}) => {
  return (
    <>
      <ChangeLogDiff before={before} after={after} />;
    </>
  );
};

export const ChangeLogRichTextDiff = ({ before, after }: { before: string; after: string }) => {
  var payload = { after, before };

  payload.after = processRteForChangeLog(after);
  payload.before = processRteForChangeLog(before);

  if (!payload.before || (!payload.after && payload.before)) {
    return ChangeLogDiff(payload);
  }

  if (payload.before.replaceAll('\n', '') === payload.after.replaceAll('\n', '')) {
    return (
      <>
        <p>Formatting added:</p>
        <MultiLineRTEditor disabled value={after} fullHeight />
      </>
    );
  }
  return ChangeLogDiff(payload);
};

export const ChangeLogDiff = ({ before, after }: { before: string; after: string }) => {
  const beforeString = before != null ? before.toString() : '';
  const afterString = after != null ? after.toString() : '';
  const changes = differ.diffWordsWithSpace(beforeString, afterString);
  return (
    <>
      {changes.map((change, index) => {
        const key = `key${index}`;
        // process line breaks for non-removed items and after values
        const vals = change.removed ? [change.value] : change.value.split(/(?=[\n])|(?<=[\n])/g);

        return (
          <span
            className={cx({
              [styles.changeRemoved]: change.removed,
              [styles.changeAdded]: change.added,
            })}
            key={key}
          >
            {' '}
            {vals.map((val, valIndex) => {
              const valKey = valIndex;
              return <React.Fragment key={valKey}>{val === '\n' ? <br /> : val}</React.Fragment>;
            })}
            <> </>
          </span>
        );
      })}
    </>
  );
};

const checkboxClass = (
  changes: any,
  type: string,
  key: string,
  key2: string,
  removedClass: string,
  addedClass: string
): string => {
  return (
    (typeof changes[key]?.applicabilityText?.[key2]?.availability === 'object' &&
      //find out if it is an old or new change
      (changes[key]?.applicabilityText?.[key2]?.availability?.__old === type
        ? removedClass
        : addedClass)) ||
    ''
  );
};

export const ChangeLogCompareFeatureGradeApplicabilityDiff = ({
  before,
  after,
}: {
  before: CompareGradeApplicabilityMap;
  after: CompareGradeApplicabilityMap;
}) => {
  const { vehicleModelsStore } = useStores();
  const changes = jsonDiff(before, after);

  return (
    <>
      {changes && (
        <div className={styles.gradeApplicability}>
          <table>
            {changes && (
              <tbody>
                <tr className={styles.headerRow}>
                  <td className={styles.column25}>Grade</td>
                  <td className={styles.column25}>Highlighted</td>
                  <td className={cx(styles.column25, styles.textCenter)}>Text</td>
                  <td className={styles.column25}>Applicability</td>
                </tr>
              </tbody>
            )}
            <tbody>
              {changes &&
                Object.keys(changes).map((key: string) => {
                  const parsedKey: string = key.split('__')[0];
                  return (
                    <tr key={key}>
                      <td className={styles.texttop}>
                        {vehicleModelsStore.getGradeById(parsedKey)}
                      </td>
                      <td className={styles.texttop}>
                        {changes[key].highlighted && (
                          <>
                            <div className={styles.changeRemoved}>
                              {before[parsedKey]?.highlighted.toString()}
                            </div>
                            <div className={styles.changeAdded}>
                              {after[parsedKey]?.highlighted.toString()}
                            </div>
                          </>
                        )}
                      </td>
                      <td className={styles.texttop}>
                        {changes[key].text && (
                          <>
                            <div className={cx(styles.changeRemoved, styles.textCenter)}>
                              {before[parsedKey]?.text}
                            </div>
                            <div className={cx(styles.changeAdded, styles.textCenter)}>
                              {after[parsedKey]?.text}
                            </div>
                          </>
                        )}
                      </td>
                      <td className={styles.texttop}>
                        {changes[key].applicability && (
                          <>
                            <div className={cx(styles.changeRemoved, styles.textCenter)}>
                              {before[parsedKey]?.applicability}
                            </div>
                            <div className={cx(styles.changeAdded, styles.textCenter)}>
                              {after[parsedKey]?.applicability}
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export const ChangeLogGradeApplicabilityDiff = ({
  before,
  after,
}: {
  before: GradeApplicability;
  after: GradeApplicability;
}) => {
  const { vehicleModelsStore } = useStores();
  const changes = jsonDiff(before, after);

  return (
    <>
      {changes && (
        <div className={styles.gradeApplicability}>
          <table>
            {changes && (
              <tbody>
                <tr className={styles.headerRow}>
                  <td className={styles.column25}>Grade</td>
                  <td className={cx(styles.column25, styles.textCenter)}>Highlighted</td>
                  <td className={styles.column50}>
                    <table className={styles.full}>
                      <thead>
                        <tr>
                          <td className={styles.applicabilityText}>Applicability Text</td>
                          <td className={cx(styles.textCenter, styles.sheader)}>S</td>
                          <td className={styles.textCenter}>A</td>
                        </tr>
                      </thead>
                    </table>
                  </td>
                </tr>
              </tbody>
            )}

            {changes &&
              Object.keys(changes).map((key: string) => {
                const parsedKey: string = key.split('__')[0];
                return (
                  <tbody>
                    <tr>
                      <td
                        className={cx(
                          styles.texttop,
                          !!changes[`${parsedKey}__added`] && styles.changeAdded,
                          !!changes[`${parsedKey}__deleted`] && styles.changeRemoved
                        )}
                      >
                        {vehicleModelsStore.getGradeById(parsedKey)}
                      </td>
                      <td className={cx(styles.texttop, styles.textCenter)}>
                        {after?.[parsedKey] && (
                          <Checkbox
                            checked={after?.[parsedKey]?.highlighted}
                            className={
                              typeof changes[key]?.highlighted == 'object' ? styles.changeAdded : ''
                            }
                            readOnly
                          ></Checkbox>
                        )}
                      </td>
                      <td>
                        <table>
                          <tbody>
                            {after?.[parsedKey]?.applicabilityText &&
                              Object.keys(after?.[parsedKey].applicabilityText).map(key2 => {
                                return (
                                  <tr className={styles.headerRow} key={key2}>
                                    <td className={styles.applicabilityText}>
                                      {typeof changes[key]?.applicabilityText?.[key2]?.text ==
                                        'object' && (
                                        <div
                                          className={cx(
                                            typeof changes[key]?.applicabilityText[key2]?.text ==
                                              'object'
                                              ? styles.changeRemoved
                                              : '',
                                            styles.applicabilityText
                                          )}
                                        >
                                          {before[parsedKey]?.applicabilityText[key2]?.text}
                                        </div>
                                      )}

                                      <div
                                        className={cx(
                                          typeof changes[key]?.applicabilityText?.[key2]?.text ==
                                            'object' ||
                                            !!changes[key]?.applicabilityText?.[`${key2}__added`]
                                            ? styles.changeAdded
                                            : '',
                                          styles.applicabilityText
                                        )}
                                      >
                                        {after?.[parsedKey]?.applicabilityText[key2].text}
                                      </div>
                                    </td>

                                    <td>
                                      <Checkbox
                                        checked={after?.[parsedKey]?.applicabilityText[
                                          key2
                                        ].availability?.includes('S')}
                                        className={checkboxClass(
                                          changes,
                                          'S',
                                          key,
                                          key2,
                                          styles.changeRemoved,
                                          styles.changeAdded
                                        )}
                                        readOnly
                                      ></Checkbox>
                                    </td>
                                    <td>
                                      <Checkbox
                                        checked={after?.[parsedKey]?.applicabilityText[
                                          key2
                                        ].availability?.includes('A')}
                                        className={checkboxClass(
                                          changes,
                                          'A',
                                          key,
                                          key2,
                                          styles.changeRemoved,
                                          styles.changeAdded
                                        )}
                                        readOnly
                                      ></Checkbox>
                                    </td>
                                  </tr>
                                );
                              })}
                            {changes &&
                              changes[key] &&
                              changes[key].applicabilityText &&
                              Object.keys(changes[key].applicabilityText).map(key2 => (
                                <>
                                  {key2.includes('__deleted') && (
                                    <>
                                      <tr className={styles.headerRow} key={key2}>
                                        <td className={styles.applicabilityText}>
                                          <div
                                            className={cx(
                                              styles.changeRemoved,
                                              styles.applicabilityText
                                            )}
                                          >
                                            {changes[key].applicabilityText[key2].text}
                                          </div>
                                        </td>
                                        <td>
                                          <Checkbox className={cx(styles.changeRemoved)}></Checkbox>
                                        </td>
                                        <td>
                                          <Checkbox className={cx(styles.changeRemoved)}></Checkbox>
                                        </td>

                                        <td></td>
                                      </tr>
                                    </>
                                  )}
                                </>
                              ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
      )}
    </>
  );
};

const ExteriorColorApplicability = ({ changeItem }: { changeItem: ChangeLogBase }) => {
  return (
    <>
      {changeItem.extColorAppBefore &&
        changeItem.extColorAppBefore.length > 0 &&
        changeItem.extColorAppBefore.map((change, key) => (
          <p key={key} className={styles.changeRemoved}>
            {change}
          </p>
        ))}
      {changeItem.extColorAppAfter &&
        changeItem.extColorAppAfter.length > 0 &&
        changeItem.extColorAppAfter.map((change, key) => <p key={key}>{change}</p>)}
    </>
  );
};

const ConditionalFC = ({
  long,
  truthyValue = '',
  falsyValue = '- -',
}: {
  long: boolean | undefined;
  truthyValue: JSX.Element | string;
  falsyValue?: JSX.Element | string;
}) => {
  if (long) {
    return typeof truthyValue === 'string' ? <span>{truthyValue}</span> : truthyValue;
  }
  return typeof falsyValue === 'string' ? <span>{falsyValue}</span> : falsyValue;
};

export const ChangeLogBooleans = ({
  before,
  after,
  changeType,
}: {
  before: string;
  after: string;
  changeType?: string;
}) => {
  const capitalize = (str: string) =>
    str.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

  return (
    <FlexContainer>
      <span>{changeType}:&nbsp;</span>
      <div>
        {before && (
          <MultiLineRTEditor
            editorStyles={styles.changeRemoved}
            disabled
            value={capitalize(before)}
            fullHeight
          />
        )}
        <MultiLineRTEditor
          editorStyles={styles.changeAdded}
          disabled
          value={capitalize(after)}
          fullHeight
        />
      </div>
    </FlexContainer>
  );
};

export const ChangeLogArray = ({
  before,
  after,
  hasChanged,
}: {
  before: KeyValueType<boolean>;
  after: KeyValueType<boolean>;
  hasChanged: boolean;
}) => {
  let curString = '';
  let addString = '';
  let delString = '';
  if (!hasChanged) {
    Object.keys(after).forEach(modelCode => {
      if (curString.length) {
        curString += ',';
      }
      curString += `${modelCode}`;
    });
    return <p key={'current'}>{curString}</p>;
  }
  Object.keys(after).forEach(modelCode => {
    if (before[modelCode]) {
      if (curString.length) {
        curString += ',';
      }
      curString += `${modelCode}`;
    } else {
      if (addString.length) {
        addString += ',';
      }
      addString += `${modelCode}`;
    }
  });
  Object.keys(before).forEach(modelCode => {
    if (!after[modelCode]) {
      if (delString.length) {
        delString += ',';
      }
      delString += `${modelCode}`;
    }
  });

  return (
    <>
      {curString.length ? <p key={'current'}>{curString}</p> : <></>}
      {delString.length ? (
        <p key={'deleted'} className={styles.changeRemoved}>
          {delString}
        </p>
      ) : (
        <></>
      )}
      {addString.length ? (
        <p key={'added'} className={styles.changeAdded}>
          {addString}
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChangeLogChanges;
