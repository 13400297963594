import { computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { cleanUpRte } from 'vapi-ui-common';
import { LIMITED_DATA_STATUS } from '../constants/vehicleData/VDConstants';
import { removeNulls } from '../utils';
import { ChangeLogBase } from './changeLog.model';
import { IDValueType, KeyValueType } from './common.model';
import {
  ReviewChangeBaseItem,
  ReviewChangeMap,
  ReviewChangeRequest,
  ReviewChangeResponse,
  ReviewChangeTypeMap,
} from './review.model';
import { ModelFieldStatus } from './vehicleModel.model';
import { CreateVehicleOptionInput, UpdateVehicleOptionInput } from '../gql/generated';

export type OptionModel = {
  id: string;
  setting: OptionSettings | null;
};

export enum OptionSettings {
  STANDARD = 'S',
  OPTIONAL = 'O',
  PACKAGE = 'P',
  UNDEFINED = '',
}

export class OptionItem {
  uid = '';
  id = '';
  revId: string | undefined;
  @observable notes = '';
  @observable modelsMap: KeyValueTypeOptionModel = {};
  @observable category = {} as IDValueType;
  @observable link = '';
  @observable isInProgress = false;
  @observable description = '';
  @observable code = '';
  @observable name = '';
  @observable rejectNotes = '';
  @observable splits = [];
  @observable isExtraCost = '';
  @observable sortOrder: string | number = 0;
  @observable changedAttributes: string[] = [];
  @observable changedModelIds: string[] = [];
  @observable fieldStatus: OptionFieldStatusRequest = {
    id: '',
    status: LIMITED_DATA_STATUS.READY_TO_PUBLISH,
    modelApplicability: {},
  };
  @observable fromTMNA: boolean = false;

  constructor(option?: OptionResponse) {
    this.uid = uuidv4();
    if (option) {
      Object.assign(this, removeNulls(option));
      this.changedAttributes.forEach(attr => {
        if (attr.includes('modelApplicability#')) {
          const splt = attr.split('#');
          if (splt.length > 1) {
            this.changedModelIds.push(splt[1]);
          }
        }
      });
    }
  }

  @computed get splitCount() {
    return this.splits.length;
  }

  getCreatePayload = (): CreateVehicleOptionInput => {
    const modelApplicability = {} as OptionModelApplicability;
    Object.values(this.modelsMap).forEach(mdl => {
      if (!mdl.setting) {
        modelApplicability[mdl.id] = null;
      } else {
        modelApplicability[mdl.id] = mdl.setting;
      }
    });

    return {
      id: this.id,
      categoryId: this.category.id,
      name: cleanUpRte(this.name),
      code: this.code,
      description: cleanUpRte(this.description),
      link: this.link,
      notes: this.notes,
      isExtraCost: this.isExtraCost,
      isInProgress: this.isInProgress,
      modelApplicability,
    };
  };

  getUpdatePayload = (): UpdateVehicleOptionInput => {
    return {
      ...this.getCreatePayload(),
      id: this.id,
      revId: this.revId!,
    };
  };

  isValidTdPR = () => {
    return !!(this.category.id && this.name && this.description);
  };

  isValid = () => {
    return !!(this.category.id && this.code && this.name);
  };
}

export type KeyValueTypeOptionModel = KeyValueType<OptionModel>;

export type OptionFieldStatusRequest = ModelFieldStatus;

export interface OptionResponse {
  id: string;
  revId: string;
  categoryId: string;
  name: string;
  code: string;
  description: string;
  notes: string | undefined;
  link: string | undefined;
  isInProgress: boolean;
  modelApplicability?: OptionModelApplicability;
  isExtraCost: string;
  changedAttributes?: string[];
  fromTMNA?: boolean;
  fieldStatus: ModelFieldStatus;
}

type OptionModelApplicability = {
  [modelCode: string]: OptionSettings | null;
};

export type OptionRequest = Omit<OptionResponse, 'fieldStatus'>;

/**
 * Options Review Item
 */
export class OptionsReviewItem {
  @observable uid = uuidv4();
  @observable id = '';
  @observable revId = '';
  @observable category = {} as IDValueType;
  @observable description = '';
  @observable changes = {} as ChangeLogBase;
  @observable otherChanges: ReviewChangeBaseItem[] = [];
  @observable changeTypeId = '';
  @observable code = '';
  @observable isAccepted = true;
  @observable isApplied = true;
  @observable isNewChange = false;
  @observable rejectNotes = '';
  @observable models: OptionModel[] = [];
  @observable name = '';
  @observable notes = '';
  @observable isInProgress = false;
  @observable isHighlighted = false;
  @observable rowHeight = undefined as number | undefined;
  @observable isExtraCost = '';

  constructor(option?: OptionsReviewResponse, change?: ReviewChangeResponse) {
    this.uid = uuidv4();
    if (option) {
      const { changes, ...rest } = option;
      Object.assign(this, removeNulls(rest));
    }

    if (change) {
      const { after, before, changeTypeId, changeType, ...rest } = change;
      Object.assign(this, removeNulls(rest));
      Object.assign(this.changes, removeNulls({ after, before, changeTypeId, changeType }));
    }

    if (option?.otherChanges) {
      this.otherChanges = Object.entries(option.otherChanges).map(([changeTypeId, otherChange]) => {
        return new ReviewChangeBaseItem(otherChange, changeTypeId);
      });
    }
  }

  getPayload = (): ReviewChangeRequest => {
    return {
      id: this.id,
      revId: this.revId,
      isAccepted: this.isAccepted,
      isApplied: this.isApplied,
      changeTypeId: this.changeTypeId,
      rejectNotes: this.rejectNotes,
    };
  };

  isValid = (): boolean => {
    return this.isAccepted || !!this.rejectNotes;
  };
}

export interface OptionsReviewResponse {
  isHighlighted: string;
  name: string;
  notes: string;
  code: string;
  modelApplicability: OptionModelApplicability;
  link: string;
  revId: string;
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
  description: string;
  shortDescription: string;
  isDeleted: boolean;
  isAccepted: boolean;
  isApplied: boolean;
  id: string;
  isInProgress: boolean;
  categoryId: string;
  isExtraCost: string;
}

export interface OptionsReviewMap {
  [id: string]: OptionsChangeTypeMap;
}

export interface OptionsChangeTypeMap extends ReviewChangeTypeMap {
  category: ReviewChangeMap<string>;
  name: ReviewChangeMap<string>;
  description: ReviewChangeMap<string>;
  code: ReviewChangeMap<string>;
  isExtraCost: ReviewChangeMap<string>;
  modelApplicability: ReviewChangeMap<KeyValueType<string>>;
}

export type OptionReviewType =
  | 'category'
  | 'name'
  | 'description'
  | 'code'
  | 'isExtraCost'
  | 'modelApplicability'
  | 'added'
  | 'deleted';

export interface OptionsMap {
  options: {
    [id: string]: OptionLangMap;
  };
  order: string[];
}

export interface OptionLangMap {
  langs: {
    [lang: string]: OptionItem;
  };
  data?: OptionResponse;
}
