import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  ActionButton,
  ActionItemModal,
  areRichTextValuesDifferent,
  CenteredTextArea,
  convertToRichTextObject,
  Modal,
} from 'vapi-ui-common';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import NotesPopover from '../../../../../../components/NotesPopover';
import { TableCell } from '../../../../../../components/Table';
import { TableHeightCell } from '../../../../../../components/Table/components/TableCell/TableHeightCell';
import TableDragIcon from '../../../../../../components/Table/components/TableDragIcon';
import useStores from '../../../../../../hooks/useStores';
import { OptionsPackage, OptionsPackageSpec } from '../../../../../../models/optionsLexus.model';
import CenteredTextAreaCell from '../../../../../../routes/vehicleData/components/tableCells/CenteredTextAreaCell';
import RichTextCell from '../../../../../../routes/vehicleData/components/tableCells/RichTextCell';
import { handleOnSortNumberUpdate } from '../../../../../../utils/sortUtils';
import PackageTableRow from '../PackageTableRow';
import OptionsLexusPackageNameColumn from './components/OptionsLexusPackageNameColumn/OptionsLexusPackageNameColumn';
import { IOptionsLexusPackageRow } from './IOptionsLexusPackageRow';
import { IOptionsLexusPackageSpecRow } from './IOptionsLexusPackageSpecRow';
import styles from './optionsLexusPackageRow.module.scss';

interface OptionsLexusPackageRowProps extends IOptionsLexusPackageRow, IOptionsLexusPackageSpecRow {
  optionsPackage: OptionsPackage;
  spec: OptionsPackageSpec;
  index: number;
  nameSpan: number;
  requiredSpan: number;
  zebra?: boolean;
  expandRow?: boolean;
}

const OptionsLexusPackageRow = ({
  optionsPackage,
  spec,
  index,
  sortMode,
  optionsTab,
  nameSpan = 1,
  requiredSpan = 1,
  disabled,
  tokens,
  zebra,
  expandRow,
  showTooltip,
  addOption = () => void 0,
  addEmptySpec = () => void 0,
  addEmptyVariety = () => void 0,
  deleteOption = () => void 0,
  updateOption = () => void 0,
  saveSpec = () => {},
  deleteSpec = () => {},
}: OptionsLexusPackageRowProps) => {
  const { optionsLexusStore } = useStores();
  const index2 = optionsLexusStore.filteredOptions.findIndex(x => x.id === optionsPackage.id);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const disableSpecInputs = disabled || optionsPackage.specs.length === 0;

  const handleOnSpecsChange = (spec: OptionsPackageSpec, specs: string) => {
    if (spec.specs !== specs) {
      spec.specs = specs;
      saveSpec(optionsPackage, spec);
    }
  };

  const handleOnTomsChange = (spec: OptionsPackageSpec, toms: string) => {
    if (spec.toms !== toms) {
      spec.toms = toms;
      saveSpec(optionsPackage, spec);
    }
  };

  const handleOnSmsChange = (spec: OptionsPackageSpec, sms: string) => {
    if (spec.sms !== sms) {
      spec.sms = sms;
      saveSpec(optionsPackage, spec);
    }
  };

  const handleOnToggleExclusive = (spec: OptionsPackageSpec) => {
    spec.isExclusive = !spec.isExclusive;
    saveSpec(optionsPackage, spec);
  };

  const handleOnNameChange = (option: OptionsPackage, name: string) => {
    if (areRichTextValuesDifferent(option.name, name)) {
      option.name = name;
      saveOption();
    }
  };

  const handleOnDescriptionChange = (option: OptionsPackage, description: string) => {
    if (areRichTextValuesDifferent(option.description, description)) {
      option.description = description;
      saveOption();
    }
  };

  const handleOnNotesChange = (option: OptionsPackage, notes: string) => {
    if (option.notes !== notes) {
      option.notes = notes;
      saveOption();
    }
  };

  const handleToggleInProgress = (option: OptionsPackage, isInProgress: boolean) => {
    option.isInProgress = isInProgress;
    saveOption();
  };

  const handleToggleTooltip = (option: OptionsPackage, tooltip: boolean) => {
    option.tooltip = tooltip;
    saveOption();
  };

  const handleOnCodeChange = (option: OptionsPackage, code: string) => {
    if (option.code !== code) {
      option.code = code;
      saveOption();
    }
  };

  const handleOnRequiredChange = (option: OptionsPackage, required: string) => {
    if (option.required !== required) {
      option.required = required;
      saveOption();
    }
  };

  const handleOnConflictsChange = (option: OptionsPackage, conflicts: string) => {
    if (option.conflicts !== conflicts) {
      option.conflicts = conflicts;
      saveOption();
    }
  };

  const saveOption = () => {
    if (!optionsPackage.id) {
      addOption(optionsPackage);
    } else {
      updateOption(optionsPackage);
    }
  };

  return (
    <Draggable draggableId={optionsPackage.uid} index={index}>
      {provided => (
        <PackageTableRow
          className={styles.row}
          zebra={zebra}
          expandRow={expandRow}
          innerRef={provided.innerRef}
          {...provided.draggableProps}
        >
          {sortMode && (
            <>
              <TableCell
                className={styles.dragCell}
                {...provided.dragHandleProps}
                rowSpan={nameSpan}
                border
                center
              >
                <TableDragIcon />
              </TableCell>
              <TableCell border center>
                <input
                  className={cx(inputStyles.input, inputStyles.smallInput)}
                  value={optionsPackage.sortOrder}
                  onBlur={newIndex =>
                    handleOnSortNumberUpdate(
                      optionsLexusStore.filteredOptions,
                      newIndex.target.value,
                      index2
                    )
                  }
                  onChange={e =>
                    (optionsPackage.sortOrder =
                      parseInt(e.currentTarget.value, 10) > 0
                        ? parseInt(e.currentTarget.value, 10)
                        : 0)
                  }
                />
              </TableCell>
            </>
          )}
          <TableCell colType="dropdown" rowSpan={nameSpan} className={styles.spannedCell} border>
            <OptionsLexusPackageNameColumn
              optionsPackage={optionsPackage}
              optionsTab={optionsTab}
              disabled={disabled}
              handleOnNotesChange={value => handleOnNotesChange(optionsPackage, value)}
              handleToggleInProgress={checked => handleToggleInProgress(optionsPackage, checked)}
              handleToggleTooltip={checked => handleToggleTooltip(optionsPackage, checked)}
              handleOnNameChange={value => handleOnNameChange(optionsPackage, value)}
              handleOnDescriptionChange={value => handleOnDescriptionChange(optionsPackage, value)}
              addEmptySpec={() => addEmptySpec(optionsPackage)}
              addEmptyVariety={() => addEmptyVariety(optionsPackage)}
              deleteOption={() => deleteOption(optionsPackage)}
              tokens={tokens}
              showTooltip={showTooltip}
            />
          </TableCell>

          {/* Code */}
          <TableCell rowSpan={nameSpan} className={styles.spannedCell} border>
            <CenteredTextArea
              size="xsmall"
              disabled={disabled}
              value={optionsPackage.code}
              onBlurCallback={value => handleOnCodeChange(optionsPackage, value)}
              useInput
              allowEmpty
              keepInputShown={!disabled}
            />
          </TableCell>

          {/* TOMS */}
          <CenteredTextAreaCell
            border
            size="xsmall"
            disabled={disableSpecInputs}
            value={spec.toms}
            onBlurCallback={value => handleOnTomsChange(spec, value)}
            allowEmpty
            singleLine
          />

          {/* SMS */}
          <CenteredTextAreaCell
            border
            size="xsmall"
            disabled={disableSpecInputs}
            value={spec.sms}
            onBlurCallback={value => handleOnSmsChange(spec, value)}
            allowEmpty
            singleLine
          />

          {/* Specs */}
          <RichTextCell
            colType="description"
            disabled={disableSpecInputs}
            value={spec.specs}
            onBlur={value => handleOnSpecsChange(spec, value)}
            suggestionTypes={tokens}
            cellStyles={styles.maxWidthSmall}
          />

          {/* Action Buttons */}
          <TableHeightCell
            onHeightChange={height => {
              optionsPackage.rowHeight = height;
              spec.rowHeight = height;
            }}
          >
            {!disabled && optionsPackage.specs.length > 0 && (
              <div style={{ display: 'flex' }}>
                <ActionButton icon="trash" onClick={() => setOpenDeleteModal(true)} />
                <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                  <ActionItemModal
                    onClose={() => setOpenDeleteModal(false)}
                    description={convertToRichTextObject(spec.specs).text}
                    onConfirm={() => deleteSpec(optionsPackage, spec)}
                    itemType="Spec"
                  />
                </Modal>
                <ActionButton
                  icon="flag"
                  selected={spec.isExclusive}
                  onClick={() => handleOnToggleExclusive(spec)}
                />
              </div>
            )}
            {spec.rejectNotes && (
              <NotesPopover
                notes={spec.rejectNotes}
                handleUpdateItem={() => void 0}
                align="left"
                icon="purpleCircle"
                iconText="Review"
                readOnly
              />
            )}
          </TableHeightCell>

          {/* Required */}
          <TableCell rowSpan={requiredSpan} className={styles.spannedCell} border>
            <CenteredTextArea
              size="xsmall"
              disabled={disabled}
              value={optionsPackage.required}
              onBlurCallback={value => handleOnRequiredChange(optionsPackage, value)}
              allowEmpty
              useInput
            />
          </TableCell>

          {/* Conflicts */}
          <TableCell rowSpan={requiredSpan} className={styles.spannedCell} border>
            <CenteredTextArea
              size="xsmall"
              disabled={disabled}
              value={optionsPackage.conflicts}
              onBlurCallback={value => handleOnConflictsChange(optionsPackage, value)}
              allowEmpty
              useInput
            />
          </TableCell>
        </PackageTableRow>
      )}
    </Draggable>
  );
};

export default observer(OptionsLexusPackageRow);
