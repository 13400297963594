import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { removeNulls } from '../utils';
import { RefItem } from './refItem.model';
import {
  CreateCfExtColorInput,
  CreateCfIntColorInput,
  CreateColorFamilyInput,
  UpdateCfExtColorInput,
  UpdateCfIntColorInput,
  UpdateColorFamilyInput,
} from '../gql/generated';

export class ColorFamilyItem {
  uid = '';
  id = '';
  revId = '';
  interiorColorsMap: { [id: string]: CFInteriorColorItem } = {};
  exteriorColorsMap: { [id: string]: CFExteriorColorItem } = {};
  @observable hexCode = '';
  @observable name = '';

  constructor(colorFamily?: ColorFamilyResponse) {
    this.uid = uuidv4();
    if (colorFamily) {
      Object.assign(this, removeNulls(colorFamily));
    }
  }

  canDelete = () => {
    return (
      !Object.keys(this.interiorColorsMap).length && !Object.keys(this.exteriorColorsMap).length
    );
  };

  isValid = () => {
    return this.name !== '' && this.hexCode !== '';
  };

  getCreatePayload = (): CreateColorFamilyInput => {
    return {
      name: this.name,
      hexCode: this.hexCode,
    };
  };

  getUpdatePayload = (): UpdateColorFamilyInput => {
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      hexCode: this.hexCode,
    };
  };
}
export interface ColorFamilyResponse {
  id: string;
  revId: string;
  name: string;
  hexCode: string;
}
export interface ColorFamilyRequest extends ColorFamilyResponse {}

export class CFColorItem {
  uid = '';
  id = '';
  revId = '';
  @observable colorFamilyNames = '';
  @observable code = '';
  @observable name = '';
  @observable hexCode = '';
  @observable colorFamilies = [] as ColorFamilyItem[];
  @observable material = {} as RefItem;

  isValid = () => {
    return false;
  };

  getPayload = (): any => {
    return {};
  };

  setColorFamilyNames() {
    const colorFamilyNames: string[] = [];

    this.colorFamilies.forEach(fam => {
      colorFamilyNames.push(fam.name);
    });

    this.colorFamilyNames = colorFamilyNames.join();
  }
}

export class CFInteriorColorItem extends CFColorItem {
  constructor(interiorColor?: CFInteriorColorResponse) {
    super();
    this.uid = uuidv4();
    if (interiorColor) {
      Object.assign(this, removeNulls(interiorColor));
    }
  }

  isValid = () => {
    return !!(this.name && this.code && this.material.id && this.colorFamilies.length);
  };

  getCreatePayload = (): CreateCfIntColorInput => {
    const colorFamilyIds = this.colorFamilies.reduce<{ [colorFamilyId: string]: boolean }>(
      (acc, fam) => {
        acc[fam.id] = true;
        return acc;
      },
      {}
    );

    return {
      name: this.name,
      code: this.code,
      materialId: this.material.id,
      colorFamilyIds,
    };
  };

  getUpdatePayload = (): UpdateCfIntColorInput => {
    return {
      id: this.id,
      revId: this.revId,
      ...this.getCreatePayload(),
    };
  };
}

export class CFExteriorColorItem extends CFColorItem {
  constructor(exteriorColor?: CFExteriorColorResponse) {
    super();
    this.uid = uuidv4();
    if (exteriorColor) {
      Object.assign(this, removeNulls(exteriorColor));
    }
  }

  isValid = () => {
    return !!(
      this.name &&
      this.code &&
      (process.env.REACT_APP_COLOR_FAMILY_V2 !== 'true' || this.hexCode) &&
      this.colorFamilies.length
    );
  };

  getCreatePayload = (): CreateCfExtColorInput => {
    const colorFamilyIds = this.colorFamilies.reduce<{ [colorFamilyId: string]: boolean }>(
      (acc, fam) => {
        acc[fam.id] = true;
        return acc;
      },
      {}
    );

    return {
      name: this.name,
      code: this.code,
      hexCode: this.hexCode,
      colorFamilyIds,
    };
  };

  getUpdatePayload = (): UpdateCfExtColorInput => {
    return {
      id: this.id,
      revId: this.revId,
      ...this.getCreatePayload(),
    };
  };
}

export interface CFColorResponse {
  id: string;
  revId: string;
  name: string;
  code: string;
  colorFamilyIds: { [colorFamilyId: string]: boolean };
}

export interface CFInteriorColorResponse extends CFColorResponse {
  materialId: string;
}

export interface CFInteriorColorRequest extends CFInteriorColorResponse {}

export interface CFExteriorColorResponse extends CFColorResponse {
  hexCode?: string;
}

export interface CFExteriorColorRequest extends CFExteriorColorResponse {}
