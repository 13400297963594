import { useState } from 'react';
import useStores from '../../../../../hooks/useStores';
import { KeyValueType } from '../../../../../models/common.model';
import { Language } from '../../../../../models/user.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../models/vehicleModel.model';
import { EditVehicleModelProps } from '../../../../../stores/vehicleData/modelTabStore';
import { hasModelChanges } from '../../../../../utils/modelsUtils';

type TUseModelProps = {
  modelMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>;
};

const useModel = ({ modelMap }: TUseModelProps) => {
  const {
    userStore: { langPermissions, isTdpr },
    teamStore: {
      team: { defaultLanguage, languages },
    },

    modelTabStore: { onEdit, getLangVehicleModel, getEditVehicleModel },
  } = useStores();

  const model = modelMap[defaultLanguage];

  onEdit(model, modelMap);

  const [modelVm, setModelVm] = useState<EditVehicleModelProps>(
    getEditVehicleModel(model, defaultLanguage)
  );
  const [katashikiArray, setKatashikiArray] = useState<string[]>(
    modelVm.katashiki?.split('|') || ['']
  );

  const getPayload = ({ language }: { language: Language }): any => {
    const katashikisString = katashikiArray
      .map(katashiki => katashiki?.trim())
      .filter(katashiki => katashiki !== '')
      .join('|');

    const {
      code,
      grade,
      fuelType,
      horsepower,
      seating,
      acceptChanges,
      tdprCode,
      isTDPR,
      isUSVI,
    } = modelVm;

    const { trimTitle, description, drive, engine, transmission, bed, cab } = getEditVehicleModel(
      model,
      language
    );

    const payload = {
      ...getEditVehicleModel(model, language),
      katashiki: katashikisString,
      code: code?.trim(),
      grade,
      fuelType,
      horsepower: horsepower?.trim(),
      seating: seating?.trim(),
      acceptChanges,
      trimTitle: trimTitle?.trim(),
      description: description?.trim(),
      drive: drive?.trim(),
      engine: engine?.trim(),
      transmission: transmission?.trim(),
      bed: bed?.trim(),
      cab: cab?.trim(),
    };

    if (isTdpr) {
      payload.tdprCode = tdprCode?.trim();
      payload.isTDPR = isTDPR;
      payload.isUSVI = isUSVI;
    }

    return payload;
  };

  const hasChangesLanguage = (language: Language) => {
    if (langPermissions[language]?.canEdit) {
      const payload = getPayload({
        language,
      });

      const langVM = getLangVehicleModel(modelMap[language], language);
      return hasModelChanges(langVM, payload);
    }

    return false;
  };

  const hasChanges = () => !!languages.find(language => hasChangesLanguage(language));

  return {
    model,
    modelVm,
    setModelVm,
    katashikiArray,
    setKatashikiArray,
    getPayload,
    hasChanges,
    hasChangesLanguage,
  };
};

export default useModel;
