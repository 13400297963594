import {
  Brand,
  Team,
  Status,
  FilterInput,
  Language,
  Region,
  InteriorTypesLexus,
} from '../gql/generated';

export const toGqlBrand = (val: string): Brand => {
  switch (val.toLowerCase()) {
    case Brand.Lexus:
      return Brand.Lexus;
    case Brand.Tdpr:
      return Brand.Tdpr;
    case Brand.Toyota:
    default:
      return Brand.Toyota;
  }
};

export const toGqlTeam = (val: string): Team => {
  const team = val.replace('-', '_').toLowerCase();

  switch (team) {
    case Team.AgencyTeam:
      return Team.AgencyTeam;
    case Team.ProductTeam:
    default:
      return Team.ProductTeam;
  }
};

export const toGqlLanguage = (val: string): Language => {
  switch (val.toUpperCase()) {
    case Language.Es:
      return Language.Es;
    case Language.En:
    default:
      return Language.En;
  }
};

export const toGqlRegion = (val: string): Region => {
  switch (val.toUpperCase()) {
    case Region.National:
      return Region.National;
    case Region.Tdpr:
      return Region.Tdpr;
    case Region.Gst:
      return Region.Gst;
    case Region.Set:
      return Region.Set;
    case Region.Usvi:
      return Region.Usvi;
    default:
      return Region.National;
  }
};

export const toGqlFilter = (val: string): FilterInput => {
  switch (val.toUpperCase()) {
    case Status.Published:
      return { status: Status.Published };
    case Status.Archived:
      return { status: Status.Archived };
    case Status.Draft:
      return { status: Status.Draft };
    default:
      return { version: parseInt(val) };
  }
};

export const toGqlInteriorTypesLexus = (val: string): InteriorTypesLexus => {
  switch (val.toLocaleLowerCase()) {
    case InteriorTypesLexus.Groups:
      return InteriorTypesLexus.Groups;
    case InteriorTypesLexus.InteriorTypes:
      return InteriorTypesLexus.InteriorTypes;
    case InteriorTypesLexus.Materials:
      return InteriorTypesLexus.Materials;
    case InteriorTypesLexus.Ornaments:
      return InteriorTypesLexus.Ornaments;
    default:
      return val as InteriorTypesLexus;
  }
};
