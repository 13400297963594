import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  Button,
  convertToRichTextObject,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'vapi-ui-common';
import { CommonLanguageChangeLogItem } from '../../../../../models/commonLanguage.model';

interface RevertChangeModalProps {
  onRevertChange: (item: CommonLanguageChangeLogItem) => void;
  close: () => void;
  changeLogItem: CommonLanguageChangeLogItem;
}

const RevertChangeModal = observer(
  ({ onRevertChange, close, changeLogItem }: RevertChangeModalProps) => {
    const descriptionObj = convertToRichTextObject(changeLogItem.description).text;
    const description =
      descriptionObj && descriptionObj.length > 120
        ? `${changeLogItem.description.substring(0, 120)}...`
        : descriptionObj;

    return (
      <>
        <ModalHeader onClose={close}>Revert Spec</ModalHeader>
        <ModalBody>
          Are you sure you want to revert the change for the spec "{description}"?
        </ModalBody>
        <ModalFooter>
          <Button
            variant="transparent"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onRevertChange(changeLogItem);
              close();
            }}
          >
            Revert
          </Button>
        </ModalFooter>
      </>
    );
  }
);

export default RevertChangeModal;
