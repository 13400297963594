import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useComponentVisible from '../../../hooks/useComponentVisible';
import React, { ReactNode } from 'react';
import { Dropdown, DropdownContent } from 'vapi-ui-common';
import styles from './headerNotificationButton.module.scss';

export interface HeaderNotificationButtonProps {
  children?: ReactNode;
}

const HeaderNotificationButton = ({ children }: HeaderNotificationButtonProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <div
      className={styles.headerNotificationButtonContainer}
      data-testid="header-notification-buton"
    >
      <Dropdown>
        <button
          className={styles.headerNotificationButton}
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          <FontAwesomeIcon icon={faBell} />
          <div className={styles.headerNotificationButtonText}>Review Pending</div>
        </button>

        <DropdownContent ref={ref} open={isComponentVisible} align="right">
          {children}
        </DropdownContent>
      </Dropdown>
    </div>
  );
};

export default HeaderNotificationButton;
