import { observer } from 'mobx-react-lite';
import {
  CommonLanguageItem,
  CommonLanguageVehicle,
  CommonLanguageVehicleChecklist,
} from '../../../../../../models/commonLanguage.model';
import React, { useEffect, useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabBody,
  FilterTabs,
  FilterTabSection,
  ModalFooter,
  ModalHeader,
} from 'vapi-ui-common';
import styles from './vehiclesModal.module.scss';
import VehiclesTab from './VehiclesTab';

interface CheckListMapItem {
  [key: string]: CommonLanguageVehicleChecklist[];
}

interface VehiclesModalProps {
  readOnly: boolean | undefined;
  close: () => void;
  onUpdate: (vehicles: CommonLanguageVehicle[]) => void;
  commonLanguageItem: CommonLanguageItem;
  vehicleModels: CommonLanguageVehicle[];
}

const VehiclesModal = observer(
  ({ readOnly, close, onUpdate, commonLanguageItem, vehicleModels }: VehiclesModalProps) => {
    const [currentYear, setCurrentYear] = useState('');
    const [data, setData] = useState<CheckListMapItem>({} as CheckListMapItem);
    const [years, setYears] = useState<string[]>([]);

    useEffect(() => {
      const list = {} as CheckListMapItem;
      vehicleModels.forEach(model => {
        // add year object if doesn't exists
        if (!list[model.modelYear]) {
          list[model.modelYear] = [];
        }

        // fetch/add category object
        let category = list[model.modelYear].filter(listItem => listItem.name === model.group)[0];
        if (!category) {
          category = {
            name: model.group as string,
            selected: true, // category is true initially. Becomes false if any vehicles is not selected
            items: [],
          };
          list[model.modelYear].push(category);
        }

        // add vehicles to category
        const selected =
          commonLanguageItem.vehicles.filter(
            veh => veh.seriesId === model.seriesId && veh.modelYear === `${model.modelYear}`
          ).length > 0;
        category.items.push({
          id: model.seriesId,
          name: model.seriesName,
          selected,
        });
        if (!selected) {
          category.selected = false;
        }
      });

      setYears(Object.keys(list));
      setCurrentYear(Object.keys(list)[0]);
      setData(list);
    }, [commonLanguageItem, vehicleModels]);

    const saveModels = () => {
      const saved: CommonLanguageVehicle[] = [];
      const totalCount = Object.keys(data).reduce((count: number, modelYear: string) => {
        return count + data[modelYear].reduce((sum, item) => sum + item.items.length, 0);
      }, 0);

      for (const modelYear in data) {
        data[modelYear].forEach(item => {
          item.items.forEach(child => {
            if (child.selected) {
              saved.push({
                seriesId: child.id,
                seriesName: child.name,
                modelYear,
              });
            }
          });
        });
      }

      onUpdate(totalCount === saved.length ? ([] as CommonLanguageVehicle[]) : saved);
      close();
    };

    return (
      <>
        <ModalHeader onClose={close}>Add Models</ModalHeader>
        <section className={styles.body}>
          <FilterTab>
            <FilterTabs
              tabs={years}
              selectedTab={currentYear}
              onSelected={tab => setCurrentYear(tab)}
            />
            <FilterTabBody>
              {years.map(year => (
                <FilterTabSection key={year} active={year === currentYear}>
                  <VehiclesTab readOnly={readOnly} data={data[year]} year={year} />
                </FilterTabSection>
              ))}
            </FilterTabBody>
          </FilterTab>
        </section>
        <ModalFooter>
          <Button variant="transparent" onClick={() => close && close()}>
            Cancel
          </Button>
          {!readOnly && (
            <Button variant="primary" onClick={() => saveModels()}>
              Add Models
            </Button>
          )}
        </ModalFooter>
      </>
    );
  }
);

export default VehiclesModal;
