import { processRteForChangeLog } from 'vapi-ui-common';
import { BnPItemsReviewResponse, BnpCategoryReviewResponse } from '../models/buildAndPrice.model';
import { ChangeLogTypes } from '../models/changeLog.model';
import { IDValueType, KeyValueType } from '../models/common.model';
import { FeatureSettings, FeaturesReviewResponse } from '../models/features.model';
import { RefItem } from '../models/refItem.model';
import { ReviewChangeBaseItem } from '../models/review.model';
import { SeriesSettingsReviewResponse } from '../models/seriesSettings.model';
import { SpecSettings, SpecsReviewResponse } from '../models/specs.model';

export const updateRevIdById = (id: string, revId: string, items: any[]) => {
  items.forEach(item => {
    if (item.id === id) {
      item.revId = revId;
    }
  });
};

export const handleOtherChangesForReview = (
  otherChanges: ReviewChangeBaseItem[],
  objects: { type: ChangeLogTypes; refItems: IDValueType<any>[] }[]
) => {
  otherChanges.forEach(otherChange => {
    objects.forEach(obj => {
      if (otherChange.changes.changeType === obj.type) {
        otherChange.changes.beforeValue = obj.refItems.filter(
          refItem => refItem.id === otherChange.changes.before
        )[0]?.value;
        otherChange.changes.afterValue = obj.refItems.filter(
          refItem => refItem.id === otherChange.changes.after
        )[0]?.value;
      }
    });
  });
};

export const handleOtherChangesForColorsLexusReview = (
  otherChanges: ReviewChangeBaseItem[],
  objects: { type: ChangeLogTypes; refItems: IDValueType<any>[] }[]
) => {
  otherChanges.forEach(otherChange => {
    objects.forEach(obj => {
      if (otherChange.changes.changeType === obj.type) {
        otherChange.changes.before = obj.refItems.filter(
          refItem => refItem.id === otherChange.changes.before
        )[0]?.value;
        otherChange.changes.after = obj.refItems.filter(
          refItem => refItem.id === otherChange.changes.after
        )[0]?.value;
      }
    });
  });
};

export const hasChange = (data: { changes?: KeyValueType<any> }[]) => {
  for (const item of data) {
    if (Object.keys(item.changes || {}).length) {
      return true;
    }
  }
  return false;
};

export const seriesSettingsHasChanges = (data: SeriesSettingsReviewResponse[]) => {
  for (const item of data) {
    if (Object.keys(item.changes || {}).length) {
      return true;
    }

    const subSeriesIds = Object.keys(item.subSeries || {});

    for (const id of subSeriesIds) {
      if (Object.keys(item.subSeries ? item.subSeries[id].changes : {}).length) {
        return true;
      }
    }
  }
  return false;
};

export const bnpHasChanges = (data: BnPItemsReviewResponse[]) => {
  for (const item of data) {
    const categoryNames = Object.keys(item.categories);

    for (const categoryName of categoryNames) {
      const thisCategory: BnpCategoryReviewResponse = item.categories[categoryName];

      if (
        Object.keys(thisCategory.changes || {}).length ||
        Object.keys(thisCategory.splitChanges || {}).length
      ) {
        return true;
      }
    }
  }

  return false;
};

export const getDefaultChangeTypeMap = (
  item: FeaturesReviewResponse | SpecsReviewResponse,
  app: KeyValueType<FeatureSettings | SpecSettings | null>
) => {
  return {
    id: item.id,
    revId: item.revId,
    isApplied: false,
    isDeleted: false,
    description: {
      before: '',
      after: processRteForChangeLog(item.description),
      hasChanged: false,
    },
    modelApplicability: { before: {}, after: app, hasChanged: false },
    isNew: false,
    rejectNotes: '',
    notes: item.notes || '',
    isInProgress: item.isInProgress,
  };
};

export const filterDuplicates = (arr: RefItem[] | IDValueType[]) => {
  const arrayOfValues: string[] = [];
  arr.forEach(el => arrayOfValues.push(el.value));
  return arr.filter((item, index) => arrayOfValues.indexOf(item.value) === index);
};
