import cx from 'clsx';
import React from 'react';
import styles from './multiselectcheckbox.module.scss';

export interface MultiSelectCheckboxProps {
  label: string;
  value: string;
  className?: string;
  name?: string;
  selectedValue?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const MultiSelectCheckbox = ({
  label,
  value,
  className,
  name,
  selectedValue,
  onChange = () => {},
  disabled = false,
}: MultiSelectCheckboxProps) => {
  return (
    <label
      className={cx(styles.multiselectcheckbox, styles.default, className)}
      data-testid="multi-select-checkbox"
    >
      <input
        type="checkbox"
        value={value}
        name={name}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          onChange(e.currentTarget.value);
        }}
        checked={value === selectedValue}
        disabled={disabled}
      />
      {label}
    </label>
  );
};
