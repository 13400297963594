import React, { useState } from 'react';
import { Button } from 'vapi-ui-common';
import cx from 'clsx';
import { ApplicabilityTextLangMap } from '../../../../../../models/compareFeatures.model';
import { Language } from '../../../../../../models/user.model';
import styles from './applicabilityText.module.scss';

interface ApplicabilityTextProps {
  applicabilityTextMap: ApplicabilityTextLangMap;
  grade: string;
  onUpdate: (textMap: ApplicabilityTextLangMap) => void;
  closePopover: () => void;
}

const ApplicabilityText = ({
  applicabilityTextMap,
  grade,
  onUpdate,
  closePopover,
}: ApplicabilityTextProps) => {
  const langs = Object.keys(applicabilityTextMap.text) as Language[];
  const [formTextMap, setFormTextMap] = useState(applicabilityTextMap);

  return (
    <section className={styles.applicabilityText}>
      <header className={styles.header}>Applicability Text</header>
      <header className={styles.grade}>{grade}</header>
      <div className={styles.body}>
        {langs.map(lang => {
          return (
            <>
              <div className={styles.langTitle}>{lang === Language.EN ? 'English' : 'Spanish'}</div>
              <textarea
                placeholder="Placeholder"
                className={cx(
                  styles.textarea,
                  !!formTextMap.text[lang]?.syncValueChange && styles.syncValueChanged
                )}
                onChange={e => {
                  setFormTextMap({
                    ...formTextMap,
                    text: {
                      ...formTextMap.text,
                      [lang]: {
                        ...formTextMap.text[lang]!,
                        text: e.currentTarget.value,
                      },
                    },
                  });
                }}
                disabled={!formTextMap.text[lang]?.canEdit}
                value={formTextMap.text[lang]?.text || ''}
              />
            </>
          );
        })}
      </div>
      <footer className={styles.footer}>
        <Button
          variant="transparent"
          onClick={() => {
            closePopover && closePopover();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            langs.forEach(lang => {
              const langMap = formTextMap.text[lang];
              if (langMap) {
                langMap.text = langMap.text.trim();
                if (langMap.text === 'o' || langMap.text === 's' || langMap.text === 'p') {
                  langMap.text = langMap.text.toUpperCase();
                }
              }
            });
            onUpdate && onUpdate(formTextMap);
            closePopover && closePopover();
          }}
        >
          Save
        </Button>
      </footer>
    </section>
  );
};

export default ApplicabilityText;
