import { action, observable } from 'mobx';
import { CategoryResponse } from '../../../models/category.model';
import { IDValueType } from '../../../models/common.model';
import {
  CompareFeaturesReviewMap,
  CompareFeaturesReviewResponse,
} from '../../../models/compareFeatures.model';
import { RefItem, RefItemObject, RefItemResponse } from '../../../models/refItem.model';
import { categoriesXForm } from '../../../utils/categoryUtils';
import { compareFeaturesReviewItemXForm } from '../../../utils/compareFeaturesUtils';
import { refItemsXForm } from '../../../utils/refItemUtils';
import BaseReviewStore from './baseReviewStore';

class CompareFeaturesReviewStore extends BaseReviewStore {
  grades: RefItem[] = [];
  @observable categories: IDValueType[] = [];
  @observable subCategories: IDValueType[] = [];
  @observable map: CompareFeaturesReviewMap = {};

  @action setMap(items: CompareFeaturesReviewResponse[]) {
    this.map = compareFeaturesReviewItemXForm(
      items,
      this.categories,
      this.subCategories,
      this.grades
    );
  }

  @action setCategories(
    response: CategoryResponse[],
    specResponse: RefItemResponse<RefItemObject>[]
  ) {
    const featureCategories = categoriesXForm(response);
    const specCategories = refItemsXForm(specResponse).map(cat => {
      return new IDValueType(cat.id, cat.value);
    });
    this.categories = [...featureCategories, ...specCategories];
  }

  @action setSubCategories(
    response: CategoryResponse[],
    specResponse: RefItemResponse<RefItemObject>[]
  ) {
    const featureSubCategories = categoriesXForm(response);
    const specTypes = refItemsXForm(specResponse).map(cat => {
      return new IDValueType(cat.id, cat.value);
    });
    this.subCategories = [...featureSubCategories, ...specTypes];
  }

  @action reset() {
    this.map = {};
    this.categories = [];
    this.subCategories = [];
  }
}

export default CompareFeaturesReviewStore;
