import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import useStores from '../../../../../hooks/useStores';
import { SeriesInfo, VehicleTeam } from '../../../../../models/vehicleData.model';
import { getSeries } from '../../../../../webservices/adminApi';
import { toGqlBrand } from '../../../../../utils/graphqlUtils';

interface IUseSeriesProps {
  setReadOnly: (readOnly: boolean) => void;
}

const useSeries = ({ setReadOnly }: IUseSeriesProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [seriesInfo, setSeriesInfo] = useState({} as SeriesInfo);
  const { userStore, vehicleSeriesInfoStore, teamStore } = useStores();

  const { team, seriesId, year } = useParams<{
    team: string;
    seriesId: string;
    year: string;
  }>();

  useEffect(() => {
    (async () => {
      const { brand, langPermissions } = userStore;

      teamStore.setTeam(team as VehicleTeam, brand, langPermissions);
      userStore.setTeamModule(team as VehicleTeam);

      try {
        const responses = await getSeries({ brand: toGqlBrand(brand) });

        const series = responses.series[seriesId];
        if (series) {
          setSeriesInfo(series);

          vehicleSeriesInfoStore.seriesId = seriesId;
          vehicleSeriesInfoStore.seriesName = series.name;
          vehicleSeriesInfoStore.seriesGroup = series.group;
          vehicleSeriesInfoStore.year = year;

          // check if user can edit a change log language
          let canEditLanguage = false;
          teamStore.team.changeLogLanguages.forEach(lang => {
            if (teamStore.team.langPermissions[lang]?.canEdit) {
              canEditLanguage = true;
            }
          });

          // set readOnly permissions by group
          if (canEditLanguage) {
            setReadOnly(!userStore.teamModule.series[vehicleSeriesInfoStore.seriesGroup].canEdit);
          }
        }
      } catch (e) {
        if (!e.response || !e.response.data.message.includes('exist')) {
          toast.error('Failed loading change log data');
        }
      }

      setIsLoaded(true);
    })();
  }, [
    setIsLoaded,
    setSeriesInfo,
    setReadOnly,
    team,
    seriesId,
    year,
    userStore,
    teamStore,
    vehicleSeriesInfoStore,
  ]);

  return { seriesInfo, isLoaded };
};

export default useSeries;
