import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import logger from "./log";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: "/"
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            logger.error(message);
            break;
          case LogLevel.Verbose:
            logger.debug(message);
            break;
          case LogLevel.Warning:
            logger.warn(message);
            break;
          default:
            break;
        }
      }
    }
  }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["User.Read"],
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const DO_NOT_LOGIN = process.env.REACT_APP_AZURE_AUTH === 'skip';

export const getToken = (request = loginRequest) => new Promise((resolve, reject) => {
  if (DO_NOT_LOGIN) {
    resolve(process.env.REACT_APP_AZURE_AUTH);
  } else {
    msalInstance.acquireTokenSilent({
      ...request,
      account: msalInstance.getAllAccounts()[0]
    }).then((response) => {
      if (response.accessToken) {
        resolve(response.accessToken);
      }
    }).catch((reason) => {
      logger.error('error in acquiring token', reason);
      reject(new Error(reason));
    });
  }
});

export const getUser = () => {
  if (DO_NOT_LOGIN) {
    return {
      localAccountId: process.env.REACT_APP_OBJECT_ID
    };
  }
  if (!msalInstance.getActiveAccount()) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  return msalInstance.getActiveAccount();
}

export const logout = async () => {
  await msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
}
