import { v4 as uuidv4 } from 'uuid';
import { ChangeLogTypes } from '../models/changeLog.model';
import { InteriorColorTypeResponse } from '../models/colors.model';
import {
  ColorLexusInteriorReviewItem,
  ColorsLexusExteriorItem,
  ExteriorAccessibilityGroup,
  GroupItem,
  InteriorCategory,
  InteriorColorTypesLexus,
  InteriorGroup,
  InteriorGroupItem,
  InteriorLexusTypes,
  InteriorMaterial,
  LexusIntColorGroupsList,
  LexusIntColorModelCodes,
  VDExteriorColorLexus,
  VDInteriorColorLexus,
} from '../models/colorsLexus.model';
import { IDValueType } from '../models/common.model';
import { ReviewChangeBaseItem } from '../models/review.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../models/vehicleModel.model';
import { convertKeyValuesToIDValues, removeNulls } from '../utils';
import { changeLogLexusColorApplicabilityHelperFunction } from './changeLogUtils';
import { handleOtherChangesForColorsLexusReview } from './reviewUtils';

export const colorLexusInteriorXform = (
  interiorColorTypes: InteriorColorTypesLexus,
  interiorResponse: VDInteriorColorLexus[]
) => {
  // Consolidate groups within group applicability
  const groups: InteriorGroup[] = [];
  const groupsCopy = JSON.parse(JSON.stringify(interiorColorTypes.groups));

  interiorColorTypes.groups.forEach(group => {
    // create new group
    const gItem = new InteriorGroup();
    gItem.group = group;

    const materials: InteriorMaterial[] = [];

    interiorResponse.forEach(item => {
      // get material item
      const matItem = getMaterialItem(materials, interiorColorTypes, item);
      // let matItem = materials.find(mat => mat.material.id === item.materialId);
      // if (!matItem) {
      //   matItem = new InteriorMaterial();
      //   const foundMat = interiorColorTypes.materials.find(
      //     typeItem => typeItem.id === item.materialId
      //   ) as IDValueType;
      //   if (foundMat) {
      //     matItem.material = foundMat;
      //   }
      //   materials.push(matItem);
      // }

      // get category for material item
      const category = new InteriorCategory();
      category.id = item.id;
      category.revId = item.revId;
      category.interiorType = interiorColorTypes.interiorTypes.find(
        typeItem => typeItem.id === item.interiorTypeId
      ) as IDValueType;
      category.interiorType_subId = item.interiorType_subId || '';

      if (item.ornamentType1Id) {
        const oType1 = interiorColorTypes.ornaments.find(
          typeItem => typeItem.id === item.ornamentType1Id
        );
        if (oType1) {
          category.ornamentType1 = oType1;
        }
      }
      category.ornamentType1_subId = item.ornamentType1_subId || '';

      if (item.ornamentType2Id) {
        const oType2 = interiorColorTypes.ornaments.find(
          typeItem => typeItem.id === item.ornamentType2Id
        );
        if (oType2) {
          category.ornamentType2 = oType2;
        }
      }

      category.ornamentType2_subId = getItemValue(item, 'ornamentType2_subId');
      category.seatAccent = item.seatAccent;
      category.headliner = item.headliner;
      category.ip = item.ip;
      category.armRest = item.armRest;
      category.carpet = item.carpet;
      category.rejectNotes = getItemValue(item, 'rejectNotes');
      category.isExtraCost = item.isExtraCost;

      if (item.changes) {
        category.changes = Object.entries(item.changes).map(([field, change]) => {
          return new ReviewChangeBaseItem(change, field);
        });
      }

      // assign group and group applicability
      groupsCopy.forEach((group: InteriorGroupItem) => {
        for (const modelId in group.models) {
          group.models[modelId] = false;
        }
        Object.entries(item.groupApplicability).forEach(entry => {
          const [key, value] = entry;
          if (group.id === key) {
            Object.entries(value).forEach(applicabilityModelEntry => {
              const [applicabilityModelKey, applicabilityModelValue] = applicabilityModelEntry;
              for (const modelId in group.models) {
                if (modelId === applicabilityModelKey) {
                  group.models[modelId] = applicabilityModelValue;
                }
              }
            });
            category.groups.push(group);
          }
        });
      });

      Object.keys(item.groupApplicability).forEach(key => {
        // add category to material if category's groupAccessibilty includes current group
        if (matItem && group.id === key) {
          category.groups.forEach(grp => {
            if (
              grp.name === group.name &&
              Object.values(grp.models).some(modelVal => modelVal === true)
            ) {
              matItem.categories.push(category);
            }
          });
        }
      });
    });

    // assign materials, filter out materials that don't have categories
    gItem.materials = materials.filter(mat => mat.categories.length > 0);

    groups.push(gItem);
  });

  return groups;
};

const getMaterialItem = (
  materials: InteriorMaterial[],
  interiorColorTypes: InteriorColorTypesLexus,
  item: VDInteriorColorLexus
) => {
  let matItem = materials.find(mat => mat.material.id === item.materialId);
  if (!matItem) {
    matItem = new InteriorMaterial();
    const foundMat = interiorColorTypes.materials.find(
      typeItem => typeItem.id === item.materialId
    ) as IDValueType;
    if (foundMat) {
      matItem.material = foundMat;
    }
    materials.push(matItem);
  }
  return matItem;
};

const getItemValue = (item: VDInteriorColorLexus, type: 'ornamentType2_subId' | 'rejectNotes') => {
  return item[type] || '';
};

export const colorLexusExteriorXform = (
  exteriorResponse: VDExteriorColorLexus[],
  interiorGroups: InteriorGroup[]
) => {
  const colors: ColorsLexusExteriorItem[] = [];

  exteriorResponse.forEach((extResp, index) => {
    const newItem = new ColorsLexusExteriorItem(extResp);

    // populate checkboxes for accessibilty table
    interiorGroups.forEach(intGroupResp => {
      const extGroup = new ExteriorAccessibilityGroup();
      extGroup.group = intGroupResp.group;
      extGroup.interiors = [];
      intGroupResp.materials.forEach(material => {
        material.categories.forEach(category => {
          extGroup.interiors.push(
            new IDValueType<boolean>(
              category.id,
              !!extResp.colorApplicability.find(
                item => item.interiorColorId === category.id && item.groupId === extGroup.group.id
              )
            )
          );
        });
      });

      if (!extGroup.interiors.length) {
        extGroup.interiors.push(new IDValueType<boolean>('', false));
      }

      newItem.colorApplicability.push(extGroup);
    });

    if (extResp.changes) {
      newItem.changes = Object.entries(extResp.changes).map(([field, change]) => {
        return new ReviewChangeBaseItem(change, field);
      });
    }

    if (extResp.otherChanges) {
      newItem.otherChanges = Object.entries(extResp.otherChanges).map(
        ([changeTypeId, otherChange]) => {
          const { after, before, changeType } = otherChange;
          return removeNulls({
            uid: uuidv4(),
            id: '',
            revId: '',
            after,
            before,
            changeTypeId,
            changeType,
          });
        }
      );
    }
    newItem.sortOrder = index + 1;
    colors.push(newItem);
  });

  return colors;
};

export const mapColorLexusExteriorApplicabilityReviewXform = (
  exteriorItems: ColorsLexusExteriorItem[],
  interiorResponse: VDInteriorColorLexus[],
  groups: InteriorGroupItem[]
) => {
  exteriorItems.forEach(extItem => {
    extItem.changes.forEach(change => {
      changeLogLexusColorApplicabilityHelperFunction(
        interiorResponse,
        groups,
        change.changes,
        change.changes.before,
        change.changes.after,
        change.changes.extColorAppBefore
      );
      changeLogLexusColorApplicabilityHelperFunction(
        interiorResponse,
        groups,
        change.changes,
        change.changes.after,
        change.changes.before,
        change.changes.extColorAppAfter
      );
    });
  });
};

export const mapColorLexusInteriorGroupApplicabilityReviewXform = (
  interiorItems: ColorLexusInteriorReviewItem[],
  groups: InteriorGroupItem[]
) => {
  interiorItems.forEach(intItem => {
    intItem.changes.forEach(change => {
      if (change.changes.changeType === ChangeLogTypes.INT_COLOR_GROUP_APPLICABILITY) {
        change.changes.beforeValue = mapColorLexusInteriorGroupApplicabilityReviewXformHelper(
          change.changes.before,
          groups
        ).join(' / ');
        change.changes.afterValue = mapColorLexusInteriorGroupApplicabilityReviewXformHelper(
          change.changes.after,
          groups
        ).join(' / ');
      }
    });
  });
};

export const mapColorLexusInteriorGroupApplicabilityReviewXformHelper = (
  intGroups: any,
  groups: InteriorGroupItem[]
) => {
  const values: string[] = [];
  if (Array.isArray(intGroups)) {
    intGroups.forEach(ig => {
      const found = groups.find(g => g.id === ig);
      if (found) {
        values.push(found.name);
      }
    });
  }

  return values;
};

export const interiorColorTypesLexusXForm = (response: InteriorColorTypeResponse) => {
  const retObj = {
    groups: [] as InteriorGroupItem[],
    materials: [] as IDValueType[],
    ornaments: [] as IDValueType[],
    interiorTypes: [] as IDValueType[],
  };
  if (Object.keys(response).length) {
    retObj.groups = convertInteriorTypeGroups(response.groups);
    retObj.materials = convertKeyValuesToIDValues(response.materials);
    retObj.ornaments = convertKeyValuesToIDValues(response.ornaments);
    retObj.interiorTypes = convertKeyValuesToIDValues(response.interiorTypes);
  }

  return retObj;
};

export const colorLexusInteriorReviewXform = (
  response: VDInteriorColorLexus[],
  interiorLexusTypes: InteriorLexusTypes
) => {
  return response.map(interior => {
    const newIntItem = new ColorLexusInteriorReviewItem(interior);
    if (interior.changes) {
      newIntItem.changes = Object.entries(interior.changes).map(([field, change]) => {
        return new ReviewChangeBaseItem(change, field);
      });
    }

    if (interior.otherChanges) {
      newIntItem.otherChanges = Object.entries(interior.otherChanges).map(
        ([changeTypeId, otherChange]) => {
          return new ReviewChangeBaseItem(otherChange, changeTypeId);
        }
      );

      handleOtherChangesForColorsLexusReview(newIntItem.otherChanges, [
        { type: ChangeLogTypes.INTERIOR, refItems: interiorLexusTypes.interiorTypes },
        { type: ChangeLogTypes.INTERIOR_TYPE_CHANGED, refItems: interiorLexusTypes.interiorTypes },
        { type: ChangeLogTypes.MATERIALS, refItems: interiorLexusTypes.materials },
        { type: ChangeLogTypes.MATERIAL_CHANGED, refItems: interiorLexusTypes.materials },
        { type: ChangeLogTypes.ORNAMENT_1, refItems: interiorLexusTypes.ornaments },
        { type: ChangeLogTypes.ORNAMENT_2, refItems: interiorLexusTypes.ornaments },
        { type: ChangeLogTypes.ORNAMENT_CHANGED, refItems: interiorLexusTypes.ornaments },
      ]);
    }
    return newIntItem;
  });
};

// adds model codes under grades for the checklist modal
export const groupsWithModelCodeXform = (
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  availableGroups: InteriorGroupItem[]
) => {
  const groupChecklist: LexusIntColorGroupsList[] = [];
  const map = new Map();

  availableGroups.forEach(grp => {
    const modelcodes: LexusIntColorModelCodes[] = [];

    vehicleModels.forEach(code => {
      if (Object.keys(grp.models).length) {
        Object.keys(grp.models).forEach(key => {
          if (!modelcodes.some(mc => mc.name === code.getVal('code')) && code.id === key) {
            modelcodes.push({
              id: code.id,
              name: code.getVal('code'),
              selected: false,
              uniqueKey: `${code.id}-${grp.name}`,
            });
          }
        });

        const checklist: LexusIntColorGroupsList = {
          id: grp.id,
          name: grp.name,
          selected: false,
          items: modelcodes,
        };
        groupChecklist.push(checklist);
      }
    });
  });

  return groupChecklist.filter(item => {
    if (!map.has(item.id)) {
      map.set(item.id, true);
      return true;
    } else {
      return false;
    }
  });
};

export const convertInteriorTypeGroups = (keyVals: GroupItem) => {
  const groups = [] as InteriorGroupItem[];

  Object.values(keyVals).forEach(value => {
    const groupItem = new InteriorGroupItem();
    if (!value.isDeleted) {
      groupItem.name = value.name;
      groupItem.id = value.id;
      groupItem.revId = value.revId;
      groupItem.models = value.models;

      groups.push(groupItem);
    }
  });

  return groups;
};
