import { observer } from 'mobx-react-lite';
import React from 'react';
import { ChangeLogBeforeAfter } from '../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import {
  HeaderCell,
  HeaderRow,
  TableRow,
  Thead,
  TwoTableWrapper,
} from '../../../../components/Table';
import useKeyFeatureOptionsLoad from '../../../../hooks/useKeyFeatureOptionsLoad';
import useStores from '../../../../hooks/useStores';
import { FeaturesChangeTypeMap } from '../../../../models/features.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../models/vehicleModel.model';
import LeftTable from '../../components/LeftTable';
import ModelTable from '../../components/ModelTable';
import ModelApplicabilityReviewCell from '../../components/ModelTable/components/ModelApplicabilityReviewCell/ModelApplicabilityReviewCell';
import { ApplyCell } from '../../components/tableCells/ApplyCell/ApplyCell';
import FlagsCell from '../../components/tableCells/FlagsCell/FlagsCell';
import VDTableCell from '../../components/tableCells/VDTableCell';

interface IFeaturesReviewTable {
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  handleOnApplyChange: (item: FeaturesChangeTypeMap, value: boolean) => void;
  handleOnNotesChange: (item: FeaturesChangeTypeMap, value: string) => void;
}

const FeaturesReviewTable = ({
  vehicleModels,
  handleOnApplyChange,
  handleOnNotesChange,
}: IFeaturesReviewTable) => {
  const {
    featuresReviewStore,
    teamStore,
    tableSizeStore: { tableRowHeight },
    featuresStore,
  } = useStores();

  const { showKeyFeature } = useKeyFeatureOptionsLoad();
  const keyFeaturesMap = featuresStore.keyFeaturesMap ?? {};

  return (
    <TwoTableWrapper>
      <LeftTable>
        <Thead>
          <HeaderRow style={{ height: `${tableRowHeight}px` }}>
            <HeaderCell>Apply & Accept?</HeaderCell>
            <HeaderCell onClick={() => featuresReviewStore.setSort('category.after')}>
              Category
            </HeaderCell>
            <HeaderCell onClick={() => featuresReviewStore.setSort('subCategory.after')}>
              Sub Category
            </HeaderCell>
            <HeaderCell onClick={() => featuresReviewStore.setSort('description.after')}>
              Description
            </HeaderCell>
            {showKeyFeature && <HeaderCell>Key Feature</HeaderCell>}
            <HeaderCell />
          </HeaderRow>
        </Thead>
        <tbody>
          {featuresReviewStore.filteredReviewItems.map((reviewMap, index) => {
            return (
              <TableRow
                key={`review-row#${index}`}
                onFillRowHeightChange={height => {
                  reviewMap.rowHeight = height;
                }}
              >
                <ApplyCell
                  id={reviewMap.id}
                  applyChecked={reviewMap.isApplied}
                  onApplyChecked={isApplied => {
                    handleOnApplyChange(reviewMap, isApplied);
                  }}
                  notes={reviewMap.rejectNotes}
                  handleOnNotesChange={notes => handleOnNotesChange(reviewMap, notes)}
                  hideRejectNotes={teamStore.team.param === VehicleTeam.AGENCY_TEAM}
                />
                <VDTableCell colType="dropdown">
                  <ChangeLogBeforeAfter
                    before={reviewMap.category.before}
                    after={reviewMap.category.after}
                    styleAfter={reviewMap.category.hasChanged}
                  />
                </VDTableCell>
                <VDTableCell colType="dropdown">
                  <ChangeLogBeforeAfter
                    before={reviewMap.subCategory.before}
                    after={reviewMap.subCategory.after}
                    styleAfter={reviewMap.subCategory.hasChanged}
                  />
                </VDTableCell>
                <VDTableCell colType="dropdown">
                  <ChangeLogBeforeAfter
                    before={reviewMap.description.before}
                    after={reviewMap.description.after}
                    styleAfter={reviewMap.description.hasChanged}
                  />
                </VDTableCell>
                <VDTableCell colType="dropdown">
                  {showKeyFeature &&
                    reviewMap.keyFeatures.map((map, idx) => {
                      return (
                        <ChangeLogBeforeAfter
                          key={`keyFeature-changes-${idx}`}
                          before={keyFeaturesMap[map.before]?.name.EN || ''}
                          after={keyFeaturesMap[map.after]?.name.EN || ''}
                          styleAfter={map.hasChanged}
                        />
                      );
                    })}
                </VDTableCell>
                <FlagsCell
                  index={0}
                  disabled
                  highlighted={false}
                  inProgress={false}
                  notes={reviewMap.notes}
                  onNotesChange={notes => handleOnNotesChange(reviewMap, notes)}
                />
              </TableRow>
            );
          })}
        </tbody>
      </LeftTable>
      <ModelTable
        showFeatureSplits={teamStore.team.allowSplits}
        viewModelCodes={featuresReviewStore.viewModelCodes}
        models={vehicleModels}
        headerStyle={{ top: 0 }}
        renderRows={() => (
          <>
            {featuresReviewStore.filteredReviewItems.map((reviewMap, idx) => (
              <React.Fragment key={idx}>
                <TableRow
                  rowHeight={reviewMap.rowHeight}
                  key={`model-app#${idx}`}
                  style={{
                    display: 'flex',
                    height: reviewMap.rowHeight ? reviewMap.rowHeight : '',
                  }}
                >
                  {vehicleModels.map(
                    (model, index) =>
                      model.show && (
                        <ModelApplicabilityReviewCell
                          key={`model-app-cell#${idx}#${index}`}
                          modelId={model.id}
                          map={reviewMap.modelApplicability}
                          isNew={reviewMap.isNew}
                          rowHeight={reviewMap.rowHeight ?? 0}
                          isDeleted={reviewMap.isDeleted}
                        />
                      )
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </>
        )}
      />
    </TwoTableWrapper>
  );
};

export default observer(FeaturesReviewTable);
