import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Accordion, Button, Modal, PublishModal } from 'vapi-ui-common';
import Header from '../../components/Header/Header';
import IconTextButton from '../../components/IconTextButton';
import Spinner from '../../components/Spinner';
import Tabs from '../../components/Tabs';
import useDebounce from '../../hooks/useDebounce';
import useStores from '../../hooks/useStores';
import { DphItem } from '../../models/dph.model';
import { Region } from '../../models/user.model';
import { handleErrorResponse } from '../../utils/errorHandlingUtils';
import { getSeries } from '../../webservices/adminApi';
import { addDph, deleteDph, publishDph, updateDph } from '../../webservices/dphApi';
import styles from './deliveryProcessingHandling.module.scss';
import DphAccordionItem from './dphAccordionItem';
import DphHeader from './dphHeader';
import DphSeries from './dphSeries';
import { toGqlBrand, toGqlRegion } from '../../utils/graphqlUtils';

const DeliveryProcessingHandling = observer(() => {
  const {
    userStore: {
      dphBrand,
      dphRegions,
      brand,
      modules: {
        Dph: { canEdit },
      },
    },
    dphStore,
  } = useStores();
  const { debounce } = useDebounce({ delay: 2000 });

  const [showPublishModal, setShowPublishModal] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isPublishDisabled, setIsPublishDisabled] = useState<boolean>(false);
  const [currentRegion, setCurrentRegion] = useState<Region>(dphRegions[0]);

  const loadData = useCallback(async () => {
    setIsLoaded(false);
    dphStore.reset();

    try {
      const [series] = await Promise.all([
        getSeries({ brand: toGqlBrand(dphBrand) }),
        dphStore.fetchData(dphBrand, currentRegion),
      ]);
      dphStore.series = series.series;

      const assignedSeries = dphStore.dphItems.reduce(
        (arr: string[], item: DphItem) => [...arr, ...item.series],
        []
      );
      setIsPublishDisabled(Object.keys(dphStore.series).length - assignedSeries.length > 0);
    } catch (e) {
      toast.error('Error loading DPH data.');
    }

    setIsLoaded(true);
  }, [currentRegion, dphBrand, dphStore]);

  useEffect(() => {
    if (dphBrand && currentRegion && brand) {
      loadData();
    }
  }, [dphBrand, currentRegion, dphStore, loadData, brand]);

  const addEmptyDphItem = () => {
    dphStore.addItem();
  };

  const checkIfCanEdit = () => {
    if (!canEdit) {
      toast.error('User doesnt have correct permissions.');
      throw new Error('');
    }
  };

  const publishDraft = async () => {
    setIsLoaded(false);
    try {
      checkIfCanEdit();
      await trackPromise(
        publishDph({
          brand: toGqlBrand(dphBrand),
          region: toGqlRegion(currentRegion),
        })
      );
      loadData();
      toast.success('Successfully published draft');
    } catch (e) {
      handleErrorResponse(e, 'Failed to publish draft');
    }
    setIsLoaded(true);
  };

  const updateDphItem = async (dphCategory: DphItem) => {
    try {
      checkIfCanEdit();
      debounce(async () => {
        await trackPromise(
          updateDph({
            brand: toGqlBrand(dphBrand),
            region: toGqlRegion(currentRegion),
            payload: {
              categoryId: dphCategory.categoryId,
              revId: dphCategory.revId,
              name: dphCategory.name,
              series: dphCategory.series,
              dph: dphCategory.dph,
              nextDph: dphCategory.nextDph,
              nextDphLiveDate: dphCategory.nextDphLiveDate,
            },
          })
        );
        loadData();
        toast.success('DPH category updated successfully');
      }, dphCategory.categoryId);
    } catch (e) {
      handleErrorResponse(e, 'DPH category failed to update');
    }
  };

  const addDphItem = async (dphCategory: DphItem) => {
    try {
      checkIfCanEdit();
      debounce(async () => {
        await trackPromise(
          addDph({
            brand: toGqlBrand(dphBrand),
            region: toGqlRegion(currentRegion),
            payload: {
              name: dphCategory.name,
              dph: dphCategory.dph,
              series: dphCategory.series,
              nextDph: dphCategory.nextDph,
              nextDphLiveDate: dphCategory.nextDphLiveDate,
            },
          })
        );
        loadData();
        toast.success('DPH category added successfully');
      }, dphCategory.categoryId);
    } catch (e) {
      handleErrorResponse(e, 'DPH category failed to add');
    }
  };

  const saveDphItem = (dphItem: DphItem) => {
    dphItem.categoryId ? updateDphItem(dphItem) : addDphItem(dphItem);
  };

  const deleteDphItem = async (dphCategory: DphItem) => {
    try {
      checkIfCanEdit();
      debounce(async () => {
        await trackPromise(
          deleteDph({
            brand: toGqlBrand(dphBrand),
            region: toGqlRegion(currentRegion),
            payload: {
              categoryId: dphCategory.categoryId,
              revId: dphCategory.revId,
            },
          })
        );
        loadData();
        toast.success('DPH category deleted successfully');
      }, dphCategory.categoryId);
    } catch (e) {
      handleErrorResponse(e, 'DPH category failed to delete');
    }
  };

  const onSave = (
    dphItem: DphItem,
    name: string,
    dph: number,
    series: string[],
    liveDphDate: string,
    nextDph: string,
    nextDphLiveDate: string
  ) => {
    dphItem.name = name;
    dphItem.dph = dph;
    dphItem.series = series;
    dphItem.liveDphDate = liveDphDate;
    dphItem.nextDph = nextDph;
    dphItem.nextDphLiveDate = nextDphLiveDate;
    saveDphItem(dphItem);
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="" moduleSubTitle="Delivery, Processing, Handling" />
      <section className={styles.body}>
        {dphRegions.length > 1 && (
          <div className={styles.tabContainer}>
            <Tabs
              selectedTab={currentRegion}
              setSelectedTab={(tab: string) => setCurrentRegion(tab as Region)}
              tabs={dphRegions}
            />
          </div>
        )}
        {canEdit && (
          <div className={styles.publishHeader}>
            <div>
              <IconTextButton icon="plus" text="Add DPH Category" onClick={addEmptyDphItem} />
            </div>
            <div className={styles.publishButton}>
              <Button
                variant="primary"
                disabled={isPublishDisabled}
                onClick={() => {
                  setShowPublishModal(true);
                }}
              >
                Publish
              </Button>
              <Modal open={showPublishModal} onClose={() => setShowPublishModal(false)}>
                <PublishModal close={() => setShowPublishModal(false)} publishData={publishDraft} />
              </Modal>
            </div>
          </div>
        )}
        <DphHeader />
        <Accordion className={styles.accordianSection}>
          {dphStore.dphItems.map((item, index) => (
            <DphAccordionItem
              key={index}
              categoryId={item.categoryId}
              name={item.name}
              seriesMap={item.series}
              seriesInfo={dphStore.series}
              liveDphDate={item.liveDphDate}
              dph={item.dph}
              nextDphLiveDate={item.nextDphLiveDate}
              nextDph={item.nextDph}
              className={styles.accordianItem}
              onEdit={(
                name: string,
                dph: number,
                series: string[],
                liveDphDate: string,
                nextDph: string,
                nextDphLiveDate: string
              ) => {
                onSave(item, name, dph, series, liveDphDate, nextDph, nextDphLiveDate);
              }}
              onDelete={() => deleteDphItem(item)}
              section={
                <DphSeries
                  list={item.series.map(seriesId => dphStore.series[seriesId]?.name ?? 'N/A')}
                />
              }
              canEdit={canEdit ?? false}
            />
          ))}
        </Accordion>
      </section>
    </>
  );
});

export default DeliveryProcessingHandling;
