import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  LD_STATUS,
  LimitedDataStatusHeader,
  OptionsMap,
} from '../../../../../../components/LimitedDataStatus';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';
import { UpdateAllModelStatusesRequest } from '../../../../../../models/vehicleModel.model';
import { handleErrorResponse } from '../../../../../../utils/errorHandlingUtils';
import { updateAllCompareFeatureStatuses } from '../../../../../../webservices/vehicleCompareFeaturesApi';

type Props = {
  readOnly?: boolean;
  setLastUpdated: React.Dispatch<React.SetStateAction<Date>>;
};

const CompareFeatureLimitedDataStatusHeader: React.FC<Props> = ({ readOnly, setLastUpdated }) => {
  const { teamStore, userStore } = useStores();

  const { team, seriesId, year: modelYear } = useParams<{
    team: VehicleTeam;
    seriesId: string;
    year: string;
  }>();

  const displayLimitedDataStatus =
    teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';

  const optionsMap = useMemo(
    (): OptionsMap => ({
      [LD_STATUS.READY_TO_PUBLISH]: {
        dropdownText: 'Set All to Ready to Publish',
        confirmModalHeaderText: 'Set all statuses to "Ready to Publish"',
        confirmModalBodyText:
          'Are you sure you want to set all fields in "In Progress" status to "Ready to Publish" on the Compare View tab?',
        confirmModalButtonText: 'Set to "Ready to Publish"',
      },

      [LD_STATUS.IN_PROGRESS]: {
        dropdownText: 'Set All to In Progress',
        confirmModalHeaderText: 'Set all statuses to "In Progress"',
        confirmModalBodyText:
          'Are you sure you want to set all fields in "Ready To Publish" status to "In Progress" on the Compare View tab?',
        confirmModalButtonText: 'Set to "In Progress"',
      },
    }),
    []
  );

  const updateAllFieldStatuses = useCallback(
    async (payload: UpdateAllModelStatusesRequest) => {
      try {
        const promises: Promise<any>[] = [];

        teamStore.team.languages
          .filter(lang => !!userStore.langPermissions[lang]?.canEdit)
          .forEach(lang => {
            promises.push(
              trackPromise(
                updateAllCompareFeatureStatuses(
                  userStore.brand,
                  team,
                  seriesId,
                  modelYear,
                  lang,
                  payload
                )
              )
            );
          });

        await Promise.all(promises);
        toast.success(`All Field Statuses - updated successfully`);
        setLastUpdated(new Date());
      } catch (e) {
        handleErrorResponse(e, 'Error in updating All Field statuses');
      }
    },
    [
      modelYear,
      seriesId,
      team,
      teamStore.team.languages,
      userStore.brand,
      userStore.langPermissions,
      setLastUpdated,
    ]
  );

  return (
    <LimitedDataStatusHeader
      hideLimitedDataStatus={!displayLimitedDataStatus}
      optionsMap={optionsMap}
      updateAllFieldStatuses={updateAllFieldStatuses}
      readonly={readOnly}
    />
  );
};

export default observer(CompareFeatureLimitedDataStatusHeader);
