import React, { useState, useCallback } from 'react';
import cx from 'clsx';
import styles from './ExpandList.module.scss';

export interface ExpandListProps {
  min: number;
  list: string[];
  cssClass?: string;
}

const ExpandList = ({ min, list, cssClass }: ExpandListProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const getText = useCallback(() => {
    if (!showAll && list.length > min) {
      return (
        <>
          {list.slice(0, min).join(',')}...
          <span className={styles.enxpandLink} onClick={() => setShowAll(!showAll)}>{`(${
            list.length - min
          })`}</span>
        </>
      );
    } else {
      return list.join(',');
    }
  }, [list, min, showAll]);

  return (
    <div className={cx(cssClass)} data-testid="expoand-list-comp">
      {getText()}
    </div>
  );
};

export default ExpandList;
