import cx from 'clsx';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ResizeDetector from 'react-resize-detector';
import useQuery from '../../hooks/useQuery';
import useStores from '../../hooks/useStores';
import { BRAND_TOYOTA } from '../../models/user.model';
import CollapsedView from './CollapsedView';
import ExpandedView from './ExpandedView';
import SideMenuFooter from './SideMenuFooter';
import styles from './sideMenu.module.scss';

export const testVDActive = (pathname: string, team: string) => {
  return pathname.includes('vehicleData') && pathname.includes(team);
};

export const hideRouteByBrand = (path: string, brand?: string) => {
  let hide: boolean;
  switch (path) {
    case '/reports':
      hide = brand !== BRAND_TOYOTA;
      break;
    default:
      hide = false;
      break;
  }
  return hide;
};

export type SideMenuProps = {
  children: ReactNode;
};

const SideMenu = ({ children }: SideMenuProps) => {
  const { resizeDetectorStore } = useStores();

  const ref = useRef<HTMLElement>(null);

  const query = useQuery();
  const isCollapsedRedirect = query.get('collapsed');
  const isCollapsed = localStorage.getItem('sidemenu.collapsed') === 'true';
  const [collapsed, setCollapsed] = useState<boolean>(
    isCollapsedRedirect === undefined ? isCollapsed : isCollapsedRedirect === 'true'
  );
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (!isCollapsedRedirect) return;
    localStorage.setItem('sidemenu.collapsed', 'collapsed');
  }, [isCollapsedRedirect]);

  const onResize = (width: number) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const toutId = setTimeout(() => {
      setTimeoutId(undefined);
      resizeDetectorStore.setValues(width, window.innerWidth);
    }, 100);

    setTimeoutId(toutId);
  };

  return (
    <section
      className={cx({
        [styles.navContainer]: true,
        [styles.collapsed]: collapsed,
      })}
      data-testid="side-menu-comp"
    >
      <nav
        className={cx({
          [styles.sideMenu]: true,
          [styles.collapsed]: collapsed,
        })}
      >
        {collapsed ? <CollapsedView /> : <ExpandedView />}
        <SideMenuFooter collapsed={collapsed} setCollapsed={setCollapsed} />
      </nav>
      <main ref={ref} className={styles.mainContent}>
        {children}
        <ResizeDetector
          handleWidth
          onResize={width => onResize(width)}
          targetDomEl={ref === null ? undefined : (ref.current as HTMLElement)}
        />
      </main>
    </section>
  );
};

export default SideMenu;
