import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import useStores from '../../../../../hooks/useStores';
import {
  UpdateAllModelStatusesRequest,
  UpdateAllModelStatusesResponse,
  UpdateModelStatusReponse,
  UpdateModelStatusRequest,
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../models/vehicleModel.model';
import { handleErrorResponse } from '../../../../../utils/errorHandlingUtils';
import {
  updateAllModelStatuses,
  updateModelStatuses,
} from '../../../../../webservices/vehicleModelsApi';
import { toGqlBrand, toGqlLanguage, toGqlTeam } from '../../../../../utils/graphqlUtils';

const useModelsFieldStatusApi = (
  seriesId: string,
  year: string,
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  const {
    userStore: { brand, langPermissions },
    teamStore: {
      team: { languages: teamLangs, name: teamName },
    },
    limitedDataStatusStore: { setDisabled: setDisabledLimitedDataStatusButton },
    vehicleModelsStore: { setModels },
  } = useStores();

  const updateFieldStatus = async (payload: UpdateModelStatusRequest) => {
    try {
      setDisabledLimitedDataStatusButton(true);
      const response = await Promise.all(
        teamLangs
          .filter(lang => !!langPermissions[lang]?.canEdit)
          .map(lang => {
            return trackPromise(
              updateModelStatuses({
                brand: toGqlBrand(brand),
                team: toGqlTeam(teamName),
                seriesId,
                modelYear: parseInt(year),
                language: toGqlLanguage(lang),
                payload: {
                  id: payload.id,
                  revId: payload.revId!,
                  grade: payload.grade,
                  drive: payload.drive,
                  engine: payload.engine,
                  horsepower: payload.horsepower,
                  transmission: payload.transmission,
                  bed: payload.bed,
                  cab: payload.cab,
                  seating: payload.seating,
                  trimTitle: payload.trimTitle,
                  description: payload.description,
                },
              })
            );
          })
      );

      const data: UpdateModelStatusReponse = response[0];
      const thisModel = models.find(item => item.id === data.id);
      thisModel?.updateDynamicProps({ fieldStatus: { ...data } });
      setModels([...models]);
      toast.success(`Model Field Status - updated successfully`);
      setDisabledLimitedDataStatusButton(false);
    } catch (e) {
      setDisabledLimitedDataStatusButton(false);
      handleErrorResponse(e, 'Error in updating model field status');
    }
  };

  const updateAllFieldStatuses = async (payload: UpdateAllModelStatusesRequest) => {
    try {
      const promises = teamLangs
        .filter(lang => !!langPermissions[lang]?.canEdit)
        .map(lang => {
          return trackPromise(
            updateAllModelStatuses({
              brand: toGqlBrand(brand),
              team: toGqlTeam(teamName),
              seriesId,
              modelYear: parseInt(year),
              language: toGqlLanguage(lang),
              status: payload.status,
            })
          );
        });

      const response = await Promise.all(promises);
      const data: UpdateAllModelStatusesResponse = response[0];
      models.forEach(item => {
        item.updateDynamicProps({ fieldStatus: data[item.id] });
      });
      setModels([...models]);
      toast.success(`All Model Field Statuses - updated successfully`);
    } catch (e) {
      handleErrorResponse(e, 'Error in updating model field status');
    }
  };

  return { updateFieldStatus, updateAllFieldStatuses };
};

export default useModelsFieldStatusApi;
