import { observable } from 'mobx';
import { IDValueType, KeyValueType } from '../../models/common.model';
import { RefItem } from '../../models/refItem.model';
import { Language } from '../../models/user.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../models/vehicleModel.model';
import { DataLang, VehicleModelsLangMap } from './vehicleModelsStore';

type CheckboxLanguages = {
  [k in Language]: {
    selected: boolean;
    description: string;
  };
};

export type FieldStatus = {
  bed: number;
  cab: number;
  description: number;
  drive: number;
  engine: number;
  grade: number;
  horsepower: number;
  id: string;
  seating: number;
  transmission: number;
  trimTitle: number;
  revId: string;
};

export type EditVehicleModelProps = {
  trimTitle: string;
  description: string;
  drive: string;
  engine: string;
  transmission: string;
  bed: string;
  cab: string;
  grade: RefItem;
  fuelType?: IDValueType;
  katashiki: string;
  code: string;
  horsepower: string;
  seating: string;
  acceptChanges: boolean;
  tdprCode?: string;
  isTDPR?: boolean;
  isUSVI?: boolean;
  fieldStatus?: FieldStatus;
};

type DataLangMapModelProps = {
  [key: string]: {
    [language in Language]: EditVehicleModelProps;
  };
};

const getCheckboxLanguages = () => {
  const languages: CheckboxLanguages = {
    EN: {
      description: 'English',
      selected: false,
    },
    ES: {
      description: 'Spanish',
      selected: false,
    },
  };

  return languages;
};

export default class ModelTabStore {
  @observable languages: CheckboxLanguages = getCheckboxLanguages();
  @observable teamLanguages: Language[] = [];
  @observable vehicleModels: VehicleModelsLangMap = {};
  @observable vehicleModelsEdit: DataLangMapModelProps = {};
  @observable readOnly: boolean = false;

  setTeamLanguages = (languages: Language[]) => {
    this.teamLanguages = languages;
    languages.forEach(language => {
      this.languages[language].selected = true;
    });
  };

  setLanguageSelected = (language: Language, selected: boolean) => {
    this.languages[language].selected = selected;
  };

  setVehicleModels = ({ vehicleModels }: DataLang) => {
    this.vehicleModels = vehicleModels;
    this.vehicleModelsEdit = {};
  };

  getLangVehicleModel = (
    model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>,
    language: Language
  ) => {
    const { id, uid } = model;
    const vmId = id ?? uid;
    return this.vehicleModels[vmId]?.[language] ?? model;
  };

  getLangVehicleModelById = (vmId: string, language: Language) => {
    return this.vehicleModels[vmId]?.[language];
  };

  getEditVehicleModel = (
    model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>,
    language?: Language
  ): any => {
    const { id, uid } = model;
    const vmId = id ?? uid;

    if (language) {
      return this.vehicleModelsEdit[vmId][language];
    }
    return this.vehicleModelsEdit[vmId];
  };

  setReadOnly = (readOnly: boolean = false) => {
    this.readOnly = readOnly;
  };

  setGrade = ({ vmId, language, grade }: { vmId: string; language: Language; grade: RefItem }) => {
    this.vehicleModelsEdit[vmId][language].grade = grade;
  };

  getGrade = (vmId: string, language: Language): RefItem => {
    return this.vehicleModelsEdit[vmId][language].grade;
  };

  getFuelType = (vmId: string, language: Language): IDValueType | undefined => {
    return this.vehicleModelsEdit[vmId][language].fuelType;
  };

  getTrimTitle = (vmId: string, language: Language): string => {
    return this.vehicleModelsEdit[vmId][language].trimTitle ?? '';
  };

  setTrimTitle = (vmId: string, language: Language, trimTitle: string) => {
    this.vehicleModelsEdit[vmId][language].trimTitle = trimTitle;
  };

  getDescription = (vmId: string, language: Language): string => {
    return this.vehicleModelsEdit[vmId][language].description ?? '';
  };

  setDescription = (vmId: string, language: Language, description: string) => {
    this.vehicleModelsEdit[vmId][language].description = description;
  };

  getDrive = (vmId: string, language: Language): string => {
    return this.vehicleModelsEdit[vmId][language].drive ?? '';
  };

  setDrive = (vmId: string, language: Language, drive: string) => {
    this.vehicleModelsEdit[vmId][language].drive = drive;
  };

  getEngine = (vmId: string, language: Language): string => {
    return this.vehicleModelsEdit[vmId][language].engine ?? '';
  };

  setEngine = (vmId: string, language: Language, engine: string) => {
    this.vehicleModelsEdit[vmId][language].engine = engine;
  };

  getTransmission = (vmId: string, language: Language): string => {
    return this.vehicleModelsEdit[vmId][language].transmission ?? '';
  };

  setTransmission = (vmId: string, language: Language, transmission: string) => {
    this.vehicleModelsEdit[vmId][language].transmission = transmission;
  };

  getBed = (vmId: string, language: Language): string => {
    return this.vehicleModelsEdit[vmId][language].bed ?? '';
  };

  setBed = (vmId: string, language: Language, bed: string) => {
    this.vehicleModelsEdit[vmId][language].bed = bed;
  };

  getCab = (vmId: string, language: Language): string => {
    return this.vehicleModelsEdit[vmId][language].cab ?? '';
  };

  setCab = (vmId: string, language: Language, cab: string) => {
    this.vehicleModelsEdit[vmId][language].cab = cab;
  };

  resetEditVehicleModel = (vmId: string) => {
    delete this.vehicleModelsEdit[vmId];
  };

  onEdit = (
    model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>,
    modelMap: KeyValueType<VehicleModelItem<VehicleModelToyota | VehicleModelLexus>>
  ) => {
    const { id, uid } = model;
    const vmId = id ?? uid;

    if (this.vehicleModelsEdit[vmId]) return;
    this.teamLanguages.forEach(language => {
      let vModel = modelMap[language];
      if (this.vehicleModels[vmId]?.[language]) {
        vModel = this.vehicleModels[vmId]?.[language];
      }
      this.vehicleModelsEdit[vmId] = {
        ...this.vehicleModelsEdit[vmId],
        [language]: vModel.getModelPropValues(),
      };
    });
  };
}
