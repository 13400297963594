import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'vapi-ui-common';
import Header from '../../../../components/Header';
import HeaderNotificationButton from '../../../../components/Header/HeaderNotificationButton';
import HeaderNotificationMenu from '../../../../components/Header/HeaderNotificationMenu';
import SecondaryHeader from '../../../../components/SecondaryHeader';
import Spinner from '../../../../components/Spinner';
import { TabPanel } from '../../../../components/Tabs/Tabs';
import Wayfinding from '../../../../components/Wayfinding';
import useQuery from '../../../../hooks/useQuery';
import useStores from '../../../../hooks/useStores';
import { UserPermissions } from '../../../../models/user.model';
import {
  AdminVehicleData,
  VDTab,
  VehicleDataVersionInfo,
  VehicleTeam,
} from '../../../../models/vehicleData.model';
import PendingReview from '../../../../routes/vehicleData/components/PendingReview';
import FeaturesController from '../../../../routes/vehicleData/tabModules/features/FeaturesController';
import OptionsController from '../../../../routes/vehicleData/tabModules/options/OptionsController';
import { getVersionInfoFromParams } from '../../../../utils/vehicleDataUtils';
import { getSeries } from '../../../../webservices/adminApi';
import { getVehicleData } from '../../../../webservices/vehicleAdminApi';
import BnPController from '../../tabModules/bp';
import ColorsController from '../../tabModules/colors';
import CompareFeaturesController from '../../tabModules/compareFeatures';
import ModelsController from '../../tabModules/models/ModelsController';
import OptionsLexusController from '../../tabModules/optionsLexus/OptionsLexusController';
import SeriesSettingsController from '../../tabModules/seriesSettings/SeriesSettingsController';
import SpecsController from '../../tabModules/specs';
import { toGqlBrand } from '../../../../utils/graphqlUtils';

const Published = () => {
  const {
    publishedStore,
    vehicleModelsStore,
    vehicleSeriesInfoStore,
    teamStore,
    userStore,
  } = useStores();

  const history = useHistory();
  const location = useLocation();
  const { brand, objectId } = userStore;
  const [isLoaded, setIsLoaded] = useState(false);
  const [oldTeam, setOldTeam] = useState('');
  const [teamParam, setTeamParam] = useState('' as VehicleTeam);
  const [adminVehicleData, setAdminVehicleData] = useState<AdminVehicleData>(
    {} as AdminVehicleData
  );
  const [vehicleDataVersionInfo, setVehicleDataVersionInfo] = useState<VehicleDataVersionInfo>(
    {} as VehicleDataVersionInfo
  );
  const [permissions, setPermissions] = useState<UserPermissions>({} as UserPermissions);
  const { team, seriesId, year, versionInfo } = useParams<{
    team: string;
    seriesId: string;
    year: string;
    versionInfo: string;
  }>();
  const selectTab = VDTab.SERIES_SETTINGS;
  const query = useQuery();
  const tab = query.get('tab') || selectTab;

  const changeLogLink = `/vehicleData/changeLog/${team}/${seriesId}/${year}/${versionInfo}?tab=${encodeURIComponent(
    publishedStore.selectedTab
  )}&return=published`;

  // load route specific data
  useEffect(() => {
    teamStore.setTeam(team as VehicleTeam, brand, userStore.langPermissions);
    userStore.setTeamModule(team as VehicleTeam);
    setTeamParam(teamStore.team.param);
    const vdVersionInfo = getVersionInfoFromParams(versionInfo);
    setVehicleDataVersionInfo(vdVersionInfo);

    (async () => {
      try {
        const [seriesResponse, vehicleDataResponse] = await Promise.all([
          getSeries({ brand: toGqlBrand(brand) }),
          getVehicleData(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            vdVersionInfo[teamStore.team.defaultLanguage]?.toString(),
            teamStore.team.defaultLanguage
          ),
          vehicleModelsStore.fetchData({
            brand,
            team: teamStore.team.param,
            series: seriesId,
            year,
            versionInfo: vdVersionInfo,
            languagePermissions: teamStore.team.langPermissions,
            defaultLanguage: teamStore.team.defaultLanguage,
          }),
        ]);

        const series = seriesResponse.series[seriesId];
        if (series) {
          vehicleSeriesInfoStore.seriesId = seriesId;
          vehicleSeriesInfoStore.seriesName = series.name;
          vehicleSeriesInfoStore.seriesGroup = series.group;
          vehicleSeriesInfoStore.year = year;

          // set the permissions by group
          setPermissions(userStore.teamModule.series[vehicleSeriesInfoStore.seriesGroup]);
        }

        setAdminVehicleData(vehicleDataResponse.data);
      } catch (e) {
        console.log(e);
        toast.error('Failed loading published data');
      }
      setIsLoaded(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vehicleSeriesInfoStore,
    vehicleModelsStore,
    brand,
    objectId,
    seriesId,
    year,
    publishedStore,
    team,
    teamStore,
    userStore,
    versionInfo,
  ]);

  // if user navigates to/from different draftPage (e.g PDT Draft to ADT Draft)
  // switch selectedTab back to Features. Otherwise tab selection in PDT Draft
  // will impact on ADT draft, and etc...
  useEffect(() => {
    if (oldTeam !== team) {
      publishedStore.selectedTab = VDTab.SERIES_SETTINGS;
      setOldTeam(team);
    }
  }, [oldTeam, team, publishedStore]);

  useEffect(() => {
    if (tab) {
      publishedStore.selectedTab = decodeURIComponent(tab);
    }
  }, [tab, publishedStore]);
  const moduleTitle = teamStore.team.pageTitle;
  const moduleSubTitle =
    !adminVehicleData.lastPublishedVersion && teamStore.team.allowSubmit ? 'Approved' : 'Published';
  const wayfindingTo = `/vehicleData/${teamStore.team.name}`;
  const reviewHref = `/vehicleData/review/${teamStore.team.name}/${vehicleSeriesInfoStore.seriesId}/${vehicleSeriesInfoStore.year}/${adminVehicleData.version}`;
  const tabs = teamStore.team.tabs;

  return isLoaded ? (
    <>
      <Header
        moduleTitle={moduleTitle}
        moduleSubTitle={moduleSubTitle}
        versionNumber={adminVehicleData.version}
      >
        {permissions.canReview &&
          (adminVehicleData.isPendingCLReview || adminVehicleData.isPendingVDReview) && (
            <HeaderNotificationButton>
              <HeaderNotificationMenu>
                <PendingReview
                  adminVehicleData={adminVehicleData}
                  reviewHref={reviewHref}
                  reviewTeamParam={teamStore.team.reviewTeamParam}
                  reviewTeamTitle={teamStore.team.reviewTeamTitle}
                />
              </HeaderNotificationMenu>
            </HeaderNotificationButton>
          )}
      </Header>
      <Wayfinding
        year={year}
        seriesName={vehicleSeriesInfoStore.seriesName}
        to={wayfindingTo}
        showModels
      />
      <SecondaryHeader
        tabs={tabs.map(tab => tab.name)}
        selectedTab={publishedStore.selectedTab}
        setSelectedTab={tab => {
          publishedStore.selectedTab = tab;
          const encodedTab = encodeURIComponent(tab);
          const url = `${location.pathname}?team=${team}&tab=${encodedTab}`;
          history.push(url);
        }}
        renderButtons={() => (
          <>
            {publishedStore.selectedTab !== VDTab.SERIES_SETTINGS && (
              <NavLink to={changeLogLink}>
                <Button variant="transparent">Change Log</Button>
              </NavLink>
            )}
          </>
        )}
      />
      <>
        <TabPanel
          tab={VDTab.SERIES_SETTINGS}
          selected={publishedStore.selectedTab === VDTab.SERIES_SETTINGS}
        >
          <SeriesSettingsController
            readOnly
            team={teamStore.team.param}
            seriesId={seriesId}
            year={year}
            vehicleModels={vehicleModelsStore.vehicleModels}
            changeLogLink={changeLogLink}
            versionInfo={vehicleDataVersionInfo}
            isPublished
          />
        </TabPanel>
        <TabPanel tab={VDTab.MODELS} selected={publishedStore.selectedTab === VDTab.MODELS}>
          <ModelsController
            isPublished
            readOnly
            team={teamParam}
            seriesId={seriesId}
            year={year}
            vehicleModels={vehicleModelsStore.vehicleModels}
            changeLogLink={changeLogLink}
            versionInfo={vehicleDataVersionInfo}
          />
        </TabPanel>
        <TabPanel tab={VDTab.FEATURES} selected={publishedStore.selectedTab === VDTab.FEATURES}>
          <FeaturesController
            isPublished
            readOnly
            team={teamParam}
            seriesId={seriesId}
            year={year}
            versionInfo={vehicleDataVersionInfo}
            vehicleModels={vehicleModelsStore.vehicleModels}
          />
        </TabPanel>
        <TabPanel tab={VDTab.OPTIONS} selected={publishedStore.selectedTab === VDTab.OPTIONS}>
          {teamStore.team.useOptionsPackages ? (
            <OptionsLexusController
              isPublished
              readOnly
              team={teamStore.team.param}
              seriesId={seriesId}
              year={year}
              versionInfo={vehicleDataVersionInfo}
              vehicleModels={vehicleModelsStore.vehicleModels}
              changeLogLink={changeLogLink}
            />
          ) : (
            <OptionsController
              isPublished
              readOnly
              team={teamStore.team.param}
              seriesId={seriesId}
              year={year}
              versionInfo={vehicleDataVersionInfo}
              vehicleModels={vehicleModelsStore.vehicleModels}
              changeLogLink={changeLogLink}
            />
          )}
        </TabPanel>
        <TabPanel tab={VDTab.SPECS} selected={publishedStore.selectedTab === VDTab.SPECS}>
          <SpecsController
            isPublished
            readOnly
            team={teamParam}
            seriesId={seriesId}
            year={year}
            versionInfo={vehicleDataVersionInfo}
            vehicleModels={vehicleModelsStore.vehicleModels}
          />
        </TabPanel>
        {teamStore.team.allowBnP && (
          <TabPanel tab={VDTab.BNP} selected={publishedStore.selectedTab === VDTab.BNP}>
            <BnPController
              isPublished
              readOnly
              team={teamStore.team.param}
              seriesId={seriesId}
              year={year}
              versionInfo={vehicleDataVersionInfo}
              vehicleModels={vehicleModelsStore.vehicleModels}
            />
          </TabPanel>
        )}
        <TabPanel tab={VDTab.COLORS} selected={publishedStore.selectedTab === VDTab.COLORS}>
          <ColorsController
            isPublished
            readOnly
            team={teamParam}
            seriesId={seriesId}
            year={year}
            versionInfo={vehicleDataVersionInfo}
            vehicleModels={vehicleModelsStore.vehicleModels}
          />
        </TabPanel>
        {teamStore.team.allowCompareFeatures && (
          <TabPanel
            tab={VDTab.COMPARE_FEATURES}
            selected={publishedStore.selectedTab === VDTab.COMPARE_FEATURES}
          >
            <CompareFeaturesController
              readOnly
              team={teamStore.team.param}
              seriesId={seriesId}
              year={year}
              grades={vehicleModelsStore.sortedGrades()}
              vehicleModels={vehicleModelsStore.vehicleModels}
              changeLogLink={changeLogLink}
              versionInfo={vehicleDataVersionInfo}
              isPublished
            />
          </TabPanel>
        )}
      </>
    </>
  ) : (
    <Spinner />
  );
};

export default observer(Published);
