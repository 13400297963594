import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import {
  AccordionHeader,
  AccordionSection,
  Button,
  ConfirmModal,
  Dropdown,
  DropdownButton,
  Input,
} from 'vapi-ui-common';
import DropdownAssign from '../../../components/DropdownAssign';
import DropdownDatepicker from '../../../components/DropdownDatepicker';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { Series } from '../../../models/vehicleData.model';
import styles from './dphAccordionItem.module.scss';

export interface DphAccordionItemProps extends HTMLAttributes<HTMLElement> {
  categoryId: string;
  name: string;
  seriesMap: string[];
  seriesInfo: Series;
  liveDphDate: string;
  dph: number;
  nextDphLiveDate: string;
  nextDph: string;
  section: ReactNode;
  onEdit?: (
    name: string,
    dph: number,
    series: string[],
    liveDphDate: string,
    nextDph: string,
    nextDphLiveDate: string
  ) => void;
  onDelete?: () => void;
  canEdit: boolean;
}

const getDate = (date: string | undefined) => {
  return date ? new Date(date) : undefined;
};

const getValueOrBackup = (value: string | number, prefix: string = '', backup: string = '--') => {
  if (!value) {
    return backup;
  }
  return `${prefix}${value}`;
};

const DphAccordionItem = ({
  categoryId,
  name,
  seriesMap,
  seriesInfo,
  liveDphDate,
  dph,
  nextDphLiveDate,
  nextDph,
  section,
  className,
  onEdit,
  onDelete,
  canEdit,
}: DphAccordionItemProps) => {
  const [expand, setExpand] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [del, setDelete] = useState<boolean>(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [categoryName, setCategoryName] = useState<string>(name);
  const [series, setSeries] = useState<string[]>(seriesMap);
  const [liveDate, setLiveDate] = useState<string>(liveDphDate);
  const [liveValue, setLiveValue] = useState<string>(dph ? dph.toString() : '');
  const [nextLiveDate, setNextLiveDate] = useState<string>(nextDphLiveDate);
  const [nextLiveValue, setNextLiveValue] = useState<string>(nextDph);
  const [saveMessage, setSaveMessage] = useState<string>('');

  const {
    ref: assignRef,
    isComponentVisible: assignIsComponentVisible,
    setIsComponentVisible: assignSetIsComponentVisible,
  } = useComponentVisible(false);

  const {
    ref: dateRef,
    isComponentVisible: dateIsComponentVisible,
    setIsComponentVisible: dateSetIsComponentVisible,
  } = useComponentVisible(false);

  const {
    ref: nextDateRef,
    isComponentVisible: nextDateIsComponentVisible,
    setIsComponentVisible: nextDateSetIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    setCategoryName(name);
    setSeries(seriesMap);
    setLiveDate(liveDphDate);
    setLiveValue(dph ? dph.toString() : '');
    setNextLiveDate(nextDphLiveDate);
    setNextLiveValue(nextDph);
  }, [name, seriesMap, liveDphDate, dph, nextDphLiveDate, nextDph, categoryId]);

  const handleOnToggle = (value: boolean) => {
    setExpand(value);
  };

  const handleDelete = () => {
    setDelete(!del);
  };

  const handleSave = () => {
    const catName = name || 'N/A';
    let message = '';

    if (categoryId) {
      if (
        liveValue !== dph.toString() &&
        (nextLiveValue !== nextDph || nextLiveDate !== nextDphLiveDate)
      ) {
        message = `Are you sure you want to overwrite the current DPH value for ${catName} to $${liveValue} AND  set the future value for ${catName} to $${nextLiveValue} on ${nextLiveDate}`;
      } else if (nextLiveValue !== nextDph || nextLiveDate !== nextDphLiveDate) {
        message = `Are you sure you want to set the future value for ${catName} to $${nextLiveValue} on ${nextLiveDate}`;
      } else if (liveValue !== dph.toString()) {
        message = `Are you sure you want to overwrite the current DPH value for ${catName} to $${liveValue}`;
      }
    }

    if (message) {
      setSaveMessage(message);
      setIsSaveModalOpen(true);
    } else if (onEdit) {
      onEdit(categoryName, Number(liveValue), series, liveDate, nextLiveValue, nextLiveDate);
      setEdit(!edit);
    }
  };

  const getFormattedDate = (date: Date) => {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const handleDphChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setStateFunc: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const regex = /^[0-9\b]+$/;
    let thisValue: string = e.target.value;

    if (thisValue === '' || regex.test(thisValue)) {
      if (thisValue.length > 1 && thisValue[0] === '0') {
        thisValue = thisValue.slice(1);
      }
      setStateFunc(thisValue);
    }
  };

  const handleCancellation = () => {
    setCategoryName(name);
    setSeries(seriesMap);
    setLiveDate(liveDphDate);
    setLiveValue(dph ? dph.toString() : '');
    setNextLiveDate(nextDphLiveDate);
    setNextLiveValue(nextDph);
    setEdit(!edit);
  };

  return (
    <div
      className={cx(className, styles.accordionItem, {
        [styles.shadow]: !expand,
        [styles.rowOnEdit]: edit,
      })}
    >
      <AccordionHeader
        expand={expand}
        className={cx({
          [styles.accordionContainer]: !edit,
          [styles.accordionContainerEdit]: edit,
        })}
        onToggle={handleOnToggle}
      >
        <div className={styles.actionButtons}>
          {edit ? (
            <div className={styles.inputRow}>
              <Input
                placeholder="Category Name"
                className={styles.inputBox1}
                name="categoryName"
                defaultValue={categoryName}
                onBlur={event => {
                  setCategoryName(event.currentTarget.value);
                }}
              />
              <Dropdown className={styles.dropdownContainer}>
                <DropdownButton
                  placeholder="Select Series"
                  className={cx(styles.tableDropdown1)}
                  onClick={() => assignSetIsComponentVisible(true)}
                >
                  {`(${series.length}) Series`}
                </DropdownButton>
                <DropdownAssign
                  ref={assignRef}
                  open={assignIsComponentVisible}
                  name={name}
                  seriesMap={series}
                  seriesInfo={seriesInfo}
                  updateSeries={(list: string[]) => setSeries(list)}
                  onClose={() => assignSetIsComponentVisible(false)}
                />
              </Dropdown>
              <Dropdown className={styles.dropdownContainer}>
                <DropdownButton
                  placeholder="Select Live Date"
                  className={cx(styles.tableDropdown2)}
                  onClick={() => dateSetIsComponentVisible(true)}
                  disabled={true}
                >
                  {getValueOrBackup(liveDate)}
                </DropdownButton>
                <DropdownDatepicker
                  ref={dateRef}
                  open={dateIsComponentVisible}
                  onClose={() => dateSetIsComponentVisible(false)}
                  updateDate={(date: Date) => setLiveDate(getFormattedDate(date))}
                  currentDate={getDate(liveDate)}
                />
              </Dropdown>
              <Input
                placeholder="DPH Value"
                className={styles.inputBox2}
                name="liveValue"
                value={liveValue}
                type="number"
                onChange={e => {
                  handleDphChange(e, setLiveValue);
                }}
                onBlur={e => {
                  handleDphChange(e, setLiveValue);
                }}
              />
              <Dropdown className={styles.dropdownContainer}>
                <DropdownButton
                  placeholder="Select Next Live Date"
                  className={cx(styles.tableDropdown3)}
                  onClick={() => nextDateSetIsComponentVisible(true)}
                >
                  {nextLiveDate}
                </DropdownButton>
                <DropdownDatepicker
                  ref={nextDateRef}
                  open={nextDateIsComponentVisible}
                  onClose={() => nextDateSetIsComponentVisible(false)}
                  updateDate={(date: Date) => {
                    setNextLiveDate(getFormattedDate(date));
                  }}
                  currentDate={getDate(nextLiveDate)}
                  onClear={() => setNextLiveDate('')}
                />
              </Dropdown>
              <Input
                className={styles.inputBox3}
                name="nextLiveValue"
                value={nextLiveValue}
                type="number"
                onChange={e => {
                  handleDphChange(e, setNextLiveValue);
                }}
                onBlur={e => {
                  handleDphChange(e, setNextLiveValue);
                }}
              />
            </div>
          ) : (
            <div className={styles.header}>
              <span className={styles.title1}>{getValueOrBackup(name)}</span>
              <span className={styles.title1}>{`(${seriesMap.length}) Series`}</span>
              <span className={styles.title2}>{getValueOrBackup(liveDphDate)}</span>
              <span className={styles.title2}>{`$${dph}`}</span>
              <span className={styles.title3}>
                {nextDphLiveDate && nextDph ? `${nextDphLiveDate} $${nextDph}` : '--'}
              </span>
            </div>
          )}
          <span className={styles.actionItems}>
            {canEdit && onDelete && !edit && (
              <FontAwesomeIcon
                className={cx(styles.trash, {
                  [styles.disabledTrash]: !categoryId || series.length,
                })}
                icon={faTrash}
                onClick={handleDelete}
              />
            )}
            {canEdit && onEdit && !edit && (
              <FontAwesomeIcon
                className={styles.edit}
                icon={faPen}
                onClick={() => setEdit(!edit)}
              />
            )}
            {edit && (
              <div className={styles.cta}>
                <Button
                  className={styles.editButton}
                  variant="primary"
                  onClick={handleCancellation}
                >
                  Cancel
                </Button>
                <Button className={styles.editButton} variant="primary" onClick={handleSave}>
                  Save
                </Button>
              </div>
            )}
          </span>
        </div>
      </AccordionHeader>
      <AccordionSection expand={expand}>{section}</AccordionSection>
      <ConfirmModal
        headerText="Delete DPH Category"
        bodyText={`Are you sure you want to delete DPH category '${name || 'N/A'}'?`}
        confirmButtonText="Delete"
        open={del}
        onClose={() => setDelete(false)}
        onConfirm={() => onDelete && onDelete()}
      />
      <ConfirmModal
        headerText="Save DPH Category"
        bodyText={saveMessage}
        confirmButtonText="Save"
        open={isSaveModalOpen}
        onClose={() => setIsSaveModalOpen(false)}
        onConfirm={() => {
          if (onEdit) {
            onEdit(categoryName, Number(liveValue), series, liveDate, nextLiveValue, nextLiveDate);
            setEdit(!edit);
          }
        }}
      />
    </div>
  );
};

export default DphAccordionItem;
