import { observer } from 'mobx-react-lite';
import React from 'react';
import { BnPReviewRowContext } from '.';
import { BnPChangeTypeMap } from '../../../../../../models/buildAndPrice.model';
import { KeyValueType } from '../../../../../../models/common.model';
import BnPReviewRow from './BnPReviewRow';

interface BnPReviewCategoryProps {
  categoryMap: KeyValueType<BnPChangeTypeMap>;
}

const BnPReviewCategory = ({ categoryMap }: BnPReviewCategoryProps) => {
  const categories = Object.values(categoryMap);

  const rowSpan = categories.reduce((rSpan, category) => {
    let newSpan = rSpan + Object.values(category.splits ?? {}).length;
    if (
      category.description.hasChanged ||
      category.title.hasChanged ||
      category.copy.hasChanged ||
      category.hasApplicabilityChange
    ) {
      newSpan += 1;
    }
    return newSpan;
  }, 1);

  const getRows = (category: BnPChangeTypeMap, mainIndex: number) => {
    const rows: JSX.Element[] = [];
    let showName = true;
    if (
      category.description.hasChanged ||
      category.title.hasChanged ||
      category.copy.hasChanged ||
      category.hasApplicabilityChange
    ) {
      showName = false;
      rows.push(<BnPReviewRow key={`${category.id}-BnPReviewRow`} />);
    }
    rows.push(
      ...Object.values(category.splits).map((split, index) => (
        <BnPReviewRow
          key={`${category.id}-${split.id}-BnPReviewRowSplit`}
          split={split}
          showName={mainIndex === 0 && index === 0 && showName}
        />
      ))
    );
    return rows;
  };

  return (
    <>
      {categories.map((category, index) => (
        <BnPReviewRowContext.Provider
          key={`${category.id}-BnPReviewRowContext`}
          value={{
            category,
            rowSpan,
            index,
          }}
        >
          {getRows(category, index)}
        </BnPReviewRowContext.Provider>
      ))}
    </>
  );
};

export default observer(BnPReviewCategory);
