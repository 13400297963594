import Checkbox from '../../../../components/Checkbox';
import Spinner from '../../../../components/Spinner';
import {
  DescriptionText,
  HeaderRow,
  Table,
  TableCell,
  TableRow,
} from '../../../../components/Table';
import useStores from '../../../../hooks/useStores';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ActionBar,
  ActionBarSection,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContainer,
  SearchInput,
} from 'vapi-ui-common';
import RichTextCell from '../tableCells/RichTextCell';
import { StandardSpecCheckItem, ModalState } from './addStandardSpec.models';
import { addSystemSpec, getSystemSpecs } from '../../../../webservices/adminApi';
import { Language, Status } from '../../../../gql/generated';
import { toGqlBrand, toGqlFilter, toGqlTeam } from '../../../../utils/graphqlUtils';
import styles from './addStandardSpecModal.module.scss';

interface AddStandardSpecModalProps {
  tabName: string;
  onClose: () => void;
  onAddCallback: () => void;
  buttonName?: string;
}

const AddStandardSpecModal = ({
  tabName,
  onClose,
  onAddCallback,
  buttonName,
}: AddStandardSpecModalProps) => {
  const {
    userStore: { brand },
    teamStore: {
      team: { name },
    },
    vehicleSeriesInfoStore: { seriesId, year },
  } = useStores();

  const [state] = useState(() => observable(new ModalState()));
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await getSystemSpecs({
          brand: toGqlBrand(brand),
          team: toGqlTeam(name),
          seriesId,
          modelYear: +year,
          language: Language.En,
          filter: toGqlFilter(Status.Draft),
        });
        state.items = Object.values(response.systemSpecs).map(item => {
          const obj = new StandardSpecCheckItem(item);
          obj.isAdded = item.isAdded;
          if (item.isAdded) {
            obj.checked = true;
            obj.disabled = true;
          }

          return obj;
        });
      } catch (e) {
        toast.error('Error fetching Standard Specs data');
      }
      setIsLoaded(true);
    })();
  }, [brand, name, seriesId, year, state]);

  const handleOnSelect = (item: StandardSpecCheckItem) => {
    item.checked = !item.checked;
    state.items = [...state.items];
  };

  const addStandardSpecs = () => {
    const specIdsToAdd: string[] = state.items.reduce(
      (list: string[], item: StandardSpecCheckItem) => {
        if (item.checked && !item.isAdded) {
          return [...list, item.id];
        } else {
          return list;
        }
      },
      []
    );

    if (specIdsToAdd.length) {
      (async () => {
        try {
          await addSystemSpec({
            brand: toGqlBrand(brand),
            team: toGqlTeam(name),
            seriesId,
            modelYear: +year,
            language: Language.En,
            systemSpec: specIdsToAdd,
          });
        } catch (e) {
          toast.error('Error adding Standard Specs data');
        }
        setIsLoaded(true);
        onAddCallback();
      })();
    }

    onClose();
  };

  return (
    <>
      <ModalHeader onClose={onClose}>
        Add {tabName} {'>'} Add Standard Spec
      </ModalHeader>
      {!isLoaded ? (
        <Spinner short />
      ) : (
        <>
          <ModalBody>
            <ActionBar>
              <ActionBarSection>
                <SearchInput
                  value={state.searchText}
                  onSearch={text => (state.searchText = text)}
                />
              </ActionBarSection>
            </ActionBar>
            <ScrollableContainer>
              <Table fullWidth>
                <thead>
                  <HeaderRow>
                    <TableCell className={styles.sticky}></TableCell>
                    <TableCell className={styles.sticky}>Category</TableCell>
                    <TableCell className={styles.sticky}>Spec Type</TableCell>
                    <TableCell className={styles.sticky}>Description</TableCell>
                  </HeaderRow>
                </thead>
                <tbody>
                  {state.filteredItems.map(item => (
                    <TableRow zebra key={item.id}>
                      <TableCell center>
                        <Checkbox
                          id={`addCl-chbox-${item.id}`}
                          defaultChecked={item.checked}
                          onChange={() => handleOnSelect(item)}
                          disabled={item.disabled}
                        />
                      </TableCell>
                      <TableCell>
                        <DescriptionText size="sm">{item.category}</DescriptionText>
                      </TableCell>
                      <TableCell>
                        <DescriptionText size="sm">{item.specType}</DescriptionText>
                      </TableCell>
                      <RichTextCell colType="description" value={item.description} disabled />
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </ScrollableContainer>
          </ModalBody>
          <ModalFooter>
            <Button variant="transparent" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={addStandardSpecs} disabled={!state.selectCount}>
              Add &nbsp;
              {buttonName && <>{buttonName}</>}
              {!buttonName && <>{tabName}(s)</>}
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default observer(AddStandardSpecModal);
