import { action, observable } from 'mobx';
import BaseReviewStore from './baseReviewStore';

class BaseColorsReviewStore extends BaseReviewStore {
  @observable isExtraCostColorFilter = false;

  @action setSort = (sortField: string) => {
    this.reverse = this.sortField !== sortField ? false : !this.reverse;
    this.sortField = sortField;
  };

  @action reset() {
    super.reset();
    this.isExtraCostColorFilter = false;
  }
}

export default BaseColorsReviewStore;
