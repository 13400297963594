import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';
import styles from './resetModal.module.scss';

type Props = {
  changeLogLocation: string;
  resetDraft: () => void;
  updateCount: number;
  pageName: string;
  close?: () => void; // is required
};
const ResetModal: React.FC<Props> = ({
  pageName,
  resetDraft,
  updateCount,
  changeLogLocation,
  close = () => {},
}) => (
  <>
    <ModalHeader onClose={close}>Reset Draft</ModalHeader>
    <ModalBody>
      Are you sure you want to reset this draft? If you reset the draft, you will lose{' '}
      <span className={styles.bold}>{updateCount}</span> changes made to{' '}
      <span className={styles.bold}>{pageName}</span>.
    </ModalBody>
    <ModalFooter className={styles.footer}>
      <Button
        variant="transparent"
        onClick={() => {
          close();
        }}
      >
        Cancel
      </Button>
      <div className={styles.ctaContainer}>
        <Button
          variant="primary"
          onClick={() => {
            resetDraft();
            close();
          }}
        >
          Reset
        </Button>
        <NavLink to={changeLogLocation}>
          <Button variant="full" onClick={() => close()}>
            View Changes
          </Button>
        </NavLink>
      </div>
    </ModalFooter>
  </>
);
export default ResetModal;
