import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { ActionBar, DateComponent } from 'vapi-ui-common';
import IconTextButton from '../../../../components/IconTextButton';
import Spinner from '../../../../components/Spinner';
import { Table, TableRow } from '../../../../components/Table';
import useSeriesSettings from '../../../../hooks/useSeriesSettings';
import useStores from '../../../../hooks/useStores';
import { ChangeLogModelItem, ChangeLogTypes } from '../../../../models/changeLog.model';
import { RefItem } from '../../../../models/refItem.model';
import { Brand } from '../../../../models/user.model';
import {
  SeriesCategories,
  SortModel,
  VehicleDataVersionInfo,
} from '../../../../models/vehicleData.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../models/vehicleModel.model';
import {
  changeLogVehicleModelGradesMapper,
  changeLogVehicleModelMapper,
} from '../../../../utils/changeLogUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { revertChangeLog } from '../../../../webservices/vehicleModelsApi';
import useChangeLogChanges from './hooks/useChangeLogChanges';
import styles from './modelsModal.module.scss';
import { getVehicleModelChangeLogs } from './utils/utils';
import { toGqlBrand, toGqlTeam, toGqlLanguage } from '../../../../utils/graphqlUtils';

interface ModelsModalProps {
  onUpdateModel?: (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => void;
  onAddModel?: (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => void;
  onDeleteModel?: (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => void;
  onSortModels?: (sortedModels: SortModel[]) => void;
  onAddGrade?: (grade: string) => void;
  onUpdateGrade?: (gradeId: string, gradeValue: string) => void;
  onClose: () => void;
  readOnly?: boolean;
}

export interface ModelsModalVMProps extends ModelsModalProps {
  brand: string;
  toggleChangeLog: () => void;
  setModels?: (models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]) => void;
  canAddDelete?: boolean;
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  grades: RefItem[];
  seriesId: string;
  seriesName: string;
  seriesGroup: SeriesCategories;
  year: string;
}

interface ModelsChangeLogProps {
  seriesId: string;
  year: string;
  readOnly?: boolean;
  version: string;
  versionInfo: VehicleDataVersionInfo;
}

const ModelsChangeLog = ({
  seriesId,
  year,
  readOnly,
  version,
  versionInfo,
}: ModelsChangeLogProps) => {
  const [logs, setLogs] = useState<ChangeLogModelItem[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    userStore: { brand },
    teamStore: {
      team: { param, changeLogLanguages },
    },
    vehicleModelsStore,
  } = useStores();

  const {
    getSeriesIdByName,
    getSeriesNameById,
    isLoaded: seriesLoaded,
    getFuelTypeIdByName,
    getFuelTypeNameById,
  } = useSeriesSettings(brand, seriesId, param, year, versionInfo, true);

  const { filterChangeLogChanges, changeLogChanges, canRevert } = useChangeLogChanges({
    getSeriesNameById,
    getFuelTypeNameById,
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoaded(false);
        const logs = await getVehicleModelChangeLogs(
          brand,
          param,
          seriesId,
          year,
          changeLogLanguages,
          version
        );

        changeLogVehicleModelGradesMapper(vehicleModelsStore.grades, logs, brand);
        changeLogVehicleModelMapper(vehicleModelsStore.fuelTypes, logs, ChangeLogTypes.FUEL_TYPE);
        setLogs(filterChangeLogChanges(logs));
        setIsLoaded(true);
      } catch (e) {
        toast.error('Error loading model change log');
        setIsLoaded(true);
      }
    })();
  }, [
    brand,
    param,
    seriesId,
    year,
    vehicleModelsStore,
    version,
    changeLogLanguages,
    filterChangeLogChanges,
  ]);

  const handleOnRevert = async (log: ChangeLogModelItem) => {
    try {
      const payload = convertLogNameToId(log).getPayload();
      const revertResponse = await trackPromise(
        revertChangeLog({
          brand: toGqlBrand(brand),
          team: toGqlTeam(param),
          seriesId,
          modelYear: parseInt(year),
          language: toGqlLanguage(log.language),
          payload: {
            changeType: payload.changeType,
            id: payload.id,
            revId: payload.revId,
            from: payload.from,
            to: payload.to,
          },
        })
      );

      if (log.changeType === ChangeLogTypes.GRADE_CHANGED) {
        vehicleModelsStore.grades.forEach(grade => {
          if (grade.id === log.id) {
            grade.value = log.beforeValue;
          }
        });
      } else {
        vehicleModelsStore.vehicleModels.forEach((model, index) => {
          if (model.revId === log.revId) {
            vehicleModelsStore.vehicleModels[index] = new VehicleModelItem<
              VehicleModelLexus | VehicleModelToyota
            >(
              brand as Brand,
              revertResponse,
              vehicleModelsStore.grades,
              vehicleModelsStore.fuelTypes
            );
            vehicleModelsStore.setModels(vehicleModelsStore.vehicleModels);
          }
        });
      }

      setIsLoaded(false);
      const logs = await getVehicleModelChangeLogs(
        brand,
        param,
        seriesId,
        year,
        changeLogLanguages,
        version
      );
      changeLogVehicleModelGradesMapper(vehicleModelsStore.grades, logs, brand);
      changeLogVehicleModelMapper(vehicleModelsStore.fuelTypes, logs, ChangeLogTypes.FUEL_TYPE);
      setLogs(logs);
      setIsLoaded(true);
      toast.success('Reverted change log successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error reverting change log');
      setIsLoaded(true);
    }
  };

  const convertLogNameToId = (log: ChangeLogModelItem) => {
    if (log.changeType === ChangeLogTypes.SERIES_SETTING) {
      log.before = getSeriesIdByName(log.before);
      log.after = getSeriesIdByName(log.after);
    } else if (log.changeType === ChangeLogTypes.FUEL_TYPE) {
      log.before = getFuelTypeIdByName(log.before);
      log.after = getFuelTypeIdByName(log.after);
    }
    return log;
  };

  const getChangeLogName = (log: ChangeLogModelItem) => {
    switch (log.changeType) {
      case ChangeLogTypes.IS_NOT_PUBLISHABLE: {
        return 'Code Red';
      }
      case ChangeLogTypes.DESCRIPTION: {
        return 'Trim Description';
      }
      default: {
        return log.changeType;
      }
    }
  };

  return (
    <>
      <ActionBar />
      {!isLoaded || !seriesLoaded ? (
        <Spinner short />
      ) : (
        <Table fullWidth className={styles.table}>
          {/* Table Header  */}
          <tbody className={styles.tbodyHeader}>
            <tr>
              <td></td>
              <td>Description</td>
              <td>Change Type</td>
              <td>Changes</td>
              <td>Modified By</td>
              <td>Modified Date</td>
            </tr>
          </tbody>

          {/* Table Entries */}
          <tbody className={styles.tbodyMain}>
            {logs.map(log => (
              <TableRow key={log.uid} zebra hoverShadow>
                <td className={styles.trimCol}>{log.code}</td>
                <td>{log.description}</td>
                <td>{getChangeLogName(log)}</td>
                <td>{changeLogChanges(log)}</td>
                <td>{log.modifiedBy}</td>
                <td>
                  <DateComponent format="MM/DD/YYYY hh:mm:ss A">{log.modifiedDate}</DateComponent>
                </td>
                <td>
                  {!readOnly && canRevert(log) && (
                    <IconTextButton icon="undo" text="Revert" onClick={() => handleOnRevert(log)} />
                  )}
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default observer(ModelsChangeLog);
