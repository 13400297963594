import cx from 'clsx';
import moment from 'moment-timezone';
import React from 'react';
import useStores from '../../../hooks/useStores';
import { ChangeLogModelItem } from '../../../models/changeLog.model';
import { GO_LIVE_DATE_TIME, goLiveDateTimeMap } from '../../../utils/dateUtils';
import styles from './changeLogChanges.module.scss';

interface Props {
  log: ChangeLogModelItem;
}

const GoLiveTimeChangeLog = ({ log }: Props) => {
  const { teamStore } = useStores();
  const timePicker = process.env.REACT_APP_GO_LIVE_TIME === 'true' && teamStore.team.showTimePicker;

  const beforeDate = log.before && moment(new Date(log.before)).isValid() ? new Date(log.before) : null;
  const afterDate = log.after && moment(new Date(log.after)).isValid() ? new Date(log.after) : null;

  const beforeTimeText = beforeDate
    ? `(${goLiveDateTimeMap[beforeDate.getHours() as GO_LIVE_DATE_TIME].text})`
    : '';

  const afterTimeText = afterDate
    ? `(${goLiveDateTimeMap[afterDate.getHours() as GO_LIVE_DATE_TIME].text})`
    : '';

  const beforeDateText = beforeDate?.toLocaleDateString() ?? '';
  const afterDateText = afterDate?.toLocaleDateString() ?? '';

  const hasDateChanges = beforeDateText !== afterDateText;
  const hasTimeChanges = beforeTimeText !== afterTimeText;

  return (
    <>
      {afterDate && (
        <p className={cx(styles.noWrap)} data-testid="log-after-p">
          <span data-testid="log-after-p-span-after-date">{afterDateText}</span>
          {timePicker && (
            <span className={cx(styles.zone)} data-testid="log-after-p-span-after-time">
              {afterTimeText}
            </span>
          )}
        </p>
      )}

      {beforeDate && (
        <p className={cx(styles.noWrap)} data-testid="log-before-p">
          <span
            className={cx({ [styles.changeRemoved]: hasDateChanges })}
            data-testid="log-before-p-span-before-date"
          >
            {beforeDateText}
          </span>
          {timePicker && (
            <span
              className={cx(styles.zone, { [styles.changeRemoved]: hasTimeChanges })}
              data-testid="log-before-p-span-before-time"
            >
              {beforeTimeText}
            </span>
          )}
        </p>
      )}
    </>
  );
};

export default GoLiveTimeChangeLog;
