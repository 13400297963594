import React, { useState } from 'react';
import { OptionItem, OptionLangMap } from '../models/options.model';
import { SpecItem, SpecsLangMap } from '../models/specs.model';
import { Modal } from 'vapi-ui-common';
import SyncTMNAChangesModal from '../routes/vehicleData/components/SyncTMNAChangesModal/SyncTMNAChangesModal';
import SpecsStore from '../stores/vehicleData/specsStore';
import OptionsStore from '../stores/vehicleData/optionsStore';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../models/vehicleModel.model';
import { AppliedChangesResponse, ChangeLogTypes } from '../models/changeLog.model';
import { KeyValueType } from '../models/common.model';

const useSyncChangesModal = (
  brand: string,
  team: string,
  seriesId: string,
  year: string,
  store: SpecsStore | OptionsStore,
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  entityType: 'specs' | 'options',
  deleteLangMap: (langMap: SpecsLangMap | OptionLangMap) => void,
  saveLangMap: (
    langMap: SpecsLangMap | OptionLangMap,
    acceptChanges: boolean,
    unlinkFromTMNA: boolean
  ) => void,
  changeModalApplicability: (item: SpecItem | OptionItem, modApp: KeyValueType<string>) => void
) => {
  const [showSyncChangesModal, setShowSyncChangesModal] = useState(false);
  const [syncChanges, setSyncChanges] = useState<SpecsLangMap | OptionLangMap | undefined>(
    undefined
  );

  const compareChanges = (langMap: SpecsLangMap | OptionLangMap) => {
    setSyncChanges(langMap);
    setShowSyncChangesModal(true);
  };

  const applyChanges = (response: AppliedChangesResponse) => {
    if (!syncChanges) {
      return;
    }
    const changeLogTypes: ChangeLogTypes[] = Object.keys(response.applied) as ChangeLogTypes[];
    changeLogTypes.forEach(changeType => {
      const langMap = response.applied[changeType];
      if (langMap) {
        Object.entries(langMap).forEach(([lang, after]) => {
          const item = syncChanges.langs[lang];
          if (item) {
            if (changeType === ChangeLogTypes.NAME && entityType === 'options') {
              (item as OptionItem).name = after;
            } else {
              switch (changeType) {
                case ChangeLogTypes.LINK:
                  item.link = after;
                  break;
                case ChangeLogTypes.DESCRIPTION:
                  item.description = after;
                  break;
                case ChangeLogTypes.NOTES:
                  item.notes = after;
                  break;
                case ChangeLogTypes.IN_PROGRESS:
                  item.isInProgress = after.toString() === 'true';
                  break;
                case ChangeLogTypes.MODEL_APPLICABILITY:
                  changeModalApplicability(item, after as KeyValueType<string>);
                  break;
                default:
                  break;
              }
            }
          }
        });
      }
    });
    saveLangMap(syncChanges, true, false);
  };

  const getSyncChangesModal = () => (
    <Modal open={showSyncChangesModal} size="auto" onClose={() => setShowSyncChangesModal(false)}>
      <SyncTMNAChangesModal
        brand={brand}
        team={team}
        seriesId={seriesId}
        year={year}
        itemId={syncChanges ? syncChanges.langs[store.defaultLang].id : ''}
        entityType={entityType}
        isNew={!!syncChanges?.langs[store.defaultLang].changedAttributes.includes('new')}
        isDelete={!!syncChanges?.langs[store.defaultLang].changedAttributes.includes('delete')}
        close={(response, shouldDelete, unlinkFromTMNA) => {
          setShowSyncChangesModal(false);
          if (syncChanges) {
            if (response) {
              applyChanges(response);
            } else if (shouldDelete) {
              deleteLangMap(syncChanges);
            } else if (unlinkFromTMNA) {
              saveLangMap(syncChanges, false, unlinkFromTMNA);
            }
          }
        }}
        vehicleModels={vehicleModels}
      />
    </Modal>
  );

  return { getSyncChangesModal, compareChanges };
};

export default useSyncChangesModal;
