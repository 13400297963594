import React, { useEffect, useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabColumn,
  FilterTabColumnList,
  FilterTabColumns,
  FilterTabFooter,
} from 'vapi-ui-common';
import Checkbox from '../../../../../../components/Checkbox';
import useChecklist from '../../../../../../hooks/useChecklist';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';

type Props = {
  categories: string[];
  categoryFilters: string[];
  setCategoryFilters: (categoryFilters: string[]) => void;
  isInProgressFilter: boolean;
  setIsInProgressFilter: (value: boolean) => void;
  isSyncUpdateFilter?: boolean;
  setIsSyncUpdateFilter?: (value: boolean) => void;
  onClose: () => void;
  isReviewNotesFilter?: boolean;
  setIsReviewNotesFilter?: (value: boolean) => void;
  isPublished?: boolean;
  isReviewPage?: boolean;
};
const OptionsFilters: React.FC<Props> = ({
  categories,
  categoryFilters,
  setCategoryFilters,
  isInProgressFilter,
  setIsInProgressFilter,
  isSyncUpdateFilter,
  setIsSyncUpdateFilter,
  onClose,
  isReviewNotesFilter,
  setIsReviewNotesFilter,
  isPublished,
  isReviewPage,
}) => {
  const { checklist, setChecklist, selectItem } = useChecklist();

  const [isInProgress, setIsInProgress] = useState(isInProgressFilter);
  const [isSyncUpdate, setIsSyncUpdate] = useState(isSyncUpdateFilter || false);
  const [isReviewNotes, setIsReviewNotes] = useState(isReviewNotesFilter || false);

  const {
    teamStore: {
      team: { name, allowInProgressFilter },
    },
  } = useStores();

  useEffect(() => {
    (() => {
      setChecklist(
        categories.map((cat: string) => ({
          name: cat,
          selected: categoryFilters.indexOf(cat) !== -1,
        }))
      );
    })();
  }, [categories, setChecklist, categoryFilters]);

  const applyFilters = () => {
    const selectedCategoryFilters: string[] = [];

    checklist.forEach(item => {
      if (item.selected) {
        selectedCategoryFilters.push(item.name);
      }
    });

    setIsInProgressFilter(isInProgress);
    setIsSyncUpdateFilter && setIsSyncUpdateFilter(isSyncUpdate);
    setCategoryFilters(selectedCategoryFilters);
    setIsReviewNotesFilter && setIsReviewNotesFilter(isReviewNotes);
    onClose();
  };

  return (
    <FilterTab>
      <FilterTabColumns>
        <FilterTabColumn>
          Category
          <FilterTabColumnList>
            {checklist.map(item => (
              <li key={item.name}>
                <Checkbox
                  id={`chboxCat${item.name}`}
                  checked={item.selected}
                  onChange={e => selectItem(item, e.currentTarget.checked)}
                >
                  {item.name}
                </Checkbox>
              </li>
            ))}
          </FilterTabColumnList>
        </FilterTabColumn>
        {allowInProgressFilter && !isReviewPage && (
          <FilterTabColumn>
            <Checkbox
              id="InProgress"
              defaultChecked={isInProgress}
              onChange={e => setIsInProgress(e.currentTarget.checked)}
            >
              In Progress
            </Checkbox>
          </FilterTabColumn>
        )}
        {setIsReviewNotesFilter && (
          <FilterTabColumn>
            <Checkbox
              id="ReviewNotes"
              defaultChecked={isReviewNotes}
              onChange={e => setIsReviewNotes(e.currentTarget.checked)}
            >
              Review Notes
            </Checkbox>
          </FilterTabColumn>
        )}
        {VehicleTeam.AGENCY_SPANISH === name && setIsSyncUpdateFilter && !isPublished && (
          <FilterTabColumn>
            <Checkbox
              id="SyncUpdates"
              checked={isSyncUpdate}
              onChange={e => setIsSyncUpdate(e.currentTarget.checked)}
            >
              Sync Updates
            </Checkbox>
          </FilterTabColumn>
        )}
      </FilterTabColumns>
      <FilterTabFooter>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </FilterTabFooter>
    </FilterTab>
  );
};

export default OptionsFilters;
