import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../../components/ChangeLogInner';
import { ChangeLogChanges } from '../../../../../components/ChangeLogTable';
import useStores from '../../../../../hooks/useStores';
import { ChangeLogItem, ChangeLogTypes } from '../../../../../models/changeLog.model';
import { Language } from '../../../../../models/user.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../models/vehicleModel.model';
import {
  changeLogHandleDeleteTypes,
  changeLogIdMapper,
  changeLogLexusColorApplicabilityMapper,
  changeLogLexusGroupApplicabilityMapper,
  groupModelMapper,
} from '../../../../../utils/changeLogUtils';
import { interiorColorTypesLexusXForm } from '../../../../../utils/colorLexusUtils';
import {
  toGqlBrand,
  toGqlFilter,
  toGqlLanguage,
  toGqlTeam,
} from '../../../../../utils/graphqlUtils';
import {
  getChangeLog,
  getInteriorColorTypes,
  getInteriorColorsLexus,
  revertChange,
} from '../../../../../webservices/vehicleColorsApi';

interface ColorsLexusChangeLogProps {
  seriesId: string;
  year: string;
  version: string;
  readOnly?: boolean;
  loadData?: () => Promise<void>;
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
}

const ColorsLexusChangeLogController = (props: ColorsLexusChangeLogProps) => {
  const { seriesId, year, version, readOnly, vehicleModels } = props;

  const {
    colorsLexusStore,
    userStore: { brand },
    teamStore,
    changeLogStore,
  } = useStores();

  const loadData = async () => {
    let versionNum = version;
    if (version && isNaN(Number(version))) {
      versionNum = '';
    }
    const [changelogResponse, intColorsResponse, intColorTypesResponse] = await Promise.all([
      getChangeLog({
        brand: toGqlBrand(brand),
        team: toGqlTeam(teamStore.team.param),
        seriesId,
        modelYear: parseInt(year),
        language: toGqlLanguage(Language.EN),
        filter: toGqlFilter(version),
      }),
      getInteriorColorsLexus({
        team: toGqlTeam(teamStore.team.param),
        seriesId,
        modelYear: Number(year),
        filter: toGqlFilter(versionNum),
        includeAll: true,
      }),
      getInteriorColorTypes({
        brand: toGqlBrand(brand),
        team: toGqlTeam(teamStore.team.param),
        seriesId,
        modelYear: Number(year),
        filter: toGqlFilter(versionNum),
        includeAll: true,
      }),
    ]);

    const logs = changelogResponse.map(item => new ChangeLogItem(item, !readOnly));
    colorsLexusStore.interiorColorTypes = interiorColorTypesLexusXForm(intColorTypesResponse);

    changeLogIdMapper(
      colorsLexusStore.interiorColorTypes.materials,
      logs,
      ChangeLogTypes.MATERIALS
    );
    changeLogIdMapper(
      colorsLexusStore.interiorColorTypes.interiorTypes,
      logs,
      ChangeLogTypes.INTERIOR
    );
    changeLogIdMapper(
      colorsLexusStore.interiorColorTypes.ornaments,
      logs,
      ChangeLogTypes.ORNAMENT_1
    );
    changeLogIdMapper(
      colorsLexusStore.interiorColorTypes.ornaments,
      logs,
      ChangeLogTypes.ORNAMENT_2
    );
    changeLogIdMapper(colorsLexusStore.interiorColorTypes.groups, logs, ChangeLogTypes.GROUP_ADDED);
    changeLogIdMapper(
      colorsLexusStore.interiorColorTypes.groups,
      logs,
      ChangeLogTypes.GROUP_DELETED
    );
    changeLogLexusGroupApplicabilityMapper(
      colorsLexusStore.interiorColorTypes.groups,
      logs,
      vehicleModels
    );
    changeLogLexusColorApplicabilityMapper(
      intColorsResponse,
      logs,
      ChangeLogTypes.EXT_COLOR_APPLICABILITY,
      colorsLexusStore.interiorColorTypes.groups
    );
    changeLogHandleDeleteTypes(logs, ChangeLogTypes.INT_COLOR_DELETED);
    changeLogHandleDeleteTypes(logs, ChangeLogTypes.EXT_COLOR_DELETED);
    groupModelMapper(logs, vehicleModels);
    changeLogStore.logs = logs;
  };

  return <ColorsLexusChangeLog {...props} loadData={loadData} />;
};

const ColorsLexusChangeLog = observer(
  ({ seriesId, year, readOnly, version, loadData = async () => {} }: ColorsLexusChangeLogProps) => {
    const {
      userStore: { brand },
      teamStore,
      changeLogStore,
      colorsStore,
    } = useStores();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      (async () => {
        setIsLoaded(false);

        try {
          await loadData();
        } catch (e) {
          toast.error('Error loading colors change log');
        }

        setIsLoaded(true);
      })();
    }, [
      loadData,
      brand,
      changeLogStore,
      readOnly,
      seriesId,
      teamStore,
      year,
      colorsStore,
      version,
    ]);

    const handleOnRevert = async (item: ChangeLogItem) => {
      setIsLoaded(false);
      try {
        await trackPromise(
          revertChange({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId,
            modelYear: parseInt(year),
            language: toGqlLanguage(Language.EN),
            payload: {
              changeType: item.payload.changeType,
              from: item.payload.from,
              to: item.payload.to ?? '',
              id: item.payload.id,
            },
          })
        );
        await loadData();

        toast.success('Change log reverted');
      } catch {
        toast.error('Error reverting change log');
      }
      setIsLoaded(true);
    };
    return (
      <ChangeLogInner
        module={'Colors'}
        isLoaded={isLoaded}
        handleOnRevert={handleOnRevert}
        changeLogChanges={(item: ChangeLogItem) => <ChangeLogChanges changeItem={item} />}
        canRevert={(item: ChangeLogItem) => item.canRevert}
      />
    );
  }
);

export default observer(ColorsLexusChangeLogController);
