export enum VDStatus {
  ARCHIVE = 'archive',
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum VD_PUBLISH_TYPE {
  PUBLISH = 'publish',
  PREVIEW = 'preview',
}

export enum LIMITED_DATA_STATUS {
  READY_TO_PUBLISH = 0,
  IN_PROGRESS = 1,
  PUBLISHED = 2,
}

export const STATUS_DRAFT = `status=${VDStatus.DRAFT.toUpperCase()}`;
export const STATUS_PUBLISHED = `status=${VDStatus.PUBLISHED.toUpperCase()}`;
export const OPPOSITE_TEAM_QUERY_SORT = 'getLatestVersion=true';
export const OPPOSITE_TEAM_SORT = 'OPPOSITE_TEAM_SORT';

export const langNameMap: { [langCode: string]: string } = {
  EN: 'English',
  ES: 'Spanish',
};

export const bnpToModelKeyMap: { [key: string]: string } = {
  drive: 'drive',
  transmission: 'transmission',
  engine: 'engine',
  beds: 'bed',
  cabs: 'cab',
  seats: 'seating',
};
