import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
  ActionButton,
  ActionItemModal,
  areRichTextValuesDifferent,
  convertToRichTextObject,
  Modal,
} from 'vapi-ui-common';
import NotesPopover from '../../../../../../components/NotesPopover';
import { TableCell, TableRow } from '../../../../../../components/Table';
import { TableRowProps } from '../../../../../../components/Table/components/TableRow/TableRow';
import { OptionsPackage, OptionsPackageSpec } from '../../../../../../models/optionsLexus.model';
import CenteredTextAreaCell from '../../../../../../routes/vehicleData/components/tableCells/CenteredTextAreaCell';
import RichTextCell from '../../../../../../routes/vehicleData/components/tableCells/RichTextCell';
import { IOptionsLexusPackageSpecRow } from './IOptionsLexusPackageSpecRow';

export interface OptionsLexusPackageSpecRowProps
  extends IOptionsLexusPackageSpecRow,
    TableRowProps {
  spec: OptionsPackageSpec;
  optionsPackage: OptionsPackage;
}

const OptionsLexusPackageSpecRow = ({
  spec,
  optionsPackage,
  disabled,
  saveSpec = () => {},
  deleteSpec = () => {},
  tokens,
}: OptionsLexusPackageSpecRowProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOnSpecsChange = (spec: OptionsPackageSpec, specs: string) => {
    if (areRichTextValuesDifferent(spec.specs, specs)) {
      spec.specs = specs;
      saveSpec(optionsPackage, spec);
    }
  };

  const handleOnTomsChange = (spec: OptionsPackageSpec, toms: string) => {
    if (spec.toms !== toms) {
      spec.toms = toms;
      saveSpec(optionsPackage, spec);
    }
  };

  const handleOnSmsChange = (spec: OptionsPackageSpec, sms: string) => {
    if (spec.sms !== sms) {
      spec.sms = sms;
      saveSpec(optionsPackage, spec);
    }
  };

  const handleOnToggleExclusive = (spec: OptionsPackageSpec) => {
    spec.isExclusive = !spec.isExclusive;
    saveSpec(optionsPackage, spec);
  };

  return (
    <TableRow zebra onFillRowHeightChange={height => (spec.rowHeight = height)}>
      {/* TOMS */}
      <CenteredTextAreaCell
        border
        size="xsmall"
        disabled={disabled}
        value={spec.toms}
        onBlurCallback={value => handleOnTomsChange(spec, value)}
        allowEmpty
        singleLine
      />

      {/* SMS */}
      <CenteredTextAreaCell
        border
        size="xsmall"
        disabled={disabled}
        value={spec.sms}
        onBlurCallback={value => handleOnSmsChange(spec, value)}
        allowEmpty
        singleLine
      />

      {/* Specs */}
      <RichTextCell
        colType="description"
        disabled={disabled}
        value={spec.specs}
        onBlur={value => handleOnSpecsChange(spec, value)}
        suggestionTypes={tokens}
      />

      {/* Action Buttons */}
      <TableCell>
        {!disabled && (
          <div style={{ display: 'flex' }}>
            <ActionButton icon="trash" onClick={() => setOpenDeleteModal(true)} />
            <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
              <ActionItemModal
                onClose={() => setOpenDeleteModal(false)}
                description={convertToRichTextObject(spec.specs).text}
                onConfirm={() => deleteSpec(optionsPackage, spec)}
                itemType="Spec"
              />
            </Modal>
            <ActionButton
              icon="flag"
              selected={spec.isExclusive}
              onClick={() => handleOnToggleExclusive(spec)}
            />
          </div>
        )}
        {spec.rejectNotes && (
          <NotesPopover
            notes={spec.rejectNotes}
            handleUpdateItem={() => void 0}
            align="left"
            icon="purpleCircle"
            iconText="Review"
            readOnly
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default observer(OptionsLexusPackageSpecRow);
