import DropdownEdit from '../../../../../components/DropdownEdit';
import useComponentVisible from '../../../../../hooks/useComponentVisible';
import cx from 'clsx';
import React, { ReactNode } from 'react';
import { Dropdown, DropdownButton } from 'vapi-ui-common';
import { addWBR } from '../../../../../utils';
import styles from './dropdownEditorCell.module.scss';
import { Language } from '../../../../../models/user.model';

type Props = {
  onAdd?: (idx: string) => void;
  value: string;
  disabled?: boolean;
  renderList: (onClose: () => void) => ReactNode;
  className?: string;
  error?: boolean;
  multiLang?: Language[];
};

const DropdownEditorCell: React.FC<Props> = ({
  onAdd,
  value,
  disabled,
  renderList,
  error,
  multiLang,
  className,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <td
      className={cx(
        styles.root,
        className,
        multiLang && multiLang.length > 1 && styles.blockInputs
      )}
    >
      <Dropdown>
        <DropdownButton
          error={error}
          disabled={disabled}
          className={styles.tableDropdown}
          onClick={() => setIsComponentVisible(true)}
        >
          {addWBR(value)}
        </DropdownButton>
        <DropdownEdit
          ref={ref}
          open={isComponentVisible}
          addBtnText={'Category'}
          onAdd={onAdd}
          renderList={() => renderList(() => setIsComponentVisible(false))}
        />
      </Dropdown>
    </td>
  );
};

export default DropdownEditorCell;
