import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox';
import useStores from '../../../../hooks/useStores';
import { GradeApplicability } from '../../../../models/features.model';
import { gradeApplicabilityXform } from '../../../../utils/gradeApplicabilityUtils';
import GradeInfo from './GradeInfo';
import styles from './styles.module.scss';

// helper function for process the check all boxes
const processAllGradesChecked = (gradeApplicability: GradeApplicability) => {
  return Object.values(gradeApplicability).every(item => item.isComparable === true);
};

type Props = {
  gradeApplicability: GradeApplicability;
  onClose: () => void;
  onConfirm: (gradeApplicability: GradeApplicability) => void;
  readOnly?: boolean;
};

const GradeApplicabilityModal = ({ readOnly, onClose, onConfirm, gradeApplicability }: Props) => {
  const { vehicleModelsStore } = useStores();
  const [gradeApp, setGradeApp] = useState<GradeApplicability>({});
  const [allGradeCheck, setAllGradesCheck] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  useEffect(() => {
    const initialGradeApp = gradeApplicabilityXform(
      vehicleModelsStore.vehicleModels,
      gradeApplicability
    );
    setGradeApp(initialGradeApp);
    setAllGradesCheck(processAllGradesChecked(initialGradeApp));
  }, [gradeApplicability, vehicleModelsStore]);

  const handleOnConfirm = () => {
    //validate.
    setShowValidation(true);
    const { isValid } = runValidation();
    if (!isValid) {
      return false;
    }

    // cleanup return object
    for (const i in gradeApp) {
      if (!gradeApp[i].isComparable) {
        delete gradeApp[i];
      }
    }

    onConfirm(gradeApp);
    onClose();
    return undefined;
  };
  const runValidation = () => {
    let isValid = true;
    const validation: {
      [id: string]: boolean;
    } = {};
    //if not validated, then return false
    for (const i in gradeApp) {
      if (gradeApp[i].isComparable && gradeApp[i].applicabilityText && Object.keys(gradeApp[i]).length > 0) {
        for (const j in gradeApp[i].applicabilityText) {
          if (gradeApp[i].applicabilityText[j]?.availability?.length) {
            validation[`${i}_${j}`] = true;
            continue;
          }
          validation[j] = false;
          isValid = false;
        }
      }
    }
    return { isValid: isValid, validation: validation };
  };

  const handleAllGrades = (event: ChangeEvent<HTMLInputElement>) => {
    Object.keys(gradeApp).forEach(
      key => (gradeApp[key].isComparable = event.currentTarget.checked)
    );

    setAllGradesCheck(!allGradeCheck);
    updateGradeApp({ ...gradeApp });
  };

  const updateGradeApp = (gradeApp: GradeApplicability) => {
    setGradeApp(gradeApp);
    setAllGradesCheck(processAllGradesChecked(gradeApp));
  };

  return (
    <>
      <ModalHeader onClose={onClose}>Compare Feature</ModalHeader>
      <ModalBody>
        <Checkbox
          disabled={readOnly}
          data-key="AllGradesCheckbox"
          checked={allGradeCheck}
          id="chboxGrdAll"
          onChange={handleAllGrades}
        >
          <span>
            <b>All Grades</b>
          </span>
        </Checkbox>

        <div className={cx(styles.headers, styles.subText)}>
          <div data-key="HighlightHeaderText">Highlight</div>
          <div data-key="EnabledHeaderText">Comparable</div>
          <div data-key="AvailabilityHeaderText" className={cx(styles.availabilityHeader)}>
            Availability
          </div>
        </div>

        {Object.keys(gradeApp).map((key: string) => (
          <GradeInfo
            id={key}
            key={key}
            gradeAppItem={gradeApp[key]}
            readOnly={readOnly}
            showValidation={showValidation}
            runValidation={runValidation}
            onSave={gradeItem =>
              updateGradeApp({
                ...gradeApp,
                [key]: gradeItem,
              })
            }
          />
        ))}
      </ModalBody>
      <ModalFooter>
        {readOnly ? (
          <Button data-key="cancelbutton" variant="transparent" onClick={onClose}>
            Close
          </Button>
        ) : (
          <>
            <Button data-key="cancelbutton" variant="transparent" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleOnConfirm} data-key="submitbutton">
              Submit
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default observer(GradeApplicabilityModal);
