import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { areRichTextValuesDifferent, SingleLineRTEditor } from 'vapi-ui-common';
import IconTextButton from '../../../../../../../components/IconTextButton';
import Input from '../../../../../../../components/Input';
import { TableCell } from '../../../../../../../components/Table';
import useStores from '../../../../../../../hooks/useStores';
import { BnPCategoryItem, BnpCategorySplit } from '../../../../../../../models/buildAndPrice.model';
import { Language } from '../../../../../../../models/user.model';
import { tokensXForm } from '../../../../../../../utils/disclaimersUtils';
import RichTextCell from '../../../../../components/tableCells/RichTextCell';
import { BuildAndPriceRowItemContext } from '../BuildAndPriceRow';
import styles from '../buildAndPriceRow.module.scss';

interface GradeOrDescriptionCellProps {
  split?: BnpCategorySplit;
}

const GradeOrDescriptionCell = ({ split }: GradeOrDescriptionCellProps) => {
  const context = useContext(BuildAndPriceRowItemContext);
  const {
    bnpStore: { langWriteMap, defaultLang, fullEditPermissions },
    disclaimersStore: { tokens },
  } = useStores();

  if (!context) {
    return null;
  }

  const disclaimerTokens = tokensXForm(tokens);

  const {
    langMap,
    readOnly,
    onUpdateDescription,
    onUpdateGradeDetail,
    splitModelAplicabilityModalRef,
    selectedLangs,
  } = context;

  const defaultItem = langMap[defaultLang];

  const handleOnDescriptionChange = (
    item: BnPCategoryItem,
    value: string,
    lang: string,
    split?: BnpCategorySplit
  ) => {
    if (areRichTextValuesDifferent(item.description, value)) {
      if (split) {
        split.description = value;
      } else {
        item.description = value;
      }
      onUpdateDescription({ item, lang, split });
    }
  };

  const handleOnGradeTitleChange = (item: BnPCategoryItem, title: string, lang: string) => {
    if (item.title !== title) {
      item.title = title;
      onUpdateGradeDetail(item, lang);
    }
  };

  const handleOnGradeCopyChange = (item: BnPCategoryItem, copy: string, lang: string) => {
    if (areRichTextValuesDifferent(item.copy, copy)) {
      item.copy = copy;
      onUpdateGradeDetail(item, lang);
    }
  };

  const hideApplicabilityOptions = !fullEditPermissions || readOnly;

  return (
    <>
      {selectedLangs.map(lang => {
        const item = langMap[lang];
        if (defaultItem.label === 'Grade') {
          return (
            <TableCell
              key={`${item.categoryId}-${lang}`}
              className={cx(styles.descriptionCell)}
              noSpan
            >
              <div>
                <label htmlFor={`amm-title-${item.categoryId}`} className={styles.titleLabel}>
                  Title
                </label>
                <Input
                  name="gradeTitle"
                  id={`amm-title-${item.categoryId}`}
                  defaultValue={item.title}
                  onBlur={e => handleOnGradeTitleChange(item, e.currentTarget.value.trim(), lang)}
                  className={cx(
                    styles.titleInput,
                    item.changedAttributes.includes('title') ? styles.errorText : ''
                  )}
                  disabled={readOnly || !langWriteMap[lang as Language]?.canEdit}
                />
              </div>
              <div className={styles.copyWrapper}>
                <label htmlFor={`amm-copy-${item.categoryId}`} className={styles.copyLabel}>
                  Copy
                </label>
                <SingleLineRTEditor
                  editorStyles={cx(
                    styles.copyInput,
                    item.changedAttributes.includes('copy') ? styles.errorText : ''
                  )}
                  value={item.copy}
                  onBlur={value => handleOnGradeCopyChange(item, value, lang)}
                  disabled={readOnly || !langWriteMap[lang as Language]?.canEdit}
                />
              </div>
            </TableCell>
          );
        }
        let targetSplit = split;
        if (split && lang !== defaultLang) {
          if (item.splitsMap[split.id]) {
            targetSplit = item.splitsMap[split.id];
          }
        }
        return (
          <RichTextCell
            key={`${item.categoryId}-${lang}`}
            suggestionTypes={disclaimerTokens}
            colType="description"
            disabled={readOnly || !langWriteMap[lang as Language]?.canEdit}
            value={targetSplit ? targetSplit.description ?? '' : item.description}
            onBlur={value => handleOnDescriptionChange(item, value, lang, targetSplit)}
            cellStyles={cx(styles.descriptionCell, {
              [styles.errorText]: item.changedAttributes.includes(
                targetSplit ? `${targetSplit.id}#description` : 'description'
              ),
              [styles.splitMenu]: !hideApplicabilityOptions,
            })}
            wrapContent={!hideApplicabilityOptions}
          >
            {!targetSplit && !hideApplicabilityOptions && (
              <IconTextButton
                className={styles.splitBtn}
                icon="split"
                text="Split Model Applicability"
                onClick={() => splitModelAplicabilityModalRef.current?.openCreate(item, lang)}
                smallIcon
              />
            )}
            {targetSplit && !hideApplicabilityOptions && (
              <div className={styles.splitBtn}>
                <IconTextButton
                  iconClassName={styles.iconBlue}
                  icon="edit"
                  text="Edit Split"
                  onClick={() =>
                    splitModelAplicabilityModalRef.current?.openEdit(item, targetSplit!, lang)
                  }
                  smallIcon
                />
                <IconTextButton
                  icon="trash"
                  text="Delete Split"
                  onClick={() =>
                    splitModelAplicabilityModalRef.current?.openDelete(item, targetSplit!, lang)
                  }
                  smallIcon
                />
              </div>
            )}
          </RichTextCell>
        );
      })}
    </>
  );
};

export default observer(GradeOrDescriptionCell);
