import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { Button, Checkbox, Dropdown, DropdownButton, DropdownList, Input } from 'vapi-ui-common';
import DropdownEdit from '../../../../../../../../components/DropdownEdit';
import DropdownEditItem from '../../../../../../../../components/DropdownEdit/DropdownEditItem';
import IconTextButton from '../../../../../../../../components/IconTextButton';
import { LimitedDataStatus } from '../../../../../../../../components/LimitedDataStatus';
import { TableRow } from '../../../../../../../../components/Table';
import { LIMITED_DATA_STATUS } from '../../../../../../../../constants/vehicleData/VDConstants';
import useComponentVisible from '../../../../../../../../hooks/useComponentVisible';
import useStores from '../../../../../../../../hooks/useStores';
import { IDValueType, KeyValueType } from '../../../../../../../../models/common.model';
import { RefItem } from '../../../../../../../../models/refItem.model';
import { BRAND_TDPR, Language } from '../../../../../../../../models/user.model';
import { SeriesCategories } from '../../../../../../../../models/vehicleData.model';
import {
  UpdateModelStatusRequest,
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../../models/vehicleModel.model';
import { FieldStatus } from '../../../../../../../../stores/vehicleData/modelTabStore';
import { addWBR } from '../../../../../../../../utils';
import { isModelValid } from '../../../../../../../../utils/modelsUtils';
import { getSortedListByString } from '../../../../../../../../utils/sortUtils';
import SyncTdPRButton from '../../../../../../components/SyncTdPRButton/SyncTdPRButton';
import useModel from '../../../../hooks/useModel';
import styles from '../../../../modelsModal.module.scss';
import HiddenField from '../HiddenField';
import Label from '../Label';
import Bed from './Bed';
import Cab from './Cab';
import Description from './Description';
import Drive from './Drive';
import Engine from './Engine';
import Transmission from './Transmission';
import TrimTitle from './TrimTitle';

interface ModelsEditPanelProps {
  modelMap: KeyValueType<VehicleModelItem<VehicleModelToyota | VehicleModelLexus>>;
  category: SeriesCategories;
  seriesName: string;
  grades: RefItem[];
  fuelTypes: IDValueType[];
  onClose: () => void;
  onAddGrade?: (grade: string) => void;
  onUpdateGrade?: (gradeId: string, gradeValue: string) => void;
  onSave?: (
    modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>
  ) => void;
  rowClass?: string;
  readOnly?: boolean;
  brand: string;
  compareModel: (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => void;
  fieldStatus?: FieldStatus;
  updateFieldStatus?: (payload: UpdateModelStatusRequest) => void;
}

const ModelsEditPanelToyota = ({
  category,
  seriesName,
  grades,
  fuelTypes,
  modelMap,
  onClose,
  onAddGrade,
  onUpdateGrade = () => {},
  onSave = () => {},
  rowClass,
  readOnly = false,
  brand,
  compareModel,
  fieldStatus,
  updateFieldStatus,
}: ModelsEditPanelProps) => {
  const {
    modelTabStore: { resetEditVehicleModel, getLangVehicleModel, setGrade },
    vehicleModelsStore: { defaultLanguage },
    userStore: { langPermissions, isTdpr },
    teamStore: {
      team: { languages: teamLanguages, showAcceptChanges, showLimitedData },
    },
  } = useStores();

  const {
    model,
    modelVm,
    setModelVm,
    katashikiArray,
    setKatashikiArray,
    getPayload,
    hasChanges,
    hasChangesLanguage,
  } = useModel({ modelMap });
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleStatusClick = (propName: string) => {
    if (!langPermissions[Language.EN]?.canEdit || readOnly) {
      return undefined;
    }

    const processStatusChange = () => {
      let payload: UpdateModelStatusRequest = {
        id: fieldStatus?.id || '',
        grade: fieldStatus?.grade || 0,
        drive: fieldStatus?.drive || 0,
        engine: fieldStatus?.engine || 0,
        horsepower: fieldStatus?.horsepower || 0,
        transmission: fieldStatus?.transmission || 0,
        bed: fieldStatus?.bed || 0,
        cab: fieldStatus?.cab || 0,
        seating: fieldStatus?.seating || 0,
        trimTitle: fieldStatus?.trimTitle || 0,
        description: fieldStatus?.description || 0,
        revId: fieldStatus?.revId,
      };

      if (payload[propName as keyof UpdateModelStatusRequest] !== undefined) {
        if (
          payload[propName as keyof UpdateModelStatusRequest] ===
          LIMITED_DATA_STATUS.READY_TO_PUBLISH
        ) {
          payload = {
            ...payload,
            [propName]: LIMITED_DATA_STATUS.IN_PROGRESS,
          };
        } else if (
          payload[propName as keyof UpdateModelStatusRequest] === LIMITED_DATA_STATUS.IN_PROGRESS
        ) {
          payload = {
            ...payload,
            [propName]: LIMITED_DATA_STATUS.READY_TO_PUBLISH,
          };
        }
      }

      if (updateFieldStatus) {
        updateFieldStatus(payload);
      }
    };

    return processStatusChange;
  };

  const handleOnClose = () => {
    resetEditVehicleModel(model.id);
    onClose();
  };

  const handleOnSave = () => {
    let hasError = false;
    const modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>> = {};

    teamLanguages.forEach(lang => {
      if (langPermissions[lang]?.canEdit) {
        const payload = getPayload({
          language: lang,
        });

        const langVM = getLangVehicleModel(modelMap[lang], lang);
        if (isModelValid(langVM, payload, brand)) {
          if (hasChangesLanguage(lang)) {
            langVM.updateDynamicProps(payload);
            modelLangMap[lang] = langVM;
          }
        } else {
          hasError = true;
          toast.error(`Please fill in the required fields - ${lang}`);
        }
      }
    });

    if (!hasError) {
      // need to make sure that the models for all languages have the same id
      let id = '';
      // first get the id
      Object.values(modelLangMap).forEach(mdl => {
        if (!id && mdl.id) {
          id = mdl.id;
        }
      });
      // if no id found then create one
      if (!id) {
        id = uuidv4();
      }
      // now give each model the same id
      Object.values(modelLangMap).forEach(mdl => {
        mdl.id = id;
      });

      onSave(modelLangMap);
      handleOnClose();
    }
  };

  const handleKatashikiChange = (index: number, newValue: string) => {
    const newKatashikiArray = [...katashikiArray];
    newKatashikiArray[index] = newValue;

    setKatashikiArray(newKatashikiArray);
  };

  const removeKatashiki = (index: number) => {
    setKatashikiArray(katashikiArray.filter((k, i) => i !== index));
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    for (const lang of teamLanguages) {
      if (!!langPermissions[lang]?.canEdit) {
        changed.push(...getLangVehicleModel(model, lang).getVal('changedAttributes'));
      }
    }
    return changed;
  };

  const isAcceptChangesVisible =
    teamLanguages.includes(Language.ES) &&
    getLangVehicleModel(model, Language.ES).getVal('changedAttributes').length > 0;
  const showSyncTdPR = brand === BRAND_TDPR && !!model.getVal('fromTMNA') && !readOnly;
  const displayLimitedDataStatus = showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';

  return (
    <TableRow zebra hoverShadow className={rowClass}>
      <td colSpan={7}>
        <section className={styles.editModel}>
          <div className={styles.inputSection}>
            <div className={styles.inputColumn}>
              {showSyncTdPR && (
                <div className={cx(styles.row, styles.syncTdPRRow)}>
                  <SyncTdPRButton
                    id={model.id}
                    changedAttributes={changedAttributes()}
                    onClick={() => {
                      compareModel(model);
                      handleOnClose();
                    }}
                  />
                </div>
              )}
              <div className={styles.column}>
                <div className={styles.label}>
                  <Label htmlFor={`amm-code-${model.uid}`}>TMNA Model Code</Label>
                </div>
                <Input
                  required
                  id={`amm-code-${model.uid}`}
                  className={styles.input}
                  value={modelVm.code}
                  disabled={!langPermissions[Language.EN]?.canEdit || readOnly || isTdpr}
                  onChange={e => setModelVm({ ...modelVm, code: e.currentTarget.value })}
                  placeholder="English"
                />
              </div>
              {isTdpr && (
                <div className={styles.column}>
                  <div className={styles.label}>
                    <Label htmlFor={`amm-tdpr-code-${model.uid}`}>TDPR/USVI Model Code</Label>
                  </div>
                  <div>
                    <Input
                      required
                      className={styles.input}
                      id={`amm-tdpr-code-${model.uid}`}
                      value={modelVm.tdprCode}
                      disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                      onChange={e => {
                        setModelVm({ ...modelVm, tdprCode: e.currentTarget.value });
                      }}
                    />
                  </div>
                </div>
              )}

              {isTdpr && (
                <div className={cx([styles.column])}>
                  <div className={styles.label}>
                    <Label htmlFor={`amm-is-tdpr-${model.id}`}>TDPR</Label>
                  </div>
                  <Checkbox
                    required={!modelVm.isTDPR && !modelVm.isUSVI}
                    id={`amm-is-tdpr-${model.id}`}
                    checked={modelVm.isTDPR || false}
                    onChange={() => {
                      setModelVm({
                        ...modelVm,
                        isTDPR: !modelVm.isTDPR,
                      });
                    }}
                    disabled={!langPermissions[Language.ES]?.canEdit || readOnly}
                  ></Checkbox>
                </div>
              )}

              {isTdpr && (
                <div className={cx([styles.column])}>
                  <div className={styles.label}>
                    <Label htmlFor={`amm-is-USVI-${model.id}`}>USVI</Label>
                  </div>
                  <Checkbox
                    required={!modelVm.isTDPR && !modelVm.isUSVI}
                    id={`amm-is-USVI-${model.id}`}
                    checked={modelVm.isUSVI || false}
                    onChange={() => {
                      setModelVm({
                        ...modelVm,
                        isUSVI: !modelVm.isUSVI,
                      });
                    }}
                    disabled={!langPermissions[Language.ES]?.canEdit || readOnly}
                  ></Checkbox>
                </div>
              )}
              <TrimTitle vmId={model.id} seriesName={seriesName} model={model}>
                <LimitedDataStatus
                  status={fieldStatus?.trimTitle}
                  onClick={handleStatusClick('trimTitle')}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                />
              </TrimTitle>
              <Drive vmId={model.id} model={model}>
                <LimitedDataStatus
                  status={fieldStatus?.drive}
                  onClick={handleStatusClick('drive')}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                />
              </Drive>
              <Transmission vmId={model.id} model={model}>
                <LimitedDataStatus
                  status={fieldStatus?.transmission}
                  onClick={handleStatusClick('transmission')}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                />
              </Transmission>
              <div className={cx([styles.column])}>
                <div className={styles.label}>
                  <Label htmlFor={`amm-fueltype-${model.uid}`}>Fuel Type</Label>
                </div>
                <DropdownList
                  required={!modelVm?.fuelType?.value}
                  btnClass={cx(styles.dropdown, styles.input)}
                  value={modelVm?.fuelType?.value}
                  list={getSortedListByString<IDValueType<any>>(fuelTypes, 'value').map(
                    ft => ft.value
                  )}
                  disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                  onSelect={value => {
                    setModelVm({
                      ...modelVm,
                      fuelType: fuelTypes.find(item => item.value === value),
                    });
                  }}
                />
              </div>
              <Cab vmId={model.id} category={category} model={model}>
                <LimitedDataStatus
                  status={fieldStatus?.cab}
                  onClick={handleStatusClick('cab')}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                />
              </Cab>
            </div>

            <div className={styles.inputColumn}>
              <div className={styles.column}>
                <div className={styles.label}>
                  <Label htmlFor={`amm-grade-${model.uid}`}>Grade</Label>
                </div>
                <Dropdown>
                  <DropdownButton
                    error={!modelVm.grade.value}
                    className={cx(styles.dropdown, styles.input)}
                    disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                    onClick={() => setIsComponentVisible(true)}
                  >
                    {addWBR(modelVm.grade.value)}
                  </DropdownButton>
                  <DropdownEdit
                    ref={ref}
                    open={isComponentVisible}
                    addBtnText={'Grade'}
                    onAdd={onAddGrade}
                    renderList={() => (
                      <>
                        {getSortedListByString<RefItem>(grades, 'value').map(item => (
                          <DropdownEditItem
                            key={item.uid}
                            value={item.value}
                            isSelected={false}
                            onEdit={(from, to) => {
                              onUpdateGrade(item.id, to);
                            }}
                            onClose={() => setIsComponentVisible(false)}
                            onSelect={() => {
                              setModelVm({ ...modelVm, grade: item });
                              setGrade({ vmId: model.id, language: defaultLanguage, grade: item });
                            }}
                          />
                        ))}
                      </>
                    )}
                  />
                </Dropdown>
                <LimitedDataStatus
                  status={fieldStatus?.grade}
                  onClick={handleStatusClick('grade')}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                />
              </div>
              <Description vmId={model.id} model={model}>
                <LimitedDataStatus
                  status={fieldStatus?.description}
                  onClick={handleStatusClick('description')}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                />
              </Description>
              <Engine vmId={model.id} model={model}>
                <LimitedDataStatus
                  status={fieldStatus?.engine}
                  onClick={handleStatusClick('engine')}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                />
              </Engine>
              <div className={styles.column}>
                <div className={styles.label}>
                  <Label htmlFor={`amm-horsepower-${model.uid}`}>Horsepower</Label>
                </div>
                <Input
                  required
                  id={`amm-horsepower-${model.uid}`}
                  className={styles.input}
                  value={modelVm.horsepower}
                  disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                  onChange={e => setModelVm({ ...modelVm, horsepower: e.currentTarget.value })}
                  placeholder="English"
                />
                <LimitedDataStatus
                  status={fieldStatus?.horsepower}
                  onClick={handleStatusClick('horsepower')}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                />
              </div>
              <Bed vmId={model.id} category={category} model={model}>
                <LimitedDataStatus
                  status={fieldStatus?.bed}
                  onClick={handleStatusClick('bed')}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                />
              </Bed>
              {katashikiArray.map((katashiki: string, index: number) => (
                <div className={styles.column} key={index}>
                  <div className={styles.label}>
                    <Label htmlFor={`amm-katashiki-${model.uid}`} optional>
                      Katashiki {index ? index + 1 : null}
                    </Label>
                  </div>
                  {index ? (
                    <IconTextButton
                      className={styles.katashikiDelete}
                      smallIcon
                      icon="remove"
                      text=""
                      onClick={() => removeKatashiki(index)}
                      disabled={!langPermissions[Language.EN]?.canEdit}
                    />
                  ) : null}
                  <Input
                    id={`amm-katashiki-${model.uid}-${index}`}
                    className={styles.input}
                    value={katashiki}
                    disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                    onChange={e => handleKatashikiChange(index, e.target.value)}
                    placeholder="English"
                  />
                </div>
              ))}

              {langPermissions[Language.EN]?.canEdit && !readOnly && (
                <IconTextButton
                  smallIcon
                  className={styles.katashiAdd}
                  icon="plus"
                  text="Katashiki"
                  disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                  onClick={() => setKatashikiArray(katashikiArray => [...katashikiArray, ''])}
                />
              )}
              {category === SeriesCategories.LARGE_SEDANS_VANS && (
                <div className={styles.column}>
                  <div className={styles.label}>
                    <Label htmlFor={`amm-seating-${model.uid}`} optional>
                      Seating
                    </Label>
                  </div>
                  <Input
                    id={`amm-seating-${model.uid}`}
                    className={styles.input}
                    value={modelVm.seating ?? ''}
                    disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                    onChange={e => setModelVm({ ...modelVm, seating: e.currentTarget.value })}
                    placeholder="English"
                  />
                  <LimitedDataStatus
                    status={fieldStatus?.seating}
                    onClick={handleStatusClick('seating')}
                    hideLimitedDataStatus={!displayLimitedDataStatus}
                  />
                </div>
              )}
              {showAcceptChanges && isAcceptChangesVisible && (
                <div className={styles.column}>
                  <div className={styles.label}>
                    <Label htmlFor={`amm-acceptChanges-${model.id}`}>Accept Changes</Label>
                  </div>
                  <Checkbox
                    id={`amm-acceptChanges-${model.id}`}
                    checked={modelVm.acceptChanges || false}
                    onChange={() => {
                      setModelVm({
                        ...modelVm,
                        acceptChanges: !modelVm.acceptChanges,
                      });
                    }}
                    disabled={!langPermissions[Language.ES]?.canEdit || readOnly}
                  ></Checkbox>
                </div>
              )}
              {(category === SeriesCategories.SEDANS_SPORTS_CARS ||
                category === SeriesCategories.CROSSOVERS) && <HiddenField />}
            </div>
          </div>

          {!readOnly && (
            <div className={styles.buttonSection}>
              <Button variant="transparent" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={!hasChanges()}
                onClick={() => {
                  handleOnSave();
                }}
              >
                Save Model
              </Button>
            </div>
          )}
        </section>
      </td>
    </TableRow>
  );
};

export default observer(ModelsEditPanelToyota);
