import { action, computed, observable } from 'mobx';
import {
  BRAND_LEXUS,
  BRAND_TDPR,
  BRAND_TOYOTA,
  LanguagePermissions,
  REGION_GST,
  REGION_NATIONAL,
  REGION_TDPR,
  REGION_USVI,
  Region_GST_TEAM,
  UserGroups,
  UserInfo,
  UserModules,
  UserVDPermissions,
} from '../models/user.model';
import { SeriesCategories, VehicleTeam } from '../models/vehicleData.model';

export class UserStore {
  @observable brand = '';
  @observable groups = '' as UserGroups;
  @observable modules = {
    Accessories: {},
    CommonLanguage: {},
    ProductTeam: {},
    AgencyTeam: {},
    Disclaimers: {},
    SeriesManager: {},
    SeriesSelections: {},
    Spanish: {},
    Dph: {},
  } as UserModules;
  @observable series: string[] = [];
  @observable objectId = '';
  @observable langPermissions = {} as LanguagePermissions;
  @observable teamModule = {} as UserVDPermissions;
  @observable region = REGION_NATIONAL;
  @observable dphRegions = [REGION_NATIONAL];
  @observable dphBrand = BRAND_TOYOTA;

  setTeamModule = (team: VehicleTeam) => {
    switch (team) {
      case VehicleTeam.AGENCY_SPANISH:
      case VehicleTeam.AGENCY_TEAM: {
        this.teamModule = this.modules.AgencyTeam;
        break;
      }
      case VehicleTeam.PRODUCT_TEAM: {
        this.teamModule = this.modules.ProductTeam;
        break;
      }
    }
  };

  @action setUserInfo = (userInfo: UserInfo) => {
    if (userInfo.modules.AgencyTeam.canView || userInfo.modules.ProductTeam.canView) {
      setDphPermissions(userInfo, false);
    }

    if (userInfo.modules.Accessories.teams) {
      const GSTuser =
        userInfo.modules.Accessories.teams[Region_GST_TEAM].canEdit &&
        userInfo.modules.Accessories.teams[Region_GST_TEAM].canView;
      if (GSTuser) {
        this.region = REGION_GST;
        setDphPermissions(userInfo, true);
      }
    }
    // give common language users dph permissions
    if (userInfo.modules.CommonLanguage.canEdit) {
      setDphPermissions(userInfo, true);
    }

    // VAPI-1355 temporary solution for Lexus
    if (userInfo.brand === BRAND_LEXUS) {
      if (process.env.REACT_APP_LEXUS_READONLY === 'true') {
        makeReadOnly(userInfo);
      }
      this.dphBrand = BRAND_LEXUS;
    } else if (userInfo.brand === BRAND_TDPR) {
      setTDPRPermissions(userInfo);
      // the region variable is used in 3 places: disclaimers, reports and the series manager when creating a new series
      // b/c tdpr doesnt have access to any of the screens (disclaimers and reports) or functionality (create a new series in the series manager) this variable is unused
      // however, if one of those functionalities will be added for tdpr then this variable will have to be updated
      this.region = REGION_TDPR;
      this.dphRegions = [REGION_TDPR, REGION_USVI];
      this.dphBrand = BRAND_TOYOTA;
    } else {
      this.dphBrand = BRAND_TOYOTA;
      if (this.region === REGION_GST) {
        this.dphRegions = [REGION_GST];
      } else {
        this.dphRegions = [REGION_NATIONAL];
      }
    }

    Object.assign(this, userInfo);
  };

  @computed get isTdpr() {
    return this.brand.toLowerCase() === BRAND_TDPR;
  }
}

const setDphPermissions = (userInfo: UserInfo, canEdit: boolean) => {
  userInfo.modules.Dph = { canView: true, canEdit };
};

const makeReadOnly = (userInfo: UserInfo) => {
  userInfo.modules.CommonLanguage = { canView: true };
  userInfo.modules.Disclaimers = { canView: true };
  userInfo.modules.ProductTeam = {
    canView: true,
    series: {
      [SeriesCategories.LARGE_SEDANS_VANS]: { canView: true },
      [SeriesCategories.SEDANS_SPORTS_CARS]: { canView: true },
      [SeriesCategories.CROSSOVERS]: { canView: true },
      [SeriesCategories.TRUCKS_SUVS]: { canView: true },
    },
  };
  userInfo.modules.AgencyTeam = {
    canView: true,
    series: {
      [SeriesCategories.LARGE_SEDANS_VANS]: { canView: true },
      [SeriesCategories.SEDANS_SPORTS_CARS]: { canView: true },
      [SeriesCategories.CROSSOVERS]: { canView: true },
      [SeriesCategories.TRUCKS_SUVS]: { canView: true },
    },
  };
  userInfo.modules.SeriesManager = { canView: true };
  userInfo.langPermissions = {
    EN: { canView: true },
  };
};

const setTDPRPermissions = (userInfo: UserInfo) => {
  userInfo.modules.CommonLanguage = { canView: false };
  userInfo.modules.Disclaimers = { canView: false };
  setDphPermissions(userInfo, true);
  userInfo.modules.ProductTeam = {
    canView: false,
    series: {
      [SeriesCategories.LARGE_SEDANS_VANS]: { canView: false },
      [SeriesCategories.SEDANS_SPORTS_CARS]: { canView: false },
      [SeriesCategories.CROSSOVERS]: { canView: false },
      [SeriesCategories.TRUCKS_SUVS]: { canView: false },
    },
  };
};

export default UserStore;
