import { v4 as uuidv4 } from 'uuid';
import { CategoryLangMap } from '../models/category.model';
import {
  ChangeLogBase,
  ChangeLogItem,
  ChangeLogModelItem,
  ChangeLogTypes,
  ColorApplicability,
  ColorApplicabilityLexus,
  ModelApplicabilityChanges,
  MODEL_APPLICABILITY_CHANGE_TYPES,
} from '../models/changeLog.model';
import { InteriorGroupItem, VDInteriorColorLexus } from '../models/colorsLexus.model';
import { IDValueType } from '../models/common.model';
import { RefItem } from '../models/refItem.model';
import { BRAND_LEXUS } from '../models/user.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../models/vehicleModel.model';

export const changeLogIdMapper = (
  source: any[],
  logs: ChangeLogItem[],
  changeLogType: ChangeLogTypes
) => {
  logs.forEach(log =>
    source.forEach(cat => {
      if (log.changeType === changeLogType) {
        if (log.before === cat.id) {
          log.beforeValue = cat.value || cat.name;
        }
        if (log.after === cat.id) {
          log.afterValue = cat.value || cat.name;
        }
      }
    })
  );
};

export const changeLogCategoryIdMapper = (
  source: IDValueType<CategoryLangMap>[],
  logs: ChangeLogItem[],
  changeLogType: ChangeLogTypes
) => {
  logs.forEach(log =>
    source.forEach(cat => {
      if (log.changeType === changeLogType) {
        const val = cat.value[Object.keys(cat.value)[0]].value;
        if (log.before === cat.id) {
          log.beforeValue = val;
        }
        if (log.after === cat.id) {
          log.afterValue = val;
        }
      }
    })
  );
};

interface ModelLog {
  changeType: ChangeLogTypes;
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
}

export const changeLogVehicleModelMapper = (
  source: any[],
  logs: ModelLog[],
  changeLogType: ChangeLogTypes
) => {
  logs.forEach(log =>
    source.forEach(cat => {
      if (log.changeType === changeLogType) {
        if (log.before === cat.id) {
          log.beforeValue = cat.value;
        }
        if (log.after === cat.id) {
          log.afterValue = cat.value;
        }
      }
    })
  );
};

export const changeLogVehicleModelGradesMapper = (
  source: any[],
  logs: ChangeLogModelItem[],
  brand: string
) => {
  if (brand === BRAND_LEXUS) {
    logs.forEach(log => {
      log.beforeValue = log.before;
      log.afterValue = log.after;
    });
  } else {
    changeLogVehicleModelMapper(source, logs, ChangeLogTypes.GRADE);
  }
};

export const changeLogFuelTypeMapper = (
  source: any[],
  logs: ChangeLogModelItem[],
  changeLogType: ChangeLogTypes
) => {
  logs.forEach(log =>
    source.forEach(cat => {
      if (log.changeType === changeLogType) {
        if (log.before === cat.id) {
          log.beforeValue = cat.type;
        }
        if (log.after === cat.id) {
          log.afterValue = cat.type;
        }
      }
    })
  );
};

export const changeLogIdArrayMapper = (
  source: any[],
  logs: ChangeLogItem[],
  changeLogType: ChangeLogTypes
) => {
  const helperFunction = (fromArray: any, toArray: string[]) => {
    if (Array.isArray(fromArray)) {
      fromArray.forEach((item: string) => {
        const value = source.find(srcItem => srcItem.id === item);
        toArray.push(value ? value.value : '');
      });
    }
  };

  logs.forEach(log => {
    if (log.changeType === changeLogType) {
      helperFunction(log.before, log.beforeValues);
      helperFunction(log.after, log.afterValues);
    }
  });
};

export const changeLogHandleDeleteTypes = (
  logs: ChangeLogItem[],
  changeLogType: ChangeLogTypes
) => {
  logs.forEach(log => {
    if (log.changeType === changeLogType) {
      log.before = log.description;
    }
  });
};

export const changeLogHandleAddedTypes = (logs: ChangeLogItem[], changeLogType: ChangeLogTypes) => {
  logs.forEach(log => {
    if (log.changeType === changeLogType) {
      log.before = log.description;
      log.description = log.name;
    }
  });
};

export const changeLogColorApplicabilityMapper = (
  source: any[],
  logs: ChangeLogItem[],
  changeLogType: ChangeLogTypes,
  grades: RefItem[]
) => {
  logs.forEach(log => {
    if (log.changeType === changeLogType) {
      modifyLogExtColorApp('before', log, source, grades);
      modifyLogExtColorApp('after', log, source, grades);
    }
  });
};

const modifyLogExtColorApp = (
  type: 'before' | 'after',
  log: ChangeLogBase,
  source: any[],
  grades: RefItem[]
) => {
  const otherType = type === 'before' ? 'after' : 'before';
  const targetArray = log[type];
  const colorApp = type === 'before' ? 'extColorAppBefore' : 'extColorAppAfter';
  if (Array.isArray(targetArray) && targetArray.length) {
    targetArray.forEach((item: ColorApplicability) => {
      const otherLogType = log[otherType];
      if (Array.isArray(otherLogType)) {
        const index = otherLogType.findIndex(
          (otherItem: ColorApplicability) =>
            item.grade === otherItem.grade && item.interiorColorId === otherItem.interiorColorId
        );
        if (index === -1) {
          // item is removed, add it to before
          // find the interior color id in the source
          const intColor = source.find(color => color.id === item.interiorColorId);
          const logExtColorApp = log[colorApp];
          if (intColor && logExtColorApp) {
            const gradeValue = `${getGradeValue(item.grade, grades)} /`;
            const interiorType = intColor.interiorType ? ` ${intColor.interiorType} /` : '';
            const interiorColorName = ` ${intColor.name} `;
            logExtColorApp.push(`${gradeValue}${interiorType}${interiorColorName}`);
          }
        }
      }
    });
  }
};

const getGradeValue = (gradeId: string, grades: RefItem[]): string => {
  return grades.find(grade => grade.id === gradeId)?.value || gradeId;
};

export const changeLogLexusColorApplicabilityMapper = (
  source: VDInteriorColorLexus[],
  logs: ChangeLogItem[],
  changeLogType: ChangeLogTypes,
  groups: InteriorGroupItem[]
) => {
  logs.forEach(log => {
    if (log.changeType === changeLogType) {
      changeLogLexusColorApplicabilityHelperFunction(
        source,
        groups,
        log,
        log.before,
        log.after,
        log.extColorAppBefore
      );
      changeLogLexusColorApplicabilityHelperFunction(
        source,
        groups,
        log,
        log.after,
        log.before,
        log.extColorAppAfter
      );
    }
  });
};

export const changeLogLexusColorApplicabilityHelperFunction = (
  source: VDInteriorColorLexus[],
  groups: InteriorGroupItem[],
  log: ChangeLogItem,
  firstArray: any,
  secondArray: any,
  destinationArray: string[]
) => {
  if (Array.isArray(firstArray) && firstArray.length) {
    firstArray.forEach((firstItem: ColorApplicabilityLexus) => {
      if (Array.isArray(secondArray)) {
        const index = secondArray.findIndex(
          (secondItem: ColorApplicabilityLexus) =>
            firstItem.groupId === secondItem.groupId &&
            firstItem.interiorColorId === secondItem.interiorColorId
        );
        if (index === -1) {
          // item is removed, add it to before
          // find the interior color id in the source
          const intColor = source.find(color => color.id === firstItem.interiorColorId);
          const groupItem = groups.find(group => group.id === firstItem.groupId);
          if (intColor) {
            destinationArray.push(
              `${groupItem ? groupItem.name : ''} / ${intColor.material} / ${intColor.interiorType}`
            );
          }
        }
      }
    });
  }
};

export const changeLogModelApplicabilityMapper = (
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  logs: ChangeLogBase[]
) => {
  logs.forEach(log => {
    changeLogModelApplicabilityItemMapper(models, log);
  });
};

export const changeLogModelApplicabilityItemMapper = (
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  log: ChangeLogBase
) => {
  if (MODEL_APPLICABILITY_CHANGE_TYPES.includes(log.changeType)) {
    const before: any = log.before;
    const after: any = log.after;
    const modelIds: Set<string> = getModelIdsForModelApplicabilityItemWrapper(log);

    log.applicabilityChanges = [] as ModelApplicabilityChanges[];
    // loop thru the model ids and build the changes
    for (const id of Array.from(modelIds)) {
      const model = models.find(item => item.id === id);
      if (model) {
        const applicabilityChange = log.applicabilityChanges.find(
          changes => changes.title === model.getVal('grade')
        );
        if (applicabilityChange) {
          modifyModelApplicabilityItemWrapperApplicabilityChange(
            applicabilityChange,
            model,
            id,
            before,
            after,
            models
          );
        } else {
          createApplicabilityChangeForModelApplicabilityItemWrapper(
            log,
            model,
            id,
            before,
            after,
            models
          );
        }
      }
    }
  }
};

const getModelIdsForModelApplicabilityItemWrapper = (log: ChangeLogBase) => {
  const before: any = log.before;
  const after: any = log.after;
  const modelIds: Set<string> = new Set<string>();
  if (before && Object.keys(before).length) {
    if (log.changeType === ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY) {
      Object.values(before).forEach(beforeValue => {
        if (beforeValue instanceof Object) {
          Object.keys(beforeValue).forEach(key => modelIds.add(key));
        }
      });
    } else {
      Object.keys(before).forEach(key => modelIds.add(key));
    }
  }
  if (after && Object.keys(after).length) {
    if (log.changeType === ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY) {
      Object.values(after).forEach(afterValue => {
        if (afterValue instanceof Object) {
          Object.keys(afterValue).forEach(key => modelIds.add(key));
        }
      });
    } else {
      Object.keys(after).forEach(key => modelIds.add(key));
    }
  }
  return modelIds;
};

const modifyModelApplicabilityItemWrapperApplicabilityChange = (
  applicabilityChange: ModelApplicabilityChanges,
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>,
  id: string,
  before: any,
  after: any,
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  const beforeChange =
    before[model.getVal('grade')] &&
    Object.keys(before[model.getVal('grade')]).find(change => change === id);
  const afterChange =
    after[model.getVal('grade')] &&
    Object.keys(after[model.getVal('grade')]).find(change => change === id);

  if (beforeChange) {
    const findCode = models.find(model => model.id === beforeChange)?.getVal('code');
    applicabilityChange.before = applicabilityChange.before
      ? `${applicabilityChange.before},${findCode}`
      : findCode;
  }
  if (afterChange) {
    const findCode = models.find(model => model.id === afterChange)?.getVal('code');
    applicabilityChange.after = applicabilityChange.after
      ? `${applicabilityChange.after},${findCode}`
      : findCode;
  }
};

const createApplicabilityChangeForModelApplicabilityItemWrapper = (
  log: ChangeLogBase,
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>,
  id: string,
  before: any,
  after: any,
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  if (!log.applicabilityChanges) {
    return;
  }
  const modelChange: ModelApplicabilityChanges = {
    title:
      log.changeType === ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY
        ? model.getVal('grade').value
        : model.getVal('modalRowInfo'),
    before:
      log.changeType === ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY
        ? before && before[model.getVal('grade').id]
          ? assignCode(before[model.getVal('grade').id], id, models)
          : ''
        : before && before[id]
        ? before[id]
        : '',
    after:
      log.changeType === ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY
        ? after && after[model.getVal('grade').id]
          ? assignCode(after[model.getVal('grade').id], id, models)
          : ''
        : after && after[id]
        ? after[id]
        : '',
  };
  if (modelChange.before !== modelChange.after) {
    log.applicabilityChanges.push(modelChange);
  }
};

const assignCode = (
  changes: any,
  id: string,
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  const findChange = Object.keys(changes).find(change => change === id) || '';

  return models.find(model => model.id === findChange)?.getVal('code') || '';
};

export const changeLogLexusGroupApplicabilityMapper = (
  groups: InteriorGroupItem[],
  logs: ChangeLogBase[],
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  logs.forEach(log => {
    changeLogLexusGroupApplicabilityItemMapper(groups, log, models);
  });
};

const changeLogLexusGroupApplicabilityItemMapper = (
  groups: InteriorGroupItem[],
  log: ChangeLogBase,
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  if (log.changeType === ChangeLogTypes.INT_COLOR_GROUP_APPLICABILITY) {
    const modelIds: Set<string> = getModelIdsForLexusGroupItemMapper(log);
    const before: any = log.before;
    const after: any = log.after;

    log.applicabilityChanges = [] as ModelApplicabilityChanges[];

    for (const id of Array.from(modelIds)) {
      groups.forEach(group => {
        if (Object.keys(group.models).find(key => key === id)) {
          const applicabilityChange =
            log.applicabilityChanges &&
            log.applicabilityChanges.find(changes => changes.title === group.name);
          if (applicabilityChange) {
            modifyLexusGroupItemMapperApplicabilityChange(
              'before',
              before,
              applicabilityChange,
              group,
              id,
              models
            );
            modifyLexusGroupItemMapperApplicabilityChange(
              'after',
              after,
              applicabilityChange,
              group,
              id,
              models
            );
          } else if (log.applicabilityChanges) {
            log.applicabilityChanges.push(
              createApplicabilityChangeForLexusGroupItemMapper(id, group, before, after, models)
            );
          }
        }
      });
    }
  }
};

export const changeLogColorApplicabilityStatusMapper = (
  grades: RefItem[],
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  logs: ChangeLogBase[]
) => {
  logs.forEach(log => {
    if (log.changeType === ChangeLogTypes.MODEL_APPLICABILITY_STATUS) {
      const after: any = log.after;
      const before: any = log.before;

      if (!log.applicabilityChanges) {
        log.applicabilityChanges = [] as ModelApplicabilityChanges[];
      }

      Object.keys(after).forEach((gradeId: string) => {
        Object.keys(after[gradeId]).forEach(modelId => {
          if (after[gradeId][modelId] !== before[gradeId][modelId]) {
            const title = `${grades.find(grade => grade.id === gradeId)?.value} / ${
              models.find(model => model.id === modelId)?.getVal('code') || ''
            }`;

            const modelChange: ModelApplicabilityChanges = {
              title,
              before: before[gradeId][modelId],
              after: after[gradeId][modelId],
            };

            let applicabilityChange = log.applicabilityChanges?.find(
              changes => changes.title === title
            );

            if (applicabilityChange) {
              applicabilityChange = modelChange;
            } else if (log.applicabilityChanges) {
              log.applicabilityChanges.push(modelChange);
            }
          }
        });
      });
    }
  });
};

const getModelIdsForLexusGroupItemMapper = (log: ChangeLogBase) => {
  const before: any = log.before;
  const after: any = log.after;
  const modelIds: Set<string> = new Set<string>();

  if (before && Object.keys(before).length) {
    Object.values(before).forEach(beforeValue => {
      if (beforeValue instanceof Object) {
        Object.keys(beforeValue).forEach(key => modelIds.add(key));
      }
    });
  }
  if (after && Object.keys(after).length) {
    Object.values(after).forEach(afterValue => {
      if (afterValue instanceof Object) {
        Object.keys(afterValue).forEach(key => modelIds.add(key));
      }
    });
  }
  return modelIds;
};

const modifyLexusGroupItemMapperApplicabilityChange = (
  type: 'before' | 'after',
  target: any,
  applicabilityChange: ModelApplicabilityChanges,
  group: InteriorGroupItem,
  id: string,
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  const change = target[group.id] && Object.keys(target[group.id]).find(change => change === id);
  if (change) {
    const findCode = models.find(model => model.id === change)?.getVal('code');
    applicabilityChange[type] = applicabilityChange[type]
      ? `${applicabilityChange[type]},${findCode}`
      : findCode;
  }
};

const createApplicabilityChangeForLexusGroupItemMapper = (
  id: string,
  group: InteriorGroupItem,
  before: any,
  after: any,
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  const modelChange: ModelApplicabilityChanges = {
    title: group.name,
    before: before && before[group.id] ? assignCode(before[group.id], id, models) : '',
    after: after && after[group.id] ? assignCode(after[group.id], id, models) : '',
  };
  return modelChange;
};

export const groupModelMapper = (
  logs: ChangeLogBase[],
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  logs.forEach(log => {
    if (log.changeType === ChangeLogTypes.GROUP_MODELS_CHANGED) {
      const before: any = log.before;
      const after: any = log.after;
      const modelIds: Set<string> = getModelIdsForGroupModelMapper(log);

      log.applicabilityChanges = [] as ModelApplicabilityChanges[];

      for (const id of Array.from(modelIds)) {
        createApplicabilityChangeForGroupModelMapper(log, id, before, after, models);
      }
    }
  });
};

const getModelIdsForGroupModelMapper = (log: ChangeLogBase) => {
  const before: any = log.before;
  const after: any = log.after;
  const modelIds: Set<string> = new Set<string>();

  if (before && Object.keys(before).length) {
    Object.keys(before).forEach(key => modelIds.add(key));
  }
  if (after && Object.keys(after).length) {
    Object.keys(after).forEach(key => modelIds.add(key));
  }
  return modelIds;
};

const createApplicabilityChangeForGroupModelMapper = (
  log: ChangeLogBase,
  id: string,
  before: any,
  after: any,
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  const model = models.find(item => item.id === id);
  if (model) {
    const modelChange: ModelApplicabilityChanges = {
      title: '',
      before: before && before[model.id] ? model.getVal('code') : '',
      after: after && after[model.id] ? model.getVal('code') : '',
    };

    if (modelChange.before !== modelChange.after && log.applicabilityChanges) {
      log.applicabilityChanges.push(modelChange);
    }
  }
};

export const changeLogGradeApplicabilityMapper = (logs: ChangeLogItem[]) => {
  const filterGradeApplicabilities = logs.filter(
    log => log.changeType === ChangeLogTypes.GRADE_APPLICABILITY
  );

  filterGradeApplicabilities.forEach(applicability => {
    const afterCopy = JSON.parse(JSON.stringify(applicability.after));
    const beforeCopy = JSON.parse(JSON.stringify(applicability.before));
    Object.keys(afterCopy).forEach(key => {
      const uid = uuidv4();
      afterCopy[key].applicabilityText = {};
      afterCopy[key].applicabilityText[uid] = {};
      afterCopy[key].applicabilityText[uid].text = afterCopy[key].text;
      afterCopy[key].applicabilityText[uid].availability = afterCopy[key].applicability;
      delete afterCopy[key].text;
      delete afterCopy[key].applicability;
    });
    applicability.after = afterCopy;

    Object.keys(beforeCopy).forEach(key => {
      const uid = uuidv4();
      beforeCopy[key].applicabilityText = {};
      beforeCopy[key].applicabilityText[uid] = {};
      beforeCopy[key].applicabilityText[uid].text = beforeCopy[key].text;
      beforeCopy[key].applicabilityText[uid].availability = beforeCopy[key].applicability;
      delete beforeCopy[key].text;
      delete beforeCopy[key].applicability;
    });
    applicability.before = beforeCopy;
  });
};

export const changeLogBnpStatusMapper = (logs: ChangeLogItem[]) => {
  logs.forEach(log => {
    switch (log.changeType) {
      case ChangeLogTypes.BEDS_STATUS:
      case ChangeLogTypes.CABS_STATUS:
      case ChangeLogTypes.DRIVE_STATUS:
      case ChangeLogTypes.ENGINE_STATUS:
      case ChangeLogTypes.GRADE_STATUS:
      case ChangeLogTypes.SEATS_STATUS:
      case ChangeLogTypes.TRANSMISSION_STATUS:
        log.description = `${ChangeLogTypes.PUBLISHED_STATUS}: ${log.changeType.replace(
          'Status',
          ''
        )}`;
        break;
      default:
        return;
    }
  });
};

export const changeLogCompareFeatureApplicabilityStatusMapper = (
  grades: RefItem[],
  logs: ChangeLogBase[]
) => {
  logs.forEach((log: ChangeLogBase) => {
    if (log.changeType === ChangeLogTypes.GRADE_APPLICABILITY_STATUS) {
      const after: any = log.after;
      const before: any = log.before;
      if (!log.applicabilityChanges) {
        log.applicabilityChanges = [] as ModelApplicabilityChanges[];
      }

      Object.keys(after).forEach((gradeId: string) => {
        if (after[gradeId] !== before[gradeId]) {
          const title = grades.find(grade => grade.id === gradeId)?.value;

          const appChange: ModelApplicabilityChanges = {
            title,
            before: before[gradeId],
            after: after[gradeId],
          };

          let applicabilityChange = log.applicabilityChanges?.find(
            changes => changes.title === title
          );

          if (applicabilityChange) {
            applicabilityChange = appChange;
          } else if (log.applicabilityChanges) {
            log.applicabilityChanges.push(appChange);
          }
        }
      });
    }
  });
};
