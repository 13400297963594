import cx from 'clsx';
import React from 'react';
import {
  CompareGradeApplicability,
  CompareGradeApplicabilityMap,
} from '../../../../../../models/compareFeatures.model';
import { FeatureSettings } from '../../../../../../models/features.model';
import { ReviewChangeMap } from '../../../../../../models/review.model';
import styles from './gradeApplicabilityReviewCell.module.scss';

interface IGradeApplicabilityReviewCell {
  gradeId: string;
  map: ReviewChangeMap<CompareGradeApplicabilityMap>;
  isNew: boolean;
  rowHeight: number;
  isDeleted: boolean;
}

const GradeApplicabilityReviewCell = ({
  gradeId,
  map,
  isNew,
  rowHeight,
  isDeleted,
}: IGradeApplicabilityReviewCell) => {
  const addedClass = styles.greenText;
  const removedClass = styles.redText;
  const hasChanged = map.hasChanged;

  const defaultGradeApplicability: CompareGradeApplicability = {
    highlighted: false,
    text: '',
    applicability: FeatureSettings.UNDEFINED,
  };

  const before =
    map.before[gradeId] && Object.keys(map.before[gradeId]).length > 0
      ? map.before[gradeId]
      : defaultGradeApplicability;
  const after =
    map.after[gradeId] && Object.keys(map.after[gradeId]).length > 0
      ? map.after[gradeId]
      : defaultGradeApplicability;
  if (!after.applicability) {
    after.applicability = FeatureSettings.UNDEFINED;
  }
  if (!before.applicability) {
    before.applicability = FeatureSettings.UNDEFINED;
  }

  const highlightedRow = (highlighted: boolean, className: string) => {
    return (
      <div className={cx(styles.autoMargin, className)}>
        {highlighted ? 'Highlighted' : 'Not Highlighted'}
      </div>
    );
  };

  const applicabilityRow = (
    applicability: FeatureSettings | null,
    className: string,
    defaultValue: string = '--'
  ) => {
    return (
      <div className={cx(styles.autoMargin, className)}>
        {applicability?.length ? applicability : defaultValue}
      </div>
    );
  };

  const textRow = (text: string | null | undefined, className: string) => {
    return <div className={cx(styles.autoMargin, className)}>{text?.length ? text : ''}</div>;
  };

  return (
    <td
      className={styles.td}
      style={{ height: rowHeight > 0 ? rowHeight : '' }}
      key={gradeId}
      rowSpan={1}
    >
      {(isDeleted || (!isNew && hasChanged && before.highlighted !== after.highlighted)) &&
        highlightedRow(before.highlighted, removedClass)}
      {!isDeleted &&
        highlightedRow(
          after.highlighted,
          isNew || (hasChanged && before.highlighted !== after.highlighted) ? addedClass : ''
        )}
      {(isDeleted ||
        (!isNew &&
          hasChanged &&
          before.applicability &&
          before.applicability !== after.applicability)) &&
        applicabilityRow(before.applicability, removedClass, '')}
      {!isDeleted &&
        applicabilityRow(
          after.applicability,
          isNew || (hasChanged && before.applicability !== after.applicability) ? addedClass : ''
        )}
      {(isDeleted || (!isNew && hasChanged && before.text && before.text !== after.text)) &&
        textRow(before.text, removedClass)}
      {!isDeleted &&
        textRow(after.text, isNew || (hasChanged && before.text !== after.text) ? addedClass : '')}
    </td>
  );
};

export default GradeApplicabilityReviewCell;
