import { observer } from 'mobx-react-lite';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';
import {
  GO_LIVE_DATE_TIME,
  GoLiveDateTime,
  goLiveDateTimeMap,
} from '../../../../../../utils/dateUtils';
import styles from '../../modelsModal.module.scss';

interface IConfirmAllGoLiveDateModal {
  close: () => void;
  //   onSubmit: (goLiveDate: string) => void;
  goLiveDate: string;
  onUpdateAllGoLiveDates: (date: string) => Promise<void>;
  timePicker?: boolean;
}

const ConfirmAllGoLiveDateModal = observer(
  ({ close, goLiveDate, onUpdateAllGoLiveDates, timePicker }: IConfirmAllGoLiveDateModal) => {
    const GoLiveDateText = (): JSX.Element => {
      const gld = new Date(goLiveDate);
      const time: GoLiveDateTime = goLiveDateTimeMap[gld.getHours() as GO_LIVE_DATE_TIME];

      return (
        <>
          <span>
            Are you sure you want to set the go live date {timePicker && ' & time'} for all models
            to
          </span>
          <span className={styles.goLiveDateConfirmInfo}>{gld.toLocaleDateString()}</span>
          {timePicker && <span className={styles.goLiveDateConfirmInfo}>{`${time.text} CST`}</span>}
          <span>?</span>
        </>
      );
    };

    return (
      <>
        <ModalHeader onClose={close}>Set Go Live Date for All Models</ModalHeader>
        <ModalBody>
          <GoLiveDateText />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="transparent"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
          {/* <Button
            variant="primary"
            onClick={() => {
              close();
            }}
          >
            Send to Preview
          </Button> */}
          {/* <Button
            variant="primary"
            onClick={() => {
              close();
            }}
          >
            Publish Now
          </Button> */}
          <Button
            variant="primary"
            onClick={() => {
              onUpdateAllGoLiveDates(goLiveDate);
              close();
            }}
          >
            Confirm Go Live Date {timePicker && ' & Time'}
          </Button>
        </ModalFooter>
      </>
    );
  }
);

export default ConfirmAllGoLiveDateModal;
