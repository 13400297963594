import { HeaderRow, LeftTableTH, Thead } from '../../../../../../components/Table';
import { observer } from 'mobx-react-lite';
import React from 'react';
import useStores from '../../../../../../hooks/useStores';
import inputStyles from '../../../../../../components/Input/input.module.scss';

type OptionsLexusHeaderRowProps = {
  viewModelCodes: boolean;
  readOnly?: boolean;
  sortMode?: boolean;
  onSort?: (field: string) => void;
};
const OptionsLexusHeaderRow = ({
  readOnly = false,
  sortMode,
  onSort = () => {},
}: OptionsLexusHeaderRowProps) => {
  const {
    tableSizeStore: { tableRowHeight },
  } = useStores();

  return (
    <Thead>
      <HeaderRow
        style={{
          height: `${tableRowHeight}px`,
        }}
      >
        {sortMode && <LeftTableTH />}        
        {sortMode && (<LeftTableTH className={inputStyles.smallInputHeader}>#</LeftTableTH>)}
        <LeftTableTH onClick={() => onSort('name')}>Name</LeftTableTH>
        <LeftTableTH onClick={() => onSort('code')}>Code</LeftTableTH>
        <LeftTableTH>TOMS</LeftTableTH>
        <LeftTableTH>SMS</LeftTableTH>
        <LeftTableTH>Specs</LeftTableTH>
        {/* Action Buttons */}
        <LeftTableTH></LeftTableTH>
        <LeftTableTH>Required</LeftTableTH>
        <LeftTableTH>Conflicts</LeftTableTH>
      </HeaderRow>
    </Thead>
  );
};

export default observer(OptionsLexusHeaderRow);
