import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import styles from './input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDebounce?: () => void;
  required?: boolean;
  maxLength?: number;
  isInvalid?: boolean;
  validate?: (value: string) => void;
}

const Input = React.forwardRef((props: InputProps, ref?: React.Ref<HTMLInputElement>) => {
  const {
    className,
    onChange,
    onDebounce,
    onBlur,
    required,
    value,
    maxLength,
    isInvalid,
    validate,
    defaultValue,
    ...rest
  } = props;
  const [hasError, setHasError] = useState<boolean>(false);
  const { debounce } = useDebounce();

  useEffect(() => {
    const currentValue = value ?? defaultValue;
    let isValid = true;

    if (required && !currentValue) {
      isValid = false;
      setHasError(true);
    }

    if (isValid && validate) {
      validate(currentValue && typeof currentValue === 'string' ? currentValue.toString() : '');
    }
    // Need to only run this once when component gets mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInvalid !== undefined) {
      setHasError(isInvalid);
    }
  }, [isInvalid]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (required) {
      setHasError(!event.target.value);
    }

    if (validate) {
      validate(event.currentTarget.value);
    }
    if (onChange) {
      onChange(event);
    }
    if (onDebounce) {
      debounce(() => {
        onDebounce();
      });
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.trim();
    if (onBlur) {
      onBlur(event);
    }
  };

  const getCssClass = () => {
    return cx(styles.input, hasError && styles.error, className);
  };

  return (
    <>
      <input
        data-testid="input-component"
        ref={ref}
        value={value}
        defaultValue={defaultValue}
        type="text"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        className={getCssClass()}
        maxLength={maxLength}
        {...rest}
      />
    </>
  );
});

export default Input;
