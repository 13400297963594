import { action, observable } from 'mobx';
import { OptionsReviewResponse } from '../../../models/options.model';
import { VDPackageLexus } from '../../../models/optionsLexus.model';
import type { ReviewProps } from '../../../models/review.model';
import { BRAND_LEXUS, BRAND_TOYOTA } from '../../../models/user.model';
import {
  getReviewExteriorColorsToyota,
  getReviewInteriorColorsToyota,
} from '../../../webservices/vehicleColorsApi';
import { getReviewCompareFeatures } from '../../../webservices/vehicleCompareFeaturesApi';
import { getReviewCLFeatures, getReviewFeatures } from '../../../webservices/vehicleFeaturesApi';
import { getReviewModels, getReviewSeriesSettings } from '../../../webservices/vehicleModelsApi';
import { getReviewOptions } from '../../../webservices/vehicleOptionsApi';
import { getReviewCLSpec, getReviewSpec } from '../../../webservices/vehicleSpecsApi';
import { getReviewItems } from '../../../webservices/vehicleBnPApi';
import { toGqlBrand, toGqlFilter, toGqlTeam } from '../../../utils/graphqlUtils';
import { Status } from 'vapi-ui-common';
import { ModelsReviewResponse } from '../../../models/vehicleModel.model';

class ReviewStore {
  @observable selectedTab = 'Features';
  @observable hideAccept = false;

  @action async fetchData(props: ReviewProps) {
    let optionsResponse;
    const { brand, team, series, year, version } = props;
    const [
      modelsReview,
      featuresReview,
      specReview,
      exteriorColorsReview,
      interiorColorsReview,
      compareFeaturesReview,
      seriesSettingsReview,
      bnpReview,
    ] = await Promise.all([
      getReviewModels({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId: series,
        modelYear: Number(year),
        filter: toGqlFilter(version ?? Status.DRAFT),
      }),
      getReviewFeatures(brand, team, series, year, version),
      getReviewSpec(brand, team, series, year, version),
      getReviewExteriorColorsToyota({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId: series,
        modelYear: Number(year),
        filter: toGqlFilter(version ?? Status.DRAFT),
      }),
      getReviewInteriorColorsToyota({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId: series,
        modelYear: Number(year),
        filter: toGqlFilter(version ?? Status.DRAFT),
      }),
      getReviewCompareFeatures(brand, team, series, year, version),
      getReviewSeriesSettings({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId: series,
        modelYear: Number(year),
        filter: toGqlFilter(version ?? Status.DRAFT),
      }),
      getReviewItems({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId: series,
        modelYear: parseInt(year),
        filter: toGqlFilter(version),
      }),
    ]);

    if (brand === BRAND_LEXUS) {
      optionsResponse = await Promise.all([
        getReviewOptions<VDPackageLexus>({
          brand: toGqlBrand(brand),
          team: toGqlTeam(team),
          seriesId: series,
          modelYear: parseInt(year),
          filter: toGqlFilter(version),
          optionTab: 'packages',
        }),
        getReviewOptions<VDPackageLexus>({
          brand: toGqlBrand(brand),
          team: toGqlTeam(team),
          seriesId: series,
          modelYear: parseInt(year),
          filter: toGqlFilter(version),
          optionTab: 'options',
        }),
      ]);
    } else {
      optionsResponse = await Promise.all([
        getReviewOptions<OptionsReviewResponse>({
          brand: toGqlBrand(brand),
          team: toGqlTeam(team),
          seriesId: series,
          modelYear: parseInt(year),
          filter: toGqlFilter(version),
        }),
      ]);
    }

    return {
      models: modelsReview as ModelsReviewResponse[],
      features: featuresReview.data,
      specs: specReview.data,
      options: brand === BRAND_TOYOTA ? optionsResponse[0] : [],
      optionsOptionLexus: brand === BRAND_LEXUS ? optionsResponse[1]! : [],
      optionsPackagesLexus: brand === BRAND_LEXUS ? optionsResponse[0] : [],
      extColors: exteriorColorsReview,
      intColors: interiorColorsReview,
      compareFeatures: compareFeaturesReview.data,
      seriesSettings: seriesSettingsReview,
      bnp: bnpReview,
    };
  }

  @action async fetchDataCL(props: ReviewProps) {
    const { brand, team, series, year, version } = props;
    const [clFeaturesReview, clSpecReview] = await Promise.all([
      getReviewCLFeatures(brand, team, series, year, version),
      getReviewCLSpec(brand, team, series, year, version),
    ]);

    return {
      features: clFeaturesReview.data,
      specs: clSpecReview.data,
    };
  }
}

export default ReviewStore;
