import cx from 'clsx';
import React, { useCallback } from 'react';
import { Checkbox } from 'vapi-ui-common';
import useStores from '../../../../../../hooks/useStores';
import { BRAND_LEXUS } from '../../../../../../models/user.model';
import styles from '../../seriesSettings.module.scss';

interface ConvertibleColumnProps {
  readOnly?: boolean;
  dataTestId: string;
  convertible: boolean;
  setConvertible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConvertibleColumn = ({
  readOnly,
  dataTestId,
  convertible,
  setConvertible = () => undefined,
}: ConvertibleColumnProps) => {
  const { userStore } = useStores();
  const isLexus = userStore.brand === BRAND_LEXUS;

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      !readOnly && setConvertible(e.currentTarget.checked),
    [setConvertible, readOnly]
  );

  if (!isLexus) return null;

  if (!convertible && readOnly) {
    return (
      <td>
        <span className={styles.isConvertibleChboxReadOnly}>--</span>
      </td>
    );
  }

  return (
    <td>
      <Checkbox
        id={dataTestId}
        data-testid={dataTestId}
        checked={convertible}
        disabled={readOnly}
        className={cx({
          [styles.isConvertibleChbox]: !readOnly,
          [styles.isConvertibleChboxReadOnly]: readOnly,
        })}
        onChange={onChange}
      />
    </td>
  );
};

export default ConvertibleColumn;
