import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import { RefItemLanguageMap } from '../models/category.model';
import { ChangeLogRequest, ChangeLogResponse } from '../models/changeLog.model';
import { RefItemResponse } from '../models/refItem.model';
import {
  SpecFieldStatusRequest,
  SpecRequest,
  SpecResponse,
  SpecsReviewRequest,
  SpecsReviewResponse,
} from '../models/specs.model';
import { Language } from '../models/user.model';
import { VehicleTeam } from '../models/vehicleData.model';
import {
  ModelFieldStatus,
  UpdateAllModelStatusesRequest,
  UpdateAllModelStatusesResponse,
} from '../models/vehicleModel.model';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-specs';

const VEHICLE_SPECS_PATH = '/specs';
export const getVehicleSpecs = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<SpecResponse[]>(
    `${RESOURCE_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}/${language}?${params}`
  );
};

export const addVehicleSpec = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  payload: SpecRequest
) => {
  return API.post<SpecResponse>(
    `${RESOURCE_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}/${language.toUpperCase()}`,
    payload
  );
};

export const updateVehicleSpec = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: SpecRequest,
  language: string,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false
) => {
  return API.put<SpecResponse>(
    `${RESOURCE_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}/${language.toUpperCase()}`,
    { ...payload, acceptChanges: acceptChanges || unlinkFromTMNA, unlinkFromTMNA }
  );
};

export const deleteVehicleSpec = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  specId: string
) => {
  return API.delete<SpecResponse>(
    `${RESOURCE_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}/${language.toUpperCase()}/${specId}`
  );
};

export const importFromCommonLanguage = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: string[]
) => {
  return API.post<SpecResponse[]>(
    `${RESOURCE_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}/import`,
    { commonLangIds: payload }
  );
};

// ChangeLog
export const getChangeLog = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  version: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<ChangeLogResponse[]>(
    `${RESOURCE_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}/${language.toUpperCase()}/change-log?${params}`
  );
};

export const revertChange = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  payload: ChangeLogRequest
) => {
  return API.put(
    `${RESOURCE_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}/${language.toUpperCase()}/change-log/revert`,
    payload
  );
};

// Categories
const CATEGORIES_PATH = '/categories';
export const getCategories = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<RefItemResponse[]>(
    `${RESOURCE_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}?${params}`
  );
};

export const getCategoriesByLang = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<RefItemResponse[]>(
    `${RESOURCE_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}/${language.toUpperCase()}?${params}`
  );
};

export const addCategories = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: { [lang: string]: string },
  id?: string
) => {
  const body: any = {
    categories: payload,
  };
  if (id) {
    body.id = id;
  }
  return API.post<RefItemLanguageMap>(
    `${RESOURCE_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}`,
    body
  );
};

export const updateCategory = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  id: string,
  name: string
) => {
  return API.put(
    `${RESOURCE_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}/${language.toUpperCase()}/${id}`,
    {
      name,
    }
  );
};

// Spec Types
const SPEC_TYPES_PATH = '/spec-types';
export const getSpecTypes = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<RefItemResponse[]>(
    `${RESOURCE_PATH}${SPEC_TYPES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}?${params}`
  );
};

export const getSpecTypesByLang = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<RefItemResponse[]>(
    `${RESOURCE_PATH}${SPEC_TYPES_PATH}/${brand}/${team}/${series}/${year}/${language.toUpperCase()}?${params}`
  );
};

export const addSpecTypes = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: { [lang: string]: string },
  id?: string
) => {
  const body: any = {
    specTypes: payload,
  };
  if (id) {
    body.id = id;
  }
  return API.post<RefItemLanguageMap>(
    `${RESOURCE_PATH}${SPEC_TYPES_PATH}/${brand}/${team}/${series}/${year}`,
    body
  );
};

export const updateSpecType = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  id: string,
  name: string
) => {
  return API.put(
    `${RESOURCE_PATH}${SPEC_TYPES_PATH}/${brand}/${team}/${series}/${year}/${language.toUpperCase()}/${id}`,
    {
      name,
    }
  );
};

// Review
const REVIEW_PATH = '/review';
export const getReviewCategories = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<RefItemResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const getReviewSpecTypes = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<RefItemResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${SPEC_TYPES_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const getReviewSpec = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<SpecsReviewResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const updateReviewSpec = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version: string,
  payload: SpecsReviewRequest
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<SpecsReviewRequest>(
    `${RESOURCE_PATH}${REVIEW_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}?${params}`,
    payload
  );
};

// Review Common Language
const REVIEW_CL_PATH = '/review-cl';
export const getReviewCLSpec = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<SpecsReviewResponse[]>(
    `${RESOURCE_PATH}${REVIEW_CL_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const updateReviewCLSpec = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version: string,
  payload: SpecsReviewRequest
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<SpecsReviewRequest>(
    `${RESOURCE_PATH}${REVIEW_CL_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}?${params}`,
    payload
  );
};

export const updateSpecFeatureStatus = (
  brand: string,
  team: VehicleTeam,
  serieId: string,
  modelYear: string,
  language: Language,
  payload: SpecFieldStatusRequest
) => {
  return API.put<ModelFieldStatus>(
    `${RESOURCE_PATH}${VEHICLE_SPECS_PATH}-status/${brand}/${team}/${serieId}/${modelYear}/${language}`,
    payload
  );
};

export const updateAllSpecStatuses = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: UpdateAllModelStatusesRequest
) => {
  return API.post<UpdateAllModelStatusesResponse>(
    `${RESOURCE_PATH}${VEHICLE_SPECS_PATH}-status/${brand}/${team}/${seriesId}/${modelYear}/${language}/all`,
    payload
  );
};
