import { TableCell } from '../../../../../../../components/Table';
import { observer } from 'mobx-react-lite';
import { HeightSyncItem } from '../../../../../../../models/colorsLexus.model';
import React from 'react';
import { AcceptApplyHeaderCell } from '../../../../../../../routes/vehicleData/components/tableCells/AcceptApplyCell';
import styles from './colorsLexusHeaderRow.module.scss';
import { TableDivRow } from './TableDivRow';

const rows = [
  'Materials',
  'Interior',
  'Ornament #1',
  'Ornament #2',
  'Seat Accent',
  'CC / Armrest',
  'Headliner',
  'IP',
  'Carpet',
];

type ColorsLexusHeaderReviewRowProps = {
  heightSync: HeightSyncItem[];
  hideAccept: boolean;
};

const ColorsLexusHeaderReviewRow = ({
  heightSync,
  hideAccept,
}: ColorsLexusHeaderReviewRowProps) => {
  const colSpan = 4;
  const getHeight = (index: number) => {
    if (index >= 0 && index < heightSync.length) {
      return heightSync[index].rowHeight;
    } else {
      return undefined;
    }
  };

  return (
    <tr>
      <td className={styles.cell} colSpan={colSpan}>
        <TableDivRow className={styles.spacer} />
        {rows.map((row, i) => (
          <TableDivRow key={i} rowHeight={getHeight(i - 1)}>
            {row}
          </TableDivRow>
        ))}
      </td>
      <TableCell border whiteBg>
        Changes
      </TableCell>
      <AcceptApplyHeaderCell hideAccept={hideAccept} />
    </tr>
  );
};

export default observer(ColorsLexusHeaderReviewRow);
