import { observable } from 'mobx';
import { SeriesCategories } from '../../models/vehicleData.model';

class VehicleSeriesInfoStore {
  @observable seriesName = '';
  @observable seriesId = '';
  @observable seriesGroup = '' as SeriesCategories;
  @observable year = '';
}

export default VehicleSeriesInfoStore;
