import cx from 'clsx';
import React, { useCallback, useContext } from 'react';
import { DropdownList } from 'vapi-ui-common';
import { KeyFeatureContext } from '.';
import useKeyFeature from '../../hooks/useKeyFeature';
import { KeyFeatureType } from '../../models/features.model';
import IconTextButton from '../IconTextButton';
import styles from './KeyFeature.module.scss';

interface Props {
  keyFeature: KeyFeatureType;
}

const KeyFeatureListItemEdit = ({ keyFeature }: Props) => {
  const { keyFeatureOptions, readOnly, keyFeaturesMap, keyFeatureErrorMap } = useContext(
    KeyFeatureContext
  );
  const { onDelete, onChange } = useKeyFeature();

  const onSelect = useCallback((description: string) => onChange({ ...keyFeature, description }), [
    onChange,
    keyFeature,
  ]);

  const onClick = useCallback(() => onDelete(keyFeature), [onDelete, keyFeature]);

  if (readOnly) return null;

  return (
    <div
      className={cx(styles.keyFeatureListItem, {
        [styles.hasChanges]: keyFeatureErrorMap[keyFeature.id ?? ''],
      })}
      data-testid={`key-feature-list-item-${keyFeature.uid}`}
    >
      <DropdownList
        list={keyFeatureOptions.map(kOption => kOption.name.EN)}
        value={keyFeaturesMap[keyFeature.id ?? '']?.name.EN ?? keyFeature.description}
        onSelect={onSelect}
        btnClass={styles.groupDropdownButton}
        className={styles.groupDropdown}
        placeholder="Select a feature"
      />
      <IconTextButton
        smallIcon
        icon="trash"
        text=""
        onClick={onClick}
        data-testid={`key-feature-list-item-trash-cta-${keyFeature.uid}`}
      />
    </div>
  );
};

export default KeyFeatureListItemEdit;
