import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { TableRow } from '../../../../../../../components/Table';
import useStores from '../../../../../../../hooks/useStores';
import { BnpCategorySplit } from '../../../../../../../models/buildAndPrice.model';
import { BuildAndPriceRowItemContext } from '../BuildAndPriceRow';
import ApplicableModelCell from './ApplicableModelCell';
import CategoryCell from './CategoryCell';
import GradeOrDescriptionCell from './GradeOrDescriptionCell';
import NameCell from './NameCell';

interface BuildAndPriceRowItemProps {
  split?: BnpCategorySplit;
}

const BuildAndPriceRowItem = ({ split }: BuildAndPriceRowItemProps) => {
  const context = useContext(BuildAndPriceRowItemContext);
  const {
    bnpStore: { defaultLang },
  } = useStores();

  if (!context) {
    return null;
  }
  const { index, categories } = context;

  const rowSpan = categories.langMaps.reduce(
    (rSpan, langMap) => rSpan + langMap[defaultLang].splits.length,
    categories.langMaps.length + 1
  );

  return (
    <TableRow zebra>
      {/* Name column */}
      {index === 0 && !split && <NameCell rowSpan={rowSpan} />}

      {/* Category column */}
      <CategoryCell split={split} />

      {/* Grade details or description */}
      <GradeOrDescriptionCell split={split} />

      <ApplicableModelCell split={split} />
    </TableRow>
  );
};

export default observer(BuildAndPriceRowItem);
