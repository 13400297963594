import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef } from 'react';
import ResizeDetector from 'react-resize-detector';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { Table } from '../../../../../../../components/Table';
import RightTableSizer from '../../../../../../../components/Table/components/RightTableSizer';
import StickyHeaderSection from '../../../../../../../components/Table/components/StickyHeaderSection/StickyHeaderSection';
import useStores from '../../../../../../../hooks/useStores';
import { InteriorColorType } from '../../../../../../../models/colors.model';
import {
  HeightSyncItem,
  InteriorCategory,
  InteriorGroup,
  InteriorGroupItem,
} from '../../../../../../../models/colorsLexus.model';
import { IDValueType } from '../../../../../../../models/common.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../models/vehicleModel.model';
import {
  ColorsLexusInteriorCategoryRow,
  ColorsLexusInteriorGroupRow,
  ColorsLexusInteriorMaterialRow,
} from './components/ColorsLexusInteriorGroupHeader/ColorsLexusInteriorGroupRow/ColorsLexusInteriorGroupRow';

interface ColorsLexusAccessibilityTableProps {
  interiorGroups: InteriorGroup[];
  heightSync: HeightSyncItem[];
  saveInteriorCategory?(category: InteriorCategory): void;
  saveInteriorColorType?(
    value: IDValueType<string>,
    interiorColorType: InteriorColorType,
    isNew?: boolean
  ): void;
  deleteInteriorCategory?(interiorCategory: InteriorCategory): void;
  readOnly?: boolean;
  renderBodyRows?(): React.ReactNode;
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  saveInteriorGroup?(interiorGroupItem: InteriorGroupItem, addGroup: boolean): void;
  deleteInteriorGroup?(groupItem: InteriorGroupItem): void;
}

const ColorsLexusAccessibilityTable = ({
  interiorGroups,
  heightSync,
  saveInteriorCategory,
  saveInteriorColorType = () => void 0,
  deleteInteriorCategory = () => void 0,
  readOnly,
  renderBodyRows = () => null,
  vehicleModels,
  saveInteriorGroup,
  deleteInteriorGroup = () => void 0,
}: ColorsLexusAccessibilityTableProps) => {
  const handleOnHeightChange = useCallback(
    (index: number, height: number) => {
      heightSync[index].rowHeight = height;
    },
    [heightSync]
  );

  const { tableSizeStore } = useStores();

  const ref = useRef<HTMLDivElement>(null);

  const onResize = (width: number, height: number) => {
    tableSizeStore.tableRowHeight = height;
  };

  return (
    <ScrollSync>
      <RightTableSizer>
        <StickyHeaderSection hasFilters>
          <ScrollSyncPane group="horizontal">
            <div style={{ overflowX: 'auto' }}>
              <Table>
                <thead>
                  <ColorsLexusInteriorGroupRow
                    interiorGroups={interiorGroups}
                    saveInteriorColorType={saveInteriorColorType}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                    saveInteriorGroup={saveInteriorGroup}
                    deleteInteriorGroup={deleteInteriorGroup}
                  />
                </thead>
                <tbody>
                  <ColorsLexusInteriorMaterialRow interiorGroups={interiorGroups} />
                  <ColorsLexusInteriorCategoryRow
                    showRejectNotes
                    interiorGroups={interiorGroups}
                    displayValue={category => <>{category.interiorType.value}</>}
                    saveInteriorCategory={saveInteriorCategory}
                    saveInteriorColorType={saveInteriorColorType}
                    deleteInteriorCategory={deleteInteriorCategory}
                    onHeightChange={height => handleOnHeightChange(0, height)}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                  />
                  <ColorsLexusInteriorCategoryRow
                    interiorGroups={interiorGroups}
                    displayValue={category => (
                      <>{category.ornamentType1 ? category.ornamentType1.value : ''}</>
                    )}
                    saveInteriorCategory={saveInteriorCategory}
                    saveInteriorColorType={saveInteriorColorType}
                    deleteInteriorCategory={deleteInteriorCategory}
                    onHeightChange={height => handleOnHeightChange(1, height)}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                  />
                  <ColorsLexusInteriorCategoryRow
                    interiorGroups={interiorGroups}
                    displayValue={category => (
                      <>{category.ornamentType2 ? category.ornamentType2.value : ''}</>
                    )}
                    saveInteriorCategory={saveInteriorCategory}
                    saveInteriorColorType={saveInteriorColorType}
                    deleteInteriorCategory={deleteInteriorCategory}
                    onHeightChange={height => handleOnHeightChange(2, height)}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                  />
                  <ColorsLexusInteriorCategoryRow
                    interiorGroups={interiorGroups}
                    displayValue={category => <>{category.seatAccent}</>}
                    saveInteriorCategory={saveInteriorCategory}
                    saveInteriorColorType={saveInteriorColorType}
                    deleteInteriorCategory={deleteInteriorCategory}
                    onHeightChange={height => handleOnHeightChange(3, height)}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                  />
                  <ColorsLexusInteriorCategoryRow
                    interiorGroups={interiorGroups}
                    displayValue={category => <>{category.armRest}</>}
                    saveInteriorCategory={saveInteriorCategory}
                    saveInteriorColorType={saveInteriorColorType}
                    deleteInteriorCategory={deleteInteriorCategory}
                    onHeightChange={height => handleOnHeightChange(4, height)}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                  />
                  <ColorsLexusInteriorCategoryRow
                    interiorGroups={interiorGroups}
                    displayValue={category => <>{category.headliner}</>}
                    saveInteriorCategory={saveInteriorCategory}
                    saveInteriorColorType={saveInteriorColorType}
                    deleteInteriorCategory={deleteInteriorCategory}
                    onHeightChange={height => handleOnHeightChange(5, height)}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                  />
                  <ColorsLexusInteriorCategoryRow
                    interiorGroups={interiorGroups}
                    displayValue={category => <>{category.ip}</>}
                    saveInteriorCategory={saveInteriorCategory}
                    saveInteriorColorType={saveInteriorColorType}
                    deleteInteriorCategory={deleteInteriorCategory}
                    onHeightChange={height => handleOnHeightChange(6, height)}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                  />
                  <ColorsLexusInteriorCategoryRow
                    interiorGroups={interiorGroups}
                    displayValue={category => <>{category.carpet}</>}
                    saveInteriorCategory={saveInteriorCategory}
                    saveInteriorColorType={saveInteriorColorType}
                    deleteInteriorCategory={deleteInteriorCategory}
                    onHeightChange={height => handleOnHeightChange(7, height)}
                    readOnly={readOnly}
                    vehicleModels={vehicleModels}
                  />
                </tbody>
              </Table>
            </div>
          </ScrollSyncPane>
          <ResizeDetector
            handleHeight
            onResize={(width, height) => onResize(width, height)}
            targetDomEl={ref === null ? undefined : (ref.current as HTMLElement)}
          />
        </StickyHeaderSection>
        <ScrollSyncPane group="horizontal">
          <div style={{ overflowX: 'scroll' }}>
            <Table>
              <tbody>{renderBodyRows()}</tbody>
            </Table>
          </div>
        </ScrollSyncPane>
      </RightTableSizer>
    </ScrollSync>
  );
};

export default observer(ColorsLexusAccessibilityTable);
