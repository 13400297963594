import React, { Fragment } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { LimitedDataCell, LimitedDataRow, LimitedDataTable } from '../components/LimitedDataTable';
import { KeyValueType } from '../models/common.model';

export const getTable = (
  ids: string[][],
  headers: string[],
  tableCombo: (string | JSX.Element)[][][][],
  fieldColumns: boolean[],
  isRichTexts: boolean[],
  tableIds: number[] = [],
  checkboxTables: KeyValueType<boolean>[] = [],
  onClickAll: (tableIds: number[]) => () => void = (tableIds: number[]) => () => ({}),
  onClickRow: (tableId: number, id: string) => () => void = (
    tableId: number,
    id: string
  ) => () => ({})
) => {
  if (tableCombo.every(list => !list.length)) {
    return null;
  }

  const isAllChecked = () => {
    const results: boolean[] = [];

    tableIds.forEach(tableId => {
      results.push(Object.values(checkboxTables[tableId]).every(isChecked => isChecked));
    });

    return results.every(result => result);
  };

  const getRows = () => {
    return tableCombo.map((list, i) => {
      return (
        <Fragment key={uuidv4() + '_Row'}>
          {list.map((row: (string | JSX.Element)[][], j: number) => {
            return (
              <LimitedDataRow
                key={j + '_limitedDataRow'}
                onClick={onClickRow(tableIds[i], ids[i][j])}
                isChecked={!!checkboxTables[tableIds[i]][ids[i][j]]}
              >
                <>
                  {row.map((blockText: (string | JSX.Element)[], k: number) => (
                    <LimitedDataCell
                      key={k + '_limitedDataCell'}
                      list={blockText}
                      isFieldColumn={fieldColumns[k]}
                      isRichText={isRichTexts[k]}
                    />
                  ))}
                </>
                <LimitedDataCell inProgressCount={row[row.length - 1].length} />
              </LimitedDataRow>
            );
          })}
        </Fragment>
      );
    });
  };

  return (
    <LimitedDataTable headers={headers} onClick={onClickAll(tableIds)} isChecked={isAllChecked()}>
      {getRows()}
    </LimitedDataTable>
  );
};
