import apolloClient from '../apolloClient';
import {
  ArchiveCommonLanguageSpecDocument,
  ArchiveCommonLanguageSpecMutation,
  ArchiveCommonLanguageSpecMutationVariables,
  CreateCommonLanguageCategoryDocument,
  CreateCommonLanguageCategoryMutation,
  CreateCommonLanguageCategoryMutationVariables,
  CreateCommonLanguageSpecDocument,
  CreateCommonLanguageSpecMutation,
  CreateCommonLanguageSpecMutationVariables,
  DeleteCommonLanguageSpecDocument,
  DeleteCommonLanguageSpecMutation,
  DeleteCommonLanguageSpecMutationVariables,
  GetCommonLanguageVersionsDocument,
  GetCommonLanguageVersionsQuery,
  GetCommonLanguageVersionsQueryVariables,
  PublishCommonLanguageDraftDocument,
  PublishCommonLanguageDraftMutation,
  PublishCommonLanguageDraftMutationVariables,
  ReadCommonLanguageCategoriesDocument,
  ReadCommonLanguageCategoriesQuery,
  ReadCommonLanguageCategoriesQueryVariables,
  ResetCommonLanguageDraftDocument,
  ResetCommonLanguageDraftMutation,
  ResetCommonLanguageDraftMutationVariables,
  RevertCommonLanguageChangeDocument,
  RevertCommonLanguageChangeMutation,
  RevertCommonLanguageChangeMutationVariables,
  SearchCommonLanguageChangeLogDocument,
  SearchCommonLanguageChangeLogQuery,
  SearchCommonLanguageChangeLogQueryVariables,
  SearchCommonLanguageSpecsDocument,
  SearchCommonLanguageSpecsQuery,
  SearchCommonLanguageSpecsQueryVariables,
  UpdateCommonLanguageCategoryDocument,
  UpdateCommonLanguageCategoryMutation,
  UpdateCommonLanguageCategoryMutationVariables,
  UpdateCommonLanguageSpecDocument,
  UpdateCommonLanguageSpecMutation,
  UpdateCommonLanguageSpecMutationVariables,
} from '../gql/generated';
import { KeyValueType } from '../models/common.model';
import {
  CommonLanguageChangeLogResponse,
  CommonLanguageResponse,
} from '../models/commonLanguage.model';
import API from '../webservices/api';

interface CategoriesResponse {
  categories: KeyValueType;
}

// Categories
export const getCategories = async (
  variables: ReadCommonLanguageCategoriesQueryVariables
): Promise<CategoriesResponse> => {
  const { data } = await apolloClient.query<
    ReadCommonLanguageCategoriesQuery,
    ReadCommonLanguageCategoriesQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: ReadCommonLanguageCategoriesDocument,
    variables,
  });

  return data.readCommonLanguageCategories.body;
};

export const addCategories = async (
  variables: CreateCommonLanguageCategoryMutationVariables
): Promise<CategoriesResponse> => {
  const { data } = await apolloClient.mutate<
    CreateCommonLanguageCategoryMutation,
    CreateCommonLanguageCategoryMutationVariables
  >({
    mutation: CreateCommonLanguageCategoryDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add categories');
  }

  return data.createCommonLanguageCategory.body;
};

export const updateCategory = async (
  variables: UpdateCommonLanguageCategoryMutationVariables
): Promise<CategoriesResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateCommonLanguageCategoryMutation,
    UpdateCommonLanguageCategoryMutationVariables
  >({
    mutation: UpdateCommonLanguageCategoryDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update category');
  }

  return data.updateCommonLanguageCategory.body;
};

// Specs
export const getSpecs = async (
  variables: SearchCommonLanguageSpecsQueryVariables
): Promise<CommonLanguageResponse[]> => {
  const { data } = await apolloClient.query<
    SearchCommonLanguageSpecsQuery,
    SearchCommonLanguageSpecsQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: SearchCommonLanguageSpecsDocument,
    variables,
  });

  return data.searchCommonLanguageSpecs.body;
};

export const addSpec = async (
  variables: CreateCommonLanguageSpecMutationVariables
): Promise<CommonLanguageResponse> => {
  const { data: responseData } = await apolloClient.mutate<
    CreateCommonLanguageSpecMutation,
    CreateCommonLanguageSpecMutationVariables
  >({
    mutation: CreateCommonLanguageSpecDocument,
    variables,
  });

  if (!responseData) {
    throw new Error('Failed to add spec');
  }

  return responseData.createCommonLanguageSpec.body;
};

export const updateSpec = async (
  variables: UpdateCommonLanguageSpecMutationVariables
): Promise<CommonLanguageResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateCommonLanguageSpecMutation,
    UpdateCommonLanguageSpecMutationVariables
  >({
    mutation: UpdateCommonLanguageSpecDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update spec');
  }

  return data.updateCommonLanguageSpec.body;
};

export const deleteSpec = async (
  variables: DeleteCommonLanguageSpecMutationVariables
): Promise<CommonLanguageResponse> => {
  const { data } = await apolloClient.mutate<
    DeleteCommonLanguageSpecMutation,
    DeleteCommonLanguageSpecMutationVariables
  >({
    mutation: DeleteCommonLanguageSpecDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete spec');
  }

  return data.deleteCommonLanguageSpec.body;
};

export const archiveSpec = async (
  variables: ArchiveCommonLanguageSpecMutationVariables
): Promise<CommonLanguageResponse> => {
  const { data: responseData } = await apolloClient.mutate<
    ArchiveCommonLanguageSpecMutation,
    ArchiveCommonLanguageSpecMutationVariables
  >({
    mutation: ArchiveCommonLanguageSpecDocument,
    variables,
  });

  if (!responseData) {
    throw new Error('Failed to archive spec');
  }

  return responseData.archiveCommonLanguageSpec.body;
};

// Change Log
export const getChangeLog = async (
  variables: SearchCommonLanguageChangeLogQueryVariables
): Promise<CommonLanguageChangeLogResponse> => {
  const { data } = await apolloClient.query<
    SearchCommonLanguageChangeLogQuery,
    SearchCommonLanguageChangeLogQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: SearchCommonLanguageChangeLogDocument,
    variables,
  });

  return data.searchCommonLanguageChangeLog.body;
};

export const revertChange = async (
  variables: RevertCommonLanguageChangeMutationVariables
): Promise<CommonLanguageResponse> => {
  const { data: responseData } = await apolloClient.mutate<
    RevertCommonLanguageChangeMutation,
    RevertCommonLanguageChangeMutationVariables
  >({
    mutation: RevertCommonLanguageChangeDocument,
    variables,
  });

  if (!responseData) {
    throw new Error('Failed to revert change');
  }

  return responseData.revertCommonLanguageChange.body;
};

// Versions
export const getCommonLanguageVersions = async (
  variables: GetCommonLanguageVersionsQueryVariables
): Promise<string[]> => {
  const { data } = await apolloClient.query<
    GetCommonLanguageVersionsQuery,
    GetCommonLanguageVersionsQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: GetCommonLanguageVersionsDocument,
    variables,
  });

  return data.getCommonLanguageVersions.body;
};

// Models
export const getModels = (brand: string) => {
  return API.get(`/vehicle-admin/data/${brand}/available-models`);
};

// Draft
export const resetDraft = async (
  variables: ResetCommonLanguageDraftMutationVariables
): Promise<void> => {
  await apolloClient.mutate<
    ResetCommonLanguageDraftMutation,
    ResetCommonLanguageDraftMutationVariables
  >({
    mutation: ResetCommonLanguageDraftDocument,
    variables,
  });
};

export const publishDraft = async (
  variables: PublishCommonLanguageDraftMutationVariables
): Promise<void> => {
  await apolloClient.mutate<
    PublishCommonLanguageDraftMutation,
    PublishCommonLanguageDraftMutationVariables
  >({
    mutation: PublishCommonLanguageDraftDocument,
    variables,
  });
};
