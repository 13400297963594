import { createContext } from 'react';
import {
  KeyFeatureOption,
  KeyFeatureOptioneMap,
  KeyFeatureType,
  KeyFeatureTypeMap,
} from '../../models/features.model';

export { default } from './KeyFeaturePopover';

export interface KeyFeatureContextProps {
  keyFeatureOptions: KeyFeatureOption[];
  keyFeatureList: KeyFeatureType[];
  setKeyFeatureList: (keyFeatureList: KeyFeatureType[]) => void;
  readOnly?: boolean;
  hasChanges?: boolean;
  hasErrors?: boolean;
  isLoaded?: boolean;
  keyFeaturesMap: KeyFeatureOptioneMap;
  keyFeatureErrorMap: KeyFeatureTypeMap;
  onSave: (keyFeatureList: KeyFeatureType[]) => void;
}

const defaultContext: KeyFeatureContextProps = {
  keyFeatureOptions: [],
  keyFeatureList: [],
  setKeyFeatureList: () => undefined,
  keyFeaturesMap: {},
  keyFeatureErrorMap: {},
  onSave: (keyFeatureList: KeyFeatureType[]) => undefined,
};

export const KeyFeatureContext = createContext<KeyFeatureContextProps>(defaultContext);
