import Checkbox from '../../../../components/Checkbox';
import NotesPopover from '../../../../components/NotesPopover';
import Spinner from '../../../../components/Spinner';
import {
  DescriptionText,
  HeaderRow,
  Table,
  TableCell,
  TableRow,
} from '../../../../components/Table';
import useStores from '../../../../hooks/useStores';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import {
  ActionBar,
  ActionBarSection,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContainer,
  SearchInput,
} from 'vapi-ui-common';
import { getSpecs } from '../../../../webservices/commonLanguageApi';
import RichTextCell from '../tableCells/RichTextCell';
import { CommonLanguageCheckItem, ModalState } from './addCommonLanguage.models';
import styles from './addCommonLanguageModal.module.scss';
import { toGqlBrand, toGqlFilter } from '../../../../utils/graphqlUtils';
import { Status } from '../../../../gql/generated';
interface AddCommonLanguageModalProps {
  tabName: string;
  onAddItems: (ids: string[]) => void;
  onClose: () => void;
  comLangIds: string[];
  buttonName?: string;
}

const AddCommonLanguageModal = ({
  tabName,
  onAddItems,
  onClose,
  comLangIds,
  buttonName,
}: AddCommonLanguageModalProps) => {
  const {
    userStore: { brand },
    vehicleSeriesInfoStore: { seriesId, year },
  } = useStores();

  const [state] = useState(() => observable(new ModalState()));
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await getSpecs({
          brand: toGqlBrand(brand),
          filter: toGqlFilter(Status.Published),
          modelYear: year,
          seriesId,
        });
        state.items = response.map(item => new CommonLanguageCheckItem(item));
      } catch (e) {
        toast.error('Error fetching common language data');
      }
      setIsLoaded(true);
    })();
  }, [brand, seriesId, state, year, comLangIds]);

  const handleOnSelect = (item: CommonLanguageCheckItem) => {
    item.checked = !item.checked;
    state.items = [...state.items];
  };

  const handleOnImport = () => {
    onAddItems(state.items.filter(item => item.checked && !item.disabled).map(item => item.id));
    onClose();
  };

  return (
    <>
      <ModalHeader onClose={onClose}>
        Add {tabName} {'>'} Common Language
      </ModalHeader>
      {!isLoaded ? (
        <Spinner short />
      ) : (
        <>
          <ModalBody>
            <ActionBar>
              <ActionBarSection>
                <SearchInput
                  value={state.searchText}
                  onSearch={text => (state.searchText = text)}
                />
              </ActionBarSection>
            </ActionBar>
            <ScrollableContainer>
              <Table fullWidth>
                <thead>
                  <HeaderRow>
                    <TableCell className={styles.sticky}></TableCell>
                    <TableCell className={styles.sticky}>Category</TableCell>
                    <TableCell className={styles.sticky}>Sub Category</TableCell>
                    <TableCell className={styles.sticky}>Description</TableCell>
                    <TableCell className={styles.sticky}>Published</TableCell>
                    <TableCell className={styles.sticky} center>
                      Previously Imported
                    </TableCell>
                    <TableCell className={styles.sticky}>Notes</TableCell>
                  </HeaderRow>
                </thead>
                <tbody>
                  {state.filteredItems.map(item => (
                    <TableRow zebra key={item.id}>
                      <TableCell center>
                        {!item.disabled && (
                          <Checkbox
                            id={`addCl-chbox-${item.id}`}
                            defaultChecked={item.checked}
                            onChange={() => handleOnSelect(item)}
                          />
                        )}
                      </TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>
                        <DescriptionText size="sm">{item.subCategory}</DescriptionText>
                      </TableCell>
                      <RichTextCell colType="description" value={item.description} disabled />
                      <TableCell>
                        {item.lastPublishedDate && (
                          <Moment format="MM/DD/YY">{item.lastPublishedDate}</Moment>
                        )}
                      </TableCell>
                      <TableCell colType="dropdown" center>
                        {comLangIds.includes(item.id) && <>Y</>}
                      </TableCell>
                      <TableCell>
                        <NotesPopover notes={item.notes} handleUpdateItem={() => {}} readOnly />
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </ScrollableContainer>
          </ModalBody>
          <ModalFooter>
            <Button variant="transparent" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => handleOnImport()}
              disabled={!state.selectCount}
            >
              Add &nbsp;
              {buttonName && <>{buttonName}</>}
              {!buttonName && <>{tabName}(s)</>}
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default observer(AddCommonLanguageModal);
