import { TableCell } from '../../../../../../../components/Table';
import { observer } from 'mobx-react-lite';
import { OptionsPackage, OptionsPackageSpec } from '../../../../../../../models/optionsLexus.model';
import { ReviewChangeBaseItem } from '../../../../../../../models/review.model';
import React from 'react';
import { AcceptApplyCell } from '../../../../../../../routes/vehicleData/components/tableCells/AcceptApplyCell';
import OptionsLexusReviewChanges from '../../OptionsLexusChanges';
import PackageTableRow from '../../PackageTableRow';
import OptionsLexusPackageSpecReviewCells from './OptionsLexusPackageSpecReviewCells';

interface OptionsLexusPackageSpecReviewRowProps {
  option: OptionsPackage;
  spec: OptionsPackageSpec;
  change: ReviewChangeBaseItem;
  isDelete?: boolean;
  hideAccept?: boolean;
  revertSpec: (
    option: OptionsPackage,
    spec: OptionsPackageSpec,
    change: ReviewChangeBaseItem
  ) => void;
}

const OptionsLexusPackageSpecReviewRow = ({
  option,
  spec,
  change,
  isDelete,
  hideAccept,
  revertSpec,
}: OptionsLexusPackageSpecReviewRowProps) => {
  return (
    <PackageTableRow
      isDelete={isDelete}
      zebra={!isDelete}
      onFillRowHeightChange={height => {
        change.rowHeight = height;
      }}
    >
      <OptionsLexusPackageSpecReviewCells spec={spec} />
      <TableCell colType="changes" whiteBg border smallText>
        <OptionsLexusReviewChanges changes={change.changes} />
        {spec.otherChanges.map(otherChange => (
          <React.Fragment key={otherChange.uid}>
            <p />
            <div>{otherChange.changeTypeId}</div>
            <OptionsLexusReviewChanges changes={otherChange.changes} />
          </React.Fragment>
        ))}
      </TableCell>
      <AcceptApplyCell
        id={`change-row-${change.uid}`}
        acceptChecked={change.isAccepted}
        applyChecked={change.isApplied}
        isNewChange={change.isNewChange}
        notes={change.rejectNotes}
        onAcceptChecked={
          hideAccept
            ? undefined
            : checked => {
                change.isAccepted = checked;
                revertSpec(option, spec, change);
              }
        }
        onApplyChecked={checked => {
          change.isApplied = checked;
          revertSpec(option, spec, change);
        }}
        onNotesChange={notes => {
          change.rejectNotes = notes;
          revertSpec(option, spec, change);
        }}
      />
    </PackageTableRow>
  );
};

export default observer(OptionsLexusPackageSpecReviewRow);
