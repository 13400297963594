import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type AddBnpCategorySplitInput = {
  applicability: Array<Scalars['String']>;
  category: Scalars['String'];
  name: Scalars['String'];
};

export type ApplicabilityInput = {
  applicability: Scalars['String'];
  modelId: Scalars['String'];
};

export type ArchiveCommonLanguageSpecInput = {
  id: Scalars['ID'];
  revId: Scalars['ID'];
};

export type ArchiveDisclaimerInput = {
  id: Scalars['String'];
  revId: Scalars['String'];
};

export type BnpStatusInput = {
  revId?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type BodyJson = {
  __typename?: 'BodyJSON';
  body: Scalars['JSON'];
};

export enum Brand {
  Lexus = 'lexus',
  Tdpr = 'tdpr',
  Toyota = 'toyota'
}

export type CommonLanguageVehicleInput = {
  modelYear: Scalars['String'];
  seriesId: Scalars['ID'];
  seriesName: Scalars['String'];
};

export type CreateCfExtColorInput = {
  code: Scalars['String'];
  colorFamilyIds: Scalars['JSON'];
  hexCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateCfIntColorInput = {
  code: Scalars['String'];
  colorFamilyIds: Scalars['JSON'];
  materialId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateColorFamilyInput = {
  hexCode: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCommonLanguageSpecInput = {
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  isInProgress: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  subCategory: Scalars['String'];
  vehicles?: Maybe<Array<CommonLanguageVehicleInput>>;
};

export type CreateDphCategoryInput = {
  dph: Scalars['Int'];
  name: Scalars['String'];
  nextDph?: Maybe<Scalars['String']>;
  nextDphLiveDate?: Maybe<Scalars['String']>;
  series: Array<Scalars['String']>;
};

export type CreateExtColorLexusInput = {
  code: Scalars['String'];
  colorApplicability?: Maybe<Array<ExtColorApplicabilityLexus>>;
  hexCode?: Maybe<Scalars['String']>;
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type CreateExtColorToyotaInput = {
  code: Scalars['String'];
  colorApplicability?: Maybe<Array<ExtColorApplicabilityToyota>>;
  hexCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type CreateGroupTypesLexusInput = {
  models: Scalars['JSON'];
  name: Scalars['String'];
};

export type CreateIntColorLexusInput = {
  armRest: Scalars['String'];
  carpet: Scalars['String'];
  groupApplicability: Scalars['JSON'];
  headliner: Scalars['String'];
  interiorTypeId: Scalars['String'];
  interiorType_subId?: Maybe<Scalars['String']>;
  ip: Scalars['String'];
  isExtraCost?: Maybe<Scalars['String']>;
  materialId: Scalars['String'];
  ornamentType1Id: Scalars['String'];
  ornamentType1_subId?: Maybe<Scalars['String']>;
  ornamentType2Id?: Maybe<Scalars['String']>;
  ornamentType2_subId?: Maybe<Scalars['String']>;
  seatAccent: Scalars['String'];
};

export type CreateIntColorToyotaInput = {
  code: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isExtraCost?: Maybe<Scalars['String']>;
  modelApplicability: Scalars['JSON'];
  name: Scalars['String'];
};

export type CreateIntColorTypesLexusInput = {
  intColorTypes: Array<Scalars['String']>;
  intType: InteriorTypesLexus;
};

export type CreateMaterialInput = {
  materials: Array<Scalars['String']>;
};

export type CreateReportInput = {
  modelYear?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type CreateSelectionsInput = {
  objectId: Scalars['String'];
  seriesSelections: Array<Scalars['String']>;
};

export type CreateSeriesCategoryInput = {
  name: Scalars['String'];
  nameES: Scalars['String'];
  seriesId: Scalars['String'];
};

export type CreateSeriesInput = {
  group: Scalars['String'];
  name: Scalars['String'];
  sourceBrand?: Maybe<Scalars['String']>;
  sourceSeriesId?: Maybe<Scalars['String']>;
};

export type CreateVehicleModelGradeInput = {
  grades: Scalars['JSON'];
  id?: Maybe<Scalars['ID']>;
};

export type CreateVehicleModelInput = {
  bed?: Maybe<Scalars['String']>;
  cab?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fuelType: Scalars['String'];
  goLiveDate?: Maybe<Scalars['String']>;
  grade: Scalars['String'];
  horsepower: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  isHybrid: Scalars['Boolean'];
  isNotPublishable: Scalars['Boolean'];
  isTDPR?: Maybe<Scalars['Boolean']>;
  isUSVI?: Maybe<Scalars['Boolean']>;
  katashiki?: Maybe<Scalars['String']>;
  seating?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  sourceId?: Maybe<Scalars['String']>;
  tdprCode?: Maybe<Scalars['String']>;
  transmission: Scalars['String'];
  trimTitle: Scalars['String'];
};

export type CreateVehicleModelLexusInput = {
  alternativeOffersDescription?: Maybe<Scalars['String']>;
  cbuNap?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fuelType: Scalars['String'];
  grade: Scalars['String'];
  horsepower: Scalars['String'];
  isHybrid: Scalars['Boolean'];
  isNotPublishable?: Maybe<Scalars['Boolean']>;
  katashiki?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  packageTrim?: Maybe<Scalars['String']>;
  requiredPackageCode?: Maybe<Scalars['String']>;
  seriesSettingId: Scalars['String'];
  specialEdition?: Maybe<Scalars['Boolean']>;
};

export type CreateVehicleModelSeriesInput = {
  convertible?: Maybe<Scalars['Boolean']>;
  estimatedMileage?: Maybe<Scalars['String']>;
  fuelTypes: Scalars['JSON'];
  horsepower?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPublishable?: Maybe<Scalars['Boolean']>;
  mpge?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parentRevId?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['String']>;
  seating?: Maybe<Scalars['String']>;
  seriesCategories: Scalars['JSON'];
  startingMSRP?: Maybe<Scalars['String']>;
};

export type CreateVehicleOptionCategoriesInput = {
  categories: Scalars['JSON'];
  id?: Maybe<Scalars['ID']>;
};

export type CreateVehicleOptionInput = {
  categoryId: Scalars['ID'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type CreateVehicleOptionLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  optionSpecs?: Maybe<Scalars['JSON']>;
  optionTotalApplicability?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['String']>;
  tooltip?: Maybe<Scalars['Boolean']>;
};

export type CreateVehicleOptionSpecInput = {
  isExclusive: Scalars['Boolean'];
  modelApplicability?: Maybe<Scalars['JSON']>;
  parentId: Scalars['ID'];
  parentRevId: Scalars['String'];
  sms?: Maybe<Scalars['String']>;
  specs: Scalars['String'];
  toms?: Maybe<Scalars['String']>;
};

export type CreateVehiclePackageLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  packageTotalApplicability?: Maybe<Scalars['JSON']>;
  packageTotalReqApplicability?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['String']>;
};

export type CreateVehiclePackageVarietyLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
  parentRevId: Scalars['String'];
  required?: Maybe<Scalars['String']>;
};

export type DeleteBnpCategorySplitInput = {
  category: Scalars['String'];
  name: Scalars['String'];
  splitId: Scalars['String'];
};

export type DeleteCommonLanguageSpecInput = {
  id: Scalars['ID'];
  revId: Scalars['ID'];
};

export type DeleteDphCategoryInput = {
  categoryId: Scalars['String'];
  revId: Scalars['String'];
};

export type Disclaimer = {
  __typename?: 'Disclaimer';
  description: Scalars['String'];
  description_es?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived?: Maybe<Scalars['Boolean']>;
  lastPublishedDate?: Maybe<Scalars['String']>;
  lastPublishedVersion?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  token: Scalars['String'];
};

export type DisclaimerResponse = {
  __typename?: 'DisclaimerResponse';
  body: Disclaimer;
};

export type ExtColorApplicabilityLexus = {
  groupId: Scalars['String'];
  interiorColorId: Scalars['String'];
};

export type ExtColorApplicabilityToyota = {
  grade: Scalars['String'];
  interiorColorId: Scalars['String'];
};

export type FilterInput = {
  status?: Maybe<Status>;
  version?: Maybe<Scalars['Int']>;
};

export type INode = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type ImportCfExtColorInput = {
  colors: Array<ImportedCfExtColor>;
};

export type ImportCfIntColorInput = {
  colors: Array<ImportedCfIntColor>;
};

export type ImportedCfExtColor = {
  id: Scalars['ID'];
  msrp?: Maybe<Scalars['String']>;
};

export type ImportedCfIntColor = {
  id: Scalars['ID'];
  modelApplicability?: Maybe<Scalars['JSON']>;
  msrp?: Maybe<Scalars['String']>;
};

export enum InteriorTypesLexus {
  Groups = 'groups',
  InteriorTypes = 'interiorTypes',
  Materials = 'materials',
  Ornaments = 'ornaments'
}


export type KeyFeatureInput = {
  category: Scalars['String'];
  name: Scalars['String'];
  nameES: Scalars['String'];
};

export enum Language {
  En = 'EN',
  Es = 'ES'
}

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  _utilCreateGSTDisclaimers: BodyJson;
  _utilDeleteGSTLioDisclaimer: BodyJson;
  addBnpCategorySplit: BodyJson;
  addSystemSpec: BodyJson;
  archiveCommonLanguageSpec: BodyJson;
  archiveDisclaimer: DisclaimerResponse;
  createCFExtColor: BodyJson;
  createCFIntColor: BodyJson;
  createColorFamily: BodyJson;
  createCommonLanguageCategory: BodyJson;
  createCommonLanguageSpec: BodyJson;
  createDPHCategory: BodyJson;
  createDisclaimer: BodyJson;
  createExtColorLexus: BodyJson;
  createExtColorToyota: BodyJson;
  createGroupTypesLexus: BodyJson;
  createIntColorLexus: BodyJson;
  createIntColorToyota: BodyJson;
  createIntColorTypesLexus: BodyJson;
  createKeyFeature: BodyJson;
  createKeyFeatures: BodyJson;
  createMaterials: BodyJson;
  createReport: BodyJson;
  createSeries: BodyJson;
  createSeriesCategory: BodyJson;
  createSeriesSelections: BodyJson;
  createSignedUrl: BodyJson;
  createSystemSpecs: BodyJson;
  createVehicleModel: BodyJson;
  createVehicleModelGrades: BodyJson;
  createVehicleModelLexus: BodyJson;
  createVehicleModelSeries: BodyJson;
  createVehicleOption: BodyJson;
  createVehicleOptionLexus: BodyJson;
  createVehicleOptionSpecLexus: BodyJson;
  createVehicleOptionsCategories: BodyJson;
  createVehiclePackageLexus: BodyJson;
  createVehiclePackageVarietyLexus: BodyJson;
  deleteBnpCategorySplit: BodyJson;
  deleteCFExtColor: BodyJson;
  deleteCFIntColor: BodyJson;
  deleteColorFamily: BodyJson;
  deleteCommonLanguageCategory: BodyJson;
  deleteCommonLanguageSpec: BodyJson;
  deleteDPHCategory: BodyJson;
  deleteDisclaimer: BodyJson;
  deleteExtColor: BodyJson;
  deleteGroupTypesLexus: BodyJson;
  deleteIntColor: BodyJson;
  deleteSeriesCategory: BodyJson;
  deleteVehicleModel: BodyJson;
  deleteVehicleModelSeries: BodyJson;
  deleteVehicleOption: BodyJson;
  deleteVehicleOptionSpecLexus: BodyJson;
  deleteVehiclePackageVarietyLexus: BodyJson;
  generateDisclaimerReport: BodyJson;
  importCFExtColors: BodyJson;
  importCFIntColors: BodyJson;
  publishColorFamilyDraft: BodyJson;
  publishCommonLanguageDraft: BodyJson;
  publishDPH: BodyJson;
  publishDisclaimersToDownstream: BodyJson;
  publishDraftDisclaimer: BodyJson;
  publishSeriesCategories: Scalars['Boolean'];
  resetCommonLanguageDraft: BodyJson;
  resetDraftDisclaimers: BodyJson;
  revertBnpChange: BodyJson;
  revertColorChange: BodyJson;
  revertCommonLanguageChange: BodyJson;
  revertDisclaimerChange: BodyJson;
  revertVehicleModelChange: BodyJson;
  revertVehicleOptionChange: BodyJson;
  sendSNSMessage: Scalars['Boolean'];
  sortVehicleModels: BodyJson;
  updateBnpCategoryApplicability: BodyJson;
  updateBnpItemDescription: BodyJson;
  updateBnpItemName: BodyJson;
  updateBnpItemTitleCopy: BodyJson;
  updateBnpReview: BodyJson;
  updateBnpStatus: BodyJson;
  updateCFExtColor: BodyJson;
  updateCFIntColor: BodyJson;
  updateColorFamily: BodyJson;
  updateCommonLanguageCategory: BodyJson;
  updateCommonLanguageSpec: BodyJson;
  updateDPHCategory: BodyJson;
  updateDisclaimer: BodyJson;
  updateExtColorLexus: BodyJson;
  updateExtColorReview: BodyJson;
  updateExtColorStatus: BodyJson;
  updateExtColorStatusAll: BodyJson;
  updateExtColorToyota: BodyJson;
  updateGroupTypesLexus: BodyJson;
  updateIntColorLexus: BodyJson;
  updateIntColorReview: BodyJson;
  updateIntColorStatus: BodyJson;
  updateIntColorToyota: BodyJson;
  updateIntColorTypesLexus: BodyJson;
  updateMaterials: BodyJson;
  updateSeries: BodyJson;
  updateSeriesCategory: BodyJson;
  updateVehicleModel: BodyJson;
  updateVehicleModelGrades: BodyJson;
  updateVehicleModelLexus: BodyJson;
  updateVehicleModelReview: BodyJson;
  updateVehicleModelSeries: BodyJson;
  updateVehicleModelSeriesStatus: BodyJson;
  updateVehicleModelStatus: BodyJson;
  updateVehicleModelStatusAll: BodyJson;
  updateVehicleModelsGoLiveDate: BodyJson;
  updateVehicleOption: BodyJson;
  updateVehicleOptionLexus: BodyJson;
  updateVehicleOptionReview: BodyJson;
  updateVehicleOptionSpecLexus: BodyJson;
  updateVehicleOptionSpecReviewLexus: BodyJson;
  updateVehicleOptionStatus: BodyJson;
  updateVehicleOptionStatusAll: BodyJson;
  updateVehicleOptionsCategories: BodyJson;
  updateVehiclePackageLexus: BodyJson;
  updateVehiclePackageVarietyLexus: BodyJson;
  updateVehiclePackageVarietyReviewLexus: BodyJson;
  uploadDisclaimers: BodyJson;
  userPermissions: BodyJson;
};


export type Mutation_UtilCreateGstDisclaimersArgs = {
  brand: Brand;
  region: Region;
};


export type Mutation_UtilDeleteGstLioDisclaimerArgs = {
  brand: Brand;
  region: Region;
};


export type MutationAddBnpCategorySplitArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: AddBnpCategorySplitInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationAddSystemSpecArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  systemSpecs: Array<Scalars['String']>;
  team: Team;
};


export type MutationArchiveCommonLanguageSpecArgs = {
  brand: Brand;
  payload: ArchiveCommonLanguageSpecInput;
};


export type MutationArchiveDisclaimerArgs = {
  brand: Brand;
  payload: ArchiveDisclaimerInput;
  region: Region;
};


export type MutationCreateCfExtColorArgs = {
  brand: Brand;
  language?: Maybe<Language>;
  payload: CreateCfExtColorInput;
};


export type MutationCreateCfIntColorArgs = {
  brand: Brand;
  language?: Maybe<Language>;
  payload: CreateCfIntColorInput;
};


export type MutationCreateColorFamilyArgs = {
  brand: Brand;
  language?: Maybe<Language>;
  payload: CreateColorFamilyInput;
};


export type MutationCreateCommonLanguageCategoryArgs = {
  brand: Brand;
  categories: Array<Scalars['String']>;
};


export type MutationCreateCommonLanguageSpecArgs = {
  brand: Brand;
  payload: CreateCommonLanguageSpecInput;
};


export type MutationCreateDphCategoryArgs = {
  brand: Brand;
  payload: CreateDphCategoryInput;
  region: Region;
};


export type MutationCreateDisclaimerArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationCreateExtColorLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateExtColorLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateExtColorToyotaArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateExtColorToyotaInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateGroupTypesLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateGroupTypesLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateIntColorLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateIntColorLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateIntColorToyotaArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateIntColorToyotaInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateIntColorTypesLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateIntColorTypesLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateKeyFeatureArgs = {
  brand: Brand;
  payload: KeyFeatureInput;
};


export type MutationCreateKeyFeaturesArgs = {
  brand: Brand;
  payload: Array<KeyFeatureInput>;
};


export type MutationCreateMaterialsArgs = {
  brand: Brand;
  language?: Maybe<Language>;
  payload: CreateMaterialInput;
};


export type MutationCreateReportArgs = {
  brand: Brand;
  reportData: CreateReportInput;
  reportType: Scalars['String'];
};


export type MutationCreateSeriesArgs = {
  brand: Brand;
  payload: CreateSeriesInput;
};


export type MutationCreateSeriesCategoryArgs = {
  brand: Brand;
  payload: CreateSeriesCategoryInput;
};


export type MutationCreateSeriesSelectionsArgs = {
  brand: Brand;
  payload: CreateSelectionsInput;
};


export type MutationCreateSignedUrlArgs = {
  action: S3Action;
  brand: Brand;
  key: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateSystemSpecsArgs = {
  brand: Brand;
};


export type MutationCreateVehicleModelArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleModelGradesArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelGradeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleModelLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleModelSeriesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelSeriesInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleOptionArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateVehicleOptionInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleOptionLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehicleOptionLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleOptionSpecLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  optionTab: Scalars['String'];
  payload: CreateVehicleOptionSpecInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleOptionsCategoriesArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehicleOptionCategoriesInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehiclePackageLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehiclePackageLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehiclePackageVarietyLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehiclePackageVarietyLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteBnpCategorySplitArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: DeleteBnpCategorySplitInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteCfExtColorArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language?: Maybe<Language>;
};


export type MutationDeleteCfIntColorArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language?: Maybe<Language>;
};


export type MutationDeleteColorFamilyArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language?: Maybe<Language>;
};


export type MutationDeleteCommonLanguageCategoryArgs = {
  brand: Brand;
  categoryId: Scalars['ID'];
};


export type MutationDeleteCommonLanguageSpecArgs = {
  brand: Brand;
  payload: DeleteCommonLanguageSpecInput;
};


export type MutationDeleteDphCategoryArgs = {
  brand: Brand;
  payload: DeleteDphCategoryInput;
  region: Region;
};


export type MutationDeleteDisclaimerArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationDeleteExtColorArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteGroupTypesLexusArgs = {
  brand: Brand;
  id: Scalars['ID'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteIntColorArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteSeriesCategoryArgs = {
  brand: Brand;
  seriesCategoryId: Scalars['String'];
};


export type MutationDeleteVehicleModelArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteVehicleModelSeriesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  seriesSettingId: Scalars['String'];
  subSeriesSettingId?: Maybe<Scalars['String']>;
  team: Team;
};


export type MutationDeleteVehicleOptionArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language: Language;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteVehicleOptionSpecLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  optionTab: Scalars['String'];
  parentId: Scalars['ID'];
  seriesId: Scalars['String'];
  specId: Scalars['ID'];
  team: Team;
};


export type MutationDeleteVehiclePackageVarietyLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  parentId: Scalars['ID'];
  seriesId: Scalars['String'];
  specId: Scalars['ID'];
  team: Team;
};


export type MutationGenerateDisclaimerReportArgs = {
  brand: Brand;
  region: Region;
  version: Scalars['String'];
};


export type MutationImportCfExtColorsArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: ImportCfExtColorInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationImportCfIntColorsArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: ImportCfIntColorInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationPublishColorFamilyDraftArgs = {
  brand: Brand;
  language?: Maybe<Language>;
};


export type MutationPublishCommonLanguageDraftArgs = {
  brand: Brand;
};


export type MutationPublishDphArgs = {
  brand: Brand;
  region: Region;
};


export type MutationPublishDisclaimersToDownstreamArgs = {
  brand: Brand;
  lang: Language;
  region: Region;
};


export type MutationPublishDraftDisclaimerArgs = {
  brand: Brand;
  region: Region;
};


export type MutationPublishSeriesCategoriesArgs = {
  brand: Brand;
};


export type MutationResetCommonLanguageDraftArgs = {
  brand: Brand;
};


export type MutationResetDraftDisclaimersArgs = {
  brand: Brand;
  region: Region;
};


export type MutationRevertBnpChangeArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: RevertBnpChangeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationRevertColorChangeArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: RevertColorChangeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationRevertCommonLanguageChangeArgs = {
  brand: Brand;
  payload: RevertCommonLanguageChangeInput;
};


export type MutationRevertDisclaimerChangeArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationRevertVehicleModelChangeArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: RevertVehicleModelChangeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationRevertVehicleOptionChangeArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  payload: RevertVehicleOptionChangeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationSendSnsMessageArgs = {
  message: Scalars['String'];
  topic: Scalars['String'];
};


export type MutationSortVehicleModelsArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: Array<SortVehicleModelsInput>;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpCategoryApplicabilityArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpCategoryApplicabilityInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpItemDescriptionArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpItemDescriptionInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpItemNameArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpItemNameInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpItemTitleCopyArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpItemTitleCopyInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  payload: UpdateBnpReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateCfExtColorArgs = {
  brand: Brand;
  language?: Maybe<Language>;
  payload: UpdateCfExtColorInput;
};


export type MutationUpdateCfIntColorArgs = {
  brand: Brand;
  language?: Maybe<Language>;
  payload: UpdateCfIntColorInput;
};


export type MutationUpdateColorFamilyArgs = {
  brand: Brand;
  language?: Maybe<Language>;
  payload: UpdateColorFamilyInput;
};


export type MutationUpdateCommonLanguageCategoryArgs = {
  brand: Brand;
  payload: UpdateCommonLanguageCategoryInput;
};


export type MutationUpdateCommonLanguageSpecArgs = {
  brand: Brand;
  payload: UpdateCommonLanguageSpecInput;
};


export type MutationUpdateDphCategoryArgs = {
  brand: Brand;
  payload: UpdateDphCategoryInput;
  region: Region;
};


export type MutationUpdateDisclaimerArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationUpdateExtColorLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateExtColorLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateExtColorReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  payload: UpdateExtColorReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateExtColorStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateExtColorStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateExtColorStatusAllArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  status: Scalars['Int'];
  team: Team;
};


export type MutationUpdateExtColorToyotaArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateExtColorToyotaInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateGroupTypesLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateGroupTypesLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorToyotaArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorToyotaInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorTypesLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorTypesLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateMaterialsArgs = {
  brand: Brand;
  language?: Maybe<Language>;
  payload: UpdateMaterialInput;
};


export type MutationUpdateSeriesArgs = {
  brand: Brand;
  payload: UpdateSeriesInput;
};


export type MutationUpdateSeriesCategoryArgs = {
  brand: Brand;
  payload: UpdateSeriesCategoryInput;
};


export type MutationUpdateVehicleModelArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelGradesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelGradeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelSeriesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelSeriesInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelSeriesStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelSeriesStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelStatusAllArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  status: Scalars['Int'];
  team: Team;
};


export type MutationUpdateVehicleModelsGoLiveDateArgs = {
  brand: Brand;
  goLiveDate: Scalars['String'];
  language: Language;
  modelYear: Scalars['Int'];
  payload: Scalars['JSON'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  payload: UpdateVehicleOptionReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionSpecLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  optionTab: Scalars['String'];
  payload: UpdateVehicleOptionSpecInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionSpecReviewLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  payload: UpdateVehicleOptionSpecReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionStatusAllArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  status: Scalars['Int'];
  team: Team;
};


export type MutationUpdateVehicleOptionsCategoriesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionCategoriesInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehiclePackageLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateVehiclePackageLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehiclePackageVarietyLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateVehiclePackageVarietyLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehiclePackageVarietyReviewLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  payload: UpdateVehiclePackageVarietyReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUploadDisclaimersArgs = {
  brand: Brand;
  lang: Language;
  payload: Scalars['JSON'];
  region: Region;
  version: Scalars['Int'];
};


export type MutationUserPermissionsArgs = {
  request: UserPermissionsRequest;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  downloadDisclaimers: BodyJson;
  generateCommonLanguageReport: BodyJson;
  getCommonLanguageVersions: BodyJson;
  getDisclaimerCurrentVersion: BodyJson;
  getDisclaimerVersions: BodyJson;
  getFuelTypes: BodyJson;
  getSystemSpecs: BodyJson;
  permissions: BodyJson;
  readAllKeyFeatures: BodyJson;
  readBnpChanges: BodyJson;
  readBnpItems: BodyJson;
  readBnpReview: BodyJson;
  readCFExtColors: BodyJson;
  readCFIntColors: BodyJson;
  readColorChanges: BodyJson;
  readColorFamilies: BodyJson;
  readCommonLanguageCategories: BodyJson;
  readDPHCategories: BodyJson;
  readExtColorReview: BodyJson;
  readExtColors: BodyJson;
  readIntColorReview: BodyJson;
  readIntColorTypesLexus: BodyJson;
  readIntColorTypesReviewLexus: BodyJson;
  readIntColors: BodyJson;
  readKeyFeatures: BodyJson;
  readMaterials: BodyJson;
  readReportLogs: BodyJson;
  readSeries: BodyJson;
  readSeriesCategories: BodyJson;
  readSeriesSelections: BodyJson;
  readVehicleModelChanges: BodyJson;
  readVehicleModelGrades: BodyJson;
  readVehicleModelGradesReviews: BodyJson;
  readVehicleModelMaps: BodyJson;
  readVehicleModelReviews: BodyJson;
  readVehicleModelSeries: BodyJson;
  readVehicleModelSeriesReviews: BodyJson;
  readVehicleModels: BodyJson;
  readVehicleOptionCategoriesReview: BodyJson;
  readVehicleOptionChanges: BodyJson;
  readVehicleOptionReview: BodyJson;
  readVehicleOptionSpecLexus: BodyJson;
  readVehicleOptions: BodyJson;
  readVehicleOptionsCategories: BodyJson;
  readVehiclePackagesVarietyLexus: BodyJson;
  searchCommonLanguageChangeLog: BodyJson;
  searchCommonLanguageSpecs: BodyJson;
  searchDisclaimerChangeLog: BodyJson;
  searchDisclaimerTokens: BodyJson;
  searchDisclaimers: BodyJson;
};


export type QueryDownloadDisclaimersArgs = {
  brand: Brand;
  lang: Language;
  region: Region;
  version: Scalars['String'];
};


export type QueryGenerateCommonLanguageReportArgs = {
  brand: Brand;
  version: Scalars['String'];
};


export type QueryGetCommonLanguageVersionsArgs = {
  brand: Brand;
};


export type QueryGetDisclaimerCurrentVersionArgs = {
  brand: Brand;
  region: Region;
};


export type QueryGetDisclaimerVersionsArgs = {
  brand: Brand;
  region: Region;
};


export type QueryGetFuelTypesArgs = {
  brand: Brand;
};


export type QueryGetSystemSpecsArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryPermissionsArgs = {
  objectId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryReadAllKeyFeaturesArgs = {
  brand: Brand;
};


export type QueryReadBnpChangesArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadBnpItemsArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadBnpReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadCfExtColorsArgs = {
  brand: Brand;
  filter: FilterInput;
  language?: Maybe<Language>;
};


export type QueryReadCfIntColorsArgs = {
  brand: Brand;
  filter: FilterInput;
  language?: Maybe<Language>;
};


export type QueryReadColorChangesArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadColorFamiliesArgs = {
  brand: Brand;
  filter: FilterInput;
  language?: Maybe<Language>;
};


export type QueryReadCommonLanguageCategoriesArgs = {
  brand: Brand;
};


export type QueryReadDphCategoriesArgs = {
  brand: Brand;
  region: Region;
};


export type QueryReadExtColorReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadExtColorsArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadIntColorReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadIntColorTypesLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  includeAll: Scalars['Boolean'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadIntColorTypesReviewLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadIntColorsArgs = {
  brand: Brand;
  filter: FilterInput;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadKeyFeaturesArgs = {
  brand: Brand;
  filter: Scalars['String'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadMaterialsArgs = {
  brand: Brand;
  language?: Maybe<Language>;
};


export type QueryReadReportLogsArgs = {
  brand: Brand;
  reportType: Scalars['String'];
};


export type QueryReadSeriesArgs = {
  brand: Brand;
};


export type QueryReadSeriesCategoriesArgs = {
  brand: Brand;
  getLatestVersion: Scalars['Boolean'];
};


export type QueryReadSeriesSelectionsArgs = {
  brand: Brand;
  objectId: Scalars['String'];
};


export type QueryReadVehicleModelChangesArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelGradesArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelGradesReviewsArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelMapsArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
  versionMap: Scalars['JSON'];
};


export type QueryReadVehicleModelReviewsArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelSeriesArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelSeriesReviewsArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelsArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionCategoriesReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionChangesArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionSpecLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionsArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionsCategoriesArgs = {
  brand: Brand;
  filter: FilterInput;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehiclePackagesVarietyLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QuerySearchCommonLanguageChangeLogArgs = {
  brand: Brand;
  filter: FilterInput;
};


export type QuerySearchCommonLanguageSpecsArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['String']>;
};


export type QuerySearchDisclaimerChangeLogArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  region: Region;
};


export type QuerySearchDisclaimerTokensArgs = {
  brand: Brand;
  region: Region;
};


export type QuerySearchDisclaimersArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  region: Region;
};

export enum Region {
  Gst = 'GST',
  National = 'NATIONAL',
  Set = 'SET',
  Tdpr = 'TDPR',
  Usvi = 'USVI'
}

export type RevertBnpChangeInput = {
  bnpSplitId?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  changeType: Scalars['String'];
  from?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  to?: Maybe<Scalars['JSON']>;
};

export type RevertColorChangeInput = {
  changeType: Scalars['String'];
  from: Scalars['JSON'];
  id: Scalars['ID'];
  to: Scalars['JSON'];
};

export type RevertCommonLanguageChangeInput = {
  after?: Maybe<Scalars['JSON']>;
  before?: Maybe<Scalars['JSON']>;
  changeType: Scalars['String'];
  id: Scalars['ID'];
  revId: Scalars['ID'];
};

export type RevertVehicleModelChangeInput = {
  changeType: Scalars['String'];
  from?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  revId: Scalars['String'];
  to?: Maybe<Scalars['JSON']>;
};

export type RevertVehicleOptionChangeInput = {
  changeType: Scalars['String'];
  from?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['JSON']>;
};

export enum S3Action {
  Download = 'DOWNLOAD',
  Upload = 'UPLOAD'
}

export type SortVehicleModelsInput = {
  id: Scalars['ID'];
  revId: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export enum Status {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum Team {
  AgencyTeam = 'agency_team',
  ProductTeam = 'product_team'
}

export type UpdateBnpCategoryApplicabilityInput = {
  applicability: Array<ApplicabilityInput>;
  category: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateBnpItemDescriptionInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  category: Scalars['String'];
  copy?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  name: Scalars['String'];
  splitId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateBnpItemNameInput = {
  isInProgress: Scalars['Boolean'];
  name: Scalars['String'];
  notes: Scalars['String'];
};

export type UpdateBnpItemTitleCopyInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  category: Scalars['String'];
  copy?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type UpdateBnpReviewInput = {
  category: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  name: Scalars['String'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateBnpStatusInput = {
  beds: BnpStatusInput;
  cabs: BnpStatusInput;
  drive: BnpStatusInput;
  engine: BnpStatusInput;
  grade: BnpStatusInput;
  seats: BnpStatusInput;
  transmission: BnpStatusInput;
};

export type UpdateCfExtColorInput = {
  code: Scalars['String'];
  colorFamilyIds: Scalars['JSON'];
  hexCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateCfIntColorInput = {
  code: Scalars['String'];
  colorFamilyIds: Scalars['JSON'];
  id: Scalars['ID'];
  materialId: Scalars['ID'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateColorFamilyInput = {
  hexCode: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateCommonLanguageCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateCommonLanguageSpecInput = {
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isInProgress: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['ID'];
  subCategory: Scalars['String'];
  vehicles?: Maybe<Array<CommonLanguageVehicleInput>>;
};

export type UpdateDphCategoryInput = {
  categoryId: Scalars['String'];
  dph: Scalars['Int'];
  name: Scalars['String'];
  nextDph?: Maybe<Scalars['String']>;
  nextDphLiveDate?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  series: Array<Scalars['String']>;
};

export type UpdateExtColorLexusInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  colorApplicability?: Maybe<Array<ExtColorApplicabilityLexus>>;
  hexCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateExtColorReviewInput = {
  changeTypeId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateExtColorStatusInput = {
  id: Scalars['ID'];
  revId: Scalars['String'];
  status: Scalars['Int'];
};

export type UpdateExtColorToyotaInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  colorApplicability?: Maybe<Array<ExtColorApplicabilityToyota>>;
  hexCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateGroupTypesLexusInput = {
  id: Scalars['ID'];
  models: Scalars['JSON'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateIntColorLexusInput = {
  armRest: Scalars['String'];
  carpet: Scalars['String'];
  groupApplicability: Scalars['JSON'];
  headliner: Scalars['String'];
  id: Scalars['ID'];
  interiorTypeId: Scalars['String'];
  interiorType_subId?: Maybe<Scalars['String']>;
  ip: Scalars['String'];
  isExtraCost?: Maybe<Scalars['String']>;
  materialId: Scalars['String'];
  ornamentType1Id: Scalars['String'];
  ornamentType1_subId?: Maybe<Scalars['String']>;
  ornamentType2Id?: Maybe<Scalars['String']>;
  ornamentType2_subId?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seatAccent: Scalars['String'];
};

export type UpdateIntColorReviewInput = {
  changeTypeId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateIntColorStatusInput = {
  id: Scalars['ID'];
  modelApplicability: Scalars['JSON'];
  revId: Scalars['String'];
};

export type UpdateIntColorToyotaInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  id: Scalars['ID'];
  isExtraCost?: Maybe<Scalars['String']>;
  modelApplicability: Scalars['JSON'];
  name: Scalars['String'];
  revId: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateIntColorTypesLexusInput = {
  id: Scalars['ID'];
  intType: InteriorTypesLexus;
  name: Scalars['String'];
};

export type UpdateMaterialInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateSeriesCategoryInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  nameES: Scalars['String'];
  revId: Scalars['String'];
  seriesId: Scalars['String'];
};

export type UpdateSeriesInput = {
  group: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateVehicleModelGradeInput = {
  gradeId: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateVehicleModelInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  bed?: Maybe<Scalars['String']>;
  cab?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fuelType: Scalars['String'];
  goLiveDate?: Maybe<Scalars['String']>;
  grade: Scalars['String'];
  horsepower: Scalars['String'];
  id: Scalars['ID'];
  isHybrid: Scalars['Boolean'];
  isNotPublishable: Scalars['Boolean'];
  isTDPR?: Maybe<Scalars['Boolean']>;
  isUSVI?: Maybe<Scalars['Boolean']>;
  katashiki?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seating?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  sourceId?: Maybe<Scalars['String']>;
  tdprCode?: Maybe<Scalars['String']>;
  transmission: Scalars['String'];
  trimTitle: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleModelLexusInput = {
  alternativeOffersDescription?: Maybe<Scalars['String']>;
  cbuNap?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fuelType: Scalars['String'];
  grade: Scalars['String'];
  horsepower: Scalars['String'];
  id: Scalars['ID'];
  isHybrid: Scalars['Boolean'];
  isNotPublishable?: Maybe<Scalars['Boolean']>;
  katashiki?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  packageTrim?: Maybe<Scalars['String']>;
  requiredPackageCode?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seriesSettingId: Scalars['String'];
  specialEdition?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleModelReviewInput = {
  changeTypeId: Scalars['String'];
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehicleModelSeriesInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  convertible?: Maybe<Scalars['Boolean']>;
  estimatedMileage?: Maybe<Scalars['String']>;
  fuelTypes: Scalars['JSON'];
  horsepower?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPublishable?: Maybe<Scalars['Boolean']>;
  mpge?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parentRevId?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seating?: Maybe<Scalars['String']>;
  seriesCategories: Scalars['JSON'];
  startingMSRP?: Maybe<Scalars['String']>;
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleModelSeriesStatusInput = {
  estimatedMileage: Scalars['Int'];
  id: Scalars['String'];
  mpge: Scalars['Int'];
  revId: Scalars['String'];
  seating: Scalars['Int'];
};

export type UpdateVehicleModelStatusInput = {
  bed: Scalars['Int'];
  cab: Scalars['Int'];
  description: Scalars['Int'];
  drive: Scalars['Int'];
  engine: Scalars['Int'];
  grade: Scalars['Int'];
  horsepower: Scalars['Int'];
  id: Scalars['String'];
  revId: Scalars['String'];
  seating: Scalars['Int'];
  transmission: Scalars['Int'];
  trimTitle: Scalars['Int'];
};

export type UpdateVehicleOptionCategoriesInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateVehicleOptionInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  categoryId: Scalars['ID'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleOptionLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  optionSpecs?: Maybe<Scalars['JSON']>;
  optionTotalApplicability?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  tooltip?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleOptionReviewInput = {
  changeTypeId: Scalars['String'];
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehicleOptionSpecInput = {
  id: Scalars['ID'];
  isExclusive: Scalars['Boolean'];
  modelApplicability?: Maybe<Scalars['JSON']>;
  parentId: Scalars['ID'];
  parentRevId: Scalars['String'];
  revId: Scalars['String'];
  sms?: Maybe<Scalars['String']>;
  specs: Scalars['String'];
  toms?: Maybe<Scalars['String']>;
};

export type UpdateVehicleOptionSpecReviewInput = {
  changeTypeId: Scalars['String'];
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  parentId: Scalars['String'];
  parentRevId: Scalars['String'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehicleOptionStatusInput = {
  id: Scalars['ID'];
  modelApplicability: Scalars['JSON'];
  revId: Scalars['String'];
  status: Scalars['Int'];
};

export type UpdateVehiclePackageLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  packageTotalApplicability?: Maybe<Scalars['JSON']>;
  packageTotalReqApplicability?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehiclePackageVarietyLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modelApplicability?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
  parentRevId: Scalars['String'];
  required?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehiclePackageVarietyReviewInput = {
  changeTypeId: Scalars['String'];
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  parentId: Scalars['String'];
  parentRevId: Scalars['String'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UserPermissionsRequest = {
  id: Scalars['String'];
  isGSTAcc: Scalars['Boolean'];
  isLexus: Scalars['Boolean'];
  isSpanish: Scalars['Boolean'];
  isTdpr: Scalars['Boolean'];
  isToyota: Scalars['Boolean'];
};

export type AddBnpCategorySplitMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: AddBnpCategorySplitInput;
}>;


export type AddBnpCategorySplitMutation = (
  { __typename?: 'Mutation' }
  & { addBnpCategorySplit: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteBnpCategorySplitMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: DeleteBnpCategorySplitInput;
}>;


export type DeleteBnpCategorySplitMutation = (
  { __typename?: 'Mutation' }
  & { deleteBnpCategorySplit: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadBnpChangesQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter: FilterInput;
}>;


export type ReadBnpChangesQuery = (
  { __typename?: 'Query' }
  & { readBnpChanges: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadBnpItemsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter: FilterInput;
}>;


export type ReadBnpItemsQuery = (
  { __typename?: 'Query' }
  & { readBnpItems: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadBnpReviewQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
}>;


export type ReadBnpReviewQuery = (
  { __typename?: 'Query' }
  & { readBnpReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type RevertBnpChangeMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: RevertBnpChangeInput;
}>;


export type RevertBnpChangeMutation = (
  { __typename?: 'Mutation' }
  & { revertBnpChange: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateBnpCategoryApplicabilityMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateBnpCategoryApplicabilityInput;
}>;


export type UpdateBnpCategoryApplicabilityMutation = (
  { __typename?: 'Mutation' }
  & { updateBnpCategoryApplicability: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateBnpItemDescriptionMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateBnpItemDescriptionInput;
}>;


export type UpdateBnpItemDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateBnpItemDescription: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateBnpItemNameMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateBnpItemNameInput;
}>;


export type UpdateBnpItemNameMutation = (
  { __typename?: 'Mutation' }
  & { updateBnpItemName: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateBnpItemTitleCopyMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateBnpItemTitleCopyInput;
}>;


export type UpdateBnpItemTitleCopyMutation = (
  { __typename?: 'Mutation' }
  & { updateBnpItemTitleCopy: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateBnpReviewMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateBnpReviewInput;
  filter: FilterInput;
}>;


export type UpdateBnpReviewMutation = (
  { __typename?: 'Mutation' }
  & { updateBnpReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateBnpStatusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateBnpStatusInput;
}>;


export type UpdateBnpStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateBnpStatus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ArchiveCommonLanguageSpecMutationVariables = Exact<{
  brand: Brand;
  payload: ArchiveCommonLanguageSpecInput;
}>;


export type ArchiveCommonLanguageSpecMutation = (
  { __typename?: 'Mutation' }
  & { archiveCommonLanguageSpec: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateCommonLanguageCategoryMutationVariables = Exact<{
  brand: Brand;
  categories: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateCommonLanguageCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCommonLanguageCategory: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateCommonLanguageSpecMutationVariables = Exact<{
  brand: Brand;
  payload: CreateCommonLanguageSpecInput;
}>;


export type CreateCommonLanguageSpecMutation = (
  { __typename?: 'Mutation' }
  & { createCommonLanguageSpec: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteCommonLanguageSpecMutationVariables = Exact<{
  brand: Brand;
  payload: DeleteCommonLanguageSpecInput;
}>;


export type DeleteCommonLanguageSpecMutation = (
  { __typename?: 'Mutation' }
  & { deleteCommonLanguageSpec: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type GenerateCommonLanguageReportQueryVariables = Exact<{
  brand: Brand;
  version: Scalars['String'];
}>;


export type GenerateCommonLanguageReportQuery = (
  { __typename?: 'Query' }
  & { generateCommonLanguageReport: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type GetCommonLanguageVersionsQueryVariables = Exact<{
  brand: Brand;
}>;


export type GetCommonLanguageVersionsQuery = (
  { __typename?: 'Query' }
  & { getCommonLanguageVersions: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type PublishCommonLanguageDraftMutationVariables = Exact<{
  brand: Brand;
}>;


export type PublishCommonLanguageDraftMutation = (
  { __typename?: 'Mutation' }
  & { publishCommonLanguageDraft: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadCommonLanguageCategoriesQueryVariables = Exact<{
  brand: Brand;
}>;


export type ReadCommonLanguageCategoriesQuery = (
  { __typename?: 'Query' }
  & { readCommonLanguageCategories: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ResetCommonLanguageDraftMutationVariables = Exact<{
  brand: Brand;
}>;


export type ResetCommonLanguageDraftMutation = (
  { __typename?: 'Mutation' }
  & { resetCommonLanguageDraft: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type RevertCommonLanguageChangeMutationVariables = Exact<{
  brand: Brand;
  payload: RevertCommonLanguageChangeInput;
}>;


export type RevertCommonLanguageChangeMutation = (
  { __typename?: 'Mutation' }
  & { revertCommonLanguageChange: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type SearchCommonLanguageChangeLogQueryVariables = Exact<{
  brand: Brand;
  filter: FilterInput;
}>;


export type SearchCommonLanguageChangeLogQuery = (
  { __typename?: 'Query' }
  & { searchCommonLanguageChangeLog: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type SearchCommonLanguageSpecsQueryVariables = Exact<{
  brand: Brand;
  filter: FilterInput;
  modelYear?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['String']>;
}>;


export type SearchCommonLanguageSpecsQuery = (
  { __typename?: 'Query' }
  & { searchCommonLanguageSpecs: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateCommonLanguageCategoryMutationVariables = Exact<{
  brand: Brand;
  payload: UpdateCommonLanguageCategoryInput;
}>;


export type UpdateCommonLanguageCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCommonLanguageCategory: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateCommonLanguageSpecMutationVariables = Exact<{
  brand: Brand;
  payload: UpdateCommonLanguageSpecInput;
}>;


export type UpdateCommonLanguageSpecMutation = (
  { __typename?: 'Mutation' }
  & { updateCommonLanguageSpec: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type AddDisclaimerMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  payload: Scalars['JSON'];
}>;


export type AddDisclaimerMutation = (
  { __typename?: 'Mutation' }
  & { createDisclaimer: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ArchiveDisclaimerMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  payload: ArchiveDisclaimerInput;
}>;


export type ArchiveDisclaimerMutation = (
  { __typename?: 'Mutation' }
  & { archiveDisclaimer: (
    { __typename?: 'DisclaimerResponse' }
    & { body: (
      { __typename?: 'Disclaimer' }
      & Pick<Disclaimer, 'id' | 'revId' | 'token' | 'description' | 'description_es' | 'notes' | 'lastPublishedVersion' | 'lastPublishedDate' | 'isArchived'>
    ) }
  ) }
);

export type DeleteDisclaimerMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  payload: Scalars['JSON'];
}>;


export type DeleteDisclaimerMutation = (
  { __typename?: 'Mutation' }
  & { deleteDisclaimer: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DownloadDisclaimersQueryVariables = Exact<{
  brand: Brand;
  region: Region;
  version: Scalars['String'];
  lang: Language;
}>;


export type DownloadDisclaimersQuery = (
  { __typename?: 'Query' }
  & { downloadDisclaimers: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type GetDisclaimerCurrentVersionQueryVariables = Exact<{
  brand: Brand;
  region: Region;
}>;


export type GetDisclaimerCurrentVersionQuery = (
  { __typename?: 'Query' }
  & { getDisclaimerCurrentVersion: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type GetDisclaimerVersionsQueryVariables = Exact<{
  brand: Brand;
  region: Region;
}>;


export type GetDisclaimerVersionsQuery = (
  { __typename?: 'Query' }
  & { getDisclaimerVersions: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type PublishDisclaimersToDownstreamMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  lang: Language;
}>;


export type PublishDisclaimersToDownstreamMutation = (
  { __typename?: 'Mutation' }
  & { publishDisclaimersToDownstream: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type PublishDraftDisclaimerMutationVariables = Exact<{
  brand: Brand;
  region: Region;
}>;


export type PublishDraftDisclaimerMutation = (
  { __typename?: 'Mutation' }
  & { publishDraftDisclaimer: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ResetDraftDisclaimersMutationVariables = Exact<{
  brand: Brand;
  region: Region;
}>;


export type ResetDraftDisclaimersMutation = (
  { __typename?: 'Mutation' }
  & { resetDraftDisclaimers: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type RevertDisclaimerChangeMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  payload: Scalars['JSON'];
}>;


export type RevertDisclaimerChangeMutation = (
  { __typename?: 'Mutation' }
  & { revertDisclaimerChange: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type SearchDisclaimerChangeLogQueryVariables = Exact<{
  brand: Brand;
  region: Region;
  filter?: Maybe<FilterInput>;
}>;


export type SearchDisclaimerChangeLogQuery = (
  { __typename?: 'Query' }
  & { searchDisclaimerChangeLog: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type SearchDisclaimerTokensQueryVariables = Exact<{
  brand: Brand;
  region: Region;
}>;


export type SearchDisclaimerTokensQuery = (
  { __typename?: 'Query' }
  & { searchDisclaimerTokens: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type SearchDisclaimersQueryVariables = Exact<{
  brand: Brand;
  region: Region;
  filter?: Maybe<FilterInput>;
}>;


export type SearchDisclaimersQuery = (
  { __typename?: 'Query' }
  & { searchDisclaimers: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateDisclaimerMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  payload: Scalars['JSON'];
}>;


export type UpdateDisclaimerMutation = (
  { __typename?: 'Mutation' }
  & { updateDisclaimer: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UploadDisclaimersMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  lang: Language;
  version: Scalars['Int'];
  payload: Scalars['JSON'];
}>;


export type UploadDisclaimersMutation = (
  { __typename?: 'Mutation' }
  & { uploadDisclaimers: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateDphCategoryMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  payload: CreateDphCategoryInput;
}>;


export type CreateDphCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createDPHCategory: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteDphCategoryMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  payload: DeleteDphCategoryInput;
}>;


export type DeleteDphCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteDPHCategory: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type PublishDphMutationVariables = Exact<{
  brand: Brand;
  region: Region;
}>;


export type PublishDphMutation = (
  { __typename?: 'Mutation' }
  & { publishDPH: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadDphCategoriesQueryVariables = Exact<{
  brand: Brand;
  region: Region;
}>;


export type ReadDphCategoriesQuery = (
  { __typename?: 'Query' }
  & { readDPHCategories: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateDphCategoryMutationVariables = Exact<{
  brand: Brand;
  region: Region;
  payload: UpdateDphCategoryInput;
}>;


export type UpdateDphCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateDPHCategory: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type GetFuelTypesQueryVariables = Exact<{
  brand: Brand;
}>;


export type GetFuelTypesQuery = (
  { __typename?: 'Query' }
  & { getFuelTypes: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadAllKeyFeaturesQueryVariables = Exact<{
  brand: Brand;
}>;


export type ReadAllKeyFeaturesQuery = (
  { __typename?: 'Query' }
  & { readAllKeyFeatures: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadKeyFeaturesQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  language: Language;
  modelYear: Scalars['Int'];
  filter: Scalars['String'];
}>;


export type ReadKeyFeaturesQuery = (
  { __typename?: 'Query' }
  & { readKeyFeatures: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateReportMutationVariables = Exact<{
  brand: Brand;
  reportType: Scalars['String'];
  reportData: CreateReportInput;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadReportLogsQueryVariables = Exact<{
  brand: Brand;
  reportType: Scalars['String'];
}>;


export type ReadReportLogsQuery = (
  { __typename?: 'Query' }
  & { readReportLogs: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateSignedUrlMutationVariables = Exact<{
  brand: Brand;
  action: S3Action;
  key: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
}>;


export type CreateSignedUrlMutation = (
  { __typename?: 'Mutation' }
  & { createSignedUrl: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateSeriesMutationVariables = Exact<{
  brand: Brand;
  payload: CreateSeriesInput;
}>;


export type CreateSeriesMutation = (
  { __typename?: 'Mutation' }
  & { createSeries: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateSeriesSelectionsMutationVariables = Exact<{
  brand: Brand;
  payload: CreateSelectionsInput;
}>;


export type CreateSeriesSelectionsMutation = (
  { __typename?: 'Mutation' }
  & { createSeriesSelections: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadSeriesQueryVariables = Exact<{
  brand: Brand;
}>;


export type ReadSeriesQuery = (
  { __typename?: 'Query' }
  & { readSeries: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadSeriesSelectionsQueryVariables = Exact<{
  brand: Brand;
  objectId: Scalars['String'];
}>;


export type ReadSeriesSelectionsQuery = (
  { __typename?: 'Query' }
  & { readSeriesSelections: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateSeriesMutationVariables = Exact<{
  brand: Brand;
  payload: UpdateSeriesInput;
}>;


export type UpdateSeriesMutation = (
  { __typename?: 'Mutation' }
  & { updateSeries: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateSeriesCategoryMutationVariables = Exact<{
  brand: Brand;
  payload: CreateSeriesCategoryInput;
}>;


export type CreateSeriesCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createSeriesCategory: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteSeriesCategoryMutationVariables = Exact<{
  brand: Brand;
  seriesCategoryId: Scalars['String'];
}>;


export type DeleteSeriesCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteSeriesCategory: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type PublishSeriesCategoriesMutationVariables = Exact<{
  brand: Brand;
}>;


export type PublishSeriesCategoriesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publishSeriesCategories'>
);

export type ReadSeriesCategoriesQueryVariables = Exact<{
  brand: Brand;
  getLatestVersion: Scalars['Boolean'];
}>;


export type ReadSeriesCategoriesQuery = (
  { __typename?: 'Query' }
  & { readSeriesCategories: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateSeriesCategoryMutationVariables = Exact<{
  brand: Brand;
  payload: UpdateSeriesCategoryInput;
}>;


export type UpdateSeriesCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateSeriesCategory: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type SendSnsMessageMutationVariables = Exact<{
  topic: Scalars['String'];
  message: Scalars['String'];
}>;


export type SendSnsMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendSNSMessage'>
);

export type AddSystemSpecMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  systemSpec: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddSystemSpecMutation = (
  { __typename?: 'Mutation' }
  & { addSystemSpec: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type GetSystemSpecsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter: FilterInput;
}>;


export type GetSystemSpecsQuery = (
  { __typename?: 'Query' }
  & { getSystemSpecs: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateCfExtColorMutationVariables = Exact<{
  brand: Brand;
  payload: CreateCfExtColorInput;
}>;


export type CreateCfExtColorMutation = (
  { __typename?: 'Mutation' }
  & { createCFExtColor: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateCfIntColorMutationVariables = Exact<{
  brand: Brand;
  payload: CreateCfIntColorInput;
}>;


export type CreateCfIntColorMutation = (
  { __typename?: 'Mutation' }
  & { createCFIntColor: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateColorFamilyMutationVariables = Exact<{
  brand: Brand;
  payload: CreateColorFamilyInput;
}>;


export type CreateColorFamilyMutation = (
  { __typename?: 'Mutation' }
  & { createColorFamily: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateExtColorLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateExtColorLexusInput;
}>;


export type CreateExtColorLexusMutation = (
  { __typename?: 'Mutation' }
  & { createExtColorLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateExtColorToyotaMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: CreateExtColorToyotaInput;
}>;


export type CreateExtColorToyotaMutation = (
  { __typename?: 'Mutation' }
  & { createExtColorToyota: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateGroupTypesLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateGroupTypesLexusInput;
}>;


export type CreateGroupTypesLexusMutation = (
  { __typename?: 'Mutation' }
  & { createGroupTypesLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateIntColorLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateIntColorLexusInput;
}>;


export type CreateIntColorLexusMutation = (
  { __typename?: 'Mutation' }
  & { createIntColorLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateIntColorToyotaMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: CreateIntColorToyotaInput;
}>;


export type CreateIntColorToyotaMutation = (
  { __typename?: 'Mutation' }
  & { createIntColorToyota: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateIntColorTypesLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateIntColorTypesLexusInput;
}>;


export type CreateIntColorTypesLexusMutation = (
  { __typename?: 'Mutation' }
  & { createIntColorTypesLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateMaterialsMutationVariables = Exact<{
  brand: Brand;
  payload: CreateMaterialInput;
}>;


export type CreateMaterialsMutation = (
  { __typename?: 'Mutation' }
  & { createMaterials: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteCfExtColorMutationVariables = Exact<{
  brand: Brand;
  deleteCfExtColorId: Scalars['ID'];
}>;


export type DeleteCfExtColorMutation = (
  { __typename?: 'Mutation' }
  & { deleteCFExtColor: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteCfIntColorMutationVariables = Exact<{
  brand: Brand;
  deleteCfIntColorId: Scalars['ID'];
}>;


export type DeleteCfIntColorMutation = (
  { __typename?: 'Mutation' }
  & { deleteCFIntColor: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteColorFamilyMutationVariables = Exact<{
  brand: Brand;
  deleteColorFamilyId: Scalars['ID'];
}>;


export type DeleteColorFamilyMutation = (
  { __typename?: 'Mutation' }
  & { deleteColorFamily: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteExtColorMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  deleteExtColorId: Scalars['ID'];
}>;


export type DeleteExtColorMutation = (
  { __typename?: 'Mutation' }
  & { deleteExtColor: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteGroupTypesLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  deleteGroupTypesLexusId: Scalars['ID'];
}>;


export type DeleteGroupTypesLexusMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroupTypesLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteIntColorMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  deleteIntColorId: Scalars['ID'];
}>;


export type DeleteIntColorMutation = (
  { __typename?: 'Mutation' }
  & { deleteIntColor: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type PublishColorFamilyDraftMutationVariables = Exact<{
  brand: Brand;
}>;


export type PublishColorFamilyDraftMutation = (
  { __typename?: 'Mutation' }
  & { publishColorFamilyDraft: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadCfExtColorsQueryVariables = Exact<{
  brand: Brand;
  filter: FilterInput;
}>;


export type ReadCfExtColorsQuery = (
  { __typename?: 'Query' }
  & { readCFExtColors: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadCfIntColorsQueryVariables = Exact<{
  brand: Brand;
  filter: FilterInput;
}>;


export type ReadCfIntColorsQuery = (
  { __typename?: 'Query' }
  & { readCFIntColors: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadColorChangesQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter: FilterInput;
}>;


export type ReadColorChangesQuery = (
  { __typename?: 'Query' }
  & { readColorChanges: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadColorFamiliesQueryVariables = Exact<{
  brand: Brand;
  filter: FilterInput;
}>;


export type ReadColorFamiliesQuery = (
  { __typename?: 'Query' }
  & { readColorFamilies: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadExtColorReviewQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
}>;


export type ReadExtColorReviewQuery = (
  { __typename?: 'Query' }
  & { readExtColorReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadExtColorsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter: FilterInput;
}>;


export type ReadExtColorsQuery = (
  { __typename?: 'Query' }
  & { readExtColors: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadIntColorReviewQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
}>;


export type ReadIntColorReviewQuery = (
  { __typename?: 'Query' }
  & { readIntColorReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadIntColorTypesLexusQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
  includeAll: Scalars['Boolean'];
}>;


export type ReadIntColorTypesLexusQuery = (
  { __typename?: 'Query' }
  & { readIntColorTypesLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadIntColorTypesReviewLexusQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
}>;


export type ReadIntColorTypesReviewLexusQuery = (
  { __typename?: 'Query' }
  & { readIntColorTypesReviewLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadIntColorsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter: FilterInput;
  includeAll: Scalars['Boolean'];
}>;


export type ReadIntColorsQuery = (
  { __typename?: 'Query' }
  & { readIntColors: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadMaterialsQueryVariables = Exact<{
  brand: Brand;
}>;


export type ReadMaterialsQuery = (
  { __typename?: 'Query' }
  & { readMaterials: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type RevertColorChangeMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: RevertColorChangeInput;
}>;


export type RevertColorChangeMutation = (
  { __typename?: 'Mutation' }
  & { revertColorChange: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateCfExtColorMutationVariables = Exact<{
  brand: Brand;
  payload: UpdateCfExtColorInput;
}>;


export type UpdateCfExtColorMutation = (
  { __typename?: 'Mutation' }
  & { updateCFExtColor: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateCfIntColorMutationVariables = Exact<{
  brand: Brand;
  payload: UpdateCfIntColorInput;
}>;


export type UpdateCfIntColorMutation = (
  { __typename?: 'Mutation' }
  & { updateCFIntColor: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateColorFamilyMutationVariables = Exact<{
  brand: Brand;
  payload: UpdateColorFamilyInput;
}>;


export type UpdateColorFamilyMutation = (
  { __typename?: 'Mutation' }
  & { updateColorFamily: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateExtColorLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateExtColorLexusInput;
}>;


export type UpdateExtColorLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateExtColorLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateExtColorReviewMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
  payload: UpdateExtColorReviewInput;
}>;


export type UpdateExtColorReviewMutation = (
  { __typename?: 'Mutation' }
  & { updateExtColorReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateExtColorStatusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateExtColorStatusInput;
}>;


export type UpdateExtColorStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateExtColorStatus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateExtColorStatusAllMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  status: Scalars['Int'];
}>;


export type UpdateExtColorStatusAllMutation = (
  { __typename?: 'Mutation' }
  & { updateExtColorStatusAll: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateExtColorToyotaMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateExtColorToyotaInput;
}>;


export type UpdateExtColorToyotaMutation = (
  { __typename?: 'Mutation' }
  & { updateExtColorToyota: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateGroupTypesLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateGroupTypesLexusInput;
}>;


export type UpdateGroupTypesLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupTypesLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateIntColorLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateIntColorLexusInput;
}>;


export type UpdateIntColorLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateIntColorLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateIntColorReviewMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
  payload: UpdateIntColorReviewInput;
}>;


export type UpdateIntColorReviewMutation = (
  { __typename?: 'Mutation' }
  & { updateIntColorReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateIntColorStatusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateIntColorStatusInput;
}>;


export type UpdateIntColorStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateIntColorStatus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateIntColorToyotaMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateIntColorToyotaInput;
}>;


export type UpdateIntColorToyotaMutation = (
  { __typename?: 'Mutation' }
  & { updateIntColorToyota: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateIntColorTypesLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateIntColorTypesLexusInput;
}>;


export type UpdateIntColorTypesLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateIntColorTypesLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateMaterialsMutationVariables = Exact<{
  brand: Brand;
  payload: UpdateMaterialInput;
}>;


export type UpdateMaterialsMutation = (
  { __typename?: 'Mutation' }
  & { updateMaterials: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehicleModelMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: CreateVehicleModelInput;
}>;


export type CreateVehicleModelMutation = (
  { __typename?: 'Mutation' }
  & { createVehicleModel: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehicleModelGradesMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelGradeInput;
}>;


export type CreateVehicleModelGradesMutation = (
  { __typename?: 'Mutation' }
  & { createVehicleModelGrades: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehicleModelLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelLexusInput;
}>;


export type CreateVehicleModelLexusMutation = (
  { __typename?: 'Mutation' }
  & { createVehicleModelLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehicleModelSeriesMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: CreateVehicleModelSeriesInput;
}>;


export type CreateVehicleModelSeriesMutation = (
  { __typename?: 'Mutation' }
  & { createVehicleModelSeries: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteVehicleModelMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  deleteVehicleModelId: Scalars['ID'];
}>;


export type DeleteVehicleModelMutation = (
  { __typename?: 'Mutation' }
  & { deleteVehicleModel: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteVehicleModelSeriesMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  seriesSettingId: Scalars['String'];
  subSeriesSettingId?: Maybe<Scalars['String']>;
}>;


export type DeleteVehicleModelSeriesMutation = (
  { __typename?: 'Mutation' }
  & { deleteVehicleModelSeries: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleModelChangesQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter?: Maybe<FilterInput>;
}>;


export type ReadVehicleModelChangesQuery = (
  { __typename?: 'Query' }
  & { readVehicleModelChanges: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleModelGradesQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  includeAll: Scalars['Boolean'];
  filter?: Maybe<FilterInput>;
}>;


export type ReadVehicleModelGradesQuery = (
  { __typename?: 'Query' }
  & { readVehicleModelGrades: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleModelGradesReviewsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter?: Maybe<FilterInput>;
}>;


export type ReadVehicleModelGradesReviewsQuery = (
  { __typename?: 'Query' }
  & { readVehicleModelGradesReviews: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleModelMapsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  versionMap: Scalars['JSON'];
}>;


export type ReadVehicleModelMapsQuery = (
  { __typename?: 'Query' }
  & { readVehicleModelMaps: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleModelReviewsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter?: Maybe<FilterInput>;
}>;


export type ReadVehicleModelReviewsQuery = (
  { __typename?: 'Query' }
  & { readVehicleModelReviews: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleModelSeriesQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  includeAll: Scalars['Boolean'];
  filter?: Maybe<FilterInput>;
}>;


export type ReadVehicleModelSeriesQuery = (
  { __typename?: 'Query' }
  & { readVehicleModelSeries: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleModelSeriesReviewsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter?: Maybe<FilterInput>;
}>;


export type ReadVehicleModelSeriesReviewsQuery = (
  { __typename?: 'Query' }
  & { readVehicleModelSeriesReviews: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleModelsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  includeAll: Scalars['Boolean'];
  filter?: Maybe<FilterInput>;
}>;


export type ReadVehicleModelsQuery = (
  { __typename?: 'Query' }
  & { readVehicleModels: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type RevertVehicleModelChangeMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: RevertVehicleModelChangeInput;
}>;


export type RevertVehicleModelChangeMutation = (
  { __typename?: 'Mutation' }
  & { revertVehicleModelChange: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type SortVehicleModelsMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: Array<SortVehicleModelsInput> | SortVehicleModelsInput;
}>;


export type SortVehicleModelsMutation = (
  { __typename?: 'Mutation' }
  & { sortVehicleModels: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleModelMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateVehicleModelInput;
}>;


export type UpdateVehicleModelMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleModel: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleModelGradesMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateVehicleModelGradeInput;
}>;


export type UpdateVehicleModelGradesMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleModelGrades: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleModelLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelLexusInput;
}>;


export type UpdateVehicleModelLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleModelLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleModelReviewMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelReviewInput;
  filter: FilterInput;
}>;


export type UpdateVehicleModelReviewMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleModelReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleModelSeriesMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateVehicleModelSeriesInput;
}>;


export type UpdateVehicleModelSeriesMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleModelSeries: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleModelSeriesStatusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateVehicleModelSeriesStatusInput;
}>;


export type UpdateVehicleModelSeriesStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleModelSeriesStatus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleModelStatusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateVehicleModelStatusInput;
}>;


export type UpdateVehicleModelStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleModelStatus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleModelStatusAllMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  status: Scalars['Int'];
}>;


export type UpdateVehicleModelStatusAllMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleModelStatusAll: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleModelsGoLiveDateMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  goLiveDate: Scalars['String'];
  payload: Scalars['JSON'];
}>;


export type UpdateVehicleModelsGoLiveDateMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleModelsGoLiveDate: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehicleOptionMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: CreateVehicleOptionInput;
}>;


export type CreateVehicleOptionMutation = (
  { __typename?: 'Mutation' }
  & { createVehicleOption: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehicleOptionLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateVehicleOptionLexusInput;
}>;


export type CreateVehicleOptionLexusMutation = (
  { __typename?: 'Mutation' }
  & { createVehicleOptionLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehicleOptionSpecLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateVehicleOptionSpecInput;
  optionTab: Scalars['String'];
}>;


export type CreateVehicleOptionSpecLexusMutation = (
  { __typename?: 'Mutation' }
  & { createVehicleOptionSpecLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehicleOptionsCategoriesMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateVehicleOptionCategoriesInput;
}>;


export type CreateVehicleOptionsCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { createVehicleOptionsCategories: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehiclePackageLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateVehiclePackageLexusInput;
}>;


export type CreateVehiclePackageLexusMutation = (
  { __typename?: 'Mutation' }
  & { createVehiclePackageLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateVehiclePackageVarietyLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: CreateVehiclePackageVarietyLexusInput;
}>;


export type CreateVehiclePackageVarietyLexusMutation = (
  { __typename?: 'Mutation' }
  & { createVehiclePackageVarietyLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteVehicleOptionMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  deleteVehicleOptionId: Scalars['ID'];
  optionTab?: Maybe<Scalars['String']>;
}>;


export type DeleteVehicleOptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteVehicleOption: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteVehicleOptionSpecLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  parentId: Scalars['ID'];
  specId: Scalars['ID'];
  optionTab: Scalars['String'];
}>;


export type DeleteVehicleOptionSpecLexusMutation = (
  { __typename?: 'Mutation' }
  & { deleteVehicleOptionSpecLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type DeleteVehiclePackageVarietyLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  parentId: Scalars['ID'];
  specId: Scalars['ID'];
}>;


export type DeleteVehiclePackageVarietyLexusMutation = (
  { __typename?: 'Mutation' }
  & { deleteVehiclePackageVarietyLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleOptionCategoriesReviewQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
}>;


export type ReadVehicleOptionCategoriesReviewQuery = (
  { __typename?: 'Query' }
  & { readVehicleOptionCategoriesReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleOptionChangesQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter: FilterInput;
  optionTab?: Maybe<Scalars['String']>;
}>;


export type ReadVehicleOptionChangesQuery = (
  { __typename?: 'Query' }
  & { readVehicleOptionChanges: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleOptionReviewQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
  optionTab?: Maybe<Scalars['String']>;
}>;


export type ReadVehicleOptionReviewQuery = (
  { __typename?: 'Query' }
  & { readVehicleOptionReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleOptionSpecLexusQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
  optionTab?: Maybe<Scalars['String']>;
}>;


export type ReadVehicleOptionSpecLexusQuery = (
  { __typename?: 'Query' }
  & { readVehicleOptionSpecLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleOptionsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter: FilterInput;
  optionTab?: Maybe<Scalars['String']>;
}>;


export type ReadVehicleOptionsQuery = (
  { __typename?: 'Query' }
  & { readVehicleOptions: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleOptionsCategoriesQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  filter: FilterInput;
  includeAll: Scalars['Boolean'];
}>;


export type ReadVehicleOptionsCategoriesQuery = (
  { __typename?: 'Query' }
  & { readVehicleOptionsCategories: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehiclePackagesVarietyLexusQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  filter: FilterInput;
}>;


export type ReadVehiclePackagesVarietyLexusQuery = (
  { __typename?: 'Query' }
  & { readVehiclePackagesVarietyLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type RevertVehicleOptionChangeMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: RevertVehicleOptionChangeInput;
  optionTab?: Maybe<Scalars['String']>;
}>;


export type RevertVehicleOptionChangeMutation = (
  { __typename?: 'Mutation' }
  & { revertVehicleOptionChange: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleOptionMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateVehicleOptionInput;
}>;


export type UpdateVehicleOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleOption: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleOptionLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionLexusInput;
}>;


export type UpdateVehicleOptionLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleOptionLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleOptionReviewMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionReviewInput;
  filter: FilterInput;
  optionTab?: Maybe<Scalars['String']>;
}>;


export type UpdateVehicleOptionReviewMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleOptionReview: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleOptionSpecLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionSpecInput;
  optionTab: Scalars['String'];
}>;


export type UpdateVehicleOptionSpecLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleOptionSpecLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleOptionSpecReviewLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionSpecReviewInput;
  filter: FilterInput;
  optionTab?: Maybe<Scalars['String']>;
}>;


export type UpdateVehicleOptionSpecReviewLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleOptionSpecReviewLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleOptionStatusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateVehicleOptionStatusInput;
}>;


export type UpdateVehicleOptionStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleOptionStatus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleOptionStatusAllMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  status: Scalars['Int'];
}>;


export type UpdateVehicleOptionStatusAllMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleOptionStatusAll: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehicleOptionsCategoriesMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  payload: UpdateVehicleOptionCategoriesInput;
}>;


export type UpdateVehicleOptionsCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicleOptionsCategories: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehiclePackageLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateVehiclePackageLexusInput;
}>;


export type UpdateVehiclePackageLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehiclePackageLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehiclePackageVarietyLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateVehiclePackageVarietyLexusInput;
}>;


export type UpdateVehiclePackageVarietyLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehiclePackageVarietyLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type UpdateVehiclePackageVarietyReviewLexusMutationVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  payload: UpdateVehiclePackageVarietyReviewInput;
  filter: FilterInput;
  optionTab?: Maybe<Scalars['String']>;
}>;


export type UpdateVehiclePackageVarietyReviewLexusMutation = (
  { __typename?: 'Mutation' }
  & { updateVehiclePackageVarietyReviewLexus: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);


export const AddBnpCategorySplitDocument = gql`
    mutation AddBnpCategorySplit($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: AddBnpCategorySplitInput!) {
  addBnpCategorySplit(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type AddBnpCategorySplitMutationFn = Apollo.MutationFunction<AddBnpCategorySplitMutation, AddBnpCategorySplitMutationVariables>;
export type AddBnpCategorySplitMutationResult = Apollo.MutationResult<AddBnpCategorySplitMutation>;
export type AddBnpCategorySplitMutationOptions = Apollo.BaseMutationOptions<AddBnpCategorySplitMutation, AddBnpCategorySplitMutationVariables>;
export const DeleteBnpCategorySplitDocument = gql`
    mutation DeleteBnpCategorySplit($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: DeleteBnpCategorySplitInput!) {
  deleteBnpCategorySplit(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type DeleteBnpCategorySplitMutationFn = Apollo.MutationFunction<DeleteBnpCategorySplitMutation, DeleteBnpCategorySplitMutationVariables>;
export type DeleteBnpCategorySplitMutationResult = Apollo.MutationResult<DeleteBnpCategorySplitMutation>;
export type DeleteBnpCategorySplitMutationOptions = Apollo.BaseMutationOptions<DeleteBnpCategorySplitMutation, DeleteBnpCategorySplitMutationVariables>;
export const ReadBnpChangesDocument = gql`
    query ReadBnpChanges($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput!) {
  readBnpChanges(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadBnpChangesQueryResult = Apollo.QueryResult<ReadBnpChangesQuery, ReadBnpChangesQueryVariables>;
export const ReadBnpItemsDocument = gql`
    query ReadBnpItems($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput!) {
  readBnpItems(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadBnpItemsQueryResult = Apollo.QueryResult<ReadBnpItemsQuery, ReadBnpItemsQueryVariables>;
export const ReadBnpReviewDocument = gql`
    query ReadBnpReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!) {
  readBnpReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadBnpReviewQueryResult = Apollo.QueryResult<ReadBnpReviewQuery, ReadBnpReviewQueryVariables>;
export const RevertBnpChangeDocument = gql`
    mutation RevertBnpChange($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: RevertBnpChangeInput!) {
  revertBnpChange(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type RevertBnpChangeMutationFn = Apollo.MutationFunction<RevertBnpChangeMutation, RevertBnpChangeMutationVariables>;
export type RevertBnpChangeMutationResult = Apollo.MutationResult<RevertBnpChangeMutation>;
export type RevertBnpChangeMutationOptions = Apollo.BaseMutationOptions<RevertBnpChangeMutation, RevertBnpChangeMutationVariables>;
export const UpdateBnpCategoryApplicabilityDocument = gql`
    mutation UpdateBnpCategoryApplicability($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateBnpCategoryApplicabilityInput!) {
  updateBnpCategoryApplicability(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateBnpCategoryApplicabilityMutationFn = Apollo.MutationFunction<UpdateBnpCategoryApplicabilityMutation, UpdateBnpCategoryApplicabilityMutationVariables>;
export type UpdateBnpCategoryApplicabilityMutationResult = Apollo.MutationResult<UpdateBnpCategoryApplicabilityMutation>;
export type UpdateBnpCategoryApplicabilityMutationOptions = Apollo.BaseMutationOptions<UpdateBnpCategoryApplicabilityMutation, UpdateBnpCategoryApplicabilityMutationVariables>;
export const UpdateBnpItemDescriptionDocument = gql`
    mutation UpdateBnpItemDescription($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateBnpItemDescriptionInput!) {
  updateBnpItemDescription(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateBnpItemDescriptionMutationFn = Apollo.MutationFunction<UpdateBnpItemDescriptionMutation, UpdateBnpItemDescriptionMutationVariables>;
export type UpdateBnpItemDescriptionMutationResult = Apollo.MutationResult<UpdateBnpItemDescriptionMutation>;
export type UpdateBnpItemDescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateBnpItemDescriptionMutation, UpdateBnpItemDescriptionMutationVariables>;
export const UpdateBnpItemNameDocument = gql`
    mutation UpdateBnpItemName($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateBnpItemNameInput!) {
  updateBnpItemName(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateBnpItemNameMutationFn = Apollo.MutationFunction<UpdateBnpItemNameMutation, UpdateBnpItemNameMutationVariables>;
export type UpdateBnpItemNameMutationResult = Apollo.MutationResult<UpdateBnpItemNameMutation>;
export type UpdateBnpItemNameMutationOptions = Apollo.BaseMutationOptions<UpdateBnpItemNameMutation, UpdateBnpItemNameMutationVariables>;
export const UpdateBnpItemTitleCopyDocument = gql`
    mutation UpdateBnpItemTitleCopy($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateBnpItemTitleCopyInput!) {
  updateBnpItemTitleCopy(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateBnpItemTitleCopyMutationFn = Apollo.MutationFunction<UpdateBnpItemTitleCopyMutation, UpdateBnpItemTitleCopyMutationVariables>;
export type UpdateBnpItemTitleCopyMutationResult = Apollo.MutationResult<UpdateBnpItemTitleCopyMutation>;
export type UpdateBnpItemTitleCopyMutationOptions = Apollo.BaseMutationOptions<UpdateBnpItemTitleCopyMutation, UpdateBnpItemTitleCopyMutationVariables>;
export const UpdateBnpReviewDocument = gql`
    mutation UpdateBnpReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateBnpReviewInput!, $filter: FilterInput!) {
  updateBnpReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
    filter: $filter
  ) {
    body
  }
}
    `;
export type UpdateBnpReviewMutationFn = Apollo.MutationFunction<UpdateBnpReviewMutation, UpdateBnpReviewMutationVariables>;
export type UpdateBnpReviewMutationResult = Apollo.MutationResult<UpdateBnpReviewMutation>;
export type UpdateBnpReviewMutationOptions = Apollo.BaseMutationOptions<UpdateBnpReviewMutation, UpdateBnpReviewMutationVariables>;
export const UpdateBnpStatusDocument = gql`
    mutation UpdateBnpStatus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateBnpStatusInput!) {
  updateBnpStatus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateBnpStatusMutationFn = Apollo.MutationFunction<UpdateBnpStatusMutation, UpdateBnpStatusMutationVariables>;
export type UpdateBnpStatusMutationResult = Apollo.MutationResult<UpdateBnpStatusMutation>;
export type UpdateBnpStatusMutationOptions = Apollo.BaseMutationOptions<UpdateBnpStatusMutation, UpdateBnpStatusMutationVariables>;
export const ArchiveCommonLanguageSpecDocument = gql`
    mutation ArchiveCommonLanguageSpec($brand: Brand!, $payload: ArchiveCommonLanguageSpecInput!) {
  archiveCommonLanguageSpec(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type ArchiveCommonLanguageSpecMutationFn = Apollo.MutationFunction<ArchiveCommonLanguageSpecMutation, ArchiveCommonLanguageSpecMutationVariables>;
export type ArchiveCommonLanguageSpecMutationResult = Apollo.MutationResult<ArchiveCommonLanguageSpecMutation>;
export type ArchiveCommonLanguageSpecMutationOptions = Apollo.BaseMutationOptions<ArchiveCommonLanguageSpecMutation, ArchiveCommonLanguageSpecMutationVariables>;
export const CreateCommonLanguageCategoryDocument = gql`
    mutation CreateCommonLanguageCategory($brand: Brand!, $categories: [String!]!) {
  createCommonLanguageCategory(brand: $brand, categories: $categories) {
    body
  }
}
    `;
export type CreateCommonLanguageCategoryMutationFn = Apollo.MutationFunction<CreateCommonLanguageCategoryMutation, CreateCommonLanguageCategoryMutationVariables>;
export type CreateCommonLanguageCategoryMutationResult = Apollo.MutationResult<CreateCommonLanguageCategoryMutation>;
export type CreateCommonLanguageCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCommonLanguageCategoryMutation, CreateCommonLanguageCategoryMutationVariables>;
export const CreateCommonLanguageSpecDocument = gql`
    mutation CreateCommonLanguageSpec($brand: Brand!, $payload: CreateCommonLanguageSpecInput!) {
  createCommonLanguageSpec(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateCommonLanguageSpecMutationFn = Apollo.MutationFunction<CreateCommonLanguageSpecMutation, CreateCommonLanguageSpecMutationVariables>;
export type CreateCommonLanguageSpecMutationResult = Apollo.MutationResult<CreateCommonLanguageSpecMutation>;
export type CreateCommonLanguageSpecMutationOptions = Apollo.BaseMutationOptions<CreateCommonLanguageSpecMutation, CreateCommonLanguageSpecMutationVariables>;
export const DeleteCommonLanguageSpecDocument = gql`
    mutation DeleteCommonLanguageSpec($brand: Brand!, $payload: DeleteCommonLanguageSpecInput!) {
  deleteCommonLanguageSpec(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type DeleteCommonLanguageSpecMutationFn = Apollo.MutationFunction<DeleteCommonLanguageSpecMutation, DeleteCommonLanguageSpecMutationVariables>;
export type DeleteCommonLanguageSpecMutationResult = Apollo.MutationResult<DeleteCommonLanguageSpecMutation>;
export type DeleteCommonLanguageSpecMutationOptions = Apollo.BaseMutationOptions<DeleteCommonLanguageSpecMutation, DeleteCommonLanguageSpecMutationVariables>;
export const GenerateCommonLanguageReportDocument = gql`
    query GenerateCommonLanguageReport($brand: Brand!, $version: String!) {
  generateCommonLanguageReport(brand: $brand, version: $version) {
    body
  }
}
    `;
export type GenerateCommonLanguageReportQueryResult = Apollo.QueryResult<GenerateCommonLanguageReportQuery, GenerateCommonLanguageReportQueryVariables>;
export const GetCommonLanguageVersionsDocument = gql`
    query GetCommonLanguageVersions($brand: Brand!) {
  getCommonLanguageVersions(brand: $brand) {
    body
  }
}
    `;
export type GetCommonLanguageVersionsQueryResult = Apollo.QueryResult<GetCommonLanguageVersionsQuery, GetCommonLanguageVersionsQueryVariables>;
export const PublishCommonLanguageDraftDocument = gql`
    mutation PublishCommonLanguageDraft($brand: Brand!) {
  publishCommonLanguageDraft(brand: $brand) {
    body
  }
}
    `;
export type PublishCommonLanguageDraftMutationFn = Apollo.MutationFunction<PublishCommonLanguageDraftMutation, PublishCommonLanguageDraftMutationVariables>;
export type PublishCommonLanguageDraftMutationResult = Apollo.MutationResult<PublishCommonLanguageDraftMutation>;
export type PublishCommonLanguageDraftMutationOptions = Apollo.BaseMutationOptions<PublishCommonLanguageDraftMutation, PublishCommonLanguageDraftMutationVariables>;
export const ReadCommonLanguageCategoriesDocument = gql`
    query ReadCommonLanguageCategories($brand: Brand!) {
  readCommonLanguageCategories(brand: $brand) {
    body
  }
}
    `;
export type ReadCommonLanguageCategoriesQueryResult = Apollo.QueryResult<ReadCommonLanguageCategoriesQuery, ReadCommonLanguageCategoriesQueryVariables>;
export const ResetCommonLanguageDraftDocument = gql`
    mutation ResetCommonLanguageDraft($brand: Brand!) {
  resetCommonLanguageDraft(brand: $brand) {
    body
  }
}
    `;
export type ResetCommonLanguageDraftMutationFn = Apollo.MutationFunction<ResetCommonLanguageDraftMutation, ResetCommonLanguageDraftMutationVariables>;
export type ResetCommonLanguageDraftMutationResult = Apollo.MutationResult<ResetCommonLanguageDraftMutation>;
export type ResetCommonLanguageDraftMutationOptions = Apollo.BaseMutationOptions<ResetCommonLanguageDraftMutation, ResetCommonLanguageDraftMutationVariables>;
export const RevertCommonLanguageChangeDocument = gql`
    mutation RevertCommonLanguageChange($brand: Brand!, $payload: RevertCommonLanguageChangeInput!) {
  revertCommonLanguageChange(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type RevertCommonLanguageChangeMutationFn = Apollo.MutationFunction<RevertCommonLanguageChangeMutation, RevertCommonLanguageChangeMutationVariables>;
export type RevertCommonLanguageChangeMutationResult = Apollo.MutationResult<RevertCommonLanguageChangeMutation>;
export type RevertCommonLanguageChangeMutationOptions = Apollo.BaseMutationOptions<RevertCommonLanguageChangeMutation, RevertCommonLanguageChangeMutationVariables>;
export const SearchCommonLanguageChangeLogDocument = gql`
    query SearchCommonLanguageChangeLog($brand: Brand!, $filter: FilterInput!) {
  searchCommonLanguageChangeLog(brand: $brand, filter: $filter) {
    body
  }
}
    `;
export type SearchCommonLanguageChangeLogQueryResult = Apollo.QueryResult<SearchCommonLanguageChangeLogQuery, SearchCommonLanguageChangeLogQueryVariables>;
export const SearchCommonLanguageSpecsDocument = gql`
    query SearchCommonLanguageSpecs($brand: Brand!, $filter: FilterInput!, $modelYear: String, $seriesId: String) {
  searchCommonLanguageSpecs(
    brand: $brand
    filter: $filter
    modelYear: $modelYear
    seriesId: $seriesId
  ) {
    body
  }
}
    `;
export type SearchCommonLanguageSpecsQueryResult = Apollo.QueryResult<SearchCommonLanguageSpecsQuery, SearchCommonLanguageSpecsQueryVariables>;
export const UpdateCommonLanguageCategoryDocument = gql`
    mutation UpdateCommonLanguageCategory($brand: Brand!, $payload: UpdateCommonLanguageCategoryInput!) {
  updateCommonLanguageCategory(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type UpdateCommonLanguageCategoryMutationFn = Apollo.MutationFunction<UpdateCommonLanguageCategoryMutation, UpdateCommonLanguageCategoryMutationVariables>;
export type UpdateCommonLanguageCategoryMutationResult = Apollo.MutationResult<UpdateCommonLanguageCategoryMutation>;
export type UpdateCommonLanguageCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCommonLanguageCategoryMutation, UpdateCommonLanguageCategoryMutationVariables>;
export const UpdateCommonLanguageSpecDocument = gql`
    mutation UpdateCommonLanguageSpec($brand: Brand!, $payload: UpdateCommonLanguageSpecInput!) {
  updateCommonLanguageSpec(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type UpdateCommonLanguageSpecMutationFn = Apollo.MutationFunction<UpdateCommonLanguageSpecMutation, UpdateCommonLanguageSpecMutationVariables>;
export type UpdateCommonLanguageSpecMutationResult = Apollo.MutationResult<UpdateCommonLanguageSpecMutation>;
export type UpdateCommonLanguageSpecMutationOptions = Apollo.BaseMutationOptions<UpdateCommonLanguageSpecMutation, UpdateCommonLanguageSpecMutationVariables>;
export const AddDisclaimerDocument = gql`
    mutation AddDisclaimer($brand: Brand!, $region: Region!, $payload: JSON!) {
  createDisclaimer(brand: $brand, region: $region, payload: $payload) {
    body
  }
}
    `;
export type AddDisclaimerMutationFn = Apollo.MutationFunction<AddDisclaimerMutation, AddDisclaimerMutationVariables>;
export type AddDisclaimerMutationResult = Apollo.MutationResult<AddDisclaimerMutation>;
export type AddDisclaimerMutationOptions = Apollo.BaseMutationOptions<AddDisclaimerMutation, AddDisclaimerMutationVariables>;
export const ArchiveDisclaimerDocument = gql`
    mutation ArchiveDisclaimer($brand: Brand!, $region: Region!, $payload: ArchiveDisclaimerInput!) {
  archiveDisclaimer(brand: $brand, region: $region, payload: $payload) {
    body {
      id
      revId
      token
      description
      description_es
      notes
      lastPublishedVersion
      lastPublishedDate
      isArchived
    }
  }
}
    `;
export type ArchiveDisclaimerMutationFn = Apollo.MutationFunction<ArchiveDisclaimerMutation, ArchiveDisclaimerMutationVariables>;
export type ArchiveDisclaimerMutationResult = Apollo.MutationResult<ArchiveDisclaimerMutation>;
export type ArchiveDisclaimerMutationOptions = Apollo.BaseMutationOptions<ArchiveDisclaimerMutation, ArchiveDisclaimerMutationVariables>;
export const DeleteDisclaimerDocument = gql`
    mutation DeleteDisclaimer($brand: Brand!, $region: Region!, $payload: JSON!) {
  deleteDisclaimer(brand: $brand, region: $region, payload: $payload) {
    body
  }
}
    `;
export type DeleteDisclaimerMutationFn = Apollo.MutationFunction<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>;
export type DeleteDisclaimerMutationResult = Apollo.MutationResult<DeleteDisclaimerMutation>;
export type DeleteDisclaimerMutationOptions = Apollo.BaseMutationOptions<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>;
export const DownloadDisclaimersDocument = gql`
    query DownloadDisclaimers($brand: Brand!, $region: Region!, $version: String!, $lang: Language!) {
  downloadDisclaimers(
    brand: $brand
    region: $region
    version: $version
    lang: $lang
  ) {
    body
  }
}
    `;
export type DownloadDisclaimersQueryResult = Apollo.QueryResult<DownloadDisclaimersQuery, DownloadDisclaimersQueryVariables>;
export const GetDisclaimerCurrentVersionDocument = gql`
    query GetDisclaimerCurrentVersion($brand: Brand!, $region: Region!) {
  getDisclaimerCurrentVersion(brand: $brand, region: $region) {
    body
  }
}
    `;
export type GetDisclaimerCurrentVersionQueryResult = Apollo.QueryResult<GetDisclaimerCurrentVersionQuery, GetDisclaimerCurrentVersionQueryVariables>;
export const GetDisclaimerVersionsDocument = gql`
    query GetDisclaimerVersions($brand: Brand!, $region: Region!) {
  getDisclaimerVersions(brand: $brand, region: $region) {
    body
  }
}
    `;
export type GetDisclaimerVersionsQueryResult = Apollo.QueryResult<GetDisclaimerVersionsQuery, GetDisclaimerVersionsQueryVariables>;
export const PublishDisclaimersToDownstreamDocument = gql`
    mutation PublishDisclaimersToDownstream($brand: Brand!, $region: Region!, $lang: Language!) {
  publishDisclaimersToDownstream(brand: $brand, region: $region, lang: $lang) {
    body
  }
}
    `;
export type PublishDisclaimersToDownstreamMutationFn = Apollo.MutationFunction<PublishDisclaimersToDownstreamMutation, PublishDisclaimersToDownstreamMutationVariables>;
export type PublishDisclaimersToDownstreamMutationResult = Apollo.MutationResult<PublishDisclaimersToDownstreamMutation>;
export type PublishDisclaimersToDownstreamMutationOptions = Apollo.BaseMutationOptions<PublishDisclaimersToDownstreamMutation, PublishDisclaimersToDownstreamMutationVariables>;
export const PublishDraftDisclaimerDocument = gql`
    mutation PublishDraftDisclaimer($brand: Brand!, $region: Region!) {
  publishDraftDisclaimer(brand: $brand, region: $region) {
    body
  }
}
    `;
export type PublishDraftDisclaimerMutationFn = Apollo.MutationFunction<PublishDraftDisclaimerMutation, PublishDraftDisclaimerMutationVariables>;
export type PublishDraftDisclaimerMutationResult = Apollo.MutationResult<PublishDraftDisclaimerMutation>;
export type PublishDraftDisclaimerMutationOptions = Apollo.BaseMutationOptions<PublishDraftDisclaimerMutation, PublishDraftDisclaimerMutationVariables>;
export const ResetDraftDisclaimersDocument = gql`
    mutation ResetDraftDisclaimers($brand: Brand!, $region: Region!) {
  resetDraftDisclaimers(brand: $brand, region: $region) {
    body
  }
}
    `;
export type ResetDraftDisclaimersMutationFn = Apollo.MutationFunction<ResetDraftDisclaimersMutation, ResetDraftDisclaimersMutationVariables>;
export type ResetDraftDisclaimersMutationResult = Apollo.MutationResult<ResetDraftDisclaimersMutation>;
export type ResetDraftDisclaimersMutationOptions = Apollo.BaseMutationOptions<ResetDraftDisclaimersMutation, ResetDraftDisclaimersMutationVariables>;
export const RevertDisclaimerChangeDocument = gql`
    mutation RevertDisclaimerChange($brand: Brand!, $region: Region!, $payload: JSON!) {
  revertDisclaimerChange(brand: $brand, region: $region, payload: $payload) {
    body
  }
}
    `;
export type RevertDisclaimerChangeMutationFn = Apollo.MutationFunction<RevertDisclaimerChangeMutation, RevertDisclaimerChangeMutationVariables>;
export type RevertDisclaimerChangeMutationResult = Apollo.MutationResult<RevertDisclaimerChangeMutation>;
export type RevertDisclaimerChangeMutationOptions = Apollo.BaseMutationOptions<RevertDisclaimerChangeMutation, RevertDisclaimerChangeMutationVariables>;
export const SearchDisclaimerChangeLogDocument = gql`
    query SearchDisclaimerChangeLog($brand: Brand!, $region: Region!, $filter: FilterInput) {
  searchDisclaimerChangeLog(brand: $brand, region: $region, filter: $filter) {
    body
  }
}
    `;
export type SearchDisclaimerChangeLogQueryResult = Apollo.QueryResult<SearchDisclaimerChangeLogQuery, SearchDisclaimerChangeLogQueryVariables>;
export const SearchDisclaimerTokensDocument = gql`
    query SearchDisclaimerTokens($brand: Brand!, $region: Region!) {
  searchDisclaimerTokens(brand: $brand, region: $region) {
    body
  }
}
    `;
export type SearchDisclaimerTokensQueryResult = Apollo.QueryResult<SearchDisclaimerTokensQuery, SearchDisclaimerTokensQueryVariables>;
export const SearchDisclaimersDocument = gql`
    query SearchDisclaimers($brand: Brand!, $region: Region!, $filter: FilterInput) {
  searchDisclaimers(brand: $brand, region: $region, filter: $filter) {
    body
  }
}
    `;
export type SearchDisclaimersQueryResult = Apollo.QueryResult<SearchDisclaimersQuery, SearchDisclaimersQueryVariables>;
export const UpdateDisclaimerDocument = gql`
    mutation UpdateDisclaimer($brand: Brand!, $region: Region!, $payload: JSON!) {
  updateDisclaimer(brand: $brand, region: $region, payload: $payload) {
    body
  }
}
    `;
export type UpdateDisclaimerMutationFn = Apollo.MutationFunction<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>;
export type UpdateDisclaimerMutationResult = Apollo.MutationResult<UpdateDisclaimerMutation>;
export type UpdateDisclaimerMutationOptions = Apollo.BaseMutationOptions<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>;
export const UploadDisclaimersDocument = gql`
    mutation UploadDisclaimers($brand: Brand!, $region: Region!, $lang: Language!, $version: Int!, $payload: JSON!) {
  uploadDisclaimers(
    brand: $brand
    region: $region
    lang: $lang
    version: $version
    payload: $payload
  ) {
    body
  }
}
    `;
export type UploadDisclaimersMutationFn = Apollo.MutationFunction<UploadDisclaimersMutation, UploadDisclaimersMutationVariables>;
export type UploadDisclaimersMutationResult = Apollo.MutationResult<UploadDisclaimersMutation>;
export type UploadDisclaimersMutationOptions = Apollo.BaseMutationOptions<UploadDisclaimersMutation, UploadDisclaimersMutationVariables>;
export const CreateDphCategoryDocument = gql`
    mutation CreateDPHCategory($brand: Brand!, $region: Region!, $payload: CreateDPHCategoryInput!) {
  createDPHCategory(brand: $brand, region: $region, payload: $payload) {
    body
  }
}
    `;
export type CreateDphCategoryMutationFn = Apollo.MutationFunction<CreateDphCategoryMutation, CreateDphCategoryMutationVariables>;
export type CreateDphCategoryMutationResult = Apollo.MutationResult<CreateDphCategoryMutation>;
export type CreateDphCategoryMutationOptions = Apollo.BaseMutationOptions<CreateDphCategoryMutation, CreateDphCategoryMutationVariables>;
export const DeleteDphCategoryDocument = gql`
    mutation DeleteDPHCategory($brand: Brand!, $region: Region!, $payload: DeleteDPHCategoryInput!) {
  deleteDPHCategory(brand: $brand, region: $region, payload: $payload) {
    body
  }
}
    `;
export type DeleteDphCategoryMutationFn = Apollo.MutationFunction<DeleteDphCategoryMutation, DeleteDphCategoryMutationVariables>;
export type DeleteDphCategoryMutationResult = Apollo.MutationResult<DeleteDphCategoryMutation>;
export type DeleteDphCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteDphCategoryMutation, DeleteDphCategoryMutationVariables>;
export const PublishDphDocument = gql`
    mutation PublishDPH($brand: Brand!, $region: Region!) {
  publishDPH(brand: $brand, region: $region) {
    body
  }
}
    `;
export type PublishDphMutationFn = Apollo.MutationFunction<PublishDphMutation, PublishDphMutationVariables>;
export type PublishDphMutationResult = Apollo.MutationResult<PublishDphMutation>;
export type PublishDphMutationOptions = Apollo.BaseMutationOptions<PublishDphMutation, PublishDphMutationVariables>;
export const ReadDphCategoriesDocument = gql`
    query ReadDPHCategories($brand: Brand!, $region: Region!) {
  readDPHCategories(brand: $brand, region: $region) {
    body
  }
}
    `;
export type ReadDphCategoriesQueryResult = Apollo.QueryResult<ReadDphCategoriesQuery, ReadDphCategoriesQueryVariables>;
export const UpdateDphCategoryDocument = gql`
    mutation UpdateDPHCategory($brand: Brand!, $region: Region!, $payload: UpdateDPHCategoryInput!) {
  updateDPHCategory(brand: $brand, region: $region, payload: $payload) {
    body
  }
}
    `;
export type UpdateDphCategoryMutationFn = Apollo.MutationFunction<UpdateDphCategoryMutation, UpdateDphCategoryMutationVariables>;
export type UpdateDphCategoryMutationResult = Apollo.MutationResult<UpdateDphCategoryMutation>;
export type UpdateDphCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateDphCategoryMutation, UpdateDphCategoryMutationVariables>;
export const GetFuelTypesDocument = gql`
    query GetFuelTypes($brand: Brand!) {
  getFuelTypes(brand: $brand) {
    body
  }
}
    `;
export type GetFuelTypesQueryResult = Apollo.QueryResult<GetFuelTypesQuery, GetFuelTypesQueryVariables>;
export const ReadAllKeyFeaturesDocument = gql`
    query ReadAllKeyFeatures($brand: Brand!) {
  readAllKeyFeatures(brand: $brand) {
    body
  }
}
    `;
export type ReadAllKeyFeaturesQueryResult = Apollo.QueryResult<ReadAllKeyFeaturesQuery, ReadAllKeyFeaturesQueryVariables>;
export const ReadKeyFeaturesDocument = gql`
    query ReadKeyFeatures($brand: Brand!, $team: Team!, $seriesId: String!, $language: Language!, $modelYear: Int!, $filter: String!) {
  readKeyFeatures(
    brand: $brand
    team: $team
    seriesId: $seriesId
    language: $language
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadKeyFeaturesQueryResult = Apollo.QueryResult<ReadKeyFeaturesQuery, ReadKeyFeaturesQueryVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($brand: Brand!, $reportType: String!, $reportData: CreateReportInput!) {
  createReport(brand: $brand, reportType: $reportType, reportData: $reportData) {
    body
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const ReadReportLogsDocument = gql`
    query ReadReportLogs($brand: Brand!, $reportType: String!) {
  readReportLogs(brand: $brand, reportType: $reportType) {
    body
  }
}
    `;
export type ReadReportLogsQueryResult = Apollo.QueryResult<ReadReportLogsQuery, ReadReportLogsQueryVariables>;
export const CreateSignedUrlDocument = gql`
    mutation CreateSignedUrl($brand: Brand!, $action: S3Action!, $key: String!, $read: Boolean) {
  createSignedUrl(brand: $brand, action: $action, key: $key, read: $read) {
    body
  }
}
    `;
export type CreateSignedUrlMutationFn = Apollo.MutationFunction<CreateSignedUrlMutation, CreateSignedUrlMutationVariables>;
export type CreateSignedUrlMutationResult = Apollo.MutationResult<CreateSignedUrlMutation>;
export type CreateSignedUrlMutationOptions = Apollo.BaseMutationOptions<CreateSignedUrlMutation, CreateSignedUrlMutationVariables>;
export const CreateSeriesDocument = gql`
    mutation CreateSeries($brand: Brand!, $payload: CreateSeriesInput!) {
  createSeries(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateSeriesMutationFn = Apollo.MutationFunction<CreateSeriesMutation, CreateSeriesMutationVariables>;
export type CreateSeriesMutationResult = Apollo.MutationResult<CreateSeriesMutation>;
export type CreateSeriesMutationOptions = Apollo.BaseMutationOptions<CreateSeriesMutation, CreateSeriesMutationVariables>;
export const CreateSeriesSelectionsDocument = gql`
    mutation CreateSeriesSelections($brand: Brand!, $payload: CreateSelectionsInput!) {
  createSeriesSelections(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateSeriesSelectionsMutationFn = Apollo.MutationFunction<CreateSeriesSelectionsMutation, CreateSeriesSelectionsMutationVariables>;
export type CreateSeriesSelectionsMutationResult = Apollo.MutationResult<CreateSeriesSelectionsMutation>;
export type CreateSeriesSelectionsMutationOptions = Apollo.BaseMutationOptions<CreateSeriesSelectionsMutation, CreateSeriesSelectionsMutationVariables>;
export const ReadSeriesDocument = gql`
    query ReadSeries($brand: Brand!) {
  readSeries(brand: $brand) {
    body
  }
}
    `;
export type ReadSeriesQueryResult = Apollo.QueryResult<ReadSeriesQuery, ReadSeriesQueryVariables>;
export const ReadSeriesSelectionsDocument = gql`
    query ReadSeriesSelections($brand: Brand!, $objectId: String!) {
  readSeriesSelections(brand: $brand, objectId: $objectId) {
    body
  }
}
    `;
export type ReadSeriesSelectionsQueryResult = Apollo.QueryResult<ReadSeriesSelectionsQuery, ReadSeriesSelectionsQueryVariables>;
export const UpdateSeriesDocument = gql`
    mutation UpdateSeries($brand: Brand!, $payload: UpdateSeriesInput!) {
  updateSeries(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type UpdateSeriesMutationFn = Apollo.MutationFunction<UpdateSeriesMutation, UpdateSeriesMutationVariables>;
export type UpdateSeriesMutationResult = Apollo.MutationResult<UpdateSeriesMutation>;
export type UpdateSeriesMutationOptions = Apollo.BaseMutationOptions<UpdateSeriesMutation, UpdateSeriesMutationVariables>;
export const CreateSeriesCategoryDocument = gql`
    mutation CreateSeriesCategory($brand: Brand!, $payload: CreateSeriesCategoryInput!) {
  createSeriesCategory(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateSeriesCategoryMutationFn = Apollo.MutationFunction<CreateSeriesCategoryMutation, CreateSeriesCategoryMutationVariables>;
export type CreateSeriesCategoryMutationResult = Apollo.MutationResult<CreateSeriesCategoryMutation>;
export type CreateSeriesCategoryMutationOptions = Apollo.BaseMutationOptions<CreateSeriesCategoryMutation, CreateSeriesCategoryMutationVariables>;
export const DeleteSeriesCategoryDocument = gql`
    mutation DeleteSeriesCategory($brand: Brand!, $seriesCategoryId: String!) {
  deleteSeriesCategory(brand: $brand, seriesCategoryId: $seriesCategoryId) {
    body
  }
}
    `;
export type DeleteSeriesCategoryMutationFn = Apollo.MutationFunction<DeleteSeriesCategoryMutation, DeleteSeriesCategoryMutationVariables>;
export type DeleteSeriesCategoryMutationResult = Apollo.MutationResult<DeleteSeriesCategoryMutation>;
export type DeleteSeriesCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteSeriesCategoryMutation, DeleteSeriesCategoryMutationVariables>;
export const PublishSeriesCategoriesDocument = gql`
    mutation PublishSeriesCategories($brand: Brand!) {
  publishSeriesCategories(brand: $brand)
}
    `;
export type PublishSeriesCategoriesMutationFn = Apollo.MutationFunction<PublishSeriesCategoriesMutation, PublishSeriesCategoriesMutationVariables>;
export type PublishSeriesCategoriesMutationResult = Apollo.MutationResult<PublishSeriesCategoriesMutation>;
export type PublishSeriesCategoriesMutationOptions = Apollo.BaseMutationOptions<PublishSeriesCategoriesMutation, PublishSeriesCategoriesMutationVariables>;
export const ReadSeriesCategoriesDocument = gql`
    query ReadSeriesCategories($brand: Brand!, $getLatestVersion: Boolean!) {
  readSeriesCategories(brand: $brand, getLatestVersion: $getLatestVersion) {
    body
  }
}
    `;
export type ReadSeriesCategoriesQueryResult = Apollo.QueryResult<ReadSeriesCategoriesQuery, ReadSeriesCategoriesQueryVariables>;
export const UpdateSeriesCategoryDocument = gql`
    mutation UpdateSeriesCategory($brand: Brand!, $payload: UpdateSeriesCategoryInput!) {
  updateSeriesCategory(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type UpdateSeriesCategoryMutationFn = Apollo.MutationFunction<UpdateSeriesCategoryMutation, UpdateSeriesCategoryMutationVariables>;
export type UpdateSeriesCategoryMutationResult = Apollo.MutationResult<UpdateSeriesCategoryMutation>;
export type UpdateSeriesCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateSeriesCategoryMutation, UpdateSeriesCategoryMutationVariables>;
export const SendSnsMessageDocument = gql`
    mutation SendSNSMessage($topic: String!, $message: String!) {
  sendSNSMessage(topic: $topic, message: $message)
}
    `;
export type SendSnsMessageMutationFn = Apollo.MutationFunction<SendSnsMessageMutation, SendSnsMessageMutationVariables>;
export type SendSnsMessageMutationResult = Apollo.MutationResult<SendSnsMessageMutation>;
export type SendSnsMessageMutationOptions = Apollo.BaseMutationOptions<SendSnsMessageMutation, SendSnsMessageMutationVariables>;
export const AddSystemSpecDocument = gql`
    mutation AddSystemSpec($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $systemSpec: [String!]!) {
  addSystemSpec(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    systemSpecs: $systemSpec
  ) {
    body
  }
}
    `;
export type AddSystemSpecMutationFn = Apollo.MutationFunction<AddSystemSpecMutation, AddSystemSpecMutationVariables>;
export type AddSystemSpecMutationResult = Apollo.MutationResult<AddSystemSpecMutation>;
export type AddSystemSpecMutationOptions = Apollo.BaseMutationOptions<AddSystemSpecMutation, AddSystemSpecMutationVariables>;
export const GetSystemSpecsDocument = gql`
    query GetSystemSpecs($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput!) {
  getSystemSpecs(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
  ) {
    body
  }
}
    `;
export type GetSystemSpecsQueryResult = Apollo.QueryResult<GetSystemSpecsQuery, GetSystemSpecsQueryVariables>;
export const CreateCfExtColorDocument = gql`
    mutation CreateCFExtColor($brand: Brand!, $payload: CreateCFExtColorInput!) {
  createCFExtColor(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateCfExtColorMutationFn = Apollo.MutationFunction<CreateCfExtColorMutation, CreateCfExtColorMutationVariables>;
export type CreateCfExtColorMutationResult = Apollo.MutationResult<CreateCfExtColorMutation>;
export type CreateCfExtColorMutationOptions = Apollo.BaseMutationOptions<CreateCfExtColorMutation, CreateCfExtColorMutationVariables>;
export const CreateCfIntColorDocument = gql`
    mutation CreateCFIntColor($brand: Brand!, $payload: CreateCFIntColorInput!) {
  createCFIntColor(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateCfIntColorMutationFn = Apollo.MutationFunction<CreateCfIntColorMutation, CreateCfIntColorMutationVariables>;
export type CreateCfIntColorMutationResult = Apollo.MutationResult<CreateCfIntColorMutation>;
export type CreateCfIntColorMutationOptions = Apollo.BaseMutationOptions<CreateCfIntColorMutation, CreateCfIntColorMutationVariables>;
export const CreateColorFamilyDocument = gql`
    mutation CreateColorFamily($brand: Brand!, $payload: CreateColorFamilyInput!) {
  createColorFamily(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateColorFamilyMutationFn = Apollo.MutationFunction<CreateColorFamilyMutation, CreateColorFamilyMutationVariables>;
export type CreateColorFamilyMutationResult = Apollo.MutationResult<CreateColorFamilyMutation>;
export type CreateColorFamilyMutationOptions = Apollo.BaseMutationOptions<CreateColorFamilyMutation, CreateColorFamilyMutationVariables>;
export const CreateExtColorLexusDocument = gql`
    mutation CreateExtColorLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateExtColorLexusInput!) {
  createExtColorLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateExtColorLexusMutationFn = Apollo.MutationFunction<CreateExtColorLexusMutation, CreateExtColorLexusMutationVariables>;
export type CreateExtColorLexusMutationResult = Apollo.MutationResult<CreateExtColorLexusMutation>;
export type CreateExtColorLexusMutationOptions = Apollo.BaseMutationOptions<CreateExtColorLexusMutation, CreateExtColorLexusMutationVariables>;
export const CreateExtColorToyotaDocument = gql`
    mutation CreateExtColorToyota($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: CreateExtColorToyotaInput!) {
  createExtColorToyota(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateExtColorToyotaMutationFn = Apollo.MutationFunction<CreateExtColorToyotaMutation, CreateExtColorToyotaMutationVariables>;
export type CreateExtColorToyotaMutationResult = Apollo.MutationResult<CreateExtColorToyotaMutation>;
export type CreateExtColorToyotaMutationOptions = Apollo.BaseMutationOptions<CreateExtColorToyotaMutation, CreateExtColorToyotaMutationVariables>;
export const CreateGroupTypesLexusDocument = gql`
    mutation CreateGroupTypesLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateGroupTypesLexusInput!) {
  createGroupTypesLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateGroupTypesLexusMutationFn = Apollo.MutationFunction<CreateGroupTypesLexusMutation, CreateGroupTypesLexusMutationVariables>;
export type CreateGroupTypesLexusMutationResult = Apollo.MutationResult<CreateGroupTypesLexusMutation>;
export type CreateGroupTypesLexusMutationOptions = Apollo.BaseMutationOptions<CreateGroupTypesLexusMutation, CreateGroupTypesLexusMutationVariables>;
export const CreateIntColorLexusDocument = gql`
    mutation CreateIntColorLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateIntColorLexusInput!) {
  createIntColorLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateIntColorLexusMutationFn = Apollo.MutationFunction<CreateIntColorLexusMutation, CreateIntColorLexusMutationVariables>;
export type CreateIntColorLexusMutationResult = Apollo.MutationResult<CreateIntColorLexusMutation>;
export type CreateIntColorLexusMutationOptions = Apollo.BaseMutationOptions<CreateIntColorLexusMutation, CreateIntColorLexusMutationVariables>;
export const CreateIntColorToyotaDocument = gql`
    mutation CreateIntColorToyota($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: CreateIntColorToyotaInput!) {
  createIntColorToyota(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateIntColorToyotaMutationFn = Apollo.MutationFunction<CreateIntColorToyotaMutation, CreateIntColorToyotaMutationVariables>;
export type CreateIntColorToyotaMutationResult = Apollo.MutationResult<CreateIntColorToyotaMutation>;
export type CreateIntColorToyotaMutationOptions = Apollo.BaseMutationOptions<CreateIntColorToyotaMutation, CreateIntColorToyotaMutationVariables>;
export const CreateIntColorTypesLexusDocument = gql`
    mutation CreateIntColorTypesLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateIntColorTypesLexusInput!) {
  createIntColorTypesLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateIntColorTypesLexusMutationFn = Apollo.MutationFunction<CreateIntColorTypesLexusMutation, CreateIntColorTypesLexusMutationVariables>;
export type CreateIntColorTypesLexusMutationResult = Apollo.MutationResult<CreateIntColorTypesLexusMutation>;
export type CreateIntColorTypesLexusMutationOptions = Apollo.BaseMutationOptions<CreateIntColorTypesLexusMutation, CreateIntColorTypesLexusMutationVariables>;
export const CreateMaterialsDocument = gql`
    mutation CreateMaterials($brand: Brand!, $payload: CreateMaterialInput!) {
  createMaterials(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateMaterialsMutationFn = Apollo.MutationFunction<CreateMaterialsMutation, CreateMaterialsMutationVariables>;
export type CreateMaterialsMutationResult = Apollo.MutationResult<CreateMaterialsMutation>;
export type CreateMaterialsMutationOptions = Apollo.BaseMutationOptions<CreateMaterialsMutation, CreateMaterialsMutationVariables>;
export const DeleteCfExtColorDocument = gql`
    mutation DeleteCFExtColor($brand: Brand!, $deleteCfExtColorId: ID!) {
  deleteCFExtColor(brand: $brand, id: $deleteCfExtColorId) {
    body
  }
}
    `;
export type DeleteCfExtColorMutationFn = Apollo.MutationFunction<DeleteCfExtColorMutation, DeleteCfExtColorMutationVariables>;
export type DeleteCfExtColorMutationResult = Apollo.MutationResult<DeleteCfExtColorMutation>;
export type DeleteCfExtColorMutationOptions = Apollo.BaseMutationOptions<DeleteCfExtColorMutation, DeleteCfExtColorMutationVariables>;
export const DeleteCfIntColorDocument = gql`
    mutation DeleteCFIntColor($brand: Brand!, $deleteCfIntColorId: ID!) {
  deleteCFIntColor(brand: $brand, id: $deleteCfIntColorId) {
    body
  }
}
    `;
export type DeleteCfIntColorMutationFn = Apollo.MutationFunction<DeleteCfIntColorMutation, DeleteCfIntColorMutationVariables>;
export type DeleteCfIntColorMutationResult = Apollo.MutationResult<DeleteCfIntColorMutation>;
export type DeleteCfIntColorMutationOptions = Apollo.BaseMutationOptions<DeleteCfIntColorMutation, DeleteCfIntColorMutationVariables>;
export const DeleteColorFamilyDocument = gql`
    mutation DeleteColorFamily($brand: Brand!, $deleteColorFamilyId: ID!) {
  deleteColorFamily(brand: $brand, id: $deleteColorFamilyId) {
    body
  }
}
    `;
export type DeleteColorFamilyMutationFn = Apollo.MutationFunction<DeleteColorFamilyMutation, DeleteColorFamilyMutationVariables>;
export type DeleteColorFamilyMutationResult = Apollo.MutationResult<DeleteColorFamilyMutation>;
export type DeleteColorFamilyMutationOptions = Apollo.BaseMutationOptions<DeleteColorFamilyMutation, DeleteColorFamilyMutationVariables>;
export const DeleteExtColorDocument = gql`
    mutation DeleteExtColor($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $deleteExtColorId: ID!) {
  deleteExtColor(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    id: $deleteExtColorId
  ) {
    body
  }
}
    `;
export type DeleteExtColorMutationFn = Apollo.MutationFunction<DeleteExtColorMutation, DeleteExtColorMutationVariables>;
export type DeleteExtColorMutationResult = Apollo.MutationResult<DeleteExtColorMutation>;
export type DeleteExtColorMutationOptions = Apollo.BaseMutationOptions<DeleteExtColorMutation, DeleteExtColorMutationVariables>;
export const DeleteGroupTypesLexusDocument = gql`
    mutation DeleteGroupTypesLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $deleteGroupTypesLexusId: ID!) {
  deleteGroupTypesLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    id: $deleteGroupTypesLexusId
  ) {
    body
  }
}
    `;
export type DeleteGroupTypesLexusMutationFn = Apollo.MutationFunction<DeleteGroupTypesLexusMutation, DeleteGroupTypesLexusMutationVariables>;
export type DeleteGroupTypesLexusMutationResult = Apollo.MutationResult<DeleteGroupTypesLexusMutation>;
export type DeleteGroupTypesLexusMutationOptions = Apollo.BaseMutationOptions<DeleteGroupTypesLexusMutation, DeleteGroupTypesLexusMutationVariables>;
export const DeleteIntColorDocument = gql`
    mutation DeleteIntColor($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $deleteIntColorId: ID!) {
  deleteIntColor(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    id: $deleteIntColorId
  ) {
    body
  }
}
    `;
export type DeleteIntColorMutationFn = Apollo.MutationFunction<DeleteIntColorMutation, DeleteIntColorMutationVariables>;
export type DeleteIntColorMutationResult = Apollo.MutationResult<DeleteIntColorMutation>;
export type DeleteIntColorMutationOptions = Apollo.BaseMutationOptions<DeleteIntColorMutation, DeleteIntColorMutationVariables>;
export const PublishColorFamilyDraftDocument = gql`
    mutation PublishColorFamilyDraft($brand: Brand!) {
  publishColorFamilyDraft(brand: $brand) {
    body
  }
}
    `;
export type PublishColorFamilyDraftMutationFn = Apollo.MutationFunction<PublishColorFamilyDraftMutation, PublishColorFamilyDraftMutationVariables>;
export type PublishColorFamilyDraftMutationResult = Apollo.MutationResult<PublishColorFamilyDraftMutation>;
export type PublishColorFamilyDraftMutationOptions = Apollo.BaseMutationOptions<PublishColorFamilyDraftMutation, PublishColorFamilyDraftMutationVariables>;
export const ReadCfExtColorsDocument = gql`
    query ReadCFExtColors($brand: Brand!, $filter: FilterInput!) {
  readCFExtColors(brand: $brand, filter: $filter) {
    body
  }
}
    `;
export type ReadCfExtColorsQueryResult = Apollo.QueryResult<ReadCfExtColorsQuery, ReadCfExtColorsQueryVariables>;
export const ReadCfIntColorsDocument = gql`
    query ReadCFIntColors($brand: Brand!, $filter: FilterInput!) {
  readCFIntColors(brand: $brand, filter: $filter) {
    body
  }
}
    `;
export type ReadCfIntColorsQueryResult = Apollo.QueryResult<ReadCfIntColorsQuery, ReadCfIntColorsQueryVariables>;
export const ReadColorChangesDocument = gql`
    query ReadColorChanges($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput!) {
  readColorChanges(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadColorChangesQueryResult = Apollo.QueryResult<ReadColorChangesQuery, ReadColorChangesQueryVariables>;
export const ReadColorFamiliesDocument = gql`
    query ReadColorFamilies($brand: Brand!, $filter: FilterInput!) {
  readColorFamilies(brand: $brand, filter: $filter) {
    body
  }
}
    `;
export type ReadColorFamiliesQueryResult = Apollo.QueryResult<ReadColorFamiliesQuery, ReadColorFamiliesQueryVariables>;
export const ReadExtColorReviewDocument = gql`
    query ReadExtColorReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!) {
  readExtColorReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadExtColorReviewQueryResult = Apollo.QueryResult<ReadExtColorReviewQuery, ReadExtColorReviewQueryVariables>;
export const ReadExtColorsDocument = gql`
    query ReadExtColors($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput!) {
  readExtColors(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadExtColorsQueryResult = Apollo.QueryResult<ReadExtColorsQuery, ReadExtColorsQueryVariables>;
export const ReadIntColorReviewDocument = gql`
    query ReadIntColorReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!) {
  readIntColorReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadIntColorReviewQueryResult = Apollo.QueryResult<ReadIntColorReviewQuery, ReadIntColorReviewQueryVariables>;
export const ReadIntColorTypesLexusDocument = gql`
    query ReadIntColorTypesLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!, $includeAll: Boolean!) {
  readIntColorTypesLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
    includeAll: $includeAll
  ) {
    body
  }
}
    `;
export type ReadIntColorTypesLexusQueryResult = Apollo.QueryResult<ReadIntColorTypesLexusQuery, ReadIntColorTypesLexusQueryVariables>;
export const ReadIntColorTypesReviewLexusDocument = gql`
    query ReadIntColorTypesReviewLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!) {
  readIntColorTypesReviewLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadIntColorTypesReviewLexusQueryResult = Apollo.QueryResult<ReadIntColorTypesReviewLexusQuery, ReadIntColorTypesReviewLexusQueryVariables>;
export const ReadIntColorsDocument = gql`
    query ReadIntColors($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput!, $includeAll: Boolean!) {
  readIntColors(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
    includeAll: $includeAll
  ) {
    body
  }
}
    `;
export type ReadIntColorsQueryResult = Apollo.QueryResult<ReadIntColorsQuery, ReadIntColorsQueryVariables>;
export const ReadMaterialsDocument = gql`
    query ReadMaterials($brand: Brand!) {
  readMaterials(brand: $brand) {
    body
  }
}
    `;
export type ReadMaterialsQueryResult = Apollo.QueryResult<ReadMaterialsQuery, ReadMaterialsQueryVariables>;
export const RevertColorChangeDocument = gql`
    mutation RevertColorChange($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: RevertColorChangeInput!) {
  revertColorChange(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type RevertColorChangeMutationFn = Apollo.MutationFunction<RevertColorChangeMutation, RevertColorChangeMutationVariables>;
export type RevertColorChangeMutationResult = Apollo.MutationResult<RevertColorChangeMutation>;
export type RevertColorChangeMutationOptions = Apollo.BaseMutationOptions<RevertColorChangeMutation, RevertColorChangeMutationVariables>;
export const UpdateCfExtColorDocument = gql`
    mutation UpdateCFExtColor($brand: Brand!, $payload: UpdateCFExtColorInput!) {
  updateCFExtColor(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type UpdateCfExtColorMutationFn = Apollo.MutationFunction<UpdateCfExtColorMutation, UpdateCfExtColorMutationVariables>;
export type UpdateCfExtColorMutationResult = Apollo.MutationResult<UpdateCfExtColorMutation>;
export type UpdateCfExtColorMutationOptions = Apollo.BaseMutationOptions<UpdateCfExtColorMutation, UpdateCfExtColorMutationVariables>;
export const UpdateCfIntColorDocument = gql`
    mutation UpdateCFIntColor($brand: Brand!, $payload: UpdateCFIntColorInput!) {
  updateCFIntColor(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type UpdateCfIntColorMutationFn = Apollo.MutationFunction<UpdateCfIntColorMutation, UpdateCfIntColorMutationVariables>;
export type UpdateCfIntColorMutationResult = Apollo.MutationResult<UpdateCfIntColorMutation>;
export type UpdateCfIntColorMutationOptions = Apollo.BaseMutationOptions<UpdateCfIntColorMutation, UpdateCfIntColorMutationVariables>;
export const UpdateColorFamilyDocument = gql`
    mutation UpdateColorFamily($brand: Brand!, $payload: UpdateColorFamilyInput!) {
  updateColorFamily(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type UpdateColorFamilyMutationFn = Apollo.MutationFunction<UpdateColorFamilyMutation, UpdateColorFamilyMutationVariables>;
export type UpdateColorFamilyMutationResult = Apollo.MutationResult<UpdateColorFamilyMutation>;
export type UpdateColorFamilyMutationOptions = Apollo.BaseMutationOptions<UpdateColorFamilyMutation, UpdateColorFamilyMutationVariables>;
export const UpdateExtColorLexusDocument = gql`
    mutation UpdateExtColorLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateExtColorLexusInput!) {
  updateExtColorLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateExtColorLexusMutationFn = Apollo.MutationFunction<UpdateExtColorLexusMutation, UpdateExtColorLexusMutationVariables>;
export type UpdateExtColorLexusMutationResult = Apollo.MutationResult<UpdateExtColorLexusMutation>;
export type UpdateExtColorLexusMutationOptions = Apollo.BaseMutationOptions<UpdateExtColorLexusMutation, UpdateExtColorLexusMutationVariables>;
export const UpdateExtColorReviewDocument = gql`
    mutation UpdateExtColorReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!, $payload: UpdateExtColorReviewInput!) {
  updateExtColorReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateExtColorReviewMutationFn = Apollo.MutationFunction<UpdateExtColorReviewMutation, UpdateExtColorReviewMutationVariables>;
export type UpdateExtColorReviewMutationResult = Apollo.MutationResult<UpdateExtColorReviewMutation>;
export type UpdateExtColorReviewMutationOptions = Apollo.BaseMutationOptions<UpdateExtColorReviewMutation, UpdateExtColorReviewMutationVariables>;
export const UpdateExtColorStatusDocument = gql`
    mutation UpdateExtColorStatus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateExtColorStatusInput!) {
  updateExtColorStatus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateExtColorStatusMutationFn = Apollo.MutationFunction<UpdateExtColorStatusMutation, UpdateExtColorStatusMutationVariables>;
export type UpdateExtColorStatusMutationResult = Apollo.MutationResult<UpdateExtColorStatusMutation>;
export type UpdateExtColorStatusMutationOptions = Apollo.BaseMutationOptions<UpdateExtColorStatusMutation, UpdateExtColorStatusMutationVariables>;
export const UpdateExtColorStatusAllDocument = gql`
    mutation UpdateExtColorStatusAll($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $status: Int!) {
  updateExtColorStatusAll(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
  ) {
    body
  }
}
    `;
export type UpdateExtColorStatusAllMutationFn = Apollo.MutationFunction<UpdateExtColorStatusAllMutation, UpdateExtColorStatusAllMutationVariables>;
export type UpdateExtColorStatusAllMutationResult = Apollo.MutationResult<UpdateExtColorStatusAllMutation>;
export type UpdateExtColorStatusAllMutationOptions = Apollo.BaseMutationOptions<UpdateExtColorStatusAllMutation, UpdateExtColorStatusAllMutationVariables>;
export const UpdateExtColorToyotaDocument = gql`
    mutation UpdateExtColorToyota($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateExtColorToyotaInput!) {
  updateExtColorToyota(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateExtColorToyotaMutationFn = Apollo.MutationFunction<UpdateExtColorToyotaMutation, UpdateExtColorToyotaMutationVariables>;
export type UpdateExtColorToyotaMutationResult = Apollo.MutationResult<UpdateExtColorToyotaMutation>;
export type UpdateExtColorToyotaMutationOptions = Apollo.BaseMutationOptions<UpdateExtColorToyotaMutation, UpdateExtColorToyotaMutationVariables>;
export const UpdateGroupTypesLexusDocument = gql`
    mutation UpdateGroupTypesLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateGroupTypesLexusInput!) {
  updateGroupTypesLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateGroupTypesLexusMutationFn = Apollo.MutationFunction<UpdateGroupTypesLexusMutation, UpdateGroupTypesLexusMutationVariables>;
export type UpdateGroupTypesLexusMutationResult = Apollo.MutationResult<UpdateGroupTypesLexusMutation>;
export type UpdateGroupTypesLexusMutationOptions = Apollo.BaseMutationOptions<UpdateGroupTypesLexusMutation, UpdateGroupTypesLexusMutationVariables>;
export const UpdateIntColorLexusDocument = gql`
    mutation UpdateIntColorLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateIntColorLexusInput!) {
  updateIntColorLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateIntColorLexusMutationFn = Apollo.MutationFunction<UpdateIntColorLexusMutation, UpdateIntColorLexusMutationVariables>;
export type UpdateIntColorLexusMutationResult = Apollo.MutationResult<UpdateIntColorLexusMutation>;
export type UpdateIntColorLexusMutationOptions = Apollo.BaseMutationOptions<UpdateIntColorLexusMutation, UpdateIntColorLexusMutationVariables>;
export const UpdateIntColorReviewDocument = gql`
    mutation UpdateIntColorReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!, $payload: UpdateIntColorReviewInput!) {
  updateIntColorReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateIntColorReviewMutationFn = Apollo.MutationFunction<UpdateIntColorReviewMutation, UpdateIntColorReviewMutationVariables>;
export type UpdateIntColorReviewMutationResult = Apollo.MutationResult<UpdateIntColorReviewMutation>;
export type UpdateIntColorReviewMutationOptions = Apollo.BaseMutationOptions<UpdateIntColorReviewMutation, UpdateIntColorReviewMutationVariables>;
export const UpdateIntColorStatusDocument = gql`
    mutation UpdateIntColorStatus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateIntColorStatusInput!) {
  updateIntColorStatus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateIntColorStatusMutationFn = Apollo.MutationFunction<UpdateIntColorStatusMutation, UpdateIntColorStatusMutationVariables>;
export type UpdateIntColorStatusMutationResult = Apollo.MutationResult<UpdateIntColorStatusMutation>;
export type UpdateIntColorStatusMutationOptions = Apollo.BaseMutationOptions<UpdateIntColorStatusMutation, UpdateIntColorStatusMutationVariables>;
export const UpdateIntColorToyotaDocument = gql`
    mutation UpdateIntColorToyota($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateIntColorToyotaInput!) {
  updateIntColorToyota(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateIntColorToyotaMutationFn = Apollo.MutationFunction<UpdateIntColorToyotaMutation, UpdateIntColorToyotaMutationVariables>;
export type UpdateIntColorToyotaMutationResult = Apollo.MutationResult<UpdateIntColorToyotaMutation>;
export type UpdateIntColorToyotaMutationOptions = Apollo.BaseMutationOptions<UpdateIntColorToyotaMutation, UpdateIntColorToyotaMutationVariables>;
export const UpdateIntColorTypesLexusDocument = gql`
    mutation UpdateIntColorTypesLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateIntColorTypesLexusInput!) {
  updateIntColorTypesLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateIntColorTypesLexusMutationFn = Apollo.MutationFunction<UpdateIntColorTypesLexusMutation, UpdateIntColorTypesLexusMutationVariables>;
export type UpdateIntColorTypesLexusMutationResult = Apollo.MutationResult<UpdateIntColorTypesLexusMutation>;
export type UpdateIntColorTypesLexusMutationOptions = Apollo.BaseMutationOptions<UpdateIntColorTypesLexusMutation, UpdateIntColorTypesLexusMutationVariables>;
export const UpdateMaterialsDocument = gql`
    mutation UpdateMaterials($brand: Brand!, $payload: UpdateMaterialInput!) {
  updateMaterials(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type UpdateMaterialsMutationFn = Apollo.MutationFunction<UpdateMaterialsMutation, UpdateMaterialsMutationVariables>;
export type UpdateMaterialsMutationResult = Apollo.MutationResult<UpdateMaterialsMutation>;
export type UpdateMaterialsMutationOptions = Apollo.BaseMutationOptions<UpdateMaterialsMutation, UpdateMaterialsMutationVariables>;
export const CreateVehicleModelDocument = gql`
    mutation CreateVehicleModel($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: CreateVehicleModelInput!) {
  createVehicleModel(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateVehicleModelMutationFn = Apollo.MutationFunction<CreateVehicleModelMutation, CreateVehicleModelMutationVariables>;
export type CreateVehicleModelMutationResult = Apollo.MutationResult<CreateVehicleModelMutation>;
export type CreateVehicleModelMutationOptions = Apollo.BaseMutationOptions<CreateVehicleModelMutation, CreateVehicleModelMutationVariables>;
export const CreateVehicleModelGradesDocument = gql`
    mutation CreateVehicleModelGrades($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateVehicleModelGradeInput!) {
  createVehicleModelGrades(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateVehicleModelGradesMutationFn = Apollo.MutationFunction<CreateVehicleModelGradesMutation, CreateVehicleModelGradesMutationVariables>;
export type CreateVehicleModelGradesMutationResult = Apollo.MutationResult<CreateVehicleModelGradesMutation>;
export type CreateVehicleModelGradesMutationOptions = Apollo.BaseMutationOptions<CreateVehicleModelGradesMutation, CreateVehicleModelGradesMutationVariables>;
export const CreateVehicleModelLexusDocument = gql`
    mutation CreateVehicleModelLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateVehicleModelLexusInput!) {
  createVehicleModelLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateVehicleModelLexusMutationFn = Apollo.MutationFunction<CreateVehicleModelLexusMutation, CreateVehicleModelLexusMutationVariables>;
export type CreateVehicleModelLexusMutationResult = Apollo.MutationResult<CreateVehicleModelLexusMutation>;
export type CreateVehicleModelLexusMutationOptions = Apollo.BaseMutationOptions<CreateVehicleModelLexusMutation, CreateVehicleModelLexusMutationVariables>;
export const CreateVehicleModelSeriesDocument = gql`
    mutation CreateVehicleModelSeries($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: CreateVehicleModelSeriesInput!) {
  createVehicleModelSeries(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateVehicleModelSeriesMutationFn = Apollo.MutationFunction<CreateVehicleModelSeriesMutation, CreateVehicleModelSeriesMutationVariables>;
export type CreateVehicleModelSeriesMutationResult = Apollo.MutationResult<CreateVehicleModelSeriesMutation>;
export type CreateVehicleModelSeriesMutationOptions = Apollo.BaseMutationOptions<CreateVehicleModelSeriesMutation, CreateVehicleModelSeriesMutationVariables>;
export const DeleteVehicleModelDocument = gql`
    mutation DeleteVehicleModel($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $deleteVehicleModelId: ID!) {
  deleteVehicleModel(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    id: $deleteVehicleModelId
  ) {
    body
  }
}
    `;
export type DeleteVehicleModelMutationFn = Apollo.MutationFunction<DeleteVehicleModelMutation, DeleteVehicleModelMutationVariables>;
export type DeleteVehicleModelMutationResult = Apollo.MutationResult<DeleteVehicleModelMutation>;
export type DeleteVehicleModelMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleModelMutation, DeleteVehicleModelMutationVariables>;
export const DeleteVehicleModelSeriesDocument = gql`
    mutation DeleteVehicleModelSeries($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $seriesSettingId: String!, $subSeriesSettingId: String) {
  deleteVehicleModelSeries(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    seriesSettingId: $seriesSettingId
    subSeriesSettingId: $subSeriesSettingId
  ) {
    body
  }
}
    `;
export type DeleteVehicleModelSeriesMutationFn = Apollo.MutationFunction<DeleteVehicleModelSeriesMutation, DeleteVehicleModelSeriesMutationVariables>;
export type DeleteVehicleModelSeriesMutationResult = Apollo.MutationResult<DeleteVehicleModelSeriesMutation>;
export type DeleteVehicleModelSeriesMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleModelSeriesMutation, DeleteVehicleModelSeriesMutationVariables>;
export const ReadVehicleModelChangesDocument = gql`
    query ReadVehicleModelChanges($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput) {
  readVehicleModelChanges(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehicleModelChangesQueryResult = Apollo.QueryResult<ReadVehicleModelChangesQuery, ReadVehicleModelChangesQueryVariables>;
export const ReadVehicleModelGradesDocument = gql`
    query ReadVehicleModelGrades($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $includeAll: Boolean!, $filter: FilterInput) {
  readVehicleModelGrades(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    includeAll: $includeAll
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehicleModelGradesQueryResult = Apollo.QueryResult<ReadVehicleModelGradesQuery, ReadVehicleModelGradesQueryVariables>;
export const ReadVehicleModelGradesReviewsDocument = gql`
    query ReadVehicleModelGradesReviews($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput) {
  readVehicleModelGradesReviews(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehicleModelGradesReviewsQueryResult = Apollo.QueryResult<ReadVehicleModelGradesReviewsQuery, ReadVehicleModelGradesReviewsQueryVariables>;
export const ReadVehicleModelMapsDocument = gql`
    query ReadVehicleModelMaps($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $versionMap: JSON!) {
  readVehicleModelMaps(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    versionMap: $versionMap
  ) {
    body
  }
}
    `;
export type ReadVehicleModelMapsQueryResult = Apollo.QueryResult<ReadVehicleModelMapsQuery, ReadVehicleModelMapsQueryVariables>;
export const ReadVehicleModelReviewsDocument = gql`
    query ReadVehicleModelReviews($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput) {
  readVehicleModelReviews(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehicleModelReviewsQueryResult = Apollo.QueryResult<ReadVehicleModelReviewsQuery, ReadVehicleModelReviewsQueryVariables>;
export const ReadVehicleModelSeriesDocument = gql`
    query ReadVehicleModelSeries($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $includeAll: Boolean!, $filter: FilterInput) {
  readVehicleModelSeries(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    includeAll: $includeAll
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehicleModelSeriesQueryResult = Apollo.QueryResult<ReadVehicleModelSeriesQuery, ReadVehicleModelSeriesQueryVariables>;
export const ReadVehicleModelSeriesReviewsDocument = gql`
    query ReadVehicleModelSeriesReviews($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput) {
  readVehicleModelSeriesReviews(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehicleModelSeriesReviewsQueryResult = Apollo.QueryResult<ReadVehicleModelSeriesReviewsQuery, ReadVehicleModelSeriesReviewsQueryVariables>;
export const ReadVehicleModelsDocument = gql`
    query ReadVehicleModels($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $includeAll: Boolean!, $filter: FilterInput) {
  readVehicleModels(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    includeAll: $includeAll
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehicleModelsQueryResult = Apollo.QueryResult<ReadVehicleModelsQuery, ReadVehicleModelsQueryVariables>;
export const RevertVehicleModelChangeDocument = gql`
    mutation RevertVehicleModelChange($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: RevertVehicleModelChangeInput!) {
  revertVehicleModelChange(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type RevertVehicleModelChangeMutationFn = Apollo.MutationFunction<RevertVehicleModelChangeMutation, RevertVehicleModelChangeMutationVariables>;
export type RevertVehicleModelChangeMutationResult = Apollo.MutationResult<RevertVehicleModelChangeMutation>;
export type RevertVehicleModelChangeMutationOptions = Apollo.BaseMutationOptions<RevertVehicleModelChangeMutation, RevertVehicleModelChangeMutationVariables>;
export const SortVehicleModelsDocument = gql`
    mutation SortVehicleModels($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: [SortVehicleModelsInput!]!) {
  sortVehicleModels(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type SortVehicleModelsMutationFn = Apollo.MutationFunction<SortVehicleModelsMutation, SortVehicleModelsMutationVariables>;
export type SortVehicleModelsMutationResult = Apollo.MutationResult<SortVehicleModelsMutation>;
export type SortVehicleModelsMutationOptions = Apollo.BaseMutationOptions<SortVehicleModelsMutation, SortVehicleModelsMutationVariables>;
export const UpdateVehicleModelDocument = gql`
    mutation UpdateVehicleModel($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateVehicleModelInput!) {
  updateVehicleModel(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleModelMutationFn = Apollo.MutationFunction<UpdateVehicleModelMutation, UpdateVehicleModelMutationVariables>;
export type UpdateVehicleModelMutationResult = Apollo.MutationResult<UpdateVehicleModelMutation>;
export type UpdateVehicleModelMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleModelMutation, UpdateVehicleModelMutationVariables>;
export const UpdateVehicleModelGradesDocument = gql`
    mutation UpdateVehicleModelGrades($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateVehicleModelGradeInput!) {
  updateVehicleModelGrades(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleModelGradesMutationFn = Apollo.MutationFunction<UpdateVehicleModelGradesMutation, UpdateVehicleModelGradesMutationVariables>;
export type UpdateVehicleModelGradesMutationResult = Apollo.MutationResult<UpdateVehicleModelGradesMutation>;
export type UpdateVehicleModelGradesMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleModelGradesMutation, UpdateVehicleModelGradesMutationVariables>;
export const UpdateVehicleModelLexusDocument = gql`
    mutation UpdateVehicleModelLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateVehicleModelLexusInput!) {
  updateVehicleModelLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleModelLexusMutationFn = Apollo.MutationFunction<UpdateVehicleModelLexusMutation, UpdateVehicleModelLexusMutationVariables>;
export type UpdateVehicleModelLexusMutationResult = Apollo.MutationResult<UpdateVehicleModelLexusMutation>;
export type UpdateVehicleModelLexusMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleModelLexusMutation, UpdateVehicleModelLexusMutationVariables>;
export const UpdateVehicleModelReviewDocument = gql`
    mutation UpdateVehicleModelReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateVehicleModelReviewInput!, $filter: FilterInput!) {
  updateVehicleModelReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
    filter: $filter
  ) {
    body
  }
}
    `;
export type UpdateVehicleModelReviewMutationFn = Apollo.MutationFunction<UpdateVehicleModelReviewMutation, UpdateVehicleModelReviewMutationVariables>;
export type UpdateVehicleModelReviewMutationResult = Apollo.MutationResult<UpdateVehicleModelReviewMutation>;
export type UpdateVehicleModelReviewMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleModelReviewMutation, UpdateVehicleModelReviewMutationVariables>;
export const UpdateVehicleModelSeriesDocument = gql`
    mutation UpdateVehicleModelSeries($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateVehicleModelSeriesInput!) {
  updateVehicleModelSeries(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleModelSeriesMutationFn = Apollo.MutationFunction<UpdateVehicleModelSeriesMutation, UpdateVehicleModelSeriesMutationVariables>;
export type UpdateVehicleModelSeriesMutationResult = Apollo.MutationResult<UpdateVehicleModelSeriesMutation>;
export type UpdateVehicleModelSeriesMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleModelSeriesMutation, UpdateVehicleModelSeriesMutationVariables>;
export const UpdateVehicleModelSeriesStatusDocument = gql`
    mutation UpdateVehicleModelSeriesStatus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateVehicleModelSeriesStatusInput!) {
  updateVehicleModelSeriesStatus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleModelSeriesStatusMutationFn = Apollo.MutationFunction<UpdateVehicleModelSeriesStatusMutation, UpdateVehicleModelSeriesStatusMutationVariables>;
export type UpdateVehicleModelSeriesStatusMutationResult = Apollo.MutationResult<UpdateVehicleModelSeriesStatusMutation>;
export type UpdateVehicleModelSeriesStatusMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleModelSeriesStatusMutation, UpdateVehicleModelSeriesStatusMutationVariables>;
export const UpdateVehicleModelStatusDocument = gql`
    mutation UpdateVehicleModelStatus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateVehicleModelStatusInput!) {
  updateVehicleModelStatus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleModelStatusMutationFn = Apollo.MutationFunction<UpdateVehicleModelStatusMutation, UpdateVehicleModelStatusMutationVariables>;
export type UpdateVehicleModelStatusMutationResult = Apollo.MutationResult<UpdateVehicleModelStatusMutation>;
export type UpdateVehicleModelStatusMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleModelStatusMutation, UpdateVehicleModelStatusMutationVariables>;
export const UpdateVehicleModelStatusAllDocument = gql`
    mutation UpdateVehicleModelStatusAll($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $status: Int!) {
  updateVehicleModelStatusAll(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
  ) {
    body
  }
}
    `;
export type UpdateVehicleModelStatusAllMutationFn = Apollo.MutationFunction<UpdateVehicleModelStatusAllMutation, UpdateVehicleModelStatusAllMutationVariables>;
export type UpdateVehicleModelStatusAllMutationResult = Apollo.MutationResult<UpdateVehicleModelStatusAllMutation>;
export type UpdateVehicleModelStatusAllMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleModelStatusAllMutation, UpdateVehicleModelStatusAllMutationVariables>;
export const UpdateVehicleModelsGoLiveDateDocument = gql`
    mutation UpdateVehicleModelsGoLiveDate($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $goLiveDate: String!, $payload: JSON!) {
  updateVehicleModelsGoLiveDate(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    goLiveDate: $goLiveDate
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleModelsGoLiveDateMutationFn = Apollo.MutationFunction<UpdateVehicleModelsGoLiveDateMutation, UpdateVehicleModelsGoLiveDateMutationVariables>;
export type UpdateVehicleModelsGoLiveDateMutationResult = Apollo.MutationResult<UpdateVehicleModelsGoLiveDateMutation>;
export type UpdateVehicleModelsGoLiveDateMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleModelsGoLiveDateMutation, UpdateVehicleModelsGoLiveDateMutationVariables>;
export const CreateVehicleOptionDocument = gql`
    mutation CreateVehicleOption($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: CreateVehicleOptionInput!) {
  createVehicleOption(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateVehicleOptionMutationFn = Apollo.MutationFunction<CreateVehicleOptionMutation, CreateVehicleOptionMutationVariables>;
export type CreateVehicleOptionMutationResult = Apollo.MutationResult<CreateVehicleOptionMutation>;
export type CreateVehicleOptionMutationOptions = Apollo.BaseMutationOptions<CreateVehicleOptionMutation, CreateVehicleOptionMutationVariables>;
export const CreateVehicleOptionLexusDocument = gql`
    mutation CreateVehicleOptionLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateVehicleOptionLexusInput!) {
  createVehicleOptionLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateVehicleOptionLexusMutationFn = Apollo.MutationFunction<CreateVehicleOptionLexusMutation, CreateVehicleOptionLexusMutationVariables>;
export type CreateVehicleOptionLexusMutationResult = Apollo.MutationResult<CreateVehicleOptionLexusMutation>;
export type CreateVehicleOptionLexusMutationOptions = Apollo.BaseMutationOptions<CreateVehicleOptionLexusMutation, CreateVehicleOptionLexusMutationVariables>;
export const CreateVehicleOptionSpecLexusDocument = gql`
    mutation CreateVehicleOptionSpecLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateVehicleOptionSpecInput!, $optionTab: String!) {
  createVehicleOptionSpecLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type CreateVehicleOptionSpecLexusMutationFn = Apollo.MutationFunction<CreateVehicleOptionSpecLexusMutation, CreateVehicleOptionSpecLexusMutationVariables>;
export type CreateVehicleOptionSpecLexusMutationResult = Apollo.MutationResult<CreateVehicleOptionSpecLexusMutation>;
export type CreateVehicleOptionSpecLexusMutationOptions = Apollo.BaseMutationOptions<CreateVehicleOptionSpecLexusMutation, CreateVehicleOptionSpecLexusMutationVariables>;
export const CreateVehicleOptionsCategoriesDocument = gql`
    mutation CreateVehicleOptionsCategories($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateVehicleOptionCategoriesInput!) {
  createVehicleOptionsCategories(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateVehicleOptionsCategoriesMutationFn = Apollo.MutationFunction<CreateVehicleOptionsCategoriesMutation, CreateVehicleOptionsCategoriesMutationVariables>;
export type CreateVehicleOptionsCategoriesMutationResult = Apollo.MutationResult<CreateVehicleOptionsCategoriesMutation>;
export type CreateVehicleOptionsCategoriesMutationOptions = Apollo.BaseMutationOptions<CreateVehicleOptionsCategoriesMutation, CreateVehicleOptionsCategoriesMutationVariables>;
export const CreateVehiclePackageLexusDocument = gql`
    mutation CreateVehiclePackageLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateVehiclePackageLexusInput!) {
  createVehiclePackageLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateVehiclePackageLexusMutationFn = Apollo.MutationFunction<CreateVehiclePackageLexusMutation, CreateVehiclePackageLexusMutationVariables>;
export type CreateVehiclePackageLexusMutationResult = Apollo.MutationResult<CreateVehiclePackageLexusMutation>;
export type CreateVehiclePackageLexusMutationOptions = Apollo.BaseMutationOptions<CreateVehiclePackageLexusMutation, CreateVehiclePackageLexusMutationVariables>;
export const CreateVehiclePackageVarietyLexusDocument = gql`
    mutation CreateVehiclePackageVarietyLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: CreateVehiclePackageVarietyLexusInput!) {
  createVehiclePackageVarietyLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type CreateVehiclePackageVarietyLexusMutationFn = Apollo.MutationFunction<CreateVehiclePackageVarietyLexusMutation, CreateVehiclePackageVarietyLexusMutationVariables>;
export type CreateVehiclePackageVarietyLexusMutationResult = Apollo.MutationResult<CreateVehiclePackageVarietyLexusMutation>;
export type CreateVehiclePackageVarietyLexusMutationOptions = Apollo.BaseMutationOptions<CreateVehiclePackageVarietyLexusMutation, CreateVehiclePackageVarietyLexusMutationVariables>;
export const DeleteVehicleOptionDocument = gql`
    mutation DeleteVehicleOption($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $deleteVehicleOptionId: ID!, $optionTab: String) {
  deleteVehicleOption(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    id: $deleteVehicleOptionId
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type DeleteVehicleOptionMutationFn = Apollo.MutationFunction<DeleteVehicleOptionMutation, DeleteVehicleOptionMutationVariables>;
export type DeleteVehicleOptionMutationResult = Apollo.MutationResult<DeleteVehicleOptionMutation>;
export type DeleteVehicleOptionMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleOptionMutation, DeleteVehicleOptionMutationVariables>;
export const DeleteVehicleOptionSpecLexusDocument = gql`
    mutation DeleteVehicleOptionSpecLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $parentId: ID!, $specId: ID!, $optionTab: String!) {
  deleteVehicleOptionSpecLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    parentId: $parentId
    specId: $specId
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type DeleteVehicleOptionSpecLexusMutationFn = Apollo.MutationFunction<DeleteVehicleOptionSpecLexusMutation, DeleteVehicleOptionSpecLexusMutationVariables>;
export type DeleteVehicleOptionSpecLexusMutationResult = Apollo.MutationResult<DeleteVehicleOptionSpecLexusMutation>;
export type DeleteVehicleOptionSpecLexusMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleOptionSpecLexusMutation, DeleteVehicleOptionSpecLexusMutationVariables>;
export const DeleteVehiclePackageVarietyLexusDocument = gql`
    mutation DeleteVehiclePackageVarietyLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $parentId: ID!, $specId: ID!) {
  deleteVehiclePackageVarietyLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    parentId: $parentId
    specId: $specId
  ) {
    body
  }
}
    `;
export type DeleteVehiclePackageVarietyLexusMutationFn = Apollo.MutationFunction<DeleteVehiclePackageVarietyLexusMutation, DeleteVehiclePackageVarietyLexusMutationVariables>;
export type DeleteVehiclePackageVarietyLexusMutationResult = Apollo.MutationResult<DeleteVehiclePackageVarietyLexusMutation>;
export type DeleteVehiclePackageVarietyLexusMutationOptions = Apollo.BaseMutationOptions<DeleteVehiclePackageVarietyLexusMutation, DeleteVehiclePackageVarietyLexusMutationVariables>;
export const ReadVehicleOptionCategoriesReviewDocument = gql`
    query ReadVehicleOptionCategoriesReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!) {
  readVehicleOptionCategoriesReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehicleOptionCategoriesReviewQueryResult = Apollo.QueryResult<ReadVehicleOptionCategoriesReviewQuery, ReadVehicleOptionCategoriesReviewQueryVariables>;
export const ReadVehicleOptionChangesDocument = gql`
    query ReadVehicleOptionChanges($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput!, $optionTab: String) {
  readVehicleOptionChanges(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type ReadVehicleOptionChangesQueryResult = Apollo.QueryResult<ReadVehicleOptionChangesQuery, ReadVehicleOptionChangesQueryVariables>;
export const ReadVehicleOptionReviewDocument = gql`
    query ReadVehicleOptionReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!, $optionTab: String) {
  readVehicleOptionReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type ReadVehicleOptionReviewQueryResult = Apollo.QueryResult<ReadVehicleOptionReviewQuery, ReadVehicleOptionReviewQueryVariables>;
export const ReadVehicleOptionSpecLexusDocument = gql`
    query ReadVehicleOptionSpecLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!, $optionTab: String) {
  readVehicleOptionSpecLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type ReadVehicleOptionSpecLexusQueryResult = Apollo.QueryResult<ReadVehicleOptionSpecLexusQuery, ReadVehicleOptionSpecLexusQueryVariables>;
export const ReadVehicleOptionsDocument = gql`
    query ReadVehicleOptions($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput!, $optionTab: String) {
  readVehicleOptions(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type ReadVehicleOptionsQueryResult = Apollo.QueryResult<ReadVehicleOptionsQuery, ReadVehicleOptionsQueryVariables>;
export const ReadVehicleOptionsCategoriesDocument = gql`
    query ReadVehicleOptionsCategories($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $filter: FilterInput!, $includeAll: Boolean!) {
  readVehicleOptionsCategories(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    filter: $filter
    includeAll: $includeAll
  ) {
    body
  }
}
    `;
export type ReadVehicleOptionsCategoriesQueryResult = Apollo.QueryResult<ReadVehicleOptionsCategoriesQuery, ReadVehicleOptionsCategoriesQueryVariables>;
export const ReadVehiclePackagesVarietyLexusDocument = gql`
    query ReadVehiclePackagesVarietyLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $filter: FilterInput!) {
  readVehiclePackagesVarietyLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehiclePackagesVarietyLexusQueryResult = Apollo.QueryResult<ReadVehiclePackagesVarietyLexusQuery, ReadVehiclePackagesVarietyLexusQueryVariables>;
export const RevertVehicleOptionChangeDocument = gql`
    mutation RevertVehicleOptionChange($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: RevertVehicleOptionChangeInput!, $optionTab: String) {
  revertVehicleOptionChange(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type RevertVehicleOptionChangeMutationFn = Apollo.MutationFunction<RevertVehicleOptionChangeMutation, RevertVehicleOptionChangeMutationVariables>;
export type RevertVehicleOptionChangeMutationResult = Apollo.MutationResult<RevertVehicleOptionChangeMutation>;
export type RevertVehicleOptionChangeMutationOptions = Apollo.BaseMutationOptions<RevertVehicleOptionChangeMutation, RevertVehicleOptionChangeMutationVariables>;
export const UpdateVehicleOptionDocument = gql`
    mutation UpdateVehicleOption($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateVehicleOptionInput!) {
  updateVehicleOption(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleOptionMutationFn = Apollo.MutationFunction<UpdateVehicleOptionMutation, UpdateVehicleOptionMutationVariables>;
export type UpdateVehicleOptionMutationResult = Apollo.MutationResult<UpdateVehicleOptionMutation>;
export type UpdateVehicleOptionMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleOptionMutation, UpdateVehicleOptionMutationVariables>;
export const UpdateVehicleOptionLexusDocument = gql`
    mutation UpdateVehicleOptionLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateVehicleOptionLexusInput!) {
  updateVehicleOptionLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleOptionLexusMutationFn = Apollo.MutationFunction<UpdateVehicleOptionLexusMutation, UpdateVehicleOptionLexusMutationVariables>;
export type UpdateVehicleOptionLexusMutationResult = Apollo.MutationResult<UpdateVehicleOptionLexusMutation>;
export type UpdateVehicleOptionLexusMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleOptionLexusMutation, UpdateVehicleOptionLexusMutationVariables>;
export const UpdateVehicleOptionReviewDocument = gql`
    mutation UpdateVehicleOptionReview($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateVehicleOptionReviewInput!, $filter: FilterInput!, $optionTab: String) {
  updateVehicleOptionReview(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
    filter: $filter
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type UpdateVehicleOptionReviewMutationFn = Apollo.MutationFunction<UpdateVehicleOptionReviewMutation, UpdateVehicleOptionReviewMutationVariables>;
export type UpdateVehicleOptionReviewMutationResult = Apollo.MutationResult<UpdateVehicleOptionReviewMutation>;
export type UpdateVehicleOptionReviewMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleOptionReviewMutation, UpdateVehicleOptionReviewMutationVariables>;
export const UpdateVehicleOptionSpecLexusDocument = gql`
    mutation UpdateVehicleOptionSpecLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateVehicleOptionSpecInput!, $optionTab: String!) {
  updateVehicleOptionSpecLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type UpdateVehicleOptionSpecLexusMutationFn = Apollo.MutationFunction<UpdateVehicleOptionSpecLexusMutation, UpdateVehicleOptionSpecLexusMutationVariables>;
export type UpdateVehicleOptionSpecLexusMutationResult = Apollo.MutationResult<UpdateVehicleOptionSpecLexusMutation>;
export type UpdateVehicleOptionSpecLexusMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleOptionSpecLexusMutation, UpdateVehicleOptionSpecLexusMutationVariables>;
export const UpdateVehicleOptionSpecReviewLexusDocument = gql`
    mutation UpdateVehicleOptionSpecReviewLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateVehicleOptionSpecReviewInput!, $filter: FilterInput!, $optionTab: String) {
  updateVehicleOptionSpecReviewLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
    filter: $filter
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type UpdateVehicleOptionSpecReviewLexusMutationFn = Apollo.MutationFunction<UpdateVehicleOptionSpecReviewLexusMutation, UpdateVehicleOptionSpecReviewLexusMutationVariables>;
export type UpdateVehicleOptionSpecReviewLexusMutationResult = Apollo.MutationResult<UpdateVehicleOptionSpecReviewLexusMutation>;
export type UpdateVehicleOptionSpecReviewLexusMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleOptionSpecReviewLexusMutation, UpdateVehicleOptionSpecReviewLexusMutationVariables>;
export const UpdateVehicleOptionStatusDocument = gql`
    mutation UpdateVehicleOptionStatus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateVehicleOptionStatusInput!) {
  updateVehicleOptionStatus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleOptionStatusMutationFn = Apollo.MutationFunction<UpdateVehicleOptionStatusMutation, UpdateVehicleOptionStatusMutationVariables>;
export type UpdateVehicleOptionStatusMutationResult = Apollo.MutationResult<UpdateVehicleOptionStatusMutation>;
export type UpdateVehicleOptionStatusMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleOptionStatusMutation, UpdateVehicleOptionStatusMutationVariables>;
export const UpdateVehicleOptionStatusAllDocument = gql`
    mutation UpdateVehicleOptionStatusAll($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $status: Int!) {
  updateVehicleOptionStatusAll(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
  ) {
    body
  }
}
    `;
export type UpdateVehicleOptionStatusAllMutationFn = Apollo.MutationFunction<UpdateVehicleOptionStatusAllMutation, UpdateVehicleOptionStatusAllMutationVariables>;
export type UpdateVehicleOptionStatusAllMutationResult = Apollo.MutationResult<UpdateVehicleOptionStatusAllMutation>;
export type UpdateVehicleOptionStatusAllMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleOptionStatusAllMutation, UpdateVehicleOptionStatusAllMutationVariables>;
export const UpdateVehicleOptionsCategoriesDocument = gql`
    mutation UpdateVehicleOptionsCategories($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $payload: UpdateVehicleOptionCategoriesInput!) {
  updateVehicleOptionsCategories(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehicleOptionsCategoriesMutationFn = Apollo.MutationFunction<UpdateVehicleOptionsCategoriesMutation, UpdateVehicleOptionsCategoriesMutationVariables>;
export type UpdateVehicleOptionsCategoriesMutationResult = Apollo.MutationResult<UpdateVehicleOptionsCategoriesMutation>;
export type UpdateVehicleOptionsCategoriesMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleOptionsCategoriesMutation, UpdateVehicleOptionsCategoriesMutationVariables>;
export const UpdateVehiclePackageLexusDocument = gql`
    mutation UpdateVehiclePackageLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateVehiclePackageLexusInput!) {
  updateVehiclePackageLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehiclePackageLexusMutationFn = Apollo.MutationFunction<UpdateVehiclePackageLexusMutation, UpdateVehiclePackageLexusMutationVariables>;
export type UpdateVehiclePackageLexusMutationResult = Apollo.MutationResult<UpdateVehiclePackageLexusMutation>;
export type UpdateVehiclePackageLexusMutationOptions = Apollo.BaseMutationOptions<UpdateVehiclePackageLexusMutation, UpdateVehiclePackageLexusMutationVariables>;
export const UpdateVehiclePackageVarietyLexusDocument = gql`
    mutation UpdateVehiclePackageVarietyLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateVehiclePackageVarietyLexusInput!) {
  updateVehiclePackageVarietyLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
  ) {
    body
  }
}
    `;
export type UpdateVehiclePackageVarietyLexusMutationFn = Apollo.MutationFunction<UpdateVehiclePackageVarietyLexusMutation, UpdateVehiclePackageVarietyLexusMutationVariables>;
export type UpdateVehiclePackageVarietyLexusMutationResult = Apollo.MutationResult<UpdateVehiclePackageVarietyLexusMutation>;
export type UpdateVehiclePackageVarietyLexusMutationOptions = Apollo.BaseMutationOptions<UpdateVehiclePackageVarietyLexusMutation, UpdateVehiclePackageVarietyLexusMutationVariables>;
export const UpdateVehiclePackageVarietyReviewLexusDocument = gql`
    mutation UpdateVehiclePackageVarietyReviewLexus($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $payload: UpdateVehiclePackageVarietyReviewInput!, $filter: FilterInput!, $optionTab: String) {
  updateVehiclePackageVarietyReviewLexus(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    payload: $payload
    filter: $filter
    optionTab: $optionTab
  ) {
    body
  }
}
    `;
export type UpdateVehiclePackageVarietyReviewLexusMutationFn = Apollo.MutationFunction<UpdateVehiclePackageVarietyReviewLexusMutation, UpdateVehiclePackageVarietyReviewLexusMutationVariables>;
export type UpdateVehiclePackageVarietyReviewLexusMutationResult = Apollo.MutationResult<UpdateVehiclePackageVarietyReviewLexusMutation>;
export type UpdateVehiclePackageVarietyReviewLexusMutationOptions = Apollo.BaseMutationOptions<UpdateVehiclePackageVarietyReviewLexusMutation, UpdateVehiclePackageVarietyReviewLexusMutationVariables>;