const Emitter = require('tiny-emitter');

export const emitter = new Emitter();
export const uiBlockEventId = 'block-ui-event';

export const uiBlock = {
  start: () => {
    emitter.emit(uiBlockEventId, true);
  },
  stop: () => {
    emitter.emit(uiBlockEventId, false);
  } 
};
