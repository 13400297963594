import React, { useCallback } from 'react';
import { FlexContainer } from 'vapi-ui-common';
import ChangeLogChanges, {
  ChangeLogBeforeAfter,
} from '../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import styles from '../components/ChangeLogTable/ChangeLogChanges/changeLogChanges.module.scss';
import { LIMITED_DATA_STATUS } from '../constants/vehicleData/VDConstants';
import {
  ChangeLogItem,
  ChangeLogTypes,
  ModelApplicabilityChanges,
} from '../models/changeLog.model';

const useChangeLogChanges = (
  statusType: ChangeLogTypes,
  applicabilityStatusType: ChangeLogTypes,
  allStatusType: ChangeLogTypes
) => {
  const limitedDataStatusToString = (status: number) => {
    switch (status) {
      case LIMITED_DATA_STATUS.READY_TO_PUBLISH:
        return 'Ready to Publish';
      case LIMITED_DATA_STATUS.IN_PROGRESS:
        return 'In Progress';
      case LIMITED_DATA_STATUS.PUBLISHED:
        return 'Published';
      default:
        return '';
    }
  };

  const limitedDataStatusChangeLogChanges = useCallback(
    (log: ChangeLogItem | ModelApplicabilityChanges) => {
      const before = Number(log.before);
      const after = Number(log.after);

      switch (after) {
        case LIMITED_DATA_STATUS.READY_TO_PUBLISH:
        case LIMITED_DATA_STATUS.IN_PROGRESS:
        case LIMITED_DATA_STATUS.PUBLISHED:
          return (
            <ChangeLogBeforeAfter
              before={limitedDataStatusToString(before)}
              after={limitedDataStatusToString(after)}
              styleAfter
              inline
            />
          );
      }
    },
    []
  );

  const modelApplicabilityStatusChangeLogChanges = useCallback(
    (log: ChangeLogItem) => {
      return (
        <>
          {log.applicabilityChanges &&
            log.applicabilityChanges.map((change, index) => (
              <FlexContainer key={index}>
                <span className={styles.changeModelTitle}>{change.title}: </span>
                <div className={styles.applicabilityChanges}>
                  {limitedDataStatusChangeLogChanges(change)}
                </div>
              </FlexContainer>
            ))}
        </>
      );
    },
    [limitedDataStatusChangeLogChanges]
  );

  const filterChangeLogChanges = useCallback(
    (log: ChangeLogItem) => {
      switch (log.changeType) {
        case statusType:
        case allStatusType:
          return Number(log.after) !== LIMITED_DATA_STATUS.PUBLISHED;
        case applicabilityStatusType: {
          const changes = Object.values(log.after);
          const isPublished =
            changes.filter(after => {
              return Number(after) === LIMITED_DATA_STATUS.PUBLISHED;
            }).length === changes.length;
          return !isPublished;
        }
        default:
          return true;
      }
    },
    [statusType, applicabilityStatusType, allStatusType]
  );

  const changeLogChanges = useCallback(
    (log: ChangeLogItem) => {
      switch (log.changeType) {
        case statusType:
        case allStatusType:
          return limitedDataStatusChangeLogChanges(log);
        case applicabilityStatusType:
          return modelApplicabilityStatusChangeLogChanges(log);
        default:
          return <ChangeLogChanges changeItem={log} />;
      }
    },
    [
      statusType,
      applicabilityStatusType,
      allStatusType,
      limitedDataStatusChangeLogChanges,
      modelApplicabilityStatusChangeLogChanges,
    ]
  );

  const canRevert = useCallback(
    (log: ChangeLogItem) => {
      switch (log.changeType) {
        case statusType:
        case applicabilityStatusType:
        case allStatusType: {
          return false;
        }
        default: {
          return log.canRevert;
        }
      }
    },
    [statusType, applicabilityStatusType, allStatusType]
  );

  return { filterChangeLogChanges, changeLogChanges, canRevert };
};

export default useChangeLogChanges;
