import { observer } from 'mobx-react-lite';
import React, { CSSProperties, ReactNode, useRef } from 'react';
import ResizeDetector from 'react-resize-detector';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { Table, Thead } from '../../../../components/Table';
import RightTableSizer from '../../../../components/Table/components/RightTableSizer';
import useStores from '../../../../hooks/useStores';
import { RefItem } from '../../../../models/refItem.model';
import { MultiSelectCheckbox } from '../../../../components/MultiSelectCheckbox';
import styles from './gradeTable.module.scss';

type ModelTableProps = {
  grades: RefItem[];
  renderRows: () => ReactNode;
  headerStyle?: CSSProperties;
  highlightSortMode: boolean;
  onSelectGrade: (id: string) => void;
  gradeToggledArr: string[];
};
const GradeTable: React.FC<ModelTableProps> = ({
  renderRows,
  onSelectGrade,
  grades,
  highlightSortMode,
  gradeToggledArr,
  headerStyle = {},
}) => {
  const { tableSizeStore } = useStores();

  const ref = useRef<HTMLDivElement>(null);

  const onResize = (width: number, height: number) => {
    tableSizeStore.tableRowHeight = height;
  };

  return (
    <ScrollSync>
      <RightTableSizer>
        <div ref={ref} className={styles.headerSection}>
          <ScrollSyncPane group="horizontal">
            <div className={styles.scrollerDivHidden}>
              <Table>
                <Thead>
                  <tr>
                    {grades.map(grade => {
                      let gradeIdToUse = '';
                      gradeToggledArr.forEach(e => {
                        if (e === grade.id) gradeIdToUse = e;
                      });
                      return (
                        <th className={styles.gradeHeader} key={grade.uid} style={headerStyle}>
                          {highlightSortMode ? (
                            <MultiSelectCheckbox
                              label={grade.value}
                              key={grade.id}
                              value={grade.id}
                              selectedValue={gradeIdToUse}
                              name={'Grade Table Radio Group'}
                              onChange={onSelectGrade}
                              className={styles.grade}
                            />
                          ) : (
                            <div className={styles.grade}>{grade.value}</div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </Thead>
              </Table>
            </div>
          </ScrollSyncPane>
          <ResizeDetector
            handleHeight
            onResize={(width, height) => onResize(width, height)}
            targetDomEl={ref === null ? undefined : (ref.current as HTMLElement)}
          />
        </div>
        <ScrollSyncPane group="horizontal">
          <div className={styles.scrollerDiv}>
            <Table>
              <tbody>{renderRows()}</tbody>
            </Table>
          </div>
        </ScrollSyncPane>
      </RightTableSizer>
    </ScrollSync>
  );
};

export default observer(GradeTable);
