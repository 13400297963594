import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import { Button } from 'vapi-ui-common';
import { Language } from '../../../../models/user.model';
import styles from './SyncTdPRButton.module.scss';

interface ISyncTdPRButton {
  id: string;
  onClick: () => void;
  changedAttributes: string[];
  selectedLangs?: Language[];
  className?: string;
}

const SyncTdPRButton = ({
  id,
  onClick,
  changedAttributes,
  selectedLangs,
  className,
}: ISyncTdPRButton) => {
  const isNew = changedAttributes.includes('new');
  const isDelete = changedAttributes.includes('delete');
  const hasTMNAChanges = !!changedAttributes.length;
  const onlySpanish = selectedLangs && !selectedLangs?.includes(Language.EN);

  return (
    <div className={styles.border}>
      <Button
        id={`compare-button-${id}`}
        className={cx(
          styles.tdprButton,
          {
            [styles.hasTMNAChanges]: hasTMNAChanges,
            [styles.onlySpanish]: onlySpanish,
            [styles.featureSpecs]: selectedLangs && !onlySpanish,
          },
          className
        )}
        onClick={() => {
          onClick();
        }}
      >
        {hasTMNAChanges && (
          <FontAwesomeIcon className={cx(styles.warningIcon)} icon={faExclamationTriangle} />
        )}
        {isNew ? 'New Row' : isDelete ? 'Deleted Row' : 'NAT'}
      </Button>
    </div>
  );
};

export default SyncTdPRButton;
