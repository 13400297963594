import { observer } from 'mobx-react-lite';
import {
  OptionsPackage,
  OptionsPackageSpec,
  OptionsPackageVariety,
} from '../../../../../../models/optionsLexus.model';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { SuggestionType } from 'vapi-ui-common';
import OptionsLexusPackageRow from '.';
import OptionsLexusDividerRow from '../OptionsLexusDividerRow';
import OptionsLexusVarietyRow from '../OptionsLexusVarietyRow';
import { PackageTotalRow } from '../totalRows';
import { IOptionsLexusPackageRow } from './IOptionsLexusPackageRow';
import { IOptionsLexusPackageSpecRow } from './IOptionsLexusPackageSpecRow';
import OptionsLexusPackageSpecRow from './OptionsLexusPackageSpecRow';

interface Props extends IOptionsLexusPackageRow, IOptionsLexusPackageSpecRow {
  options: OptionsPackage[];
  saveVariety: (option: OptionsPackage, variety: OptionsPackageVariety) => void;
  deleteVariety: (option: OptionsPackage, variety: OptionsPackageVariety) => void;
  rerender: boolean;
}

/**
 * Wraps the Droppable component so updates to options, categories will
 * trigger a re-render
 */
const OptionsLexusPackageRowsContainer = ({
  options,
  disabled,
  saveSpec,
  deleteSpec,
  saveVariety,
  deleteVariety,
  rerender,
  tokens,
  ...rest
}: Props) => {
  const renderSpecRows = (option: OptionsPackage, tokens?: SuggestionType[]) => (
    <>
      {option.specs.map((spec, index) =>
        index === 0 ? null : (
          <OptionsLexusPackageSpecRow
            key={spec.uid}
            disabled={disabled}
            optionsPackage={option}
            spec={spec}
            saveSpec={saveSpec}
            deleteSpec={deleteSpec}
            tokens={tokens}
          />
        )
      )}
    </>
  );

  const renderTotalRows = (option: OptionsPackage) => (
    <>
      {!option.isOption && !option.packageVarieties.length && (
        <>
          {/* Package Total */}
          <PackageTotalRow
            onFillRowHeightChange={height => {
              option.packageTotals.rowHeight = height;
            }}
          >
            <td colSpan={6}>Package Total</td>
          </PackageTotalRow>

          {/* Package Total Requirements */}
          <PackageTotalRow
            isRequired
            onFillRowHeightChange={height => (option.packageTotalReqs.rowHeight = height)}
          >
            <td colSpan={6}>Package Total with Requirements</td>
          </PackageTotalRow>
        </>
      )}
      {option.isOption && (
        <PackageTotalRow
          isRequired
          onFillRowHeightChange={height => (option.packageTotals.rowHeight = height)}
        >
          <td colSpan={6}>Total</td>
        </PackageTotalRow>
      )}
      {option.packageVarieties.map(item => (
        <OptionsLexusVarietyRow
          key={item.uid}
          variety={item}
          readOnly={disabled}
          saveOption={() => saveVariety(option, item)}
          onDeleteVariety={variety => deleteVariety(option, variety)}
        />
      ))}
    </>
  );

  return (
    <Droppable droppableId="optionsDroppable">
      {provided => (
        <tbody ref={provided.innerRef} {...provided.droppableProps}>
          {options.map((option, index) => (
            <React.Fragment key={option.uid}>
              <OptionsLexusPackageRow
                key={option.uid}
                index={index}
                disabled={disabled}
                optionsPackage={option}
                spec={option.specs.length > 0 ? option.specs[0] : new OptionsPackageSpec()}
                nameSpan={
                  option.packageVarieties.length > 0
                    ? option.specs.length || 1
                    : (option.specs.length || 1) + (option.isOption ? 1 : 2)
                }
                requiredSpan={option.specs.length || 1}
                zebra={option.specs.length > 0}
                expandRow={option.specs.length < 2}
                saveSpec={saveSpec}
                deleteSpec={deleteSpec}
                tokens={tokens}
                {...rest}
              />
              {renderSpecRows(option, tokens)}
              {renderTotalRows(option)}
              <OptionsLexusDividerRow />
            </React.Fragment>
          ))}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
};

export default observer(OptionsLexusPackageRowsContainer);
