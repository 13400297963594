import React, { useEffect, useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabColumn,
  FilterTabColumns,
  FilterTabFooter,
} from 'vapi-ui-common';
import Checkbox from '../../../../../../components/Checkbox';
import FiltersColumn from '../../../../../../components/FiltersColumn/FiltersColumn';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';

type CompareFiltersProps = {
  categories: string[];
  categoryFilters: string[];
  subCategories?: string[];
  subCategoryFilters?: string[];
  setSubCategoryFilters?: (subCategoryFilters: string[]) => void;
  setCategoryFilters: (categoryFilters: string[]) => void;
  isInProgressFilter: boolean;
  setIsInProgressFilter: (value: boolean) => void;
  onClose: () => void;
  isPublished?: boolean;
  isSyncUpdateFilter?: boolean;
  setIsSyncUpdateFilter?: (value: boolean) => void;
  isReviewNotesFilter?: boolean;
  setIsReviewNotesFilter?: (value: boolean) => void;
};
const CompareFeatureFilters: React.FC<CompareFiltersProps> = ({
  categories,
  categoryFilters,
  setCategoryFilters,
  subCategories,
  subCategoryFilters,
  setSubCategoryFilters,
  isInProgressFilter,
  setIsInProgressFilter,
  onClose,
  isPublished,
  isSyncUpdateFilter,
  setIsSyncUpdateFilter,
  isReviewNotesFilter,
  setIsReviewNotesFilter,
}) => {
  const [isInProgress, setIsInProgress] = useState(isInProgressFilter);
  const [isSyncUpdate, setIsSyncUpdate] = useState(isSyncUpdateFilter || false);
  const [isReviewNotes, setIsReviewNotes] = useState(isReviewNotesFilter || false);

  const {
    teamStore: {
      team: { name, allowInProgressFilter },
    },
  } = useStores();

  const [selectedCategoryFilters, setSelectedCategoryFilters] = useState<string[]>(
    categoryFilters ?? []
  );
  const [selectedSubCategoryFilters, setSelectedSubCategoryFilters] = useState<string[]>(
    subCategoryFilters ?? []
  );

  useEffect(() => {
    if (categoryFilters) {
      setSelectedCategoryFilters(categoryFilters);
    }
    if (subCategoryFilters) {
      setSelectedSubCategoryFilters(subCategoryFilters);
    }
  }, [
    categoryFilters,
    subCategoryFilters,
    setSelectedCategoryFilters,
    setSelectedSubCategoryFilters,
  ]);

  const applyFilters = () => {
    setIsInProgressFilter(isInProgress);

    if (setCategoryFilters) {
      setCategoryFilters(selectedCategoryFilters);
    }
    if (setSubCategoryFilters) {
      setSubCategoryFilters(selectedSubCategoryFilters);
    }

    setIsSyncUpdateFilter && setIsSyncUpdateFilter(isSyncUpdate);
    setIsReviewNotesFilter && setIsReviewNotesFilter(isReviewNotes);
    onClose();
  };

  return (
    <FilterTab>
      <FilterTabColumns>
        <FiltersColumn
          title={'Categories'}
          list={categories ? categories : []}
          filters={selectedCategoryFilters}
          setFilters={setSelectedCategoryFilters}
        />
        <FiltersColumn
          title={'Sub Categories'}
          list={subCategories ? subCategories : []}
          filters={selectedSubCategoryFilters}
          setFilters={setSelectedSubCategoryFilters}
        />
        {allowInProgressFilter && (
          <FilterTabColumn>
            <Checkbox
              id="InProgress"
              defaultChecked={isInProgress}
              onChange={e => setIsInProgress(e.currentTarget.checked)}
            >
              In Progress
            </Checkbox>
          </FilterTabColumn>
        )}
        {setIsReviewNotesFilter && !isPublished && (
          <FilterTabColumn>
            <Checkbox
              id="ReviewNotes"
              checked={isReviewNotes}
              onChange={e => setIsReviewNotes(e.currentTarget.checked)}
            >
              Review Notes
            </Checkbox>
          </FilterTabColumn>
        )}
        {VehicleTeam.AGENCY_SPANISH === name && setIsSyncUpdateFilter && !isPublished && (
          <FilterTabColumn>
            <Checkbox
              id="SyncUpdates"
              checked={isSyncUpdate}
              onChange={e => setIsSyncUpdate(e.currentTarget.checked)}
            >
              Sync Updates
            </Checkbox>
          </FilterTabColumn>
        )}
      </FilterTabColumns>
      <FilterTabFooter>
        <Button variant="primary" onClick={() => applyFilters()}>
          Apply Filters
        </Button>
      </FilterTabFooter>
    </FilterTab>
  );
};

export default CompareFeatureFilters;
