import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import { ChangeLogRequest, ChangeLogResponse } from '../models/changeLog.model';
import {
  CompareFeatureDocumentResponse,
  CompareFeatureRequest,
  CompareFeatureResponse,
  CompareFeaturesReviewResponse,
  CompareFieldStatusRequest,
  CompareGradeApplicabilityMap,
  CompareType,
  IDoubleSortList,
} from '../models/compareFeatures.model';
import { RefItem } from '../models/refItem.model';
import { ReviewChangeRequest } from '../models/review.model';
import { Language } from '../models/user.model';
import { VehicleTeam } from '../models/vehicleData.model';
import {
  GradeFieldStatus,
  UpdateAllModelStatusesRequest,
  UpdateAllModelStatusesResponse,
} from '../models/vehicleModel.model';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-features';
const COMPARE_FEATURES_PATH = '/compare-features';

export const getCompareFeatures = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CompareFeatureDocumentResponse>(
    `${RESOURCE_PATH}${COMPARE_FEATURES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}?${params}`
  );
};

export const addCompareFeature = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  payload: CompareFeatureRequest
) => {
  return API.post<CompareFeatureResponse>(
    `${RESOURCE_PATH}${COMPARE_FEATURES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}`,
    payload
  );
};

export const updateCompareFeature = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  payload: CompareFeatureRequest,
  acceptChanges: boolean = false
) => {
  if (!payload.notes) {
    payload.notes = '';
  }
  return API.put<CompareFeatureResponse>(
    `${RESOURCE_PATH}${COMPARE_FEATURES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}`,
    { ...payload, acceptChanges }
  );
};

export const deleteCompareFeature = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  compareFeatureId: string
) => {
  return API.delete(
    `${RESOURCE_PATH}${COMPARE_FEATURES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}/${compareFeatureId}`
  );
};

// ChangeLog
export const getChangeLog = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  version: string,
  type: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<ChangeLogResponse[]>(
    `${RESOURCE_PATH}/change-log/${brand}/${team}/${series}/${year}/${lang}/${type}?${params}`
  );
};

export const revertChange = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  type: string,
  payload: ChangeLogRequest
) => {
  return API.put(
    `${RESOURCE_PATH}/change-log/revert/${brand}/${team}/${series}/${year}/${lang}/${type}`,
    payload
  );
};

export const updateDoubleSortList = (
  brand: string,
  team: string,
  seriesId: string,
  year: string,
  lang: string,
  payload: IDoubleSortList
) => {
  return API.put<IDoubleSortList>(
    `${RESOURCE_PATH}${COMPARE_FEATURES_PATH}/sortList/${brand}/${team}/${seriesId}/${year}/${lang}`,
    payload
  );
};

export const addCompareFeatureFromParent = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  year: string,
  lang: string,
  parentId: string,
  compareType: CompareType,
  description: string,
  categoryId: string,
  subCategoryId: string,
  grades: RefItem[],
  id?: string
) => {
  const gradeApplicability: CompareGradeApplicabilityMap = {};
  grades.forEach(grade => {
    gradeApplicability[grade.id] = {
      highlighted: false,
      text: null,
      applicability: null,
    };
  });
  const request: CompareFeatureRequest = {
    id: id ?? '',
    revId: '',
    compareType,
    parentId,
    description,
    categoryId,
    subCategoryId,
    isInProgress: false,
    notes: '',
    gradeApplicability: {},
  };
  return addCompareFeature(brand, team, seriesId, year, lang, request);
};

// Review
const REVIEW_PATH = '/review';
const COMPARE_REVIEW_PATH = '/compareFeatures';

export const getReviewCompareFeatures = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CompareFeaturesReviewResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${COMPARE_REVIEW_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const updateReviewCompareFeature = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version: string,
  payload: ReviewChangeRequest
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<CompareFeaturesReviewResponse>(
    `${RESOURCE_PATH}${REVIEW_PATH}${COMPARE_REVIEW_PATH}/${brand}/${team}/${series}/${year}?${params}`,
    payload
  );
};

export const updateCompareFeatureStatus = (
  brand: string,
  team: VehicleTeam,
  serieId: string,
  modelYear: string,
  language: Language,
  payload: CompareFieldStatusRequest
) => {
  return API.put<GradeFieldStatus>(
    `${RESOURCE_PATH}${COMPARE_FEATURES_PATH}-status/${brand}/${team}/${serieId}/${modelYear}/${language}`,
    payload
  );
};

export const updateAllCompareFeatureStatuses = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: UpdateAllModelStatusesRequest
) => {
  return API.post<UpdateAllModelStatusesResponse>(
    `${RESOURCE_PATH}${COMPARE_FEATURES_PATH}-status/${brand}/${team}/${seriesId}/${modelYear}/${language}/all`,
    payload
  );
};
