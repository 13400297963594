import { observer } from 'mobx-react-lite';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { TwoTableWrapper } from '../../../../../components/Table';
import { TableTabs } from '../../../../../components/Table/components/TableTabs';
import { uiBlock } from '../../../../../components/UiBlocker/uiBlock';
import useStores from '../../../../../hooks/useStores';
import { COLORS_TAB } from '../../../../../models/colors.model';
import { ColorsLexusExteriorItem } from '../../../../../models/colorsLexus.model';
import { ReviewChangeBaseItem } from '../../../../../models/review.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../models/vehicleModel.model';
import ActionBarVehicleData from '../../../../../routes/vehicleData/components/ActionBarVehicleData';
import LeftTable from '../../../../../routes/vehicleData/components/LeftTable';
import { ProductDataControllerProps } from '../../../../../routes/vehicleData/models/controllers.model';
import { handleErrorResponse } from '../../../../../utils/errorHandlingUtils';
import { updateRevIdById } from '../../../../../utils/reviewUtils';
import { updateReviewExteriorColors } from '../../../../../webservices/vehicleColorsApi';
import ColorsFilters from '../components/ColorsFilters';
import ColorsLexusAccessibilityTable from './components/ColorsLexusAccessibilityTable';
import ColorsLexusInteriorRow from './components/ColorsLexusAccessibilityTable/components/ColorsLexusInteriorRow';
import ColorsLexusExteriorReviewRow from './components/ColorsLexusExteriorRow/ColorsLexusExteriorReviewRow';
import ColorsLexusHeaderReviewRow from './components/ColorsLexusHeaderRow/ColorsLexusHeaderReviewRow';
import { Status } from 'vapi-ui-common';
import { toGqlBrand, toGqlTeam, toGqlFilter } from '../../../../../utils/graphqlUtils';

interface ColorsLexusExteriorReviewProps extends ProductDataControllerProps {
  colorTab: COLORS_TAB;
  setColorTab(tab: COLORS_TAB): void;
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
}

const ColorsLexusExteriorReview = ({
  seriesId,
  year,
  version = '',
  colorTab,
  setColorTab,
  vehicleModels,
}: ColorsLexusExteriorReviewProps) => {
  const {
    reviewStore: { hideAccept },
    colorsLexusReviewStore: { exteriorStore },
    teamStore,
    userStore: { brand },
  } = useStores();

  const updateExterior = async (
    exterior: ColorsLexusExteriorItem,
    change: ReviewChangeBaseItem
  ) => {
    try {
      uiBlock.start();
      if (change.isValid()) {
        const payload = {
          ...change.getPayload(),
          id: exterior.id,
          revId: exterior.revId,
        };
        const response = await trackPromise(
          updateReviewExteriorColors({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId,
            modelYear: Number(year),
            filter: toGqlFilter(version ?? Status.DRAFT),
            payload: {
              id: payload.id,
              revId: payload.revId,
              isAccepted: payload.isAccepted,
              isApplied: payload.isApplied,
              changeTypeId: payload.changeTypeId,
              rejectNotes: payload.rejectNotes,
            },
          })
        );
        const { id, revId } = response;
        updateRevIdById(id, revId, exteriorStore.exteriorItems);
        toast.success('Successfully updated exterior color');
      } else {
        toast.error('Please fill in the required fields for the exterior color');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating exterior color');
    }

    uiBlock.stop();
  };

  return (
    <>
      <ActionBarVehicleData
        readOnly
        searchText={exteriorStore.searchText}
        onSearchTextChange={text => exteriorStore.onFilter(() => (exteriorStore.searchText = text))}
        renderFilter={onClose => (
          <ColorsFilters
            onClose={onClose}
            isInProgressFilter={exteriorStore.isInProgressFilter}
            setIsInProgressFilter={value =>
              exteriorStore.onFilter(() => (exteriorStore.isInProgressFilter = value))
            }
            isExtraCostColorFilter={exteriorStore.isExtraCostFilter}
            setIsExtraCostColorFilter={value =>
              exteriorStore.onFilter(() => (exteriorStore.isExtraCostFilter = value))
            }
          />
        )}
      />
      <TableTabs
        style={{ zIndex: 1 }}
        tabs={[COLORS_TAB.EXTERIOR, COLORS_TAB.INTERIOR]}
        currentTab={colorTab}
        onSelectTab={value => setColorTab(value as COLORS_TAB)}
      />
      <TwoTableWrapper>
        <LeftTable
          renderHeaderRow={() => (
            <ColorsLexusHeaderReviewRow
              heightSync={exteriorStore.heightSync}
              hideAccept={hideAccept}
            />
          )}
          renderRows={() =>
            exteriorStore.filteredExteriors.map(ext =>
              ext.changes.map(change => (
                <ColorsLexusExteriorReviewRow
                  key={change.uid}
                  change={change}
                  exterior={ext}
                  updateItem={updateExterior}
                  hideAccept={hideAccept}
                />
              ))
            )
          }
        />
        <ColorsLexusAccessibilityTable
          interiorGroups={exteriorStore.interiorGroups}
          heightSync={exteriorStore.heightSync}
          readOnly
          vehicleModels={vehicleModels}
          renderBodyRows={() =>
            exteriorStore.filteredExteriors.map(ext =>
              ext.changes.map(change => (
                <ColorsLexusInteriorRow
                  key={change.uid}
                  exteriorItem={ext}
                  rowHeight={change.rowHeight}
                  readOnly
                />
              ))
            )
          }
        />
      </TwoTableWrapper>
    </>
  );
};

export default observer(ColorsLexusExteriorReview);
