import React, { TdHTMLAttributes, useLayoutEffect, useRef } from 'react';

export interface TableHeightCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
  onHeightChange: (height: number) => void;
  height?: number;
}

const TableHeightCell = ({ onHeightChange, height, ...rest }: TableHeightCellProps) => {
  const ref = useRef<HTMLTableCellElement>(null);
  useLayoutEffect(() => {
    if (ref && ref.current) {
      onHeightChange(ref.current.offsetHeight);
    }
  }, [onHeightChange]);

  return (
    <td ref={ref} data-testid="table-height-cell" {...rest} />
    // {({ width, height, targetRef }) => <div ref={targetRef}>{`${width}x${height}`}</div>}
  );
};

export { TableHeightCell };
