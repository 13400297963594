import apolloClient from '../apolloClient';
import {
  Brand,
  CreateCfExtColorDocument,
  CreateCfExtColorMutation,
  CreateCfExtColorMutationVariables,
  CreateCfIntColorDocument,
  CreateCfIntColorMutation,
  CreateCfIntColorMutationVariables,
  CreateColorFamilyDocument,
  CreateColorFamilyMutation,
  CreateColorFamilyMutationVariables,
  CreateExtColorLexusDocument,
  CreateExtColorLexusMutation,
  CreateExtColorLexusMutationVariables,
  CreateExtColorToyotaDocument,
  CreateExtColorToyotaMutation,
  CreateExtColorToyotaMutationVariables,
  CreateGroupTypesLexusDocument,
  CreateGroupTypesLexusMutation,
  CreateGroupTypesLexusMutationVariables,
  CreateIntColorLexusDocument,
  CreateIntColorLexusMutation,
  CreateIntColorLexusMutationVariables,
  CreateIntColorToyotaDocument,
  CreateIntColorToyotaMutation,
  CreateIntColorToyotaMutationVariables,
  CreateIntColorTypesLexusDocument,
  CreateIntColorTypesLexusMutation,
  CreateIntColorTypesLexusMutationVariables,
  CreateMaterialsDocument,
  CreateMaterialsMutation,
  CreateMaterialsMutationVariables,
  DeleteCfExtColorDocument,
  DeleteCfExtColorMutation,
  DeleteCfExtColorMutationVariables,
  DeleteCfIntColorDocument,
  DeleteCfIntColorMutation,
  DeleteCfIntColorMutationVariables,
  DeleteColorFamilyDocument,
  DeleteColorFamilyMutation,
  DeleteColorFamilyMutationVariables,
  DeleteExtColorDocument,
  DeleteExtColorMutation,
  DeleteExtColorMutationVariables,
  DeleteGroupTypesLexusDocument,
  DeleteGroupTypesLexusMutation,
  DeleteGroupTypesLexusMutationVariables,
  DeleteIntColorDocument,
  DeleteIntColorMutation,
  DeleteIntColorMutationVariables,
  Language,
  PublishColorFamilyDraftDocument,
  PublishColorFamilyDraftMutation,
  PublishColorFamilyDraftMutationVariables,
  ReadCfExtColorsDocument,
  ReadCfExtColorsQuery,
  ReadCfExtColorsQueryVariables,
  ReadCfIntColorsDocument,
  ReadCfIntColorsQuery,
  ReadCfIntColorsQueryVariables,
  ReadColorChangesDocument,
  ReadColorChangesQuery,
  ReadColorChangesQueryVariables,
  ReadColorFamiliesDocument,
  ReadColorFamiliesQuery,
  ReadColorFamiliesQueryVariables,
  ReadExtColorReviewDocument,
  ReadExtColorReviewQuery,
  ReadExtColorReviewQueryVariables,
  ReadExtColorsDocument,
  ReadExtColorsQuery,
  ReadExtColorsQueryVariables,
  ReadIntColorReviewDocument,
  ReadIntColorReviewQuery,
  ReadIntColorReviewQueryVariables,
  ReadIntColorsDocument,
  ReadIntColorsQuery,
  ReadIntColorsQueryVariables,
  ReadIntColorTypesLexusDocument,
  ReadIntColorTypesLexusQuery,
  ReadIntColorTypesLexusQueryVariables,
  ReadIntColorTypesReviewLexusDocument,
  ReadIntColorTypesReviewLexusQuery,
  ReadIntColorTypesReviewLexusQueryVariables,
  ReadMaterialsDocument,
  ReadMaterialsQuery,
  ReadMaterialsQueryVariables,
  RevertColorChangeDocument,
  RevertColorChangeMutation,
  RevertColorChangeMutationVariables,
  UpdateCfExtColorDocument,
  UpdateCfExtColorMutation,
  UpdateCfExtColorMutationVariables,
  UpdateCfIntColorDocument,
  UpdateCfIntColorMutation,
  UpdateCfIntColorMutationVariables,
  UpdateColorFamilyDocument,
  UpdateColorFamilyMutation,
  UpdateColorFamilyMutationVariables,
  UpdateExtColorLexusDocument,
  UpdateExtColorLexusMutation,
  UpdateExtColorLexusMutationVariables,
  UpdateExtColorReviewDocument,
  UpdateExtColorReviewMutation,
  UpdateExtColorReviewMutationVariables,
  UpdateExtColorStatusAllDocument,
  UpdateExtColorStatusAllMutation,
  UpdateExtColorStatusAllMutationVariables,
  UpdateExtColorStatusDocument,
  UpdateExtColorStatusMutation,
  UpdateExtColorStatusMutationVariables,
  UpdateExtColorToyotaDocument,
  UpdateExtColorToyotaMutation,
  UpdateExtColorToyotaMutationVariables,
  UpdateGroupTypesLexusDocument,
  UpdateGroupTypesLexusMutation,
  UpdateGroupTypesLexusMutationVariables,
  UpdateIntColorLexusDocument,
  UpdateIntColorLexusMutation,
  UpdateIntColorLexusMutationVariables,
  UpdateIntColorReviewDocument,
  UpdateIntColorReviewMutation,
  UpdateIntColorReviewMutationVariables,
  UpdateIntColorStatusDocument,
  UpdateIntColorStatusMutation,
  UpdateIntColorStatusMutationVariables,
  UpdateIntColorToyotaDocument,
  UpdateIntColorToyotaMutation,
  UpdateIntColorToyotaMutationVariables,
  UpdateIntColorTypesLexusDocument,
  UpdateIntColorTypesLexusMutation,
  UpdateIntColorTypesLexusMutationVariables,
  UpdateMaterialsDocument,
  UpdateMaterialsMutation,
  UpdateMaterialsMutationVariables,
} from '../gql/generated';
import { ChangeLogResponse } from '../models/changeLog.model';
import {
  CFExteriorColorResponse,
  CFInteriorColorResponse,
  ColorFamilyResponse,
} from '../models/colorFamilies.model';
import {
  ColorsReviewResponse,
  ExteriorColorFieldStatus,
  ExteriorColorResponse,
  InteriorColorFieldStatus,
  InteriorColorResponse,
  InteriorColorTypeResponse,
} from '../models/colors.model';
import { GroupItem, VDExteriorColorLexus, VDInteriorColorLexus } from '../models/colorsLexus.model';
import { RefItemResponse } from '../models/refItem.model';
import { UpdateAllModelStatusesResponse } from '../models/vehicleModel.model';

// ext-colors
export const getExteriorColorsToyota = async (
  variables: ReadExtColorsQueryVariables
): Promise<ExteriorColorResponse[]> => {
  const { data } = await apolloClient.query<ReadExtColorsQuery, ReadExtColorsQueryVariables>({
    query: ReadExtColorsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readExtColors.body;
};

export const getExteriorColorsLexus = async (
  variables: Omit<ReadExtColorsQueryVariables, 'brand' | 'language'>
): Promise<VDExteriorColorLexus[]> => {
  const { data } = await apolloClient.query<ReadExtColorsQuery, ReadExtColorsQueryVariables>({
    query: ReadExtColorsDocument,
    variables: {
      ...variables,
      brand: Brand.Lexus,
      language: Language.En,
    },
    fetchPolicy: 'network-only',
  });

  return data.readExtColors.body;
};

export const addExteriorColorToyota = async (
  variables: CreateExtColorToyotaMutationVariables
): Promise<ExteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    CreateExtColorToyotaMutation,
    CreateExtColorToyotaMutationVariables
  >({
    mutation: CreateExtColorToyotaDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.createExtColorToyota.body;
};

export const addExteriorColorLexus = async (
  variables: Omit<CreateExtColorLexusMutationVariables, 'brand'>
): Promise<ExteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    CreateExtColorLexusMutation,
    CreateExtColorLexusMutationVariables
  >({
    mutation: CreateExtColorLexusDocument,
    variables: {
      ...variables,
      brand: Brand.Lexus,
    },
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.createExtColorLexus.body;
};

export const updateExteriorColorToyota = async (
  variables: UpdateExtColorToyotaMutationVariables
): Promise<ExteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateExtColorToyotaMutation,
    UpdateExtColorToyotaMutationVariables
  >({
    mutation: UpdateExtColorToyotaDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update exterior color');
  }

  return data.updateExtColorToyota.body;
};

export const updateExteriorColorLexus = async (
  variables: Omit<UpdateExtColorLexusMutationVariables, 'brand'>
): Promise<ExteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateExtColorLexusMutation,
    UpdateExtColorLexusMutationVariables
  >({
    mutation: UpdateExtColorLexusDocument,
    variables: {
      ...variables,
      brand: Brand.Lexus,
    },
  });

  if (!data) {
    throw new Error('Failed to update exterior color');
  }

  return data.updateExtColorLexus.body;
};

export const updateExteriorColorStatus = async (
  variables: Omit<UpdateExtColorStatusMutationVariables, 'language'>
): Promise<ExteriorColorFieldStatus> => {
  const { data } = await apolloClient.mutate<
    UpdateExtColorStatusMutation,
    UpdateExtColorStatusMutationVariables
  >({
    mutation: UpdateExtColorStatusDocument,
    variables: {
      ...variables,
      language: Language.En,
    },
  });

  if (!data) {
    throw new Error('Failed to update exterior color status');
  }

  return data.updateExtColorStatus.body;
};

export const updateAllColorStatuses = async (
  variables: UpdateExtColorStatusAllMutationVariables
): Promise<UpdateAllModelStatusesResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateExtColorStatusAllMutation,
    UpdateExtColorStatusAllMutationVariables
  >({
    mutation: UpdateExtColorStatusAllDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update all color statuses');
  }

  return data.updateExtColorStatusAll.body;
};

export const deleteExteriorColor = async (
  variables: DeleteExtColorMutationVariables
): Promise<ExteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    DeleteExtColorMutation,
    DeleteExtColorMutationVariables
  >({
    mutation: DeleteExtColorDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update all color statuses');
  }

  return data.deleteExtColor.body;
};

// int-colors
export const getInteriorColorsToyota = async (
  variables: ReadIntColorsQueryVariables
): Promise<InteriorColorResponse[]> => {
  const { data } = await apolloClient.query<ReadIntColorsQuery, ReadIntColorsQueryVariables>({
    query: ReadIntColorsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readIntColors.body;
};

export const getInteriorColorsLexus = async (
  variables: Omit<ReadIntColorsQueryVariables, 'brand' | 'language'>
): Promise<VDInteriorColorLexus[]> => {
  const { data } = await apolloClient.query<ReadIntColorsQuery, ReadIntColorsQueryVariables>({
    query: ReadIntColorsDocument,
    variables: {
      ...variables,
      brand: Brand.Lexus,
      language: Language.En,
    },
    fetchPolicy: 'network-only',
  });

  return data.readIntColors.body;
};

export const addInteriorColorToyota = async (
  variables: CreateIntColorToyotaMutationVariables
): Promise<InteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    CreateIntColorToyotaMutation,
    CreateIntColorToyotaMutationVariables
  >({
    mutation: CreateIntColorToyotaDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add interior color');
  }

  return data.createIntColorToyota.body;
};

export const addInteriorColorLexus = async (
  variables: Omit<CreateIntColorLexusMutationVariables, 'brand'>
): Promise<InteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    CreateIntColorLexusMutation,
    CreateIntColorLexusMutationVariables
  >({
    mutation: CreateIntColorLexusDocument,
    variables: {
      ...variables,
      brand: Brand.Lexus,
    },
  });

  if (!data) {
    throw new Error('Failed to add interior color');
  }

  return data.createIntColorLexus.body;
};

export const updateInteriorColorToyota = async (
  variables: UpdateIntColorToyotaMutationVariables
): Promise<InteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateIntColorToyotaMutation,
    UpdateIntColorToyotaMutationVariables
  >({
    mutation: UpdateIntColorToyotaDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update interior color');
  }

  return data.updateIntColorToyota.body;
};

export const updateInteriorColorLexus = async (
  variables: Omit<UpdateIntColorLexusMutationVariables, 'brand'>
): Promise<InteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateIntColorLexusMutation,
    UpdateIntColorLexusMutationVariables
  >({
    mutation: UpdateIntColorLexusDocument,
    variables: {
      ...variables,
      brand: Brand.Lexus,
    },
  });

  if (!data) {
    throw new Error('Failed to update interior color');
  }

  return data.updateIntColorLexus.body;
};

export const updateInteriorColorStatus = async (
  variables: Omit<UpdateIntColorStatusMutationVariables, 'language'>
): Promise<InteriorColorFieldStatus> => {
  const { data } = await apolloClient.mutate<
    UpdateIntColorStatusMutation,
    UpdateIntColorStatusMutationVariables
  >({
    mutation: UpdateIntColorStatusDocument,
    variables: {
      ...variables,
      language: Language.En,
    },
  });

  if (!data) {
    throw new Error('Failed to update interior color status');
  }

  return data.updateIntColorStatus.body;
};

export const deleteInteriorColor = async (
  variables: DeleteIntColorMutationVariables
): Promise<InteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    DeleteIntColorMutation,
    DeleteIntColorMutationVariables
  >({
    mutation: DeleteIntColorDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete interior color');
  }

  return data.deleteIntColor.body;
};

// int-color-types
export const getInteriorColorTypes = async (
  variables: ReadIntColorTypesLexusQueryVariables
): Promise<InteriorColorTypeResponse> => {
  const { data } = await apolloClient.query<
    ReadIntColorTypesLexusQuery,
    ReadIntColorTypesLexusQueryVariables
  >({
    query: ReadIntColorTypesLexusDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readIntColorTypesLexus.body;
};

export const addInteriorColorType = async (
  variables: CreateIntColorTypesLexusMutationVariables
): Promise<InteriorColorTypeResponse> => {
  const { data } = await apolloClient.mutate<
    CreateIntColorTypesLexusMutation,
    CreateIntColorTypesLexusMutationVariables
  >({
    mutation: CreateIntColorTypesLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add interior color type');
  }

  return data.createIntColorTypesLexus.body;
};

export const updateInteriorColorType = async (
  variables: UpdateIntColorTypesLexusMutationVariables
): Promise<InteriorColorTypeResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateIntColorTypesLexusMutation,
    UpdateIntColorTypesLexusMutationVariables
  >({
    mutation: UpdateIntColorTypesLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update interior color type');
  }

  return data.updateIntColorTypesLexus.body;
};

// changes
export const getChangeLog = async (
  variables: ReadColorChangesQueryVariables
): Promise<ChangeLogResponse[]> => {
  const { data } = await apolloClient.query<ReadColorChangesQuery, ReadColorChangesQueryVariables>({
    query: ReadColorChangesDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readColorChanges.body;
};

export const revertChange = async (variables: RevertColorChangeMutationVariables) => {
  const { data } = await apolloClient.mutate<
    RevertColorChangeMutation,
    RevertColorChangeMutationVariables
  >({
    mutation: RevertColorChangeDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to revert change');
  }

  return data.revertColorChange.body;
};

// review
export const getReviewInteriorColorsToyota = async (
  variables: ReadIntColorReviewQueryVariables
): Promise<ColorsReviewResponse[]> => {
  const { data } = await apolloClient.query<
    ReadIntColorReviewQuery,
    ReadIntColorReviewQueryVariables
  >({
    query: ReadIntColorReviewDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readIntColorReview.body;
};

export const getReviewInteriorColorsLexus = async (
  variables: Omit<ReadIntColorReviewQueryVariables, 'brand'>
): Promise<VDInteriorColorLexus[]> => {
  const { data } = await apolloClient.query<
    ReadIntColorReviewQuery,
    ReadIntColorReviewQueryVariables
  >({
    query: ReadIntColorReviewDocument,
    variables: {
      ...variables,
      brand: Brand.Lexus,
    },
    fetchPolicy: 'network-only',
  });

  return data.readIntColorReview.body;
};

export const getReviewExteriorColorsToyota = async (
  variables: ReadExtColorReviewQueryVariables
): Promise<ColorsReviewResponse[]> => {
  const { data } = await apolloClient.query<
    ReadExtColorReviewQuery,
    ReadExtColorReviewQueryVariables
  >({
    query: ReadExtColorReviewDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readExtColorReview.body;
};

export const getReviewExteriorColorsLexus = async (
  variables: Omit<ReadExtColorReviewQueryVariables, 'brand'>
): Promise<VDExteriorColorLexus[]> => {
  const { data } = await apolloClient.query<
    ReadExtColorReviewQuery,
    ReadExtColorReviewQueryVariables
  >({
    query: ReadExtColorReviewDocument,
    variables: {
      ...variables,
      brand: Brand.Lexus,
    },
    fetchPolicy: 'network-only',
  });

  return data.readExtColorReview.body;
};

export const getReviewInteriorColorTypes = async (
  variables: ReadIntColorTypesReviewLexusQueryVariables
): Promise<InteriorColorTypeResponse> => {
  const { data } = await apolloClient.query<
    ReadIntColorTypesReviewLexusQuery,
    ReadIntColorTypesReviewLexusQueryVariables
  >({
    query: ReadIntColorTypesReviewLexusDocument,
    variables: {
      ...variables,
      brand: Brand.Lexus,
    },
    fetchPolicy: 'network-only',
  });

  return data.readIntColorTypesReviewLexus.body;
};

export const updateReviewInteriorColors = async (
  variables: UpdateIntColorReviewMutationVariables
): Promise<ColorsReviewResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateIntColorReviewMutation,
    UpdateIntColorReviewMutationVariables
  >({
    mutation: UpdateIntColorReviewDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update interior color review');
  }

  return data.updateIntColorReview.body;
};

export const updateReviewExteriorColors = async (
  variables: UpdateExtColorReviewMutationVariables
): Promise<VDExteriorColorLexus> => {
  const { data } = await apolloClient.mutate<
    UpdateExtColorReviewMutation,
    UpdateExtColorReviewMutationVariables
  >({
    mutation: UpdateExtColorReviewDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update exterior color review');
  }

  return data.updateExtColorReview.body;
};

// groups
export const addInteriorColorGroup = async (
  variables: CreateGroupTypesLexusMutationVariables
): Promise<GroupItem> => {
  const { data } = await apolloClient.mutate<
    CreateGroupTypesLexusMutation,
    CreateGroupTypesLexusMutationVariables
  >({
    mutation: CreateGroupTypesLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add interior color group');
  }

  return data.createGroupTypesLexus.body;
};

export const updateInteriorColorGroup = async (
  variables: UpdateGroupTypesLexusMutationVariables
): Promise<GroupItem> => {
  const { data } = await apolloClient.mutate<
    UpdateGroupTypesLexusMutation,
    UpdateGroupTypesLexusMutationVariables
  >({
    mutation: UpdateGroupTypesLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update interior color group');
  }

  return data.updateGroupTypesLexus.body;
};

export const deleteInteriorColorGroup = async (
  variables: DeleteGroupTypesLexusMutationVariables
): Promise<undefined> => {
  const { data } = await apolloClient.mutate<
    DeleteGroupTypesLexusMutation,
    DeleteGroupTypesLexusMutationVariables
  >({
    mutation: DeleteGroupTypesLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete interior color group');
  }

  return data.deleteGroupTypesLexus.body;
};

// color-families
export const getColorFamilies = async (
  variables: ReadColorFamiliesQueryVariables
): Promise<ColorFamilyResponse[]> => {
  const { data } = await apolloClient.query<
    ReadColorFamiliesQuery,
    ReadColorFamiliesQueryVariables
  >({
    query: ReadColorFamiliesDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readColorFamilies.body;
};

export const addColorFamily = async (
  variables: CreateColorFamilyMutationVariables
): Promise<ColorFamilyResponse> => {
  const { data } = await apolloClient.mutate<
    CreateColorFamilyMutation,
    CreateColorFamilyMutationVariables
  >({
    mutation: CreateColorFamilyDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add color family');
  }

  return data.createColorFamily.body;
};

export const updateColorFamily = async (
  variables: UpdateColorFamilyMutationVariables
): Promise<ColorFamilyResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateColorFamilyMutation,
    UpdateColorFamilyMutationVariables
  >({
    mutation: UpdateColorFamilyDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update color family');
  }

  return data.updateColorFamily.body;
};

export const deleteColorFamily = async (
  variables: DeleteColorFamilyMutationVariables
): Promise<undefined> => {
  const { data } = await apolloClient.mutate<
    DeleteColorFamilyMutation,
    DeleteColorFamilyMutationVariables
  >({
    mutation: DeleteColorFamilyDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete interior color group');
  }

  return data.deleteColorFamily.body;
};

export const publishColorFamilyDraft = async (
  variables: PublishColorFamilyDraftMutationVariables
): Promise<undefined> => {
  const { data } = await apolloClient.mutate<
    PublishColorFamilyDraftMutation,
    PublishColorFamilyDraftMutationVariables
  >({
    mutation: PublishColorFamilyDraftDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to publish color family draft');
  }

  return data.publishColorFamilyDraft.body;
};

// materials
export const getMaterials = async (
  variables: ReadMaterialsQueryVariables
): Promise<RefItemResponse[]> => {
  const { data } = await apolloClient.query<ReadMaterialsQuery, ReadMaterialsQueryVariables>({
    query: ReadMaterialsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readMaterials.body;
};

export const addMaterials = async (
  variables: CreateMaterialsMutationVariables
): Promise<RefItemResponse[]> => {
  const { data } = await apolloClient.mutate<
    CreateMaterialsMutation,
    CreateMaterialsMutationVariables
  >({
    mutation: CreateMaterialsDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add color family');
  }

  return data.createMaterials.body;
};

export const updateMaterials = async (
  variables: UpdateMaterialsMutationVariables
): Promise<RefItemResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateMaterialsMutation,
    UpdateMaterialsMutationVariables
  >({
    mutation: UpdateMaterialsDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update materials');
  }

  return data.updateMaterials.body;
};

// cf-int-colors
export const getCFInteriorColors = async (
  variables: ReadCfIntColorsQueryVariables
): Promise<CFInteriorColorResponse[]> => {
  const { data } = await apolloClient.query<ReadCfIntColorsQuery, ReadCfIntColorsQueryVariables>({
    query: ReadCfIntColorsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readCFIntColors.body;
};

export const addCFInteriorColor = async (
  variables: CreateCfIntColorMutationVariables
): Promise<CFInteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    CreateCfIntColorMutation,
    CreateCfIntColorMutationVariables
  >({
    mutation: CreateCfIntColorDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add color family interior color');
  }

  return data.createCFIntColor.body;
};

export const updateCFInteriorColor = async (
  variables: UpdateCfIntColorMutationVariables
): Promise<CFInteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateCfIntColorMutation,
    UpdateCfIntColorMutationVariables
  >({
    mutation: UpdateCfIntColorDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update color family interior color');
  }

  return data.updateCFIntColor.body;
};

export const deleteCFInteriorColor = async (variables: DeleteCfIntColorMutationVariables) => {
  const { data } = await apolloClient.mutate<
    DeleteCfIntColorMutation,
    DeleteCfIntColorMutationVariables
  >({
    mutation: DeleteCfIntColorDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete color family interior color');
  }

  return data.deleteCFIntColor.body;
};

// cf-ext-colors
export const getCFExteriorColors = async (
  variables: ReadCfExtColorsQueryVariables
): Promise<CFExteriorColorResponse[]> => {
  const { data } = await apolloClient.query<ReadCfExtColorsQuery, ReadCfExtColorsQueryVariables>({
    query: ReadCfExtColorsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readCFExtColors.body;
};

export const addCFExteriorColor = async (
  variables: CreateCfExtColorMutationVariables
): Promise<CFExteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    CreateCfExtColorMutation,
    CreateCfExtColorMutationVariables
  >({
    mutation: CreateCfExtColorDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add color family exterior color');
  }

  return data.createCFExtColor.body;
};

export const updateCFExteriorColor = async (
  variables: UpdateCfExtColorMutationVariables
): Promise<CFExteriorColorResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateCfExtColorMutation,
    UpdateCfExtColorMutationVariables
  >({
    mutation: UpdateCfExtColorDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update color family exterior color');
  }

  return data.updateCFExtColor.body;
};

export const deleteCFExteriorColor = async (variables: DeleteCfExtColorMutationVariables) => {
  const { data } = await apolloClient.mutate<
    DeleteCfExtColorMutation,
    DeleteCfExtColorMutationVariables
  >({
    mutation: DeleteCfExtColorDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete color family exterior color');
  }

  return data.deleteCFExtColor.body;
};
