import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import { CompareGradeSettingsCell } from '../../../../../../models/compareFeatures.model';
import settingsCellStyles from '../../../ModelTable/components/SettingsCell/settingsCell.module.scss';
import styles from './highlightSortOrderCell.module.scss';

type HighlightSortOrderCellProps = {
  grade: CompareGradeSettingsCell;
  oddRow: boolean;
  rowSpan: number;
  onUpdateSortOrder: (value: number, gradeId: string, featureId: string) => void;
  isActive: boolean;
  leftBorder: boolean;
  rightBorder: boolean;
  maxSortValue: number;
};

const HighlightSortOrderCell: React.FC<HighlightSortOrderCellProps> = ({
  grade,
  oddRow,
  rowSpan,
  onUpdateSortOrder,
  isActive,
  leftBorder,
  rightBorder,
  maxSortValue,
}) => {
  const { id: gradeId, highlightSortOrder, featureId: compareFeatureId } = grade;
  const [sortOrderInputValue, setSortOrderInputValue] = useState<number | string>(
    highlightSortOrder ?? 0
  );

  useEffect(() => {
    if (highlightSortOrder !== undefined) {
      setSortOrderInputValue(highlightSortOrder);
    }
  }, [highlightSortOrder]);

  return (
    <td
      key={gradeId}
      className={cx(settingsCellStyles.compareFeatureColumn, {
        [styles.rightBorder]: rightBorder,
        [styles.leftBorder]: leftBorder,
        [settingsCellStyles.oddRowColor]: oddRow,
      })}
      rowSpan={rowSpan}
    >
      {isActive ? (
        <input
          id="highlight sort input"
          type="text"
          onBlur={e => {
            let val =
              parseInt(e.currentTarget.value, 10) > 0 ? parseInt(e.currentTarget.value, 10) : 1;
            val = Math.max(1, Math.min(maxSortValue, val));
            setSortOrderInputValue(val);
            onUpdateSortOrder(val, gradeId, compareFeatureId);
          }}
          className={settingsCellStyles.setting}
          value={sortOrderInputValue}
          onChange={e => {
            setSortOrderInputValue(e.currentTarget.value);
          }}
          disabled={sortOrderInputValue === 0}
        />
      ) : (
        <div className={settingsCellStyles.setting} />
      )}
    </td>
  );
};

export default HighlightSortOrderCell;
