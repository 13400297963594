import React from 'react';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import useQuery from '../../../../hooks/useQuery';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import SpecsReviewCL from './SpecsReviewCL';
import SpecsReview from './SpecsReview';

const SpecsReviewController = (props: ProductDataControllerProps) => {
  const team = useQuery().get('team') as VehicleTeam;

  switch(team) {
    case VehicleTeam.PRODUCT_TEAM:
    case VehicleTeam.AGENCY_TEAM: {
      return <SpecsReview {...props} />;
    }
    case VehicleTeam.COMMON_LANGUAGE_TEAM: {
      return <SpecsReviewCL {...props} />;
    }
    default: return (null);
  }
};

export default SpecsReviewController;
