import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { removeNulls } from '../utils';

export interface SeriesCategory {
  id: string;
  revId: string;
  name: string;
  nameES: string;
  seriesId: string;
}

export class SeriesCategoryItem implements SeriesCategory {
  uid = '';
  id = '';
  revId = '';
  series: SeriesList[] = [];
  @observable name = '';
  @observable nameES = '';
  @observable seriesId = '';

  constructor(category?: SeriesCategory) {
    this.uid = uuidv4();
    if (category) {
      Object.assign(this, removeNulls(category));
    }
  }

  isValid = () => {
    return !!(this.name && this.nameES && this.seriesId);
  };
}

export interface SeriesList {
  seriesYear: string;
  seriesName: string;
  isPublishedToDownstream: boolean;
}
