import { action, observable } from 'mobx';
import { InteriorColorType, InteriorColorTypeResponse } from '../../models/colors.model';
import {
  ColorsLexusExteriorItem,
  ExteriorAccessibilityGroup,
  HeightSyncItem,
  InteriorCategory,
  InteriorGroup,
  InteriorGroupItem,
} from '../../models/colorsLexus.model';
import { IDValueType } from '../../models/common.model';
import { VehicleTeam } from '../../models/vehicleData.model';
import { convertKeyValuesToIDValues, isSortReverse, sortBy, toLowerCase } from '../../utils';
import {
  colorLexusExteriorXform,
  colorLexusInteriorXform,
  convertInteriorTypeGroups,
  interiorColorTypesLexusXForm,
} from '../../utils/colorLexusUtils';
import {
  getExteriorColorsLexus,
  getInteriorColorsLexus,
  getInteriorColorTypes,
} from '../../webservices/vehicleColorsApi';
import { toGqlBrand, toGqlFilter, toGqlTeam } from '../../utils/graphqlUtils';
import { Status } from 'vapi-ui-common';

class ColorsLexusStore {
  exteriorItems: ColorsLexusExteriorItem[] = [];
  reverseSort = false;
  sortField = 'id';
  searchText = '';
  isInProgressFilter = false;
  isExtraCostFilter = false;
  isReviewNotesFilter = false;
  @observable heightSync = [{}, {}, {}, {}, {}, {}, {}, {}] as HeightSyncItem[];
  @observable interiorGroups: InteriorGroup[] = [];
  @observable filteredExteriors: ColorsLexusExteriorItem[] = [];
  @observable viewModelCodes = false;
  @observable interiorColorTypes = {
    groups: [] as InteriorGroupItem[],
    materials: [] as IDValueType[],
    ornaments: [] as IDValueType[],
    interiorTypes: [] as IDValueType[],
  };

  fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    version?: string
  ) => {
    const [
      interiorColorTypesResponse,
      interiorColorsResponse,
      exteriorColorsResponse,
    ] = await Promise.all([
      getInteriorColorTypes({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId,
        modelYear: Number(year),
        filter: toGqlFilter(version || Status.DRAFT),
        includeAll: false,
      }),
      getInteriorColorsLexus({
        team: toGqlTeam(team),
        seriesId,
        modelYear: Number(year),
        filter: toGqlFilter(version || Status.DRAFT),
        includeAll: false,
      }),
      getExteriorColorsLexus({
        team: toGqlTeam(team),
        seriesId,
        modelYear: Number(year),
        filter: toGqlFilter(version || Status.DRAFT),
      }),
    ]);

    this.interiorColorTypes = interiorColorTypesLexusXForm(interiorColorTypesResponse);

    const interiorGroups = colorLexusInteriorXform(this.interiorColorTypes, interiorColorsResponse);
    const exteriorItems = colorLexusExteriorXform(exteriorColorsResponse, interiorGroups);
    this.setExteriorColors(exteriorItems);
    this.setInteriorGroups(interiorGroups);
  };

  setExteriorColors = (exteriorItems: ColorsLexusExteriorItem[]) => {
    this.exteriorItems = exteriorItems;
    this.filteredExteriors = exteriorItems;
  };

  setInteriorGroups = (groups: InteriorGroup[]) => {
    this.interiorGroups = groups;
  };

  addInteriorGroup = () => {
    const newItem = new InteriorGroup();
    this.interiorGroups = [newItem, ...this.interiorGroups];

    // add placeholder for exterior applicability
    this.exteriorItems.forEach(extItem => {
      const newColorApp = new ExteriorAccessibilityGroup();
      newColorApp.group = {} as InteriorGroupItem;
      newColorApp.interiors.push(new IDValueType('', false));
      extItem.colorApplicability = [newColorApp, ...extItem.colorApplicability];
    });
  };

  addItem = () => {
    const newItem = new ColorsLexusExteriorItem();

    this.interiorGroups.forEach(intGroupItem => {
      const newAccGroup = new ExteriorAccessibilityGroup();
      newAccGroup.group = intGroupItem.group;

      intGroupItem.materials.forEach(material => {
        material.categories.forEach(category => {
          newAccGroup.interiors.push(new IDValueType<boolean>(category.id, false));
        });
      });

      if (!intGroupItem.materials.length) {
        newAccGroup.interiors.push(new IDValueType('', false));
      }

      newItem.colorApplicability.push(newAccGroup);
    });

    this.exteriorItems = [newItem, ...this.exteriorItems];
    this.filteredExteriors = [newItem, ...this.filteredExteriors];
  };

  saveInteriorColorType = (
    interiorColorType: InteriorColorType,
    responseData: InteriorColorTypeResponse
  ) => {
    switch (interiorColorType) {
      case 'groups': {
        this.interiorColorTypes = {
          ...this.interiorColorTypes,
          groups: convertInteriorTypeGroups(responseData.groups),
        };
        break;
      }
      case 'interiorTypes': {
        this.interiorColorTypes = {
          ...this.interiorColorTypes,
          interiorTypes: convertKeyValuesToIDValues(responseData.interiorTypes),
        };
        break;
      }
      case 'materials': {
        this.interiorColorTypes = {
          ...this.interiorColorTypes,
          materials: convertKeyValuesToIDValues(responseData.materials),
        };
        break;
      }
      case 'ornaments': {
        this.interiorColorTypes = {
          ...this.interiorColorTypes,
          ornaments: convertKeyValuesToIDValues(responseData.ornaments),
        };
        break;
      }
    }
  };

  deleteItem = (interiorCategory: InteriorCategory) => {
    this.interiorGroups.forEach(intGroup => {
      // filter out category
      intGroup.materials.forEach(mat => {
        mat.categories = mat.categories.filter(cat => cat.id !== interiorCategory.id);
      });

      // remove materials with empty categories
      intGroup.materials = intGroup.materials.filter(mat => mat.categories.length > 0);
    });

    // remove chboxes from exteriors
    this.exteriorItems.forEach(extItem => {
      extItem.colorApplicability.forEach(colorApp => {
        colorApp.interiors = colorApp.interiors.filter(int => int.id !== interiorCategory.id);

        // if all cells are gone, add placeholder
        if (!colorApp.interiors.length) {
          colorApp.interiors.push(new IDValueType('', false));
        }
      });
    });
  };

  deleteExteriorItem = (exteriorItem: ColorsLexusExteriorItem) => {
    this.exteriorItems = this.exteriorItems.filter(item => item.uid !== exteriorItem.uid);
    this.filteredExteriors = this.filteredExteriors.filter(item => item.uid !== exteriorItem.uid);
  };

  deleteInteriorGroup = (interiorGroup: InteriorGroupItem) => {
    this.interiorGroups = this.interiorGroups.filter(item => item.group.uid !== interiorGroup.uid);
    this.exteriorItems.forEach(item => {
      item.colorApplicability = item.colorApplicability.filter(
        applicability => applicability.group.uid !== interiorGroup.uid
      );
    });
  };

  onSort = (field: string) => {
    this.reverseSort = isSortReverse(this.sortField, field, this.reverseSort);
    this.sortField = field;
    this.exteriorItems = this.exteriorItems.sort(sortBy(this.sortField, this.reverseSort));
    this.filteredExteriors = this.filterColors();
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredExteriors = this.filterColors();
  };

  @action resetFilters = () => {
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isExtraCostFilter = false;
    this.filteredExteriors = this.exteriorItems.slice();
  };

  filterColors = () => {
    const sorted = this.exteriorItems.slice();
    const lowerSearchText = toLowerCase(this.searchText);
    return sorted.filter(
      item =>
        (!lowerSearchText ||
          toLowerCase(item.code).includes(lowerSearchText) ||
          toLowerCase(item.hexCode).includes(lowerSearchText) ||
          toLowerCase(item.name).includes(lowerSearchText) ||
          toLowerCase(item.notes).includes(lowerSearchText)) &&
        (!this.isInProgressFilter || item.isInProgress) &&
        (!this.isExtraCostFilter || item.isExtraCost) &&
        (!this.isReviewNotesFilter || item.rejectNotes.length > 0)
    );
  };

  @action reset() {
    this.interiorGroups = [];
    this.reverseSort = false;
    this.sortField = 'id';
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isReviewNotesFilter = false;
    this.interiorColorTypes = {
      groups: [] as InteriorGroupItem[],
      materials: [] as IDValueType[],
      ornaments: [] as IDValueType[],
      interiorTypes: [] as IDValueType[],
    };
    this.viewModelCodes = false;
  }
}

export default ColorsLexusStore;
