import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { Modal } from 'vapi-ui-common';
import IconTextButton from '../../../../../../components/IconTextButton';
import {
  LD_STATUS,
  LimitedDataStatusHeader,
  OptionsMap,
} from '../../../../../../components/LimitedDataStatus';
import { Table } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';
import { KeyValueType } from '../../../../../../models/common.model';
import { BRAND_TDPR, BRAND_TOYOTA, Brand } from '../../../../../../models/user.model';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../models/vehicleModel.model';
import { GO_LIVE_DATE_TIME } from '../../../../../../utils/dateUtils';
import { getSortModels } from '../../../../../../utils/vehicleDataUtils';
import { ModelsModalVMProps } from '../../ModelsProps';
import styles from '../../modelsModal.module.scss';
import EditGoLiveDateModal from '../EditGoLiveDateModal';
import ConfirmAllGoLiveDateModal from './ConfirmAllGoLiveDateModal';
import ModelsModalEditPanel from './ModelsModalEditPanel';
import ModelsModalRow from './ModelsModalRow';

const ModelsModalTable = ({
  brand,
  seriesGroup,
  seriesName,
  vehicleModels,
  grades,
  fuelTypes,
  onAddModel,
  onUpdateModel,
  onUpdateAllGoLiveDates,
  onDeleteModel,
  onSortModels = () => void 0,
  onAddGrade,
  onUpdateGrade,
  onShow,
  canAddDelete,
  canCopy,
  canDeleteCopiedModels,
  canEditGoLiveDate,
  readOnly,
  seriesSettings,
  canAddFromDropdown,
  compareModel,
  editableLanguages,
  updateFieldStatus,
  updateAllFieldStatuses,
}: ModelsModalVMProps) => {
  const { teamStore } = useStores();

  const [showAddModel, setShowAddModel] = useState(false);
  const [showCalander, setShowCalander] = useState(false);
  const [openGoLiveDateForAll, setOpenGoLiveDateForAll] = useState(false);
  const [goLiveDate, setGoLiveDate] = useState('');
  const timePicker = process.env.REACT_APP_GO_LIVE_TIME === 'true' && teamStore.team.showTimePicker;

  const currentGoLiveDate = useMemo(() => {
    const glDate = new Date();

    if (timePicker) {
      glDate.setHours(GO_LIVE_DATE_TIME.AM_5);
    }

    return glDate;
  }, [timePicker]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = vehicleModels.splice(result.source.index, 1);
    vehicleModels.splice(result.destination.index, 0, removed);
    vehicleModels.forEach((item, index) => (item.sortOrder = index));
    onSortModels(getSortModels(vehicleModels));

    return vehicleModels;
  };

  const newModelMap = () => {
    const modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>> = {};
    editableLanguages.forEach(lang => {
      modelLangMap[lang] = new VehicleModelItem<VehicleModelLexus | VehicleModelToyota>(
        brand as Brand
      );
    });

    return modelLangMap;
  };

  const displayLimitedDataStatus =
    teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';

  const optionsMap = useMemo(
    (): OptionsMap => ({
      [LD_STATUS.READY_TO_PUBLISH]: {
        dropdownText: 'Set All to Ready to Publish',
        confirmModalHeaderText: 'Set all statuses to "Ready to Publish".',
        confirmModalBodyText: 'Are sure that you want to set all statuses to "Ready to Publish".',
        confirmModalButtonText: 'Confirm',
      },

      [LD_STATUS.IN_PROGRESS]: {
        dropdownText: 'Set All to in Progress',
        confirmModalHeaderText: 'Set all statuses to "In Progress".',
        confirmModalBodyText: 'Are sure that you want to set all statuses to "In Progress".',
        confirmModalButtonText: 'Confirm',
      },
    }),
    []
  );

  return (
    <section>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table fullWidth className={styles.table}>
          <tbody className={styles.tbodyActionBar}>
            <tr>
              <td className={styles.codeRedTitle}>Code Red</td>
              <td colSpan={1}></td>
              <td className={styles.modelCodeTitle}>Models Code</td>
              <td>Trim Name</td>
              <td>
                <LimitedDataStatusHeader
                  className={styles.publishStatusHeader}
                  readonly={readOnly}
                  updateAllFieldStatuses={updateAllFieldStatuses}
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                  optionsMap={optionsMap}
                />
              </td>
              {(brand === BRAND_TOYOTA || brand === BRAND_TDPR) && (
                <td>
                  Go Live Date
                  {!readOnly &&
                    brand !== BRAND_TDPR &&
                    teamStore.team.param !== VehicleTeam.AGENCY_SPANISH && (
                      <IconTextButton
                        icon="chevronDown"
                        text=""
                        smallIcon
                        onClick={() => setShowCalander(true)}
                      />
                    )}
                </td>
              )}
              <td>Katashiki</td>
              <td className={styles.addModelColumn}>
                {!readOnly && canAddDelete && (
                  <IconTextButton
                    icon="plus"
                    text="Add Model"
                    smallIcon
                    onClick={() => setShowAddModel(true)}
                  />
                )}
              </td>
            </tr>
          </tbody>

          <Droppable droppableId="modelsmodaldroppable">
            {provided => (
              <tbody
                ref={provided.innerRef}
                className={styles.tbodyMain}
                {...provided.droppableProps}
              >
                {showAddModel && (
                  <ModelsModalEditPanel
                    brand={brand}
                    seriesName={seriesName}
                    modelMap={newModelMap()}
                    grades={grades}
                    fuelTypes={fuelTypes}
                    category={seriesGroup}
                    onClose={() => setShowAddModel(false)}
                    onSave={onAddModel}
                    onAddGrade={!canAddFromDropdown ? undefined : onAddGrade}
                    onUpdateGrade={onUpdateGrade}
                    seriesSettings={seriesSettings}
                    compareModel={compareModel}
                  />
                )}
                {/*  open set all go live date calander */}
                {showCalander && (
                  <Modal open={showCalander} onClose={() => setShowCalander(false)}>
                    <EditGoLiveDateModal
                      headerText="Set Go Live Date for All Models"
                      customButtonText="Next"
                      close={() => setShowCalander(false)}
                      onSubmit={(goLiveDate: string) => {
                        setGoLiveDate(goLiveDate);
                        setOpenGoLiveDateForAll(true);
                      }}
                      currentGoLiveDate={currentGoLiveDate}
                      timePicker={timePicker}
                    />
                  </Modal>
                )}
                {/* open confirmation modal */}
                {openGoLiveDateForAll && (
                  <Modal
                    open={openGoLiveDateForAll}
                    onClose={() => setOpenGoLiveDateForAll(false)}
                    size="auto"
                  >
                    <ConfirmAllGoLiveDateModal
                      close={() => setOpenGoLiveDateForAll(false)}
                      goLiveDate={goLiveDate}
                      onUpdateAllGoLiveDates={onUpdateAllGoLiveDates}
                      timePicker={timePicker}
                    />
                  </Modal>
                )}
                {vehicleModels.map((model, index) => (
                  <ModelsModalRow
                    key={model.uid}
                    brand={brand}
                    index={index}
                    readOnly={readOnly}
                    category={seriesGroup}
                    model={model}
                    grades={grades}
                    fuelTypes={fuelTypes}
                    onShow={onShow}
                    onSave={onUpdateModel}
                    onCopy={onAddModel}
                    onDelete={onDeleteModel}
                    onAddGrade={!canAddFromDropdown ? undefined : onAddGrade}
                    onUpdateGrade={onUpdateGrade}
                    canAddDelete={canAddDelete}
                    canCopy={canCopy}
                    seriesName={seriesName}
                    seriesSettings={seriesSettings}
                    canEditGoLiveDate={canEditGoLiveDate}
                    compareModel={compareModel}
                    editableLanguages={editableLanguages}
                    canDeleteCopiedModels={canDeleteCopiedModels}
                    updateFieldStatus={updateFieldStatus}
                  />
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </section>
  );
};

export default observer(ModelsModalTable);
