import React from 'react';
import {
  ActionBarDivider,
  ActionBarFilterButton,
  ActionBarSection,
  Dropdown,
  DropdownContent,
  SearchInput,
} from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox';
import IconTextButton from '../../../../components/IconTextButton';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import { ActionBarPropsModel } from '../ActionBarVehicleData/actionBarVehicleData.model';
import styles from '../ActionBarVehicleData/actionBarVehicleData.module.scss';

const ActionBarFiltersSection = ({
  searchText,
  onSearchTextChange,
  renderFilter,
  toggleViewModelCodes,
  viewModelCodes,
  readOnly,
  buttons,
  renderButtons,
  hideFilter,
}: ActionBarPropsModel) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <ActionBarSection>
      {onSearchTextChange && (
        <SearchInput
          value={searchText}
          onSearch={value => {
            onSearchTextChange(value);
          }}
        />
      )}
      {!hideFilter && (
        <Dropdown>
          <ActionBarFilterButton onClick={() => setIsComponentVisible(true)}>
            Filter
          </ActionBarFilterButton>
          <DropdownContent ref={ref} open={isComponentVisible}>
            {renderFilter(() => setIsComponentVisible(false))}
          </DropdownContent>
        </Dropdown>
      )}

      {toggleViewModelCodes && (
        <Checkbox
          id="viewModelCodesCheckbox"
          className={styles.checkbox}
          checked={viewModelCodes}
          onChange={() => toggleViewModelCodes()}
        >
          <span className={styles.checkboxLabel}>View Model Codes</span>
        </Checkbox>
      )}
      {renderButtons}
      {!readOnly && buttons
        ? buttons.map(btn => (
            <React.Fragment key={btn.text}>
              <ActionBarDivider />
              <IconTextButton
                icon="plus"
                text={btn.text}
                className={styles.iconButton}
                iconClassName={styles.addFeatureIcon}
                onClick={() => {
                  btn.callback();
                }}
              />
            </React.Fragment>
          ))
        : ''}
    </ActionBarSection>
  );
};

export default ActionBarFiltersSection;
