import { observer } from 'mobx-react-lite';
import React from 'react';
import { Table } from '../../../../components/Table/Table';
import { ColorFamilyItem } from '../../../../models/colorFamilies.model';
import ColorFamilyHeaderRow from '../colorFamiliesHeaderRow';
import ColorFamiliesRow from '../colorFamiliesRow';

interface ColorFamiliesTableProps {
  colorFamilies: ColorFamilyItem[];
  deleteColorItem: (color: ColorFamilyItem) => void;
  saveColorFamilyItem: (color: ColorFamilyItem) => void;
  readOnly: boolean;
  onSort?: (field: string, isReverse: boolean) => void;
}

const ColorFamiliesTable = ({
  readOnly,
  colorFamilies,
  deleteColorItem,
  saveColorFamilyItem,
  onSort = () => {},
}: ColorFamiliesTableProps) => {
  return (
    <>
      <ColorFamilyHeaderRow onSort={onSort} />
      <Table fullWidth>
        {colorFamilies.map(colorFamily => (
          <ColorFamiliesRow
            readOnly={readOnly}
            colorFamily={colorFamily}
            deleteColorItem={deleteColorItem}
            saveColorFamilyItem={saveColorFamilyItem}
            key={colorFamily.uid}
          />
        ))}
      </Table>
    </>
  );
};

export default observer(ColorFamiliesTable);
