import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Checkbox, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';
import styles from './seriesManagerDphModal.module.scss';
import { DphMap } from '../../../../models/dph.model';

interface SeriesManagerDphModalProps {
  close?: () => void;
  dphCategories: DphMap;
  handleOnSaveDph: (id: string) => Promise<void>;
}

const SeriesManagerDphModal = observer(
  ({ close = () => {}, dphCategories, handleOnSaveDph }: SeriesManagerDphModalProps) => {
    const [check, setCheck] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('');

    return (
      <>
        <ModalHeader onClose={close}>Assign DPH</ModalHeader>
        <ModalBody>
          <section>
            <div className={styles.header}>
              Category <span className={styles.dphHeader}>DPH</span>
            </div>
            <div>
              {Object.entries(dphCategories).map(([key, item]) => {
                return (
                  <div className={styles.body}>
                    <Checkbox
                      id={`dphChbox${item.categoryId}`}
                      className={styles.checkbox}
                      checked={item.categoryId === check}
                      onChange={e => {
                        setCheck(item.categoryId);
                        setSelectedCategoryId(item.categoryId);
                      }}
                    >
                      {item.name}
                    </Checkbox>
                    <span className={styles.dph} key={item.categoryId}>
                      ${item.dph}
                    </span>
                  </div>
                );
              })}
            </div>
          </section>
        </ModalBody>
        <ModalFooter>
          <Button variant="transparent" onClick={() => close()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleOnSaveDph(selectedCategoryId);
            }}
          >
            Assign DPH
          </Button>
        </ModalFooter>
      </>
    );
  }
);

export default SeriesManagerDphModal;
