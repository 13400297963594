import apolloClient from '../apolloClient';
import {
  ReadVehicleOptionsQueryVariables,
  ReadVehicleOptionsQuery,
  ReadVehicleOptionsDocument,
  CreateVehicleOptionDocument,
  CreateVehicleOptionMutation,
  CreateVehicleOptionMutationVariables,
  CreateVehicleOptionLexusDocument,
  CreateVehicleOptionLexusMutation,
  CreateVehicleOptionLexusMutationVariables,
  CreateVehiclePackageLexusDocument,
  CreateVehiclePackageLexusMutation,
  CreateVehiclePackageLexusMutationVariables,
  UpdateVehicleOptionDocument,
  UpdateVehicleOptionLexusDocument,
  UpdateVehicleOptionLexusMutation,
  UpdateVehicleOptionLexusMutationVariables,
  UpdateVehicleOptionMutation,
  UpdateVehicleOptionMutationVariables,
  UpdateVehiclePackageLexusDocument,
  UpdateVehiclePackageLexusMutation,
  UpdateVehiclePackageLexusMutationVariables,
  DeleteVehicleOptionDocument,
  DeleteVehicleOptionMutation,
  DeleteVehicleOptionMutationVariables,
  CreateVehicleOptionSpecLexusDocument,
  CreateVehicleOptionSpecLexusMutation,
  CreateVehicleOptionSpecLexusMutationVariables,
  ReadVehicleOptionSpecLexusDocument,
  ReadVehicleOptionSpecLexusQuery,
  ReadVehicleOptionSpecLexusQueryVariables,
  UpdateVehicleOptionSpecLexusDocument,
  UpdateVehicleOptionSpecLexusMutation,
  UpdateVehicleOptionSpecLexusMutationVariables,
  DeleteVehicleOptionSpecLexusDocument,
  DeleteVehicleOptionSpecLexusMutation,
  DeleteVehicleOptionSpecLexusMutationVariables,
  CreateVehiclePackageVarietyLexusDocument,
  CreateVehiclePackageVarietyLexusMutation,
  CreateVehiclePackageVarietyLexusMutationVariables,
  DeleteVehiclePackageVarietyLexusDocument,
  DeleteVehiclePackageVarietyLexusMutation,
  DeleteVehiclePackageVarietyLexusMutationVariables,
  UpdateVehiclePackageVarietyLexusDocument,
  UpdateVehiclePackageVarietyLexusMutation,
  UpdateVehiclePackageVarietyLexusMutationVariables,
  ReadVehiclePackagesVarietyLexusDocument,
  ReadVehiclePackagesVarietyLexusQuery,
  ReadVehiclePackagesVarietyLexusQueryVariables,
  ReadVehicleOptionChangesDocument,
  ReadVehicleOptionChangesQuery,
  ReadVehicleOptionChangesQueryVariables,
  RevertVehicleOptionChangeDocument,
  RevertVehicleOptionChangeMutation,
  RevertVehicleOptionChangeMutationVariables,
  ReadVehicleOptionsCategoriesDocument,
  ReadVehicleOptionsCategoriesQuery,
  ReadVehicleOptionsCategoriesQueryVariables,
  UpdateVehicleOptionsCategoriesDocument,
  UpdateVehicleOptionsCategoriesMutation,
  UpdateVehicleOptionsCategoriesMutationVariables,
  CreateVehicleOptionsCategoriesDocument,
  CreateVehicleOptionsCategoriesMutation,
  CreateVehicleOptionsCategoriesMutationVariables,
  ReadVehicleOptionCategoriesReviewDocument,
  ReadVehicleOptionCategoriesReviewQuery,
  ReadVehicleOptionCategoriesReviewQueryVariables,
  ReadVehicleOptionReviewDocument,
  ReadVehicleOptionReviewQuery,
  ReadVehicleOptionReviewQueryVariables,
  UpdateVehicleOptionReviewDocument,
  UpdateVehicleOptionReviewMutation,
  UpdateVehicleOptionReviewMutationVariables,
  UpdateVehicleOptionSpecReviewLexusDocument,
  UpdateVehicleOptionSpecReviewLexusMutation,
  UpdateVehicleOptionSpecReviewLexusMutationVariables,
  UpdateVehiclePackageVarietyReviewLexusDocument,
  UpdateVehiclePackageVarietyReviewLexusMutation,
  UpdateVehiclePackageVarietyReviewLexusMutationVariables,
  UpdateVehicleOptionStatusAllDocument,
  UpdateVehicleOptionStatusAllMutation,
  UpdateVehicleOptionStatusAllMutationVariables,
  UpdateVehicleOptionStatusDocument,
  UpdateVehicleOptionStatusMutation,
  UpdateVehicleOptionStatusMutationVariables,
} from '../gql/generated';
import { CategoryLanguageMap, CategoryResponse } from '../models/category.model';
import { ChangeLogResponse } from '../models/changeLog.model';
import { OptionResponse } from '../models/options.model';
import {
  VDOptionLexusBase,
  VDPackageSpecLexus,
  VDPackageVarietyRequest,
} from '../models/optionsLexus.model';
import { ReviewChangeRequest } from '../models/review.model';
import { ModelFieldStatus, UpdateAllModelStatusesResponse } from '../models/vehicleModel.model';

type OptionsResponseUnion = OptionResponse | VDOptionLexusBase;

// Options
export const getOptions = async <T extends OptionsResponseUnion>(
  variables: ReadVehicleOptionsQueryVariables
): Promise<T[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleOptionsQuery,
    ReadVehicleOptionsQueryVariables
  >({
    query: ReadVehicleOptionsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleOptions.body;
};

export const addOption = async (
  variables: CreateVehicleOptionMutationVariables
): Promise<OptionResponse> => {
  const { data } = await apolloClient.mutate<
    CreateVehicleOptionMutation,
    CreateVehicleOptionMutationVariables
  >({
    mutation: CreateVehicleOptionDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.createVehicleOption.body;
};

export const addOptionLexus = async (
  variables: CreateVehicleOptionLexusMutationVariables
): Promise<VDOptionLexusBase> => {
  const { data } = await apolloClient.mutate<
    CreateVehicleOptionLexusMutation,
    CreateVehicleOptionLexusMutationVariables
  >({
    mutation: CreateVehicleOptionLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.createVehicleOptionLexus.body;
};

export const addPackageLexus = async (
  variables: CreateVehiclePackageLexusMutationVariables
): Promise<VDOptionLexusBase> => {
  const { data } = await apolloClient.mutate<
    CreateVehiclePackageLexusMutation,
    CreateVehiclePackageLexusMutationVariables
  >({
    mutation: CreateVehiclePackageLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.createVehiclePackageLexus.body;
};

export const updateOption = async (
  variables: UpdateVehicleOptionMutationVariables
): Promise<OptionResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleOptionMutation,
    UpdateVehicleOptionMutationVariables
  >({
    mutation: UpdateVehicleOptionDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update exterior color');
  }

  return data.updateVehicleOption.body;
};

export const updateOptionLexus = async (
  variables: UpdateVehicleOptionLexusMutationVariables
): Promise<VDOptionLexusBase> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleOptionLexusMutation,
    UpdateVehicleOptionLexusMutationVariables
  >({
    mutation: UpdateVehicleOptionLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update exterior color');
  }

  return data.updateVehicleOptionLexus.body;
};

export const updatePackageLexus = async (
  variables: UpdateVehiclePackageLexusMutationVariables
): Promise<VDOptionLexusBase> => {
  const { data } = await apolloClient.mutate<
    UpdateVehiclePackageLexusMutation,
    UpdateVehiclePackageLexusMutationVariables
  >({
    mutation: UpdateVehiclePackageLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update exterior color');
  }

  return data.updateVehiclePackageLexus.body;
};

export const deleteOption = async (
  variables: DeleteVehicleOptionMutationVariables
): Promise<any> => {
  const { data } = await apolloClient.mutate<
    DeleteVehicleOptionMutation,
    DeleteVehicleOptionMutationVariables
  >({
    mutation: DeleteVehicleOptionDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update exterior color');
  }

  return data.deleteVehicleOption.body;
};

// Specs
export const getSpecs = async (
  variables: ReadVehicleOptionSpecLexusQueryVariables
): Promise<VDPackageSpecLexus[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleOptionSpecLexusQuery,
    ReadVehicleOptionSpecLexusQueryVariables
  >({
    query: ReadVehicleOptionSpecLexusDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleOptionSpecLexus.body;
};

export const addSpec = async (
  variables: CreateVehicleOptionSpecLexusMutationVariables
): Promise<VDPackageSpecLexus> => {
  const { data } = await apolloClient.mutate<
    CreateVehicleOptionSpecLexusMutation,
    CreateVehicleOptionSpecLexusMutationVariables
  >({
    mutation: CreateVehicleOptionSpecLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.createVehicleOptionSpecLexus.body;
};

export const updateSpec = async (
  variables: UpdateVehicleOptionSpecLexusMutationVariables
): Promise<VDPackageSpecLexus> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleOptionSpecLexusMutation,
    UpdateVehicleOptionSpecLexusMutationVariables
  >({
    mutation: UpdateVehicleOptionSpecLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.updateVehicleOptionSpecLexus.body;
};

export const deleteSpec = async (
  variables: DeleteVehicleOptionSpecLexusMutationVariables
): Promise<any> => {
  const { data } = await apolloClient.mutate<
    DeleteVehicleOptionSpecLexusMutation,
    DeleteVehicleOptionSpecLexusMutationVariables
  >({
    mutation: DeleteVehicleOptionSpecLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.deleteVehicleOptionSpecLexus.body;
};

// Varieties
export const getVarieties = async (
  variables: ReadVehiclePackagesVarietyLexusQueryVariables
): Promise<VDPackageVarietyRequest[]> => {
  const { data } = await apolloClient.query<
    ReadVehiclePackagesVarietyLexusQuery,
    ReadVehiclePackagesVarietyLexusQueryVariables
  >({
    query: ReadVehiclePackagesVarietyLexusDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehiclePackagesVarietyLexus.body;
};

export const addVariety = async (
  variables: CreateVehiclePackageVarietyLexusMutationVariables
): Promise<VDPackageVarietyRequest> => {
  const { data } = await apolloClient.mutate<
    CreateVehiclePackageVarietyLexusMutation,
    CreateVehiclePackageVarietyLexusMutationVariables
  >({
    mutation: CreateVehiclePackageVarietyLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.createVehiclePackageVarietyLexus.body;
};

export const updateVariety = async (
  variables: UpdateVehiclePackageVarietyLexusMutationVariables
): Promise<VDPackageVarietyRequest> => {
  const { data } = await apolloClient.mutate<
    UpdateVehiclePackageVarietyLexusMutation,
    UpdateVehiclePackageVarietyLexusMutationVariables
  >({
    mutation: UpdateVehiclePackageVarietyLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.updateVehiclePackageVarietyLexus.body;
};

export const deleteVariety = async (
  variables: DeleteVehiclePackageVarietyLexusMutationVariables
): Promise<any> => {
  const { data } = await apolloClient.mutate<
    DeleteVehiclePackageVarietyLexusMutation,
    DeleteVehiclePackageVarietyLexusMutationVariables
  >({
    mutation: DeleteVehiclePackageVarietyLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.deleteVehiclePackageVarietyLexus.body;
};

// ChangeLog
export const getChangeLog = async (
  variables: ReadVehicleOptionChangesQueryVariables
): Promise<ChangeLogResponse[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleOptionChangesQuery,
    ReadVehicleOptionChangesQueryVariables
  >({
    query: ReadVehicleOptionChangesDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleOptionChanges.body;
};

export const revertChange = async (
  variables: RevertVehicleOptionChangeMutationVariables
): Promise<any> => {
  const { data } = await apolloClient.mutate<
    RevertVehicleOptionChangeMutation,
    RevertVehicleOptionChangeMutationVariables
  >({
    mutation: RevertVehicleOptionChangeDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.revertVehicleOptionChange.body;
};

// Categories
export const getCategories = async (
  variables: ReadVehicleOptionsCategoriesQueryVariables
): Promise<CategoryResponse[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleOptionsCategoriesQuery,
    ReadVehicleOptionsCategoriesQueryVariables
  >({
    query: ReadVehicleOptionsCategoriesDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleOptionsCategories.body;
};

export const addCategories = async (
  variables: CreateVehicleOptionsCategoriesMutationVariables
): Promise<CategoryLanguageMap> => {
  const { data } = await apolloClient.mutate<
    CreateVehicleOptionsCategoriesMutation,
    CreateVehicleOptionsCategoriesMutationVariables
  >({
    mutation: CreateVehicleOptionsCategoriesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.createVehicleOptionsCategories.body;
};

export const updateCategory = async (
  variables: UpdateVehicleOptionsCategoriesMutationVariables
): Promise<CategoryLanguageMap> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleOptionsCategoriesMutation,
    UpdateVehicleOptionsCategoriesMutationVariables
  >({
    mutation: UpdateVehicleOptionsCategoriesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.updateVehicleOptionsCategories.body;
};

// Review
export const getReviewCategories = async (
  variables: ReadVehicleOptionCategoriesReviewQueryVariables
): Promise<CategoryResponse[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleOptionCategoriesReviewQuery,
    ReadVehicleOptionCategoriesReviewQueryVariables
  >({
    query: ReadVehicleOptionCategoriesReviewDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleOptionCategoriesReview.body;
};

export const getReviewOptions = async <OptionsReviewResponseUnion>(
  variables: ReadVehicleOptionReviewQueryVariables
): Promise<OptionsReviewResponseUnion[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleOptionReviewQuery,
    ReadVehicleOptionReviewQueryVariables
  >({
    query: ReadVehicleOptionReviewDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleOptionReview.body;
};

export const updateReviewOption = async (
  variables: UpdateVehicleOptionReviewMutationVariables
): Promise<ReviewChangeRequest> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleOptionReviewMutation,
    UpdateVehicleOptionReviewMutationVariables
  >({
    mutation: UpdateVehicleOptionReviewDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.updateVehicleOptionReview.body;
};

export const updateReviewOptionSpec = async (
  variables: UpdateVehicleOptionSpecReviewLexusMutationVariables
): Promise<ReviewChangeRequest> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleOptionSpecReviewLexusMutation,
    UpdateVehicleOptionSpecReviewLexusMutationVariables
  >({
    mutation: UpdateVehicleOptionSpecReviewLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.updateVehicleOptionSpecReviewLexus.body;
};

export const updateReviewOptionVariety = async (
  variables: UpdateVehiclePackageVarietyReviewLexusMutationVariables
): Promise<ReviewChangeRequest> => {
  const { data } = await apolloClient.mutate<
    UpdateVehiclePackageVarietyReviewLexusMutation,
    UpdateVehiclePackageVarietyReviewLexusMutationVariables
  >({
    mutation: UpdateVehiclePackageVarietyReviewLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.updateVehiclePackageVarietyReviewLexus.body;
};

export const updateOptionFeatureStatus = async (
  variables: UpdateVehicleOptionStatusMutationVariables
): Promise<ModelFieldStatus> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleOptionStatusMutation,
    UpdateVehicleOptionStatusMutationVariables
  >({
    mutation: UpdateVehicleOptionStatusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.updateVehicleOptionStatus.body;
};

export const updateAllOptionStatuses = async (
  variables: UpdateVehicleOptionStatusAllMutationVariables
): Promise<UpdateAllModelStatusesResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleOptionStatusAllMutation,
    UpdateVehicleOptionStatusAllMutationVariables
  >({
    mutation: UpdateVehicleOptionStatusAllDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add exterior color');
  }

  return data.updateVehicleOptionStatusAll.body;
};
