import { removeNulls } from '../utils';

export class DphItem {
  categoryId = '';
  revId = '';
  name = '';
  seriesMap = {};
  series: string[] = [];
  dph = 0;
  liveDphDate = '';
  nextDph = '';
  nextDphLiveDate = '';

  constructor(dph?: DphResponse) {
    if (dph) {
      Object.assign(this, removeNulls(dph));
      this.series = Object.keys(this.seriesMap);
    }
  }
}

export interface DphMap {
  [id: string]: DphResponse;
}

export interface DphResponse {
  categoryId: string;
  revId: string;
  name: string;
  dph: number;
  seriesMap?: Record<string, true>;
  liveDphDate?: string;
  nextDph?: string;
  nextDphLiveDate?: string;
}

export interface DphRequest extends DphResponse {
  series: string[];
}
