import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ChecklistItem } from '../../../../hooks/useChecklist';
import GradeListItem from './GradeListItem';

interface IGradeListProps {
  checklist: ChecklistItem[];
  handleModelApplicability: () => void;
  selectItem: (item: ChecklistItem, selected: boolean) => void;
}

const GradeList = ({ checklist, handleModelApplicability, selectItem }: IGradeListProps) => {
  const [selectedGrade, setSelectedGrade] = useState('');

  return (
    <>
      {checklist?.map(item => (
        <GradeListItem
          key={item.name}
          grade={item}
          handleModelApplicability={handleModelApplicability}
          selectItem={selectItem}
          selectedGrade={selectedGrade}
          setSelectedGrade={setSelectedGrade}
        />
      ))}
    </>
  );
};

export default observer(GradeList);
