import React from 'react';
import { HashRouter, Redirect, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.module.scss';
import SideMenu from './components/SideMenu';
import PostSpinner from './components/Spinner/PostSpinner';
import { Toast } from './components/Toast';
import UiBlocker from './components/UiBlocker';
import useStores from './hooks/useStores';
import {
  AppRoute,
  ColorFamiliesRoutes,
  CommonLanguageRoutes,
  DisclaimerRoutes,
  DPHRoutes,
  RestOfRoutes,
  SeriesCategoriesRoutes,
  SpanishDisclaimersRoutes,
  VehicleDataRoutes,
} from './models/routes.model';

type RouteBuilderProps = {
  routes: AppRoute[];
};
const RouteBuilder = ({ routes }: RouteBuilderProps) => {
  return (
    <>
      {routes.map((route: AppRoute, index: number) => (
        <React.Fragment key={index}>
          <Route path={route.path} exact component={route.component} />
          {route.childRoutes &&
            route.childRoutes.map((childRoute: AppRoute, index: number) => (
              <Route key={index} exact path={childRoute.path} component={childRoute.component} />
            ))}
        </React.Fragment>
      ))}
    </>
  );
};

const App = () => {
  const {
    userStore: { modules },
  } = useStores();

  const getDefaultRedirect = () => {
    if (modules.CommonLanguage.canView) {
      return <Redirect to="/commonLanguage/published" />;
    }

    if (modules.ProductTeam.canView) {
      return <Redirect to="/vehicleData/product-team" />;
    }

    if (modules.AgencyTeam.canView) {
      return <Redirect to="/vehicleData/agency-team" />;
    }

    if (modules.Spanish.canView) {
      return <Redirect to="/vehicleData/agency-spanish" />;
    }

    if (modules.Disclaimers.canView) {
      return <Redirect to="/disclaimers/published" />;
    }

    return <Redirect to="/reports" />;
  };

  return (
    <HashRouter>
      <Toast />
      <UiBlocker>
        <SideMenu>
          <PostSpinner>
            <Route path="/" exact render={getDefaultRedirect} />
            <Route
              path="/commonLanguage"
              exact
              render={() => <Redirect to="/commonLanguage/published" />}
            />
            <RouteBuilder routes={CommonLanguageRoutes} />
            <RouteBuilder routes={VehicleDataRoutes} />
            <RouteBuilder routes={DisclaimerRoutes} />
            <RouteBuilder routes={SeriesCategoriesRoutes} />
            <RouteBuilder routes={SpanishDisclaimersRoutes} />
            <RouteBuilder routes={ColorFamiliesRoutes} />
            <RouteBuilder routes={DPHRoutes} />
            <RouteBuilder routes={RestOfRoutes} />
          </PostSpinner>
        </SideMenu>
      </UiBlocker>
    </HashRouter>
  );
};

export default App;
