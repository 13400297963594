import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
  areRichTextValuesDifferent,
  DateComponent,
  Dropdown,
  DropdownButton,
  FlagsCell,
  Modal,
  MultiLineRTEditor,
  TextArea,
} from 'vapi-ui-common';
import ActionButtonsTableCell from '../../../../../../components/ActionButtonsTableCell';
import DropdownEdit from '../../../../../../components/DropdownEdit';
import DropdownEditItem from '../../../../../../components/DropdownEdit/DropdownEditItem';
import { TableCell, TableRow } from '../../../../../../components/Table';
import useComponentVisible from '../../../../../../hooks/useComponentVisible';
import { IDValueType } from '../../../../../../models/common.model';
import {
  CommonLanguageCategory,
  CommonLanguageItem,
  CommonLanguageVehicle,
} from '../../../../../../models/commonLanguage.model';
import { getSortedListByString } from '../../../../../../utils/sortUtils';
import VehiclesModal from '../VehiclesModal';
import VehiclesModalButton from '../VehiclesModalButton';
import styles from './specRow.module.scss';

interface SpecRowProps {
  item: CommonLanguageItem;
  index: number;
  categories: IDValueType[];
  onDeleteItem?: (spec: CommonLanguageItem) => void;
  onUpdateItem?: (spec: CommonLanguageItem) => void;
  onAddItem?: (spec: CommonLanguageItem) => Promise<boolean>;
  onArchiveItem?: (spec: CommonLanguageItem) => void;
  onAddCategory?: (category: string) => void;
  onUpdateCategory?: (category: CommonLanguageCategory) => void;
  readOnly?: boolean;
  vehicleModels: CommonLanguageVehicle[];
}

const SpecRow = ({
  item,
  index,
  categories,
  onDeleteItem = () => null,
  onUpdateItem = () => null,
  onAddItem = () => new Promise(() => false),
  onArchiveItem = () => null,
  onAddCategory = () => null,
  onUpdateCategory = () => null,
  readOnly,
  vehicleModels,
}: SpecRowProps) => {
  const [isNew, setNew] = useState(!item.id);
  const [openVehicleModal, setOpenVehicleModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleOnCategorySelect = (category: string) => {
    if (item.category !== category) {
      const categoryId = categories.filter(item => item.value === category).map(item => item.id)[0];
      item.setCategory(categoryId, category);
      saveItem(item);
    }
  };

  const handleOnSubCategoryChange = (subCategory: string) => {
    if (item.subCategory !== subCategory) {
      item.subCategory = subCategory;
      saveItem(item);
    }
  };

  const handleOnDescriptionChange = (description: string) => {
    if (areRichTextValuesDifferent(item.description, description)) {
      item.description = description;
      saveItem(item);
    }
  };

  const handleOnVehicleModelsChange = (vehicleModels: CommonLanguageVehicle[]) => {
    item.vehicles = vehicleModels;
    saveItem(item);
  };

  const handleOnNotesChange = (notes: string) => {
    if (notes !== item.notes) {
      item.notes = notes;
      saveItem(item);
    }
  };

  const handleInProgressChange = (item: CommonLanguageItem) => {
    item.isInProgress = !item.isInProgress;
    saveItem(item);
  };

  const saveItem = async (item: CommonLanguageItem) => {
    if (!item.id) {
      const isValid = await onAddItem(item);
      if (isValid) {
        setNew(false);
      }
    } else {
      onUpdateItem(item);
    }
  };

  // Reduce id hash to last four digits
  const renderId = (id: string) => {
    return id.substring(id.length - 4);
  };

  return (
    <>
      <TableRow zebra className={cx({ [styles.newItem]: isNew })}>
        {/* ID */}
        <TableCell
          large
          className={cx({
            [styles.id]: true,
            [styles.tableText]: true,
          })}
        >
          {item.id && <div>{renderId(item.id)}</div>}
        </TableCell>

        {/* Category */}
        <TableCell large className={styles.category}>
          <Dropdown>
            <DropdownButton
              disabled={readOnly}
              className={cx(styles.tableDropdown, { [styles.errItem]: !item.category })}
              onClick={() => setIsComponentVisible(true)}
            >
              {item.category}
            </DropdownButton>
            <DropdownEdit
              ref={ref}
              open={isComponentVisible}
              addBtnText={'Category'}
              onAdd={value => onAddCategory(value)}
              renderList={() => (
                <>
                  {getSortedListByString(categories, 'value').map(catItem => (
                    <DropdownEditItem
                      key={catItem.uid}
                      value={catItem.value}
                      isSelected={item.category === catItem.value}
                      onEdit={(old, newVal) => {
                        catItem.value = newVal;
                        onUpdateCategory(catItem);
                      }}
                      onSelect={() => handleOnCategorySelect(catItem.value)}
                      onClose={() => setIsComponentVisible(false)}
                    />
                  ))}
                </>
              )}
            />
          </Dropdown>
        </TableCell>

        {/* SubCategory */}
        <TableCell large className={styles.subCategory}>
          <TextArea
            defaultValue={item.subCategory}
            name="subCategory"
            className={cx({
              [styles.errItem]: !item.subCategory,
              [styles.tableBorderedText]: true,
              [styles.disabled]: readOnly,
            })}
            onBlur={e => handleOnSubCategoryChange(e.currentTarget.value)}
            disabled={readOnly}
          />
        </TableCell>

        {/* Description */}
        <TableCell large className={styles.description}>
          <MultiLineRTEditor
            value={item.description}
            onBlur={value => handleOnDescriptionChange(value)}
            disabled={readOnly}
            disableMentions
          />
        </TableCell>

        {/* Published */}
        <TableCell large className={styles.published}>
          <DateComponent format="MM/DD/YY">{item.lastPublishedDate}</DateComponent>
        </TableCell>

        {/* Model */}
        <TableCell large className={styles.model}>
          <VehiclesModalButton
            vehicleModels={vehicleModels}
            commonLanguageItem={item}
            readOnly={readOnly}
            onClick={() => setOpenVehicleModal(!openVehicleModal)}
          />
          <Modal size="auto" open={openVehicleModal} onClose={() => setOpenVehicleModal(false)}>
            <VehiclesModal
              vehicleModels={vehicleModels}
              close={() => setOpenVehicleModal(false)}
              readOnly={readOnly}
              commonLanguageItem={item}
              onUpdate={handleOnVehicleModelsChange}
            />
          </Modal>
        </TableCell>

        {/* Notes  */}
        <TableCell large>
          <FlagsCell
            disabled={readOnly}
            index={index}
            notes={item.notes}
            highlighted={false}
            inProgress={item.isInProgress}
            toggleInProgress={() => handleInProgressChange(item)}
            onNotesChange={notes => handleOnNotesChange(notes)}
          />
        </TableCell>

        {/* Action buttons  */}
        <ActionButtonsTableCell
          readOnly={readOnly}
          item={item}
          setOpenDeleteModal={setOpenDeleteModal}
          openDeleteModal={openDeleteModal}
          setOpenArchiveModal={setOpenArchiveModal}
          openArchiveModal={openArchiveModal}
          onDeleteItem={() => onDeleteItem(item)}
          onArchiveItem={() => onArchiveItem(item)}
          itemType="Spec"
        />
        {/* <TableCell large className={styles.actionButtons}>
          {!readOnly && (
            <>
              {!item.lastPublishedDate && (
                <>
                  <ActionButton icon="trash" onClick={() => setOpenDeleteModal(true)} />
                  <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                    <ActionItemModal
                      onClose={() => setOpenDeleteModal(false)}
                      description={convertToRichTextObject(item.description).text}
                      onConfirm={() => onDeleteItem(item)}
                      itemType="Spec"
                    />
                  </Modal>
                </>
              )}
              {item.lastPublishedDate && (
                <>
                  <ActionButton icon="archive" onClick={() => setOpenArchiveModal(true)} />
                  <Modal open={openArchiveModal} onClose={() => setOpenArchiveModal(false)}>
                    <ActionItemModal
                      description={convertToRichTextObject(item.description).text}
                      onClose={() => setOpenArchiveModal(false)}
                      onConfirm={() => onArchiveItem(item)}
                      action="archive"
                      itemType="Spec"
                    />
                  </Modal>
                </>
              )}
            </>
          )}
        </TableCell> */}
      </TableRow>
    </>
  );
};

export default observer(SpecRow);
