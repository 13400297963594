import React from 'react';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import useQuery from '../../../../hooks/useQuery';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import FeaturesReview from './FeaturesReview';
import FeaturesReviewCL from './FeaturesReviewCL';

const FeatureReviewController = (props: ProductDataControllerProps) => {
  const team = useQuery().get('team') as VehicleTeam;

  switch(team) {
    case VehicleTeam.PRODUCT_TEAM:
    case VehicleTeam.AGENCY_TEAM: {
      return <FeaturesReview {...props} />;
    }
    case VehicleTeam.COMMON_LANGUAGE_TEAM: {
      return <FeaturesReviewCL {...props} />;
    }
    default: return (null);
  }
};

export default FeatureReviewController;
