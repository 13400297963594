import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { HeaderCell, Table, TableRow, Thead } from '../../../../components/Table';

interface CommonLanguageTableProps {
  isArchived?: boolean;
  onSort: (key: string) => void;
  renderRows: () => ReactNode;
}

const CommonLanguageTable = observer(
  ({ isArchived, onSort, renderRows }: CommonLanguageTableProps) => {
    return (
      <Table fullWidth>
        <Thead>
          <TableRow>
            <HeaderCell onClick={() => onSort('id')}>ID</HeaderCell>
            <HeaderCell onClick={() => onSort('category')}>Category</HeaderCell>
            <HeaderCell onClick={() => onSort('subCategory')}>Sub Category</HeaderCell>
            <HeaderCell onClick={() => onSort('description')}>Description</HeaderCell>
            <HeaderCell onClick={() => onSort('lastPublishedDate')}>
              {isArchived ? 'Archived' : 'Published'}
            </HeaderCell>
            <HeaderCell>Model</HeaderCell>
            <HeaderCell>Notes</HeaderCell>
            <HeaderCell />
          </TableRow>
        </Thead>

        <tbody>{renderRows()}</tbody>
      </Table>
    );
  }
);

export default CommonLanguageTable;
