import { useCallback } from 'react';
import { useParams } from 'react-router';
import { VDKeyFeatureOptionResponse } from '../models/features.model';
import { VehicleTeam } from '../models/vehicleData.model';
import { getVersionInfoFromParams } from '../utils/vehicleDataUtils';
import { getAllKeyFeatures, getKeyFeatures } from '../webservices/adminApi';
import useStores from './useStores';
import { toGqlBrand, toGqlLanguage, toGqlTeam } from '../utils/graphqlUtils';

const useKeyFeatureOptionsLoad = () => {
  const { userStore, featuresStore, teamStore } = useStores();
  const { seriesId, year: modelYear, versionInfo } = useParams<{
    team: VehicleTeam;
    seriesId: string;
    year: string;
    versionInfo: string;
  }>();

  const showKeyFeature =
    teamStore.team.showKeyFeature && process.env.REACT_APP_KEY_FEATURES === 'true';

  const loadKeyFeatures = useCallback(async () => {
    let data: VDKeyFeatureOptionResponse = { keyFeatures: {} };

    if (showKeyFeature) {
      const language = teamStore.team.defaultLanguage;
      const version = getVersionInfoFromParams(versionInfo)[language]?.toString();
      const res = await getKeyFeatures({
        brand: toGqlBrand(userStore.brand),
        team: toGqlTeam(teamStore.team.param),
        seriesId,
        modelYear: parseInt(modelYear),
        language: toGqlLanguage(language),
        filter: version ?? 'DRAFT',
      });

      data = res;
    }

    featuresStore.setKeyFeatures(data);
  }, [userStore, featuresStore, teamStore, seriesId, modelYear, versionInfo, showKeyFeature]);

  const loadAllKeyFeatures = useCallback(async () => {
    let data: VDKeyFeatureOptionResponse = { keyFeatures: {} };

    if (showKeyFeature) {
      data = await getAllKeyFeatures({
        brand: toGqlBrand(userStore.brand),
      });
    }

    featuresStore.setKeyFeatures(data);
  }, [userStore, featuresStore, showKeyFeature]);

  return {
    loadKeyFeatures,
    loadAllKeyFeatures,
    showKeyFeature,
  };
};

export default useKeyFeatureOptionsLoad;
