import Checkbox from '../../../../../../components/Checkbox';
import useChecklist from '../../../../../../hooks/useChecklist';
import { CommonLanguageVehicleChecklist } from '../../../../../../models/commonLanguage.model';
import React, { useEffect } from 'react';
import { FilterTabColumn, FilterTabColumnList, FilterTabColumns } from 'vapi-ui-common';

interface VehiclesTabProps {
  year: string;
  data: CommonLanguageVehicleChecklist[];
  readOnly?: boolean;
}

const VehiclesTab = ({ year, data, readOnly }: VehiclesTabProps) => {
  const { checklist, setChecklist, selectItem } = useChecklist();

  useEffect(() => {
    setChecklist(data);
  }, [setChecklist, data]);

  return (
    <FilterTabColumns>
      {checklist &&
        checklist.map(category => (
          // Category Checkbox
          <FilterTabColumn key={category.name}>
            <Checkbox
              id={`chbox-${year}-${category.name}`}
              checked={category.selected}
              disabled={readOnly}
              onChange={e => selectItem(category, e.currentTarget.checked)}
            >
              {category.name}
            </Checkbox>

            {/* Vehicle Checkbox */}
            <FilterTabColumnList>
              {category.items &&
                category.items.map(item => (
                  <li key={item.name}>
                    <Checkbox
                      id={`chbox-${year}-${item.name}`}
                      checked={item.selected}
                      disabled={readOnly}
                      onChange={e => selectItem(item, e.currentTarget.checked)}
                    >
                      {item.name}
                    </Checkbox>
                  </li>
                ))}
            </FilterTabColumnList>
          </FilterTabColumn>
        ))}
    </FilterTabColumns>
  );
};

export default VehiclesTab;
