import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { cleanUpRte } from 'vapi-ui-common';
import { LIMITED_DATA_STATUS } from '../constants/vehicleData/VDConstants';
import { removeNulls } from '../utils';
import { ChangeLogBase, ChangeLogTypes } from './changeLog.model';
import { BaseReviewItem, IDValueType, KeyValueType } from './common.model';
import { RefItem } from './refItem.model';
import { ReviewChangeMap, ReviewChangeResponse, ReviewChangeTypeMap } from './review.model';
import { ModelFieldStatus } from './vehicleModel.model';

export class SpecItem {
  uid = '';
  id = '';
  revId = '';
  @observable comLangId = '';
  @observable modelsMap: KeyValueTypeSpecsModel = {};
  @observable link = '';
  @observable category = {} as RefItem;
  @observable specType = {} as RefItem;
  @observable description = '';
  @observable isInProgress = false;
  @observable isRequired = false;
  @observable notes = '';
  @observable rejectNotes = '';
  @observable sortOrder: number | string = 0;
  @observable compareFeatureId: string = '';
  @observable changedAttributes: string[] = [];
  @observable changedModelIds: string[] = [];
  @observable isTDPR: boolean = false;
  @observable isUSVI: boolean = false;
  @observable fromTMNA: boolean = false;
  @observable fieldStatus: SpecFieldStatusRequest = {
    id: '',
    status: LIMITED_DATA_STATUS.READY_TO_PUBLISH,
    modelApplicability: {},
  };

  constructor(spec?: SpecResponse | StandardSpecItem) {
    this.uid = uuidv4();
    if (spec) {
      Object.assign(this, removeNulls(spec));
      this.changedAttributes.forEach(attr => {
        if (attr.includes('modelApplicability#')) {
          const splt = attr.split('#');
          if (splt.length > 1) {
            this.changedModelIds.push(splt[1]);
          }
        }
      });
    }
  }

  getPayload = () => {
    const modelApplicability = {} as SpecModelApplicability;
    Object.values(this.modelsMap).forEach(mdl => {
      if (!mdl.setting) {
        modelApplicability[mdl.id] = null;
      } else {
        modelApplicability[mdl.id] = mdl.setting;
      }
    });

    return {
      id: this.id,
      revId: this.revId,
      categoryId: this.category.id,
      specTypeId: this.specType.id,
      comLangId: this.comLangId,
      description: cleanUpRte(this.description),
      isInProgress: this.isInProgress,
      notes: this.notes,
      link: this.link,
      modelApplicability,
    } as SpecRequest;
  };

  isValid = () => {
    return !!(this.category.id && this.specType.id && this.description);
  };
}

export type KeyValueTypeSpecsModel = KeyValueType<SpecsModel>;

export type SpecFieldStatusRequest = ModelFieldStatus;

export interface SpecResponse {
  id: string;
  revId: string;
  modelApplicability: SpecModelApplicability;
  categoryId: string;
  specTypeId: string | undefined;
  description: string;
  isInProgress: boolean;
  notes: string | undefined;
  comLangId?: string;
  changedAttributes?: string[];
  isTDPR?: boolean;
  isUSVI?: boolean;
  fromTMNA?: boolean;
  fieldStatus: ModelFieldStatus;
}

export type SpecRequest = Omit<SpecResponse, 'fieldStatus'>;

type SpecModelApplicability = {
  [modelCode: string]: SpecSettings | null;
};

export type SpecsModel = {
  id: string; // modelid
  setting: SpecSettings | null; // value in settings cell
  limitedDataStatus?: number;
};

export type KeyValueTypeSpecSettings = KeyValueType<SpecSettings>;

export class SpecsReviewItem extends BaseReviewItem {
  @observable specType = {} as IDValueType;
  @observable models: SpecsModel[] = [];
  @observable modelApplicability: KeyValueTypeSpecSettings = {};

  constructor({
    spec,
    change,
    category,
    specType,
    models,
    changeTypeId,
  }: {
    spec?: SpecsReviewResponse;
    change?: ReviewChangeResponse;
    category?: RefItem;
    specType?: RefItem;
    models?: SpecsModel[];
    changeTypeId?: string;
  }) {
    super();
    this.uid = uuidv4();
    if (spec) {
      Object.assign(this, removeNulls(spec));
    }
    this.destructureReviewChangeResponse(change);
    this.createOtherChanges(spec);

    if (category) {
      this.category = category;
    }

    if (specType) {
      this.specType = specType;
    }

    if (models) {
      this.models = models;
    }

    if (changeTypeId) {
      this.changeTypeId = changeTypeId;
    }
  }
}

export interface SpecsReviewResponse {
  isHighlighted: string;
  notes: string;
  modelApplicability: SpecModelApplicability;
  link: string;
  revId: string;
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
  description: string;
  specTypeId: string;
  specType?: string;
  shortDescription: string;
  isDeleted: boolean;
  isAccepted: boolean;
  isApplied: boolean;
  isNewChange: boolean;
  id: string;
  isInProgress: boolean;
  categoryId: string;
  category?: string;
}

export interface SpecsReviewRequest {
  id: string;
  revId: string;
  isAccepted: boolean;
  isApplied: boolean;
  rejectNotes: string;
}

export enum SpecSettings {
  STANDARD = 'S',
  OPTIONAL = 'O',
  PACKAGE = 'P',
  UNDEFINED = '',
}

export interface SpecsReviewMap {
  [id: string]: SpecsChangeTypeMap;
}

export interface SpecsChangeTypeMap extends ReviewChangeTypeMap {
  category: ReviewChangeMap<string>;
  specType: ReviewChangeMap<string>;
  description: ReviewChangeMap<string>;
  modelApplicability: ReviewChangeMap<KeyValueType<SpecSettings>>;
}

export type SpecReviewType =
  | 'category'
  | 'specType'
  | 'description'
  | 'modelApplicability'
  | 'added'
  | 'deleted';

export class SpecsReviewChangeItem implements ChangeLogBase {
  id = '';
  revId = '';
  before = '';
  after = '';
  beforeValue = '';
  afterValue = '';
  modifiedDate = '';
  modifiedBy = '';
  changeType = '' as ChangeLogTypes;
}

export interface SpecsMap {
  specs: {
    [id: string]: SpecsLangMap;
  };
  order: string[];
}

export interface SpecsLangMap {
  langs: {
    [lang: string]: SpecItem;
  };
  data?: SpecResponse;
}

export interface StandardSpecItem {
  category: string;
  categoryId: string;
  description: string;
  id: string;
  isAdded: boolean;
  specType: string;
  specTypeId: string;
}

export type GetStandardSpecsResponse = {
  systemSpecs: KeyValueType<StandardSpecItem>;
};

export interface NewStandardSpectItem extends StandardSpecItem {
  isDeleted: boolean;
  isInProgress: boolean;
  revId: string;
}

export type AddStandardSpecsResponse = {
  specs: {
    newSpecs: KeyValueType<NewStandardSpectItem>;
  };
};
