import React, { useCallback } from 'react';
import { Dropdown, DropdownContent } from 'vapi-ui-common';
import styles from './dropdownListSelect.module.scss';
import useComponentVisible from '../../hooks/useComponentVisible';

export interface DropdownListSelectProps {
  list?: Array<string>;
  onSelect?: (item: string) => void;
  children?: JSX.Element;
}

const DropdownListSelect = ({
  list = [],
  onSelect = () => ({}),
  children = <div>click me</div>,
}: DropdownListSelectProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const openDropdown = useCallback(() => {
    setIsComponentVisible(true);
  }, [setIsComponentVisible]);

  const onClick = useCallback(
    (val: string) => {
      return () => {
        onSelect(val);
        setIsComponentVisible(false);
      };
    },
    [setIsComponentVisible, onSelect]
  );

  return (
    <Dropdown>
      <div onClick={openDropdown} data-testid="dropdown-list-select-comp">
        {children}
      </div>
      <DropdownContent
        menuClass={styles.dropDownListSelectContainer}
        open={isComponentVisible}
        ref={ref}
      >
        <ul className={styles.dropDownListSelect}>
          {list &&
            list.map(item => (
              <li key={item} onClick={onClick(item)}>
                {item}
              </li>
            ))}
        </ul>
      </DropdownContent>
    </Dropdown>
  );
};

export default DropdownListSelect;
