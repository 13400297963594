
import React from 'react';

import styles from '../../../modelsModal.module.scss';

const Label = (props: {htmlFor: string, children: React.ReactNode, optional?: boolean}) => {
  const { htmlFor, children, optional, } = props;

  return (
    <>
      <label htmlFor={htmlFor}>
        {children}
      </label>
      { optional && <span className={styles.optional}>-Optional</span>}
    </>
  );
}

export default Label
