import { ReportItem, ReportResponse, VehicleModel } from '../models/reports.model';
import { Brand } from '../models/user.model';

export const reportsXForm = (
  reports: ReportResponse[],
  type: string,
  models:VehicleModel[],
) => {
  return reports.map(item => reportsItemXForm(item, type, models));
};

export const reportsItemXForm = (
  item: ReportResponse,
  type: string,
  vehicleModels: VehicleModel[]
) => {
  const selectedSeries = vehicleModels.filter(model => model.seriesId === item.seriesId);
  const reportItem  = new ReportItem(item);

  reportItem.series = selectedSeries.length ? selectedSeries[0].seriesName : '';
  reportItem.year = item.modelYear;
  reportItem.reportType = type;

  return reportItem;
};

type SeriesMatchFn = (model: VehicleModel, seriesYear: string, series: string) => boolean;
const compareModelWithSelected: SeriesMatchFn = ({ modelYear, seriesName }, seriesYear, series) => {
  const sameYear = modelYear.toString() === seriesYear;
  const sameName = seriesName.toLowerCase() === series.toLowerCase()
  return sameYear && sameName;
}

export const filterSelectedSeries = (seriesYear: string, series: string) => (model: VehicleModel) => compareModelWithSelected(model, seriesYear, series)

type HandlerMap = {
  [key: string]: () => Promise<void>
}
export type BrandMap = {
  [K in Brand]?: HandlerMap
}

export const bindGetHandlerFn = (errorPrefix: string = 'error', reportHandlersMap: BrandMap) => {
  const createGetHandlerFn = (brand: string, reportType: string) => {
    const errorFn = (errorMessage: string) => console.error(`${errorPrefix}: ${errorMessage}`)
  
    const thisBrandHandlers = reportHandlersMap[brand as keyof BrandMap]
    if (!thisBrandHandlers) {
      return errorFn(`cannot find report handlers for brand ${brand}`)
    }
  
    const reportHandler = thisBrandHandlers[reportType as keyof typeof thisBrandHandlers]
    if (!reportHandler) {
      return errorFn(`cannot find report handlers for report type ${reportType} and brand ${brand}`)
    }
  
    return reportHandler
  }
  return createGetHandlerFn
}
