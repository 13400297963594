import cx from 'clsx';
import React, { useContext } from 'react';
import { KeyFeatureContext } from '.';
import { KeyFeatureType } from '../../models/features.model';
import styles from './KeyFeature.module.scss';
import KeyFeatureListItemEdit from './KeyFeatureListItemEdit';
import KeyFeatureListItemReadOnly from './KeyFeatureListItemReadOnly';

interface Props {
  keyFeature: KeyFeatureType;
}

const KeyFeatureListItem = ({ keyFeature }: Props) => {
  const { readOnly } = useContext(KeyFeatureContext);

  return (
    <li
      className={cx(styles.keyFeatureListItemContainer, { [styles.zebra]: readOnly })}
      data-testid={`key-feature-list-item-container-${keyFeature.uid}`}
    >
      <KeyFeatureListItemReadOnly keyFeature={keyFeature} />
      <KeyFeatureListItemEdit keyFeature={keyFeature} />
    </li>
  );
};

export default KeyFeatureListItem;
