import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { KeyFeatureContext } from '.';
import Spinner from '../Spinner';
import styles from './KeyFeature.module.scss';
import KeyFeatureDialogBody from './KeyFeatureDialogBody';
import KeyFeatureDialogFooter from './KeyFeatureDialogFooter';
import KeyFeatureDialogHeader from './KeyFeatureDialogHeader';

interface Props {
  disabled?: boolean;
  closePopover?: () => void;
}

const KeyFeature = ({ disabled = false, closePopover = () => undefined }: Props) => {
  const { isLoaded } = useContext(KeyFeatureContext);

  if (!isLoaded) {
    return <Spinner testId="key-feature-dialog-spinner" />;
  }

  return (
    <section className={styles.dialog} data-testid="key-feature-dialog">
      <KeyFeatureDialogHeader />

      <KeyFeatureDialogBody />

      <KeyFeatureDialogFooter disabled={disabled} closePopover={closePopover} />
    </section>
  );
};

export default observer(KeyFeature);
