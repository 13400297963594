import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ActionBar,
  ActionBarDivider,
  Button,
  Header,
  IconTextButton,
  Modal,
  PublishModal,
  Spinner,
} from 'vapi-ui-common';
import SecondaryHeader from '../../components/SecondaryHeader';
import { TabPanel } from '../../components/Tabs/Tabs';
import useDebounce from '../../hooks/useDebounce';
import useQuery from '../../hooks/useQuery';
import useStores from '../../hooks/useStores';
import {
  CFColorItem,
  CFExteriorColorItem,
  CFInteriorColorItem,
  ColorFamilyItem,
} from '../../models/colorFamilies.model';
import { RefItem } from '../../models/refItem.model';
import { VDColorFamiliesTab } from '../../models/vehicleData.model';
import { handleErrorResponse } from '../../utils/errorHandlingUtils';
import { toGqlBrand } from '../../utils/graphqlUtils';
import {
  addCFExteriorColor,
  addCFInteriorColor,
  addColorFamily,
  addMaterials,
  deleteCFExteriorColor,
  deleteCFInteriorColor,
  deleteColorFamily,
  publishColorFamilyDraft,
  updateCFExteriorColor,
  updateCFInteriorColor,
  updateColorFamily,
  updateMaterials,
} from '../../webservices/vehicleColorsApi';
import ActionBarFiltersSection from '../vehicleData/components/ActionBarFiltersSection';
import CFColorsTable from './components/CFColorsTable';
import ColorFamiliesTable from './components/colorFamiliesTable';

// import styles from './colorFamiliesController.module.scss';

const ColorFamily = () => {
  const {
    userStore: { brand, modules },
    colorFamiliesStore,
  } = useStores();

  const query = useQuery();
  const tab = query.get('tab') || VDColorFamiliesTab.COLOR_FAMILIES;
  const history = useHistory();
  const location = useLocation();

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const { debounce } = useDebounce({ delay: 2000 });
  const readOnly = !modules.AgencyTeam.canEdit;

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    colorFamiliesStore.reset();
    (async () => {
      try {
        await colorFamiliesStore.fetchData(brand);
      } catch (e) {
        toast.error('Error loading Color Families data.');
        if (e instanceof Error) console.log(e.message);
      }
      setIsLoaded(true);
    })();
  }, [brand, colorFamiliesStore]);

  useEffect(() => {
    fetchData();
  }, [brand, colorFamiliesStore, fetchData]);

  useEffect(() => {
    if (tab) {
      colorFamiliesStore.selectedTab = decodeURIComponent(tab) as VDColorFamiliesTab;
    }
  }, [tab, colorFamiliesStore]);

  const addEmptyColorFamilyItem = () => {
    colorFamiliesStore.addItem();
    showFormFieldError('Color Family');
  };

  // Color Families Tab
  const addColorFamilyItem = async (color: ColorFamilyItem) => {
    setIsLoaded(false);
    try {
      if (color && color.isValid()) {
        const { id, revId } = await trackPromise(
          addColorFamily({
            brand: toGqlBrand(brand),
            payload: color.getCreatePayload(),
          })
        );

        color.id = id;
        color.revId = revId;
        toast.success('Color item added successfully');
      } else {
        throw new Error('Color Family is not valid');
      }
    } catch (e) {
      handleErrorResponse(e, `Error adding color family: ${e}`);
    }
    setIsLoaded(true);
  };

  const deleteColorFamilyItem = async (color: ColorFamilyItem) => {
    setIsLoaded(false);
    try {
      colorFamiliesStore.deleteItem(color);
      if (color.id) {
        await trackPromise(
          deleteColorFamily({ brand: toGqlBrand(brand), deleteColorFamilyId: color.id })
        );
      }
      toast.success('Color Family deleted successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting color family');
    }
    setIsLoaded(true);
  };

  const updateColorFamilyItem = async (color: ColorFamilyItem) => {
    setIsLoaded(false);
    try {
      debounce(async () => {
        const response = await trackPromise(
          updateColorFamily({
            brand: toGqlBrand(brand),
            payload: color.getUpdatePayload(),
          })
        );
        color.revId = response.revId;
        toast.success('Color item updated successfully');
      }, color.uid);
    } catch (e) {
      handleErrorResponse(e, 'Color Item failed add');
    }
    setIsLoaded(true);
  };

  const saveColorFamilyItem = (color: ColorFamilyItem) => {
    if (color.isValid()) {
      color.id ? updateColorFamilyItem(color) : addColorFamilyItem(color);
    }
  };

  // Materials dropdown Interior Tab
  const handleAddMaterial = async (value: string) => {
    try {
      const response = await trackPromise(
        addMaterials({
          brand: toGqlBrand(brand),
          payload: {
            materials: [value],
          },
        })
      );
      colorFamiliesStore.setMaterials(response);
    } catch (e) {
      handleErrorResponse(e, 'Error adding material');
    }
  };

  const handleUpdateMaterialItem = async (material: RefItem) => {
    try {
      if (material.value) {
        await trackPromise(
          updateMaterials({
            brand: toGqlBrand(brand),
            payload: {
              id: material.id,
              name: material.value as string,
            },
          })
        );
        colorFamiliesStore.materials = colorFamiliesStore.materials.map(mat => {
          if (mat.id === material.id) {
            mat.value = material.value;
          }
          return mat;
        });
        toast.success('Material successfully updated');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating material item');
    }
  };

  // Interior Colors Item funcs
  const addEmptyInteriorColorItem = () => {
    colorFamiliesStore.addInteriorItem();
    showFormFieldError('Interior Color');
  };

  const addInteriorColorItem = async (color: CFInteriorColorItem) => {
    setIsLoaded(false);
    try {
      if (color && color.isValid()) {
        const { id, revId } = await trackPromise(
          addCFInteriorColor({
            brand: toGqlBrand(brand),
            payload: color.getCreatePayload(),
          })
        );

        color.id = id;
        color.revId = revId;
        color.colorFamilies.forEach(fam => {
          fam.interiorColorsMap[color.id] = color;
        });
        toast.success('Interior Color item added successfully');
      } else {
        throw new Error('Interior Color is not valid');
      }
    } catch (e) {
      handleErrorResponse(e, `Error adding Interior Color: ${e}`);
    }
    setIsLoaded(true);
  };

  const updateInteriorColorItem = async (color: CFInteriorColorItem) => {
    setIsLoaded(false);
    try {
      debounce(async () => {
        const response = await trackPromise(
          updateCFInteriorColor({
            brand: toGqlBrand(brand),
            payload: color.getUpdatePayload(),
          })
        );

        color.colorFamilies.forEach(fam => {
          fam.interiorColorsMap[color.id] = color;
        });

        const famIds = color.colorFamilies.map(fam => fam.id);
        colorFamiliesStore.colorFamilies.forEach(fam => {
          if (fam.interiorColorsMap[color.id] && !famIds.includes(fam.id)) {
            delete fam.interiorColorsMap[color.id];
          }
        });

        color.revId = response.revId;
        toast.success('Color item updated successfully');
      }, color.uid);
    } catch (e) {
      handleErrorResponse(e, 'Color Item failed add');
    }
    setIsLoaded(true);
  };

  const deleteInteriorColorItem = async (color: CFColorItem) => {
    const thisColor = color as CFInteriorColorItem;
    setIsLoaded(false);
    try {
      colorFamiliesStore.deleteInteriorItem(thisColor);
      if (thisColor.id) {
        await trackPromise(
          deleteCFInteriorColor({
            brand: toGqlBrand(brand),
            deleteCfIntColorId: thisColor.id,
          })
        );

        thisColor.colorFamilies.forEach(fam => {
          if (fam.interiorColorsMap[thisColor.id]) {
            delete fam.interiorColorsMap[thisColor.id];
          }
        });
      }
      toast.success('Interior color deleted successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting interior color');
    }
    setIsLoaded(true);
  };

  const saveInteriorColorItem = (color: CFColorItem) => {
    const thisColor = color as CFInteriorColorItem;
    if (thisColor.isValid()) {
      thisColor.id ? updateInteriorColorItem(thisColor) : addInteriorColorItem(thisColor);
    }
  };

  // Exterior Color Funcs
  const addEmptyExteriorColorItem = () => {
    colorFamiliesStore.addExteriorItem();
    showFormFieldError('Exterior Color');
  };

  const addExteriorColorItem = async (color: CFExteriorColorItem) => {
    setIsLoaded(false);
    try {
      if (color && color.isValid()) {
        const { id, revId } = await trackPromise(
          addCFExteriorColor({
            brand: toGqlBrand(brand),
            payload: color.getCreatePayload(),
          })
        );

        color.id = id;
        color.revId = revId;
        color.colorFamilies.forEach(fam => {
          fam.exteriorColorsMap[color.id] = color;
        });
        toast.success('Exterior Color item added successfully');
      } else {
        throw new Error('Exterior Color is not valid');
      }
    } catch (e) {
      handleErrorResponse(e, `Error adding Exterior Color: ${e}`);
    }
    setIsLoaded(true);
  };

  const updateExteriorColorItem = async (color: CFExteriorColorItem) => {
    setIsLoaded(false);
    try {
      debounce(async () => {
        const response = await trackPromise(
          updateCFExteriorColor({
            brand: toGqlBrand(brand),
            payload: color.getUpdatePayload(),
          })
        );
        color.colorFamilies.forEach(fam => {
          fam.exteriorColorsMap[color.id] = color;
        });
        const famIds = color.colorFamilies.map(fam => fam.id);
        colorFamiliesStore.colorFamilies.forEach(fam => {
          if (fam.exteriorColorsMap[color.id] && !famIds.includes(fam.id)) {
            delete fam.exteriorColorsMap[color.id];
          }
        });
        color.revId = response.revId;
        toast.success('Color item updated successfully');
      }, color.uid);
    } catch (e) {
      handleErrorResponse(e, 'Color Item failed add');
    }
    setIsLoaded(true);
  };

  const deleteExteriorColorItem = async (color: CFColorItem) => {
    const thisColor = color as CFExteriorColorItem;
    setIsLoaded(false);
    try {
      colorFamiliesStore.deleteExteriorItem(thisColor);
      if (thisColor.id) {
        await trackPromise(
          deleteCFExteriorColor({
            brand: toGqlBrand(brand),
            deleteCfExtColorId: thisColor.id,
          })
        );

        thisColor.colorFamilies.forEach(fam => {
          if (fam.exteriorColorsMap[thisColor.id]) {
            delete fam.exteriorColorsMap[thisColor.id];
          }
        });
      }
      toast.success('Exterior color deleted successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting Exterior color');
    }
    setIsLoaded(true);
  };

  const saveExteriorColorItem = (color: CFColorItem) => {
    const thisColor = color as CFExteriorColorItem;
    if (thisColor.isValid()) {
      thisColor.id ? updateExteriorColorItem(thisColor) : addExteriorColorItem(thisColor);
    }
  };

  const historyTabRedirect = (tab: string) => {
    colorFamiliesStore.selectedTab = tab as VDColorFamiliesTab;
    const encodedTab = encodeURIComponent(tab);
    const url = `${location.pathname}?tab=${encodedTab}`;
    history.push(url);
  };

  const publishDraft = async () => {
    setIsLoaded(false);
    try {
      let isValid = true;
      let invalidatedResource: string = 'Color Family';
      colorFamiliesStore.colorFamilies.forEach(fam => {
        if (!fam.isValid()) {
          isValid = false;
        }
      });
      if (isValid) {
        colorFamiliesStore.interiorColors.forEach(color => {
          if (!color.isValid()) {
            isValid = false;
            invalidatedResource = 'Interior Color';
          }
        });
      }
      if (isValid) {
        colorFamiliesStore.exteriorColors.forEach(color => {
          if (!color.isValid()) {
            isValid = false;
            invalidatedResource = 'Exterior Color';
          }
        });
      }
      if (isValid) {
        await trackPromise(publishColorFamilyDraft({ brand: toGqlBrand(brand) }));
        toast.success('Successfully published draft');
      } else {
        throw new Error(`${invalidatedResource} is not valid`);
      }
    } catch (e) {
      handleErrorResponse(e, ` ${e} Failed to publish draft`);
    }
    fetchData();
    historyTabRedirect(tab);
    setIsLoaded(true);
  };

  const getActionBarButtons = () => {
    const actionBarButtons: React.ReactNode[] = [];
    if (colorFamiliesStore.selectedTab === VDColorFamiliesTab.COLOR_FAMILIES) {
      actionBarButtons.push(
        <IconTextButton
          icon="plus"
          text="Add Color Family"
          onClick={() => {
            addEmptyColorFamilyItem();
          }}
        />
      );
    } else if (colorFamiliesStore.selectedTab === VDColorFamiliesTab.INTERIOR) {
      actionBarButtons.push(
        <IconTextButton
          icon="plus"
          text="Add Interior Color"
          onClick={() => {
            addEmptyInteriorColorItem();
          }}
        />
      );
    } else if (colorFamiliesStore.selectedTab === VDColorFamiliesTab.EXTERIOR) {
      actionBarButtons.push(
        <IconTextButton
          icon="plus"
          text="Add Exterior Color"
          onClick={() => {
            addEmptyExteriorColorItem();
          }}
        />
      );
    }

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  const showFormFieldError = (item: string) => {
    toast.error(`Please finish filling out all items of the new ${item}.`);
  };

  const savedColorFamilies = colorFamiliesStore.colorFamilies.filter(fam => !!fam.id);

  const handleOnPublishClick = () => {
    if (validationMessage) {
      toast.error(validationMessage);
    } else {
      setShowPublishModal(true);
    }
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="" moduleSubTitle="Color Family" />
      <SecondaryHeader
        tabs={colorFamiliesStore.tabs}
        selectedTab={colorFamiliesStore.selectedTab}
        setSelectedTab={tab => {
          colorFamiliesStore.resetFilters();
          historyTabRedirect(tab);
        }}
        renderButtons={() => (
          <>
            {!readOnly && (
              <>
                <Button variant="primary" onClick={handleOnPublishClick}>
                  Publish
                </Button>
                <Modal open={showPublishModal} onClose={() => setShowPublishModal(false)}>
                  <PublishModal
                    close={() => setShowPublishModal(false)}
                    publishData={() => {
                      publishDraft();
                    }}
                  />
                </Modal>
              </>
            )}
          </>
        )}
      />
      {!readOnly && (
        <ActionBar>
          <ActionBarFiltersSection
            searchText={colorFamiliesStore.searchText}
            onSearchTextChange={text =>
              colorFamiliesStore.onFilter(
                () => (colorFamiliesStore.searchText = text),
                colorFamiliesStore.selectedTab
              )
            }
            renderButtons={getActionBarButtons()}
            renderFilter={onclose => <div></div>}
            hideFilter
          />
        </ActionBar>
      )}

      <TabPanel
        tab={VDColorFamiliesTab.COLOR_FAMILIES}
        selected={colorFamiliesStore.selectedTab === VDColorFamiliesTab.COLOR_FAMILIES}
      >
        <ColorFamiliesTable
          deleteColorItem={deleteColorFamilyItem}
          saveColorFamilyItem={saveColorFamilyItem}
          colorFamilies={colorFamiliesStore.filteredColorFamilies}
          readOnly={readOnly}
          onSort={colorFamiliesStore.onSortColorFamilies}
        />
      </TabPanel>

      <TabPanel
        tab={VDColorFamiliesTab.INTERIOR}
        selected={colorFamiliesStore.selectedTab === VDColorFamiliesTab.INTERIOR}
      >
        <CFColorsTable
          readOnly={readOnly}
          colors={colorFamiliesStore.filteredInteriorColors}
          colorFamilies={savedColorFamilies}
          saveColorItem={saveInteriorColorItem}
          deleteColorItem={deleteInteriorColorItem}
          materials={colorFamiliesStore.materials || []}
          onAddMaterial={handleAddMaterial}
          onUpdateMaterial={handleUpdateMaterialItem}
          onSort={colorFamiliesStore.onSortInteriorColors}
          setValidationMessage={setValidationMessage}
        />
      </TabPanel>

      <TabPanel
        tab={VDColorFamiliesTab.EXTERIOR}
        selected={colorFamiliesStore.selectedTab === VDColorFamiliesTab.EXTERIOR}
      >
        <CFColorsTable
          readOnly={readOnly}
          colors={colorFamiliesStore.filteredExteriorColors}
          colorFamilies={savedColorFamilies}
          saveColorItem={saveExteriorColorItem}
          deleteColorItem={deleteExteriorColorItem}
          hideMaterial
          onSort={colorFamiliesStore.onSortExteriorColors}
          setValidationMessage={setValidationMessage}
        />
      </TabPanel>
    </>
  );
};

export default observer(ColorFamily);
