import React, { useEffect, useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabColumn,
  FilterTabColumns,
  FilterTabFooter,
} from 'vapi-ui-common';
import CategoryFiltersColumn from '../../../../../../components/CategoryFiltersColumn';
import Checkbox from '../../../../../../components/Checkbox';
import useChecklist from '../../../../../../hooks/useChecklist';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';

type FiltersProps = {
  categories: string[];
  categoryFilters: string[];
  setCategoryFilters: (categoryFilters: string[]) => void;
  isInProgressFilter: boolean;
  setIsInProgressFilter: (value: boolean) => void;
  isSyncUpdateFilter?: boolean;
  setIsSyncUpdateFilter?: (value: boolean) => void;
  isHighlightedFilter?: boolean;
  setIsHighlightedFilter?: (value: boolean) => void;
  onClose: () => void;
  isReviewNotesFilter?: boolean;
  setIsReviewNotesFilter?: (value: boolean) => void;
  isPublished?: boolean;
  isReviewPage?: boolean;
};
const Filters: React.FC<FiltersProps> = ({
  categories,
  categoryFilters,
  setCategoryFilters,
  isInProgressFilter,
  setIsInProgressFilter,
  isSyncUpdateFilter,
  setIsSyncUpdateFilter,
  isHighlightedFilter,
  setIsHighlightedFilter,
  onClose,
  isReviewNotesFilter,
  setIsReviewNotesFilter,
  isPublished,
  isReviewPage,
}) => {
  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } = useChecklist();

  const [isInProgress, setIsInProgress] = useState(isInProgressFilter);
  const [isSyncUpdate, setIsSyncUpdate] = useState(isSyncUpdateFilter || false);
  const [isHighlighted, setIsHighlighted] = useState(isHighlightedFilter || false);
  const [isReviewNotes, setIsReviewNotes] = useState(isReviewNotesFilter || false);

  const {
    teamStore: {
      team: { name, allowInProgressFilter },
    },
  } = useStores();

  useEffect(() => {
    (() => {
      setChecklist(
        categories.map((cat: string) => ({
          name: cat,
          selected: categoryFilters.indexOf(cat) !== -1,
        }))
      );
    })();
  }, [categories, setChecklist, categoryFilters]);

  const applyFilters = () => {
    const selectedCategoryFilters: string[] = [];

    checklist.forEach(item => {
      if (item.selected) {
        selectedCategoryFilters.push(item.name);
      }
    });

    setIsInProgressFilter(isInProgress);
    setIsSyncUpdateFilter && setIsSyncUpdateFilter(isSyncUpdate);
    setIsHighlightedFilter && setIsHighlightedFilter(isHighlighted);
    setCategoryFilters(selectedCategoryFilters);
    setIsReviewNotesFilter && setIsReviewNotesFilter(isReviewNotes);
    onClose();
  };

  return (
    <FilterTab>
      <FilterTabColumns>
        <CategoryFiltersColumn
          isAllSelected={isAllSelected}
          selectAll={selectAll}
          checklist={checklist}
          selectItem={selectItem}
        />
        {allowInProgressFilter && !isReviewPage && (
          <FilterTabColumn>
            <Checkbox
              id="InProgress"
              defaultChecked={isInProgress}
              onChange={e => setIsInProgress(e.currentTarget.checked)}
            >
              In Progress
            </Checkbox>
          </FilterTabColumn>
        )}
        {setIsHighlightedFilter && (
          <FilterTabColumn>
            <Checkbox
              id="KeyFeatures"
              defaultChecked={isHighlighted}
              onChange={e => setIsHighlighted(e.currentTarget.checked)}
            >
              Key Features
            </Checkbox>
          </FilterTabColumn>
        )}
        {setIsReviewNotesFilter && (
          <FilterTabColumn>
            <Checkbox
              id="ReviewNotes"
              defaultChecked={isReviewNotes}
              onChange={e => setIsReviewNotes(e.currentTarget.checked)}
            >
              Review Notes
            </Checkbox>
          </FilterTabColumn>
        )}
        {VehicleTeam.AGENCY_SPANISH === name && setIsSyncUpdateFilter && !isPublished && (
          <FilterTabColumn>
            <Checkbox
              id="SyncUpdates"
              checked={isSyncUpdate}
              onChange={e => setIsSyncUpdate(e.currentTarget.checked)}
            >
              Sync Updates
            </Checkbox>
          </FilterTabColumn>
        )}
      </FilterTabColumns>
      <FilterTabFooter>
        <Button variant="primary" onClick={() => applyFilters()}>
          Apply Filters
        </Button>
      </FilterTabFooter>
    </FilterTab>
  );
};

export default Filters;
