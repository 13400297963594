import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../components/ChangeLogInner';
import useChangeLogChanges from '../../../../hooks/useChangeLogChanges';
import useStores from '../../../../hooks/useStores';
import {
  ChangeLogItem,
  ChangeLogLangMap,
  ChangeLogTypes,
} from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import {
  changeLogHandleAddedTypes,
  changeLogHandleDeleteTypes,
  changeLogIdMapper,
  changeLogModelApplicabilityMapper,
} from '../../../../utils/changeLogUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { filterOutUnsuableChanges } from '../../../../utils/gradeApplicabilityUtils';
import {
  getCategories,
  getChangeLog,
  revertChange,
} from '../../../../webservices/vehicleOptionsApi';
import { toGqlBrand, toGqlFilter, toGqlLanguage, toGqlTeam } from '../../../../utils/graphqlUtils';

interface OptionsChangeLogProps {
  seriesId: string;
  year: string;
  readOnly?: boolean;
  version: string;
}

const OptionsChangeLog = ({ seriesId, year, readOnly, version }: OptionsChangeLogProps) => {
  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
    optionsStore,
    vehicleModelsStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);
  const { filterChangeLogChanges, changeLogChanges, canRevert } = useChangeLogChanges(
    ChangeLogTypes.OPTION_STATUS,
    ChangeLogTypes.MODEL_APPLICABILITY_STATUS,
    ChangeLogTypes.ALL_OPTION_STATUS
  );

  useEffect(() => {
    changeLogStore.reset();

    (async () => {
      setIsLoaded(false);

      try {
        const selectedLangs: KeyValueType<boolean> = {};
        teamStore.team.changeLogLanguages.forEach(lang => {
          selectedLangs[lang] = true;
        });
        changeLogStore.selectedLangsMap = selectedLangs;

        const responses = await Promise.all(
          teamStore.team.changeLogLanguages.map(lang =>
            Promise.all([
              getChangeLog({
                brand: toGqlBrand(brand),
                team: toGqlTeam(teamStore.team.param),
                seriesId: seriesId,
                modelYear: parseInt(year),
                language: toGqlLanguage(lang),
                filter: toGqlFilter(version),
              }),
              getCategories({
                brand: toGqlBrand(brand),
                team: toGqlTeam(teamStore.team.param),
                seriesId: seriesId,
                modelYear: parseInt(year),
                language: toGqlLanguage(lang),
                filter: toGqlFilter(version),
                includeAll: true,
              }),
            ])
          )
        );
        let index = 0;
        const changeLogLangMap: ChangeLogLangMap = {};
        for (const lang of teamStore.team.changeLogLanguages) {
          const [changeLogResponse, categoriesResponse] = responses[index];
          const logs = changeLogResponse
            .map(item => new ChangeLogItem(item, !readOnly, lang))
            .filter(filterOutUnsuableChanges)
            .filter(filterChangeLogChanges);

          optionsStore.updateCategoriesLangMap(
            lang,
            optionsStore.categoriesMap,
            categoriesResponse
          );

          changeLogIdMapper(
            optionsStore.getCategoriesForLang(lang, optionsStore.categoriesMap),
            logs,
            ChangeLogTypes.CATEGORY
          );
          changeLogHandleDeleteTypes(logs, ChangeLogTypes.OPTION_DELETED);
          changeLogHandleAddedTypes(logs, ChangeLogTypes.OPTION_ADDED);
          changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
          changeLogLangMap[lang] = logs;

          index++;
        }

        changeLogStore.changeLogLangMap = changeLogLangMap;
      } catch (e) {
        toast.error('Error loading options change log');
      }

      setIsLoaded(true);
    })();
  }, [
    brand,
    changeLogStore,
    readOnly,
    seriesId,
    teamStore,
    year,
    optionsStore,
    version,
    vehicleModelsStore,
    filterChangeLogChanges,
  ]);

  const handleOnRevert = async (item: ChangeLogItem) => {
    setIsLoaded(false);
    try {
      const changeLogLangMap: ChangeLogLangMap = JSON.parse(
        JSON.stringify(changeLogStore.changeLogLangMap)
      );
      const language = item.language ?? Language.EN;
      const payload = item.payload;
      await trackPromise(
        revertChange({
          brand: toGqlBrand(brand),
          team: toGqlTeam(teamStore.team.param),
          seriesId: seriesId,
          modelYear: parseInt(year),
          language: toGqlLanguage(language),
          payload: {
            changeType: payload.changeType,
            id: payload.id,
            from: payload.from,
            parentId: payload.parentId,
            to: payload.to,
          },
        })
      );
      const response = await getChangeLog({
        brand: toGqlBrand(brand),
        team: toGqlTeam(teamStore.team.param),
        seriesId: seriesId,
        modelYear: parseInt(year),
        language: toGqlLanguage(language),
        filter: toGqlFilter(version),
      });
      const logs = response
        .map(item => new ChangeLogItem(item, !readOnly, language))
        .filter(filterChangeLogChanges);
      changeLogIdMapper(
        optionsStore.getCategoriesForLang(language, optionsStore.categoriesMap),
        logs,
        ChangeLogTypes.CATEGORY
      );
      changeLogHandleAddedTypes(logs, ChangeLogTypes.OPTION_ADDED);
      changeLogHandleDeleteTypes(logs, ChangeLogTypes.OPTION_DELETED);
      changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
      changeLogLangMap[language] = logs;
      changeLogStore.changeLogLangMap = changeLogLangMap;

      toast.success('Change log reverted');
    } catch (e) {
      handleErrorResponse(e, 'Error reverting change log');
    }
    setIsLoaded(true);
  };
  return (
    <ChangeLogInner
      module={'Option'}
      isLoaded={isLoaded}
      handleOnRevert={handleOnRevert}
      changeLogChanges={changeLogChanges}
      canRevert={canRevert}
    />
  );
};

export default observer(OptionsChangeLog);
