import { ChecklistItem } from 'vapi-ui-common/dist/src/hooks/useChecklist/useChecklist';
import { Language, UserPermissions } from './user.model';

export interface SeriesManagerVehicle {
  name: string;
  group?: string;
}

export interface Series {
  [key: string]: SeriesInfo;
}

export interface SeriesInfo {
  id: string;
  revId: string;
  name: string;
  group: SeriesCategories;
  sourceSeriesId?: string;
  sourceBrand?: string;
}

export interface UserSeries {
  id: string;
  revId: string;
  name: string;
}

export interface DashboardDetail {
  [key: string]: DashboardDetailData;
}

export interface DashboardDetailData {
  name: string;
  years: {
    [key: string]: DashboardDetailYear[];
  };
}

export interface DashboardDetailYear {
  year: number;
  isDraft: boolean;
  datePublished: string;
  version: number;
  isPendingVDReview?: boolean;
  isPendingCLReview?: boolean;
  isSubmitted?: boolean;
  notes?: string;
  vdStatus?: DashboardDetailStatus;
  previewDownstreamDate?: string;
  previewDownstreamVersion?: string;
  publishDownstreamDate?: string;
  publishDownstreamVersion?: number;
  createdDate?: string;
  englishPublishedDate?: string;
  englishVersion?: number;
  spanishVersion?: number;
  spanishPublishedDate?: string;
  key?: string;
  hasSourceUpdates?: { [lang: string]: boolean };
  sourceVersion?: number;
  sourceLang?: string;
  lastSyncDate?: string;
  outOfSync?: boolean;
  skippedLivePublish?: boolean;
  onlyStatusSyncUpdates?: boolean;
}

type DashboardDetailStatus = {
  currentStatus: VDStatus;
  modifiedDate: string;
  modifiedUser: string;
};

export enum VDStatus {
  APPROVED = 'Approved',
  SUBMITTED = 'Submitted',
  REJECTED = 'Rejected',
  PUBLISHED = 'Published',
  CERTIFIED = 'Certified',
}

export enum VDTab {
  SERIES_SETTINGS = 'Series Settings',
  MODELS = 'Models',
  FEATURES = 'Features',
  SPECS = 'Specs',
  OPTIONS = 'Options',
  BNP = 'B & P',
  COLORS = 'Colors',
  COMPARE_FEATURES = 'Compare View',
}

export enum VDColorFamiliesTab {
  COLOR_FAMILIES = 'Color Families',
  EXTERIOR = 'Exterior',
  INTERIOR = 'Interior',
}
export interface SeriesManagerVehicleChecklist {
  name: string;
  items: Array<SeriesManagerVehicleChecklistItem>;
}

export interface SeriesManagerVehicleChecklistItem extends ChecklistItem {
  id: string;
  selected: boolean;
  carImage: string;
  revId: string;
  group: string;
}

export interface DashboardVehicle {
  modelYear: string;
  seriesId: string;
  seriesName: string;
  group?: string;
}

export interface SeriesInfoItem {
  id: string;
  seriesName: string;
  permissions: UserPermissions;
  modelYears: DashboardDetailYear[];
}

export enum VehicleTeam {
  PRODUCT_TEAM = 'product-team',
  AGENCY_TEAM = 'agency-team',
  COMMON_LANGUAGE_TEAM = 'cl',
  AGENCY_SPANISH = 'agency-spanish',
}

export enum VehicleCategories {
  LARGE_SEDANS_VANS = 'Large Sedans/Vans',
  SEDANS_SPORTS_CARS = 'Sedans/Sports Cars',
  CROSSOVERS = 'Crossover Utility Vehicles',
  TRUCKS_SUVS = 'Trucks/SUVs',
  LEXUS_TRUCKS_SUVS = 'SUVs',
}

export enum SeriesCategories {
  TRUCKS_SUVS = 'Trucks/SUVs',
  LARGE_SEDANS_VANS = 'Large Sedans/Vans',
  CROSSOVERS = 'Crossover Utility Vehicles',
  SEDANS_SPORTS_CARS = 'Sedans/Sports Cars',
}

export type AdminVehicleData = {
  status: string;
  seriesId: string;
  modelYear: number;
  isPendingVDReview: boolean;
  isPendingCLReview: boolean;
  isSubmitted?: boolean;
  version?: string;
  lastPublishedVersion?: number;
  lastPublishedDate?: string;
  lastSyncDate?: string;
  hasSourceUpdates?: { [brand: string]: boolean };
};

export interface VersionResponse {
  version: string;
  seriesId: string;
  modelYear: number;
  isSubmitted?: boolean;
}

export type SortModel = {
  id: string;
  revId: string;
  sortOrder: number;
};

export interface SpanishDraftMap {
  [seriesId: string]: { [modelYear: number]: boolean };
}

export type VehicleDataVersionInfo = {
  [lang in Language]?: string | number;
};

export interface VDInProgressToPublish {
  seriesSettings?: Record<string, true>;
  models?: Record<string, true>;
  features?: Record<string, true>;
  specs?: Record<string, true>;
  options?: Record<string, true>;
  bnp?: Record<string, true>;
  exteriorColors?: Record<string, true>;
  interiorColors?: Record<string, true>;
  compareFeatures?: Record<string, true>;
}
