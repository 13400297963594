import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ActionButton, Checkbox } from 'vapi-ui-common';
import { LimitedDataStatus } from '../../../../../../components/LimitedDataStatus';
import { TableRowBase } from '../../../../../../components/Table/components/TableRow/TableRow';
import { LIMITED_DATA_STATUS } from '../../../../../../constants/vehicleData/VDConstants';
import useStores from '../../../../../../hooks/useStores';
import { FuelTypeItemResponse } from '../../../../../../models/fuelType.model';
import {
  FieldStatus,
  SeriesCategoryItem,
  SeriesSettingsLangMap,
  UpdateSeriesSettingsStatusRequest,
} from '../../../../../../models/seriesSettings.model';
import { BRAND_LEXUS, BRAND_TDPR, Brand, Language } from '../../../../../../models/user.model';
import SyncTdPRButton from '../../../../components/SyncTdPRButton/SyncTdPRButton';
import useLimitedDataStatus from '../../hooks/useLimitedDataStatus';
import styles from '../../seriesSettings.module.scss';
import ConvertibleColumn from './ConvertibleColumn';

interface SeriesSettingsRowBaseProps {
  brand: Brand;
  index: number;
  saveSeriesSetting?: (seriesSettingsLangMap: SeriesSettingsLangMap) => void;
  onDelete?: (seriesSetting: SeriesSettingsLangMap) => void;
  saveSubSeriesSetting?: (seriesSettingsLangMap: SeriesSettingsLangMap) => void;
  onDeleteSubSeriesSetting?: (seriesSetting: SeriesSettingsLangMap) => void;
  readOnly?: boolean;
  fuelTypesList: FuelTypeItemResponse[];
  seriesCategoriesList: SeriesCategoryItem[];
  canDeleteSetting: boolean;
  seriesSettingsLangMap: SeriesSettingsLangMap;
  defaultLang: Language;
  languages: Language[];
  compareSettings?: (settings: SeriesSettingsLangMap) => void;
  updateFieldStatus?: (
    payload: UpdateSeriesSettingsStatusRequest,
    setFieldStatus: (val: FieldStatus) => void
  ) => void;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPublished?: boolean;
  isSubmitted?: boolean;
}

const SeriesSettingsRowBase = ({
  brand,
  index,
  fuelTypesList,
  saveSeriesSetting = () => {},
  onDelete = () => void 0,
  saveSubSeriesSetting = () => {},
  onDeleteSubSeriesSetting = () => void 0,
  readOnly,
  seriesCategoriesList,
  canDeleteSetting,
  seriesSettingsLangMap,
  defaultLang,
  languages,
  compareSettings,
  updateFieldStatus,
  setIsEditOpen,
  isPublished,
  isSubmitted,
}: SeriesSettingsRowBaseProps) => {
  const isLexus = brand === BRAND_LEXUS;
  const defaultSeriesSettings = seriesSettingsLangMap[defaultLang];
  const [showMpge, setShowMpge] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [codeRed, setCodeRed] = useState(!defaultSeriesSettings.isPublishable);
  const { fieldStatus, handleStatusClick } = useLimitedDataStatus(defaultSeriesSettings);

  const { teamStore } = useStores();

  const handleOnDelete = () => {
    if (defaultSeriesSettings.parentId) {
      onDeleteSubSeriesSetting(seriesSettingsLangMap);
    } else {
      onDelete(seriesSettingsLangMap);
    }
  };

  const getNamesString = () => {
    const names = [] as string[];
    Object.values(seriesSettingsLangMap).forEach(item => {
      names.push(item.name);
    });
    return names.join(' | ');
  };

  const getFuelTypesString = () => {
    const fuelNames = [] as string[];
    Object.keys(defaultSeriesSettings.fuelTypes).forEach(type => {
      fuelTypesList.forEach(item => {
        if (item.id === type) {
          fuelNames.push(item.type);
        }
      });
    });
    return fuelNames.join(', ') || '--';
  };

  const getSeriesCategoriesString = () => {
    const categories = [] as string[];
    Object.keys(defaultSeriesSettings.seriesCategories).forEach(type => {
      seriesCategoriesList.forEach(item => {
        if (item.id === type) {
          categories.push(item.name);
        }
      });
    });
    return categories.join(', ') || '--';
  };

  const checkFuelTypeForMpge = () => {
    const fuelNames = [] as string[];
    Object.keys(defaultSeriesSettings.fuelTypes).forEach(type => {
      fuelTypesList.forEach(item => {
        if (item.id === type) {
          fuelNames.push(item.type);
        }
      });
    });
    setShowMpge(fuelNames.includes('Plug-in Hybrid') || fuelNames.includes('Fuel Cell'));
    return showMpge ? defaultSeriesSettings.mpge : '--';
  };

  const getRangeOrMileage = () => {
    const mileage = defaultSeriesSettings.estimatedMileage;
    const range = defaultSeriesSettings.range;
    const fuels = [] as string[];
    Object.keys(defaultSeriesSettings.fuelTypes).forEach(type => {
      fuelTypesList.forEach(item => {
        if (item.id === type) {
          fuels.push(item.type);
        }
      });
    });
    return (fuels.includes('Battery Electric') ? range : mileage) || '--';
  };

  const showSyncTdPR =
    brand === BRAND_TDPR && compareSettings && defaultSeriesSettings.fromTMNA && !readOnly;

  const changedAttributes = () => {
    const changed: string[] = [];
    languages.forEach(lang => {
      const settings = seriesSettingsLangMap[lang];
      if (settings) {
        changed.push(...settings.changedAttributes);
      }
    });
    return changed;
  };

  const displayLimitedDataStatus =
    teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';
  const disableLimitedDataStatus = isPublished || isSubmitted;

  return (
    <Draggable draggableId={defaultSeriesSettings.uid} index={index}>
      {(provided, snapshot) => (
        <TableRowBase
          zebra
          onMouseEnter={() => setShowIcons(true)}
          onMouseLeave={() => setShowIcons(false)}
          className={styles.seriesSettingRowCollapsed}
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
        >
          <td>
            <Checkbox
              id={`CodeRed-${defaultSeriesSettings.uid}`}
              checked={codeRed}
              disabled={readOnly}
              className={styles.isPublishableChbox}
              onChange={e => {
                setCodeRed(e.currentTarget.checked);
                for (const lang of Object.keys(seriesSettingsLangMap)) {
                  seriesSettingsLangMap[lang].isPublishable = !e.currentTarget.checked;
                }
                if (defaultSeriesSettings.isSubSeries) {
                  saveSubSeriesSetting(seriesSettingsLangMap);
                } else {
                  saveSeriesSetting(seriesSettingsLangMap);
                }
              }}
            ></Checkbox>
          </td>

          <td>
            <div className={styles.seriesName}>
              <span>
                {getNamesString()}
                {defaultSeriesSettings.isSubSeries ? '(Sub Series)' : ''}
              </span>
              {showSyncTdPR && (
                <SyncTdPRButton
                  id={defaultSeriesSettings.id}
                  onClick={() => {
                    compareSettings(seriesSettingsLangMap);
                  }}
                  changedAttributes={changedAttributes()}
                />
              )}
            </div>
          </td>

          <td>{getFuelTypesString()}</td>

          <td>{getSeriesCategoriesString()}</td>

          <ConvertibleColumn
            readOnly
            convertible={defaultSeriesSettings.convertible}
            dataTestId={`Convertible-${defaultSeriesSettings.uid}`}
          />

          {!isLexus && (
            <td>
              <div className={styles.seatingCol}>
                {defaultSeriesSettings.seating || '--'}
                <LimitedDataStatus
                  status={fieldStatus?.seating}
                  onClick={
                    disableLimitedDataStatus ||
                    fieldStatus?.seating === LIMITED_DATA_STATUS.PUBLISHED
                      ? undefined
                      : handleStatusClick('seating', updateFieldStatus)
                  }
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                  clickOverride={true}
                />
              </div>
            </td>
          )}

          {!isLexus && (
            <td>
              <div className={styles.mileageCol}>
                {getRangeOrMileage()}
                <LimitedDataStatus
                  status={fieldStatus?.estimatedMileage}
                  onClick={
                    disableLimitedDataStatus ||
                    fieldStatus?.estimatedMileage === LIMITED_DATA_STATUS.PUBLISHED
                      ? undefined
                      : handleStatusClick('estimatedMileage', updateFieldStatus)
                  }
                  hideLimitedDataStatus={!displayLimitedDataStatus}
                  clickOverride={true}
                />
              </div>
            </td>
          )}

          {!isLexus && (
            <td>
              <div className={styles.mpgCol}>
                {checkFuelTypeForMpge()}
                {showMpge && (
                  <LimitedDataStatus
                    status={fieldStatus?.mpge}
                    onClick={
                      disableLimitedDataStatus ||
                      fieldStatus?.mpge === LIMITED_DATA_STATUS.PUBLISHED
                        ? undefined
                        : handleStatusClick('mpge', updateFieldStatus)
                    }
                    hideLimitedDataStatus={!displayLimitedDataStatus}
                    clickOverride={true}
                  />
                )}
              </div>
            </td>
          )}

          <td className={styles.ctaCol}>
            {!readOnly && showIcons && (
              <>
                {canDeleteSetting && <ActionButton icon="trash" onClick={() => handleOnDelete()} />}
                <ActionButton icon="edit" onClick={() => setIsEditOpen(true)} />
              </>
            )}
          </td>
        </TableRowBase>
      )}
    </Draggable>
  );
};

export default observer(SeriesSettingsRowBase);
