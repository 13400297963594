import React, { useEffect } from 'react';
import VehicleFilterInner from '../../../../components/VehicleFilter';
import useChecklist from '../../../../hooks/useChecklist';
import {
  CommonLanguageVehicle,
  CommonLanguageVehicleChecklist,
} from '../../../../models/commonLanguage.model';

interface VehicleFilterProps {
  vehicleModels: CommonLanguageVehicle[];
  vehicleFilters: string[];
  setVehicles: (filters: string[]) => void;
}

const VehicleFilter = ({ vehicleModels, vehicleFilters, setVehicles }: VehicleFilterProps) => {
  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } = useChecklist();

  useEffect(() => {
    const vehiclesChecklist: CommonLanguageVehicleChecklist[] = [];

    vehicleModels.forEach(model => {
      // fetch/add category;
      let category = vehiclesChecklist.filter(listItem => listItem.name === model.group)[0];
      if (!category) {
        category = {
          name: model.group as string,
          selected: true,
          items: [],
        };
        vehiclesChecklist.push(category);
      }

      // fetch/add series
      if (!category.items.filter(item => item.id === model.seriesId).length) {
        const selected = vehicleFilters.indexOf(model.seriesId) > -1;
        category.items.push({
          id: model.seriesId,
          name: model.seriesName,
          selected,
        });
        if (!selected) {
          category.selected = false;
        }
      }
    });

    setChecklist(vehiclesChecklist);
  }, [setChecklist, vehicleFilters, vehicleModels]);

  const handleOnChecked = (item: any, checked: boolean) => {
    selectItem(item, checked);
    setVehicles(processFilters());
  };

  const handleOnAllChecked = (checked: boolean) => {
    selectAll(checked);
    setVehicles(processFilters());
  };

  const processFilters = () => {
    const filters: string[] = [];

    checklist.forEach(category => {
      if (category.items) {
        category.items.forEach(item => {
          if (item.selected) {
            filters.push(item.id);
          }
        });
      }
    });

    return filters;
  };

  return (
    <VehicleFilterInner
      isAllSelected={isAllSelected}
      handleOnAllChecked={handleOnAllChecked}
      checklist={checklist}
      handleOnChecked={handleOnChecked}
    />
  );
};

export default VehicleFilter;
