import { observer } from 'mobx-react-lite';
import React from 'react';
import Checkbox, { CheckboxLabel } from '../../../../../../../components/Checkbox';
import Input from '../../../../../../../components/Input';
import NotesPopover from '../../../../../../../components/NotesPopover';
import { TableCell, TableRow } from '../../../../../../../components/Table';
import { ColorsLexusExteriorItem } from '../../../../../../../models/colorsLexus.model';
import { ReviewChangeBaseItem } from '../../../../../../../models/review.model';
import ExtraCostPopover from '../../../../../../../routes/vehicleData/components/ExtraCostPopover';
import { AcceptApplyCell } from '../../../../../../../routes/vehicleData/components/tableCells/AcceptApplyCell';
import ColorsLexusReviewChanges from '../ColorsLexusReviewChanges/ColorsLexusReviewChanges';
import styles from './colorsLexusExteriorRow.module.scss';

interface ColorsLexusExteriorRowProps {
  exterior: ColorsLexusExteriorItem;
  change: ReviewChangeBaseItem;
  updateItem: (exterior: ColorsLexusExteriorItem, change: ReviewChangeBaseItem) => void;
  hideAccept: boolean;
}

const ColorsLexusExteriorRow = ({
  exterior,
  change,
  updateItem,
  hideAccept,
}: ColorsLexusExteriorRowProps) => {
  const handleOnAcceptChange = async (value: boolean) => {
    change.isAccepted = value;
    updateItem(exterior, change);
  };

  const handleOnApplyChange = async (value: boolean) => {
    change.isApplied = value;
    updateItem(exterior, change);
  };

  const handleOnNotesChange = async (notes: string) => {
    change.rejectNotes = notes;
    updateItem(exterior, change);
  };

  return (
    <TableRow
      zebra
      className={styles.row}
      onFillRowHeightChange={height => (change.rowHeight = height)}
    >
      <TableCell className={styles.cell}>
        <Input
          className={styles.codeInput}
          defaultValue={exterior.code}
          placeholder="Code"
          disabled
        />
      </TableCell>
      <TableCell className={styles.cell}>
        <Input
          className={styles.descriptionInput}
          defaultValue={exterior.name}
          placeholder="Name"
          disabled
        />
      </TableCell>
      <TableCell className={styles.cell}>
        <ExtraCostPopover
          msrp={exterior.isExtraCost}
          readOnly
          popoverClass={styles.msrpPopoverClass}
        />
      </TableCell>
      <TableCell className={styles.cell}>
        <NotesPopover
          notes={exterior.notes}
          readOnly
          handleUpdateItem={() => void 0}
          textButtonClassName={styles.notes}
          popoverClass={styles.notesPopoverClass}
          toggleElement={
            <Checkbox
              id={`notes-${exterior.uid}`}
              checked={!!exterior.notes}
              onChange={() => void 0}
              theme="greenOutline"
              small
            >
              <CheckboxLabel small>Notes</CheckboxLabel>
            </Checkbox>
          }
        />
        <Checkbox
          id={`inprogress-${exterior.uid}`}
          checked={exterior.isInProgress}
          disabled
          theme="redOutline"
          small
        >
          <CheckboxLabel small>In Progress</CheckboxLabel>
        </Checkbox>
      </TableCell>
      <TableCell colType="changes" whiteBg border>
        <ColorsLexusReviewChanges changes={change.changes} />
        {exterior.otherChanges.map(otherChange => (
          <React.Fragment key={otherChange.uid}>
            <p />
            <ColorsLexusReviewChanges changes={otherChange} />
          </React.Fragment>
        ))}
      </TableCell>
      <AcceptApplyCell
        id={`change-row-${change.uid}`}
        acceptChecked={change.isAccepted}
        applyChecked={change.isApplied}
        notes={change.rejectNotes}
        onAcceptChecked={hideAccept ? undefined : handleOnAcceptChange}
        onApplyChecked={handleOnApplyChange}
        onNotesChange={handleOnNotesChange}
        isNewChange={change.isNewChange}
      />
    </TableRow>
  );
};

export default observer(ColorsLexusExteriorRow);
