import React from 'react';
import { TableRowBase } from '../../../components/Table/components/TableRow/TableRow';
import { SeriesList } from '../../../models/seriesCategories.model';
import styles from './seriesDetails.module.scss';

interface SeriesListProps {
  list: SeriesList[];
}

const SeriesDetails = ({ list }: SeriesListProps) => {
  return (
    <>
      {!list.length ? (
        <>
          <TableRowBase zebra className={styles.row}>
            <div className={styles.empty}>No Series In Category</div>
          </TableRowBase>
        </>
      ) : (
        <>
          {list.map((series, ind) => (
            <TableRowBase zebra key={ind} className={styles.row}>
              <div className={styles.year}>{series.seriesYear}</div>
              <div className={styles.series}>{series.seriesName}</div>
            </TableRowBase>
          ))}
        </>
      )}
    </>
  );
};

export default SeriesDetails;
