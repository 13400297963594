import moment from 'moment-timezone';

const SERVER_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A z';
export const SERVER_GO_LIVE_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

/**
 * Convert Server Date to Local Date
 * @returns string in ISO format
 * @param date
 */
export const convertServerDateToLocalDate = (date: string) => {
  return date ? moment.utc(date, SERVER_TIME_FORMAT).local().format() : date;
};

/**
 * Convert Date to String Date with custom format
 * @returns string date with custom format
 * @param date
 * @param format
 */
export const formatDate = (date: Date, format: string) => moment(date).format(format);

export const isPastDate = (date: string) => {
  return moment(new Date(date)).isBefore();
};

export const isFutureDate = (date: string) => {
  return moment(new Date(date)).isAfter();
};

export enum GO_LIVE_DATE_TIME_TEXT {
  AM_12 = '12am',
  AM_1 = '1am',
  AM_2 = '2am',
  AM_3 = '3am',
  AM_4 = '4am',
  AM_5 = '5am',
  AM_6 = '6am',
  AM_7 = '7am',
  AM_8 = '8am',
  AM_9 = '9am',
  AM_10 = '10am',
  AM_11 = '11am',
  PM_12 = '12pm',
  PM_1 = '1pm',
  PM_2 = '2pm',
  PM_3 = '3pm',
  PM_4 = '4pm',
  PM_5 = '5pm',
  PM_6 = '6pm',
  PM_7 = '7pm',
  PM_8 = '8pm',
  PM_9 = '9pm',
  PM_10 = '10pm',
  PM_11 = '11pm',
}

export enum GO_LIVE_DATE_TIME {
  AM_12 = 0,
  AM_1 = 1,
  AM_2 = 2,
  AM_3 = 3,
  AM_4 = 4,
  AM_5 = 5,
  AM_6 = 6,
  AM_7 = 7,
  AM_8 = 8,
  AM_9 = 9,
  AM_10 = 10,
  AM_11 = 11,
  PM_12 = 12,
  PM_1 = 13,
  PM_2 = 14,
  PM_3 = 15,
  PM_4 = 16,
  PM_5 = 17,
  PM_6 = 18,
  PM_7 = 19,
  PM_8 = 20,
  PM_9 = 21,
  PM_10 = 22,
  PM_11 = 23,
}

export interface GoLiveDateTime {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  text: GO_LIVE_DATE_TIME_TEXT;
}

export type GoLiveDateTimeMap = {
  [key in GO_LIVE_DATE_TIME]: GoLiveDateTime;
};

export const goLiveDateTimeMap: GoLiveDateTimeMap = {
  [GO_LIVE_DATE_TIME.AM_12]: {
    hours: GO_LIVE_DATE_TIME.AM_12,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_12,
  },
  [GO_LIVE_DATE_TIME.AM_1]: {
    hours: GO_LIVE_DATE_TIME.AM_1,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_1,
  },
  [GO_LIVE_DATE_TIME.AM_2]: {
    hours: GO_LIVE_DATE_TIME.AM_2,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_2,
  },
  [GO_LIVE_DATE_TIME.AM_3]: {
    hours: GO_LIVE_DATE_TIME.AM_3,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_3,
  },
  [GO_LIVE_DATE_TIME.AM_4]: {
    hours: GO_LIVE_DATE_TIME.AM_4,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_4,
  },
  [GO_LIVE_DATE_TIME.AM_5]: {
    hours: GO_LIVE_DATE_TIME.AM_5,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_5,
  },
  [GO_LIVE_DATE_TIME.AM_6]: {
    hours: GO_LIVE_DATE_TIME.AM_6,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_6,
  },
  [GO_LIVE_DATE_TIME.AM_7]: {
    hours: GO_LIVE_DATE_TIME.AM_7,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_7,
  },
  [GO_LIVE_DATE_TIME.AM_8]: {
    hours: GO_LIVE_DATE_TIME.AM_8,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_8,
  },
  [GO_LIVE_DATE_TIME.AM_9]: {
    hours: GO_LIVE_DATE_TIME.AM_9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_9,
  },
  [GO_LIVE_DATE_TIME.AM_10]: {
    hours: GO_LIVE_DATE_TIME.AM_10,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_10,
  },
  [GO_LIVE_DATE_TIME.AM_11]: {
    hours: GO_LIVE_DATE_TIME.AM_11,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.AM_11,
  },
  [GO_LIVE_DATE_TIME.PM_12]: {
    hours: GO_LIVE_DATE_TIME.PM_12,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_12,
  },
  [GO_LIVE_DATE_TIME.PM_1]: {
    hours: GO_LIVE_DATE_TIME.PM_1,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_1,
  },
  [GO_LIVE_DATE_TIME.PM_2]: {
    hours: GO_LIVE_DATE_TIME.PM_2,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_2,
  },
  [GO_LIVE_DATE_TIME.PM_3]: {
    hours: GO_LIVE_DATE_TIME.PM_3,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_3,
  },
  [GO_LIVE_DATE_TIME.PM_4]: {
    hours: GO_LIVE_DATE_TIME.PM_4,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_4,
  },
  [GO_LIVE_DATE_TIME.PM_5]: {
    hours: GO_LIVE_DATE_TIME.PM_5,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_5,
  },
  [GO_LIVE_DATE_TIME.PM_6]: {
    hours: GO_LIVE_DATE_TIME.PM_6,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_6,
  },
  [GO_LIVE_DATE_TIME.PM_7]: {
    hours: GO_LIVE_DATE_TIME.PM_7,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_7,
  },
  [GO_LIVE_DATE_TIME.PM_8]: {
    hours: GO_LIVE_DATE_TIME.PM_8,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_8,
  },
  [GO_LIVE_DATE_TIME.PM_9]: {
    hours: GO_LIVE_DATE_TIME.PM_9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_9,
  },
  [GO_LIVE_DATE_TIME.PM_10]: {
    hours: GO_LIVE_DATE_TIME.PM_10,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_10,
  },
  [GO_LIVE_DATE_TIME.PM_11]: {
    hours: GO_LIVE_DATE_TIME.PM_11,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GO_LIVE_DATE_TIME_TEXT.PM_11,
  },
};
