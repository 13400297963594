import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
  ActionItemModal,
  convertToRichTextObject,
  Modal,
  MultiLineRTEditor,
  SingleLineRTEditor,
  SuggestionType,
} from 'vapi-ui-common';
import Checkbox from '../../../../../../../../components/Checkbox';
import IconTextButton from '../../../../../../../../components/IconTextButton';
import NotesPopover from '../../../../../../../../components/NotesPopover';
import { OptionsPackage, OptionsTabType } from '../../../../../../../../models/optionsLexus.model';
import styles from './optionsLexusPackageNameColumn.module.scss';

interface OptionsLexusPackageNameColumnProps {
  optionsPackage: OptionsPackage;
  optionsTab?: OptionsTabType;
  disabled?: boolean;
  handleOnNotesChange?: (value: string) => void;
  handleToggleInProgress?: (checked: boolean) => void;
  handleToggleTooltip?: (checked: boolean) => void;
  handleOnNameChange?: (value: string) => void;
  handleOnDescriptionChange?: (value: string) => void;
  addEmptySpec?: () => void;
  addEmptyVariety?: () => void;
  deleteOption?: () => void;
  tokens?: SuggestionType[];
  showTooltip?: boolean;
}

const OptionsLexusPackageNameColumn = ({
  optionsPackage,
  optionsTab,
  disabled,
  handleOnNotesChange = () => void 0,
  handleToggleInProgress = () => void 0,
  handleToggleTooltip = () => void 0,
  handleOnNameChange = () => void 0,
  handleOnDescriptionChange = () => void 0,
  addEmptySpec = () => void 0,
  addEmptyVariety = () => void 0,
  deleteOption = () => void 0,
  tokens,
  showTooltip,
}: OptionsLexusPackageNameColumnProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <>
      {/* Notes */}
      <NotesPopover
        notes={optionsPackage.notes}
        handleUpdateItem={value => handleOnNotesChange(value)}
        align="left"
        readOnly={disabled}
      />

      {/* In Progress */}
      {!disabled && (
        <Checkbox
          id={`inprogress-${optionsPackage.uid}`}
          className={styles.checkbox}
          checked={optionsPackage.isInProgress}
          onChange={e => handleToggleInProgress(e.currentTarget.checked)}
        >
          <span className={styles.checkboxLabel}>In Progress</span>
        </Checkbox>
      )}
      {disabled && optionsPackage.isInProgress && (
        <IconTextButton smallIcon icon="redCircle" text="In progress" />
      )}

      {/* Tooltip */}
      {showTooltip && !disabled && (
        <Checkbox
          id={`tooltip-${optionsPackage.uid}`}
          className={styles.checkbox}
          checked={optionsPackage.tooltip}
          onChange={e => handleToggleTooltip(e.currentTarget.checked)}
        >
          <span className={styles.checkboxLabel}>Tooltip</span>
        </Checkbox>
      )}
      {showTooltip && disabled && optionsPackage.tooltip && (
        <IconTextButton smallIcon icon="circle" text="Tooltip" />
      )}

      {/* Name */}
      <SingleLineRTEditor
        editorStyles={cx(styles.nameInput, disabled && styles.disabled)}
        disabled={disabled}
        value={optionsPackage.name}
        onBlur={value => handleOnNameChange(value)}
        suggestionTypes={tokens}
        placeholder="Add Name"
      />

      {/* Description */}
      <MultiLineRTEditor
        editorStyles={cx(styles.descriptionInput, disabled && styles.disabled)}
        disabled={disabled}
        value={optionsPackage.description}
        onBlur={value => handleOnDescriptionChange(value)}
        suggestionTypes={tokens}
        placeholder="Add Description"
      />

      {/* Add Spec */}
      {!disabled && optionsPackage.id && (
        <div>
          <IconTextButton smallIcon icon="plus" text="Add Spec" onClick={() => addEmptySpec()} />
        </div>
      )}

      {/* Add Package Variety  */}
      {!disabled && !optionsPackage.isOption && optionsPackage.id && (
        <div>
          <IconTextButton
            smallIcon
            icon="random"
            text="Package Variety"
            onClick={() => addEmptyVariety()}
          />
        </div>
      )}

      {/* Delete Package */}
      {!disabled && (
        <div>
          <IconTextButton
            smallIcon
            icon="trash"
            text={`Delete ${optionsTab === 'options' ? 'Option' : 'Package'}`}
            onClick={() => setOpenDeleteModal(true)}
          />
        </div>
      )}
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <ActionItemModal
          onClose={() => setOpenDeleteModal(false)}
          description={convertToRichTextObject(optionsPackage.name).text}
          onConfirm={() => deleteOption()}
          itemType={optionsTab === 'options' ? 'Option' : 'Package'}
        />
      </Modal>

      {optionsPackage.rejectNotes && (
        <NotesPopover
          notes={optionsPackage.rejectNotes}
          handleUpdateItem={() => void 0}
          align="left"
          icon="purpleCircle"
          iconText="Review"
          readOnly
        />
      )}
    </>
  );
};

export default observer(OptionsLexusPackageNameColumn);
