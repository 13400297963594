import ChangeLogChanges, {
  ChangeLogBeforeAfter,
} from '../../../../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import { ChangeLogBase, ChangeLogTypes } from '../../../../../../../models/changeLog.model';
import React from 'react';
import { FlexContainer } from 'vapi-ui-common';
import styles from './colorsLexusReviewChanges.module.scss';

interface ColorsLexusReviewChangesProps {
  changes: ChangeLogBase;
}

const ColorsLexusReviewChanges = ({ changes }: ColorsLexusReviewChangesProps) => {
  const LabeledChanges = (props: { label: string; before: string; after: string }) => {
    const { label, before, after } = props;
    return (
      <FlexContainer>
        <label style={{ marginRight: '4px' }}>{label}:</label>
        <ChangeLogBeforeAfter inline before={before} after={after} />
      </FlexContainer>
    );
  };

  const ChangeFromChanges = (props: { label: string; before: string; after: string }) => {
    const { label, before, after } = props;
    return (
      <FlexContainer>
        <div>
          {label} changed from
          <span className={styles.beforeText}> {before}</span> to{' '}
          <span className={styles.afterText}>{after}</span>
        </div>
      </FlexContainer>
    );
  };

  switch (changes.changeType) {
    case ChangeLogTypes.EXT_COLOR_ADDED: {
      return <div>Exterior Color Added</div>;
    }

    case ChangeLogTypes.EXT_COLOR_DELETED: {
      return <div>Exterior Color Deleted</div>;
    }

    case ChangeLogTypes.INT_COLOR_ADDED: {
      return <div>Interior Color Added</div>;
    }

    case ChangeLogTypes.INT_COLOR_DELETED: {
      return <div>Interior Color Deleted</div>;
    }

    case ChangeLogTypes.INT_COLOR_GROUP_APPLICABILITY: {
      return (
        <div>
          <span>Group Applicability:</span>
          <ChangeLogBeforeAfter before={changes.beforeValue} after={changes.afterValue} />
        </div>
      );
    }

    case ChangeLogTypes.MATERIAL_CHANGED:
    case ChangeLogTypes.MATERIALS: {
      return <ChangeFromChanges label="Material" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.INTERIOR: {
      return (
        <ChangeFromChanges label="Interior Type" before={changes.before} after={changes.after} />
      );
    }

    case ChangeLogTypes.INTERIOR_ID: {
      return (
        <ChangeFromChanges label="Interior ID" before={changes.before} after={changes.after} />
      );
    }

    case ChangeLogTypes.ORNAMENT_1: {
      return (
        <ChangeFromChanges label="Ornament #1" before={changes.before} after={changes.after} />
      );
    }

    case ChangeLogTypes.ORNAMENT_1_ID: {
      return (
        <ChangeFromChanges label="Ornament #1 ID" before={changes.before} after={changes.after} />
      );
    }

    case ChangeLogTypes.ORNAMENT_2: {
      return (
        <ChangeFromChanges label="Ornament #1" before={changes.before} after={changes.after} />
      );
    }

    case ChangeLogTypes.ORNAMENT_2_ID: {
      return (
        <ChangeFromChanges label="Ornament #1 ID" before={changes.before} after={changes.after} />
      );
    }

    case ChangeLogTypes.CARPET: {
      return <ChangeFromChanges label="Carpet" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.CC_ARMREST: {
      return (
        <ChangeFromChanges label="CC / Armrest" before={changes.before} after={changes.after} />
      );
    }

    case ChangeLogTypes.HEADLINER: {
      return <ChangeFromChanges label="Headliner" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.SEAT_ACCENT: {
      return (
        <ChangeFromChanges label="Seat Accent" before={changes.before} after={changes.after} />
      );
    }

    case ChangeLogTypes.IP: {
      return <ChangeFromChanges label="IP" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.EXT_COLOR_EXTRA_COST: {
      return (
        <LabeledChanges
          label="Extra Cost"
          before={String(changes.before)}
          after={String(changes.after)}
        />
      );
    }

    case ChangeLogTypes.EXT_COLOR_IN_PROGRESS: {
      return (
        <LabeledChanges
          label="In Progress"
          before={String(changes.before)}
          after={String(changes.after)}
        />
      );
    }

    case ChangeLogTypes.EXT_COLOR_CODE: {
      return <LabeledChanges label="Code" before={changes.before} after={changes.after} />;
    }

    case ChangeLogTypes.EXT_COLOR_NAME: {
      return <LabeledChanges label="Name" before={changes.before} after={changes.after} />;
    }

    default: {
      return <ChangeLogChanges changeItem={changes} />;
    }
  }
};

export default ColorsLexusReviewChanges;
