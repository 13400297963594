import cx from 'clsx';
import React from 'react';
import { LIMITED_DATA_STATUS } from '../../constants/vehicleData/VDConstants';
import { LimitedDataStatus } from '../LimitedDataStatus';
import styles from './multiLimitedData.module.scss';

export interface MultiLimitedDataProps {
  className?: string;
  statuses: LIMITED_DATA_STATUS[];
  hideLimitedDataStatus?: boolean;
  hideTotal?: boolean;
}

const MultiLimitedData = ({
  className,
  statuses,
  hideLimitedDataStatus,
  hideTotal,
}: MultiLimitedDataProps) => {
  if (hideLimitedDataStatus) return null;

  const getList = (status: LIMITED_DATA_STATUS) => {
    const list = statuses.filter(item => item === status);

    if (!list.length) {
      return null;
    }

    return (
      <div className={styles.statusDisplay}>
        <LimitedDataStatus total={list.length} status={status} hideTotal={hideTotal} />
      </div>
    );
  };

  return (
    <div className={cx(styles.container, className)} data-testid="multi-limited-data">
      {getList(LIMITED_DATA_STATUS.READY_TO_PUBLISH)}
      {getList(LIMITED_DATA_STATUS.IN_PROGRESS)}
      {getList(LIMITED_DATA_STATUS.PUBLISHED)}
    </div>
  );
};

export default MultiLimitedData;
