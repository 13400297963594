import cx from 'clsx';
import React from 'react';
import Checkbox from '../../../../../components/Checkbox';
import IconTextButton from '../../../../../components/IconTextButton';
import { LimitedDataStatus } from '../../../../../components/LimitedDataStatus';
import NotesPopover from '../../../../../components/NotesPopover';
import { TableCell } from '../../../../../components/Table';
import { LIMITED_DATA_STATUS } from '../../../../../constants/vehicleData/VDConstants';
import ExtraCostPopover from '../../ExtraCostPopover';
import CompareFeatureAction from './components/CompareFeatureAction';
import HighlightFeatureAction from './components/HighlightFeatureAction';
import styles from './flagsCell.module.scss';

type Props = {
  displayInProgressCheckBox?: boolean;
  allowGradeApplicability?: boolean;
  toggleInProgress?: () => void;
  toggleHighlighted?: () => void;
  toggleCompareArrow?: () => void;
  displayHighlighted?: boolean;
  onNotesChange?: (notes: string) => void;
  unlinkCompareFeatureItem?: (shouldDelete: boolean) => void;
  highlighted: boolean;
  inProgress: boolean;
  notes: string;
  index: number | string;
  disabled?: boolean;
  onExtraCostChange?: (msrp: string) => void;
  rejectNotes?: string;
  msrp?: string;
  isCompareHighlighted?: boolean;
  allowCompareFeature?: boolean;
  allowUnlinkDeleteModal?: boolean;
  type?: string;
  description?: string;
  canEditRejectNotes?: boolean;
  rejectNotesButtonText?: string;
  badgeText?: string;
  displayLimitedDataStatus?: boolean;
  limitedDataStatus?: LIMITED_DATA_STATUS;
  onClickLimitedDataStatus?: (val: LIMITED_DATA_STATUS) => void;
  hideInProgress?: boolean;
  keyFeatureComponent?: React.ReactNode;
} & React.HTMLAttributes<HTMLTableCellElement>;

const FlagsCell: React.FC<Props> = ({
  notes,
  index,
  className,
  allowGradeApplicability,
  inProgress,
  highlighted,
  onNotesChange = () => {},
  toggleInProgress = () => {},
  toggleHighlighted = () => {},
  toggleCompareArrow,
  unlinkCompareFeatureItem,
  displayHighlighted = true,
  displayInProgressCheckBox = true,
  disabled,
  onExtraCostChange = () => {},
  rejectNotes,
  msrp,
  isCompareHighlighted = false,
  allowCompareFeature = false,
  allowUnlinkDeleteModal = false,
  description = '',
  type = '',
  canEditRejectNotes,
  rejectNotesButtonText = 'Review',
  badgeText,
  displayLimitedDataStatus,
  limitedDataStatus,
  onClickLimitedDataStatus,
  hideInProgress,
  keyFeatureComponent,
}) => {
  return (
    <TableCell colType="flags" className={cx(styles.flagsCellWrapper, className)}>
      <div className={styles.flagsCell}>
        {(msrp || msrp === '') && (
          <ExtraCostPopover
            msrp={msrp}
            handleUpdateItem={onExtraCostChange}
            readOnly={disabled}
            popoverClass={styles.msrpPopoverClass}
          />
        )}
        {badgeText && <div className={styles.badgeText}>{badgeText}</div>}
        <LimitedDataStatus
          className={cx(styles.limitedDataStatus)}
          hideLimitedDataStatus={!displayLimitedDataStatus}
          status={limitedDataStatus}
          onClick={disabled ? undefined : onClickLimitedDataStatus}
        />
        <NotesPopover
          notes={notes}
          readOnly={disabled}
          handleUpdateItem={onNotesChange}
          textButtonClassName={styles.notes}
          iconTextButtonTextClassName={styles.textClassName}
          popoverClass={styles.notesPopoverClass}
          iconClassNameContainer={styles.iconClassNameContainer}
        />
        {displayInProgressCheckBox && !disabled && !hideInProgress && (
          <Checkbox
            id={`inprogress-${index}`}
            className={styles.checkbox}
            checked={inProgress}
            onChange={toggleInProgress}
          >
            <span className={styles.checkboxLabel}>In progress</span>
          </Checkbox>
        )}
        {disabled && inProgress && !hideInProgress && (
          <IconTextButton smallIcon icon="redCircle" text="In progress" className={styles.notes} />
        )}
        {(rejectNotes || canEditRejectNotes) && (
          <NotesPopover
            notes={rejectNotes || ''}
            icon="purpleCircle"
            iconText={rejectNotesButtonText}
            readOnly={!canEditRejectNotes}
            handleUpdateItem={onNotesChange}
            textButtonClassName={styles.notes}
            popoverClass={styles.notesPopoverClass}
            title="Reject Notes"
            iconTextButtonTextClassName={styles.textClassName}
            iconClassNameContainer={styles.iconClassNameContainer}
          />
        )}

        {keyFeatureComponent}
      </div>

      {allowGradeApplicability && displayHighlighted && (!disabled || highlighted) && (
        <HighlightFeatureAction
          disabled={disabled}
          highlighted={highlighted}
          onClick={toggleHighlighted}
        />
      )}

      {allowCompareFeature && allowUnlinkDeleteModal && (
        <CompareFeatureAction
          popoverClass={styles.comparePopoverContainer}
          unlinkCompareFeatureItem={unlinkCompareFeatureItem}
          highlighted={isCompareHighlighted}
          onClick={() => {}}
          allowUnlinkDeleteModal={isCompareHighlighted && !disabled}
          type={type}
          description={description}
        />
      )}

      {allowCompareFeature && toggleCompareArrow && !allowUnlinkDeleteModal && (
        <CompareFeatureAction
          highlighted={isCompareHighlighted}
          onClick={toggleCompareArrow}
          type={type}
          description={description}
        />
      )}
    </TableCell>
  );
};

export default FlagsCell;
