import { IDValueType } from '../../../../../models/common.model';
import { ReviewChangeBaseItem } from '../../../../../models/review.model';
import React from 'react';
import { PackageTotalCell, PackageTotalRow } from '../components/totalRows';

export const renderTotalApplicabilityReview = (
  changes: ReviewChangeBaseItem[],
  applicability: IDValueType[],
  isRequired?: boolean,
  rowHeight?: number
) => {
  return (
    <React.Fragment>
      {changes.map(change => (
        <PackageTotalRow rowHeight={rowHeight} key={change.uid} isRequired={isRequired}>
          {applicability.map(app => (
            <PackageTotalCell key={app.id} value={app.value} disabled />
          ))}
        </PackageTotalRow>
      ))}
    </React.Fragment>
  );
};
