/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ActionBarDivider } from 'vapi-ui-common';
import IconTextButton from '../../../../components/IconTextButton';
import Spinner from '../../../../components/Spinner';
import { TableRow, TwoTableWrapper } from '../../../../components/Table';
import SortButton from '../../../../components/sortModule/SortButton';
import SortDropdown from '../../../../components/sortModule/SortDropdown';
import SortModal from '../../../../components/sortModule/SortModal';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useDebounce from '../../../../hooks/useDebounce';
import useStores from '../../../../hooks/useStores';
import useSyncChangesModal from '../../../../hooks/useSyncChangesModal';
import { CategoriesMap, CategoryLangMap } from '../../../../models/category.model';
import { IDValueType, KeyValueType } from '../../../../models/common.model';
import { OptionItem, OptionLangMap, OptionSettings } from '../../../../models/options.model';
import { VDSortableEntity } from '../../../../models/sort.model';
import { SpecItem, SpecsLangMap } from '../../../../models/specs.model';
import { BRAND_TDPR, Language } from '../../../../models/user.model';
import { VDTab, VehicleTeam } from '../../../../models/vehicleData.model';
import ActionBarVehicleData from '../../../../routes/vehicleData/components/ActionBarVehicleData';
import LeftTable from '../../../../routes/vehicleData/components/LeftTable';
import ModelTable from '../../../../routes/vehicleData/components/ModelTable';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { tokensXForm } from '../../../../utils/disclaimersUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import getLangActionBarButtons from '../../../../utils/getLangActionBarButtons';
import { getSortPayload, getSortedCopy } from '../../../../utils/sortUtils';
import { syncSpanishUpdates, updateSortList } from '../../../../webservices/vehicleAdminApi';
import {
  addCategories,
  addOption,
  deleteOption,
  updateCategory,
  updateOption,
} from '../../../../webservices/vehicleOptionsApi';
import { displaySyncMessage } from '../models/utils/utils';
import ProductTeamFilters from './components/Filters';
import ModelCell from './components/ModelCell';
import OptionsHeaderRow from './components/OptionsHeaderRow';
import OptionsRowContainer from './components/OptionsRow/OptionsRowContainer';
import useOppositeTeamSort from '../../../../hooks/useOppositeTeamSort';
import { toGqlBrand, toGqlTeam, toGqlLanguage } from '../../../../utils/graphqlUtils';

const OptionsController = ({
  readOnly,
  team,
  seriesId,
  year,
  version,
  versionInfo,
  vehicleModels,
  isPublished,
  reloadDraft,
}: ProductDataControllerProps) => {
  const {
    optionsStore,
    teamStore,
    userStore: { brand },
    disclaimersStore: { tokens },
  } = useStores();

  const { debounce } = useDebounce({ delay: 2000 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortMode, setSortMode] = useState(false);
  const [sortCategoryModal, setSortCategoryModal] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const disclaimerTokens = tokensXForm(tokens);

  useEffect(() => {
    setIsLoaded(false);
    optionsStore.reset();

    (async () => {
      try {
        await optionsStore.fetchData(
          brand,
          team,
          seriesId,
          year,
          vehicleModels,
          teamStore.team.langPermissions,
          versionInfo
        );
      } catch (e) {
        toast.error('Error loading options data');
      }
      setIsLoaded(true);
    })();
  }, [brand, optionsStore, seriesId, team, vehicleModels, year, version, lastUpdated]);

  const {
    OppositeTeamSort,
    getSortListForOppositeTeam,
    turnOnOppositeTeamSort,
  } = useOppositeTeamSort(brand, team, seriesId, year, VDSortableEntity.OPTIONS);

  const addEmptyOptionItem = () => {
    optionsStore.addItem(vehicleModels);
    showFormFieldError();
  };

  const isOptionValid = (option: OptionItem) => {
    if (brand === BRAND_TDPR) {
      return option.isValidTdPR();
    }
    return option.isValid();
  };

  const updateOptionItem = async (
    option: OptionItem,
    lang: string,
    acceptChanges: boolean = false,
    unlinkFromTMNA: boolean = false
  ) => {
    if (isOptionValid(option)) {
      try {
        debounce(async () => {
          try {
            const response = await trackPromise(
              updateOption({
                brand: toGqlBrand(brand),
                team: toGqlTeam(team),
                seriesId: seriesId,
                modelYear: parseInt(year),
                language: toGqlLanguage(lang),
                payload: { ...option.getUpdatePayload(), acceptChanges, unlinkFromTMNA },
              })
            );
            updateRevId(option, response.revId);
            option.fieldStatus = response.fieldStatus;

            if (acceptChanges || unlinkFromTMNA) {
              option.changedAttributes = [];
              option.changedModelIds = [];
            }
            if (unlinkFromTMNA) {
              option.fromTMNA = false;
            }
            toast.success(`${langNameMap[lang]} Option updated successfully`);
          } catch (e) {
            handleErrorResponse(e, 'Option failed update');
          }
        }, option.uid);
      } catch (e) {
        handleErrorResponse(e, 'Option failed update');
      }
    } else {
      showFormFieldError();
    }
  };

  const addOptionItem = async (option: OptionItem, lang: string) => {
    if (isOptionValid(option)) {
      try {
        debounce(async () => {
          try {
            const response = await trackPromise(
              addOption({
                brand: toGqlBrand(brand),
                team: toGqlTeam(team),
                seriesId: seriesId,
                modelYear: parseInt(year),
                language: toGqlLanguage(lang),
                payload: option.getCreatePayload(),
              })
            );
            option.id = response.id;
            option.fieldStatus = response.fieldStatus;
            updateRevId(option, response.revId);
            toast.success('Option added successfully');
          } catch (e) {
            handleErrorResponse(e, 'Option failed add');
          }
        }, option.uid);
      } catch (e) {
        handleErrorResponse(e, 'Option failed add');
      }
    } else {
      showFormFieldError();
    }
  };

  const saveOptionLangMap = async (
    optionLangMap: OptionLangMap,
    lang: string = '',
    acceptChanges: boolean = false,
    unlinkFromTMNA: boolean = false
  ) => {
    if (lang && !optionsStore.langWriteMap[lang as Language]?.canEdit) {
      toast.error(`You do not have permissions to update ${langNameMap[lang]} options.`);
      return;
    }
    const langs = lang ? [lang] : optionsStore.editableLangs;
    const promises: Promise<any>[] = [];
    let numValid = 0;
    for (const lang of optionsStore.editableLangs) {
      // check to make sure all options either have a revid or are valid
      const option = optionLangMap.langs[lang];
      if (!option.revId && !isOptionValid(option)) {
        showFormFieldError();
        return;
      } else if (!option.revId && !langs.includes(lang)) {
        // see features controller for reasoning
        langs.push(lang);
      }
    }
    for (const lang of langs) {
      const option = optionLangMap.langs[lang];
      if (isOptionValid(option)) {
        numValid++;
        if (option.revId) {
          promises.push(updateOptionItem(option, lang, acceptChanges, unlinkFromTMNA));
        } else {
          promises.push(addOptionItem(option, lang));
        }
      }
    }
    if (!numValid) {
      showFormFieldError();
    } else {
      await Promise.all(promises);
    }
  };

  const deleteOptionLangMap = async (optionLangMap: OptionLangMap) => {
    try {
      const uid = optionLangMap.langs[optionsStore.defaultLang].uid;
      // only delete the options that the user has write permissions for
      for (const lang of optionsStore.editableLangs) {
        const option = optionLangMap.langs[lang];
        if (option.revId) {
          await trackPromise(
            deleteOption({
              brand: toGqlBrand(brand),
              team: toGqlTeam(team),
              seriesId: seriesId,
              modelYear: parseInt(year),
              language: toGqlLanguage(lang),
              deleteVehicleOptionId: option.id,
            })
          );
        }
      }
      optionsStore.deleteItem(uid);
      toast.success('Option deleted sucessfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting Option');
    }
  };

  const copyOptionLangMap = async (optionLangMap: OptionLangMap) => {
    try {
      const copiedOptionLangMap = optionsStore.copyMap(
        JSON.parse(JSON.stringify(optionLangMap)),
        vehicleModels
      );
      const optionId = uuidv4();
      let isValid = true;
      optionsStore.allLangs.forEach(lang => {
        copiedOptionLangMap.langs[lang].id = optionId;
        if (!isOptionValid(copiedOptionLangMap.langs[lang])) {
          isValid = false;
        }
      });

      if (isValid) {
        for (const lang of optionsStore.allLangs) {
          const copiedOption = copiedOptionLangMap.langs[lang];
          if (optionsStore.langWriteMap[lang]?.canEdit) {
            const response = await trackPromise(
              addOption({
                brand: toGqlBrand(brand),
                team: toGqlTeam(team),
                seriesId: seriesId,
                modelYear: parseInt(year),
                language: toGqlLanguage(lang),
                payload: copiedOption.getCreatePayload(),
              })
            );
            updateRevId(copiedOption, response.revId);
          }
        }
        const sortPayload = getSortPayload(
          optionsStore.optionLangMaps.map(langMap => langMap.langs[optionsStore.editableLangs[0]])
        );
        await trackPromise(
          updateSortList(brand, team, seriesId, year, VDSortableEntity.OPTIONS, sortPayload)
        );
        toast.success('Option copied successfully');
      } else {
        showFormFieldError();
      }
    } catch (e) {
      handleErrorResponse(e, 'Option failed copy');
    }
  };

  const addCategoryItem = async (payload: { [lang: string]: string }, id?: string) => {
    try {
      const response = await trackPromise(
        addCategories({
          brand: toGqlBrand(brand),
          team: toGqlTeam(team),
          seriesId: seriesId,
          modelYear: parseInt(year),
          payload: {
            id,
            categories: payload,
          },
        })
      );
      const categoriesMap: CategoriesMap = JSON.parse(JSON.stringify(optionsStore.categoriesMap));
      Object.keys(response).forEach(lang => {
        optionsStore.updateCategoriesLangMap(
          lang as Language,
          categoriesMap,
          response[lang],
          optionsStore.categoriesSortList
        );
      });
      optionsStore.fillOutCategoriesMap(categoriesMap);
      optionsStore.categoriesMap = categoriesMap;
      toast.success('Category added successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding category');
    }
  };

  const updateCategoryItem = async (
    categoryLangMap: CategoryLangMap,
    payload: { [lang: string]: string }
  ) => {
    try {
      for (const lang of optionsStore.editableLangs) {
        if (payload[lang]) {
          await trackPromise(
            updateCategory({
              brand: toGqlBrand(brand),
              team: toGqlTeam(team),
              seriesId: seriesId,
              modelYear: parseInt(year),
              language: toGqlLanguage(lang),
              payload: {
                id: categoryLangMap[lang].id,
                name: payload[lang],
              },
            })
          );
        }
      }
      optionsStore.editableLangs.forEach(lang => {
        if (payload[lang]) {
          categoryLangMap[lang].value = payload[lang];
        }
      });
      const catMap = optionsStore.categoriesMap;
      for (const lang of optionsStore.editableLangs) {
        if (payload[lang]) {
          catMap.categories[categoryLangMap[lang].id] = categoryLangMap;
          break;
        }
      }
      optionsStore.categoriesMap = catMap;
      toast.success('Category updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error editing category');
    }
  };

  const onSaveSortCategories = async (list: IDValueType<string>[], dataType: VDSortableEntity) => {
    try {
      const catPayload = getSortPayload(list);
      await trackPromise(updateSortList(brand, team, seriesId, year, dataType, catPayload));

      let optionsPayload;
      if (dataType === VDSortableEntity.OPTIONS_CATEGORIES) {
        const categories =
          dataType === VDSortableEntity.OPTIONS_CATEGORIES
            ? list
            : optionsStore.getDefaultCategories(optionsStore.categoriesMap);

        const optionsCopy = getSortedCopy<OptionItem>(
          optionsStore.getDefaultOptions(optionsStore.optionLangMaps),
          item => item.category.id,
          categories
        );
        optionsPayload = getSortPayload(optionsCopy);
      }

      if (optionsPayload) {
        await trackPromise(
          updateSortList(brand, team, seriesId, year, VDSortableEntity.OPTIONS, optionsPayload)
        );
        setIsLoaded(false);
        optionsStore.resetFilters();
        await trackPromise(
          optionsStore.fetchData(
            brand,
            team,
            seriesId,
            year,
            vehicleModels,
            teamStore.team.langPermissions,
            versionInfo
          )
        );
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating category sort');
    }
    setIsLoaded(true);
  };

  const onStopSorting = async () => {
    setSortMode(false);
    try {
      const optionItems = optionsStore.getDefaultOptions(optionsStore.filteredOptionLangMaps);
      const sortPayload = getSortPayload(optionItems);
      await trackPromise(
        updateSortList(brand, team, seriesId, year, VDSortableEntity.OPTIONS, sortPayload)
      );
      setIsLoaded(false);
      await trackPromise(
        optionsStore.fetchData(
          brand,
          team,
          seriesId,
          year,
          vehicleModels,
          teamStore.team.langPermissions,
          versionInfo
        )
      );
      setIsLoaded(true);
    } catch (e) {
      handleErrorResponse(e, 'Error updating option sort');
    }
  };

  const updateRevId = (option: OptionItem, revId: string) => {
    option.revId = revId;
  };

  const syncUpdates = async () => {
    setIsLoaded(false);
    try {
      const res = await syncSpanishUpdates(brand, team, seriesId, year);
      displaySyncMessage(res.data.onlyStatusSyncUpdates);

      if (reloadDraft) {
        setIsLoaded(true);
        const teamParam = VehicleTeam.AGENCY_SPANISH;
        const url =
          brand !== BRAND_TDPR
            ? `/vehicleData/draft/${teamParam}/${seriesId}/${year}/EN:${res.data.sourceVersion}|ES:DRAFT?team=${teamParam}&tab=${VDTab.OPTIONS}`
            : '';
        reloadDraft(url);
      }
    } catch (e) {
      handleErrorResponse(e, 'Error syncing spanish data');
      setIsLoaded(true);
    }
  };

  const getActionBarButtons = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];
    if (showActionButtons && teamStore.team.allowAddDeleteData) {
      actionBarButtons.push(
        <IconTextButton icon="plus" text="Add Option" onClick={() => addEmptyOptionItem()} />
      );
      actionBarButtons.push(
        sortMode ? (
          <SortButton toggled onClick={onStopSorting}>
            Stop Sorting
          </SortButton>
        ) : (
          <>
            <SortDropdown
              buttonText="Sort"
              list={getSortListForOppositeTeam(teamStore.team.name, ['Rows', 'Categories'])}
              onSelect={value => {
                switch (value) {
                  case 'Rows': {
                    optionsStore.resetFilters();
                    setSortMode(true);
                    break;
                  }
                  case 'Categories': {
                    setSortCategoryModal(true);
                    break;
                  }
                  case OppositeTeamSort.APPLY_PRODUCT_SORT:
                  case OppositeTeamSort.APPLY_AGENCY_SORT: {
                    turnOnOppositeTeamSort(
                      optionsStore.filteredOptionLangMaps,
                      optionsStore.resetFilters,
                      optionsStore.setLangMapList,
                      setSortMode
                    );
                    break;
                  }
                }
              }}
            />
            <SortModal
              open={sortCategoryModal}
              onClose={() => setSortCategoryModal(false)}
              onSave={list => onSaveSortCategories(list, VDSortableEntity.OPTIONS_CATEGORIES)}
              idValueList={optionsStore.getDefaultCategories(optionsStore.categoriesMap)}
              headerText="Sort Categories"
            />
          </>
        )
      );
    }

    const langButtons = getLangActionBarButtons(
      {
        allLangs: optionsStore.allLangs,
        selectedLangsMap: optionsStore.selectedLangsMap,
        updateSelectedLangs: optionsStore.updateSelectedLangs,
        showActionButtons,
      },
      {
        canSyncUpdates: teamStore.team.canSyncUpdates,
        sourceENVersion: versionInfo.EN?.toString(),
        brand,
        team,
        seriesId,
        year,
        syncUpdates,
      }
    );
    actionBarButtons.push(...langButtons);

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = optionsStore.filteredOptionLangMaps.splice(result.source.index, 1);
    optionsStore.filteredOptionLangMaps.splice(result.destination.index, 0, removed);
    optionsStore.filteredOptionLangMaps.forEach((item, index: number) => {
      Object.values(item.langs).forEach(optionItem => {
        optionItem.sortOrder = index + 1;
      });
    });
    return optionsStore.filteredOptionLangMaps;
  };

  const showFormFieldError = () => {
    toast.error('Please finish filling out all items of the new option.');
  };

  const changeModalApplicability = (item: SpecItem | OptionItem, modApp: KeyValueType<string>) => {
    const list = item as OptionItem;
    Object.values(list.modelsMap).forEach(model => {
      if (modApp[model.id]) {
        model.setting = modApp[model.id] as OptionSettings;
      } else {
        model.setting = OptionSettings.UNDEFINED;
      }
    });
  };

  const { getSyncChangesModal, compareChanges } = useSyncChangesModal(
    brand,
    team,
    seriesId,
    year,
    optionsStore,
    vehicleModels,
    'options',
    (langMap: SpecsLangMap | OptionLangMap) => {
      deleteOptionLangMap(langMap as OptionLangMap);
    },
    (langMap: SpecsLangMap | OptionLangMap, acceptChanges: boolean, unlinkFromTMNA: boolean) => {
      saveOptionLangMap(langMap as OptionLangMap, '', acceptChanges, unlinkFromTMNA);
    },
    changeModalApplicability
  );

  const visibleModels = () => {
    if (brand !== BRAND_TDPR) {
      return vehicleModels;
    }
    return vehicleModels.filter(model => model.getVal('isTDPR') || model.getVal('isUSVI'));
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBarVehicleData
        readOnly={readOnly}
        toggleViewModelCodes={() => (optionsStore.viewModelCodes = !optionsStore.viewModelCodes)}
        viewModelCodes={optionsStore.viewModelCodes}
        searchText={optionsStore.searchText}
        onSearchTextChange={text => optionsStore.onFilter(() => (optionsStore.searchText = text))}
        renderButtons={getActionBarButtons(!readOnly)}
        renderFilter={onClose => (
          <ProductTeamFilters
            onClose={onClose}
            categories={optionsStore
              .getDefaultCategories(optionsStore.categoriesMap)
              .map(cat => cat.value)}
            categoryFilters={optionsStore.categoryFilters}
            setCategoryFilters={categoryFilters =>
              optionsStore.onFilter(() => (optionsStore.categoryFilters = categoryFilters))
            }
            isInProgressFilter={optionsStore.isInProgressFilter}
            setIsInProgressFilter={value =>
              optionsStore.onFilter(() => (optionsStore.isInProgressFilter = value))
            }
            isSyncUpdateFilter={optionsStore.isSyncUpdateFilter}
            setIsSyncUpdateFilter={value =>
              optionsStore.onFilter(() => (optionsStore.isSyncUpdateFilter = value))
            }
            isReviewNotesFilter={optionsStore.isReviewNotesFilter}
            setIsReviewNotesFilter={
              team === VehicleTeam.AGENCY_TEAM && version == null
                ? value => optionsStore.onFilter(() => (optionsStore.isReviewNotesFilter = value))
                : undefined
            }
            isPublished={isPublished}
          />
        )}
      />
      <TwoTableWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <LeftTable>
            <OptionsHeaderRow
              viewModelCodes={optionsStore.viewModelCodes}
              allowLinks={teamStore.team.allowLinks}
              readOnly={readOnly}
              onSort={optionsStore.onSort}
              sortMode={sortMode}
              setLastUpdated={setLastUpdated}
            />
            <OptionsRowContainer
              optionLangMaps={optionsStore.filteredOptionLangMaps}
              sortMode={sortMode}
              readOnly={readOnly}
              saveOptionLangMap={saveOptionLangMap}
              deleteOptionLangMap={deleteOptionLangMap}
              copyOptionLangMap={copyOptionLangMap}
              addCategoryItem={addCategoryItem}
              updateCategoryItem={updateCategoryItem}
              showLink={teamStore.team.allowLinks}
              allowDisclaimerTokens={teamStore.team.allowDisclaimerTokens}
              disclaimerTokens={disclaimerTokens}
              brand={brand}
              compareOption={compareChanges}
            />
          </LeftTable>
        </DragDropContext>
        <ModelTable
          showFeatureSplits={false}
          viewModelCodes={optionsStore.viewModelCodes}
          models={visibleModels()}
          headerStyle={{ top: 0 }}
          renderRows={() => (
            <>
              {optionsStore.filteredOptionLangMaps.map((optionLangMap, idx) => {
                const defaultOption = optionLangMap.langs[optionsStore.defaultLang];
                const changedModelIds: string[] = [];
                for (const lang of optionsStore.editableLangs) {
                  changedModelIds.push(...optionLangMap.langs[lang].changedModelIds);
                }
                return (
                  <React.Fragment key={defaultOption.uid}>
                    <TableRow rowHeight={optionsStore.getOptionsRowHeight(optionLangMap)}>
                      {visibleModels().map((model, index) => {
                        return (
                          <ModelCell
                            model={model}
                            changedModelIds={changedModelIds}
                            defaultOption={defaultOption}
                            optionLangMap={optionLangMap}
                            idx={idx}
                            readOnly={readOnly}
                            saveOptionLangMap={saveOptionLangMap}
                            key={`${defaultOption.uid}${model.uid}${
                              defaultOption.modelsMap[model.id].setting
                            }`}
                          />
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </>
          )}
        />
      </TwoTableWrapper>
      {getSyncChangesModal()}
    </>
  );
};

export default observer(OptionsController);
