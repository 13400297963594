import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import { KeyFeatureContext, KeyFeatureContextProps } from '.';
import useKeyFeatureLoad from '../../hooks/useKeyFeatureLoad';
import useKeyFeatureOptionsLoad from '../../hooks/useKeyFeatureOptionsLoad';
import useStores from '../../hooks/useStores';
import { FeatureLangMap, KeyFeatureType } from '../../models/features.model';
import Popover from '../Popover';
import KeyFeature from './KeyFeature';
import styles from './KeyFeature.module.scss';
import KeyFeatureCTA from './KeyFeatureCTA';

interface Props {
  readOnly?: boolean;
  featureLangMap: FeatureLangMap;
  onSave: (keyFeatureList: KeyFeatureType[]) => void;
}

const KeyFeaturePopover = ({ readOnly, featureLangMap, onSave }: Props) => {
  const { teamStore, featuresStore } = useStores();

  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);

  const onShow = useCallback(() => setIsPopoverVisible(true), [setIsPopoverVisible]);
  const onHide = useCallback(() => setIsPopoverVisible(false), [setIsPopoverVisible]);

  const feature = featureLangMap.langs[teamStore.team.defaultLanguage];

  const { showKeyFeature } = useKeyFeatureOptionsLoad();

  const {
    keyFeatureList,
    setKeyFeatureList,
    defaultFeatureItem,
    hasChanges,
    hasBeenModified,
    hasErrors,
    keyFeatureOptions,
    keyFeatureErrorMap,
  } = useKeyFeatureLoad({
    isShown: isPopoverVisible,
    featureLangMap,
    categoryId: feature.category.id,
  });

  const keyFeatureContext: KeyFeatureContextProps = useMemo(
    () => ({
      keyFeatureOptions,
      keyFeatureList,
      setKeyFeatureList,
      readOnly,
      hasChanges,
      hasErrors,
      isLoaded: !!featuresStore.keyFeaturesMap,
      keyFeaturesMap: featuresStore.keyFeaturesMap ?? {},
      keyFeatureErrorMap,
      onSave,
    }),
    [
      keyFeatureOptions,
      keyFeatureList,
      setKeyFeatureList,
      readOnly,
      hasChanges,
      hasErrors,
      featuresStore,
      keyFeatureErrorMap,
      onSave,
    ]
  );

  const isDisabled = useMemo(() => {
    if (readOnly) return true;
    return !hasBeenModified;
  }, [readOnly, hasBeenModified]);

  if (!showKeyFeature) return null;

  return (
    <KeyFeatureContext.Provider value={keyFeatureContext}>
      <div data-testid="key-feature-component">
        <Popover
          popoverElementClass={styles.popoverElementClass}
          align="left"
          toggleElement={<KeyFeatureCTA keyFeatures={defaultFeatureItem.keyFeatures} />}
          popoverElement={<KeyFeature disabled={isDisabled} />}
          onShow={onShow}
          onHide={onHide}
        />
      </div>
    </KeyFeatureContext.Provider>
  );
};

export default observer(KeyFeaturePopover);
