import cx from 'clsx';
import React from 'react';
import styles from './tableTab.module.scss';

interface TableTabProps {
  tab: string;
  isSelected: boolean;
  onSelectTab: (value: string) => void;
}

export const TableTab = ({ tab, isSelected, onSelectTab }: TableTabProps) => {
  return (
    <button
      className={cx(styles.tableTab, isSelected && styles.selected)}
      onClick={() => onSelectTab(tab)}
    >
      {tab}
    </button>
  );
};
