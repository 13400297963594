import { action, observable } from 'mobx';
import { ColorGradeItem } from '../../../models/colors.model';

class ColorsReviewStore {
  @observable tabs = ['Exterior', 'Interior'];
  @observable selectedTab = 'Exterior';
  @observable colorGradeItems: ColorGradeItem[] = [];

  @action reset() {
    this.tabs = ['Exterior', 'Interior'];
    this.selectedTab = 'Exterior';
    this.colorGradeItems = [];
  }
}

export default ColorsReviewStore;
