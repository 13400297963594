import React from 'react';
import VDTableCell from '../VDTableCell/VDTableCell';
import styles from './addedDeletedCell.module.scss';

interface IAddedDeletedCell {
  isNew: boolean;
  isDeleted: boolean;
  className?: string;
}

const AddedDeletedCell = ({ isNew, isDeleted, className }: IAddedDeletedCell) => {
  return (
    <VDTableCell colType="dropdown" className={className}>
      <div className={isNew ? styles.greenText : isDeleted ? styles.redText : ''}>
        {isNew ? 'Added' : isDeleted ? 'Deleted' : ''}
      </div>
    </VDTableCell>
  );
};
export default AddedDeletedCell;
