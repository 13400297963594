import React, { ReactNode } from 'react';
import cx from 'clsx';
import Tabs from '../Tabs';
import styles from './secondaryHeader.module.scss';

export type SecondaryHeaderProps = {
  tabs: string[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  isTabDisabled?: (tab: string) => boolean;
  renderButtons: () => ReactNode;
  className?: string;
};

const SecondaryHeader: React.FC<SecondaryHeaderProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  isTabDisabled,
  renderButtons,
  className,
}) => {
  return (
    <div className={cx(styles.root, className)} data-testid="secondary-header-comp">
      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isTabDisabled={isTabDisabled}
        tabs={tabs}
      />
      <div className={styles.buttons}>{renderButtons()}</div>
    </div>
  );
};

export default SecondaryHeader;
