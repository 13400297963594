import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'vapi-ui-common';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';
import useStores from '../../../hooks/useStores';
import { DisclaimersItem } from '../../../models/disclaimers.model';
import { getDisclaimers } from '../../../webservices/disclaimersApi';
import { getAvailableModels } from '../../../webservices/vehicleAdminApi';
import ActionBarDisclaimers from '../components/ActionBarDisclaimers';
import DisclaimersTable from '../components/DisclaimersTable';
import DisclaimerRow from '../components/DisclaimersTable/components/DisclaimerRow';
import { VDStatus } from '../../../constants/vehicleData/VDConstants';

const DisclaimersPublished = observer(() => {
  const {
    userStore: {
      brand,
      region,
      modules: {
        Disclaimers: { canEdit },
      },
    },
    disclaimersStore,
  } = useStores();

  const config = { readOnly: true };
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    disclaimersStore.reset();

    (async () => {
      try {
        const responses = await Promise.all([
          getDisclaimers(brand, region, VDStatus.PUBLISHED),
          getAvailableModels(brand),
        ]);

        disclaimersStore.setData(responses[0].map(item => new DisclaimersItem(item)));
        disclaimersStore.vehicleModels = responses[1].data;
      } catch (e) {
        toast.error('Error loading published data');
      }

      setIsLoaded(true);
    })();

    return () => {
      disclaimersStore.setData([]);
    };
  }, [brand, disclaimersStore, region]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="Disclaimers" moduleSubTitle="Published">
        <NavLink to="/disclaimers/changeLog?return=published">
          <Button variant="transparent">Change Log</Button>
        </NavLink>
        {canEdit && (
          <NavLink to="/disclaimers/draft">
            <Button variant="primary">Edit</Button>
          </NavLink>
        )}
      </Header>
      <ActionBarDisclaimers
        readOnly={config.readOnly}
        searchText={disclaimersStore.searchText}
        setSearchText={value => {
          disclaimersStore.searchText = value;
          disclaimersStore.filterData();
        }}
        vehicleModels={disclaimersStore.vehicleModels}
        vehicleFilters={disclaimersStore.vehicleFilters}
        setFilters={disclaimersStore.setFilters}
        onResetDraft={() => {}}
        onAddDisclaimerItem={() => {}}
      />
      <DisclaimersTable
        onSort={disclaimersStore.setSort}
        region={region}
        renderRows={() => (
          <>
            {disclaimersStore.filteredData.map(item => (
              <DisclaimerRow
                key={item.uid}
                item={item}
                config={config}
                vehicleModels={disclaimersStore.vehicleModels}
                region={region}
              />
            ))}
          </>
        )}
      />
    </>
  );
});

export default DisclaimersPublished;
