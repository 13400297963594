import cx from 'clsx';
import React from 'react';
import { TableCell } from '../../../../../components/Table';
import { TableCellProps } from '../../../../../components/Table/components/TableCell/TableCell';
import styles from './vdTableCell.module.scss';

interface VDTableCellProps extends TableCellProps {
  size?: 'large' | 'maxWidth';
}

const VDTableCell = ({ size, children, ...rest }: VDTableCellProps) => {
  return (
    <TableCell className={cx(styles.vdTableCell, size && styles[size])} {...rest}>
      <div className={styles.container}>{children}</div>
    </TableCell>
  );
};

export default VDTableCell;
