import { faCode, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Popover from '../../../../../components/Popover';
import CustomPopover from './components/CustomPopover';
import styles from './linkCell.module.scss';

type Props = {
  link: string;
  disabled?: boolean;
  onClick: (link: string) => void;
  popoverType?: 'hyperlink' | 'logos';
};

const LinkCell: React.FC<Props> = ({ link, disabled, onClick, popoverType = 'hyperlink' }) => {
  const isActive = link !== '';
  return (
    <td className={styles.root}>
      {(!disabled || isActive) && (
        <Popover
          toggleElement={
            <button onClick={() => {}} className={styles.button}>
              <FontAwesomeIcon
                icon={popoverType === 'hyperlink' ? faLink : faCode}
                className={cx(styles.icon, { [styles.active]: isActive })}
              />
            </button>
          }
          popoverElement={
            <CustomPopover
              readOnly={disabled}
              link={link}
              onUpdate={onClick}
              popoverType={popoverType}
            />
          }
          align="left"
          popoverElementClass={styles.popoverElementClass}
        />
      )}
    </td>
  );
};

export default observer(LinkCell);
