import { TableCell } from '../../../../../../../components/Table';
import { OptionsPackageSpec } from '../../../../../../../models/optionsLexus.model';
import React from 'react';
import { ActionButton, CenteredTextArea, MultiLineRTEditor } from 'vapi-ui-common';

interface OptionsLexusPackageSpecReviewCellsProps {
  spec: OptionsPackageSpec;
}

const OptionsLexusPackageSpecReviewCells = ({ spec }: OptionsLexusPackageSpecReviewCellsProps) => {
  return (
    <>
      <TableCell colType="dropdown">
        <CenteredTextArea disabled value={spec.toms} size="xsmall" useInput />
      </TableCell>
      <TableCell colType="dropdown">
        <CenteredTextArea disabled value={spec.sms} size="xsmall" useInput />
      </TableCell>
      <TableCell colType="dropdown">
        <MultiLineRTEditor disabled value={spec.specs} />
      </TableCell>
      <TableCell>
        {spec.isExclusive && <ActionButton icon="flag" selected={spec.isExclusive} />}
      </TableCell>
    </>
  );
};

export default OptionsLexusPackageSpecReviewCells;
