import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import DraftHeader from '../../../components/DraftHeader';
import Spinner from '../../../components/Spinner';
import { CheckMessageToast } from '../../../components/Toast';
import useStores from '../../../hooks/useStores';
import { CommonLanguageCategory, CommonLanguageItem } from '../../../models/commonLanguage.model';
import { categoriesObjectXForm } from '../../../utils/categoryUtils';
import { handleErrorResponse } from '../../../utils/errorHandlingUtils';
import {
  addCategories,
  addSpec,
  archiveSpec,
  deleteSpec,
  getCategories,
  getChangeLog,
  getSpecs,
  publishDraft,
  resetDraft,
  updateCategory,
  updateSpec,
} from '../../../webservices/commonLanguageApi';
import { getAvailableModels } from '../../../webservices/vehicleAdminApi';
import ActionBarCommonLanguage from '../components/ActionBarCommonLanguage';
import CommonLanguageTable from '../components/CommonLanguageTable';
import SpecRow from '../components/CommonLanguageTable/components/SpecRow';
import { toGqlBrand, toGqlFilter } from '../../../utils/graphqlUtils';
import { Status } from '../../../gql/generated';

const CommonLanguageDraft = observer(() => {
  const {
    userStore: {
      brand,
      modules: {
        CommonLanguage: { canEdit },
      },
    },
    commonLanguageStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);

  useEffect(() => {
    commonLanguageStore.reset();

    (async () => {
      try {
        const [dataResponse, categoriesResponse, modelsResponse] = await Promise.all([
          getSpecs({
            brand: toGqlBrand(brand),
            filter: toGqlFilter(Status.Draft),
          }),
          getCategories({ brand: toGqlBrand(brand) }),
          getAvailableModels(brand),
        ]);

        commonLanguageStore.setData(dataResponse.map(item => new CommonLanguageItem(item)));
        commonLanguageStore.categories = categoriesObjectXForm(categoriesResponse.categories);
        commonLanguageStore.vehicleModels = modelsResponse.data;
      } catch (e) {
        console.log(e);
        toast.error('Error loading common language data');
      }

      setIsLoaded(true);
    })();

    return () => {
      commonLanguageStore.data = [];
    };
  }, [brand, commonLanguageStore]);

  const handleDeleteItem = async (spec: CommonLanguageItem) => {
    try {
      if (spec.id) {
        await trackPromise(
          deleteSpec({
            brand: toGqlBrand(brand),
            payload: {
              id: spec.id,
              revId: spec.revId,
            },
          })
        );
      }
      commonLanguageStore.deleteItem(spec);
    } catch (e) {
      handleErrorResponse(e, 'Error deleting common lanaguage item');
    }
  };

  const handleUpdateItem = async (spec: CommonLanguageItem) => {
    try {
      if (spec.isValid) {
        const response = await trackPromise(
          updateSpec({
            brand: toGqlBrand(brand),
            payload: spec.toUpdatePayload(),
          })
        );
        spec.revId = response.revId;
        toast.success('Spec successfully updated');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating common language item');
    }
  };

  const handleAddItem = async (spec: CommonLanguageItem) => {
    try {
      if (spec.isValid) {
        const response = await trackPromise(
          addSpec({
            brand: toGqlBrand(brand),
            payload: spec.toCreatePayload(),
          })
        );
        spec.id = response.id;
        spec.revId = response.revId;
        toast.success('Spec successfully added');
        return true;
      }
    } catch (e) {
      handleErrorResponse(e, 'Error adding common language item');
    }
    return false;
  };

  const handleArchiveItem = async (spec: CommonLanguageItem) => {
    try {
      await trackPromise(
        archiveSpec({
          brand: toGqlBrand(brand),
          payload: {
            id: spec.id,
            revId: spec.revId,
          },
        })
      );
      commonLanguageStore.deleteItem(spec);
    } catch (e) {
      handleErrorResponse(e, 'Error archiving common language item');
    }
  };

  const handleUpdateCategory = async (category: CommonLanguageCategory) => {
    try {
      const response = await trackPromise(
        updateCategory({
          brand: toGqlBrand(brand),
          payload: {
            id: category.id,
            name: category.value,
          },
        })
      );
      commonLanguageStore.categories = categoriesObjectXForm(response.categories);
      commonLanguageStore.updateDataCatagories(category.id);
    } catch (e) {
      handleErrorResponse(e, 'Error updating category');
    }
  };

  const handleAddCategory = async (value: string) => {
    try {
      const response = await trackPromise(
        addCategories({ brand: toGqlBrand(brand), categories: value })
      );
      commonLanguageStore.categories = categoriesObjectXForm(response.categories);
    } catch (e) {
      handleErrorResponse(e, 'Error adding category');
    }
  };

  const handlePublishData = async () => {
    setIsLoaded(false);
    try {
      await trackPromise(publishDraft({ brand: toGqlBrand(brand) }));
      toast.success(<CheckMessageToast message="Your draft has been successfully published." />);
      const dataResponse = await getSpecs({
        brand: toGqlBrand(brand),
        filter: { status: Status.Published },
      });
      const updatedData = commonLanguageStore.data.map(item => {
        const f = dataResponse.find(res => res.id === item.id);
        if (f) {
          if (f.lastPublishedDate) {
            item.lastPublishedDate = f.lastPublishedDate;
          }
          if (f.lastPublishedVersion) {
            item.lastPublishedVersion = f.lastPublishedVersion;
          }
        }
        return item;
      });
      commonLanguageStore.setData(updatedData);
    } catch (e) {
      handleErrorResponse(e, 'Error publishing spec');
    }
    setIsLoaded(true);
  };

  const handleResetDraft = async () => {
    try {
      await trackPromise(resetDraft({ brand: toGqlBrand(brand) }));

      setIsLoaded(false);
      const dataResponse = await getSpecs({
        brand: toGqlBrand(brand),
        filter: { status: Status.Draft },
      });
      commonLanguageStore.setData(dataResponse.map(item => new CommonLanguageItem(item)));
    } catch (e) {
      handleErrorResponse(e, 'Error resetting draft');
    }

    setIsLoaded(true);
  };

  const getUpdateCount = async () => {
    let count = 0;
    try {
      const response = await trackPromise(
        getChangeLog({
          brand: toGqlBrand(brand),
          filter: { status: Status.Draft },
        })
      );
      count = response.currentChangeCount as number;
    } catch (e) {
      handleErrorResponse(e, 'Error getting update count');
    }
    return count;
  };

  const handleAddSpec = async () => {
    commonLanguageStore.addItem();
    toast.error('Please finish filling out all items of the new spec.');
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <DraftHeader
        title="Common Language"
        modalOpen={openPublishModal}
        openModal={() => setOpenPublishModal(true)}
        closeModal={() => setOpenPublishModal(false)}
        handlePublishData={handlePublishData}
      />
      <ActionBarCommonLanguage
        readOnly={!canEdit}
        searchText={commonLanguageStore.searchText}
        setSearchText={value => {
          commonLanguageStore.searchText = value;
          commonLanguageStore.filterData();
        }}
        categories={commonLanguageStore.categories}
        categoryFilters={commonLanguageStore.categoryFilters}
        vehicleModels={commonLanguageStore.vehicleModels}
        vehicleFilters={commonLanguageStore.vehicleFilters}
        setFilters={commonLanguageStore.setFilters}
        onResetDraft={handleResetDraft}
        getUpdateCount={getUpdateCount}
        onAddSpec={handleAddSpec}
      />
      <CommonLanguageTable
        onSort={commonLanguageStore.setSort}
        renderRows={() =>
          commonLanguageStore.filteredData.map((item, index) => (
            <SpecRow
              index={index}
              key={item.uid}
              readOnly={!canEdit}
              item={item}
              onDeleteItem={handleDeleteItem}
              onUpdateItem={handleUpdateItem}
              onAddItem={handleAddItem}
              onArchiveItem={handleArchiveItem}
              categories={commonLanguageStore.categories}
              onAddCategory={handleAddCategory}
              onUpdateCategory={handleUpdateCategory}
              vehicleModels={commonLanguageStore.vehicleModels}
            />
          ))
        }
      />
    </>
  );
});

export default CommonLanguageDraft;
