import { CommonLanguageCategory, CommonLanguageVehicle } from '../../../../models/commonLanguage.model';
import React, { useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabBody,
  FilterTabFooter,
  FilterTabs,
  FilterTabSection,
} from 'vapi-ui-common';
import CategoryFilter from './CategoryFilter';
import VehicleFilter from './VehicleFilter';

interface CommonLanguageFilterProps {
  categories: CommonLanguageCategory[];
  categoryFilters: string[];
  vehicles: CommonLanguageVehicle[];
  vehicleFilters: string[];
  setFilters: (categoryFilters: string[], vehicleFilters: string[]) => void;
  onClose: () => void;
}

const CommonLanguageFilter = ({
  categories,
  categoryFilters,
  vehicles,
  vehicleFilters,
  setFilters,
  onClose,
}: CommonLanguageFilterProps) => {
  const [selectedTab, setSelectedTab] = useState('Category');
  const [catFilters, setCategoryFilters] = useState(categoryFilters);
  const [vehFilters, setVehicleFilters] = useState(vehicleFilters);

  const handleApplyFilters = () => {
    setFilters(catFilters, vehFilters);
    onClose();
  };

  return (
    <FilterTab>
      <FilterTabs
        tabs={['Category', 'Vehicles']}
        selectedTab={selectedTab}
        onSelected={setSelectedTab}
      />
      <FilterTabBody>
        <FilterTabSection active={selectedTab === 'Category'}>
          <CategoryFilter
            categories={categories}
            categoryFilters={catFilters}
            setCategories={setCategoryFilters}
          />
        </FilterTabSection>
        <FilterTabSection active={selectedTab === 'Vehicles'}>
          <VehicleFilter
            vehicleModels={vehicles}
            vehicleFilters={vehFilters}
            setVehicles={setVehicleFilters}
          />
        </FilterTabSection>
      </FilterTabBody>
      <FilterTabFooter>
        <Button variant="primary" onClick={handleApplyFilters}>
          Apply Filters
        </Button>
      </FilterTabFooter>
    </FilterTab>
  );
};

export default CommonLanguageFilter;
