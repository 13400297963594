import React, { useState } from 'react';
import Checkbox from '../../../../../components/Checkbox';
import NotesPopover from '../../../../../components/NotesPopover';
import { TableCell } from '../../../../../components/Table';

interface AcceptApplyCellProps {
  id: string;
  applyChecked: boolean;
  notes: string;
  onApplyChecked: (value: boolean) => void;
  onNotesChange: (value: string) => void;
  acceptChecked?: boolean;
  onAcceptChecked?: (value: boolean) => void;
  rowSpan?: number;
  isNewChange?: boolean;
  disableApply?: boolean;
}

export const AcceptApplyCell = ({
  id,
  applyChecked,
  notes,
  onApplyChecked,
  onNotesChange,
  acceptChecked,
  onAcceptChecked,
  rowSpan = 1,
  isNewChange,
  disableApply,
}: AcceptApplyCellProps) => {
  const [isAcceptChecked, setIsAcceptChecked] = useState(acceptChecked);
  const [openNotes, setOpenNotes] = useState(false);

  const handleOnAcceptChange = (value: boolean) => {
    setIsAcceptChecked(value);
    if (onAcceptChecked) {
      onAcceptChecked(value);
    }
    setOpenNotes(true);
  };

  const handleOnNotesChange = (value: string) => {
    onNotesChange(value);
  };

  return (
    <>
      {onAcceptChecked && (
        <TableCell colType="acceptApply" center whiteBg rowSpan={rowSpan}>
          <Checkbox
            id={`chbox-accept-${id}`}
            defaultChecked={acceptChecked}
            onChange={e => handleOnAcceptChange(e.currentTarget.checked)}
          />
        </TableCell>
      )}
      <TableCell colType="acceptApply" center whiteBg rowSpan={rowSpan}>
        {isAcceptChecked && (
          <Checkbox
            id={`chbox-apply-${id}`}
            defaultChecked={applyChecked}
            onChange={e => onApplyChecked(e.currentTarget.checked)}
            disabled={disableApply}
          />
        )}
        {!isAcceptChecked && (
          <NotesPopover
            defaultOpen={openNotes}
            notes={notes}
            handleUpdateItem={handleOnNotesChange}
          />
        )}
      </TableCell>
    </>
  );
};
