/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ActionBarDivider, Modal } from 'vapi-ui-common';
import IconTextButton from '../../../../components/IconTextButton';
import Spinner from '../../../../components/Spinner';
import { TableRow, TwoTableWrapper } from '../../../../components/Table';
import SortButton from '../../../../components/sortModule/SortButton';
import SortDropdown from '../../../../components/sortModule/SortDropdown';
import SortModal from '../../../../components/sortModule/SortModal';
import { VDStatus, langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useDebounce from '../../../../hooks/useDebounce';
import useStores from '../../../../hooks/useStores';
import { RefItemLangMap, RefItemsMap } from '../../../../models/category.model';
import { IDValueType, KeyValueType } from '../../../../models/common.model';
import { CompareType } from '../../../../models/compareFeatures.model';
import { RefItem } from '../../../../models/refItem.model';
import { VDSortableEntity } from '../../../../models/sort.model';
import { SpecItem, SpecSettings, SpecsLangMap } from '../../../../models/specs.model';
import { BRAND_TDPR, Language } from '../../../../models/user.model';
import { VDTab, VehicleTeam } from '../../../../models/vehicleData.model';
import ActionBarVehicleData from '../../../../routes/vehicleData/components/ActionBarVehicleData';
import LeftTable from '../../../../routes/vehicleData/components/LeftTable';
import ModelTable from '../../../../routes/vehicleData/components/ModelTable';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { compareFeatureItemXForm } from '../../../../utils/compareFeaturesUtils';
import { tokensXForm } from '../../../../utils/disclaimersUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import getLangActionBarButtons from '../../../../utils/getLangActionBarButtons';
import { getSortPayload, getSortedCopy } from '../../../../utils/sortUtils';
import { specItemXForm } from '../../../../utils/specsUtils';
import { syncSpanishUpdates, updateSortList } from '../../../../webservices/vehicleAdminApi';
import {
  addCompareFeatureFromParent,
  deleteCompareFeature,
  updateCompareFeature,
} from '../../../../webservices/vehicleCompareFeaturesApi';
import {
  addCategories,
  addSpecTypes,
  addVehicleSpec,
  deleteVehicleSpec,
  getCategoriesByLang,
  importFromCommonLanguage,
  updateCategory,
  updateSpecType,
  updateVehicleSpec,
} from '../../../../webservices/vehicleSpecsApi';
import AddCommonLanguageModal from '../../components/AddCommonLanguageModal';
import AddStandardSpecsModal from '../../components/AddStandardSpecModal';
import { displaySyncMessage } from '../models/utils/utils';
import SpecsFilters from './components/SpecsFilters';
import SpecRowsContainer from './components/SpecsFormRow/SpecRowsContainer';
import SpecsHeaderRow from './components/SpecsHeaderRow';
import SpecsModelCell from './components/SpecsModelCell';
import useSyncChangesModal from '../../../../hooks/useSyncChangesModal';
import { OptionItem, OptionLangMap } from '../../../../models/options.model';
import DropdownAdd from '../../../../components/DropdownAdd';
import useOppositeTeamSort from '../../../../hooks/useOppositeTeamSort';

const SpecsController = ({
  readOnly,
  team,
  seriesId,
  year,
  version,
  versionInfo,
  vehicleModels,
  grades,
  isPublished,
  reloadDraft,
}: ProductDataControllerProps) => {
  const {
    commonLanguageStore,
    specsStore,
    teamStore,
    userStore: { brand },
    disclaimersStore: { tokens },
  } = useStores();
  const { debounce } = useDebounce({ delay: 2000 });
  const updatingCompareFeatureLink = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortMode, setSortMode] = useState(false);
  const [sortCategoryModal, setSortCategoryModal] = useState(false);
  const [sortSpecTypeModal, setSortSpecTypeModal] = useState(false);
  const [showAddCommonLanguage, setShowAddCommonLanguage] = useState(false);
  const [showAddStandardiSpec, setShowAddStandardSpec] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const disclaimerTokens = tokensXForm(tokens);
  const onLoad = useCallback(async () => {
    setIsLoaded(false);
    specsStore.reset();
    try {
      if (teamStore.team.allowCommonLanguageData) {
        await commonLanguageStore.fetchData(brand, VDStatus.PUBLISHED, seriesId, year);
      }
      await specsStore.fetchData(
        brand,
        team,
        seriesId,
        year,
        vehicleModels,
        grades || [],
        teamStore.team.langPermissions,
        versionInfo
      );
    } catch (e) {
      toast.error('Error loading specs data');
      console.log(e.message);
    }
    setIsLoaded(true);
  }, [
    brand,
    specsStore,
    seriesId,
    team,
    vehicleModels,
    year,
    versionInfo,
    commonLanguageStore,
    grades,
    lastUpdated,
  ]);
  useEffect(() => {
    onLoad();
  }, [onLoad]);
  const {
    OppositeTeamSort,
    getSortListForOppositeTeam,
    turnOnOppositeTeamSort,
  } = useOppositeTeamSort(brand, team, seriesId, year, VDSortableEntity.SPECS);
  const updateSpecItem = async (
    spec: SpecItem,
    compareChangeMessageRequest: boolean,
    language: string,
    acceptChanges: boolean = false,
    unlinkFromTMNA: boolean = false
  ) => {
    if (spec.isValid()) {
      try {
        debounce(async () => {
          const response = await trackPromise(
            updateVehicleSpec(
              brand,
              team,
              seriesId,
              year,
              spec.getPayload(),
              language,
              acceptChanges,
              unlinkFromTMNA
            )
          );
          spec.revId = response.data.revId;
          spec.fieldStatus = response.data.fieldStatus;
          if (acceptChanges || unlinkFromTMNA) {
            spec.changedAttributes = [];
            spec.changedModelIds = [];
          }
          if (unlinkFromTMNA) {
            spec.fromTMNA = false;
          }
          if (spec.compareFeatureId) {
            const compareFeature =
              specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[language];
            if (spec.specType.id) {
              compareFeature.subCategory.id = spec.specType.id;
            }
            compareFeature.category.id = spec.category.id;
            const compareRes = await trackPromise(
              updateCompareFeature(
                brand,
                team,
                seriesId,
                year,
                language,
                compareFeature.getPayload()
              )
            );
            compareFeature.revId = compareRes.data.revId;
          }
          toast.success(`${langNameMap[language]} Spec updated successfully`);
          if (compareChangeMessageRequest && spec.compareFeatureId && spec.id) {
            toast.success('Changes have been updated on the Compare View Page');
          }
        }, spec.uid);
      } catch (e) {
        handleErrorResponse(e, 'Spec failed update');
      }
    }
  };
  const addSpecItem = async (spec: SpecItem, language: string) => {
    if (spec.isValid()) {
      try {
        debounce(async () => {
          const response = await trackPromise(
            addVehicleSpec(brand, team, seriesId, year, language, spec.getPayload())
          );
          spec.id = response.data.id;
          spec.revId = response.data.revId;
          spec.fieldStatus = response.data.fieldStatus;
          toast.success('Spec added successfully');
        }, spec.uid);
      } catch (e) {
        handleErrorResponse(e, 'Spec failed add');
      }
    } else {
      showFormFieldError();
    }
  };
  const saveSpecLangMap = async (
    specLangMap: SpecsLangMap,
    compareChangeMessageRequest: boolean = false,
    lang: string = '',
    acceptChanges: boolean = false,
    unlinkFromTMNA: boolean = false
  ) => {
    if (lang && !specsStore.langWriteMap[lang as Language]?.canEdit) {
      toast.error(`You do not have permissions to update ${langNameMap[lang]} specs.`);
      return;
    }
    const langs = lang ? [lang] : specsStore.editableLangs;
    const promises: Promise<any>[] = [];
    let numValid = 0;
    for (const lang of specsStore.editableLangs) {
      // check to make sure all specs either have a revid or are valid
      const spec = specLangMap.langs[lang];
      if (!spec.revId && !spec.isValid()) {
        showFormFieldError();
        return;
      } else if (!spec.revId && !langs.includes(lang)) {
        // see features controller for reasoning
        langs.push(lang);
      }
    }
    for (const lang of langs) {
      const spec = specLangMap.langs[lang];
      if (spec.isValid()) {
        numValid++;
        if (spec.revId) {
          promises.push(
            updateSpecItem(spec, compareChangeMessageRequest, lang, acceptChanges, unlinkFromTMNA)
          );
        } else {
          promises.push(addSpecItem(spec, lang));
        }
      }
    }
    if (!numValid) {
      showFormFieldError();
    } else {
      await Promise.all(promises);
    }
  };
  const deleteSpecLangMap = async (specLangMap: SpecsLangMap) => {
    try {
      const uid = specLangMap.langs[specsStore.allLangs[0]].uid;
      let specId = '';
      let hasCompareFeature = false;
      // only delete the features that the user has write permissions for
      for (const language of specsStore.editableLangs) {
        const spec = specLangMap.langs[language];
        specId = spec.id;
        if (spec.revId) {
          await trackPromise(deleteVehicleSpec(brand, team, seriesId, year, language, spec.id));
        }
        if (spec.compareFeatureId) {
          await trackPromise(
            deleteCompareFeature(brand, team, seriesId, year, language, spec.compareFeatureId)
          );
          hasCompareFeature = true;
        }
      }
      specsStore.deleteItem(uid);
      if (hasCompareFeature) {
        delete specsStore.compareFeaturesMap.compareFeatures[specId];
        toast.success('Changes have been updated on the Compare View Page');
      }
      toast.success('Spec deleted sucessfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting spec');
    }
  };
  const copySpecLangMap = async (specLangMap: SpecsLangMap) => {
    try {
      const copiedSpecLangMap = specsStore.copyMap(
        JSON.parse(JSON.stringify(specLangMap)),
        vehicleModels
      );
      const specId = uuidv4();
      let isValid = true;
      specsStore.allLangs.forEach(lang => {
        copiedSpecLangMap.langs[lang].id = specId;
        if (!copiedSpecLangMap.langs[lang].isValid()) {
          isValid = false;
        }
      });
      if (isValid) {
        for (const lang of specsStore.allLangs) {
          const copiedSpec = copiedSpecLangMap.langs[lang];
          if (specsStore.langWriteMap[lang]?.canEdit) {
            // if the user has write permissions for this language then we should create the feature on the backend too
            const response = await trackPromise(
              addVehicleSpec(brand, team, seriesId, year, lang, copiedSpec.getPayload())
            );
            copiedSpec.revId = response.data.revId;
          }
        }
        const sortPayload = getSortPayload(
          specsStore.specLangMaps.map(langMap => langMap.langs[specsStore.editableLangs[0]])
        );
        await trackPromise(
          updateSortList(brand, team, seriesId, year, VDSortableEntity.SPECS, sortPayload)
        );
        toast.success('Spec copied successfully');
      } else {
        showFormFieldError();
      }
    } catch (e) {
      handleErrorResponse(e, 'Spec failed copy');
    }
  };
  const addEmptySpecItem = () => {
    specsStore.addItem(vehicleModels);
    showFormFieldError();
  };
  const addCategoryItem = async (payload: { [lang: string]: string }, id?: string) => {
    try {
      const response = await trackPromise(addCategories(brand, team, seriesId, year, payload, id));
      const refItemsMap: RefItemsMap = JSON.parse(JSON.stringify(specsStore.categoriesMap));
      Object.keys(response.data).forEach(lang => {
        specsStore.updateCategoriesLangMap(
          lang as Language,
          refItemsMap,
          response.data[lang],
          specsStore.categoriesSortList
        );
      });
      specsStore.fillOutCategoriesMap(refItemsMap);
      specsStore.categoriesMap = refItemsMap;
      toast.success('Category added successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding category');
    }
  };
  const updateCategoryItem = async (
    refItemLangMap: RefItemLangMap,
    payload: { [lang: string]: string }
  ) => {
    try {
      for (const lang of specsStore.editableLangs) {
        if (payload[lang]) {
          await trackPromise(
            updateCategory(
              brand,
              team,
              seriesId,
              year,
              lang,
              refItemLangMap[lang].id,
              payload[lang]
            )
          );
        }
      }
      specsStore.editableLangs.forEach(lang => {
        if (payload[lang]) {
          refItemLangMap[lang].value = payload[lang];
        }
      });
      const catMap = specsStore.categoriesMap;
      for (const lang of specsStore.editableLangs) {
        if (payload[lang]) {
          catMap.categories[refItemLangMap[lang].id] = refItemLangMap;
          break;
        }
      }
      specsStore.categoriesMap = catMap;
      toast.success('Category updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error editing category');
    }
  };
  const addSpecTypeItem = async (payload: { [lang: string]: string }, id?: string) => {
    try {
      const response = await trackPromise(addSpecTypes(brand, team, seriesId, year, payload, id));
      const specTypeMap: RefItemsMap = JSON.parse(JSON.stringify(specsStore.specTypeMap));
      Object.keys(response.data).forEach(lang => {
        specsStore.updateCategoriesLangMap(
          lang as Language,
          specTypeMap,
          response.data[lang],
          specsStore.specTypesSortList
        );
      });
      specsStore.fillOutCategoriesMap(specTypeMap);
      specsStore.specTypeMap = specTypeMap;
      toast.success('SpecType added successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding specType');
    }
  };
  const updateSpecTypeItem = async (
    specTypeLangMap: RefItemLangMap,
    payload: { [lang: string]: string }
  ) => {
    try {
      for (const lang of specsStore.editableLangs) {
        if (payload[lang] && specsStore.langWriteMap[lang]?.canEdit) {
          await trackPromise(
            updateSpecType(
              brand,
              team,
              seriesId,
              year,
              lang,
              specTypeLangMap[lang].id,
              payload[lang]
            )
          );
        }
      }
      specsStore.editableLangs.forEach(lang => {
        if (payload[lang]) {
          specTypeLangMap[lang].value = payload[lang];
        }
      });
      const specTypeMap = specsStore.specTypeMap;
      for (const lang of specsStore.editableLangs) {
        if (payload[lang]) {
          specTypeMap.categories[specTypeLangMap[lang].id] = specTypeLangMap;
          break;
        }
      }
      specsStore.specTypeMap = specTypeMap;
      toast.success('SpecType updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error editing specType');
    }
  };
  const addCommonLanguageSpec = async (ids: string[]) => {
    try {
      const response = await trackPromise(
        importFromCommonLanguage(brand, team, seriesId, year, ids)
      );
      const promises: Promise<AxiosResponse<any>>[] = [];
      specsStore.allLangs.forEach(lang => {
        promises.push(
          getCategoriesByLang(
            brand,
            team,
            seriesId,
            year,
            lang.toUpperCase(),
            versionInfo[lang]?.toString()
          )
        );
      });
      const categoryResponse = await Promise.all(promises);
      const numLangs = specsStore.allLangs.length;
      for (let i = 0; i < numLangs; i += 1) {
        const lang = specsStore.allLangs[i];
        specsStore.updateCategoriesLangMap(
          lang,
          specsStore.categoriesMap,
          categoryResponse[i].data,
          specsStore.categoriesSortList
        );
      }
      specsStore.fillOutCategoriesMap(specsStore.categoriesMap);
      const comLangSpecMap: SpecsLangMap[] = [];
      for (const data of response.data) {
        const specLangMap: SpecsLangMap = { langs: {}, data };
        const comLangItem = specItemXForm(
          data,
          vehicleModels,
          specsStore.getDefaultCategories(specsStore.categoriesMap),
          specsStore.getDefaultCategories(specsStore.specTypeMap),
          specsStore.specLangMaps.length + comLangSpecMap.length,
          {}
        );
        if (
          comLangItem.comLangId &&
          !specsStore
            .getDefaultSpecs(specsStore.specLangMaps)
            .filter(
              fitem => fitem.comLangId === comLangItem.comLangId && fitem.id === comLangItem.id
            ).length
        ) {
          specLangMap.langs[specsStore.defaultLang] = comLangItem;
          specsStore.allLangs.forEach(lang => {
            if (!specLangMap.langs[lang]) {
              specLangMap.langs[lang] = specItemXForm(
                data,
                vehicleModels,
                specsStore.getCategoriesForLang(lang, specsStore.categoriesMap),
                specsStore.getCategoriesForLang(lang, specsStore.specTypeMap),
                specsStore.specLangMaps.length + comLangSpecMap.length,
                specsStore.getCompareFeatureMapForLang(lang)
              );
              specLangMap.langs[lang].revId = '';
              specLangMap.langs[lang].description = '';
            }
          });
          comLangSpecMap.push(specLangMap);
        }
      }
      specsStore.specLangMaps = [...comLangSpecMap, ...specsStore.specLangMaps];
      specsStore.filteredSpecLangMaps = [...comLangSpecMap, ...specsStore.filteredSpecLangMaps];
    } catch (e) {
      handleErrorResponse(e, 'Error adding common language feature');
    }
  };
  const showFormFieldError = () => {
    toast.error('Please finish filling out all items of the new spec.');
  };
  const onSaveSortCategories = async (list: IDValueType[], dataType: VDSortableEntity) => {
    try {
      const catPayload = getSortPayload(list);
      await trackPromise(updateSortList(brand, team, seriesId, year, dataType, catPayload));
      let featuresPayload;
      if (
        dataType === VDSortableEntity.SPECS_CATEGORIES ||
        dataType === VDSortableEntity.SPECS_SPECTYPE
      ) {
        const categories =
          dataType === VDSortableEntity.SPECS_CATEGORIES
            ? list
            : specsStore.getDefaultCategories(specsStore.categoriesMap);
        const specTypes =
          dataType === VDSortableEntity.SPECS_SPECTYPE
            ? list
            : specsStore.getDefaultCategories(specsStore.specTypeMap);
        const specsCopy = getSortedCopy<SpecItem>(
          specsStore.getDefaultSpecs(specsStore.specLangMaps),
          item => item.specType.id,
          specTypes
        );
        const specsCopy2 = getSortedCopy<SpecItem>(specsCopy, item => item.category.id, categories);
        featuresPayload = getSortPayload(specsCopy2);
      }
      if (featuresPayload) {
        await trackPromise(
          updateSortList(brand, team, seriesId, year, VDSortableEntity.SPECS, featuresPayload)
        );
        setIsLoaded(false);
        specsStore.resetFilters();
        await trackPromise(
          specsStore.fetchData(
            brand,
            team,
            seriesId,
            year,
            vehicleModels,
            grades || [],
            teamStore.team.langPermissions,
            versionInfo
          )
        );
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating category sort');
    }
    setIsLoaded(true);
  };
  const onStopSorting = async () => {
    setSortMode(false);
    try {
      const featureItems = specsStore.getDefaultSpecs(specsStore.filteredSpecLangMaps);
      const sortPayload = getSortPayload(featureItems);
      await trackPromise(
        updateSortList(brand, team, seriesId, year, VDSortableEntity.SPECS, sortPayload)
      );
      setIsLoaded(false);
      await trackPromise(
        specsStore.fetchData(
          brand,
          team,
          seriesId,
          year,
          vehicleModels,
          grades || [],
          teamStore.team.langPermissions,
          versionInfo
        )
      );
      setIsLoaded(true);
    } catch (e) {
      handleErrorResponse(e, 'Error updating spec sort');
    }
  };
  const handleCompareFeatureHighlighted = async (specLangMap: SpecsLangMap) => {
    if (!updatingCompareFeatureLink.current) {
      for (const lang of specsStore.editableLangs) {
        if (!specLangMap.langs[lang].isValid() || !specLangMap.langs[lang].revId) {
          toast.error('Please finish filling out all items of the new spec.');
          return;
        }
      }
      updatingCompareFeatureLink.current = true;
      const compareFeatureId = uuidv4();
      let hasFailed = false;
      const hasCompareFeature = !!specLangMap.langs[specsStore.defaultLang].compareFeatureId;
      for (const lang of specsStore.editableLangs) {
        try {
          const spec = specLangMap.langs[lang];
          if (!spec.compareFeatureId && !hasCompareFeature) {
            const compareFeatureResponse = await trackPromise(
              addCompareFeatureFromParent(
                brand,
                team,
                seriesId,
                year,
                lang,
                spec.id,
                CompareType.Spec,
                spec.description,
                spec.category.id,
                spec.specType.id || '',
                grades || [],
                compareFeatureId
              )
            );
            if (!specsStore.compareFeaturesMap.compareFeatures[spec.id]) {
              specsStore.compareFeaturesMap.compareFeatures[spec.id] = {
                langs: {},
                data: compareFeatureResponse.data,
              };
            }
            const cats: RefItem[] = specsStore.getCategoriesForLang(lang, specsStore.categoriesMap);
            const specTypes: RefItem[] = specsStore.getCategoriesForLang(
              lang,
              specsStore.specTypeMap
            );
            specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[
              lang
            ] = compareFeatureItemXForm(
              compareFeatureResponse.data,
              grades || [],
              [],
              [],
              cats,
              specTypes,
              0
            );
            spec.compareFeatureId =
              specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[lang].id;
          } else if (spec.compareFeatureId && hasCompareFeature) {
            const compareFeature =
              specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[lang];
            compareFeature.parentId = '';
            await trackPromise(
              updateCompareFeature(brand, team, seriesId, year, lang, compareFeature.getPayload())
            );
            delete specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[lang];
            spec.compareFeatureId = '';
            if (!Object.keys(specsStore.compareFeaturesMap.compareFeatures[spec.id].langs).length) {
              delete specsStore.compareFeaturesMap.compareFeatures[spec.id];
            }
          }
        } catch (e) {
          hasFailed = true;
          handleErrorResponse(e, 'Spec failed update');
          break;
        }
      }
      if (!hasFailed) {
        toast.success('Spec updated successfully');
        toast.success('Changes have also been updated on the Compare View page');
      }
      updatingCompareFeatureLink.current = false;
    }
  };
  const syncUpdates = async () => {
    setIsLoaded(false);
    try {
      const res = await syncSpanishUpdates(brand, team, seriesId, year);
      displaySyncMessage(res.data.onlyStatusSyncUpdates);
      if (reloadDraft) {
        setIsLoaded(true);
        const teamParam = VehicleTeam.AGENCY_SPANISH;
        const url =
          brand !== BRAND_TDPR
            ? `/vehicleData/draft/${teamParam}/${seriesId}/${year}/EN:${res.data.sourceVersion}|ES:DRAFT?team=${teamParam}&tab=${VDTab.SPECS}`
            : '';
        reloadDraft(url);
      }
    } catch (e) {
      handleErrorResponse(e, 'Error syncing spanish data');
      setIsLoaded(true);
    }
  };
  const getActionBarButtons = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];
    if (showActionButtons && teamStore.team.allowAddDeleteData) {
      if (process.env.REACT_APP_STANDARD_SPEC === 'true') {
        actionBarButtons.push(
          <DropdownAdd btnText="Add Spec">
            <>
              {teamStore.team.allowCommonLanguageData && (
                <IconTextButton
                  icon="link"
                  text="Add Common Language"
                  onClick={() => {
                    setShowAddCommonLanguage(true);
                  }}
                />
              )}
              {process.env.REACT_APP_STANDARD_SPEC === 'true' && (
                <IconTextButton
                  icon="plus"
                  text="Add Standard Spec"
                  onClick={() => {
                    setShowAddStandardSpec(true);
                  }}
                />
              )}
              <IconTextButton icon="plus" text="Add Spec" onClick={addEmptySpecItem} />
            </>
          </DropdownAdd>
        );
      } else {
        if (teamStore.team.allowCommonLanguageData) {
          actionBarButtons.push(
            <IconTextButton
              icon="link"
              text="Add Common Language"
              onClick={() => {
                setShowAddCommonLanguage(true);
              }}
            />
          );
        }
        actionBarButtons.push(
          <IconTextButton icon="plus" text="Add Spec" onClick={addEmptySpecItem} />
        );
      }
      actionBarButtons.push(
        sortMode ? (
          <SortButton toggled onClick={onStopSorting}>
            Stop Sorting
          </SortButton>
        ) : (
          <>
            <SortDropdown
              buttonText="Sort"
              list={getSortListForOppositeTeam(
                teamStore.team.name,
                teamStore.team.allowSpecType
                  ? ['Rows', 'Categories', 'Spec Types']
                  : ['Rows', 'Categories']
              )}
              onSelect={value => {
                switch (value) {
                  case 'Rows': {
                    specsStore.resetFilters();
                    setSortMode(true);
                    break;
                  }
                  case 'Categories': {
                    setSortCategoryModal(true);
                    break;
                  }
                  case 'Spec Types': {
                    setSortSpecTypeModal(true);
                    break;
                  }
                  case OppositeTeamSort.APPLY_PRODUCT_SORT:
                  case OppositeTeamSort.APPLY_AGENCY_SORT: {
                    turnOnOppositeTeamSort(
                      specsStore.specLangMaps,
                      specsStore.resetFilters,
                      specsStore.setLangMapList,
                      setSortMode
                    );
                    break;
                  }
                }
              }}
            />
            <SortModal
              open={sortCategoryModal}
              onClose={() => setSortCategoryModal(false)}
              onSave={list => onSaveSortCategories(list, VDSortableEntity.SPECS_CATEGORIES)}
              idValueList={specsStore.getDefaultCategories(specsStore.categoriesMap)}
              headerText="Sort Categories"
            />
            <SortModal
              open={sortSpecTypeModal}
              onClose={() => setSortSpecTypeModal(false)}
              onSave={list => onSaveSortCategories(list, VDSortableEntity.SPECS_SPECTYPE)}
              idValueList={specsStore.getDefaultCategories(specsStore.specTypeMap)}
              headerText="Sort Spec Types"
            />
          </>
        )
      );
    }
    const langButtons = getLangActionBarButtons(
      {
        allLangs: specsStore.allLangs,
        selectedLangsMap: specsStore.selectedLangsMap,
        updateSelectedLangs: specsStore.updateSelectedLangs,
        showActionButtons,
      },
      {
        canSyncUpdates: teamStore.team.canSyncUpdates,
        sourceENVersion: versionInfo.EN?.toString(),
        brand,
        team,
        seriesId,
        year,
        syncUpdates,
      }
    );
    actionBarButtons.push(...langButtons);
    return actionBarButtons.map((button, index) => (
      <React.Fragment key={index}>
        <ActionBarDivider />
        {button}
      </React.Fragment>
    ));
  };
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }
    const [removed] = specsStore.filteredSpecLangMaps.splice(result.source.index, 1);
    specsStore.filteredSpecLangMaps.splice(result.destination.index, 0, removed);
    specsStore.filteredSpecLangMaps.forEach((item, index: number) => {
      Object.values(item.langs).forEach(specItem => {
        specItem.sortOrder = index + 1;
      });
    });
    return specsStore.filteredSpecLangMaps;
  };
  const visibleModels = () => {
    return brand !== BRAND_TDPR
      ? vehicleModels
      : vehicleModels.filter(model => model.getVal('isTDPR') || model.getVal('isUSVI'));
  };
  const changeModalApplicability = (item: SpecItem | OptionItem, modApp: KeyValueType<string>) => {
    Object.values(item).forEach(model => {
      model.setting = modApp[model.id]
        ? (modApp[model.id] as SpecSettings)
        : SpecSettings.UNDEFINED;
    });
  };
  const { getSyncChangesModal, compareChanges } = useSyncChangesModal(
    brand,
    team,
    seriesId,
    year,
    specsStore,
    vehicleModels,
    'specs',
    (langMap: SpecsLangMap | OptionLangMap) => {
      deleteSpecLangMap(langMap as SpecsLangMap);
    },
    (langMap: SpecsLangMap | OptionLangMap, acceptChanges: boolean, unlinkFromTMNA: boolean) => {
      saveSpecLangMap(langMap as SpecsLangMap, false, undefined, acceptChanges, unlinkFromTMNA);
    },
    changeModalApplicability
  );
  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBarVehicleData
        readOnly={readOnly}
        toggleViewModelCodes={() => (specsStore.viewModelCodes = !specsStore.viewModelCodes)}
        viewModelCodes={specsStore.viewModelCodes}
        searchText={specsStore.searchText}
        onSearchTextChange={text => specsStore.onFilter(() => (specsStore.searchText = text))}
        renderButtons={getActionBarButtons(!readOnly)}
        renderFilter={onClose => (
          <SpecsFilters
            onClose={onClose}
            categories={specsStore
              .getDefaultCategories(specsStore.categoriesMap)
              .map(cat => cat.value)}
            categoryFilters={specsStore.categoryFilters}
            setCategoryFilters={categoryFilters =>
              specsStore.onFilter(() => (specsStore.categoryFilters = categoryFilters))
            }
            specTypes={specsStore
              .getDefaultCategories(specsStore.specTypeMap)
              .map(spec => spec.value)}
            specTypesFilters={specsStore.specTypeFilters}
            setSpecTypesFilters={specTypeFilters =>
              specsStore.onFilter(() => (specsStore.specTypeFilters = specTypeFilters))
            }
            isInProgressFilter={specsStore.isInProgressFilter}
            setIsInProgressFilter={value =>
              specsStore.onFilter(() => (specsStore.isInProgressFilter = value))
            }
            isSyncUpdateFilter={specsStore.isSyncUpdateFilter}
            setIsSyncUpdateFilter={value =>
              specsStore.onFilter(() => (specsStore.isSyncUpdateFilter = value))
            }
            brand={brand}
            isReviewNotesFilter={specsStore.isReviewNotesFilter}
            setIsReviewNotesFilter={
              team === VehicleTeam.AGENCY_TEAM && version == null
                ? value => specsStore.onFilter(() => (specsStore.isReviewNotesFilter = value))
                : undefined
            }
            isPublished={isPublished}
          />
        )}
      />
      <TwoTableWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <LeftTable>
            <SpecsHeaderRow
              viewModelCodes={specsStore.viewModelCodes}
              allowLinks={teamStore.team.allowLinks}
              readOnly={readOnly}
              onSort={specsStore.onSort}
              showSpecTypes={teamStore.team.allowSpecType}
              showSpecLinkModal={teamStore.team.allowShowSpecLinkModal}
              sortMode={sortMode}
              languages={specsStore.allLangs.filter(lang => specsStore.selectedLangsMap[lang])}
              team={teamStore.team.name}
              brand={brand}
              setLastUpdated={setLastUpdated}
            />
            <SpecRowsContainer
              specLangMaps={specsStore.filteredSpecLangMaps}
              saveSpecLangMap={saveSpecLangMap}
              deleteSpecLangMap={deleteSpecLangMap}
              copySpecLangMap={copySpecLangMap}
              addCategoryItem={addCategoryItem}
              updateCategoryItem={updateCategoryItem}
              addSpecTypeItem={addSpecTypeItem}
              updateSpecTypeItem={updateSpecTypeItem}
              handleCompareFeatureHighlighted={handleCompareFeatureHighlighted}
              showLink={team === VehicleTeam.AGENCY_TEAM}
              readOnly={readOnly}
              sortMode={sortMode}
              showSpecLinkModal={teamStore.team.allowShowSpecLinkModal}
              showSpecType={teamStore.team.allowSpecType}
              allowDisclaimerTokens={teamStore.team.allowDisclaimerTokens}
              disclaimerTokens={disclaimerTokens}
              brand={brand}
              compareSpec={compareChanges}
            />
          </LeftTable>
        </DragDropContext>
        <ModelTable
          viewModelCodes={specsStore.viewModelCodes}
          models={visibleModels()}
          headerStyle={{ top: 0 }}
          renderRows={() => (
            <>
              {specsStore.filteredSpecLangMaps.map((specLangMap, idx) => {
                const defaultSpec = specLangMap.langs[specsStore.modelApplicabilityLang];
                let changedModelIds: string[] = [];
                for (const lang of specsStore.editableLangs) {
                  changedModelIds.push(...specLangMap.langs[lang].changedModelIds);
                }
                return (
                  <React.Fragment key={defaultSpec.uid}>
                    <TableRow rowHeight={specsStore.getRowHeight(specLangMap)}>
                      {visibleModels().map(model => (
                        <SpecsModelCell
                          model={model}
                          changedModelIds={changedModelIds}
                          defaultSpec={defaultSpec}
                          specLangMap={specLangMap}
                          idx={idx}
                          readOnly={readOnly}
                          saveSpecLangMap={saveSpecLangMap}
                          key={`${defaultSpec.uid}${model.uid}`}
                        />
                      ))}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </>
          )}
        />
      </TwoTableWrapper>
      <Modal
        open={showAddCommonLanguage}
        size="auto"
        onClose={() => setShowAddCommonLanguage(false)}
      >
        <AddCommonLanguageModal
          tabName="Specs"
          comLangIds={specsStore.getCommonLanguageIds()}
          onAddItems={addCommonLanguageSpec}
          onClose={() => setShowAddCommonLanguage(false)}
          buttonName="Spec(s)"
        />
      </Modal>
      <Modal open={showAddStandardiSpec} size="auto" onClose={() => setShowAddStandardSpec(false)}>
        <AddStandardSpecsModal
          tabName="Specs"
          onClose={() => setShowAddStandardSpec(false)}
          buttonName="Spec(s)"
          onAddCallback={onLoad}
        />
      </Modal>
      {getSyncChangesModal()}
    </>
  );
};
export default observer(SpecsController);
