import React from 'react';
import cx from 'clsx';
import { RichTextCell } from 'vapi-ui-common';
import { TableCell } from '../Table';
import { LimitedDataStatus } from '../LimitedDataStatus';
import { LIMITED_DATA_STATUS } from '../../constants/vehicleData/VDConstants';
import styles from './limitedDataTable.module.scss';

export interface LimitedDataCellProps {
  list?: (string | JSX.Element)[];
  inProgressCount?: number;
  isFieldColumn?: boolean;
  isRichText?: boolean;
}

export const LimitedDataCell = ({
  list,
  inProgressCount,
  isFieldColumn,
  isRichText,
  ...rest
}: LimitedDataCellProps) => {
  const getTableCell = () => {
    if (inProgressCount) {
      const indexes = [];
      for (let i = 0; i < inProgressCount; i++) {
        indexes.push(i);
      }
      return indexes.map((item: number, i: number) => (
        <div key={i} className={styles.textBlock}>
          <LimitedDataStatus status={LIMITED_DATA_STATUS.IN_PROGRESS} />
        </div>
      ));
    }

    return list?.map((item: string | JSX.Element, i: number) => (
      <div
        key={i}
        className={cx(styles.textBlock, {
          [styles.fieldColumn]: isFieldColumn,
          [styles.textBlockSize]: !isRichText,
          [styles.richTextSize]: isRichText,
        })}
      >
        {isRichText ? (
          <RichTextCell
            cellStyles={styles.richTextSection}
            disabled={true}
            colType="description"
            value={item as string}
          />
        ) : (
          item
        )}
      </div>
    ));
  };

  return (
    <TableCell className={styles.tableCellText} {...rest} data-testid="limtted-data-cell">
      {getTableCell()}
    </TableCell>
  );
};
