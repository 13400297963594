import Spinner from '../../components/Spinner';
import useRouteParams from '../../hooks/useRouteParams';
import useStores from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { dashboardXForm } from '../../utils/vehicleDataUtils';
import { getSeries, getUserSeries } from '../../webservices/adminApi';
import { getDashboardDetails } from '../../webservices/vehicleAdminApi';
import { VehicleTeam } from '../../models/vehicleData.model';
import SeriesManager from './modules/SeriesManager';
import VehicleDashboard from './modules/VehicleDashboard';
import { toGqlBrand } from '../../utils/graphqlUtils';

const VehicleData = observer(() => {
  const { userStore, vehicleDataStore, teamStore } = useStores();

  const { brand, objectId } = userStore;

  const [isLoaded, setIsLoaded] = useState(false);
  const [showSeriesManager, setShowSeriesManager] = useState(true);
  const { team } = useRouteParams();

  useEffect(() => {
    teamStore.setTeam(team, brand, userStore.langPermissions);
    userStore.setTeamModule(team);

    (async () => {
      setIsLoaded(false);
      vehicleDataStore.reset();

      try {
        const [
          allSeriesResponse,
          userSeriesResponse,
          dashboardResponse,
        ] = await Promise.allSettled([
          getSeries({ brand: toGqlBrand(brand) }),
          getUserSeries({ brand: toGqlBrand(brand), objectId }),
          getDashboardDetails(brand, teamStore.team.param, team === VehicleTeam.AGENCY_SPANISH),
        ]);

        if (allSeriesResponse.status === 'rejected' || userSeriesResponse.status === 'rejected') {
          throw new Error('Failed to load vehicle data');
        }

        vehicleDataStore.series = allSeriesResponse.value.series;
        vehicleDataStore.userSeries = userSeriesResponse.value.seriesSelections;

        if (dashboardResponse.status === 'fulfilled') {
          vehicleDataStore.dashboardSeries = dashboardXForm(
            dashboardResponse.value.data,
            vehicleDataStore.series,
            userStore.teamModule,
            teamStore.team.param
          );
        }

        if (vehicleDataStore.dashboardSeries.length) {
          setShowSeriesManager(false);
        } else {
          setShowSeriesManager(true);
        }
      } catch (e) {
        toast.error('Error loading vehicle data');
      }

      setIsLoaded(true);
    })();
  }, [teamStore, userStore, vehicleDataStore, brand, objectId, team]);

  return isLoaded ? (
    showSeriesManager ? (
      <SeriesManager onShowSeriesManager={() => setShowSeriesManager(false)} />
    ) : (
      <VehicleDashboard onShowSeriesManager={() => setShowSeriesManager(true)} />
    )
  ) : (
    <Spinner />
  );
});

export default VehicleData;
