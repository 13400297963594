import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../components/ChangeLogInner';
import useStores from '../../../../hooks/useStores';
import { ChangeLogItem, ChangeLogLangMap } from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import {
  getChangeLog as getBnpChangeLog,
  revertChange as revertBnpChange,
} from '../../../../webservices/vehicleBnPApi';
import useChangeLogChanges from './hooks/useChangeLogChanges';
import { changeLogBnpStatusMapper } from '../../../../utils/changeLogUtils';
import { toGqlBrand, toGqlFilter, toGqlLanguage, toGqlTeam } from '../../../../utils/graphqlUtils';

interface BnPChangeLogProps {
  seriesId: string;
  year: string;
  readOnly?: boolean;
  version: string;
}

const BnPChangeLog = ({ seriesId, year, readOnly, version }: BnPChangeLogProps) => {
  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);
  const { filterChangeLogChanges, changeLogChanges, canRevert } = useChangeLogChanges();

  useEffect(() => {
    changeLogStore.reset();

    (async () => {
      setIsLoaded(false);

      try {
        const selectedLangs: KeyValueType<boolean> = {};
        teamStore.team.changeLogLanguages.forEach(lang => {
          selectedLangs[lang] = true;
        });
        changeLogStore.selectedLangsMap = selectedLangs;

        const responses = await Promise.all(
          teamStore.team.changeLogLanguages.map(lang =>
            getBnpChangeLog({
              brand: toGqlBrand(brand),
              team: toGqlTeam(teamStore.team.param),
              seriesId,
              modelYear: parseInt(year),
              language: toGqlLanguage(lang),
              filter: toGqlFilter(version),
            })
          )
        );

        let index = 0;
        const changeLogLangMap: ChangeLogLangMap = {};
        for (const lang of teamStore.team.changeLogLanguages) {
          const logs = responses[index]
            .map(
              item =>
                new ChangeLogItem(
                  item,
                  !readOnly && teamStore.team.param !== VehicleTeam.PRODUCT_TEAM,
                  lang
                )
            )
            .filter(filterChangeLogChanges);
          changeLogBnpStatusMapper(logs);
          changeLogLangMap[lang] = logs;
          index += 1;
        }

        changeLogStore.changeLogLangMap = changeLogLangMap;
      } catch (e) {
        toast.error('Error loading features change log');
      }

      setIsLoaded(true);
    })();
  }, [brand, changeLogStore, readOnly, seriesId, teamStore, year, version, filterChangeLogChanges]);

  const handleOnRevert = async (item: ChangeLogItem) => {
    const language = item.language ?? Language.EN;
    try {
      await trackPromise(
        revertBnpChange({
          brand: toGqlBrand(brand),
          team: toGqlTeam(teamStore.team.param),
          seriesId,
          modelYear: parseInt(year),
          language: toGqlLanguage(language),
          payload: {
            name: item.name,
            category: item.category,
            changeType: item.changeType,
            from: item.payload.from,
            to: item.payload.to ?? '',
            bnpSplitId: item.bnpSplitId,
          },
        })
      );
      const response = await getBnpChangeLog({
        brand: toGqlBrand(brand),
        team: toGqlTeam(teamStore.team.param),
        seriesId,
        modelYear: parseInt(year),
        language: toGqlLanguage(language),
        filter: toGqlFilter(version),
      });
      const logs = response.map(
        item =>
          new ChangeLogItem(
            item,
            !readOnly && teamStore.team.param !== VehicleTeam.PRODUCT_TEAM,
            language
          )
      );
      changeLogStore.changeLogLangMap = { ...changeLogStore.changeLogLangMap, [language]: logs };

      toast.success('Change log reverted');
    } catch {
      toast.error('Error reverting change log');
    }
  };
  return (
    <ChangeLogInner
      module={'Feature'}
      isLoaded={isLoaded}
      handleOnRevert={handleOnRevert}
      changeLogChanges={changeLogChanges}
      canRevert={canRevert}
    />
  );
};

export default observer(BnPChangeLog);
