import { IDValueType } from './common.model';

export interface RefItemResponse<T = RefItemObject> {
  id: string;
  object: T;
}

export interface RefItemObject {
  id: string;
  isDeleted: boolean;
  isRequired: boolean;
  name: string;
}

export class RefItem extends IDValueType {
  isDeleted = false;
  isRequired = false;

  constructor(response?: RefItemObject) {
    super('', '');
    if (response) {
      this.id = response.id;
      this.value = response.name;
      this.isDeleted = response.isDeleted;
      this.isRequired = response.isRequired;
    }
  }

  getPayload = () => {};
}
