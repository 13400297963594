import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';
import useStores from '../../../hooks/useStores';
import { DisclaimersItem } from '../../../models/disclaimers.model';
import { getDisclaimers } from '../../../webservices/disclaimersApi';
import { getAvailableModels } from '../../../webservices/vehicleAdminApi';
import ActionBarDisclaimers from '../components/ActionBarDisclaimers';
import DisclaimersTable from '../components/DisclaimersTable';
import DisclaimerRow from '../components/DisclaimersTable/components/DisclaimerRow';

const DisclaimersArchive = observer(() => {
  const {
    userStore: { brand, region },
    disclaimersStore,
  } = useStores();

  const config = {
    readOnly: true,
    isArchived: true,
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    disclaimersStore.reset();

    (async () => {
      try {
        const responses = await Promise.all([
          getDisclaimers(brand, region, 'archived'),
          getAvailableModels(brand),
        ]);

        disclaimersStore.setData(responses[0].map(item => new DisclaimersItem(item)));
        disclaimersStore.vehicleModels = responses[1].data;
      } catch (e) {
        toast.error('Error loading archived data');
      }

      setIsLoaded(true);
    })();

    return () => {
      disclaimersStore.setData([]);
    };
  }, [brand, disclaimersStore, region]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="Disclaimers" moduleSubTitle="Archive" />
      <ActionBarDisclaimers
        readOnly={config.readOnly}
        searchText={disclaimersStore.searchText}
        setSearchText={value => {
          disclaimersStore.searchText = value;
          disclaimersStore.filterData();
        }}
        vehicleModels={disclaimersStore.vehicleModels}
        vehicleFilters={disclaimersStore.vehicleFilters}
        setFilters={disclaimersStore.setFilters}
        onResetDraft={() => {}}
        onAddDisclaimerItem={() => {}}
      />
      <DisclaimersTable
        isArchived
        onSort={disclaimersStore.setSort}
        renderRows={() => (
          <>
            {disclaimersStore.filteredData.map(item => (
              <DisclaimerRow
                key={item.uid}
                item={item}
                config={config}
                vehicleModels={disclaimersStore.vehicleModels}
              />
            ))}
          </>
        )}
      />
    </>
  );
});

export default DisclaimersArchive;
