import { convertToRichTextObject } from 'vapi-ui-common';
import { IDValueType, KeyValueType } from '../models/common.model';
import {
  OptionsPackage,
  OptionsPackageSpec,
  OptionsPackageSpecModel,
  OptionsPackageTotal,
  OptionsPackageVariety,
  VDOptionLexus,
  VDPackageLexus,
  VDPackageSpecLexus,
} from '../models/optionsLexus.model';
import { ReviewChangeBaseItem, ReviewChangeResponse } from '../models/review.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../models/vehicleModel.model';
import { changeLogModelApplicabilityItemMapper } from './changeLogUtils';

const PACKAGE_TOTAL_APPLICABILITY_FIELD = 'packageTotalApplicabiity';
const PACKAGE_TOTAL_REQUIRED_APPLICABILITY_FIELD = 'packageTotalReqApplicabiity';
const PACKAGE_VARIETIES_FIELD = 'packageVarieties';
const OPTION_TOTAL_APPLICABILITY_FIELD = 'optionTotalApplicabiity';

/*
PACKAGE XFORMS
*/
export const optionsLexusXForm = (
  data: VDPackageLexus[],
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  return data.map((item, index) => optionsLexusItemXForm(item, vehicleModels, index));
};

export const optionsLexusItemXForm = (
  item: VDPackageLexus,
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  index: number
) => {
  const optionItem = new OptionsPackage(item);
  optionItem.sortOrder = index + 1;
  if (item.changes) {
    optionItem.changes = Object.entries(item.changes)
      .filter(([field]) => !isApplicabilityField(field))
      .map(([field, change]) => new ReviewChangeBaseItem(change, field));
  }

  if (item.otherChanges) {
    optionItem.otherChanges = Object.entries(item.otherChanges).map(
      ([changeTypeId, otherChange]) => {
        const newItem = new ReviewChangeBaseItem(otherChange, changeTypeId);
        changeLogModelApplicabilityItemMapper(vehicleModels, newItem.changes);
        return newItem;
      }
    );
  }

  // process total applicability
  optionItem.packageTotals = processTotalApplicability(
    vehicleModels,
    item.packageTotalApplicabiity,
    item.changes,
    field => [PACKAGE_TOTAL_APPLICABILITY_FIELD, OPTION_TOTAL_APPLICABILITY_FIELD].includes(field)
  );

  // process total req applicability
  optionItem.packageTotalReqs = processTotalApplicability(
    vehicleModels,
    item.packageTotalReqApplicabiity,
    item.changes,
    field => field === PACKAGE_TOTAL_REQUIRED_APPLICABILITY_FIELD
  );

  // process varieties
  if (item.packageVarieties) {
    const varieties = item.packageVarieties;
    const packageVarieties: OptionsPackageVariety[] = Object.entries(varieties).map(
      ([key, variety]) => {
        const newItem = new OptionsPackageVariety(variety);
        newItem.applicability = vehicleModels.map(
          mdl =>
            new IDValueType(
              mdl.id,
              variety.modelApplicability ? variety.modelApplicability[mdl.id] : ''
            )
        );

        if (variety.changes) {
          newItem.changes = Object.entries(variety.changes).map(([field, change]) => {
            const varChange = new ReviewChangeBaseItem(change, field);
            changeLogModelApplicabilityItemMapper(vehicleModels, varChange.changes);
            return varChange;
          });
        }

        if (variety.otherChanges) {
          newItem.otherChanges = Object.entries(variety.otherChanges).map(
            ([changeTypeId, otherChange]) => {
              const varChange = new ReviewChangeBaseItem(otherChange, changeTypeId);
              changeLogModelApplicabilityItemMapper(vehicleModels, varChange.changes);
              return varChange;
            }
          );
        }
        return newItem;
      }
    );
    optionItem.packageVarieties = packageVarieties;
  }

  // process specs
  if (item.packageSpecs) {
    optionItem.specs = processSpecs(item.packageSpecs, vehicleModels);
  }

  return optionItem;
};

/*
OPTION XFORMS
*/
export const optionsOptionLexusXForm = (
  data: VDOptionLexus[],
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  return data.map((item, index) => optionsOptionLexusItemXForm(item, vehicleModels, index));
};

export const optionsOptionLexusItemXForm = (
  item: VDOptionLexus,
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  index: number
) => {
  const optionItem = new OptionsPackage(item);
  optionItem.isOption = true;

  if (item.changes) {
    optionItem.changes = Object.entries(item.changes)
      .filter(([field]) => !isApplicabilityField(field))
      .map(([field, change]) => new ReviewChangeBaseItem(change, field));
  }

  if (item.otherChanges) {
    optionItem.otherChanges = Object.entries(item.otherChanges).map(
      ([changeTypeId, otherChange]) => {
        const newItem = new ReviewChangeBaseItem(otherChange, changeTypeId);
        changeLogModelApplicabilityItemMapper(vehicleModels, newItem.changes);
        return newItem;
      }
    );
  }

  optionItem.packageTotals = processTotalApplicability(
    vehicleModels,
    item.optionTotalApplicabiity,
    item.changes,
    field => [PACKAGE_TOTAL_APPLICABILITY_FIELD, OPTION_TOTAL_APPLICABILITY_FIELD].includes(field)
  );

  if (item.optionSpecs) {
    optionItem.specs = processSpecs(item.optionSpecs, vehicleModels);
  }
  optionItem.sortOrder = index + 1;
  return optionItem;
};

export const convertOptionsPropToText = (rawProp: string) => {
  return convertToRichTextObject(rawProp).text;
};

/*
HELPER FUNCTIONS
*/
const processTotalApplicability = (
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  applicability?: KeyValueType,
  changes?: KeyValueType<ReviewChangeResponse>,
  filter?: (field: string) => boolean,
  otherChanges?: KeyValueType<ReviewChangeResponse>
) => {
  const packageTotal = new OptionsPackageTotal();
  packageTotal.applicability = vehicleModels.map(
    mdl => new IDValueType(mdl.id, applicability ? applicability[mdl.id] : '')
  );

  if (applicability && changes) {
    packageTotal.changes = Object.entries(changes)
      .filter(([field]) => (filter ? filter(field) : true))
      .map(([field, change]) => {
        const revItem = new ReviewChangeBaseItem(change, field);
        changeLogModelApplicabilityItemMapper(vehicleModels, revItem.changes);
        return revItem;
      });
  }

  if (applicability && otherChanges) {
    packageTotal.otherChanges = Object.entries(otherChanges)
      .filter(([field]) => (filter ? filter(field) : true))
      .map(([field, change]) => {
        const revItem = new ReviewChangeBaseItem(change, field);
        changeLogModelApplicabilityItemMapper(vehicleModels, revItem.changes);
        return revItem;
      });
  }

  return packageTotal;
};

const processSpecs = (
  specs: KeyValueType<VDPackageSpecLexus>,
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  return Object.entries(specs).map(([key, specItem]) => {
    const newSpec = new OptionsPackageSpec(specItem);
    const models: OptionsPackageSpecModel[] = vehicleModels.map(mdl => ({
      id: mdl.id,
      setting: specItem.modelApplicability ? specItem.modelApplicability[mdl.id] : '',
    }));
    newSpec.models = models;

    if (specItem.changes) {
      newSpec.changes = Object.entries(specItem.changes).map(([field, change]) => {
        const revItem = new ReviewChangeBaseItem(change, field);
        changeLogModelApplicabilityItemMapper(vehicleModels, revItem.changes);
        return revItem;
      });
    }

    if (specItem.otherChanges) {
      newSpec.otherChanges = Object.entries(specItem.otherChanges).map(
        ([changeTypeId, otherChange]) => {
          const newItem = new ReviewChangeBaseItem(otherChange, changeTypeId);
          changeLogModelApplicabilityItemMapper(vehicleModels, newItem.changes);
          return newItem;
        }
      );
    }

    return newSpec;
  });
};

const isApplicabilityField = (field: string) => {
  switch (field) {
    case PACKAGE_TOTAL_APPLICABILITY_FIELD:
    case PACKAGE_TOTAL_REQUIRED_APPLICABILITY_FIELD:
    case PACKAGE_VARIETIES_FIELD:
    case OPTION_TOTAL_APPLICABILITY_FIELD: {
      return true;
    }
    default: {
      return false;
    }
  }
};
