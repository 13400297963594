import { TableCell } from '../../../../../../../components/Table';
import { observer } from 'mobx-react-lite';
import { OptionsPackage, OptionsPackageSpec, OptionsTabType } from '../../../../../../../models/optionsLexus.model';
import { ReviewChangeBaseItem } from '../../../../../../../models/review.model';
import React from 'react';
import { AcceptApplyCell } from '../../../../../../../routes/vehicleData/components/tableCells/AcceptApplyCell';
import { SuggestionType } from 'vapi-ui-common';
import OptionsLexusReviewChanges from '../../OptionsLexusChanges';
import PackageTableRow from '../../PackageTableRow';
import OptionsLexusPackageNameColumn from '../components/OptionsLexusPackageNameColumn';
import OptionsLexusPackageSpecFillerCell from '../components/OptionsLexusPackageSpecFillerCell';
import styles from '../optionsLexusPackageRow.module.scss';
import OptionsLexusPackageSpecReviewCells from './OptionsLexusPackageSpecReviewCells';

interface OptionsLexusPackageAddDeleteReviewRowProps {
  optionsPackage: OptionsPackage;
  optionsTab: OptionsTabType;
  spec?: OptionsPackageSpec;
  change: ReviewChangeBaseItem;
  nameSpan: number;
  requiredSpan: number;
  tokens?: SuggestionType[];
  allowTokens?: boolean;
  zebra?: boolean;
  expandRow?: boolean;
  isDelete?: boolean;
  hideAccept?: boolean;
  onUpdate?(option: OptionsPackage, change: ReviewChangeBaseItem): void;
}

const OptionsLexusPackageAddDeleteReviewRow = ({
  optionsPackage,
  spec,
  change,
  optionsTab,
  nameSpan = 1,
  requiredSpan = 1,
  tokens,
  allowTokens,
  zebra,
  expandRow,
  isDelete,
  hideAccept,
  onUpdate = () => void 0,
}: OptionsLexusPackageAddDeleteReviewRowProps) => {
  const disabled = true;
  const getSpannedCellStyle = () => {
    return !isDelete ? styles.spannedCell : undefined;
  };

  return (
    <>
      <PackageTableRow
        className={styles.row}
        zebra={zebra}
        isDelete={isDelete}
        onFillRowHeightChange={height => {
          optionsPackage.rowHeight = height;
          if (spec) {
            spec.rowHeight = height;
          }
        }}
      >
        <TableCell colType="dropdown" rowSpan={nameSpan} className={getSpannedCellStyle()} border>
          <OptionsLexusPackageNameColumn
            optionsPackage={optionsPackage}
            optionsTab={optionsTab}
            disabled={disabled}
            tokens={tokens}
          />
        </TableCell>

        {/* Code */}
        <TableCell rowSpan={nameSpan} className={getSpannedCellStyle()} border>
          {optionsPackage.code}
        </TableCell>

        {spec ? (
          <OptionsLexusPackageSpecReviewCells spec={spec} />
        ) : (
          <OptionsLexusPackageSpecFillerCell count={4} />
        )}

        {/* Required */}
        <TableCell rowSpan={requiredSpan} className={getSpannedCellStyle()} border>
          {optionsPackage.required}
        </TableCell>

        {/* Conflicts */}
        <TableCell rowSpan={requiredSpan} className={getSpannedCellStyle()} border>
          {optionsPackage.conflicts}
        </TableCell>

        <TableCell rowSpan={requiredSpan} colType="changes" whiteBg border smallText>
          <OptionsLexusReviewChanges changes={change.changes} />
          {optionsPackage.otherChanges.map(otherChange => (
            <React.Fragment key={otherChange.uid}>
              <p />
              <div>{otherChange.changeTypeId}</div>
              <OptionsLexusReviewChanges changes={otherChange.changes} />
            </React.Fragment>
          ))}
        </TableCell>

        <AcceptApplyCell
          id={`change-row-${change.uid}`}
          rowSpan={requiredSpan}
          acceptChecked={change.isAccepted}
          applyChecked={change.isApplied}
          isNewChange={change.isNewChange}
          notes={change.rejectNotes}
          onAcceptChecked={
            hideAccept
              ? undefined
              : checked => {
                  change.isAccepted = checked;
                  onUpdate(optionsPackage, change);
                }
          }
          onApplyChecked={checked => {
            change.isApplied = checked;
            onUpdate(optionsPackage, change);
          }}
          onNotesChange={notes => {
            change.rejectNotes = notes;
            onUpdate(optionsPackage, change);
          }}
        />
      </PackageTableRow>
    </>
  );
};

export default observer(OptionsLexusPackageAddDeleteReviewRow);
