import React from 'react';
import { ChangeLogDiff } from '../../../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import styles from '../../../../../../components/ChangeLogTable/ChangeLogChanges/changeLogChanges.module.scss';
import { KEY_FEATURE_CHANGELOG_CHANGE_TITLE } from '../../../../../../hooks/useFeaturesChangeLogChanges';
import { ModelApplicabilityChanges } from '../../../../../../models/changeLog.model';

interface Props {
  change: ModelApplicabilityChanges;
}

const KeyFeaturesChangeLogChange = ({ change }: Props) => {
  const { before = '', after = '', title } = change;
  const isDeleted = title === KEY_FEATURE_CHANGELOG_CHANGE_TITLE.DELETED;

  return (
    <div className={styles.applicabilityChanges}>
      {isDeleted && <span>- -</span>}
      {!isDeleted && <ChangeLogDiff before={before} after={after} />}
    </div>
  );
};

export default KeyFeaturesChangeLogChange;
