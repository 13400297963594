import { observer } from 'mobx-react-lite';
import { OptionLangMap } from '../../../../../../models/options.model';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IOptionsRow } from './IOptionsRow';
import OptionsRow from './OptionsRow';

interface OptionsRowsContainerProps extends IOptionsRow {
  optionLangMaps: OptionLangMap[];
}

/**
 * Wraps the Droppable component so updates to options, categories will
 * trigger a re-render
 */
const OptionsRowsContainer = ({ optionLangMaps, ...rest }: OptionsRowsContainerProps) => {
  return (
    <Droppable droppableId="optionsDroppable">
      {provided => (
        <tbody ref={provided.innerRef} {...provided.droppableProps}>
          {optionLangMaps.map((option, index) => {
            const uid = option.langs[Object.keys(option.langs)[0]].uid;
            return (
              <Draggable key={uid} draggableId={uid} index={index}>
                {(provided, snapshot) => (
                  <OptionsRow
                    key={uid}
                    index={index}
                    optionLangMap={option}
                    draggableProvided={provided}
                    {...rest}
                  />
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
};

export default observer(OptionsRowsContainer);
