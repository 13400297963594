import apolloClient from '../apolloClient';
import {
  ReadAllKeyFeaturesDocument,
  ReadAllKeyFeaturesQuery,
  ReadAllKeyFeaturesQueryVariables,
  GetSystemSpecsDocument,
  GetSystemSpecsQuery,
  GetSystemSpecsQueryVariables,
  AddSystemSpecDocument,
  AddSystemSpecMutation,
  AddSystemSpecMutationVariables,
  GetFuelTypesQueryVariables,
  GetFuelTypesDocument,
  GetFuelTypesQuery,
  ReadKeyFeaturesQuery,
  ReadKeyFeaturesQueryVariables,
  ReadKeyFeaturesDocument,
  ReadSeriesCategoriesQueryVariables,
  ReadSeriesCategoriesQuery,
  ReadSeriesCategoriesDocument,
  CreateSeriesCategoryMutationVariables,
  CreateSeriesCategoryDocument,
  CreateSeriesCategoryMutation,
  UpdateSeriesCategoryMutationVariables,
  UpdateSeriesCategoryMutation,
  UpdateSeriesCategoryDocument,
  DeleteSeriesCategoryMutationVariables,
  DeleteSeriesCategoryMutation,
  DeleteSeriesCategoryDocument,
  PublishSeriesCategoriesMutationVariables,
  PublishSeriesCategoriesMutation,
  PublishSeriesCategoriesDocument,
  ReadSeriesQueryVariables,
  ReadSeriesQuery,
  ReadSeriesDocument,
  CreateSeriesMutation,
  CreateSeriesMutationVariables,
  CreateSeriesDocument,
  UpdateSeriesMutation,
  UpdateSeriesDocument,
  ReadSeriesSelectionsQueryVariables,
  ReadSeriesSelectionsQuery,
  ReadSeriesSelectionsDocument,
  CreateSeriesSelectionsMutationVariables,
  CreateSeriesSelectionsMutation,
  CreateSeriesSelectionsDocument,
  UpdateSeriesMutationVariables,
} from '../gql/generated';
import { VDKeyFeatureOptionResponse } from '../models/features.model';
import { FuelTypeResponse } from '../models/fuelType.model';
import { SeriesCategory } from '../models/seriesCategories.model';
import { AddStandardSpecsResponse, GetStandardSpecsResponse } from '../models/specs.model';
import { Series, UserSeries } from '../models/vehicleData.model';
import API from '../webservices/api';

const RESOURCE_PATH = '/admin';

export const getPermissions = (data: { token: string; objectId: string }) => {
  return API.post(`${RESOURCE_PATH}/permissions`, data);
};

// Series Categories
export const getSeriesCategories = async (
  variables: ReadSeriesCategoriesQueryVariables
): Promise<SeriesCategory[]> => {
  const { data } = await apolloClient.query<
    ReadSeriesCategoriesQuery,
    ReadSeriesCategoriesQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: ReadSeriesCategoriesDocument,
    variables,
  });

  return data.readSeriesCategories.body;
};

export const addSeriesCategory = async (
  variables: CreateSeriesCategoryMutationVariables
): Promise<SeriesCategory> => {
  const { data } = await apolloClient.mutate<
    CreateSeriesCategoryMutation,
    CreateSeriesCategoryMutationVariables
  >({
    mutation: CreateSeriesCategoryDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add Series Category');
  }

  return data.createSeriesCategory.body;
};

export const updateSeriesCategory = async (
  variables: UpdateSeriesCategoryMutationVariables
): Promise<SeriesCategory> => {
  const { data } = await apolloClient.mutate<
    UpdateSeriesCategoryMutation,
    UpdateSeriesCategoryMutationVariables
  >({
    mutation: UpdateSeriesCategoryDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update Series Category');
  }

  return data.updateSeriesCategory.body;
};

export const deleteSeriesCategory = async (
  variables: DeleteSeriesCategoryMutationVariables
): Promise<SeriesCategory> => {
  const { data } = await apolloClient.mutate<
    DeleteSeriesCategoryMutation,
    DeleteSeriesCategoryMutationVariables
  >({
    mutation: DeleteSeriesCategoryDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete Series Category');
  }

  return data.deleteSeriesCategory.body;
};

export const publishSeriesCategory = async (
  variables: PublishSeriesCategoriesMutationVariables
): Promise<Boolean> => {
  const { data } = await apolloClient.mutate<
    PublishSeriesCategoriesMutation,
    PublishSeriesCategoriesMutationVariables
  >({
    mutation: PublishSeriesCategoriesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to publish Series Category');
  }

  return data.publishSeriesCategories;
};

// Series
export type SeriesResponse = {
  series: Series;
};

export const getSeries = async (variables: ReadSeriesQueryVariables): Promise<SeriesResponse> => {
  const { data } = await apolloClient.query<ReadSeriesQuery, ReadSeriesQueryVariables>({
    fetchPolicy: 'network-only',
    query: ReadSeriesDocument,
    variables,
  });

  return data.readSeries.body;
};

export const addSeries = async (
  variables: CreateSeriesMutationVariables
): Promise<SeriesResponse> => {
  const { data } = await apolloClient.mutate<CreateSeriesMutation, CreateSeriesMutationVariables>({
    mutation: CreateSeriesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to add Series');
  }

  return data.createSeries.body;
};

export const updateSeries = async (
  variables: UpdateSeriesMutationVariables
): Promise<SeriesResponse> => {
  const { data } = await apolloClient.mutate<UpdateSeriesMutation, UpdateSeriesMutationVariables>({
    mutation: UpdateSeriesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update Series');
  }

  return data.updateSeries.body;
};

// Series Selections
export type UserSeriesResponse = {
  seriesSelections: UserSeries[];
};

export const getUserSeries = async (
  variables: ReadSeriesSelectionsQueryVariables
): Promise<UserSeriesResponse> => {
  const { data } = await apolloClient.query<
    ReadSeriesSelectionsQuery,
    ReadSeriesSelectionsQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: ReadSeriesSelectionsDocument,
    variables,
  });

  return data.readSeriesSelections.body;
};

export const updateUserSeries = async (
  variables: CreateSeriesSelectionsMutationVariables
): Promise<UserSeriesResponse> => {
  const { data } = await apolloClient.mutate<
    CreateSeriesSelectionsMutation,
    CreateSeriesSelectionsMutationVariables
  >({
    mutation: CreateSeriesSelectionsDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update User Series');
  }

  return data.createSeriesSelections.body;
};

// Fuel Types
export const getFuelTypes = async (
  variables: GetFuelTypesQueryVariables
): Promise<FuelTypeResponse> => {
  const { data } = await apolloClient.query<GetFuelTypesQuery, GetFuelTypesQueryVariables>({
    query: GetFuelTypesDocument,
    variables,
  });

  return data.getFuelTypes.body;
};

// Key Features
export const getKeyFeatures = async (
  variables: ReadKeyFeaturesQueryVariables
): Promise<VDKeyFeatureOptionResponse> => {
  const { data } = await apolloClient.query<ReadKeyFeaturesQuery, ReadKeyFeaturesQueryVariables>({
    fetchPolicy: 'network-only',
    query: ReadKeyFeaturesDocument,
    variables,
  });

  return data.readKeyFeatures.body;
};

export const getAllKeyFeatures = async (
  variables: ReadAllKeyFeaturesQueryVariables
): Promise<VDKeyFeatureOptionResponse> => {
  const { data } = await apolloClient.query<
    ReadAllKeyFeaturesQuery,
    ReadAllKeyFeaturesQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: ReadAllKeyFeaturesDocument,
    variables,
  });

  return data.readAllKeyFeatures.body;
};

// System Specs
export const getSystemSpecs = async (
  variables: GetSystemSpecsQueryVariables
): Promise<GetStandardSpecsResponse> => {
  const { data } = await apolloClient.query<GetSystemSpecsQuery, GetSystemSpecsQueryVariables>({
    fetchPolicy: 'network-only',
    query: GetSystemSpecsDocument,
    variables,
  });

  return data.getSystemSpecs.body;
};

export const addSystemSpec = async (
  variables: AddSystemSpecMutationVariables
): Promise<AddStandardSpecsResponse> => {
  const { data } = await apolloClient.mutate<AddSystemSpecMutation, AddSystemSpecMutationVariables>(
    {
      mutation: AddSystemSpecDocument,
      variables,
    }
  );

  if (!data) {
    throw new Error('Failed to add Standard Spec');
  }

  return data.addSystemSpec.body;
};
