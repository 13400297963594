import { processRteForChangeLog } from 'vapi-ui-common';
import { ChangeLogTypes } from '../models/changeLog.model';
import { IDValueType, KeyValueType } from '../models/common.model';
import {
  OptionItem,
  OptionModel,
  OptionResponse,
  OptionReviewType,
  OptionsChangeTypeMap,
  OptionSettings,
  OptionsReviewItem,
  OptionsReviewMap,
  OptionsReviewResponse,
} from '../models/options.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../models/vehicleModel.model';
import { changeLogModelApplicabilityItemMapper } from './changeLogUtils';
import { handleOtherChangesForReview } from './reviewUtils';

export const optionsXForm = (
  data: OptionResponse[],
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  categories: IDValueType[]
) => {
  return data.map((item, index) => optionItemXForm(item, vehicleModels, categories, index));
};

export const optionItemXForm = (
  item: OptionResponse,
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  categories: IDValueType[],
  index: number
) => {
  const modelsMap: KeyValueType<OptionModel> = {};
  vehicleModels.forEach(mdl => {
    modelsMap[mdl.id] = {
      id: mdl.id,
      setting: item.modelApplicability ? item.modelApplicability[mdl.id] : OptionSettings.UNDEFINED,
    };
  });

  const category = categories.filter(cat => cat.id === item.categoryId);
  const optionItem = new OptionItem({ ...item });
  optionItem.category = category.length ? category[0] : optionItem.category;
  optionItem.modelsMap = modelsMap;
  optionItem.sortOrder = index + 1;
  return optionItem;
};

export const mapEmptyOptionModels = (
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
): KeyValueType<OptionModel> => {
  const map: KeyValueType<OptionModel> = {};
  vehicleModels.forEach(mdl => {
    map[mdl.id] = {
      id: mdl.id,
      setting: OptionSettings.UNDEFINED,
    };
  });
  return map;
};

export const optionsReviewItemXForm = (
  items: OptionsReviewResponse[],
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
  categories: IDValueType[]
) => {
  const optionItems: OptionsReviewItem[] = [];
  const map: OptionsReviewMap = {};

  items.forEach(item => {
    const category = categories.filter(cat => cat.id === item.categoryId);
    const models: OptionModel[] = vehicleModels.map(mdl => ({
      id: mdl.id,
      setting: item.modelApplicability ? item.modelApplicability[mdl.id] : '',
    }));

    // started here
    let isApplied = true;
    let rejectNotes = '';

    const app: KeyValueType = {};
    vehicleModels.forEach(model => {
      app[model.id] = item.modelApplicability
        ? item.modelApplicability[model.id]
        : OptionSettings.UNDEFINED;
    });

    if (!map[item.id]) {
      map[item.id] = {
        id: item.id,
        revId: item.revId,
        isApplied: false,
        isNew: false,
        isDeleted: false,
        rejectNotes: '',
        notes: item.notes || '',
        isInProgress: item.isInProgress,
        description: {
          before: '',
          after: processRteForChangeLog(item.description),
          hasChanged: false,
        },
        name: {
          before: '',
          after: processRteForChangeLog(item.name),
          hasChanged: false,
        },
        code: {
          before: '',
          after: item.code,
          hasChanged: false,
        },
        isExtraCost: {
          before: '',
          after: item.isExtraCost,
          hasChanged: false,
        },
        category: {
          before: '',
          after: category[0]?.value ?? '',
          hasChanged: false,
        },
        modelApplicability: { before: {}, after: app, hasChanged: false },
      } as OptionsChangeTypeMap;
    }

    Object.entries(item.changes).forEach(([key, change]) => {
      const optionItem = new OptionsReviewItem(item, change);
      optionItem.category = category.length ? category[0] : optionItem.category;
      optionItem.models = models;
      optionItem.changeTypeId = key;

      switch (change.changeType) {
        case ChangeLogTypes.CATEGORY: {
          optionItem.changes.beforeValue = change.before as string;
          optionItem.changes.afterValue = change.after as string;
          break;
        }
      }

      if (optionItem.otherChanges) {
        handleOtherChangesForReview(optionItem.otherChanges, [
          { type: ChangeLogTypes.CATEGORY, refItems: categories },
        ]);
        optionItem.otherChanges.forEach(ocItem =>
          changeLogModelApplicabilityItemMapper(vehicleModels, ocItem.changes)
        );
      }

      // map model applicability changes
      changeLogModelApplicabilityItemMapper(vehicleModels, optionItem.changes);
      optionItems.push(optionItem);

      isApplied = isApplied && change.isApplied;
      rejectNotes = change.rejectNotes || rejectNotes;

      const changeLogKey = getKeyFromChangeLogType(change.changeType);
      if (changeLogKey === 'deleted') {
        map[item.id].isDeleted = true;
        map[item.id].category.before = map[item.id].category.after;
        map[item.id].category.after = '';
        map[item.id].category.hasChanged = true;

        map[item.id].name.before = map[item.id].name.after;
        map[item.id].name.after = '';
        map[item.id].name.hasChanged = true;

        map[item.id].description.before = map[item.id].description.after;
        map[item.id].description.after = '';
        map[item.id].description.hasChanged = true;

        map[item.id].isExtraCost.before = map[item.id].isExtraCost.after;
        map[item.id].isExtraCost.after = '';
        map[item.id].isExtraCost.hasChanged = true;

        map[item.id].code.before = map[item.id].code.after;
        map[item.id].code.after = '';
        map[item.id].code.hasChanged = true;

        map[item.id].modelApplicability.before = map[item.id].modelApplicability.after;
        map[item.id].modelApplicability.after = {};
        map[item.id].modelApplicability.hasChanged = true;
      } else if (changeLogKey === 'added') {
        map[item.id].category.hasChanged = true;
        map[item.id].name.hasChanged = true;
        map[item.id].description.hasChanged = true;
        map[item.id].isExtraCost.hasChanged = true;
        map[item.id].code.hasChanged = true;
        map[item.id].modelApplicability.hasChanged = true;
        map[item.id].isNew = true;
      } else if (changeLogKey) {
        if (changeLogKey === 'description' || changeLogKey === 'name') {
          map[item.id][changeLogKey].before = processRteForChangeLog(optionItem.changes.before);
          map[item.id][changeLogKey].after = processRteForChangeLog(optionItem.changes.after);
        } else if (changeLogKey === 'modelApplicability') {
          const beforeModelApp: KeyValueType<OptionSettings> = {};
          const afterModelApp: KeyValueType<OptionSettings> = {};
          vehicleModels.forEach(mdl => {
            const id = mdl.id;
            if (id) {
              const before = change.before ? (change.before as KeyValueType<string>) : {};
              const beforeVal =
                before && before[id]
                  ? (before[id] as OptionSettings.UNDEFINED)
                  : OptionSettings.UNDEFINED;
              beforeModelApp[id] = beforeVal;

              const after = change.after ? (change.after as KeyValueType<string>) : {};
              const afterVal =
                after && after[id]
                  ? (after[id] as OptionSettings.UNDEFINED)
                  : OptionSettings.UNDEFINED;
              afterModelApp[id] = afterVal;
            }
          });
          map[item.id][changeLogKey].before = beforeModelApp;
          map[item.id][changeLogKey].after = afterModelApp;
        } else {
          map[item.id][changeLogKey].before = optionItem.changes.before;
          map[item.id][changeLogKey].after = optionItem.changes.after;
        }
        map[item.id][changeLogKey].hasChanged = true;
      }
    });
    map[item.id].isApplied = isApplied;
    map[item.id].rejectNotes = rejectNotes;
  });

  return { map, items: optionItems };
};

const getKeyFromChangeLogType = (type: ChangeLogTypes): OptionReviewType | undefined => {
  switch (type) {
    case ChangeLogTypes.CATEGORY:
      return 'category';
    case ChangeLogTypes.NAME:
      return 'name';
    case ChangeLogTypes.DESCRIPTION:
      return 'description';
    case ChangeLogTypes.CODE:
      return 'code';
    case ChangeLogTypes.OPTION_EXTRA_COST:
      return 'isExtraCost';
    case ChangeLogTypes.MODEL_APPLICABILITY:
      return 'modelApplicability';
    case ChangeLogTypes.OPTION_ADDED:
      return 'added';
    case ChangeLogTypes.OPTION_DELETED:
      return 'deleted';
  }
  return undefined;
};
