import { TableCell } from '../../../../../components/Table';
import { ChangeLogTypes } from '../../../../../models/changeLog.model';
import { OptionsPackage, OptionsPackageVariety } from '../../../../../models/optionsLexus.model';
import { ReviewChangeBaseItem } from '../../../../../models/review.model';
import React from 'react';
import { AcceptApplyCell } from '../../../../../routes/vehicleData/components/tableCells/AcceptApplyCell';
import OptionsLexusReviewChanges from '../components/OptionsLexusChanges';
import { PackageTotalRow } from '../components/totalRows';

const renderTotalCells = (
  option: OptionsPackage,
  change: ReviewChangeBaseItem,
  revertTotalApplicability: (option: OptionsPackage, change: ReviewChangeBaseItem) => void,
  hideAccept = false,
  otherChanges?: ReviewChangeBaseItem[]
) => (
  <>
    <TableCell colType="changes" whiteBg border>
      <OptionsLexusReviewChanges changes={change.changes} />
    </TableCell>
    <AcceptApplyCell
      id={`totalApp-${change.uid}`}
      isNewChange={change.isNewChange}
      applyChecked={change.isApplied}
      onApplyChecked={checked => {
        change.isApplied = checked;
        revertTotalApplicability(option, change);
      }}
      acceptChecked={change.isAccepted}
      onAcceptChecked={
        hideAccept
          ? undefined
          : checked => {
              change.isAccepted = checked;
              revertTotalApplicability(option, change);
            }
      }
      notes={change.rejectNotes}
      onNotesChange={notes => {
        change.rejectNotes = notes;
        revertTotalApplicability(option, change);
      }}
    />
  </>
);
export const renderTotalReviewRows = (
  option: OptionsPackage,
  revertTotalApplicability: (option: OptionsPackage, change: ReviewChangeBaseItem) => void,
  revertVariety: (
    option: OptionsPackage,
    variety: OptionsPackageVariety,
    change: ReviewChangeBaseItem
  ) => void,
  hideAccept = false
) => (
  <>
    {option.getVarietyChangesCount() === 0 && (
      <>
        {option.packageTotals.changes.map(change => (
          <PackageTotalRow
            key={change.uid}
            isRequired={
              option.isOption ||
              change.changes.changeType === ChangeLogTypes.PACKAGE_TOTAL_REQ_MODEL_APPLICABILITY
            }
            onFillRowHeightChange={height => (option.packageTotals.rowHeight = height)}
          >
            <td colSpan={6}>Package Total</td>
            {renderTotalCells(option, change, revertTotalApplicability, hideAccept)}
          </PackageTotalRow>
        ))}

        {option.packageTotalReqs.changes.map(change => (
          <PackageTotalRow
            key={change.uid}
            onFillRowHeightChange={height => (option.packageTotalReqs.rowHeight = height)}
            isRequired
          >
            <td colSpan={6}>Package Total with Requirements</td>
            {renderTotalCells(option, change, revertTotalApplicability, hideAccept)}
          </PackageTotalRow>
        ))}
      </>
    )}

    {option.getVarietyChangesCount() > 0 &&
      option.packageVarieties.map(variety => (
        <React.Fragment key={variety.uid}>
          {variety.changes.map(change => (
            <PackageTotalRow
              key={change.uid}
              isRequired
              isDelete={change.changes.changeType === ChangeLogTypes.PACKAGE_VARIETY_DELETED}
              onFillRowHeightChange={height => (variety.rowHeight = height)}
            >
              <td>{variety.name}</td>
              <td>{variety.code}</td>
              <td colSpan={4} />
              <td>{variety.required}</td>
              <td>{variety.conflicts}</td>
              <TableCell whiteBg border>
                <OptionsLexusReviewChanges changes={change.changes} />
                {variety.otherChanges.map(otherChange => (
                  <React.Fragment key={otherChange.uid}>
                    <p />
                    <OptionsLexusReviewChanges changes={otherChange.changes} />
                  </React.Fragment>
                ))}
              </TableCell>
              <AcceptApplyCell
                id={`variety-${change.uid}`}
                isNewChange={change.isNewChange}
                applyChecked={change.isApplied}
                onApplyChecked={checked => {
                  change.isApplied = checked;
                  revertVariety(option, variety, change);
                }}
                acceptChecked={change.isAccepted}
                onAcceptChecked={
                  hideAccept
                    ? undefined
                    : checked => {
                        change.isAccepted = checked;
                        revertVariety(option, variety, change);
                      }
                }
                notes={change.rejectNotes}
                onNotesChange={notes => {
                  change.rejectNotes = notes;
                  revertVariety(option, variety, change);
                }}
              />
            </PackageTotalRow>
          ))}
        </React.Fragment>
      ))}
  </>
);
