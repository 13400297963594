import cx from 'clsx';
import { TableRow, TableRowProps } from '../../../../../../components/Table/components/TableRow/TableRow';
import React from 'react';
import styles from './packageTableRow.module.scss';

export interface PackageTableRowProps extends TableRowProps {
  expandRow?: boolean;
  isDelete?: boolean;
}

const PackageTableRow = ({ expandRow, isDelete, className, ...rest }: PackageTableRowProps) => {
  return (
    <TableRow
      className={cx(className, expandRow && styles.expandRow, isDelete && styles.deleted)}
      {...rest}
    />
  );
};

export default PackageTableRow;
