import cx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Button, DropdownList } from 'vapi-ui-common';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import { Brand } from '../../gql/generated';
import useStores from '../../hooks/useStores';
import { ReportItem, ReportTypes, getReportTypesByBrand } from '../../models/reports.model';
import { BRAND_LEXUS, BRAND_TOYOTA, Language } from '../../models/user.model';
import { toGqlBrand } from '../../utils/graphqlUtils';
import { bindGetHandlerFn, filterSelectedSeries, reportsXForm } from '../../utils/reportsUtils';
import { getCommonLanguageVersions } from '../../webservices/commonLanguageApi';
import { getDisclaimerVersions } from '../../webservices/disclaimersApi';
import {
  GenerateReportPayload,
  downloadReport,
  generateReport,
  getAvailableReports,
} from '../../webservices/reportsApi';
import { getAvailableModels, getTeamVersions } from '../../webservices/vehicleAdminApi';
import ReportsTable from './components/ReportsTable';
import styles from './reports.module.scss';

const Reports = () => {
  const {
    userStore: { brand, region },
    reportsStore,
  } = useStores();

  const reportTypes = getReportTypesByBrand(brand);

  const [isLoaded, setIsLoaded] = useState(false);
  const [reportType, setReportType] = useState('');
  const [seriesYear, setSeriesYear] = useState('');
  const [series, setSeries] = useState('');
  const [version, setVersion] = useState('');
  const [reportsGenerated, setReportsGenerated] = useState(false);
  const [isVersionLoaded, setIsVersionLoaded] = useState(false);
  const mountedRef = useRef(true);

  // remove placeholder tooltip dropdown values
  const [tooltipVersions] = useState(['DRAFT', 'PUBLISHED']);

  useEffect(() => {
    reportsStore.reset();
  }, [reportsStore]);

  // Get available models and populate models, seriesList, seriesYearList
  // sets IsLoaded
  useEffect(() => {
    (async () => {
      setIsLoaded(false);

      try {
        const modelsResponse = await getAvailableModels(brand);
        if (mountedRef.current) {
          reportsStore.models = modelsResponse.data;

          reportsStore.models.forEach(model => {
            if (!reportsStore.seriesList.includes(model.seriesName)) {
              reportsStore.seriesList.push(model.seriesName);
            }

            if (!reportsStore.seriesYearList.includes(model.modelYear.toString())) {
              reportsStore.seriesYearList.push(model.modelYear.toString());
            }
          });

          setIsLoaded(true);
        }
      } catch (e) {
        if (mountedRef.current) {
          setIsLoaded(true);
          toast.error('Error loading reports');
        }
      }
    })();

    return () => {
      mountedRef.current = false;
      reportsStore.allReports = [];
    };
  }, [brand, reportsStore]);

  // When reportType, seriesYear, series is changed, re-fetch versions list
  const loadVersions = (reportType: string, seriesYear: string, series: string) => {
    setIsVersionLoaded(false);
    setVersion('');

    reportsStore.versionList = [];
    reportsStore.reportType = reportType;

    // start getting versions logic
    // getting available versions for commonlang/disclaimer reports
    const getVersionsByBrand = async () => {
      let versionData: string[] | null = null;

      if (reportType === ReportTypes.COMMON_LANGUAGE) {
        versionData = await getCommonLanguageVersions({
          brand: toGqlBrand(brand),
        });
      }
      if (reportType === ReportTypes.DISCLAIMERS) {
        versionData = await getDisclaimerVersions(brand, region);
      }
      if (!versionData) {
        return console.error(
          `unable to get versions: no handler for report type ${reportType} and brand ${brand}`
        );
      }

      versionData.forEach(item => {
        reportsStore.versionList.push(item);
      });
    };

    // getting available versions for all other toyota reports
    const getVersionsByTeam = async () => {
      const seriesSelected = series !== '';
      const yearSelected = seriesYear !== '';
      if (!seriesSelected || !yearSelected) {
        return;
      }

      const filterSelectedSeriesWithParams = filterSelectedSeries(seriesYear, series);

      const selectedSeries = reportsStore.models.filter(model => {
        return filterSelectedSeriesWithParams(model);
      });
      if (!selectedSeries.length) {
        return;
      }

      const isProductTeam = reportType === ReportTypes.PRODUCT_TEAM;
      const reportTeam = isProductTeam ? 'product-team' : 'agency-team';

      const teamVersions = await getTeamVersions(
        brand,
        reportTeam,
        selectedSeries[0].seriesId,
        seriesYear,
        Language.EN // defaulted to english for now
      );
      teamVersions.data.forEach(item => {
        reportsStore.versionList.push(item.version);
      });
    };

    // throw handlers for each report type in a config object
    const reportHandlersMap = {
      [BRAND_TOYOTA]: {
        [ReportTypes.COMMON_LANGUAGE]: getVersionsByBrand,
        [ReportTypes.DISCLAIMERS]: getVersionsByBrand,
        [ReportTypes.PRODUCT_TEAM]: getVersionsByTeam,
        [ReportTypes.AGENCY_TEAM]: getVersionsByTeam,
        [ReportTypes.COMPARE_MODEL]: getVersionsByTeam,
      },
      [BRAND_LEXUS]: {
        [ReportTypes.COMMON_LANGUAGE]: getVersionsByBrand,
        [ReportTypes.DISCLAIMERS]: getVersionsByBrand,
        [ReportTypes.INFORMATION_LAYER]: getVersionsByTeam,
        // [ReportTypes.TOOL_TIPS]: reportByYearAndVersion,
        [ReportTypes.VSD]: getVersionsByTeam,
        [ReportTypes.TOOL_TIPS]: getVersionsByTeam,
      },
    };

    // partially bind error text and config to a function
    // call again with brand and reportType to get correct handler
    const getVersionsHandler = bindGetHandlerFn('unable to get versions', reportHandlersMap);

    // get versions
    const getAvailableVersions = async () => {
      const thisVersionsHandler = getVersionsHandler(brand, reportType);

      if (!thisVersionsHandler) {
        return;
      }

      await thisVersionsHandler();
    };
    // end getting versions logic

    (async () => {
      try {
        await getAvailableVersions();
      } catch (e) {
        toast.error('Error loading versions');
      }
      setIsVersionLoaded(true);
    })();
  };

  const reportTypeOnSelect = async (type: string) => {
    setReportsGenerated(false);
    setReportType(type);
    const reports = await getAvailableReports({ brand: brand as Brand, reportType: type });
    reportsStore.allReports = reportsXForm(reports, type, reportsStore.models);
    setReportsGenerated(true);
    loadVersions(type, seriesYear, series);
  };

  const seriesOnSelect = (series: string) => {
    setSeries(series);
    setSeriesYear('');
    reportsStore.seriesYearList = [];
    reportsStore.models.forEach(model => {
      if (model.seriesName.toLowerCase() === series.toLowerCase()) {
        reportsStore.seriesYearList.push(model.modelYear.toString());
      }
    });
    loadVersions(reportType, seriesYear, series);
  };

  const seriesYearOnSelect = (seriesYear: string) => {
    setSeriesYear(seriesYear);
    loadVersions(reportType, seriesYear, series);
  };

  const listOrganizer = (reportTypes: ReportTypes[]) => {
    return reportTypes
      .sort((a, b) => a.localeCompare(b))
      .map((type: string) => {
        return formatReportType(type);
      });
  };

  const formatReportType = (type: string) => {
    if (type) {
      return type.charAt(0).toUpperCase() + type.slice(1);
    }
    return '';
  };

  // start generate report logic
  // shared logic
  const getGeneratedReports = async (payload: GenerateReportPayload) => {
    try {
      const generatedReports = await trackPromise(
        generateReport({
          brand: brand as Brand,
          reportType,
          reportData: {
            version: payload.version,
            seriesId: payload.seriesId,
            modelYear: payload.modelYear?.toString(),
          },
        })
      );
      reportsStore.allReports = reportsXForm(generatedReports, reportType, reportsStore.models);
      setReportsGenerated(true);
    } catch (e) {
      toast.error('Error loading report');
    }
  };
  // generate commonlang/disclaimer
  const reportByVersion = async () => getGeneratedReports({ version });

  // generate disclaimer
  const reportByRegionAndVersion = async () => getGeneratedReports({ version, region });

  // generate lexus tooltips
  const reportByYearAndVersion = async () => {
    const yearSelected = seriesYear !== '';
    if (!yearSelected) {
      return undefined;
    }
    const modelYear = Number(seriesYear);
    return getGeneratedReports({ version, modelYear });
  };
  // generate all other toyota
  const reportBySeriesYearAndVersion = async () => {
    const seriesSelected = series !== '';
    const yearSelected = seriesYear !== '';
    if (!seriesSelected || !yearSelected) {
      return undefined;
    }

    const filterSelectedSeriesWithParams = filterSelectedSeries(seriesYear, series);

    const selectedSeries = reportsStore.models.filter(model => {
      return filterSelectedSeriesWithParams(model);
    });
    const modelYear = Number(seriesYear);
    const seriesId = selectedSeries.length ? selectedSeries[0].seriesId : '';

    return getGeneratedReports({ version, seriesId, modelYear });
  };

  // throw handlers for each report type in a config object
  const generateReportHanders = {
    [BRAND_TOYOTA]: {
      [ReportTypes.COMMON_LANGUAGE]: reportByVersion,
      [ReportTypes.DISCLAIMERS]: reportByRegionAndVersion,
      [ReportTypes.PRODUCT_TEAM]: reportBySeriesYearAndVersion,
      [ReportTypes.AGENCY_TEAM]: reportBySeriesYearAndVersion,
      [ReportTypes.COMPARE_MODEL]: reportBySeriesYearAndVersion,
    },
    [BRAND_LEXUS]: {
      [ReportTypes.COMMON_LANGUAGE]: reportByVersion,
      [ReportTypes.DISCLAIMERS]: reportByRegionAndVersion,
      [ReportTypes.TOOL_TIPS]: reportByYearAndVersion,
      [ReportTypes.INFORMATION_LAYER]: reportBySeriesYearAndVersion,
      [ReportTypes.VSD]: reportBySeriesYearAndVersion,
    },
  };

  // partially bind error text and config to a function
  // call again with brand and reportType to get correct handler
  const getGenerateReportHandler = bindGetHandlerFn(
    'error generating report',
    generateReportHanders
  );

  // generate report
  const generateReports = async () => {
    setReportsGenerated(false);
    const versionSelected = version !== '';
    if (!versionSelected) {
      return;
    }

    const reportHandler = getGenerateReportHandler(brand, reportType);
    if (!reportHandler) {
      return;
    }
    await reportHandler();
  };
  // end generate report logic

  const onDownloadReport = async (report: ReportItem) => {
    const s3Url = await trackPromise(
      downloadReport({ brand: brand as Brand, key: report.bucketKey })
    );
    const anchor = document.createElement('a');

    anchor.href = s3Url;
    anchor.download = report.fileName;
    anchor.click();
  };

  // remove placeholder tooltip dropdown values
  const isTooltipReport = () => {
    return reportType === ReportTypes.TOOL_TIPS;
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="" moduleSubTitle="Reports" />
      <section className={styles.reportTypeContainer}>
        <div>
          <label htmlFor="report" className={styles.reportTypeLabel}>
            Select a report Type
          </label>
          <div className={styles.reportTypeBtn}>
            <DropdownList
              value={formatReportType(reportType)}
              list={listOrganizer(reportTypes)}
              onSelect={item => reportTypeOnSelect(item.toLowerCase())}
            />
          </div>
        </div>
        {reportType !== '' && (
          <>
            {reportType !== ReportTypes.COMMON_LANGUAGE && reportType !== ReportTypes.DISCLAIMERS && (
              <div className={styles.seriesContainer}>
                <label htmlFor="series" className={styles.reportTypeLabel}>
                  {!isTooltipReport() ? 'Series' : 'Year'}
                </label>
                {
                  // remove placeholder tooltip dropdown values
                  !isTooltipReport() && (
                    <div className={styles.seriesBtn}>
                      <DropdownList
                        value={series}
                        list={reportsStore.seriesList}
                        onSelect={item => seriesOnSelect(item)}
                      />
                    </div>
                  )
                }
                {
                  // remove placeholder tooltip dropdown values
                  (isTooltipReport() || series !== '') && (
                    <div className={isTooltipReport() ? styles.seriesBtn : styles.modelBtn}>
                      <DropdownList
                        value={seriesYear}
                        list={reportsStore.seriesYearList}
                        onSelect={item => seriesYearOnSelect(item)}
                      />
                    </div>
                  )
                }
              </div>
            )}

            {isVersionLoaded && (
              <>
                <div className={cx(styles.versionContainer, styles.generateCtaContainer)}>
                  <label htmlFor="version" className={styles.reportTypeLabel}>
                    Latest Version
                  </label>
                  <div className={styles.versionBtn}>
                    <DropdownList
                      value={version}
                      list={!isTooltipReport() ? reportsStore.versionList : tooltipVersions}
                      onSelect={item => setVersion(item)}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}

        <div>
          <Button className={styles.generateReportCta} variant="primary" onClick={generateReports}>
            Generate Report
          </Button>
        </div>

        {reportsGenerated && (
          <ReportsTable reports={reportsStore.sortedReports} onDownload={onDownloadReport} />
        )}
      </section>
    </>
  );
};

export default Reports;
