import { observable, action } from "mobx";

class ResizeDetectorStore {
  @observable mainContentWidth = 0;
  @observable windowWidth = 0;

  @action setValues = (mainContentWidth: number, windowWidth: number) => {
    this.mainContentWidth = mainContentWidth;
    this.windowWidth = windowWidth
  };
}

export default ResizeDetectorStore;
