import React, { HTMLAttributes } from 'react';
import styles from './headerNotificationMenu.module.scss';

const HeaderNotificationMenu = (props: HTMLAttributes<HTMLElement>) => {
  return (
    <section
      className={styles.headerNotificationMenu}
      {...props}
      data-testid="header-notification-menu"
    />
  );
};

export default HeaderNotificationMenu;
