import cx from 'clsx';
import React, { ReactNode } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import styles from './spinner.module.scss';

const PostSpinner = ({ children }: { children: ReactNode }) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <>
      {children}
      {promiseInProgress && (
        <section className={styles.postSpinner}>
          <div className={cx(styles.ldsRing, styles.postRing)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </section>
      )}
    </>
  );
};

export default PostSpinner;
