import { useCallback } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import { FeatureItem } from '../models/features.model';
import { OptionItem } from '../models/options.model';
import { SpecItem } from '../models/specs.model';
import {
  ModelFieldStatus,
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../models/vehicleModel.model';
import useStores from './useStores';
import { VehicleTeam } from '../models/vehicleData.model';
import { Language } from '../models/user.model';
import { handleErrorResponse } from '../utils/errorHandlingUtils';
import { LIMITED_DATA_STATUS } from '../constants/vehicleData/VDConstants';
import { updateOptionFeatureStatus } from '../webservices/vehicleOptionsApi';
import { toGqlBrand, toGqlTeam, toGqlLanguage } from '../utils/graphqlUtils';
import { AxiosResponse } from 'axios';

interface IUseFieldStatusModelUpdateProps {
  store: FeatureItem | OptionItem | SpecItem;
  model?: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  callBack?: (
    brand: string,
    team: VehicleTeam,
    serieId: string,
    modelYear: string,
    language: Language,
    payload: ModelFieldStatus
  ) => Promise<AxiosResponse<ModelFieldStatus, any>>;
  callBackGql?: typeof updateOptionFeatureStatus;
}

const useFieldStatusModelUpdate = ({
  store,
  model,
  callBack,
  callBackGql,
}: IUseFieldStatusModelUpdateProps) => {
  const {
    userStore: { brand },
    limitedDataStatusStore: { setDisabled },
  } = useStores();
  const { team, seriesId, year: modelYear } = useParams<{
    team: VehicleTeam;
    seriesId: string;
    year: string;
  }>();

  const onSave = useCallback(
    async (payload: ModelFieldStatus) => {
      if (!callBack && !callBackGql) return;

      try {
        setDisabled(true);
        if (callBack) {
          const res = await trackPromise(
            callBack(brand, team, seriesId, modelYear, Language.EN, payload)
          );

          store.fieldStatus = res.data;
        } else if (callBackGql) {
          const res = await trackPromise(
            callBackGql({
              brand: toGqlBrand(brand),
              team: toGqlTeam(team),
              seriesId: seriesId,
              modelYear: parseInt(modelYear),
              language: toGqlLanguage(Language.EN),
              payload: {
                id: payload.id,
                revId: (payload as any).revId,
                status: payload.status,
                modelApplicability: payload.modelApplicability,
              },
            })
          );

          store.fieldStatus = res;
        }
        toast.success(`Feature Field Status updated successfully`);
        setDisabled(false);
      } catch (e) {
        setDisabled(false);
        handleErrorResponse(e, 'Feature Field Status failed update');
      }
    },
    [store, brand, modelYear, seriesId, setDisabled, team, callBack, callBackGql]
  );

  const onClick = useCallback(() => {
    const fieldStatus = store.fieldStatus ?? { modelApplicability: {} };
    const payload: ModelFieldStatus = { ...fieldStatus };

    if (payload.status === LIMITED_DATA_STATUS.IN_PROGRESS) {
      payload.status = LIMITED_DATA_STATUS.READY_TO_PUBLISH;
      return onSave(payload);
    }

    if (payload.status === LIMITED_DATA_STATUS.READY_TO_PUBLISH) {
      payload.status = LIMITED_DATA_STATUS.IN_PROGRESS;
      return onSave(payload);
    }
  }, [store, onSave]);

  const onClickModelApplicability = useCallback(() => {
    if (!model) return;

    const modelId = model.id;
    const fieldStatus = store.fieldStatus ?? { modelApplicability: {} };
    const status = fieldStatus?.modelApplicability?.[modelId];
    const payload: ModelFieldStatus = {
      ...fieldStatus,
      modelApplicability: { ...fieldStatus.modelApplicability },
    };

    if (status === LIMITED_DATA_STATUS.IN_PROGRESS) {
      payload.modelApplicability[modelId] = LIMITED_DATA_STATUS.READY_TO_PUBLISH;
      return onSave(payload);
    }

    if (status === LIMITED_DATA_STATUS.READY_TO_PUBLISH) {
      payload.modelApplicability[modelId] = LIMITED_DATA_STATUS.IN_PROGRESS;
      return onSave(payload);
    }
  }, [store, model, onSave]);

  return { onClick, onClickModelApplicability };
};

export default useFieldStatusModelUpdate;
