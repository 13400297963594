import { useEffect, useState } from 'react';
import { LIMITED_DATA_STATUS } from '../../../../../constants/vehicleData/VDConstants';
import {
  FieldStatus,
  SeriesSettingsItem,
  UpdateSeriesSettingsStatusRequest,
} from '../../../../../models/seriesSettings.model';

const useLimitedDataStatus = (defaultSeriesSettings: SeriesSettingsItem) => {
  const [fieldStatus, setFieldStatus] = useState<FieldStatus | undefined>(
    defaultSeriesSettings.fieldStatus
  );

  useEffect(() => {
    setFieldStatus(defaultSeriesSettings.fieldStatus);
  }, [defaultSeriesSettings]);

  const handleStatusClick = (
    propName: string,
    updateFieldStatus?: (
      payload: UpdateSeriesSettingsStatusRequest,
      setFieldStatus: (val: FieldStatus) => void
    ) => void
  ) => {
    return () => {
      let payload: UpdateSeriesSettingsStatusRequest = {
        id: fieldStatus?.id || '',
        revId: fieldStatus?.revId || '',
        estimatedMileage: fieldStatus?.estimatedMileage || 0,
        mpge: fieldStatus?.mpge || 0,
        seating: fieldStatus?.seating || 0,
      };

      if (payload[propName as keyof UpdateSeriesSettingsStatusRequest] !== undefined) {
        if (
          payload[propName as keyof UpdateSeriesSettingsStatusRequest] ===
          LIMITED_DATA_STATUS.READY_TO_PUBLISH
        ) {
          payload = {
            ...payload,
            [propName]: LIMITED_DATA_STATUS.IN_PROGRESS,
          };
        } else {
          payload = {
            ...payload,
            [propName]: LIMITED_DATA_STATUS.READY_TO_PUBLISH,
          };
        }
      }

      if (updateFieldStatus) {
        updateFieldStatus(payload, setFieldStatus);
      }
    };
  };

  return {
    fieldStatus,
    handleStatusClick,
  };
};

export default useLimitedDataStatus;
