import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import React, { HTMLAttributes, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import useStores from "./hooks/useStores";
import "./index.scss";
import { UserInfo } from "./models/user.model";
import * as serviceWorker from "./serviceWorker";
import { getPermissions } from "./webservices/adminApi";
import { getToken, getUser, msalInstance } from "./authConfig";

const getUserInfo = async () => {
  const token = await getToken();
  const user = getUser();
  const objectId = user?.localAccountId ?? '';
  const response = await getPermissions({ token, objectId });

  return { ...response.data, objectId } as UserInfo;
};

const UserInfoComponent = ({ children }: HTMLAttributes<HTMLDivElement>) => {
  const { userStore } = useStores();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    getUserInfo().then(userInfo => {
      userStore.setUserInfo(userInfo);
      setIsLoaded(true);
    });
  }, [userStore]);

  return <>{isLoaded && children}</>;
};

const appWithOptionalAuth = process.env.REACT_APP_AZURE_AUTH === 'skip'
  ? (
    <UserInfoComponent>
      <App />
    </UserInfoComponent>
  )
  : (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
      >
        <UserInfoComponent>
          <App />
        </UserInfoComponent>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );

ReactDOM.render(appWithOptionalAuth, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
