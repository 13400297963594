import { observer } from 'mobx-react-lite';
import React from 'react';
import Checkbox from '../../../../components/Checkbox';
import { ChecklistItem } from '../../../../hooks/useChecklist';
import useStores from '../../../../hooks/useStores';
import {
  IntColorModelApplicability,
  InteriorColorLangItemMap,
} from '../../../../models/colors.model';
import useFieldStatusIntColor from '../../tabModules/colors/hooks/useFieldStatusIntColor';
import GradeList from './GradeList';
import styles from './addEditInteriorColorModal.module.scss';

interface IAllModelsProps {
  selectedInteriorColorMap?: InteriorColorLangItemMap;
  checklist: ChecklistItem[];
  isAllSelected: boolean;
  selectAll: (selected: boolean) => void;
  selectItem: (item: ChecklistItem, selected: boolean) => void;
}

const AllModels = ({
  selectedInteriorColorMap,
  checklist,
  isAllSelected,
  selectAll,
  selectItem,
}: IAllModelsProps) => {
  const { colorsStore } = useStores();

  const { onEditAllModels } = useFieldStatusIntColor();

  const handleModelApplicability = () => {
    if (selectedInteriorColorMap) {
      const modAppObj = {} as IntColorModelApplicability;

      checklist.forEach(grade => {
        if (grade.selected || grade.items?.filter(selected => selected.selected).length) {
          modAppObj[grade.id] = {};
          if (grade.items) {
            grade.items.forEach(model => {
              if (model.selected) {
                modAppObj[grade.id][model.id] = true;
              }
            });
          }
        }
      });

      colorsStore.editableLangs.forEach(lang => {
        selectedInteriorColorMap.langs[lang].modelApplicability = modAppObj;
      });
    }
  };

  return (
    <>
      <Checkbox
        id="chboxGrdAll"
        className={styles.all}
        checked={isAllSelected}
        onChange={e => {
          selectAll(e.currentTarget.checked);
          handleModelApplicability();
          onEditAllModels(checklist);
        }}
      >
        <span>All Models</span>
      </Checkbox>
      <GradeList
        checklist={checklist}
        handleModelApplicability={handleModelApplicability}
        selectItem={selectItem}
      />
    </>
  );
};

export default observer(AllModels);
