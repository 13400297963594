import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownList,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'vapi-ui-common';
import Input from '../../../../components/Input';
import { VehicleCategories } from '../../../../models/vehicleData.model';
import styles from './addVehicleModal.module.scss';

interface AddVehicleModalProps {
  close?: () => void;
  onChange?: (value: string) => void;
  closeDropdown?: () => void;
  onAddSeries: (series: string, group: VehicleCategories) => void;
  groups: VehicleCategories[];
}

const AddVehicleModal = observer(
  ({ close = () => {}, onAddSeries, groups }: AddVehicleModalProps) => {
    const [currentGroup, setCurrentGroup] = useState(groups[0]);
    const [series, setSeries] = useState('');

    const handleOnSave = async () => {
      const trimmedSeries = series.trim();
      if (series && trimmedSeries) {
        onAddSeries(trimmedSeries, currentGroup);
        close();
      }
    };

    return (
      <>
        <ModalHeader onClose={close}>Add Vehicle</ModalHeader>
        <ModalBody>
          <section className={styles.container}>
            <div>
              <label htmlFor="model" className={styles.modelLabel}>
                Model
              </label>
              <Input
                className={styles.inputBox}
                value={series}
                onChange={e => setSeries(e.currentTarget.value)}
              />
            </div>
            <div>
              <label htmlFor="Vehicle Group" className={styles.groupLabel}>
                Vehicle Group
              </label>
              <Dropdown className={styles.groupDropdown}>
                <DropdownList
                  list={groups}
                  value={currentGroup}
                  onSelect={(item: string) => setCurrentGroup(item as VehicleCategories)}
                />
              </Dropdown>
            </div>
          </section>
        </ModalBody>
        <ModalFooter>
          <Button variant="transparent" onClick={() => close()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleOnSave()}>
            Next
          </Button>
        </ModalFooter>
      </>
    );
  }
);

export default AddVehicleModal;
