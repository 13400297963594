import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { KeyValueType } from '../models/common.model';
import {
  FeatureLangMap,
  KeyFeatureOption,
  KeyFeatureType,
  KeyFeatureTypeMap,
} from '../models/features.model';
import { toKeyFeatureList, toKeyFeatureOptionsList } from '../utils/featuresUtils';
import useStores from './useStores';

type PropsLoad = {
  isShown: boolean;
  featureLangMap: FeatureLangMap;
  categoryId?: string;
};

const useKeyFeatureLoad = ({ isShown, featureLangMap, categoryId }: PropsLoad) => {
  const { teamStore, featuresStore } = useStores();

  const [keyFeatureList, setKeyFeatureList] = useState<KeyFeatureType[]>([]);
  const [keyFeatureOptions, setKeyFeatureOptions] = useState<KeyFeatureOption[]>([]);
  const [changedAttributes, setChangedAttributes] = useState<KeyValueType<string>>({});
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [keyFeauresMapOnLoad, setKeyFeauresMapOnLoad] = useState<KeyFeatureTypeMap>({});
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [keyFeatureErrorMap, setKeyFeatureErrorMap] = useState<KeyFeatureTypeMap>({});
  const defaultFeatureItem = featureLangMap.langs[teamStore.team.defaultLanguage];

  useEffect(() => {
    const changedMap: KeyValueType<string> = {};

    defaultFeatureItem.changedAttributes?.forEach(attr => {
      if (attr.includes('keyFeature#')) {
        const splt = attr.split('#');
        if (splt.length > 1) {
          const keyFeatureId = splt[1];
          changedMap[keyFeatureId] = attr;
        }
      }
    });

    setChangedAttributes(changedMap);
    setHasChanges(!!Object.keys(changedMap).length);
  }, [defaultFeatureItem.changedAttributes, setHasChanges, setChangedAttributes]);

  useEffect(() => {
    if (!isShown) return;

    const dKeyFeature: KeyFeatureType = { uid: uuidv4(), description: '' };
    const kFeatureList = toKeyFeatureList(defaultFeatureItem.keyFeatures, changedAttributes);

    setKeyFeatureList(kFeatureList ?? [dKeyFeature]);

    const kfMap = kFeatureList?.reduce((map, kf) => ({ ...map, [kf?.id ?? '']: kf }), {});
    setKeyFeauresMapOnLoad(kfMap ?? {});
  }, [
    isShown,
    defaultFeatureItem.keyFeatures,
    changedAttributes,
    setKeyFeatureList,
    setKeyFeauresMapOnLoad,
  ]);

  const hasBeenModified = useMemo(() => {
    if (!isShown) return;

    const fkfMap = keyFeatureList.reduce((map: KeyFeatureTypeMap, kf) => {
      if (!kf.id) return map;
      return { ...map, [kf.id]: kf };
    }, {});

    const onLoadLength = Object.keys(keyFeauresMapOnLoad).length;
    const fkfList = Object.values(fkfMap);

    const kFeature = fkfList.find(kf => kf.id && !keyFeauresMapOnLoad[kf.id]);

    return fkfList.length !== onLoadLength || !!kFeature;
  }, [isShown, keyFeatureList, keyFeauresMapOnLoad]);

  useEffect(() => {
    setKeyFeatureOptions(
      toKeyFeatureOptionsList({
        categoryId,
        keyFeatures: featuresStore.keyFeatures?.keyFeatures,
      })
    );
  }, [setKeyFeatureOptions, categoryId, featuresStore, featuresStore.keyFeatures]);

  useEffect(() => {
    const kfList = toKeyFeatureList(defaultFeatureItem.keyFeatures);
    const fkfList = kfList?.filter(kf => !keyFeatureOptions.find(opt => opt.id === kf.id));
    const hasErrors = !fkfList ? false : !!fkfList.length;

    setHasErrors(hasErrors);

    const fkfMap = fkfList?.reduce((map, kf) => ({ ...map, [kf?.id ?? '']: kf }), {});
    setKeyFeatureErrorMap(fkfMap ?? {});
  }, [keyFeatureOptions, defaultFeatureItem.keyFeatures, setHasErrors, setKeyFeatureErrorMap]);

  return {
    keyFeatureList,
    setKeyFeatureList,
    defaultFeatureItem,
    hasChanges,
    hasBeenModified,
    hasErrors,
    keyFeatureOptions,
    keyFeatureErrorMap,
  };
};

export default useKeyFeatureLoad;
