import { action, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { convertToRichTextObject, Status } from 'vapi-ui-common';
import { LIMITED_DATA_STATUS } from '../../constants/vehicleData/VDConstants';
import { CategoriesMap, CategoryResponse } from '../../models/category.model';
import { IDValueType, KeyValueType } from '../../models/common.model';
import { OptionItem, OptionLangMap, OptionResponse, OptionsMap } from '../../models/options.model';
import { ISortList, VDSortableEntity } from '../../models/sort.model';
import { Language, LanguagePermissions } from '../../models/user.model';
import { VehicleDataVersionInfo, VehicleTeam } from '../../models/vehicleData.model';
import {
  VehicleModel,
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelPropsLexus,
  VehicleModelPropsToyota,
  VehicleModelToyota,
} from '../../models/vehicleModel.model';
import { isSortReverse, sortBy, toLowerCase } from '../../utils';
import { categoriesXForm } from '../../utils/categoryUtils';
import parseLangWriteMap from '../../utils/languageUtils';
import { mapEmptyOptionModels, optionItemXForm } from '../../utils/optionsUtils';
import { sortCategoriesFunction } from '../../utils/sortUtils';
import { getSortList } from '../../webservices/vehicleAdminApi';
import { getCategories, getOptions } from '../../webservices/vehicleOptionsApi';
import { toGqlBrand, toGqlTeam, toGqlFilter, toGqlLanguage } from '../../utils/graphqlUtils';

class OptionsStore {
  reverseSort = false;
  sortField = 'id';
  searchText = '';
  isInProgressFilter = false;
  isSyncUpdateFilter = false;
  isReviewNotesFilter = false;
  categoryFilters: string[] = [];
  categoriesSortList: ISortList = {} as ISortList;
  optionLangMaps: OptionLangMap[] = [];
  // langs
  langWriteMap: LanguagePermissions = {};
  allLangs: Language[] = [];
  editableLangs: Language[] = [];
  defaultLang: Language = Language.EN;
  fullEditPermissions: boolean = false;

  @observable selectedLangsMap: KeyValueType<boolean> = {};
  @observable filteredOptionLangMaps: OptionLangMap[] = [];

  @observable categoriesMap: CategoriesMap = { categories: {}, order: [] };
  @observable viewModelCodes = true;
  @observable optionsRowHeightMap: KeyValueType<number> = {};

  @action fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    vehicleModels: VehicleModelItem<
      VehicleModel<VehicleModelPropsLexus> | VehicleModel<VehicleModelPropsToyota>
    >[],
    langWriteMap: LanguagePermissions,
    versionInfo: VehicleDataVersionInfo
  ) => {
    this.reset();
    const {
      allLangs,
      editableLangs,
      defaultLang,
      selectedLangsMap,
      fullEditPermissions,
    } = parseLangWriteMap(langWriteMap);

    this.langWriteMap = langWriteMap;
    this.allLangs = allLangs;
    this.editableLangs = editableLangs;
    this.defaultLang = defaultLang;
    this.selectedLangsMap = selectedLangsMap;
    this.fullEditPermissions = fullEditPermissions;

    const responses = await Promise.all(
      this.allLangs.map(lang =>
        Promise.all([
          getCategories({
            brand: toGqlBrand(brand),
            team: toGqlTeam(team),
            seriesId: seriesId,
            modelYear: parseInt(year),
            language: toGqlLanguage(lang),
            filter: toGqlFilter(versionInfo[lang]?.toString() ?? Status.DRAFT),
            includeAll: false,
          }),
          getOptions<OptionResponse>({
            brand: toGqlBrand(brand),
            team: toGqlTeam(team),
            seriesId: seriesId,
            modelYear: parseInt(year),
            language: toGqlLanguage(lang),
            filter: toGqlFilter(versionInfo[lang]?.toString() ?? Status.DRAFT),
          }),
        ])
      )
    );

    if (this.defaultLang && versionInfo[this.defaultLang]) {
      const sortResponses = await Promise.all([
        getSortList(
          brand,
          team,
          seriesId,
          year,
          VDSortableEntity.OPTIONS_CATEGORIES,
          versionInfo[this.defaultLang]?.toString()
        ),
      ]);
      this.categoriesSortList = sortResponses[0].data;
    }

    let index = 0;
    const optionsMap: OptionsMap = { options: {}, order: [] };
    for (const lang of this.allLangs) {
      const [categoriesResponse, optionsResponse] = responses[index];
      this.updateCategoriesLangMap(
        lang,
        this.categoriesMap,
        categoriesResponse,
        this.categoriesSortList
      );
      this.updateOptionLangMap(lang, optionsMap, optionsResponse, vehicleModels);
      index++;
    }

    this.fillOutCategoriesMap(this.categoriesMap);
    this.fillOutOptionLangMap(optionsMap, vehicleModels);
    this.setOptionLangMaps(optionsMap);
  };

  updateCategoriesLangMap = (
    lang: Language,
    categoriesMap: CategoriesMap,
    data: CategoryResponse[],
    sortList?: ISortList
  ) => {
    const useSortList = sortList && !!Object.keys(sortList.sortList).length;
    const categories: IDValueType<string>[] =
      useSortList && sortList
        ? categoriesXForm(data, sortCategoriesFunction(sortList.sortList))
        : categoriesXForm(data);
    categories.forEach(cat => {
      if (!categoriesMap.categories[cat.id]) {
        categoriesMap.categories[cat.id] = {};
      }
      categoriesMap.categories[cat.id][lang] = cat;
      if (!categoriesMap.order.includes(cat.id)) {
        categoriesMap.order.push(cat.id);
      }
    });
  };

  /**
   * Ensure that every language has all possibly categories/subcategories.
   * @param langs
   * @param categoriesMap
   */
  fillOutCategoriesMap = (categoriesMap: CategoriesMap) => {
    Object.values(categoriesMap.categories).forEach(cat => {
      const defaultId = cat.EN ? cat.EN.id : Object.values(cat)[0].id;
      this.allLangs.forEach(lang => {
        if (!cat[lang]) {
          cat[lang] = new IDValueType<string>(defaultId, '');
        }
      });
    });
  };

  getCategoriesForLang = (lang: string, categoriesMap: CategoriesMap) => {
    const cats: IDValueType<string>[] = [];
    Object.keys(categoriesMap.categories).forEach(catId => {
      if (categoriesMap.categories[catId][lang]) {
        cats.push(categoriesMap.categories[catId][lang]);
      }
    });
    return cats;
  };

  getDefaultCategories = (categoriesMap: CategoriesMap) => {
    const lang = this.defaultLang;
    const cats: IDValueType<string>[] = [];
    Object.keys(categoriesMap.categories).forEach(catId => {
      if (categoriesMap.categories[catId][lang]) {
        cats.push(categoriesMap.categories[catId][lang]);
      }
    });
    return cats;
  };

  updateOptionLangMap = (
    lang: string,
    optionsMap: OptionsMap,
    data: OptionResponse[],
    vehicleModels: VehicleModelItem<
      VehicleModel<VehicleModelPropsLexus> | VehicleModel<VehicleModelPropsToyota>
    >[]
  ) => {
    const cats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.categoriesMap);

    data.forEach(item => {
      const index = optionsMap.order.findIndex(id => id === item.id);
      const option = optionItemXForm(
        item,
        vehicleModels,
        cats,
        index >= 0 ? index : optionsMap.order.length
      );
      if (!optionsMap.options[option.id]) {
        optionsMap.options[option.id] = { data: item, langs: {} };
      }
      optionsMap.options[option.id].langs[lang] = option;
      if (!optionsMap.order.includes(option.id)) {
        optionsMap.order.push(option.id);
      }
    });
  };

  fillOutOptionLangMap = (
    optionsMap: OptionsMap,
    vehicleModels: VehicleModelItem<
      VehicleModel<VehicleModelPropsLexus> | VehicleModel<VehicleModelPropsToyota>
    >[]
  ) => {
    Object.keys(optionsMap.options).forEach(featureId => {
      const optionLangMap = optionsMap.options[featureId];
      const featureData: OptionResponse = optionLangMap.data ?? ({} as OptionResponse);
      const index = optionsMap.order.findIndex(id => id === featureId);

      this.allLangs.forEach(lang => {
        if (!optionLangMap.langs[lang]) {
          optionLangMap.langs[lang] = optionItemXForm(
            featureData,
            vehicleModels,
            this.getCategoriesForLang(lang, this.categoriesMap),
            index
          );
          optionLangMap.langs[lang].revId = '';
          optionLangMap.langs[lang].description = '';
        }
      });
    });
  };

  setOptionLangMaps = (optionsMap: OptionsMap) => {
    const optionLangMaps: OptionLangMap[] = [];
    optionsMap.order.forEach(id => {
      const feature = optionsMap.options[id];
      optionLangMaps.push(feature);
    });
    this.optionLangMaps = optionLangMaps;
    this.filteredOptionLangMaps = optionLangMaps;
  };

  setLangMapList = (map: OptionLangMap[]) => {
    this.optionLangMaps = map;
    this.filteredOptionLangMaps = map;
  };

  updateSelectedLangs = (lang: string, isSelected: boolean) => {
    const selectedMap = JSON.parse(JSON.stringify(this.selectedLangsMap));
    if (selectedMap[lang] != null) {
      selectedMap[lang] = isSelected;
    }
    this.selectedLangsMap = selectedMap;
  };

  getDefaultOptions = (featureLangMaps: OptionLangMap[]) => {
    return featureLangMaps.map(langMap => langMap.langs[this.defaultLang]);
  };

  getOptionsForLang = (lang: string) => {
    const options: OptionItem[] = [];
    this.optionLangMaps.forEach(langMap => {
      if (langMap.langs[lang]) {
        options.push(langMap.langs[lang]);
      }
    });
    return options;
  };

  getOptionsMap = () => {
    const optionsMap: OptionsMap = { options: {}, order: [] };
    this.optionLangMaps.forEach(langMap => {
      const id = langMap.langs[Object.keys(langMap.langs)[0]].id;
      optionsMap.options[id] = langMap;
      optionsMap.order.push(id);
    });
    return optionsMap;
  };

  addItem = (vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]) => {
    const id = uuidv4();
    const newLangMap: OptionLangMap = { langs: {} };
    this.allLangs.forEach(lang => {
      const newItem = new OptionItem();
      newItem.modelsMap = mapEmptyOptionModels(vehicleModels);
      newItem.id = id;
      newLangMap.langs[lang] = newItem;
    });

    this.optionLangMaps = [newLangMap, ...this.optionLangMaps];
    this.filteredOptionLangMaps = [newLangMap, ...this.filteredOptionLangMaps];
  };

  copyMap = (
    optionLangMap: OptionLangMap,
    vehicleModels: VehicleModelItem<
      VehicleModel<VehicleModelPropsLexus> | VehicleModel<VehicleModelPropsToyota>
    >[]
  ) => {
    const optionUid = optionLangMap.langs[this.defaultLang].uid;
    const unfilteredIndex =
      this.optionLangMaps.findIndex(item => item.langs[this.defaultLang].uid === optionUid) + 1;
    const filteredIndex =
      this.filteredOptionLangMaps.findIndex(
        item => item.langs[this.defaultLang].uid === optionUid
      ) + 1;

    const newLangMap: OptionLangMap = { langs: {} };
    for (let lang of this.allLangs) {
      const option = optionLangMap.langs[lang];
      const optionCopy = new OptionItem();
      const {
        id,
        revId,
        uid,
        isValid,
        isValidTdPR,
        modelsMap,
        getCreatePayload,
        getUpdatePayload,
        ...rest
      } = option;
      Object.assign(optionCopy, rest);
      optionCopy.modelsMap = mapEmptyOptionModels(vehicleModels);
      newLangMap.langs[lang] = optionCopy;
    }

    const langMaps = this.optionLangMaps.slice();
    langMaps.splice(unfilteredIndex, 0, newLangMap);
    this.optionLangMaps = langMaps;

    const filteredLangMaps = this.filteredOptionLangMaps.slice();
    filteredLangMaps.splice(filteredIndex, 0, newLangMap);
    this.filteredOptionLangMaps = filteredLangMaps;

    return newLangMap;
  };

  deleteItem = (uid: string) => {
    this.optionLangMaps = this.optionLangMaps.filter(
      item => item.langs[this.defaultLang].uid !== uid
    );
    this.filteredOptionLangMaps = this.filteredOptionLangMaps.filter(
      item => item.langs[this.defaultLang].uid !== uid
    );
  };

  onSort = (field: string, lang?: string) => {
    this.reverseSort = isSortReverse(this.sortField, field, this.reverseSort);
    this.sortField = field;
    let features = lang
      ? this.getOptionsForLang(lang)
      : this.getDefaultOptions(this.optionLangMaps);
    features = features.sort(sortBy(this.sortField, this.reverseSort));
    const optionsMap = this.getOptionsMap();
    optionsMap.order = [];
    features.forEach(feature => {
      optionsMap.order.push(feature.id);
    });
    this.setOptionLangMaps(optionsMap);
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredOptionLangMaps = this.filterLangMaps();
  };

  @action resetFilters = () => {
    this.searchText = '';
    this.categoryFilters = [];
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.isReviewNotesFilter = false;
    this.filteredOptionLangMaps = this.optionLangMaps.slice();
  };

  filterLangMaps = () => {
    const sorted = this.optionLangMaps.slice();
    const lowerSearchText = toLowerCase(this.searchText);
    return sorted.filter(optionLangMap => {
      let checked = false;
      let hasChangedAttributes = false;
      for (let lang of this.allLangs) {
        const option = optionLangMap.langs[lang];

        if (!checked) {
          checked = true;
          if (
            this.categoryFilters.length &&
            !this.categoryFilters.includes(option.category.value)
          ) {
            return false;
          }
          if (
            this.isInProgressFilter &&
            option.fieldStatus.status !== LIMITED_DATA_STATUS.IN_PROGRESS
          ) {
            return false;
          }
          if (this.isReviewNotesFilter && option.rejectNotes.length === 0) {
            return false;
          }
        }

        if (option.changedAttributes.length) {
          hasChangedAttributes = true;
        }

        if (lowerSearchText) {
          const valuesToCheck: string[] = [
            option.category.value,
            convertToRichTextObject(option.description).text,
            convertToRichTextObject(option.name).text,
            option.code,
            option.link,
            option.isExtraCost,
            option.notes,
          ];
          for (let val of valuesToCheck) {
            if (toLowerCase(val).includes(lowerSearchText)) {
              return true;
            }
          }
        }
      }

      if (this.isSyncUpdateFilter && !hasChangedAttributes) {
        return false;
      }

      return !lowerSearchText;
    });
  };

  hasChangedAttributes() {
    return !!this.optionLangMaps.filter(
      langMap =>
        !!this.editableLangs.filter(lang => !!langMap.langs[lang].changedAttributes.length).length
    ).length;
  }

  @action reset() {
    this.reverseSort = false;
    this.sortField = 'id';
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.isReviewNotesFilter = false;
    this.categoryFilters = [];
    this.categoriesMap = { categories: {}, order: [] };
    this.selectedLangsMap = {};
    this.viewModelCodes = true;
    this.optionLangMaps = [];
    this.filteredOptionLangMaps = [];
    this.optionsRowHeightMap = {};
  }

  setOptionsRowHeight = (optionLangMap: OptionLangMap, rowHeight: number) => {
    const key = optionLangMap.langs[this.defaultLang].id;
    this.optionsRowHeightMap[key] = rowHeight;
  };

  getOptionsRowHeight(optionLangMap: OptionLangMap) {
    const key = optionLangMap.langs[this.defaultLang].id;
    return this.optionsRowHeightMap[key];
  }
}

export default OptionsStore;
