import { processRteForChangeLog } from 'vapi-ui-common';
import {
  BnpCategoryReviewResponse,
  BnpCategorySplitReview,
  BnPChangeTypeMap,
  BnPItemsReviewResponse,
  BnPReviewItem,
} from '../models/buildAndPrice.model';
import { ChangeLogTypes } from '../models/changeLog.model';
import { KeyValueType } from '../models/common.model';
import { ReviewChangeMap } from '../models/review.model';

const getBnPItemsReviewResponseChanges = (category: BnpCategoryReviewResponse) => {
  const id = `${category.itemKey}#${category.categoryKey}`;
  let isApplied = true;
  let rejectNotes = '';
  let description: ReviewChangeMap = {
    after: processRteForChangeLog(category.description as string),
    before: '',
    hasChanged: false,
  };
  let title: ReviewChangeMap = {
    after: processRteForChangeLog(category.title as string),
    before: '',
    hasChanged: false,
  };
  let copy: ReviewChangeMap = {
    after: processRteForChangeLog(category.copy as string),
    before: '',
    hasChanged: false,
  };
  let applicability: ReviewChangeMap<KeyValueType<string>> = {
    after: (category.applicability ?? {}) as KeyValueType<string>,
    before: {},
    hasChanged: false,
  };

  Object.values(category.changes).forEach(change => {
    isApplied = isApplied && change.isApplied;
    rejectNotes = change.rejectNotes;

    switch (change.changeType) {
      case ChangeLogTypes.CATEGORY_APPLICABILITY: {
        applicability = {
          after: change.after as KeyValueType<string>,
          before: change.before as KeyValueType<string>,
          hasChanged: true,
        };
        break;
      }

      case ChangeLogTypes.DESCRIPTION: {
        description = {
          after: processRteForChangeLog(change.after as string),
          before: processRteForChangeLog(change.before as string),
          hasChanged: true,
        };
        break;
      }

      case ChangeLogTypes.TITLE: {
        title = {
          after: processRteForChangeLog(change.after as string),
          before: processRteForChangeLog(change.before as string),
          hasChanged: true,
        };
        break;
      }

      case ChangeLogTypes.COPY: {
        copy = {
          after: processRteForChangeLog(change.after as string),
          before: processRteForChangeLog(change.before as string),
          hasChanged: true,
        };
        break;
      }
    }
  });

  return {
    id,
    description,
    title,
    copy,
    isApplied,
    rejectNotes,
    applicability,
  };
};

const getBnPItemsReviewResponseSplitChanges = ({
  splitChanges,
  splits = {},
}: BnpCategoryReviewResponse) => {
  const sChanges: KeyValueType<BnpCategorySplitReview> = {};
  let isApplied = true;
  let rejectNotes = '';

  Object.values(splitChanges || {}).forEach(sChange => {
    Object.values(sChange).forEach(change => {
      isApplied = isApplied && change.isApplied;
      rejectNotes = change.rejectNotes;

      const split = change.bnpSplitId ? splits[change.bnpSplitId] : undefined;
      if (!split) {
        return;
      }

      switch (change.changeType) {
        case ChangeLogTypes.BNP_SPLIT_ADDED: {
          sChanges[split.id] = {
            id: split.id,
            isNew: true,
            isDeleted: false,
            splitNumber: split.splitNumber,
            description: {
              after: processRteForChangeLog(split.description as string),
              before: '',
              hasChanged: true,
            },
          };
          break;
        }

        case ChangeLogTypes.BNP_SPLIT_DESCRIPTION: {
          sChanges[split.id] = {
            id: split.id,
            isNew: false,
            isDeleted: false,
            splitNumber: split.splitNumber,
            description: {
              after: processRteForChangeLog(change.after as string),
              before: processRteForChangeLog(change.before as string),
              hasChanged: true,
            },
          };
          break;
        }
      }
    });
  });

  return { splits: sChanges, isApplied, rejectNotes };
};

export const bnpReviewXform = (items: BnPItemsReviewResponse[]): BnPReviewItem[] => {
  const reviewItems: BnPReviewItem[] = [];

  items.forEach(({ categories, label, notes, isInProgress, sortOrder }) => {
    const catList = Object.values(categories).filter(
      ({ changes, splitChanges }) =>
        Object.values(changes).length || Object.values(splitChanges || {}).length
    );

    const categoriesMap: KeyValueType<BnPChangeTypeMap> = {};
    let itemKey = '';

    catList.forEach(category => {
      const changes = getBnPItemsReviewResponseChanges(category);
      const splitChanges = getBnPItemsReviewResponseSplitChanges(category);
      itemKey = category.itemKey;

      categoriesMap[changes.id] = {
        notes: notes || '',
        isInProgress,
        itemLabel: label,
        isDeleted: false,
        isNew: false,
        itemKey: category.itemKey,
        categoryKey: category.categoryKey,
        categoryName: category.categoryName,
        revId: category.revId,
        ...changes,
        ...splitChanges,
        isApplied: changes.isApplied && splitChanges.isApplied,
        rejectNotes: changes.rejectNotes || splitChanges.rejectNotes,
        hasApplicabilityChange: false,
      };
      if (changes.applicability.hasChanged) {
        categoriesMap[changes.id].hasApplicabilityChange = !!Object.keys(
          getApplicabilityBeforeMap(changes.applicability, 'default')
        ).length;
        // if the category applicability has changed then we want to make sure all the splits associated with this category show up in the review table
        Object.values(category.splits || {}).forEach(split => {
          if (
            !categoriesMap[changes.id].splits[split.id] &&
            !split.isDeleted &&
            Object.keys(getApplicabilityBeforeMap(changes.applicability, split.id)).length
          ) {
            categoriesMap[changes.id].splits[split.id] = {
              id: split.id,
              isNew: false,
              isDeleted: false,
              splitNumber: split.splitNumber,
              description: {
                after: processRteForChangeLog(split.description || ''),
                before: '',
                hasChanged: false,
              },
            };
          }
        });
      }
    });
    if (Object.keys(categoriesMap).length) {
      reviewItems.push({
        categories: categoriesMap,
        itemKey,
        sortOrder: sortOrder === undefined ? Object.keys(categoriesMap).length : sortOrder,
      });
    }
  });

  return reviewItems;
};

/**
 *
 * @param applicability
 * @param targetSplitId
 * @returns the applicability before map; if the map has entries then that means that applicability has changed for the targetSplitId
 */
const getApplicabilityBeforeMap = (
  applicability: ReviewChangeMap<KeyValueType<string>>,
  targetSplitId: string
) => {
  const before = applicability.before;
  const after = applicability.after;
  const beforeMap: KeyValueType<true> = {};
  Object.entries(before).forEach(([modelId, splitId]) => {
    if (splitId === targetSplitId) {
      beforeMap[modelId] = true;
    }
  });

  // need to go through all the after entries to see if any model was added/deleted for this split
  const afterEntries = Object.entries(after);
  for (const entry of afterEntries) {
    const [modelId, splitId] = entry;
    if (splitId === targetSplitId) {
      if (beforeMap[modelId]) {
        delete beforeMap[modelId];
      } else {
        // a new model has been added
        break;
      }
    }
  }

  return beforeMap;
};
