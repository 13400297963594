import { useCallback, useEffect, useMemo } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { LIMITED_DATA_STATUS } from '../../../../../constants/vehicleData/VDConstants';
import { ChecklistItem } from '../../../../../hooks/useChecklist';
import useStores from '../../../../../hooks/useStores';
import {
  InteriorColorFieldStatusRequest,
  InteriorColorItem,
} from '../../../../../models/colors.model';
import { VehicleTeam } from '../../../../../models/vehicleData.model';
import { handleErrorResponse } from '../../../../../utils/errorHandlingUtils';
import { updateInteriorColorStatus } from '../../../../../webservices/vehicleColorsApi';
import { toGqlBrand, toGqlTeam } from '../../../../../utils/graphqlUtils';
import { UpdateIntColorStatusInput } from '../../../../../gql/generated';

interface IUseFieldStatusIntColorProps {
  intColor?: InteriorColorItem;
  gradeId?: string;
  modelId?: string;
}

const useFieldStatusIntColor = (props?: IUseFieldStatusIntColorProps) => {
  const { intColor: interiorColorItem, gradeId, modelId } = props ?? {};

  const {
    userStore: { brand },
    limitedDataStatusStore: { setDisabled },
    colorsStore,
  } = useStores();

  const { team, seriesId, year: modelYear } = useParams<{
    team: VehicleTeam;
    seriesId: string;
    year: string;
  }>();

  const intColor: InteriorColorItem | undefined = useMemo(() => {
    if (!interiorColorItem) return colorsStore.defaultInteriorColorItem;
    return interiorColorItem;
  }, [interiorColorItem, colorsStore.defaultInteriorColorItem]);

  useEffect(() => {
    const hasIntColorChange = intColor?.fieldStatus?.id !== colorsStore.fieldStatus?.id;
    if (intColor?.fieldStatus && hasIntColorChange) {
      colorsStore.fieldStatus = JSON.parse(JSON.stringify(intColor.fieldStatus));
    }
  }, [intColor, colorsStore]);

  const payload: InteriorColorFieldStatusRequest = useMemo(() => {
    if (interiorColorItem?.fieldStatus) return interiorColorItem.fieldStatus;
    return colorsStore.fieldStatus;
  }, [colorsStore.fieldStatus, interiorColorItem]);

  const onSave = useCallback(
    async (callBack: () => void) => {
      if (!intColor) return;

      try {
        setDisabled(true);
        const res = await trackPromise(
          updateInteriorColorStatus({
            brand: toGqlBrand(brand),
            team: toGqlTeam(team),
            seriesId,
            modelYear: Number(modelYear),
            payload: payload as UpdateIntColorStatusInput,
          })
        );

        intColor.fieldStatus = res;
        toast.success(`Interior Color Field Status updated successfully`);
        setDisabled(false);
        callBack();
      } catch (e) {
        setDisabled(false);
        handleErrorResponse(e, 'Interior Color Field Status failed update');
      }
    },
    [intColor, brand, modelYear, seriesId, setDisabled, team, payload]
  );

  const onEdit = useCallback(() => {
    if (!intColor) return;

    Object.keys(payload.modelApplicability).forEach(gId => {
      Object.keys(payload.modelApplicability[gId]).forEach(mId => {
        const status = payload.modelApplicability[gId][mId];
        if (status === LIMITED_DATA_STATUS.PUBLISHED)
          payload.modelApplicability[gId][mId] = LIMITED_DATA_STATUS.READY_TO_PUBLISH;
      });
    });
  }, [intColor, payload]);

  const onClickModel = useCallback(() => {
    if (!intColor || !gradeId || !modelId) return;

    const status = payload.modelApplicability[gradeId][modelId];
    if (status === LIMITED_DATA_STATUS.IN_PROGRESS) {
      payload.modelApplicability[gradeId][modelId] = LIMITED_DATA_STATUS.READY_TO_PUBLISH;
    } else if (status === LIMITED_DATA_STATUS.READY_TO_PUBLISH) {
      payload.modelApplicability[gradeId][modelId] = LIMITED_DATA_STATUS.IN_PROGRESS;
    }
  }, [intColor, gradeId, modelId, payload]);

  const onEditModel = useCallback(() => {
    if (!intColor || !gradeId || !modelId) return;

    if (!payload.modelApplicability[gradeId]) {
      payload.modelApplicability[gradeId] = {
        [modelId]: LIMITED_DATA_STATUS.READY_TO_PUBLISH,
      };
      return;
    }

    const status = payload.modelApplicability[gradeId][modelId];
    if (!status || status === LIMITED_DATA_STATUS.PUBLISHED) {
      payload.modelApplicability[gradeId][modelId] = LIMITED_DATA_STATUS.READY_TO_PUBLISH;
    }
  }, [intColor, gradeId, modelId, payload]);

  const onEditGrade = useCallback(
    (grade: ChecklistItem) => {
      if (!intColor) return;

      const gId = grade.id;
      const models = grade.items ?? [];

      if (!payload.modelApplicability[gId]) {
        payload.modelApplicability[gId] = {};
      }

      models.forEach(({ id: mId }) => {
        const status = payload.modelApplicability[gId][mId];
        if (!status || status === LIMITED_DATA_STATUS.PUBLISHED)
          payload.modelApplicability[gId][mId] = LIMITED_DATA_STATUS.READY_TO_PUBLISH;
      });
    },
    [intColor, payload]
  );

  const onEditAllModels = useCallback(
    (gradeList: ChecklistItem[]) => gradeList.forEach(grade => onEditGrade(grade)),
    [onEditGrade]
  );

  const getMultiLimitedDataStatuses = useCallback(() => {
    if (!gradeId) {
      return {
        statuses: [],
        hideTotal: false,
      };
    }

    const gApplicability = payload.modelApplicability[gradeId] ?? {};
    const models = Object.keys(gApplicability);
    const inProgress: LIMITED_DATA_STATUS[] = [];
    const readyToPublish: LIMITED_DATA_STATUS[] = [];
    const published: LIMITED_DATA_STATUS[] = [];

    models.forEach(mId => {
      const status = gApplicability[mId];
      if (status === LIMITED_DATA_STATUS.PUBLISHED) published.push(status);
      else if (status === LIMITED_DATA_STATUS.READY_TO_PUBLISH) readyToPublish.push(status);
      else if (status === LIMITED_DATA_STATUS.IN_PROGRESS) inProgress.push(status);
    });

    const notPublished = readyToPublish.concat(inProgress);
    const statuses = notPublished.length > 0 ? notPublished : published;
    const hideTotal =
      readyToPublish.length === models.length ||
      inProgress.length === models.length ||
      published.length === models.length;

    return {
      statuses,
      hideTotal,
    };
  }, [gradeId, payload]);

  const hasChanges = useCallback(
    (gradeList: ChecklistItem[]) => {
      if (!intColor) {
        return false;
      }

      const cSelection = gradeList.filter(item => {
        return item.selected || item.items?.some(code => code.selected);
      });

      if (!cSelection.length) return false;

      const defModelApplicability = intColor.fieldStatus?.modelApplicability ?? {};
      const defGradeList = Object.entries(defModelApplicability);
      const gList = Object.entries(payload.modelApplicability);

      if (defGradeList.length !== gList.length) return true;

      return !!defGradeList.find(([gId, gModels]) => {
        const grade = payload.modelApplicability[gId];
        if (!grade) return true;

        const modelList = Object.entries(gModels);
        const cModelList = Object.entries(grade);

        if (modelList.length !== cModelList.length) return true;
        return !!modelList.find(([mId, status]) => grade[mId] !== status);
      });
    },
    [payload, intColor]
  );

  return {
    onEdit,
    onClickModel,
    onEditModel,
    onEditGrade,
    onEditAllModels,
    getMultiLimitedDataStatuses,
    onSave,
    hasChanges,
  };
};

export default useFieldStatusIntColor;
