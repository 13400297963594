import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { useContext } from 'react';
import { KeyFeatureContext } from '.';
import { VDKeyFeatures } from '../../models/features.model';
import styles from './KeyFeature.module.scss';

interface Props {
  keyFeatures?: VDKeyFeatures;
  onClick?: () => void;
}

const KeyFeatureCTA = ({ keyFeatures, onClick = () => undefined }: Props) => {
  const { readOnly, hasChanges, hasErrors } = useContext(KeyFeatureContext);
  const numKeyFeatures = Object.keys(keyFeatures ?? {}).length;
  const clickable = !!numKeyFeatures || !readOnly;

  return (
    <div
      className={cx(styles.container, {
        [styles.cta]: clickable,
        [styles.hasChanges]: hasChanges || hasErrors,
      })}
      onClick={clickable ? onClick : undefined}
      data-testid="key-feature-cta"
    >
      <span className={styles.iconContainer}>
        <FontAwesomeIcon
          data-testid="key-feature-cta-icon"
          className={cx(styles.icon, { [styles.hasKeyFeatures]: !!numKeyFeatures })}
          icon={faStar}
        />
      </span>
      <span className={styles.statusMessage} data-testid="key-feature-cta-text">
        Key Feature {numKeyFeatures > 1 ? `(${numKeyFeatures})` : ''}
      </span>
    </div>
  );
};

export default KeyFeatureCTA;
