import * as differ from 'diff';
import { v4 as uuidv4 } from 'uuid';
import { convertToRichTextObject } from 'vapi-ui-common';
import { ChangeLogTypes } from '../models/changeLog.model';
import {
  CommonLanguageCategory,
  CommonLanguageChangeLogItem,
  CommonLanguageChangeLogResponse,
  CommonLanguageChangeLogResponseItem,
  CommonLanguageItem,
} from '../models/commonLanguage.model';
import { toLowerCase } from '../utils';

export const changeLogXForm = (version: string, data: CommonLanguageChangeLogResponse) => {
  const newData: CommonLanguageChangeLogItem[] = [];
  data.changes.forEach((item: CommonLanguageChangeLogResponseItem) => {
    item.changes.forEach(change => {
      const { id, revId, description, notes, isInProgress } = item;
      const changeType = change.changeType as ChangeLogTypes;
      newData.push({
        uid: uuidv4(),
        id,
        revId,
        description,
        isInProgress,
        notes,
        changeType,
        before: change.before,
        beforeValue:
          change.changeType === ChangeLogTypes.SUB_CATEGORY ? change.before : change.beforeValue,
        afterValue:
          change.changeType === ChangeLogTypes.SUB_CATEGORY ? change.after : change.afterValue,
        after: change.after,
        // changes field is for string diff
        changes: ChangeLogTypes.IN_PROGRESS
          ? []
          : differ.diffChars(change.before || '', change.after || ''),
        modelsAdded: change.modelsAdded,
        modelsRemoved: change.modelsRemoved,
        modifiedDate: change.modifiedDate,
        modifiedBy: change.modifiedBy,
        canRevert:
          version === 'DRAFT' &&
          changeType !== ChangeLogTypes.CATEGORY &&
          changeType !== ChangeLogTypes.DRAFT_RESET,
      });
    });
  });
  return newData;
};

export const getCategoryId = (category: string, categories: CommonLanguageCategory[]) => {
  return categories.filter(item => item.value === category).map(item => item.id)[0];
};

export const filterSpecs = (
  searchText: string,
  vehicleFilters: string[],
  categoryFilters: string[],
  data: CommonLanguageItem[]
) => {
  const loweredSearchText = toLowerCase(searchText);
  return data.filter(
    item =>
      // test search string
      (!loweredSearchText ||
        toLowerCase(item.category).includes(loweredSearchText) ||
        toLowerCase(item.subCategory).includes(loweredSearchText) ||
        toLowerCase(convertToRichTextObject(item.description).text).includes(loweredSearchText) ||
        toLowerCase(item.notes).includes(loweredSearchText)) &&
      // toLower(item.published).indexOf(loweredSearchText) > -1 // not sure if we need to filter based on the published version
      // test filter vehicle box
      (!vehicleFilters.length ||
        (item.vehicles && !item.vehicles.length) ||
        vehicleFilters.filter(
          filterItem =>
            item.vehicles && item.vehicles.filter(vehicle => vehicle.seriesId === filterItem).length
        ).length) &&
      // test filter category box
      (!categoryFilters.length ||
        categoryFilters.filter(filterItem => item.category === filterItem).length)
  );
};
