import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContainer,
} from 'vapi-ui-common';
import { Table, TableRow } from '../../../components/Table';
import { IDValueType } from '../../../models/common.model';
import { RefItem } from '../../../models/refItem.model';
import styles from './sortModal.module.scss';

export interface SortModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (list: IDValueType[] | RefItem[]) => void;
  idValueList?: IDValueType[];
  headerText?: string;
}

const SortModal = ({ open, onClose, onSave, idValueList = [], headerText }: SortModalProps) => {
  const [list, setList] = useState([] as IDValueType[]);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setList(idValueList.slice());
    setCanSave(false);
  }, [idValueList, open]);

  const onDragEndCallback = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    setCanSave(true);

    const [removed] = list.splice(result.source.index, 1);
    list.splice(result.destination.index, 0, removed);

    return list;
  };

  const onSaveCallback = () => {
    onSave(list);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader onClose={onClose}>{headerText}</ModalHeader>
      <ModalBody>
        <ScrollableContainer>
          <DragDropContext onDragEnd={onDragEndCallback}>
            <Table fullWidth data-testid="sort-modal-comp">
              <Droppable droppableId="sortFeatureCatDroppable">
                {provided => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {list.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {provided => (
                          <TableRow
                            zebra
                            key={item.id}
                            className={styles.row}
                            innerRef={provided.innerRef}
                            style={provided.draggableProps.style}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>{item.value}</td>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </ScrollableContainer>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" disabled={!canSave} onClick={onSaveCallback}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(SortModal);
