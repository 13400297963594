import React, { TableHTMLAttributes } from 'react';
import PackageTableRow from '../PackageTableRow';

interface OptionsLexusSpacerRowProps extends TableHTMLAttributes<HTMLTableRowElement> {
  count: number;
  rowHeight?: number;
  expandRow?: boolean;
}

const OptionsLexusSpacerRow = ({
  count,
  rowHeight,
  expandRow,
  ...rest
}: OptionsLexusSpacerRowProps) => {
  const items = [];
  for (var i = 0; i < count; i++) {
    items.push(<PackageTableRow rowHeight={rowHeight} {...rest} key={i} />);
  }
  return <>{items}</>;
};

export default OptionsLexusSpacerRow;
