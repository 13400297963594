import cx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  ConfirmModal,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'vapi-ui-common';
import { LimitedDataStatus } from '../../../../../../../components/LimitedDataStatus';
import SecondaryHeader from '../../../../../../../components/SecondaryHeader';
import {
  LIMITED_DATA_STATUS,
  VD_PUBLISH_TYPE,
} from '../../../../../../../constants/vehicleData/VDConstants';
import useLimitedDataTableState from '../../../../../../../hooks/useLimitedDataTableState';
import useModelCodesTable from '../../../../../../../hooks/useModelCodesTable';
import useStores from '../../../../../../../hooks/useStores';
import { BRAND_TDPR, Brand } from '../../../../../../../models/user.model';
import {
  VDInProgressToPublish,
  VDTab,
  VehicleDataVersionInfo,
} from '../../../../../../../models/vehicleData.model';
import {
  ModelDirectory,
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../models/vehicleModel.model';
import { FieldStatus } from '../../../../../../../stores/vehicleData/modelTabStore';
import { getTable } from '../../../../../../../utils/limitedDataStatusUtils';
import styles from './publishEFCModal.module.scss';
import { CompareFeatureLangMap } from '../../../../../../../models/compareFeatures.model';
import { ColorGradeLangItem, ColorItemLangMap } from '../../../../../../../models/colors.model';
import ExpandList from '../../../../../../../components/ExpandList';
import { BnPFieldStatusResponse } from '../../../../../../../models/buildAndPrice.model';

interface PublishEFCModalProps {
  open: boolean;
  onConfirm: () => void;
  onConfirmWithInProgressData?: (inProgressToPublish: VDInProgressToPublish) => void;
  onClose: () => void;
  seriesId: string;
  year: string;
  versionInfo: VehicleDataVersionInfo;
  publishType?: VD_PUBLISH_TYPE;
}

const enum PUBLISH_EFC_TABLES {
  SERIES_SETTINGS = 0,
  MODELS = 1,
  FEATURES = 2,
  SPECS = 3,
  OPTIONS = 4,
  B_AND_P = 5,
  EXTERIOR_COLORS = 6,
  INTERIOR_COLORS = 7,
  COMPARE_VIEW = 8,
}

const PublishEFCModal = ({
  open,
  onClose,
  onConfirm,
  onConfirmWithInProgressData,
  seriesId,
  year,
  versionInfo,
  publishType,
}: PublishEFCModalProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(VDTab.SERIES_SETTINGS);
  const [confirmWithoutInProgressModal, setConfirmWithoutInProgressModal] = useState<boolean>(
    false
  );
  const [confirmWithInProgressModal, setConfirmWithInProgressModal] = useState<boolean>(false);
  const [models, setModels] = useState<ModelDirectory>({});
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const { checkboxTables, onClickAll, onClickRow, totalChecked } = useLimitedDataTableState();

  const {
    userStore: { brand },
    seriesSettingsStore,
    vehicleModelsStore,
    featuresStore,
    teamStore,
    specsStore,
    optionsStore,
    bnpStore,
    colorsStore,
    compareFeatureStore,
  } = useStores();

  const { getModelCodesTable: getFeaturesTable } = useModelCodesTable(
    featuresStore.featureLangMaps,
    featuresStore.defaultLang,
    models,
    PUBLISH_EFC_TABLES.FEATURES,
    checkboxTables,
    onClickAll,
    onClickRow
  );
  const { getModelCodesTable: getSpecsTable } = useModelCodesTable(
    specsStore.specLangMaps,
    specsStore.defaultLang,
    models,
    PUBLISH_EFC_TABLES.SPECS,
    checkboxTables,
    onClickAll,
    onClickRow
  );
  const { getModelCodesTable: getOptionsTable } = useModelCodesTable(
    optionsStore.optionLangMaps,
    optionsStore.defaultLang,
    models,
    PUBLISH_EFC_TABLES.OPTIONS,
    checkboxTables,
    onClickAll,
    onClickRow
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          seriesSettingsStore.fetchData(
            brand as Brand,
            teamStore.team.param,
            seriesId,
            year,
            teamStore.team.defaultSeriesSettingsLanguage,
            teamStore.team.seriesSettingsLanguages,
            versionInfo
          ),
          vehicleModelsStore.fetchData({
            brand,
            team: teamStore.team.param,
            series: seriesId,
            year,
            versionInfo,
            languagePermissions: teamStore.team.langPermissions,
            defaultLanguage: teamStore.team.defaultLanguage,
          }),
        ]);

        const modelDirectory = vehicleModelsStore.vehicleModels
          .filter(model => brand !== BRAND_TDPR || model.getVal('isTDPR') || model.getVal('isUSVI'))
          .reduce(
            (
              obj: ModelDirectory,
              item: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>
            ) => {
              obj[item.id] = item;

              return obj;
            },
            {}
          );

        setModels(modelDirectory);

        await Promise.all([
          featuresStore.fetchData(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            vehicleModelsStore.vehicleModels,
            teamStore.team.switchShortLongDescription,
            vehicleModelsStore.grades,
            teamStore.team.langPermissions,
            versionInfo
          ),
          specsStore.fetchData(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            vehicleModelsStore.vehicleModels,
            vehicleModelsStore.grades,
            teamStore.team.langPermissions,
            versionInfo
          ),
          optionsStore.fetchData(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            vehicleModelsStore.vehicleModels,
            teamStore.team.langPermissions,
            versionInfo
          ),
          bnpStore.fetchData(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            vehicleModelsStore.grades,
            teamStore.team.langPermissions,
            versionInfo
          ),
          colorsStore.fetchData(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            vehicleModelsStore.sortedGrades(),
            teamStore.team.langPermissions,
            versionInfo
          ),
          compareFeatureStore.fetchData(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            vehicleModelsStore.grades,
            teamStore.team.langPermissions,
            versionInfo
          ),
        ]);

        setIsLoaded(true);
      } catch (e) {
        console.log(e);
        toast.error('Failed loading data');
      }
    };

    if (open) {
      fetchData();
    }
  }, [
    seriesId,
    seriesSettingsStore,
    brand,
    open,
    teamStore.team.defaultSeriesSettingsLanguage,
    teamStore.team.seriesSettingsLanguages,
    teamStore.team.param,
    versionInfo,
    year,
    vehicleModelsStore,
    teamStore.team.langPermissions,
    teamStore.team.defaultLanguage,
    featuresStore,
    teamStore.team.switchShortLongDescription,
    specsStore,
    optionsStore,
    bnpStore,
    colorsStore,
    compareFeatureStore,
  ]);

  const getTitle = () => {
    switch (publishType) {
      case VD_PUBLISH_TYPE.PREVIEW:
        return 'Preview';
      case VD_PUBLISH_TYPE.PUBLISH:
      default:
        return 'Publish';
    }
  };

  const getSeriesTable = useCallback(() => {
    const ids: string[] = [];
    const headers = ['Name', 'Field', '', 'Publish Status'];
    const seriesSettingsLangMaps = seriesSettingsStore.seriesSettingsLangMaps;
    const list = seriesSettingsLangMaps.reduce((total: string[][][], seriesSettingsLangMap) => {
      const defaultSeriesSettings = seriesSettingsLangMap[seriesSettingsStore.defaultLang];
      const fieldStatus = defaultSeriesSettings.fieldStatus;
      const row = [];
      const fields = [];
      const fieldValues = [];
      if (fieldStatus?.seating === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('Seating');
        fieldValues.push(defaultSeriesSettings.seating || '--');
      }

      if (fieldStatus?.estimatedMileage === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('Estimated Mileage');
        fieldValues.push(defaultSeriesSettings.estimatedMileage || '--');
      }

      if (fieldStatus?.mpge === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('MPGe');
        fieldValues.push(defaultSeriesSettings.mpge || '--');
      }

      if (fields.length) {
        row.push([defaultSeriesSettings.name]);
        row.push(fields);
        row.push(fieldValues);
        ids.push(fieldStatus.id);
        total.push(row);
      }
      return total;
    }, []);
    const boldColumns = [false, true, false];
    const isRichTexts = [false, false, false];

    return getTable(
      [ids],
      headers,
      [list],
      boldColumns,
      isRichTexts,
      [PUBLISH_EFC_TABLES.SERIES_SETTINGS],
      checkboxTables,
      onClickAll,
      onClickRow
    );
  }, [
    seriesSettingsStore.defaultLang,
    seriesSettingsStore.seriesSettingsLangMaps,
    checkboxTables,
    onClickAll,
    onClickRow,
  ]);

  const getModelsTable = useCallback(() => {
    const ids: string[] = [];
    const headers = ['Model', 'Field', '', 'Publish Status'];
    const list = Object.values(models).reduce((total: string[][][], model) => {
      const fieldStatus: FieldStatus = model.getVal('fieldStatus');
      const row = [];
      const fields = [];
      const fieldValues = [];
      if (fieldStatus?.trimTitle === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('Trim Title');
        fieldValues.push(model.getVal('trimTitle') || '--');
      }

      if (fieldStatus?.drive === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('Drive');
        fieldValues.push(model.getVal('drive') || '--');
      }

      if (fieldStatus?.transmission === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('Transmission');
        fieldValues.push(model.getVal('transmission') || '--');
      }

      if (fieldStatus?.grade === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('Grade');
        fieldValues.push(model.getVal('grade').value || '--');
      }

      if (fieldStatus?.description === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('Trim Description');
        fieldValues.push(model.getVal('description') || '--');
      }

      if (fieldStatus?.engine === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('Engine');
        fieldValues.push(model.getVal('engine') || '--');
      }

      if (fieldStatus?.horsepower === LIMITED_DATA_STATUS.IN_PROGRESS) {
        fields.push('Horsepower');
        fieldValues.push(model.getVal('horsepower') || '--');
      }

      if (fields.length) {
        row.push([model.getVal('code') || '--']);
        row.push(fields);
        row.push(fieldValues);
        ids.push(fieldStatus.id);
        total.push(row);
      }

      return total;
    }, []);
    const boldColumns = [false, true, false];
    const isRichTexts = [false, false, false];

    return getTable(
      [ids],
      headers,
      [list],
      boldColumns,
      isRichTexts,
      [PUBLISH_EFC_TABLES.MODELS],
      checkboxTables,
      onClickAll,
      onClickRow
    );
  }, [models, checkboxTables, onClickAll, onClickRow]);

  const getBnpTable = useCallback(() => {
    const ids: string[] = [];
    const headers = ['Name', 'Publish Status'];
    const boldColumns = [false];
    const isRichTexts = [false];

    const list = bnpStore.data.reduce((rows: string[][][], item) => {
      if (
        bnpStore.fieldStatus[item.name as keyof BnPFieldStatusResponse].status ===
        LIMITED_DATA_STATUS.IN_PROGRESS
      ) {
        rows.push([[item.label]]);
        ids.push(item.uid);
      }

      return rows;
    }, []);

    return getTable(
      [ids],
      headers,
      [list],
      boldColumns,
      isRichTexts,
      [PUBLISH_EFC_TABLES.B_AND_P],
      checkboxTables,
      onClickAll,
      onClickRow
    );
  }, [bnpStore, checkboxTables, onClickAll, onClickRow]);

  const getColorsTable = useCallback(() => {
    const extIds: string[] = [];
    const headers = ['Models', 'Description', 'Publish Status'];
    const boldColumns = [false, false];
    const isRichTexts = [false, true];

    const exteriorList = colorsStore.colorItemLangMaps.reduce(
      (total: (string | JSX.Element)[][][], obj: ColorItemLangMap) => {
        const data = obj.langs[colorsStore.defaultLang];
        const textCells = [];

        if (data?.fieldStatus?.status === LIMITED_DATA_STATUS.IN_PROGRESS) {
          const modelCodes: string[] = [];

          Object.values(data.interiorApplicability).forEach(gradeList => {
            gradeList.forEach(item => {
              if (item.checked) {
                Object.values(item.interiorItem.modelApplicability).forEach(
                  modelApplicabilityItem => {
                    const modelsId = Object.keys(modelApplicabilityItem);

                    modelsId.forEach(uid => {
                      if (models[uid]) {
                        const code = models[uid].getVal('code');
                        if (!modelCodes.includes(code)) {
                          modelCodes.push(code);
                        }
                      }
                    });
                  }
                );
              }
            });
          });

          if (modelCodes.length) {
            textCells.push([<ExpandList min={6} list={modelCodes} />]);
            textCells.push([data.name]);
            extIds.push(data.fieldStatus.id);
            total.push(textCells);
          }
        }

        return total;
      },
      []
    );

    const intIds: string[] = [];
    const interiorDirectory: { [key: string]: boolean } = {};
    const interiorList = colorsStore.colorGradeLangItems.reduce(
      (total: (string | JSX.Element)[][][], obj: ColorGradeLangItem) => {
        obj.interiorItems.forEach(intItem => {
          const textCells = [];
          const modelCodes: string[] = [];
          const thisItem = intItem.langs[colorsStore.defaultLang];
          const gradeValues = Object.values(thisItem.fieldStatus?.modelApplicability || []);

          gradeValues.forEach(modelObj => {
            const modelsId = Object.keys(modelObj);

            modelsId.forEach(uid => {
              if (modelObj[uid] === LIMITED_DATA_STATUS.IN_PROGRESS && models[uid]) {
                const code = models[uid].getVal('code');
                if (!modelCodes.includes(code)) {
                  modelCodes.push(models[uid].getVal('code'));
                }
              }
            });
          });

          if (modelCodes.length && !interiorDirectory[modelCodes.join(',') + thisItem.name]) {
            interiorDirectory[modelCodes.join(',') + thisItem.name] = true;
            textCells.push([<ExpandList min={6} list={modelCodes} />]);
            textCells.push([thisItem.name]);
            intIds.push(thisItem.fieldStatus?.id || '');
            total.push(textCells);
          }
        });

        return total;
      },
      []
    );

    return getTable(
      [extIds, intIds],
      headers,
      [exteriorList, interiorList],
      boldColumns,
      isRichTexts,
      [PUBLISH_EFC_TABLES.EXTERIOR_COLORS, PUBLISH_EFC_TABLES.INTERIOR_COLORS],
      checkboxTables,
      onClickAll,
      onClickRow
    );
  }, [
    checkboxTables,
    colorsStore.colorItemLangMaps,
    colorsStore.defaultLang,
    onClickAll,
    onClickRow,
    models,
    colorsStore.colorGradeLangItems,
  ]);

  const getCompareTable = useCallback(() => {
    const ids: string[] = [];
    const headers = ['Grades', 'Description', 'Publish Status'];
    const boldColumns = [false, false];
    const isRichTexts = [false, true];
    const list = compareFeatureStore.compareFeatureLangMaps.reduce(
      (total: (string | JSX.Element)[][][], obj: CompareFeatureLangMap) => {
        const data = obj.langs[compareFeatureStore.defaultLang];
        const gradeIds = Object.keys(data.fieldStatus.gradeApplicability);
        const textCells = [];

        if (
          data.fieldStatus.status === LIMITED_DATA_STATUS.READY_TO_PUBLISH ||
          data.fieldStatus.status === LIMITED_DATA_STATUS.PUBLISHED
        ) {
          const gradeValues: string[] = [];

          gradeIds.forEach(id => {
            if (data.fieldStatus.gradeApplicability[id] === LIMITED_DATA_STATUS.IN_PROGRESS) {
              const gradeItem = vehicleModelsStore.grades.find(item => item.id === id);
              gradeValues.push(gradeItem?.value);
            }
          });

          if (gradeValues.length) {
            textCells.push([<ExpandList min={8} list={gradeValues} />]);
            textCells.push([data.description]);
            ids.push(data.fieldStatus.id);
            total.push(textCells);
          }
        } else if (data.fieldStatus.status === LIMITED_DATA_STATUS.IN_PROGRESS) {
          textCells.push(['All Grades']);
          textCells.push([data.description]);
          ids.push(data.fieldStatus.id);
          total.push(textCells);
        }

        return total;
      },
      []
    );

    return getTable(
      [ids],
      headers,
      [list],
      boldColumns,
      isRichTexts,
      [PUBLISH_EFC_TABLES.COMPARE_VIEW],
      checkboxTables,
      onClickAll,
      onClickRow
    );
  }, [
    checkboxTables,
    compareFeatureStore.compareFeatureLangMaps,
    compareFeatureStore.defaultLang,
    onClickAll,
    onClickRow,
    vehicleModelsStore.grades,
  ]);

  const populatePayload = (
    tableId: PUBLISH_EFC_TABLES,
    name: string,
    submitObj: VDInProgressToPublish
  ) => {
    let obj = {};

    if (checkboxTables[tableId]) {
      Object.keys(checkboxTables[tableId]).forEach(id => {
        if (checkboxTables[tableId][id]) {
          obj = {
            ...obj,
            [id]: true,
          };
        }
      });
    }

    if (Object.keys(obj).length) {
      submitObj = {
        ...submitObj,
        [name]: obj,
      };
    }

    return submitObj;
  };

  const submitInProgressData = () => {
    let submitObj = {};
    submitObj = populatePayload(PUBLISH_EFC_TABLES.SERIES_SETTINGS, 'seriesSettings', submitObj);
    submitObj = populatePayload(PUBLISH_EFC_TABLES.MODELS, 'models', submitObj);
    submitObj = populatePayload(PUBLISH_EFC_TABLES.FEATURES, 'features', submitObj);
    submitObj = populatePayload(PUBLISH_EFC_TABLES.SPECS, 'specs', submitObj);
    submitObj = populatePayload(PUBLISH_EFC_TABLES.OPTIONS, 'options', submitObj);
    submitObj = populatePayload(PUBLISH_EFC_TABLES.B_AND_P, 'bnp', submitObj);
    submitObj = populatePayload(PUBLISH_EFC_TABLES.EXTERIOR_COLORS, 'exteriorColors', submitObj);
    submitObj = populatePayload(PUBLISH_EFC_TABLES.INTERIOR_COLORS, 'interiorColors', submitObj);
    submitObj = populatePayload(PUBLISH_EFC_TABLES.COMPARE_VIEW, 'compareFeatures', submitObj);

    onConfirmWithInProgressData && onConfirmWithInProgressData(submitObj);
  };

  return (
    <>
      <Modal className={styles.modalContainer} open={open} onClose={onClose}>
        {!isLoaded ? (
          <Spinner />
        ) : (
          <>
            <ModalHeader onClose={onClose}>{`${getTitle()} to EFC`}</ModalHeader>
            <ModalBody className={styles.modalBody}>
              <div className={styles.bodyDescription}>
                You have Certified Data that is marked as{' '}
                {<LimitedDataStatus status={LIMITED_DATA_STATUS.IN_PROGRESS} />}.
                {` Select all that
                you wish to ${getTitle().toLowerCase()} downstream to EFC.`}
              </div>
              <SecondaryHeader
                tabs={Object.values(VDTab)}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                renderButtons={() => <></>}
                className={styles.secondaryHeader}
              />
              {selectedTab === VDTab.SERIES_SETTINGS && getSeriesTable()}
              {selectedTab === VDTab.MODELS && getModelsTable()}
              {selectedTab === VDTab.FEATURES && getFeaturesTable()}
              {selectedTab === VDTab.SPECS && getSpecsTable()}
              {selectedTab === VDTab.OPTIONS && getOptionsTable()}
              {selectedTab === VDTab.BNP && getBnpTable()}
              {selectedTab === VDTab.COLORS && getColorsTable()}
              {selectedTab === VDTab.COMPARE_FEATURES && getCompareTable()}
            </ModalBody>
            <ModalFooter className={styles.modalFooter}>
              <Button
                className={cx({ [styles.disabledButton]: !!totalChecked })}
                variant="transparent"
                disabled={!!totalChecked}
                onClick={() => {
                  setConfirmWithoutInProgressModal(true);
                }}
              >
                {`${getTitle()} without in Progress data`}
              </Button>
              <Button
                variant="primary"
                disabled={!totalChecked}
                onClick={() => {
                  setConfirmWithInProgressModal(true);
                }}
              >
                {`${getTitle()} (${totalChecked}) to EFC`}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
      <ConfirmModal
        headerText={`${getTitle()} without 'In progress' to EFC`}
        bodyText={`Are you sure you’d like to ${getTitle().toLowerCase()} without sending In Progress data to EFC?`}
        confirmButtonText={`${getTitle()} Without In Progress`}
        open={confirmWithoutInProgressModal}
        onClose={() => {
          setConfirmWithoutInProgressModal(false);
        }}
        onConfirm={() => {
          onConfirm();
          setConfirmWithoutInProgressModal(false);
          onClose();
        }}
      />
      <ConfirmModal
        headerText={`${getTitle()} with 'In progress' to EFC`}
        bodyText={`Are you sure you’d like to ${getTitle().toLowerCase()} and In Progress data to EFC?`}
        confirmButtonText={`${getTitle()} With In Progress`}
        open={confirmWithInProgressModal}
        onClose={() => {
          setConfirmWithInProgressModal(false);
        }}
        onConfirm={() => {
          submitInProgressData();
          setConfirmWithInProgressModal(false);
          onClose();
        }}
      />
    </>
  );
};

export default PublishEFCModal;
