import cx from 'clsx';
import React, { useRef, useState } from 'react';
import styles from './tooltip.module.scss';

export interface TooltipProps {
  popoverClass?: string;
  toggleElement: React.ReactElement;
  popoverElement?: React.ReactElement;
  popoverText?: string;
  align?: 'right' | 'left';
  popoverElementClass?: string;
  defaultOpen?: boolean;
  leftInititalPosition?: number;
  ignoreCalculatedPosition?: boolean;
  hide?: boolean;
}

const Tooltip = ({
  popoverClass,
  toggleElement,
  popoverElement,
  popoverText,
  hide,
  popoverElementClass,
  align,
  defaultOpen = false,
}: TooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handlePosition = () => {
    setOpen(true);
  };

  const handleExitPosition = () => {
    setOpen(false);
  };

  return (
    <div className={styles.popoverContainer} data-testid="tooltip-component">
      <div ref={ref} className={cx([popoverClass, styles.popover])}>
        {React.cloneElement(toggleElement, {
          onMouseOver: () => handlePosition(),
          onMouseLeave: () => handleExitPosition(),
        })}
      </div>
      {open && !hide && (
        <div className={styles.popoverElementContainer}>
          <div
            className={cx(
              {
                [styles.popoverElement]: true,
                [styles.popoverDefault]: true,
              },
              styles[`align-${align}`],
              popoverElementClass
            )}
          >
            {popoverElement}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
