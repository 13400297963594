import cx from 'clsx';
import React, { LegacyRef, useCallback, useEffect, useRef } from 'react';
import styles from './tableRow.module.scss';

export interface TableRowProps extends TableRowBaseProps {
  onFillRowHeightChange?(height: number): void;
  rowHeight?: string | number;
}

export const TableRow: React.FC<TableRowProps> = ({
  onFillRowHeightChange,
  rowHeight,
  ...rest
}) => {
  const ref = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (onFillRowHeightChange) {
      setTimeout(() => {
        if (ref && ref.current) {
          // This is not being executed sometimes because onFillRowHeightChange becomes undefined at somepoint when ref changes
          // for this reason i ended creating the onInnerRefChange method and onRefChange
          // console.log('this is my height>>>', ref.current.offsetHeight);
          onFillRowHeightChange(ref.current.getBoundingClientRect().height);
        }
      }, 100);
    }
  }, [onFillRowHeightChange]);

  const onInnerRefChange = useCallback(
    (htmlTableRowElement: HTMLTableRowElement) => {
      if (!onFillRowHeightChange || !htmlTableRowElement) return;
      onFillRowHeightChange(htmlTableRowElement.getBoundingClientRect().height);
    },
    [onFillRowHeightChange]
  );

  return (
    <TableRowBase
      innerRef={ref}
      onInnerRefChange={onInnerRefChange}
      style={{
        height: `${rowHeight}px`,
      }}
      {...rest}
    />
  );
};

export interface TableRowBaseProps extends React.HTMLAttributes<HTMLTableRowElement> {
  zebra?: boolean;
  hoverShadow?: boolean;
  className?: string;
  innerRef?: LegacyRef<HTMLTableRowElement>;
  onInnerRefChange?(htmlTableRowElement: HTMLTableRowElement): void;
}

export const TableRowBase = ({
  zebra,
  hoverShadow,
  className,
  innerRef,
  onInnerRefChange,
  ...rest
}: TableRowBaseProps) => {
  const onRefChange = useCallback(
    (htmlTableRowElement: HTMLTableRowElement) => {
      if (onInnerRefChange) {
        onInnerRefChange(htmlTableRowElement);
      }

      if (typeof innerRef === 'function') {
        innerRef(htmlTableRowElement);
      } else if (innerRef) {
        // @ts-ignore
        innerRef.current = htmlTableRowElement;
      }
    },
    [innerRef, onInnerRefChange]
  );

  return (
    <tr
      ref={onRefChange}
      className={cx(styles.tr, zebra && styles.zebra, hoverShadow && styles.hoverShadow, className)}
      data-testid="table-row-comp"
      {...rest}
    />
  );
};
