import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import { CategoryLanguageMap, CategoryResponse } from '../models/category.model';
import { ChangeLogRequest, ChangeLogResponse } from '../models/changeLog.model';
import {
  FeatureFieldStatusRequest,
  FeatureRequest,
  FeatureResponse,
  FeatureSplitRequest,
  FeatureSplitResponse,
  FeaturesReviewRequest,
  FeaturesReviewResponse,
} from '../models/features.model';
import { ReviewChangeRequest } from '../models/review.model';
import { Language } from '../models/user.model';
import { VehicleTeam } from '../models/vehicleData.model';
import {
  ModelFieldStatus,
  UpdateAllModelStatusesRequest,
  UpdateAllModelStatusesResponse,
} from '../models/vehicleModel.model';
import API from '../webservices/api';
import {
  CATEGORIES_PATH,
  addCategories as _addCategories,
  getCategories as _getCategories,
  updateCategory as _updateCategory,
} from './categoryApi';

const RESOURCE_PATH = '/vehicle-features';

// Models
const FEATURES_PATH = '/features';
export const getFeatures = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<FeatureResponse[]>(
    `${RESOURCE_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}?${params}`
  );
};

export const addFeature = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: FeatureRequest,
  lang: string
) => {
  return API.post<FeatureResponse>(
    `${RESOURCE_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}`,
    payload
  );
};

export const updateFeature = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: FeatureRequest,
  lang: string,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false
) => {
  return API.put<FeatureResponse>(
    `${RESOURCE_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}`,
    {
      ...payload,
      acceptChanges: acceptChanges || unlinkFromTMNA,
      unlinkFromTMNA,
    }
  );
};

export const updateFeatureStatus = (
  brand: string,
  team: VehicleTeam,
  serieId: string,
  modelYear: string,
  language: Language,
  payload: FeatureFieldStatusRequest
) => {
  return API.put<ModelFieldStatus>(
    `${RESOURCE_PATH}${FEATURES_PATH}-status/${brand}/${team}/${serieId}/${modelYear}/${language}`,
    payload
  );
};

export const updateAllFeatureStatuses = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: UpdateAllModelStatusesRequest
) => {
  return API.post<UpdateAllModelStatusesResponse>(
    `${RESOURCE_PATH}${FEATURES_PATH}-status/${brand}/${team}/${seriesId}/${modelYear}/${language}/all`,
    payload
  );
};

export const deleteFeature = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  featureId: string
) => {
  return API.delete(
    `${RESOURCE_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}/${featureId}`
  );
};

export const importFromCommonLanguage = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: string[]
) => {
  return API.post<FeatureResponse[]>(
    `${RESOURCE_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}/import`,
    { commonLangIds: payload }
  );
};

// ChangeLog
export const getChangeLog = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  version: string,
  type: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<ChangeLogResponse[]>(
    `${RESOURCE_PATH}/change-log/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}/${type}?${params}`
  );
};

export const revertChange = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  type: string,
  payload: ChangeLogRequest
) => {
  return API.put(
    `${RESOURCE_PATH}/change-log/revert/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}/${type}`,
    payload
  );
};

// Categories
export const getCategories = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string,
  includeAll?: boolean
) => {
  return _getCategories(RESOURCE_PATH, brand, team, series, year, 'EN', version, includeAll);
};

export const getCategoriesByLang = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<CategoryResponse[]>(
    `${RESOURCE_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}?${params}`
  );
};

export const addCategories = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: { [lang: string]: string },
  id?: string
) => {
  return _addCategories(RESOURCE_PATH, brand, team, series, year, payload, id);
};

export const updateCategory = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  id: string,
  name: string,
  lang: string
) => {
  return _updateCategory(RESOURCE_PATH, brand, team, series, year, id, name, lang);
};

// Sub categories
const SUB_CATEGORIES_PATH = '/sub-categories';
export const getSubCategories = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<CategoryResponse[]>(
    `${RESOURCE_PATH}${SUB_CATEGORIES_PATH}/${brand}/${team}/${series}/${year}/EN?${params}`
  );
};

export const getSubCategoriesByLang = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<CategoryResponse[]>(
    `${RESOURCE_PATH}${SUB_CATEGORIES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}?${params}`
  );
};

export const addSubCategories = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: { [lang: string]: string },
  id?: string
) => {
  const body: any = { subCategories: payload };
  if (id) {
    body.id = id;
  }
  return API.post<CategoryLanguageMap>(
    `${RESOURCE_PATH}${SUB_CATEGORIES_PATH}/${brand}/${team}/${series}/${year}`,
    body
  );
};

export const updateSubCategory = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  id: string,
  name: string,
  lang: string
) => {
  return API.put(
    `${RESOURCE_PATH}${SUB_CATEGORIES_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}/${id}`,
    { name }
  );
};

// Review
const REVIEW_PATH = '/review';
export const getReviewCategories = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CategoryResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const getReviewSubCategories = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CategoryResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${SUB_CATEGORIES_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const getReviewFeatures = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<FeaturesReviewResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const updateReviewFeature = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version: string,
  payload: ReviewChangeRequest
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<FeaturesReviewRequest>(
    `${RESOURCE_PATH}${REVIEW_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}?${params}`,
    payload
  );
};

// Review Common Language
const REVIEW_CL_PATH = '/review-cl';
export const getReviewCLFeatures = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<FeaturesReviewResponse[]>(
    `${RESOURCE_PATH}${REVIEW_CL_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const updateReviewCLFeature = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version: string,
  payload: ReviewChangeRequest
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<FeaturesReviewRequest>(
    `${RESOURCE_PATH}${REVIEW_CL_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}?${params}`,
    payload
  );
};

// Splits
const SPLITS_PATH = '/splits';
export const addFeatureSplit = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  payload: FeatureSplitRequest
) => {
  return API.post<FeatureSplitResponse>(
    `${RESOURCE_PATH}${FEATURES_PATH}${SPLITS_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}`,
    payload
  );
};

export const updateFeatureSplit = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  payload: FeatureSplitRequest
) => {
  return API.put<FeatureSplitResponse>(
    `${RESOURCE_PATH}${FEATURES_PATH}${SPLITS_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}`,
    payload
  );
};

export const deleteFeatureSplit = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  featureId: string,
  splitId: string
) => {
  return API.delete<FeatureSplitResponse>(
    `${RESOURCE_PATH}${FEATURES_PATH}${SPLITS_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}/${featureId}/${splitId}`
  );
};
