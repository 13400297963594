import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import styles from './highlightFeatureAction.module.scss';

type Props = {
  disabled?: boolean;
  highlighted: boolean;
  onClick: () => void;
};
const HighlightFeatureAction: React.FC<Props> = ({ highlighted, onClick, disabled }) => {
  return (
    <button onClick={onClick} className={styles.root} title="Highlight this item">
      <FontAwesomeIcon
        icon={faFlag}
        className={cx(styles.flagIcon, {
          [styles.active]: highlighted,
        })}
      />
    </button>
  );
};

export default HighlightFeatureAction;
