import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { BnPReviewRowContext } from '..';
import { TableCell } from '../../../../../../../components/Table';
import styles from '../bnPReviewTable.module.scss';

const CategoryCell = () => {
  const context = useContext(BnPReviewRowContext);

  if (!context) {
    return null;
  }

  const {
    category: { categoryName },
  } = context;

  return <TableCell className={styles.categoryCell}>{categoryName}</TableCell>;
};

export default observer(CategoryCell);
