import apolloClient from '../apolloClient';
import {
  AddDisclaimerDocument,
  AddDisclaimerMutation,
  AddDisclaimerMutationVariables,
  ArchiveDisclaimerDocument,
  ArchiveDisclaimerMutation,
  ArchiveDisclaimerMutationVariables,
  Brand,
  DeleteDisclaimerDocument,
  DeleteDisclaimerMutation,
  DeleteDisclaimerMutationVariables,
  Disclaimer,
  DownloadDisclaimersDocument,
  DownloadDisclaimersQuery,
  DownloadDisclaimersQueryVariables,
  GetDisclaimerCurrentVersionDocument,
  GetDisclaimerCurrentVersionQuery,
  GetDisclaimerCurrentVersionQueryVariables,
  GetDisclaimerVersionsDocument,
  GetDisclaimerVersionsQuery,
  GetDisclaimerVersionsQueryVariables,
  Language,
  Maybe,
  PublishDisclaimersToDownstreamDocument,
  PublishDisclaimersToDownstreamMutation,
  PublishDisclaimersToDownstreamMutationVariables,
  PublishDraftDisclaimerDocument,
  PublishDraftDisclaimerMutation,
  PublishDraftDisclaimerMutationVariables,
  QuerySearchDisclaimersArgs,
  Region,
  ResetDraftDisclaimersDocument,
  ResetDraftDisclaimersMutation,
  ResetDraftDisclaimersMutationVariables,
  RevertDisclaimerChangeDocument,
  RevertDisclaimerChangeMutation,
  RevertDisclaimerChangeMutationVariables,
  SearchDisclaimerChangeLogDocument,
  SearchDisclaimerChangeLogQuery,
  SearchDisclaimerChangeLogQueryVariables,
  SearchDisclaimerTokensDocument,
  SearchDisclaimerTokensQuery,
  SearchDisclaimerTokensQueryVariables,
  SearchDisclaimersDocument,
  SearchDisclaimersQuery,
  Status,
  UpdateDisclaimerDocument,
  UpdateDisclaimerMutation,
  UpdateDisclaimerMutationVariables,
  UploadDisclaimersDocument,
  UploadDisclaimersMutation,
  UploadDisclaimersMutationVariables,
} from '../gql/generated';
import {
  DisclaimerChangeLogRequest,
  DisclaimerIdRequest,
  DisclaimerVersionInfoResponse,
  DisclaimersChangeLogResponse,
  DisclaimersRequest,
  DisclaimersResponse,
} from '../models/disclaimers.model';
import { VersionInfo } from '../models/spanishDisclaimers.model';

// Disclaimers
export const getDisclaimers = async (
  brand: string,
  region: string,
  status: string
): Promise<DisclaimersResponse[]> => {
  const { data } = await apolloClient.query<SearchDisclaimersQuery, QuerySearchDisclaimersArgs>({
    fetchPolicy: 'network-only',
    query: SearchDisclaimersDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
      filter: {
        status: status.toUpperCase() as Status,
      },
    },
  });

  return data.searchDisclaimers.body;
};

export const addDisclaimer = async (
  brand: string,
  region: string,
  request: DisclaimersRequest
): Promise<DisclaimersResponse> => {
  const { data } = await apolloClient.mutate<AddDisclaimerMutation, AddDisclaimerMutationVariables>(
    {
      mutation: AddDisclaimerDocument,
      variables: {
        brand: brand as Brand,
        region: region.toUpperCase() as Region,
        payload: request,
      },
    }
  );

  if (!data) {
    throw new Error('Failed to add disclaimer');
  }

  return data.createDisclaimer.body;
};

export const updateDisclaimer = async (
  brand: string,
  region: string,
  request: DisclaimersRequest
): Promise<DisclaimersResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateDisclaimerMutation,
    UpdateDisclaimerMutationVariables
  >({
    mutation: UpdateDisclaimerDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
      payload: request,
    },
  });

  if (!data) {
    throw new Error('Failed to update disclaimer');
  }

  return data.updateDisclaimer.body;
};

export const deleteDisclaimer = async (
  brand: string,
  region: string,
  request: DisclaimerIdRequest
): Promise<DisclaimersResponse> => {
  const { data } = await apolloClient.mutate<
    DeleteDisclaimerMutation,
    DeleteDisclaimerMutationVariables
  >({
    mutation: DeleteDisclaimerDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
      payload: request,
    },
  });

  if (!data) {
    throw new Error('Failed to delete disclaimer');
  }

  return data.deleteDisclaimer.body;
};

export const archiveDisclaimer = async (
  brand: string,
  region: string,
  request: DisclaimerIdRequest
): Promise<Disclaimer> => {
  const { data } = await apolloClient.mutate<
    ArchiveDisclaimerMutation,
    ArchiveDisclaimerMutationVariables
  >({
    mutation: ArchiveDisclaimerDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
      payload: request,
    },
  });

  if (!data) {
    throw new Error('Failed to archive disclaimer');
  }

  return data.archiveDisclaimer.body;
};

// Change Log
export const getChangeLog = async (
  brand: string,
  region: string,
  version: string
): Promise<DisclaimersChangeLogResponse> => {
  const { data } = await apolloClient.query<
    SearchDisclaimerChangeLogQuery,
    SearchDisclaimerChangeLogQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: SearchDisclaimerChangeLogDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
      filter:
        version.toUpperCase() === 'DRAFT'
          ? { status: version.toUpperCase() as Status }
          : { version: parseInt(version) },
    },
  });

  return data.searchDisclaimerChangeLog.body;
};

export const revertChange = async (
  brand: string,
  region: string,
  request: DisclaimerChangeLogRequest
): Promise<DisclaimersResponse> => {
  const { data } = await apolloClient.mutate<
    RevertDisclaimerChangeMutation,
    RevertDisclaimerChangeMutationVariables
  >({
    mutation: RevertDisclaimerChangeDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
      payload: request,
    },
  });

  if (!data) {
    throw new Error('Failed to revert disclaimer change');
  }

  return data.revertDisclaimerChange.body;
};

// Versions
export const getDisclaimerVersions = async (brand: string, region: string): Promise<string[]> => {
  const { data } = await apolloClient.query<
    GetDisclaimerVersionsQuery,
    GetDisclaimerVersionsQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: GetDisclaimerVersionsDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
    },
  });

  return data.getDisclaimerVersions.body;
};

export const getVersionInfo = async (brand: string, region: string): Promise<VersionInfo> => {
  const { data } = await apolloClient.query<
    GetDisclaimerCurrentVersionQuery,
    GetDisclaimerCurrentVersionQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: GetDisclaimerCurrentVersionDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
    },
  });

  return data.getDisclaimerCurrentVersion.body;
};

// Draft
export const resetDraft = async (
  brand: string,
  region: string
): Promise<Maybe<DisclaimerVersionInfoResponse>> => {
  const { data } = await apolloClient.mutate<
    ResetDraftDisclaimersMutation,
    ResetDraftDisclaimersMutationVariables
  >({
    mutation: ResetDraftDisclaimersDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
    },
  });

  if (!data) {
    throw new Error('Failed to reset draft');
  }

  return data.resetDraftDisclaimers.body;
};

export const publishDraft = async (
  brand: string,
  region: string
): Promise<Maybe<DisclaimerVersionInfoResponse>> => {
  const { data } = await apolloClient.mutate<
    PublishDraftDisclaimerMutation,
    PublishDraftDisclaimerMutationVariables
  >({
    mutation: PublishDraftDisclaimerDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
    },
  });

  if (!data) {
    throw new Error('Failed to publish draft');
  }

  return data.publishDraftDisclaimer.body;
};

// Tokens
export const getDisclaimerTokens = async (brand: string, region: string): Promise<string[]> => {
  const { data } = await apolloClient.query<
    SearchDisclaimerTokensQuery,
    SearchDisclaimerTokensQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: SearchDisclaimerTokensDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
    },
  });

  return data.searchDisclaimerTokens.body;
};

// Spanish
export const uploadDisclaimersDocument = async (
  brand: string,
  region: string,
  lang: string,
  resultJSON: any
): Promise<Maybe<DisclaimerVersionInfoResponse>> => {
  const { data } = await apolloClient.mutate<
    UploadDisclaimersMutation,
    UploadDisclaimersMutationVariables
  >({
    mutation: UploadDisclaimersDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
      lang: lang as Language,
      version: resultJSON['version'],
      payload: resultJSON,
    },
  });

  if (!data) {
    throw new Error('Failed to upload disclaimers');
  }

  return data.uploadDisclaimers.body;
};

export const getDisclaimersDocumentLink = async (
  brand: string,
  region: string,
  version: number,
  lang: string
): Promise<Record<string, any>> => {
  const { data } = await apolloClient.query<
    DownloadDisclaimersQuery,
    DownloadDisclaimersQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: DownloadDisclaimersDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
      version: version.toString(),
      lang: lang as Language,
    },
  });

  return data.downloadDisclaimers.body;
};

export const publishDisclaimers = async (
  brand: string,
  region: string,
  lang: string
): Promise<string> => {
  const { data } = await apolloClient.mutate<
    PublishDisclaimersToDownstreamMutation,
    PublishDisclaimersToDownstreamMutationVariables
  >({
    mutation: PublishDisclaimersToDownstreamDocument,
    variables: {
      brand: brand as Brand,
      region: region.toUpperCase() as Region,
      lang: lang as Language,
    },
  });

  if (!data) {
    throw new Error('Failed to revert disclaimer change');
  }

  return data.publishDisclaimersToDownstream.body;
};
