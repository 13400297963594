import { toast } from 'react-toastify';
import { ChangeLogModelItem, ChangeLogModelResponse } from '../../../../../models/changeLog.model';
import { Language } from '../../../../../models/user.model';
import { VehicleTeam } from '../../../../../models/vehicleData.model';
import { sortBy } from '../../../../../utils';
import { getChangeLog } from '../../../../../webservices/vehicleModelsApi';
import {
  toGqlBrand,
  toGqlFilter,
  toGqlLanguage,
  toGqlTeam,
} from '../../../../../utils/graphqlUtils';
import { Status } from '../../../../../gql/generated';

export const getVehicleModelChangeLogs = async (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  year: string,
  languages: Language[],
  version?: string
) => {
  const logs: ChangeLogModelItem[] = [];
  const promises: Promise<ChangeLogModelResponse[]>[] = [];
  for (const lang of languages) {
    promises.push(
      getChangeLog({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId,
        modelYear: parseInt(year),
        language: toGqlLanguage(lang),
        filter: toGqlFilter(version ?? Status.Draft),
      })
    );
  }
  const responses = await Promise.all(promises);
  let index = 0;
  for (const lang of languages) {
    const changeLogs = responses[index].map(
      (item: ChangeLogModelResponse) => new ChangeLogModelItem(item, version || '', brand, lang)
    );

    logs.push(...changeLogs);
    index += 1;
  }

  return logs.slice().sort(sortBy('modifiedDate', true, true));
};

export const displaySyncMessage = (onlyStatusSyncUpdates?: boolean) => {
  if (onlyStatusSyncUpdates) {
    toast.warn('Status Updates have occurred, these are auto approved');
  } else {
    toast.success('Sync successful');
  }
};
