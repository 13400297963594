import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input } from 'vapi-ui-common';
import useStores from '../../../../../../../../hooks/useStores';
import { SeriesCategories } from '../../../../../../../../models/vehicleData.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../../models/vehicleModel.model';
import styles from '../../../../modelsModal.module.scss';
import Label from '../Label';

interface CabProps {
  vmId: string;
  category: SeriesCategories;
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  children?: JSX.Element | JSX.Element[];
}

const Cab = observer(({ vmId, category, model, children }: CabProps) => {
  const {
    modelTabStore: { languages: checkboxLanguages, readOnly, getCab, setCab, getLangVehicleModel },
    userStore: { langPermissions },
    teamStore: {
      team: { languages: teamLanguages },
    },
  } = useStores();

  if (category === SeriesCategories.TRUCKS_SUVS) {
    return (
      <div className={styles.column}>
        <div className={styles.label}>
          <Label htmlFor={`amm-cab-${vmId}`} optional>
            Cab
          </Label>
        </div>
        <div className={cx(styles.content)}>
          {teamLanguages.map(language => {
            if (checkboxLanguages[language].selected && langPermissions[language]?.canView) {
              let changedAttributes: string[] = [];
              if (langPermissions[language]?.canEdit) {
                changedAttributes =
                  getLangVehicleModel(model, language).getVal('changedAttributes') ?? [];
              }
              return (
                <Input
                  id={`amm-cab-${vmId}-${language}`}
                  key={`amm-cab-${vmId}-${language}`}
                  value={getCab(vmId, language)}
                  disabled={!langPermissions[language]?.canEdit || readOnly}
                  onChange={e => setCab(vmId, language, e.currentTarget.value)}
                  placeholder={checkboxLanguages[language].description}
                  className={cx(
                    { [styles.errorText]: changedAttributes.includes('cab') },
                    styles.input
                  )}
                />
              );
            }

            return null;
          })}
        </div>
        {children}
      </div>
    );
  }

  return <></>;
});

export default Cab;
