import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  DateComponent,
  HeaderRow,
  IconTextButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiLineRTEditor,
  Notes,
  Popover,
  ScrollableContainer,
  Spinner,
  Table,
  TableCell,
  TableRow,
} from 'vapi-ui-common';
import { ChangeLogChanges } from '../../../../components/ChangeLogTable';
import {
  AppliedChangesResponse,
  ChangeLogTypes,
  SYNCED_CHANGE_TYPES_MAP,
} from '../../../../models/changeLog.model';
import {
  ColorGradeItem,
  ColorGradeLangItem,
  InteriorColorResponse,
} from '../../../../models/colors.model';
import { IDValueType, KeyValueType } from '../../../../models/common.model';
import { FuelTypeItemResponse } from '../../../../models/fuelType.model';
import { RefItem } from '../../../../models/refItem.model';
import { ReviewSyncItem } from '../../../../models/review.model';
import { Language } from '../../../../models/user.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../models/vehicleModel.model';
import {
  changeLogModelApplicabilityItemMapper,
  changeLogVehicleModelMapper,
} from '../../../../utils/changeLogUtils';
import { reviewColorApplicabilityMapper } from '../../../../utils/colorUtils';
import { getFuelTypeNameByIdUtil } from '../../../../utils/seriesSettingsUtils';
import { compareItem } from '../../../../webservices/vehicleAdminApi';
import styles from './SyncTMNAChangesModal.module.scss';

type EntityType =
  | 'series'
  | 'models'
  | 'features'
  | 'specs'
  | 'options'
  | 'exteriorColors'
  | 'interiorColors';

interface ISyncTMNAChangesModal {
  brand: string;
  team: string;
  seriesId: string;
  year: string;
  entityType: EntityType;
  itemId: string;
  isNew: boolean;
  isDelete: boolean;
  close: (
    response?: AppliedChangesResponse,
    shouldDelete?: boolean,
    unlinkFromTMNA?: boolean
  ) => void;
  parentSeriesId?: string;
  fuelTypes?: IDValueType[];
  vehicleModels?: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  grades?: RefItem[];
  colorGradeLangItems?: ColorGradeLangItem[];
  fuelTypeList?: FuelTypeItemResponse[];
}

interface AppliedMap {
  applied: {
    [k in ChangeLogTypes]?: {
      [lang: string]: boolean;
    };
  };
}

interface ChangesMap {
  changes: {
    [k in ChangeLogTypes]?: {
      [lang: string]: ReviewSyncItem;
    };
  };
}

const entityTypeToNameMap: KeyValueType<string> = {
  series: 'Series Setting',
  models: 'Model',
  features: 'Feature',
  specs: 'Specs',
  exteriorColors: 'Exterior Color',
  interiorColors: 'Interior Color',
};

const fillFuelTypeMap = (value: any, fuelTypeList: FuelTypeItemResponse[]) => {
  const fuelTypeMap: KeyValueType<boolean> = {};
  if (value) {
    for (const fuelType of Object.keys(value)) {
      if (value[fuelType]) {
        fuelTypeMap[getFuelTypeNameByIdUtil(fuelTypeList, fuelType)] = true;
      }
    }
  }
  return fuelTypeMap;
};

const seriesFuelTypesMapper = (
  item: ReviewSyncItem,
  changeLogType: ChangeLogTypes,
  fuelTypeList: FuelTypeItemResponse[]
) => {
  if (item.changes.changeType === changeLogType) {
    const beforeMap = fillFuelTypeMap(item.changes.before, fuelTypeList);
    const afterMap = fillFuelTypeMap(item.changes.after, fuelTypeList);
    const beforeArr: string[] = [];
    const beforeFuelTypes = Object.keys(beforeMap);
    for (const fuelType of beforeFuelTypes) {
      if (!afterMap[fuelType]) {
        beforeArr.push(fuelType);
      } else {
        delete beforeMap[fuelType];
        delete afterMap[fuelType];
      }
    }
    item.changes.extColorAppBefore = beforeArr;
    item.changes.extColorAppAfter = Object.keys(afterMap);
  }
};

const SyncTMNAChangesModal = ({
  brand,
  team,
  seriesId,
  year,
  entityType,
  itemId,
  close,
  isNew,
  isDelete,
  parentSeriesId = '',
  fuelTypes = [],
  vehicleModels = [],
  grades = [],
  colorGradeLangItems = [],
  fuelTypeList = [],
}: ISyncTMNAChangesModal) => {
  const changesMap = useRef<ChangesMap>({ changes: {} });
  const querying = useRef(false);
  const [syncItems, setSyncItems] = useState<ReviewSyncItem[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [numberChecked, setNumberChecked] = useState(0);
  const [appliedMap, setAppliedMap] = useState<AppliedMap>({ applied: {} });

  useEffect(() => {
    if ((isNew || isDelete) && !isLoaded) {
      setIsLoaded(true);
    }
    if (querying.current || isNew || isDelete) {
      return;
    }
    querying.current = true;
    (async () => {
      try {
        const { data } = await compareItem(
          brand,
          team,
          seriesId,
          year,
          entityType,
          itemId,
          parentSeriesId
        );
        const items: ReviewSyncItem[] = [];
        const appMap: AppliedMap = { applied: {} };
        const cMap: ChangesMap = { changes: {} };

        Object.entries(data.langs).forEach(([lang, response]) => {
          let colorGradeItems: ColorGradeItem[] = [];
          if (colorGradeLangItems && colorGradeLangItems.length) {
            colorGradeItems = colorGradeLangItems.map(gradeItem => {
              const item = new ColorGradeItem(gradeItem.grade);
              gradeItem.interiorItems.forEach(interiorItem => {
                const res = JSON.parse(
                  JSON.stringify(interiorItem.langs[lang])
                ) as InteriorColorResponse;
                let newName: any = res.name;
                try {
                  newName = JSON.parse(newName);
                  if (newName.text) {
                    res.name = newName.text;
                  }
                } catch {}
                item.interiorItems.push(res);
              });
              return item;
            });
          }
          if (response) {
            Object.entries(response.changes).forEach(([changeTypeId, change]) => {
              const item = new ReviewSyncItem(lang as Language, response, change, changeTypeId);
              if (vehicleModels && vehicleModels.length) {
                changeLogModelApplicabilityItemMapper(vehicleModels, item.changes);
              }
              if (fuelTypes && fuelTypes.length) {
                changeLogVehicleModelMapper(fuelTypes, [item.changes], ChangeLogTypes.FUEL_TYPE);
              }
              if (
                entityType === 'exteriorColors' &&
                grades &&
                grades.length &&
                colorGradeItems &&
                colorGradeItems.length
              ) {
                reviewColorApplicabilityMapper(
                  item,
                  colorGradeItems,
                  ChangeLogTypes.EXT_COLOR_APPLICABILITY,
                  grades
                );
              }
              if (entityType === 'series') {
                seriesFuelTypesMapper(item, ChangeLogTypes.FUEL_TYPES, fuelTypeList);
              }
              if (!appMap.applied[item.changes.changeType]) {
                appMap.applied[item.changes.changeType] = {};
              }
              appMap.applied[item.changes.changeType]![lang] = false;
              if (!cMap.changes[change.changeType]) {
                cMap.changes[change.changeType] = {};
              }
              cMap.changes[change.changeType]![lang] = item;
            });
          }
        });
        // group them by change type
        Object.values(cMap.changes).forEach(langMap => {
          if (langMap) {
            Object.values(langMap).forEach(item => {
              items.push(item);
            });
          }
        });
        changesMap.current = cMap;
        setSyncItems(items);
        setAppliedMap(appMap);
        setIsLoaded(true);
      } catch (e) {
        console.log(e);
        toast.error('Error loading data');
        close();
      }
    })();
  }, [
    brand,
    team,
    seriesId,
    year,
    entityType,
    itemId,
    close,
    fuelTypes,
    vehicleModels,
    grades,
    colorGradeLangItems,
    isLoaded,
    isNew,
    isDelete,
    parentSeriesId,
    fuelTypeList,
  ]);

  useEffect(() => {
    let numChecked = 0;
    Object.values(appliedMap.applied).forEach(langMap => {
      if (langMap) {
        numChecked += Object.values(langMap).filter(value => value).length;
      }
    });
    setNumberChecked(numChecked);
  }, [appliedMap]);

  const keepAndUnlinkItem = () => {
    close(undefined, false, true);
  };

  const deleteItem = () => {
    close(undefined, true, false);
  };

  const ignoreChanges = () => {
    close({ applied: {} });
  };

  const applyChanges = () => {
    const appliedResponse: AppliedChangesResponse = { applied: {} };
    const appliedTypes = Object.keys(appliedMap.applied) as ChangeLogTypes[];
    appliedTypes.forEach(changeType => {
      const langMap = appliedMap.applied[changeType];
      if (langMap) {
        Object.keys(langMap).forEach(lang => {
          if (langMap[lang]) {
            // change accepted now update the original item and save
            if (!appliedResponse.applied[changeType]) {
              appliedResponse.applied[changeType] = {};
            }
            const change = changesMap.current.changes[changeType]![lang].changes;
            appliedResponse.applied[changeType]![lang] = change.after ?? '';
          }
        });
      }
    });
    close(appliedResponse);
  };

  const getModalBody = () => {
    if (!isLoaded) {
      return <Spinner short />;
    }
    if (isNew || isDelete) {
      return (
        <>
          <ModalBody>
            <div>{`Would you like to ${isNew ? 'add' : 'delete'} this ${
              entityTypeToNameMap[entityType] ?? entityType
            }?`}</div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="transparent"
              onClick={() => {
                if (isNew) {
                  deleteItem();
                } else {
                  keepAndUnlinkItem();
                }
              }}
            >
              {isNew ? 'Delete' : 'Keep'}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (isNew) {
                  ignoreChanges();
                } else {
                  deleteItem();
                }
              }}
            >
              {isNew ? 'Add' : 'Delete'}
            </Button>
          </ModalFooter>
        </>
      );
    }

    return (
      <>
        <ModalBody>
          <ScrollableContainer style={{ overflow: 'scroll' }}>
            <Table fullWidth className={styles.aapTable}>
              <HeaderRow className={styles.stickyNav}>
                <TableCell>Apply?</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Change Type</TableCell>
                <TableCell>Changes</TableCell>
                <TableCell>Published Date</TableCell>
                <TableCell>Notes</TableCell>
              </HeaderRow>
              <tbody className={styles.appBody}>
                {syncItems.map(reviewItem => {
                  const change = reviewItem.changes;
                  const changeType = change.changeType;
                  return (
                    <TableRow
                      key={reviewItem.uid}
                      onFillRowHeightChange={height => {
                        reviewItem.rowHeight = height;
                      }}
                    >
                      <TableCell center className={styles.checkBox}>
                        <Checkbox
                          id={`chbox-${reviewItem.uid}`}
                          checked={appliedMap.applied[changeType]![reviewItem.language]}
                          onChange={e => {
                            if (SYNCED_CHANGE_TYPES_MAP[changeType]) {
                              const entry = appliedMap.applied[changeType]!;
                              const isApplied = !entry[reviewItem.language];
                              Object.keys(entry).forEach(lang => {
                                entry[lang] = isApplied;
                              });

                              setAppliedMap({
                                ...appliedMap,
                                applied: {
                                  ...appliedMap.applied,
                                  [changeType]: entry,
                                },
                              });
                            } else {
                              setAppliedMap({
                                ...appliedMap,
                                applied: {
                                  ...appliedMap.applied,
                                  [changeType]: {
                                    ...appliedMap.applied[changeType],
                                    [reviewItem.language]: !appliedMap.applied[changeType]![
                                      reviewItem.language
                                    ],
                                  },
                                },
                              });
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell large>
                        <MultiLineRTEditor value={reviewItem.description} disabled />
                      </TableCell>
                      <TableCell large>{reviewItem.language}</TableCell>
                      <TableCell large>{change.changeType}</TableCell>
                      <TableCell large>
                        <ChangeLogChanges changeItem={change} />
                      </TableCell>
                      <TableCell large>
                        <DateComponent format="MM/DD/YYYY hh:mm:ss A">
                          {reviewItem.publishedDate}
                        </DateComponent>
                      </TableCell>
                      <TableCell large>
                        {reviewItem.notes && (
                          <Popover
                            toggleElement={<IconTextButton smallIcon icon="circle" text="Notes" />}
                            popoverElement={<Notes readOnly notes={reviewItem.notes} />}
                            align="right"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
          </ScrollableContainer>
        </ModalBody>
        <ModalFooter>
          <Button variant="transparent" onClick={() => ignoreChanges()}>
            Ignore Changes
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              applyChanges();
            }}
            disabled={numberChecked < 1}
          >
            {`Apply (${numberChecked})`}
          </Button>
        </ModalFooter>
      </>
    );
  };

  return (
    <>
      <ModalHeader onClose={() => close()}>Sync Changes from TMNA</ModalHeader>
      {getModalBody()}
    </>
  );
};

export default SyncTMNAChangesModal;
