import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';
import {
  GO_LIVE_DATE_TIME,
  GoLiveDateTime,
  SERVER_GO_LIVE_DATE_FORMAT,
  formatDate,
  goLiveDateTimeMap,
} from '../../../../../utils/dateUtils';
import GoLiveDateTimePicker from './GoLiveDateTimePicker';

interface IEditGoLiveDateModal {
  close: () => void;
  onSubmit: (goLiveDate: string) => void;
  currentGoLiveDate: Date | null;
  headerText: string;
  customButtonText?: string;
  timePicker?: boolean;
}

const EditGoLiveDateModal = observer(
  ({
    close,
    onSubmit,
    currentGoLiveDate,
    headerText,
    customButtonText = 'Confirm',
    timePicker,
  }: IEditGoLiveDateModal) => {
    const [goLiveDate, setGoLiveDate] = useState<Date>(currentGoLiveDate ?? new Date());
    const [currentTime, setTime] = useState<GO_LIVE_DATE_TIME | undefined>(
      currentGoLiveDate?.getHours()
    );

    const onChangeTime = useCallback((time: GoLiveDateTime) => setTime(time.hours), [setTime]);

    const onConfirm = useCallback(() => {
      close();

      let date = goLiveDate.toLocaleDateString();

      if (timePicker) {
        const time = goLiveDateTimeMap[currentTime ?? GO_LIVE_DATE_TIME.AM_5];
        goLiveDate.setHours(time.hours, time.minutes);
        date = formatDate(goLiveDate, SERVER_GO_LIVE_DATE_FORMAT);
      }

      onSubmit(date);
    }, [close, goLiveDate, currentTime, onSubmit, timePicker]);

    return (
      <>
        <ModalHeader onClose={close}>{headerText}</ModalHeader>
        <ModalBody>
          <Calendar value={goLiveDate} onChange={setGoLiveDate} minDate={new Date()} />
          <GoLiveDateTimePicker
            currentTime={currentTime}
            onChange={onChangeTime}
            timePicker={timePicker}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="transparent"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            {customButtonText}
          </Button>
        </ModalFooter>
      </>
    );
  }
);

export default EditGoLiveDateModal;
