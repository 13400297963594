import { observer } from 'mobx-react-lite';
import React from 'react';
import { HeaderRow, LeftTableTH, Thead } from '../../../../components/Table';
import styles from './CFColorsTableHeaderRow.module.scss';

type CFColorsTableHeaderRowProps = {
  hideMaterial?: boolean;
  onSort?: (field: string, isReverse: boolean) => void;
};

const CFColorsTableHeaderRow: React.FC<CFColorsTableHeaderRowProps> = ({
  hideMaterial = false,
  onSort = () => {},
}) => {
  let isReverse = false;

  return (
    <Thead>
      <HeaderRow>
        <LeftTableTH
          className={styles.code}
          onClick={() => {
            onSort('code', isReverse);
            isReverse = !isReverse;
          }}
        >
          Color Code
        </LeftTableTH>
        <LeftTableTH
          className={styles.name}
          onClick={() => {
            onSort('name', isReverse);
            isReverse = !isReverse;
          }}
        >
          Name
        </LeftTableTH>
        {process.env.REACT_APP_COLOR_FAMILY_V2 === 'true' && hideMaterial && (
          <LeftTableTH
            className={styles.code}
            onClick={() => {
              onSort('hexCode', isReverse);
              isReverse = !isReverse;
            }}
          >
            Hex Code
          </LeftTableTH>
        )}
        <LeftTableTH
          className={styles.colorFamily}
          onClick={() => {
            onSort('colorFamilyNames', isReverse);
            isReverse = !isReverse;
          }}
        >
          Color Family
        </LeftTableTH>
        {!hideMaterial && (
          <LeftTableTH
            className={styles.columnHeaderWidth}
            onClick={() => {
              onSort('material.value', isReverse);
              isReverse = !isReverse;
            }}
          >
            Material
          </LeftTableTH>
        )}
      </HeaderRow>
    </Thead>
  );
};

export default observer(CFColorsTableHeaderRow);
