import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Modal, PublishModal } from 'vapi-ui-common';
import Header from '../Header';

export interface IDraftHeader {
  title: 'Common Language' | 'Disclaimers';
  modalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  handlePublishData: () => Promise<void>;
}

const DraftHeader = ({
  title,
  modalOpen,
  openModal,
  closeModal,
  handlePublishData,
}: IDraftHeader) => {
  const url =
    title === 'Common Language'
      ? '/commonLanguage/changeLog?return=draft'
      : '/disclaimers/changeLog?return=draft';
  return (
    <Header moduleTitle={title} moduleSubTitle="Draft">
      <NavLink to={url}>
        <Button variant="transparent" data-testid="draft-header-comp">
          Change Log
        </Button>
      </NavLink>
      <Button variant="primary" onClick={openModal}>
        Publish
      </Button>
      <Modal open={modalOpen} onClose={closeModal}>
        <PublishModal close={closeModal} publishData={handlePublishData} />
      </Modal>
    </Header>
  );
};

export default DraftHeader;
