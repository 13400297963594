import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import DraftHeader from '../../../components/DraftHeader';
import Spinner from '../../../components/Spinner';
import { CheckMessageToast } from '../../../components/Toast';
import { VDStatus } from '../../../constants/vehicleData/VDConstants';
import useStores from '../../../hooks/useStores';
import { DisclaimersItem } from '../../../models/disclaimers.model';
import { handleErrorResponse } from '../../../utils/errorHandlingUtils';
import {
  addDisclaimer,
  archiveDisclaimer,
  deleteDisclaimer,
  getChangeLog,
  getDisclaimers,
  publishDraft,
  resetDraft,
  updateDisclaimer,
} from '../../../webservices/disclaimersApi';
import { getAvailableModels } from '../../../webservices/vehicleAdminApi';
import ActionBarDisclaimers from '../components/ActionBarDisclaimers';
import DisclaimersTable from '../components/DisclaimersTable';
import DisclaimerRow from '../components/DisclaimersTable/components/DisclaimerRow';

const DisclaimersDraft = observer(() => {
  const {
    userStore: {
      brand,
      region,
      modules: {
        Disclaimers: { canEdit },
      },
    },
    disclaimersStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);

  const config = { readOnly: !canEdit };

  useEffect(() => {
    disclaimersStore.reset();

    (async () => {
      try {
        const responses = await Promise.all([
          getDisclaimers(brand, region, VDStatus.DRAFT),
          getAvailableModels(brand),
        ]);

        disclaimersStore.setData(responses[0].map(item => new DisclaimersItem(item)));
        disclaimersStore.vehicleModels = responses[1].data;
      } catch (e) {
        toast.error('Error loading disclaimers data');
      }

      setIsLoaded(true);
    })();

    return () => {
      disclaimersStore.setData([]);
    };
  }, [brand, disclaimersStore, region]);

  const handleDeleteItem = async (disclaimer: DisclaimersItem) => {
    try {
      if (disclaimer.id) {
        await trackPromise(
          deleteDisclaimer(brand, region, { id: disclaimer.id, revId: disclaimer.revId })
        );
      }
      disclaimersStore.deleteItem(disclaimer);
    } catch (e) {
      handleErrorResponse(e, 'Error deleting disclaimer item');
    }
  };

  const handleUpdateItem = async (disclaimer: DisclaimersItem) => {
    try {
      if (disclaimer.isValid) {
        const response = await trackPromise(
          updateDisclaimer(brand, region, disclaimer.getPayload())
        );
        disclaimer.revId = response.revId;
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating disclaimer item');
    }
  };

  const handleAddItem = async (disclaimer: DisclaimersItem) => {
    try {
      if (disclaimer.isValid) {
        const response = await trackPromise(addDisclaimer(brand, region, disclaimer.getPayload()));
        disclaimer.id = response.id;
        disclaimer.revId = response.revId;
        toast.success('Disclaimer successfully added');
        return true;
      }
    } catch (e) {
      handleErrorResponse(e, 'Error adding disclaimer item');
    }
    return false;
  };

  const handleArchiveItem = async (disclaimer: DisclaimersItem) => {
    try {
      await trackPromise(
        archiveDisclaimer(brand, region, { id: disclaimer.id, revId: disclaimer.revId })
      );
      disclaimersStore.setData(
        disclaimersStore.data.filter(
          item => item.id !== disclaimer.id && item.revId !== disclaimer.revId
        )
      );
    } catch (e) {
      handleErrorResponse(e, 'Error archiving disclaimer item');
    }
  };

  const handleResetDraft = async () => {
    try {
      await trackPromise(resetDraft(brand, region));

      setIsLoaded(false);
      const dataResponse = await getDisclaimers(brand, region, 'draft');
      disclaimersStore.setData(dataResponse.map(item => new DisclaimersItem(item)));
    } catch (e) {
      handleErrorResponse(e, 'Error resetting draft');
    }

    setIsLoaded(true);
  };

  const getUpdateCount = async () => {
    let count = 0;
    try {
      const response = await trackPromise(getChangeLog(brand, region, 'draft'));
      count = response.currentChangeCount as number;
    } catch {
      toast.error('Error getting update count');
    }
    return count;
  };

  const handlePublishData = async () => {
    setIsLoaded(false);
    try {
      await trackPromise(publishDraft(brand, region));
      toast.success(<CheckMessageToast message="Your draft has been successfully published." />);
      const dataResponse = await getDisclaimers(brand, region, 'published');
      const updatedData = disclaimersStore.data.map(item => {
        const f = dataResponse.find(res => res.id === item.id);
        if (f) {
          if (f.lastPublishedDate) {
            item.lastPublishedDate = f.lastPublishedDate;
          }
          if (f.lastPublishedVersion) {
            item.lastPublishedVersion = f.lastPublishedVersion;
          }
        }
        return item;
      });
      disclaimersStore.setData(updatedData);
    } catch (e) {
      handleErrorResponse(e, 'Error publishing disclaimer');
    }
    setIsLoaded(true);
  };

  const handleAddDisclaimer = async () => {
    // If new item is already being made, show error
    disclaimersStore.addItem();

    if (!toast.error) {
      toast.error('Please finish filling out all items of the new disclaimer.');
    }
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <DraftHeader
        title="Disclaimers"
        modalOpen={openPublishModal}
        openModal={() => setOpenPublishModal(true)}
        closeModal={() => setOpenPublishModal(false)}
        handlePublishData={handlePublishData}
      />
      <ActionBarDisclaimers
        readOnly={!canEdit}
        searchText={disclaimersStore.searchText}
        setSearchText={value => {
          disclaimersStore.searchText = value;
          disclaimersStore.filterData();
        }}
        vehicleModels={disclaimersStore.vehicleModels}
        vehicleFilters={disclaimersStore.vehicleFilters}
        setFilters={disclaimersStore.setFilters}
        onResetDraft={handleResetDraft}
        getUpdateCount={getUpdateCount}
        onAddDisclaimerItem={handleAddDisclaimer}
        region={region}
      />
      <DisclaimersTable
        region={region}
        onSort={disclaimersStore.setSort}
        renderRows={() =>
          disclaimersStore.filteredData.map(item => (
            <DisclaimerRow
              key={item.uid}
              item={item}
              onDeleteItem={handleDeleteItem}
              onUpdateItem={handleUpdateItem}
              onAddItem={handleAddItem}
              onArchiveItem={handleArchiveItem}
              vehicleModels={disclaimersStore.vehicleModels}
              config={config}
              region={region}
            />
          ))
        }
      />
    </>
  );
});

export default DisclaimersDraft;
