import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../../../components/Spinner';
import { uiBlock } from '../../../../components/UiBlocker/uiBlock';
import useStores from '../../../../hooks/useStores';
import { OptionsChangeTypeMap, OptionsReviewResponse } from '../../../../models/options.model';
import ActionBarVehicleData from '../../../../routes/vehicleData/components/ActionBarVehicleData';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { categoriesXForm } from '../../../../utils/categoryUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { optionsReviewItemXForm } from '../../../../utils/optionsUtils';
import { filterDuplicates } from '../../../../utils/reviewUtils';
import {
  getReviewCategories,
  getReviewOptions,
  updateReviewOption,
} from '../../../../webservices/vehicleOptionsApi';
import OptionsFilters from './components/Filters';
import OptionsReviewTable from './components/OptionsReviewTable';
import { toGqlBrand, toGqlTeam, toGqlFilter } from '../../../../utils/graphqlUtils';

const OptionsReview = ({
  seriesId,
  year,
  version = '',
  vehicleModels,
  changeLogLink = '',
}: ProductDataControllerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    optionsReviewStore,
    teamStore,
    userStore: { brand },
  } = useStores();

  useEffect(() => {
    optionsReviewStore.reset();

    (async () => {
      try {
        const [categoriesResponse, optionsResponse] = await Promise.all([
          getReviewCategories({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId: seriesId,
            modelYear: parseInt(year),
            filter: toGqlFilter(version),
          }),
          getReviewOptions<OptionsReviewResponse>({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId: seriesId,
            modelYear: parseInt(year),
            filter: toGqlFilter(version),
          }),
        ]);

        optionsReviewStore.categories = categoriesXForm(categoriesResponse);
        const { map } = optionsReviewItemXForm(
          optionsResponse,
          vehicleModels,
          optionsReviewStore.categories
        );
        optionsReviewStore.map = map;
      } catch {
        toast.error('Error loading Options review data');
      }
      setIsLoaded(true);
    })();
  }, [optionsReviewStore, vehicleModels, brand, seriesId, teamStore, version, year]);

  const handleOnMapApplyChange = async (map: OptionsChangeTypeMap, value: boolean) => {
    map.isApplied = value;
    saveMap(map);
  };

  const handleOnMapNotesChange = async (map: OptionsChangeTypeMap, value: string) => {
    map.rejectNotes = value;
    saveMap(map);
  };

  const saveMap = async (map: OptionsChangeTypeMap) => {
    try {
      if (optionsReviewStore.isMapValid(map, teamStore.team.param)) {
        uiBlock.start();
        const payload = optionsReviewStore.getMapPayload(map, teamStore.team.param);
        const response = await updateReviewOption({
          brand: toGqlBrand(brand),
          team: toGqlTeam(teamStore.team.param),
          seriesId: seriesId,
          modelYear: parseInt(year),
          filter: toGqlFilter(version),
          payload: {
            id: payload.id,
            revId: payload.revId,
            changeTypeId: payload.changeTypeId,
            isAccepted: payload.isAccepted,
            isApplied: payload.isApplied,
            rejectNotes: payload.rejectNotes,
          },
        });
        map.revId = response.revId;
        toast.success('Successfully updated option');
      } else {
        toast.error('Please fill in the required fields for the option');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating option');
    }

    uiBlock.stop();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBarVehicleData
        readOnly
        toggleViewModelCodes={() =>
          (optionsReviewStore.viewModelCodes = !optionsReviewStore.viewModelCodes)
        }
        viewModelCodes={optionsReviewStore.viewModelCodes}
        searchText={optionsReviewStore.searchText}
        onSearchTextChange={text => (optionsReviewStore.searchText = text)}
        renderFilter={onClose => (
          <OptionsFilters
            onClose={onClose}
            categories={filterDuplicates(optionsReviewStore.categories).map(item => item.value)}
            categoryFilters={optionsReviewStore.categoryFilters}
            setCategoryFilters={categoryFilters =>
              (optionsReviewStore.categoryFilters = categoryFilters)
            }
            isInProgressFilter={optionsReviewStore.isInProgressFilter}
            setIsInProgressFilter={value => (optionsReviewStore.isInProgressFilter = value)}
            isReviewPage={true}
          />
        )}
      />

      <OptionsReviewTable
        vehicleModels={vehicleModels}
        handleOnApplyChange={handleOnMapApplyChange}
        handleOnNotesChange={handleOnMapNotesChange}
      />
    </>
  );
};

export default observer(OptionsReview);
