import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../../../../components/Spinner';
import useStores from '../../../../../hooks/useStores';
import { COLORS_TAB } from '../../../../../models/colors.model';
import { ProductDataControllerProps } from '../../../../../routes/vehicleData/models/controllers.model';
import ColorsLexusExteriorReview from './ColorsLexusExteriorReview';
import ColorsLexusInteriorReview from './ColorsLexusInteriorReview';

const ColorsLexusReview = ({
  team,
  seriesId,
  year,
  version,
  versionInfo,
  vehicleModels,
  changeLogLink = '',
}: ProductDataControllerProps) => {
  const {
    teamStore,
    colorsLexusReviewStore,
    userStore: { brand },
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);
  const [colorTab, setColorTab] = useState(COLORS_TAB.EXTERIOR);

  useEffect(() => {
    colorsLexusReviewStore.reset();
    (async () => {
      try {
        await colorsLexusReviewStore.fetchData(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version
        );
      } catch (e) {
        console.log(e);
        toast.error('Error loading colors review data');
      }
      setIsLoaded(true);
    })();
  }, [colorsLexusReviewStore, brand, vehicleModels, seriesId, teamStore, version, year]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      {colorTab === COLORS_TAB.EXTERIOR && (
        <ColorsLexusExteriorReview
          team={team}
          seriesId={seriesId}
          year={year}
          version={version}
          versionInfo={versionInfo}
          vehicleModels={vehicleModels}
          changeLogLink={changeLogLink}
          colorTab={colorTab}
          setColorTab={setColorTab}
        />
      )}
      {colorTab === COLORS_TAB.INTERIOR && (
        <ColorsLexusInteriorReview
          team={team}
          seriesId={seriesId}
          year={year}
          version={version}
          versionInfo={versionInfo}
          vehicleModels={vehicleModels}
          changeLogLink={changeLogLink}
          colorTab={colorTab}
          setColorTab={setColorTab}
        />
      )}
    </>
  );
};

export default observer(ColorsLexusReview);
