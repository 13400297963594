import cx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { SideMenuIcon } from 'vapi-ui-common';
import useStores from '../../hooks/useStores';
import { BRAND_TOYOTA, Language, REGION_GST } from '../../models/user.model';
import { hideRouteByBrand, testVDActive } from './SideMenu';
import styles from './sideMenu.module.scss';

const CollapsedView = () => {
  const {
    userStore: { modules, brand, langPermissions, region },
  } = useStores();

  return (
    <section data-testid="collapsed-view-comp">
      <h1 className={styles.titleCollapsed}>V</h1>
      <section className={cx(styles.listContainer, styles.collapsed)}>
        <ul>
          {region === REGION_GST && (
            <>
              <a
                href={`${process.env.REACT_APP_ACCESSORIES_URL}/commonLanguage/published?collapsed=true`}
              >
                <SideMenuIcon icon="commonLang" />
              </a>
              <a href={`${process.env.REACT_APP_ACCESSORIES_URL}/gst/dashboard?collapsed=true`}>
                <SideMenuIcon icon="productTeam" />
              </a>
            </>
          )}

          {modules.CommonLanguage.canView && (
            <NavLink
              to={'/commonLanguage/published'}
              isActive={(match, location) => location.pathname.includes('commonLanguage')}
            >
              <SideMenuIcon icon="commonLang" />
            </NavLink>
          )}

          {modules.ProductTeam.canView && (
            <NavLink
              to={'/vehicleData/product-team'}
              isActive={(match, location) => testVDActive(location.pathname, 'product-team')}
            >
              <SideMenuIcon icon="productTeam" />
            </NavLink>
          )}

          {modules.AgencyTeam.canView && (
            <NavLink
              to={'/vehicleData/agency-team'}
              isActive={(match, location) => testVDActive(location.pathname, 'agency-team')}
            >
              <SideMenuIcon icon="agency" />
            </NavLink>
          )}

          {brand === BRAND_TOYOTA && langPermissions[Language.ES]?.canView && (
            <NavLink
              to={'/vehicleData/agency-spanish'}
              isActive={(match, location) => testVDActive(location.pathname, 'agency-spanish')}
            >
              <SideMenuIcon icon="spanish" />
            </NavLink>
          )}

          {brand === BRAND_TOYOTA && langPermissions[Language.ES]?.canView && (
            <NavLink to={'/spanishDisclaimers'}>
              <SideMenuIcon icon="disclaimer" />
            </NavLink>
          )}

          {modules.Disclaimers.canView && langPermissions[Language.EN]?.canEdit && (
            <NavLink to={'/disclaimers/published'}>
              <SideMenuIcon icon="disclaimer" />
            </NavLink>
          )}

          {modules.AgencyTeam.canView && (
            <NavLink to={'/seriesCategories'}>
              <SideMenuIcon icon="seriesCat" />
            </NavLink>
          )}

          {modules.AgencyTeam.canView && (
            <NavLink to={'/colorFamilies'}>
              <SideMenuIcon icon="colorFam" />
            </NavLink>
          )}

          {modules.Dph.canView && (
            <NavLink to={'/dph'}>
              <SideMenuIcon icon="dph" />
            </NavLink>
          )}

          {region !== REGION_GST && !hideRouteByBrand('/reports', brand) && (
            <NavLink to={'/reports?collapsed=true'}>
              <SideMenuIcon icon="reports" />
            </NavLink>
          )}

          {region === REGION_GST && !hideRouteByBrand('/reports', brand) && (
            <a href={`${process.env.REACT_APP_ACCESSORIES_URL}/reports?collapsed=true`}>
              <SideMenuIcon icon="reports" />
            </a>
          )}
        </ul>
      </section>
    </section>
  );
};

export default CollapsedView;
