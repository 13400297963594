import { observer } from 'mobx-react-lite';
import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import useStores from '../../../hooks/useStores';

const RightTableSizer = (props: HTMLAttributes<HTMLDivElement>) => {
  const {
    resizeDetectorStore: { mainContentWidth },
  } = useStores();

  const [style, setStyle] = useState({});
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      const width = `${mainContentWidth * 0.4}px`;
      setStyle({
        width,
        maxWidth: width,
      });
    }
  }, [mainContentWidth]);

  return <div ref={ref} style={style} {...props} />;
};

export default observer(RightTableSizer);
