import IconTextButton from '../../components/IconTextButton';
import React, { ReactNode, Ref } from 'react';
import { DropdownContent } from 'vapi-ui-common';
import styles from './dropdownEdit.module.scss';
import DropdownEditAdd from './DropdownEditAdd';

export interface DropdownEditProps {
  open: boolean;
  addBtnText: string;
  onAdd?: (value: string) => void;
  renderList: () => ReactNode;
}

const DropdownEdit = React.forwardRef(
  ({ open, addBtnText, onAdd, renderList }: DropdownEditProps, ref: Ref<any>) => {
    const [addItem, setAddItem] = React.useState(false);
    const [value, setValue] = React.useState('');
    const addValue = (val: string) => {
      setValue(val);
      if (value !== val && onAdd) {
        onAdd(val);
      }
    };

    return (
      <>
        <DropdownContent open={open} ref={ref}>
          <>
            <div className={styles.scrollContainer} data-testid="dropdown-edit-comp">
              <ul className={styles.list}>{renderList()}</ul>
            </div>
            {onAdd && (
              <footer className={styles.dropdownEditFooter}>
                {!addItem && (
                  <IconTextButton
                    smallIcon
                    icon="plus"
                    text={addBtnText}
                    onClick={() => setAddItem(true)}
                  />
                )}
                {addItem && <DropdownEditAdd onClick={value => addValue(value)} />}
              </footer>
            )}
          </>
        </DropdownContent>
      </>
    );
  }
);

export default DropdownEdit;
