import cx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Button, Modal } from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox';
import Header from '../../../../components/Header';
import useChecklist from '../../../../hooks/useChecklist';
import useRouteParams from '../../../../hooks/useRouteParams';
import useStores from '../../../../hooks/useStores';
import { BRAND_LEXUS, BRAND_TOYOTA } from '../../../../models/user.model';
import {
  Series,
  SeriesInfo,
  SeriesManagerVehicleChecklist,
  SeriesManagerVehicleChecklistItem,
  UserSeries,
  VehicleCategories,
  VehicleTeam,
} from '../../../../models/vehicleData.model';
import { dashboardXForm, getEditableVehicleGroups } from '../../../../utils/vehicleDataUtils';
import { addSeries, updateSeries, updateUserSeries } from '../../../../webservices/adminApi';
import { getDashboardDetails } from '../../../../webservices/vehicleAdminApi';
import AddVehicleModal from '../../components/AddVehicleModal';
import EditSeriesName from '../../components/EditSeriesName/EditSeriesName';
import SeriesManagerDphModal from '../../components/SeriesManagerDphModal/SeriesManagerDphModal';
import image from '../SeriesManager/car_jelly.png';
import styles from './seriesManager.module.scss';
import { getDph, updateDph } from '../../../../webservices/dphApi';
import { DphMap } from '../../../../models/dph.model';
import { toGqlBrand, toGqlRegion } from '../../../../utils/graphqlUtils';

interface SeriesManagerProps {
  onShowSeriesManager: () => void;
}

const carImage = image;

const createChecklist = (series: Series, userSeries: UserSeries[]) => {
  const vehicleModels = Object.keys(series).map(key => ({ ...series[key], id: key }));
  const vehiclesChecklist: SeriesManagerVehicleChecklist[] = [];

  vehicleModels.forEach(model => {
    // fetch/add category;
    let category = vehiclesChecklist.filter(listItem => listItem.name === model.group)[0];
    if (!category) {
      category = {
        name: model.group as string,
        items: [],
      };
      vehiclesChecklist.push(category);
    }
    // fetch/add series;
    if (!category.items.filter(item => item.name === model.group).length) {
      category.items.push({
        id: model.id,
        name: model.name,
        selected: userSeries.filter(item => item.id === model.id).length > 0,
        carImage: carImage,
        revId: model.revId,
        group: model.group,
      });
    }
  });

  return vehiclesChecklist;
};

const SeriesManager = ({ onShowSeriesManager }: SeriesManagerProps) => {
  const {
    userStore: { brand, region, objectId, modules, teamModule, dphBrand },
    vehicleDataStore,
    teamStore,
  } = useStores();

  const { checklist, setChecklist, selectItem, selectAll } = useChecklist();
  const selectedSeries = useMemo(
    () => checklist.flatMap(({ items }) => items?.filter(i => i.selected) || []),
    [checklist]
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [openAddVehicleModal, setAddVehicleModal] = useState(false);
  const [openDphModal, setOpenDphModal] = useState(false);
  const [newSeries, setNewSeries] = useState<SeriesInfo | undefined>(undefined);
  const [dphCategories, setDphCategories] = useState({} as DphMap);
  const canEdit = modules.SeriesSelections.canEdit;
  const canAddSeries = modules.SeriesManager.canEdit;
  const canEditSeries =
    teamStore.team.allowEditSereisName && Object.values(modules.SeriesManager).length > 0;

  const { team } = useRouteParams();

  useEffect(() => {
    setChecklist(createChecklist(vehicleDataStore.series, vehicleDataStore.userSeries));
  }, [setChecklist, vehicleDataStore]);

  useEffect(() => {
    (async () => {
      try {
        if (dphBrand === BRAND_TOYOTA) {
          const response = await getDph({
            brand: toGqlBrand(dphBrand),
            region: toGqlRegion(region),
          });
          setDphCategories(response);
        }
      } catch (e) {
        toast.error('Error loading dph categories');
      }
    })();
  }, [dphBrand, region]);

  const handleOnSave = async () => {
    const selectedSeriesIds: string[] = selectedSeries.map(item => item.id);

    try {
      await trackPromise(
        updateUserSeries({
          brand: toGqlBrand(brand),
          payload: { objectId, seriesSelections: selectedSeriesIds },
        })
      );
      vehicleDataStore.userSeries = selectedSeriesIds.map(item => {
        const userSeriesItem = vehicleDataStore.userSeries.filter(ser => ser.id === item);
        const seriesItem =
          vehicleDataStore.series[
            Object.keys(vehicleDataStore.series).filter(seriesId => seriesId === item)[0]
          ];
        return userSeriesItem.length
          ? { ...userSeriesItem[0] }
          : { id: item, revId: '', name: seriesItem.name };
      });

      const dashboardResponse = await trackPromise(
        getDashboardDetails(brand, teamStore.team.param, team === VehicleTeam.AGENCY_SPANISH)
      );
      vehicleDataStore.dashboardSeries = dashboardXForm(
        dashboardResponse.data,
        vehicleDataStore.series,
        teamModule
      );

      if (selectedSeriesIds.length) {
        onShowSeriesManager();
      } else {
        // Error
      }
    } catch (e) {
      toast.error('Error saving user series');
    }
  };

  const handleOnAddSeries = async (name: string, group: VehicleCategories) => {
    if (vehicleDataStore.findSeriesByName(name)) {
      return toast.error('Please enter a new series name');
    }

    const currentGroup =
      brand === BRAND_LEXUS && group === VehicleCategories.LEXUS_TRUCKS_SUVS
        ? VehicleCategories.TRUCKS_SUVS
        : group;

    try {
      const response = await trackPromise(
        addSeries({ brand: toGqlBrand(brand), payload: { name, group: currentGroup } })
      );
      Object.assign(vehicleDataStore.series, response.series);
      setChecklist(createChecklist(vehicleDataStore.series, vehicleDataStore.userSeries));
      Object.values(response.series).forEach(series => {
        if (series.name === name) {
          setNewSeries(series);
        }
      });
      setOpenDphModal(true);
    } catch (e) {
      toast.error('Error adding series');
    }
  };

  const handleOnUpdateSeries = async (name: string, item: SeriesManagerVehicleChecklistItem) => {
    if (!name.trim().length) {
      toast.error('Error editing series name');
      return;
    }

    if (vehicleDataStore.findSeriesByName(name)) {
      return toast.error('Please enter a new series name');
    }

    try {
      const { id, revId, group } = item;
      const response = await trackPromise(
        updateSeries({ brand: toGqlBrand(brand), payload: { id, revId, name, group } })
      );
      const series = response.series[id];
      item.revId = series.revId;
      item.name = series.name;

      setChecklist([...checklist]);

      Object.assign(vehicleDataStore.series, response.series);

      vehicleDataStore.dashboardSeries.forEach(series => {
        if (series.id === item.id) {
          series.seriesName = item.name;
        }
      });
    } catch (e) {
      toast.error('Error editing series name');
    }
  };

  // dph
  const handleOnSaveDph = async (categoryId: string) => {
    if (!dphCategories?.[categoryId] || !newSeries) {
      return;
    }
    try {
      const dphCategory = dphCategories[categoryId];

      dphCategory.name = dphCategory.name ? dphCategory.name : '';
      dphCategory.nextDph = dphCategory.nextDph ? dphCategory.nextDph : '';
      dphCategory.nextDphLiveDate = dphCategory.nextDphLiveDate ? dphCategory.nextDphLiveDate : '';
      let seriesMap = dphCategory.seriesMap ? dphCategory.seriesMap : {};

      const series = [...Object.keys(seriesMap), newSeries.id];
      await trackPromise(
        updateDph({
          brand: toGqlBrand(dphBrand),
          region: toGqlRegion(region),
          payload: {
            categoryId: dphCategory.categoryId,
            revId: dphCategory.revId,
            name: dphCategory.name,
            series,
            dph: dphCategory.dph,
            nextDph: dphCategory.nextDph,
            nextDphLiveDate: dphCategory.nextDphLiveDate,
          },
        })
      );
      setOpenDphModal(false);
      toast.success(`Successfully added series to the ${dphCategory.name} dph category.`);
    } catch (e) {
      toast.error('Error adding to dph category.');
    }
  };

  return (
    <>
      <Header moduleTitle="" moduleSubTitle={`${teamStore.team.pageTitle} Data`}>
        {vehicleDataStore.dashboardSeries.length > 0 && (
          <Button variant="transparent" onClick={() => onShowSeriesManager()}>
            Back to Dashboard
          </Button>
        )}
      </Header>
      <h1 className={styles.heading}>Which series(s) will you be managing?</h1>

      <>
        <div className={styles.seriesManagerContainer}>
          {checklist.map((category, index) => (
            // Category
            <div className={styles.categoriesContainer} key={category.name}>
              <button
                className={cx(styles.categories, {
                  [styles.selected]: tabIndex === index,
                  [styles.long]: category.name === 'Crossover Utility Vehicles',
                  [styles.trucks]: category.name === 'Trucks/SUVs',
                })}
                onClick={() => setTabIndex(index)}
              >
                {brand === BRAND_LEXUS && category.name === VehicleCategories.TRUCKS_SUVS
                  ? VehicleCategories.LEXUS_TRUCKS_SUVS
                  : category.name}
              </button>
            </div>
          ))}

          {checklist.map((category, index) => (
            <div
              className={cx({ [styles.tilesContainer]: tabIndex === index })}
              key={category.name}
            >
              <div className={styles.tilesWrapper}>
                {tabIndex === index &&
                  category.items &&
                  category.items.map(item => (
                    // Vehicle Checkbox
                    <div key={item.name} className={styles.tile}>
                      <img src={item.carImage} alt={carImage} className={styles.carJellyImage} />
                      {canEditSeries ? (
                        <EditSeriesName
                          checkboxID={`chbox${item.name}`}
                          isChecked={item.selected}
                          item={item as SeriesManagerVehicleChecklistItem}
                          selectItem={selectItem}
                          onUpdateSeries={handleOnUpdateSeries}
                        ></EditSeriesName>
                      ) : (
                        <Checkbox
                          id={`chbox${item.name}`}
                          className={styles.checkbox}
                          checked={item.selected}
                          disabled={!canEdit}
                          onChange={e => selectItem(item, e.currentTarget.checked)}
                        >
                          {item.name}
                        </Checkbox>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        {/* Selections */}
        <div className={styles.selections}>
          <label htmlFor="selections" className={styles.selectionsLabel}>
            Your Selections:
          </label>
          <span className={styles.list}>
            {checklist.map(category => (
              <span key={category.name}>
                {category.items &&
                  category.items.map(item => (
                    <span key={item.name}>
                      {item.selected && (
                        <Button
                          variant="selection"
                          className={styles.selectionCta}
                          disabled={!canEdit}
                          onClick={() => selectItem(item, false)}
                        >
                          <div className={styles.selectionCtaText} title={item.name}>
                            {item.name}
                          </div>
                        </Button>
                      )}
                    </span>
                  ))}
              </span>
            ))}
          </span>

          {/* Clear All */}
          {canEdit && (
            <Button variant="transparent" onClick={() => selectAll(false)}>
              Clear All
            </Button>
          )}
        </div>
        {/* Save Vehicle(s) */}
        {canEdit && (
          <div className={styles.saveVehicles}>
            <Button
              variant="primary"
              onClick={() => handleOnSave()}
              disabled={selectedSeries.length === 0}
            >
              Save Vehicle(s)
            </Button>
          </div>
        )}
        <div className={styles.seperator}></div>
        {/* Add Vehicle */}
        <div className={styles.addVehicle}>
          {teamStore.team.allowAddDeleteSeries && canAddSeries && (
            <Button
              variant="primary"
              onClick={() => {
                setAddVehicleModal(true);
              }}
            >
              Add Vehicle
            </Button>
          )}
          {/* add dph category modal */}
          <Modal open={openDphModal} onClose={() => setOpenDphModal(false)}>
            <SeriesManagerDphModal
              close={() => setOpenDphModal(false)}
              dphCategories={dphCategories}
              handleOnSaveDph={handleOnSaveDph}
            />
          </Modal>
          {/* add vehicle modal */}
          <Modal open={openAddVehicleModal} onClose={() => setAddVehicleModal(false)}>
            <AddVehicleModal
              close={() => setAddVehicleModal(false)}
              onAddSeries={handleOnAddSeries}
              groups={getEditableVehicleGroups(teamModule, brand)}
            />
          </Modal>
        </div>
      </>
    </>
  );
};

export default SeriesManager;
