import apolloClient from '../apolloClient';
import {
  ReadVehicleModelsQueryVariables,
  ReadVehicleModelsQuery,
  ReadVehicleModelsDocument,
  CreateVehicleModelDocument,
  CreateVehicleModelMutation,
  CreateVehicleModelMutationVariables,
  UpdateVehicleModelDocument,
  UpdateVehicleModelMutation,
  UpdateVehicleModelMutationVariables,
  CreateVehicleModelLexusDocument,
  CreateVehicleModelLexusMutation,
  CreateVehicleModelLexusMutationVariables,
  UpdateVehicleModelLexusDocument,
  UpdateVehicleModelLexusMutation,
  UpdateVehicleModelLexusMutationVariables,
  DeleteVehicleModelDocument,
  DeleteVehicleModelMutation,
  DeleteVehicleModelMutationVariables,
  SortVehicleModelsDocument,
  SortVehicleModelsMutation,
  SortVehicleModelsMutationVariables,
  ReadVehicleModelGradesDocument,
  ReadVehicleModelGradesQuery,
  ReadVehicleModelGradesQueryVariables,
  CreateVehicleModelGradesDocument,
  CreateVehicleModelGradesMutation,
  CreateVehicleModelGradesMutationVariables,
  UpdateVehicleModelGradesDocument,
  UpdateVehicleModelGradesMutation,
  UpdateVehicleModelGradesMutationVariables,
  ReadVehicleModelChangesDocument,
  ReadVehicleModelChangesQuery,
  ReadVehicleModelChangesQueryVariables,
  RevertVehicleModelChangeDocument,
  RevertVehicleModelChangeMutation,
  RevertVehicleModelChangeMutationVariables,
  ReadVehicleModelReviewsDocument,
  ReadVehicleModelReviewsQuery,
  ReadVehicleModelReviewsQueryVariables,
  UpdateVehicleModelReviewDocument,
  UpdateVehicleModelReviewMutation,
  UpdateVehicleModelReviewMutationVariables,
  ReadVehicleModelGradesReviewsDocument,
  ReadVehicleModelGradesReviewsQuery,
  ReadVehicleModelGradesReviewsQueryVariables,
  ReadVehicleModelSeriesDocument,
  ReadVehicleModelSeriesQuery,
  ReadVehicleModelSeriesQueryVariables,
  CreateVehicleModelSeriesDocument,
  CreateVehicleModelSeriesMutation,
  CreateVehicleModelSeriesMutationVariables,
  UpdateVehicleModelSeriesDocument,
  UpdateVehicleModelSeriesMutation,
  UpdateVehicleModelSeriesMutationVariables,
  DeleteVehicleModelSeriesDocument,
  DeleteVehicleModelSeriesMutation,
  DeleteVehicleModelSeriesMutationVariables,
  ReadVehicleModelMapsDocument,
  ReadVehicleModelMapsQuery,
  ReadVehicleModelMapsQueryVariables,
  UpdateVehicleModelsGoLiveDateDocument,
  UpdateVehicleModelsGoLiveDateMutation,
  UpdateVehicleModelsGoLiveDateMutationVariables,
  UpdateVehicleModelStatusDocument,
  UpdateVehicleModelStatusMutation,
  UpdateVehicleModelStatusMutationVariables,
  UpdateVehicleModelStatusAllDocument,
  UpdateVehicleModelStatusAllMutation,
  UpdateVehicleModelStatusAllMutationVariables,
  UpdateVehicleModelSeriesStatusDocument,
  UpdateVehicleModelSeriesStatusMutation,
  UpdateVehicleModelSeriesStatusMutationVariables,
  ReadVehicleModelSeriesReviewsDocument,
  ReadVehicleModelSeriesReviewsQuery,
  ReadVehicleModelSeriesReviewsQueryVariables,
} from '../gql/generated';
import { RefItemLanguageMap } from '../models/category.model';
import { ChangeLogModelResponse } from '../models/changeLog.model';
import { RefItemResponse } from '../models/refItem.model';
import { ReviewChangeRequest } from '../models/review.model';
import {
  SeriesSettingsResponse,
  SeriesSettingsReviewResponse,
  UpdateSeriesSettingsStatusResponse,
} from '../models/seriesSettings.model';
import { SortModel } from '../models/vehicleData.model';
import {
  ModelLangMap,
  ModelsReviewResponse,
  VehicleModel,
  VehicleModelLexus,
  VehicleModelToyota,
  UpdateModelStatusReponse,
  UpdateAllModelStatusesResponse,
} from '../models/vehicleModel.model';

// Models
export const getModels = async (
  variables: ReadVehicleModelsQueryVariables
): Promise<VehicleModel<VehicleModelLexus | VehicleModelToyota>[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleModelsQuery,
    ReadVehicleModelsQueryVariables
  >({
    query: ReadVehicleModelsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleModels.body;
};

export const addModel = async (
  variables: CreateVehicleModelMutationVariables
): Promise<VehicleModel<VehicleModelToyota | VehicleModelLexus>> => {
  const { data } = await apolloClient.mutate<
    CreateVehicleModelMutation,
    CreateVehicleModelMutationVariables
  >({
    mutation: CreateVehicleModelDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to create vehicle model');
  }

  return data.createVehicleModel.body;
};

export const updateModel = async (
  variables: UpdateVehicleModelMutationVariables
): Promise<VehicleModel<VehicleModelToyota | VehicleModelLexus>> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleModelMutation,
    UpdateVehicleModelMutationVariables
  >({
    mutation: UpdateVehicleModelDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update vehicle model');
  }

  return data.updateVehicleModel.body;
};

export const addModelLexus = async (
  variables: CreateVehicleModelLexusMutationVariables
): Promise<VehicleModel<VehicleModelToyota | VehicleModelLexus>> => {
  const { data } = await apolloClient.mutate<
    CreateVehicleModelLexusMutation,
    CreateVehicleModelLexusMutationVariables
  >({
    mutation: CreateVehicleModelLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to create vehicle model');
  }

  return data.createVehicleModelLexus.body;
};

export const updateModelLexus = async (
  variables: UpdateVehicleModelLexusMutationVariables
): Promise<VehicleModel<VehicleModelToyota | VehicleModelLexus>> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleModelLexusMutation,
    UpdateVehicleModelLexusMutationVariables
  >({
    mutation: UpdateVehicleModelLexusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update vehicle model');
  }

  return data.updateVehicleModelLexus.body;
};

export const deleteModel = async (variables: DeleteVehicleModelMutationVariables): Promise<any> => {
  const { data } = await apolloClient.mutate<
    DeleteVehicleModelMutation,
    DeleteVehicleModelMutationVariables
  >({
    mutation: DeleteVehicleModelDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete vehicle model');
  }

  return data.deleteVehicleModel.body;
};

export const sortModels = async (
  variables: SortVehicleModelsMutationVariables
): Promise<SortModel[]> => {
  const { data } = await apolloClient.mutate<
    SortVehicleModelsMutation,
    SortVehicleModelsMutationVariables
  >({
    mutation: SortVehicleModelsDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to sort vehicle models');
  }

  return data.sortVehicleModels.body;
};

//Grades
export const getGrades = async (
  variables: ReadVehicleModelGradesQueryVariables
): Promise<RefItemResponse[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleModelGradesQuery,
    ReadVehicleModelGradesQueryVariables
  >({
    query: ReadVehicleModelGradesDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleModelGrades.body;
};

export const addGrades = async (
  variables: CreateVehicleModelGradesMutationVariables
): Promise<RefItemLanguageMap> => {
  const { data } = await apolloClient.mutate<
    CreateVehicleModelGradesMutation,
    CreateVehicleModelGradesMutationVariables
  >({
    mutation: CreateVehicleModelGradesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to create vehicle model grades');
  }

  return data.createVehicleModelGrades.body;
};

export const updateGrade = async (
  variables: UpdateVehicleModelGradesMutationVariables
): Promise<RefItemLanguageMap> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleModelGradesMutation,
    UpdateVehicleModelGradesMutationVariables
  >({
    mutation: UpdateVehicleModelGradesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update vehicle model grades');
  }

  return data.updateVehicleModelGrades.body;
};

//Changelog
export const getChangeLog = async (
  variables: ReadVehicleModelChangesQueryVariables
): Promise<ChangeLogModelResponse[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleModelChangesQuery,
    ReadVehicleModelChangesQueryVariables
  >({
    query: ReadVehicleModelChangesDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleModelChanges.body;
};

export const revertChangeLog = async (
  variables: RevertVehicleModelChangeMutationVariables
): Promise<VehicleModel<VehicleModelLexus | VehicleModelToyota>> => {
  const { data } = await apolloClient.mutate<
    RevertVehicleModelChangeMutation,
    RevertVehicleModelChangeMutationVariables
  >({
    mutation: RevertVehicleModelChangeDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to revert vehicle model change');
  }

  return data.revertVehicleModelChange.body;
};

// Review
export const getReviewModels = async (
  variables: ReadVehicleModelReviewsQueryVariables
): Promise<(VehicleModel<VehicleModelLexus | VehicleModelToyota> | ModelsReviewResponse)[]> => {
  // VehicleModel<VehicleModelLexus | VehicleModelToyota>[]
  const { data } = await apolloClient.query<
    ReadVehicleModelReviewsQuery,
    ReadVehicleModelReviewsQueryVariables
  >({
    query: ReadVehicleModelReviewsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleModelReviews.body;
};

export const updateReviewModels = async (
  variables: UpdateVehicleModelReviewMutationVariables
): Promise<ReviewChangeRequest> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleModelReviewMutation,
    UpdateVehicleModelReviewMutationVariables
  >({
    mutation: UpdateVehicleModelReviewDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update vehicle model review');
  }

  return data.updateVehicleModelReview.body;
};

export const getReviewGrades = async (
  variables: ReadVehicleModelGradesReviewsQueryVariables
): Promise<RefItemResponse[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleModelGradesReviewsQuery,
    ReadVehicleModelGradesReviewsQueryVariables
  >({
    query: ReadVehicleModelGradesReviewsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleModelGradesReviews.body;
};

// Series Settings
export const getSeriesSettings = async (
  variables: ReadVehicleModelSeriesQueryVariables
): Promise<SeriesSettingsResponse[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleModelSeriesQuery,
    ReadVehicleModelSeriesQueryVariables
  >({
    query: ReadVehicleModelSeriesDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleModelSeries.body;
};

export const addSeriesSetting = async (
  variables: CreateVehicleModelSeriesMutationVariables
): Promise<SeriesSettingsResponse> => {
  const { data } = await apolloClient.mutate<
    CreateVehicleModelSeriesMutation,
    CreateVehicleModelSeriesMutationVariables
  >({
    mutation: CreateVehicleModelSeriesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to create vehicle model series');
  }

  return data.createVehicleModelSeries.body;
};

export const updateSeriesSetting = async (
  variables: UpdateVehicleModelSeriesMutationVariables
): Promise<SeriesSettingsResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleModelSeriesMutation,
    UpdateVehicleModelSeriesMutationVariables
  >({
    mutation: UpdateVehicleModelSeriesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update vehicle model series');
  }

  return data.updateVehicleModelSeries.body;
};

export const deleteSeriesSetting = async (
  variables: DeleteVehicleModelSeriesMutationVariables
): Promise<any> => {
  const { data } = await apolloClient.mutate<
    DeleteVehicleModelSeriesMutation,
    DeleteVehicleModelSeriesMutationVariables
  >({
    mutation: DeleteVehicleModelSeriesDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to delete vehicle model series');
  }

  return data.deleteVehicleModelSeries.body;
};

// Series Settings Review
export const getReviewSeriesSettings = async (
  variables: ReadVehicleModelSeriesReviewsQueryVariables
): Promise<SeriesSettingsReviewResponse[]> => {
  const { data } = await apolloClient.query<
    ReadVehicleModelSeriesReviewsQuery,
    ReadVehicleModelSeriesReviewsQueryVariables
  >({
    query: ReadVehicleModelSeriesReviewsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleModelSeriesReviews.body;
};

// For BnP
export const getModelMaps = async (
  variables: ReadVehicleModelMapsQueryVariables
): Promise<ModelLangMap> => {
  const { data } = await apolloClient.query<
    ReadVehicleModelMapsQuery,
    ReadVehicleModelMapsQueryVariables
  >({
    query: ReadVehicleModelMapsDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  return data.readVehicleModelMaps.body;
};

// Go live date
export const updateGoLiveDate = async (
  variables: UpdateVehicleModelsGoLiveDateMutationVariables
): Promise<VehicleModel<VehicleModelLexus | VehicleModelToyota>[]> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleModelsGoLiveDateMutation,
    UpdateVehicleModelsGoLiveDateMutationVariables
  >({
    mutation: UpdateVehicleModelsGoLiveDateDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update vehicle model go live date');
  }

  return data.updateVehicleModelsGoLiveDate.body;
};

export const updateModelStatuses = async (
  variables: UpdateVehicleModelStatusMutationVariables
): Promise<UpdateModelStatusReponse> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleModelStatusMutation,
    UpdateVehicleModelStatusMutationVariables
  >({
    mutation: UpdateVehicleModelStatusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update vehicle model status');
  }

  return data.updateVehicleModelStatus.body;
};

export const updateAllModelStatuses = async (
  variables: UpdateVehicleModelStatusAllMutationVariables
): Promise<UpdateAllModelStatusesResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleModelStatusAllMutation,
    UpdateVehicleModelStatusAllMutationVariables
  >({
    mutation: UpdateVehicleModelStatusAllDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update vehicle model all status');
  }

  return data.updateVehicleModelStatusAll.body;
};

export const updateSeriesSettingsStatuses = async (
  variables: UpdateVehicleModelSeriesStatusMutationVariables
): Promise<UpdateSeriesSettingsStatusResponse> => {
  const { data } = await apolloClient.mutate<
    UpdateVehicleModelSeriesStatusMutation,
    UpdateVehicleModelSeriesStatusMutationVariables
  >({
    mutation: UpdateVehicleModelSeriesStatusDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to update vehicle model series status');
  }

  return data.updateVehicleModelSeriesStatus.body;
};
