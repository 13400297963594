import React from 'react';
import { Checkbox } from 'vapi-ui-common';
import { TableRow, TableCell } from '../Table';
import styles from './limitedDataTable.module.scss';

export interface LimitedDataRowProps {
  children: JSX.Element | JSX.Element[];
  isChecked?: boolean;
  onClick?: () => void;
}

export const LimitedDataRow = ({ children, isChecked, onClick, ...rest }: LimitedDataRowProps) => {
  return (
    <TableRow zebra {...rest} data-testid="limited-data-row">
      <TableCell className={styles.tableCellText}>
        <div onClick={onClick}>
          <Checkbox checked={isChecked} onChange={() => ({})} />
        </div>
      </TableCell>
      {children}
    </TableRow>
  );
};
