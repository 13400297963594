import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ActionBar, ActionBarSection, MultiLineRTEditor, SearchInput } from 'vapi-ui-common';
import { ChangeLogHeaderRow, ChangeLogRow } from '../../../../components/ChangeLogTable';
import Spinner from '../../../../components/Spinner';
import { Table } from '../../../../components/Table';
import { TableTabs } from '../../../../components/Table/components/TableTabs';
import useQuery from '../../../../hooks/useQuery';
import useStores from '../../../../hooks/useStores';
import { ChangeLogItem, ChangeLogTypes } from '../../../../models/changeLog.model';
import { OptionsTabType } from '../../../../models/optionsLexus.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../models/vehicleModel.model';
import {
  changeLogHandleDeleteTypes,
  changeLogModelApplicabilityMapper,
} from '../../../../utils/changeLogUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { getChangeLog, revertChange } from '../../../../webservices/vehicleOptionsApi';
import styles from './optionsLexusChangeLog.module.scss';
import { Language } from '../../../../gql/generated';
import { toGqlBrand, toGqlTeam, toGqlFilter } from '../../../../utils/graphqlUtils';

const getVersion = (version: string | null) => {
  return version ? `&version=${version}` : '';
};

interface OptionsChangeLogProps {
  seriesId: string;
  year: string;
  readOnly?: boolean;
  version: string;
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
}

const OptionsLexusChangeLog = ({
  seriesId,
  year,
  readOnly,
  version,
  vehicleModels,
}: OptionsChangeLogProps) => {
  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
  } = useStores();

  const query = useQuery();
  const optionsTabParam = (query.get('optionsTab') || 'packages') as OptionsTabType;
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [tabDisplay, setTabDisplay] = useState(
    optionsTabParam === 'options' ? 'Options' : 'Packages'
  );
  const [optionsTab, setOptionsTab] = useState<OptionsTabType>(optionsTabParam);
  const history = useHistory();

  useEffect(() => {
    changeLogStore.reset();

    (async () => {
      setIsLoaded(false);

      try {
        const responses = await getChangeLog({
          brand: toGqlBrand(brand),
          team: toGqlTeam(teamStore.team.param),
          seriesId: seriesId,
          modelYear: parseInt(year),
          language: Language.En,
          filter: toGqlFilter(version),
          optionTab: optionsTab,
        });

        const logs = responses.map(item => new ChangeLogItem(item, !readOnly));

        changeLogHandleDeleteTypes(logs, ChangeLogTypes.OPTION_DELETED);
        changeLogModelApplicabilityMapper(vehicleModels, logs);
        changeLogStore.logs = logs;
        setHasLoadingError(false);
      } catch (e) {
        toast.error('Error loading options change log');
        setHasLoadingError(true);
      }

      setIsLoaded(true);
    })();
  }, [
    brand,
    changeLogStore,
    readOnly,
    seriesId,
    teamStore,
    year,
    version,
    vehicleModels,
    optionsTab,
  ]);

  const handleOnRevert = async (item: ChangeLogItem) => {
    try {
      const payload = item.payload;
      await trackPromise(
        revertChange({
          brand: toGqlBrand(brand),
          team: toGqlTeam(teamStore.team.param),
          seriesId: seriesId,
          modelYear: parseInt(year),
          language: Language.En,
          payload: {
            changeType: payload.changeType,
            id: payload.id,
            from: payload.from,
            parentId: payload.parentId,
            to: payload.to,
          },
          optionTab: optionsTab,
        })
      );
      const response = await getChangeLog({
        brand: toGqlBrand(brand),
        team: toGqlTeam(teamStore.team.param),
        seriesId: seriesId,
        modelYear: parseInt(year),
        language: Language.En,
        filter: toGqlFilter(version),
        optionTab: optionsTab,
      });
      const logs = response.map(item => new ChangeLogItem(item, !readOnly));
      changeLogHandleDeleteTypes(logs, ChangeLogTypes.OPTION_DELETED);
      changeLogModelApplicabilityMapper(vehicleModels, logs);
      changeLogStore.logs = logs;

      toast.success('Change log reverted');
    } catch (e) {
      handleErrorResponse(e, 'Error reverting change log');
    }
  };

  const getDescriptionColumn = (item: ChangeLogItem) => {
    switch (item.changeType) {
      case ChangeLogTypes.PACKAGE_SPEC_ADDED:
      case ChangeLogTypes.PACKAGE_SPEC_DELETED:
      case ChangeLogTypes.PACKAGE_SPEC_SMS_CHANGED:
      case ChangeLogTypes.PACKAGE_SPEC_TOMS_CHANGED:
      case ChangeLogTypes.PACKAGE_SPEC_SPECS_CHANGED:
      case ChangeLogTypes.PACKAGE_SPEC_EXCLUSIVE_CHANGED:
      case ChangeLogTypes.PACKAGE_SPEC_MODEL_APPLICABILITY:
      case ChangeLogTypes.PACKAGE_SPEC_MODEL_APPLICABILITY_CHANGED:
      case ChangeLogTypes.PACKAGE_VARIETY_ADDED:
      case ChangeLogTypes.PACKAGE_VARIETY_DELETED:
      case ChangeLogTypes.PACKAGE_VARIETY_MODEL_APPLICABILITY:
      case ChangeLogTypes.PACKAGE_VARIETY_NAME_CHANGED:
      case ChangeLogTypes.PACKAGE_VARIETY_CODE_CHANGED:
      case ChangeLogTypes.PACKAGE_VARIETY_REQUIRED_CHANGED:
      case ChangeLogTypes.PACKAGE_VARIETY_CONFLICTS_CHANGED:
      case ChangeLogTypes.OPTION_SPEC_ADDED:
      case ChangeLogTypes.OPTION_SPEC_DELETED:
      case ChangeLogTypes.OPTION_SPEC_SMS_CHANGED:
      case ChangeLogTypes.OPTION_SPEC_TOMS_CHANGED:
      case ChangeLogTypes.OPTION_SPEC_SPECS_CHANGED:
      case ChangeLogTypes.OPTION_SPEC_EXCLUSIVE_CHANGED:
      case ChangeLogTypes.OPTION_SPEC_MODEL_APPLICABILITY: {
        return (
          <>
            <MultiLineRTEditor
              editorStyles={styles.descriptionParentName}
              disabled
              value={item.parentName}
            />
            <MultiLineRTEditor disabled value={item.name} />
          </>
        );
      }

      default: {
        return <MultiLineRTEditor disabled value={item.description || item.name} />;
      }
    }
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarSection>
          <SearchInput
            value={changeLogStore.searchText}
            onSearch={text => (changeLogStore.searchText = text)}
          />
        </ActionBarSection>
      </ActionBar>
      <TableTabs
        tabs={['Packages', 'Options']}
        currentTab={tabDisplay}
        onSelectTab={value => {
          const valLower = value.toLowerCase();
          const version = getVersion(query.get('version'));
          setTabDisplay(value);
          setOptionsTab(valLower as OptionsTabType);
          history.push(
            `?team=${query.get('team')}&tab=${query.get(
              'tab'
            )}${version}&optionsTab=${valLower}&return=${query.get('return')}`
          );
        }}
      />
      {hasLoadingError ? null : (
        <Table fullWidth>
          <ChangeLogHeaderRow onSort={changeLogStore.setSort} />
          <tbody>
            {changeLogStore.filteredLogs.map(item => (
              <ChangeLogRow
                key={item.changeLogId}
                item={item}
                module={'Option'}
                onRevert={handleOnRevert}
                customDescription={getDescriptionColumn(item)}
              />
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default observer(OptionsLexusChangeLog);
