import React from 'react';
import { DropdownList } from 'vapi-ui-common';
import DropdownEdit from '../../../../../../../components/DropdownEdit';
import DropdownEditItem from '../../../../../../../components/DropdownEdit/DropdownEditItem';
import { IDValueType } from '../../../../../../../models/common.model';

interface ColorsInteriorDropdownProps {
  intItem: IDValueType<string>;
  type: string;
  addBtnText: string;
  list: IDValueType<string>[];
  setValue(value: IDValueType<string>): void;
  onSave(value: IDValueType<string>, type: string, isNew?: boolean): void;
  placeholder: string;
  required?: boolean;
}

const ColorsInteriorDropdown = ({
  intItem,
  type,
  addBtnText,
  list,
  setValue,
  onSave,
  placeholder,
  required,
}: ColorsInteriorDropdownProps) => {
  return (
    <DropdownList
      value={intItem?.value}
      placeholder={placeholder}
      required={required}
      contentElement={(ref, open, closeDropdown) => (
        <DropdownEdit
          ref={ref}
          open={open}
          addBtnText={addBtnText}
          onAdd={value => {
            onSave(new IDValueType('', value), type, true);
          }}
          renderList={() =>
            list.map(item => (
              <DropdownEditItem
                key={item.uid}
                value={item.value}
                isSelected={item.id === intItem?.value}
                onEdit={(oldVal, newVal) => {
                  item.value = newVal;
                  onSave(item, type);
                }}
                onSelect={() => {
                  setValue(item);
                  closeDropdown();
                }}
              />
            ))
          }
        />
      )}
    />
  );
};

export default ColorsInteriorDropdown;
