import { computed, observable } from 'mobx';
import { toLowerCase } from '../../../../utils';
import { SpecItem } from '../../../../models/specs.model';

export class ModalState {
  @observable items: StandardSpecCheckItem[] = [];
  @observable searchText = '';

  @computed get filteredItems() {
    const text = toLowerCase(this.searchText);
    return this.items.filter(item => !text || toLowerCase(item.description).includes(text));
  }

  @computed get selectCount() {
    return this.items.filter(item => !item.disabled && item.checked).length;
  }
}

export class StandardSpecCheckItem extends SpecItem {
  checked = false;
  disabled = false;
  isAdded = false;
}
