import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input } from 'vapi-ui-common';
import useStores from '../../../../../../../../hooks/useStores';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../../models/vehicleModel.model';
import styles from '../../../../modelsModal.module.scss';
import Label from '../Label';

interface DriveProps {
  vmId: string;
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  children?: JSX.Element | JSX.Element[];
}

const Drive = observer(({ vmId, model, children }: DriveProps) => {
  const {
    modelTabStore: {
      languages: checkboxLanguages,
      readOnly,
      getDrive,
      setDrive,
      getLangVehicleModel,
    },
    userStore: { langPermissions },
    teamStore: {
      team: { languages: teamLanguages },
    },
  } = useStores();

  return (
    <div className={styles.column}>
      <div className={styles.label}>
        <Label htmlFor={`amm-drive-${vmId}`}>Drive</Label>
      </div>
      <div className={cx(styles.content)}>
        {teamLanguages.map(language => {
          if (checkboxLanguages[language].selected && langPermissions[language]?.canView) {
            let changedAttributes: string[] = [];
            if (langPermissions[language]?.canEdit) {
              changedAttributes =
                getLangVehicleModel(model, language).getVal('changedAttributes') ?? [];
            }
            return (
              <Input
                required
                id={`amm-drive-${vmId}-${language}`}
                key={`amm-drive-${vmId}-${language}`}
                value={getDrive(vmId, language)}
                disabled={!langPermissions[language]?.canEdit || readOnly}
                onChange={e => setDrive(vmId, language, e.currentTarget.value)}
                placeholder={checkboxLanguages[language].description}
                className={cx(
                  { [styles.errorText]: changedAttributes.includes('drive') },
                  styles.input
                )}
              />
            );
          }

          return null;
        })}
      </div>
      {children}
    </div>
  );
});

export default Drive;
