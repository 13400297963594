import { AdminVehicleData, VehicleTeam } from '../../../../models/vehicleData.model';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PendingReview.module.scss';

interface PendingReviewProps {
  adminVehicleData: AdminVehicleData;
  reviewHref: string;
  reviewTeamParam: VehicleTeam;
  reviewTeamTitle: string;
}

const PendingReview = ({
  adminVehicleData,
  reviewHref,
  reviewTeamParam,
  reviewTeamTitle,
}: PendingReviewProps) => {
  const intro = `Review updates from `;
  if (adminVehicleData.isPendingVDReview) {
    return (
      <div className={styles.pendingReviewContainer}>
        {intro}
        <span className={styles.pendingReviewLink}>
          <Link to={`${reviewHref}?team=${reviewTeamParam}`}>{reviewTeamTitle}</Link>
        </span>
        {adminVehicleData.isPendingCLReview && (
          <span>
            <br />
            {intro} Common Language
          </span>
        )}
      </div>
    );
  } else if (adminVehicleData.isPendingCLReview) {
    return (
      <div className={styles.pendingReviewContainer}>
        {intro}
        <span className={styles.pendingReviewLink}>
          <Link to={`${reviewHref}?team=${VehicleTeam.COMMON_LANGUAGE_TEAM}`}>Common Language</Link>
        </span>
      </div>
    );
  }

  return null;
};

export default PendingReview;
