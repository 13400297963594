import { observer } from 'mobx-react-lite';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { CompareFeatureLangMap } from '../../../../../../models/compareFeatures.model';
import CompareFeatureRow from './CompareFeatureRow';
import { ICompareFeatureRow } from './ICompareFeatureRow';

interface ComapareFeatureRowsContainerProps extends ICompareFeatureRow {
  compareFeatureLangMaps: CompareFeatureLangMap[];
  setGradeCellStatusChange?: (val: boolean) => void;
}

/**
 * Wraps the Droppable component so updates to Compare features, categories will
 * trigger a re-render
 */

const CompareFeatureRowsContainer = ({
  compareFeatureLangMaps,
  sortMode,
  setGradeCellStatusChange,
  ...rest
}: ComapareFeatureRowsContainerProps) => {
  return (
    <Droppable droppableId="featuresDroppable" isDropDisabled={!sortMode}>
      {provided => (
        <tbody ref={provided.innerRef} {...provided.droppableProps}>
          {compareFeatureLangMaps.map((langMap, index) => {
            const uid = langMap.langs[Object.keys(langMap.langs)[0]].uid;
            return (
              <Draggable key={uid} draggableId={uid} index={index}>
                {(provided, snapshot) => (
                  <CompareFeatureRow
                    key={uid}
                    index={index}
                    compareFeatureLangMap={langMap}
                    draggableProvided={provided}
                    sortMode={sortMode}
                    setGradeCellStatusChange={setGradeCellStatusChange}
                    {...rest}
                  />
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
};

export default observer(CompareFeatureRowsContainer);
