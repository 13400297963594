import cx from 'clsx';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Checkbox, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';
import { SplitModelApplicabilityModalContext } from '.';
import useStores from '../../../../../../hooks/useStores';
import { BnPItemsResponse } from '../../../../../../models/buildAndPrice.model';
import { KeyValueType } from '../../../../../../models/common.model';
import { handleErrorResponse } from '../../../../../../utils/errorHandlingUtils';
import { addCategorySplit, updateApplicability } from '../../../../../../webservices/vehicleBnPApi';
import ModelApplicabilityList from './ModelApplicabilityList';
import styles from './splitModelApplicabilityModal.module.scss';
import { toGqlBrand, toGqlLanguage, toGqlTeam } from '../../../../../../utils/graphqlUtils';

const SaveModal = () => {
  const context = useContext(SplitModelApplicabilityModalContext);

  const {
    userStore: { brand },
    teamStore: {
      team: { param: team },
    },
    bnpStore,
  } = useStores();

  const { seriesId, year } = useParams<{
    seriesId: string;
    year: string;
  }>();

  if (!context) {
    return null;
  }

  const {
    category,
    setIsSaving,
    isSaving,
    allCheckedInfo,
    setCheckedAll,
    splitModelApplicabilityMap,
    onClose,
    split,
    lang,
  } = context;

  const { isChecked, numCheckedGrades, numCheckedModels, checkedModels } = allCheckedInfo();
  const { categoryValue, name, categoryId, setSplits, applicability } = category;

  const onCreate = async () => {
    if (!lang) {
      return;
    }

    const checkedModelIds: string[] = Object.keys(checkedModels);
    if (!checkedModelIds.length) {
      toast.error('No applicable models selected. Please select at least 1 model.');
      return;
    }

    // if a user doesnt have a split then we need to make sure that at least 1 model is unchecked because the default split needs at least 1 applicable model
    let hasUncheckedModel = false;
    const applicabilities = Object.values(splitModelApplicabilityMap);
    for (const splitModelApplicability of applicabilities) {
      const modelIds = Object.keys(splitModelApplicability.models);
      for (const modelId of modelIds) {
        if (!checkedModels[modelId]) {
          hasUncheckedModel = true;
          break;
        }
      }
    }

    if (!hasUncheckedModel) {
      toast.error(
        'At least 1 model is required for default split. Please uncheck at least 1 model.'
      );
      return;
    }

    setIsSaving(true);

    try {
      const res = await addCategorySplit({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId,
        modelYear: parseInt(year),
        language: toGqlLanguage(lang),
        payload: {
          name,
          category: categoryId,
          applicability: checkedModelIds,
        },
      });
      const catName = name as keyof BnPItemsResponse;
      const data = res[catName];
      const catData = data.categories[categoryId];

      const nSplits = Object.values(catData?.splits ?? {}).reduce((spMap, sp) => {
        if (!sp.isDeleted) {
          return { ...spMap, [sp.id]: sp };
        }

        return spMap;
      }, {});

      setSplits(nSplits);

      const newApplicability: KeyValueType<string> = catData?.applicability ?? {};
      const modelIds = Object.keys(newApplicability);

      for (const modelId of modelIds) {
        if (!applicability[modelId]) {
          delete newApplicability[modelId];
        }
      }

      category.applicability = newApplicability;

      if (data.fieldStatus) bnpStore.fieldStatus[catName] = data.fieldStatus;

      toast.success('Split successfully created.');
      onClose();
    } catch (error) {
      handleErrorResponse(error, 'Unable to add split.');
      setIsSaving(false);
    }
  };

  const onUpdate = async () => {
    if (!split || !lang) {
      return;
    }

    const checkedModelIds: string[] = Object.keys(checkedModels);
    if (!checkedModelIds.length) {
      toast.error('No applicable models selected.');
      return;
    }

    setIsSaving(true);

    const nApplicability = Object.entries(applicability).reduce((nApp, [modelId, splitId]) => {
      if (checkedModels[modelId]) {
        return { ...nApp, [modelId]: split.id };
      }

      if (split.id === splitId) {
        return { ...nApp, [modelId]: 'default' };
      }

      return { ...nApp, [modelId]: splitId };
    }, {} as Record<string, string>);

    try {
      const res = await updateApplicability({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId,
        modelYear: parseInt(year),
        language: toGqlLanguage(lang),
        payload: {
          name,
          category: categoryId,
          applicability: Object.entries(nApplicability).map(([modelId, splitId]) => ({
            modelId,
            applicability: splitId,
          })),
        },
      });
      category.applicability = nApplicability;

      const catName = name as keyof BnPItemsResponse;
      const fieldStatus = res[catName].fieldStatus;
      if (fieldStatus) bnpStore.fieldStatus[catName] = fieldStatus;

      toast.success('Applicability updated successfully.');
      onClose();
    } catch (error) {
      handleErrorResponse(error, 'Unable to update applicability.');
      setIsSaving(false);
    }
  };

  const renderList = () => {
    return Object.values(splitModelApplicabilityMap).map(splitModelApplicability => {
      return (
        <ModelApplicabilityList
          key={splitModelApplicability.grade.id}
          splitModelApplicability={splitModelApplicability}
          includedModels={Object.values(splitModelApplicability.models)}
        />
      );
    });
  };

  return (
    <>
      <ModalHeader onClose={onClose}>{`Split > ${categoryValue} Applicable Models`}</ModalHeader>
      <ModalBody>
        {Object.values(splitModelApplicabilityMap).length ? (
          <Checkbox
            labelClassName={cx({
              [styles.checkboxSquare]: (numCheckedGrades || numCheckedModels) && !isChecked,
            })}
            className={styles.modelApplicabilityItem}
            id="AllModels"
            checked={isChecked}
            onChange={e => setCheckedAll(!isChecked)}
          >
            All Models
          </Checkbox>
        ) : (
          <div />
        )}

        <div className={styles.modelApplicabilityGrades}>{renderList()}</div>
      </ModalBody>
      <ModalFooter>
        <Button variant="primary" onClick={!split ? onCreate : onUpdate} disabled={isSaving}>
          Split
        </Button>
      </ModalFooter>
    </>
  );
};
export default SaveModal;
