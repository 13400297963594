import NotesPopover from '../../../../../../components/NotesPopover';
import { TableCell, TableRow } from '../../../../../../components/Table';
import { observer } from 'mobx-react-lite';
import { OptionsPackageVariety } from '../../../../../../models/optionsLexus.model';
import React, { TableHTMLAttributes, useState } from 'react';
import {
  ActionButton,
  ActionItemModal,
  CenteredTextArea,
  FlexContainer,
  Modal,
} from 'vapi-ui-common';
import styles from './optionsLexusVarietyRow.module.scss';

interface PackageVarietyRowProps extends TableHTMLAttributes<HTMLTableRowElement> {
  variety: OptionsPackageVariety;
  readOnly?: boolean;
  saveOption?(): void;
  onDeleteVariety?(variety: OptionsPackageVariety): void;
}

const PackageVarietyRow = ({
  variety,
  readOnly,
  saveOption = () => void 0,
  onDeleteVariety = () => void 0,
  ...rest
}: PackageVarietyRowProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOnNameChange = (name: string) => {
    if (name !== variety.name) {
      variety.name = name;
      saveOption();
    }
  };

  const handleOnCodeChange = (code: string) => {
    if (code !== variety.code) {
      variety.code = code;
      saveOption();
    }
  };

  const handleOnRequiredChange = (required: string) => {
    if (required !== variety.required) {
      variety.required = required;
      saveOption();
    }
  };

  const handleOnConflictsChange = (conflicts: string) => {
    if (conflicts !== variety.conflicts) {
      variety.conflicts = conflicts;
      saveOption();
    }
  };

  const handleOnDelete = () => {
    onDeleteVariety(variety);
  };

  return (
    <TableRow className={styles.varietyRow} {...rest}>
      <TableCell>
        <CenteredTextArea
          value={variety.name}
          onBlurCallback={handleOnNameChange}
          disabled={readOnly}
          placeholder="Variety Name"
          size="medium"
          allowEmpty
          useInput
        />
      </TableCell>
      <TableCell>
        <CenteredTextArea
          value={variety.code}
          onBlurCallback={handleOnCodeChange}
          disabled={readOnly}
          placeholder="Code"
          size="small"
          allowEmpty
          useInput
        />
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>
        <FlexContainer>
          {!readOnly && <ActionButton icon="trash" onClick={() => setOpenDeleteModal(true)} />}
          <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
            <ActionItemModal
              onClose={() => setOpenDeleteModal(false)}
              description={variety.name}
              onConfirm={() => handleOnDelete()}
              itemType="Variety"
            />
          </Modal>
          {variety.rejectNotes && (
            <NotesPopover
              notes={variety.rejectNotes}
              handleUpdateItem={() => void 0}
              align="left"
              icon="purpleCircle"
              iconText="Review"
              readOnly
            />
          )}
        </FlexContainer>
      </TableCell>
      <TableCell>
        <CenteredTextArea
          value={variety.required}
          onBlurCallback={handleOnRequiredChange}
          disabled={readOnly}
          placeholder="Required"
          size="small"
          allowEmpty
          useInput
        />
      </TableCell>
      <TableCell>
        <CenteredTextArea
          value={variety.conflicts}
          onBlurCallback={handleOnConflictsChange}
          disabled={readOnly}
          placeholder="Conflicts"
          size="small"
          allowEmpty
          useInput
        />
      </TableCell>
    </TableRow>
  );
};

export default observer(PackageVarietyRow);
