import { action, observable } from 'mobx';
import { ReviewChangeRequest, ReviewChangeTypeMap } from '../../../models/review.model';
import { VehicleTeam } from '../../../models/vehicleData.model';

export default class BaseReviewStore {
  @observable categoryFilters: string[] = [];
  @observable searchText = '';
  @observable isInProgressFilter = false;
  @observable isHighlightedFilter = false;
  @observable viewModelCodes = false;
  @observable showLongDescription = true;
  @observable showShortDescription = false;
  @observable reverse = true;
  @observable sortField = 'modifiedDate';
  @observable isNewChangeFilter = true;

  setSort = (sortField: string) => {
    this.reverse = this.sortField !== sortField ? false : !this.reverse;
    this.sortField = sortField;
  };

  getMapPayload(map: ReviewChangeTypeMap, team: VehicleTeam): ReviewChangeRequest {
    return {
      id: map.id,
      revId: map.revId,
      isAccepted: map.isApplied || team === VehicleTeam.AGENCY_TEAM,
      isApplied: map.isApplied,
      changeTypeId: '',
      rejectNotes: map.rejectNotes,
    };
  }

  isMapValid = (map: ReviewChangeTypeMap, team: VehicleTeam): boolean => {
    return team === VehicleTeam.AGENCY_TEAM || map.isApplied || !!map.rejectNotes;
  };

  @action reset() {
    this.categoryFilters = [];
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isHighlightedFilter = false;
    this.viewModelCodes = false;
    this.showLongDescription = true;
    this.showShortDescription = false;
    this.reverse = true;
    this.sortField = 'modifiedDate';
    this.isNewChangeFilter = true;
  }
}
