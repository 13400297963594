import { observer } from 'mobx-react-lite';
import React from 'react';
import useStores from '../../../../../../hooks/useStores';
import { ApplicabilityTextLangMap } from '../../../../../../models/compareFeatures.model';
import { OptionItem, OptionLangMap, OptionSettings } from '../../../../../../models/options.model';
import { Language } from '../../../../../../models/user.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../models/vehicleModel.model';
import SettingsCell from '../../../../components/ModelTable/components/SettingsCell';
import { updateOptionFeatureStatus } from '../../../../../../webservices/vehicleOptionsApi';
import useFieldStatusModelUpdate from '../../../../../../hooks/useFieldStatusModelUpdate';
import { tokensXForm } from '../../../../../../utils/disclaimersUtils';

interface ModelCellProps {
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  defaultOption: OptionItem;
  changedModelIds: string[];
  readOnly?: boolean;
  idx: number;
  saveOptionLangMap: (
    optionLangMap: OptionLangMap,
    lang?: string,
    acceptChanges?: boolean
  ) => Promise<void>;
  optionLangMap: OptionLangMap;
}

const ModelCell = ({
  model,
  defaultOption,
  changedModelIds,
  readOnly,
  idx,
  saveOptionLangMap,
  optionLangMap,
}: ModelCellProps) => {
  const {
    optionsStore,
    teamStore,
    disclaimersStore: { tokens },
  } = useStores();
  const { onClickModelApplicability: onClickLimitedDataStatus } = useFieldStatusModelUpdate({
    store: defaultOption,
    model,
    callBackGql: updateOptionFeatureStatus,
  });

  const disclaimerTokens = tokensXForm(tokens);

  const modelMap = defaultOption.modelsMap[model.id];

  if (model.show && modelMap) {
    const syncValueChanged = changedModelIds.includes(model.id);
    let defaultSetting = '';
    let setEditableText = false;
    const applicabilityTextMap: ApplicabilityTextLangMap = { text: {} };

    for (const lang of optionsStore.allLangs) {
      const canEdit = optionsStore.editableLangs.includes(lang);
      const text = optionLangMap.langs[lang].modelsMap[model.id].setting;
      if (canEdit && text && !setEditableText) {
        defaultSetting = text;
        setEditableText = true;
      } else if (!canEdit && !defaultSetting) {
        defaultSetting = text;
      }
      applicabilityTextMap.text[lang] = {
        text,
        canEdit,
      };
      if (syncValueChanged && !canEdit) {
        applicabilityTextMap.text[lang]!.syncValueChange = syncValueChanged;
      }
    }

    const modelData = {
      id: modelMap.id,
      applicabilityTextMap,
      setting: defaultSetting,
    };

    return (
      <SettingsCell
        disableApplicabilityText={optionsStore.allLangs.length <= 1 || readOnly}
        disableInput={!optionsStore.editableLangs.includes(Language.EN)}
        disabled={readOnly}
        model={modelData}
        syncValueChanged={changedModelIds.includes(model.id)}
        oddRow={idx % 2 === 1}
        rowSpan={defaultOption.splitCount + 1}
        allowTokens={teamStore.team.allowDisclaimerTokens}
        disclaimerTokens={disclaimerTokens}
        onUpdate={value => {
          let shouldSave = false;
          optionsStore.editableLangs.forEach(lang => {
            const setting = optionLangMap.langs[lang].modelsMap[model.id].setting || '';
            shouldSave = shouldSave || setting !== value;
            optionLangMap.langs[lang].modelsMap[
              model.id
            ].setting = (value as string) as OptionSettings;
          });
          if (shouldSave) {
            saveOptionLangMap(optionLangMap);
          }
        }}
        onUpdateApplicabilityText={applicabilityTextMap => {
          let shouldSave = false;
          const langs = Object.keys(applicabilityTextMap.text) as Language[];
          langs.forEach(lang => {
            const textMap = applicabilityTextMap.text[lang]!;
            if (textMap.canEdit) {
              const option = optionLangMap.langs[lang];
              const curTxt = option.modelsMap[model.id].setting;
              if (curTxt !== textMap.text) {
                shouldSave = true;
                option.modelsMap[model.id].setting = textMap.text as OptionSettings;
              }
            }
          });
          if (shouldSave) {
            saveOptionLangMap(optionLangMap);
          }
        }}
        allowFreeFormText
        displayLimitedDataStatus={
          teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true'
        }
        limitedDataStatus={defaultOption.fieldStatus?.modelApplicability?.[model.id]}
        onClickLimitedDataStatus={onClickLimitedDataStatus}
      />
    );
  }

  return <></>;
};

export default observer(ModelCell);
