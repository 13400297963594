import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ActionBar } from 'vapi-ui-common';
import Spinner from '../../../../components/Spinner';
import { uiBlock } from '../../../../components/UiBlocker/uiBlock';
import useStores from '../../../../hooks/useStores';
import { SpecsChangeTypeMap } from '../../../../models/specs.model';
import ActionBarFiltersSection from '../../../../routes/vehicleData/components/ActionBarFiltersSection';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { refItemsXForm } from '../../../../utils/refItemUtils';
import { filterDuplicates } from '../../../../utils/reviewUtils';
import { specsReviewXForm } from '../../../../utils/specsUtils';
import {
  getReviewCategories,
  getReviewSpec,
  getReviewSpecTypes,
  updateReviewSpec,
} from '../../../../webservices/vehicleSpecsApi';
import SpecsFilters from './components/SpecsFilters';
import SpecsReviewTable from './SpecsReviewTable';

const SpecsReview = ({
  seriesId,
  year,
  version = '',
  vehicleModels,
}: ProductDataControllerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    specsReviewStore,
    teamStore,
    userStore: { brand },
  } = useStores();

  useEffect(() => {
    specsReviewStore.reset();

    (async () => {
      try {
        const responses = await Promise.all([
          getReviewCategories(brand, teamStore.team.param, seriesId, year, version),
          getReviewSpecTypes(brand, teamStore.team.param, seriesId, year, version),
          getReviewSpec(brand, teamStore.team.param, seriesId, year, version),
        ]);

        specsReviewStore.categories = refItemsXForm(responses[0].data);
        specsReviewStore.specTypes = refItemsXForm(responses[1].data);
        const { map } = specsReviewXForm(
          responses[2].data,
          vehicleModels,
          specsReviewStore.categories,
          specsReviewStore.specTypes
        );
        specsReviewStore.map = map;
      } catch {
        toast.error('Error loading Specs review data');
      }
      setIsLoaded(true);
    })();
  }, [specsReviewStore, brand, seriesId, teamStore, version, year, vehicleModels]);

  const handleOnMapApplyChange = async (map: SpecsChangeTypeMap, value: boolean) => {
    map.isApplied = value;
    saveMap(map);
  };

  const handleOnMapNotesChange = async (map: SpecsChangeTypeMap, value: string) => {
    map.rejectNotes = value;
    saveMap(map);
  };

  const saveMap = async (map: SpecsChangeTypeMap) => {
    try {
      if (specsReviewStore.isMapValid(map, teamStore.team.param)) {
        uiBlock.start();
        const response = await updateReviewSpec(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version,
          specsReviewStore.getMapPayload(map, teamStore.team.param)
        );
        map.revId = response.data.revId;
        toast.success('Successfully updated spec');
      } else {
        toast.error('Please fill in the required fields for the spec');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating spec');
    }

    uiBlock.stop();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly
          searchText={specsReviewStore.searchText}
          onSearchTextChange={text => (specsReviewStore.searchText = text)}
          toggleViewModelCodes={() =>
            (specsReviewStore.viewModelCodes = !specsReviewStore.viewModelCodes)
          }
          viewModelCodes={specsReviewStore.viewModelCodes}
          buttons={[]}
          renderFilter={onClose => (
            <SpecsFilters
              onClose={onClose}
              categories={filterDuplicates(specsReviewStore.categories).map(item => item.value)}
              categoryFilters={specsReviewStore.categoryFilters}
              setCategoryFilters={categoryFilters =>
                (specsReviewStore.categoryFilters = categoryFilters)
              }
              specTypes={filterDuplicates(specsReviewStore.specTypes).map(item => item.value)}
              specTypesFilters={specsReviewStore.specTypeFilters}
              setSpecTypesFilters={specFilters => (specsReviewStore.specTypeFilters = specFilters)}
              isInProgressFilter={specsReviewStore.isInProgressFilter}
              setIsInProgressFilter={value => (specsReviewStore.isInProgressFilter = value)}
              brand={brand}
              isReviewPage={true}
            />
          )}
        />
      </ActionBar>
      <SpecsReviewTable
        vehicleModels={vehicleModels}
        handleOnApplyChange={handleOnMapApplyChange}
        handleOnNotesChange={handleOnMapNotesChange}
      />
    </>
  );
};

export default observer(SpecsReview);
