import React, { useContext } from 'react';
import { KeyFeatureContext } from '.';
import useKeyFeature from '../../hooks/useKeyFeature';
import IconTextButton from '../IconTextButton';
import styles from './KeyFeature.module.scss';
import KeyFeatureListItem from './KeyFeatureListItem';

const KeyFeatureDialogBody = () => {
  const { keyFeatureList, onSplit } = useKeyFeature();
  const { readOnly } = useContext(KeyFeatureContext);

  return (
    <main className={styles.body} data-testid="key-feature-dialog-body">
      <div data-testid="key-feature-dialog-body-container">
        <ol className={styles.keyFeatureList} data-testid="key-feature-dialog-body-container-list">
          {keyFeatureList.map(keyFeature => (
            <KeyFeatureListItem key={keyFeature.uid} keyFeature={keyFeature} />
          ))}
        </ol>
        {!readOnly && (
          <IconTextButton
            smallIcon
            icon="plus"
            text="Split Key Feature"
            onClick={onSplit}
            data-testid="key-feature-dialog-body-container-split-cta"
          />
        )}
      </div>
    </main>
  );
};

export default KeyFeatureDialogBody;
