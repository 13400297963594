import React, { useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabColumn,
  FilterTabColumns,
  FilterTabFooter,
} from 'vapi-ui-common';
import Checkbox from '../../../../../../components/Checkbox';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';

type Props = {
  isReviewNotesFilter: boolean;
  setIsReviewNotesFilter: (value: boolean) => void;
  isSyncUpdateFilter?: boolean;
  setIsSyncUpdateFilter?: (value: boolean) => void;
  onClose: () => void;
  isPublished?: boolean;
};
const ModelsFilters: React.FC<Props> = ({
  isReviewNotesFilter,
  setIsReviewNotesFilter,
  isSyncUpdateFilter,
  setIsSyncUpdateFilter,
  onClose,
  isPublished,
}) => {
  const [isReviewNotes, setIsReviewNotes] = useState(isReviewNotesFilter);
  const [isSyncUpdate, setIsSyncUpdate] = useState(isSyncUpdateFilter || false);

  const {
    teamStore: {
      team: { name },
    },
  } = useStores();

  const applyFilters = () => {
    setIsReviewNotesFilter(isReviewNotes);
    setIsSyncUpdateFilter && setIsSyncUpdateFilter(isSyncUpdate);
    onClose();
  };

  return (
    <FilterTab>
      <FilterTabColumns>
        <FilterTabColumn>
          <Checkbox
            id="Review Notes"
            defaultChecked={isReviewNotes}
            onChange={e => setIsReviewNotes(e.currentTarget.checked)}
          >
            Review Notes
          </Checkbox>
        </FilterTabColumn>
        {VehicleTeam.AGENCY_SPANISH === name && setIsSyncUpdateFilter && !isPublished && (
          <FilterTabColumn>
            <Checkbox
              id="SyncUpdates"
              checked={isSyncUpdate}
              onChange={e => setIsSyncUpdate(e.currentTarget.checked)}
            >
              Sync Updates
            </Checkbox>
          </FilterTabColumn>
        )}
      </FilterTabColumns>
      <FilterTabFooter>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </FilterTabFooter>
    </FilterTab>
  );
};

export default ModelsFilters;
