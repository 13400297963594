import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import Checkbox from '../../../../components/Checkbox';
import MultiLimitedData from '../../../../components/MultiLimitedData';
import { ChecklistItem } from '../../../../hooks/useChecklist';
import useStores from '../../../../hooks/useStores';
import { BRAND_TDPR } from '../../../../models/user.model';
import useFieldStatusIntColor from '../../tabModules/colors/hooks/useFieldStatusIntColor';
import ModelList from './ModelList';
import styles from './addEditInteriorColorModal.module.scss';

interface IGradeListItemProps {
  handleModelApplicability: () => void;
  selectItem: (item: ChecklistItem, selected: boolean) => void;
  grade: ChecklistItem;
  selectedGrade: string;
  setSelectedGrade: React.Dispatch<React.SetStateAction<string>>;
}

const GradeListItem = ({
  handleModelApplicability,
  selectItem,
  grade,
  selectedGrade,
  setSelectedGrade,
}: IGradeListItemProps) => {
  const {
    teamStore,
    userStore: { brand },
    colorsStore: { fieldStatus },
  } = useStores();

  const { getMultiLimitedDataStatuses, onEditGrade } = useFieldStatusIntColor({
    gradeId: grade.id,
  });

  const onClick = useCallback(
    () => setSelectedGrade(grade.name === selectedGrade ? '' : grade.name),
    [grade, selectedGrade, setSelectedGrade]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      selectItem(grade, e.currentTarget.checked);
      handleModelApplicability();
      onEditGrade(grade);
    },
    [grade, selectItem, handleModelApplicability, onEditGrade]
  );

  const showGrade = (grade: ChecklistItem) => {
    if (!grade.items?.length) {
      return false;
    }

    return brand !== BRAND_TDPR || !!grade.items?.find(model => !model.hide);
  };

  if (!showGrade(grade)) return null;

  const displayLimitedDataStatus =
    teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';

  const isSelected = selectedGrade === grade.name;
  const statusesSummary = getMultiLimitedDataStatuses();

  return (
    <div
      key={grade.name}
      className={cx(styles.treeMenu, {
        [styles.treeMenuLimitedDataStatus]: displayLimitedDataStatus,
      })}
    >
      <div
        onClick={onClick}
        className={cx(styles.down, {
          [styles.downLimitedDataStatus]: displayLimitedDataStatus,
          [styles.up]: isSelected,
        })}
      >
        <div
          className={cx({
            [styles.menuItemsLimitedDataStatus]: displayLimitedDataStatus,
            [styles.menuItemsLimitedDataStatusListItem]: displayLimitedDataStatus,
          })}
          onClick={e => e.stopPropagation()}
        >
          <Checkbox
            id={`chboxGrd${grade.name}`}
            className={cx({
              [styles.menuItemsLimitedDataStatusListItem]: displayLimitedDataStatus,
            })}
            checked={grade.selected}
            onChange={onChange}
          >
            {grade.name}
          </Checkbox>
          <MultiLimitedData
            className={cx({
              [styles.menuItemsMultiLimitedData]: displayLimitedDataStatus,
            })}
            hideLimitedDataStatus={!isSelected || !displayLimitedDataStatus || !fieldStatus.id}
            statuses={statusesSummary.statuses}
            hideTotal={statusesSummary.hideTotal}
          />
        </div>
      </div>

      <ModelList
        grade={grade}
        selectedGrade={selectedGrade}
        handleModelApplicability={handleModelApplicability}
        selectItem={selectItem}
      />
    </div>
  );
};

export default observer(GradeListItem);
