import cx from 'clsx';
import React from 'react';
import { SideMenuIcon } from 'vapi-ui-common';
import { logout } from '../../authConfig';
import CollapsedFooterMenu from './CollapsedFooterMenu';
import styles from './sideMenu.module.scss';

export const testVDActive = (pathname: string, team: string) => {
  return pathname.includes('vehicleData') && pathname.includes(team);
};

export type SideMenuFooterProps = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
};

const SideMenuFooter = ({ collapsed, setCollapsed }: SideMenuFooterProps) => {
  const handleOnCollapsed = () => {
    const val = !collapsed;
    setCollapsed(val);
    localStorage.setItem('sidemenu.collapsed', val ? 'true' : '');
  };

  return (
    <footer
      className={cx(
        {
          [styles.listContainer]: collapsed,
          [styles.collapsed]: collapsed,
        },
        styles.footer
      )}
      data-testid="side-menu-footer"
    >
      <CollapsedFooterMenu collapsed={collapsed} />

      <div className={cx(styles.footerContainer)}>
        <SideMenuIcon title="Logout" icon="logout" onClick={() => logout()}>
          {!collapsed && 'Logout'}
        </SideMenuIcon>
      </div>

      <section className={cx(styles.collapseToggle, collapsed && styles.collapsed)}>
        <SideMenuIcon
          icon={collapsed ? 'expandNavArrow' : 'collapseNavArrow'}
          onClick={() => handleOnCollapsed()}
        />
      </section>
    </footer>
  );
};

export default SideMenuFooter;
