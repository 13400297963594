import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import Checkbox from '../../../../components/Checkbox';
import { LimitedDataStatus } from '../../../../components/LimitedDataStatus';
import { ChecklistItem } from '../../../../hooks/useChecklist';
import useStores from '../../../../hooks/useStores';
import useFieldStatusIntColor from '../../tabModules/colors/hooks/useFieldStatusIntColor';
import styles from './addEditInteriorColorModal.module.scss';

interface IModelListItemProps {
  handleModelApplicability: () => void;
  selectItem: (item: ChecklistItem, selected: boolean) => void;
  grade: ChecklistItem;
  model: ChecklistItem;
}

const ModelListItem = ({
  handleModelApplicability,
  selectItem,
  grade,
  model,
}: IModelListItemProps) => {
  const {
    teamStore,
    colorsStore: { fieldStatus },
  } = useStores();

  const {
    onClickModel: onClickLimitedDataStatus,
    onEditModel: onEditLimitedDataStatus,
  } = useFieldStatusIntColor({
    gradeId: grade.id,
    modelId: model.id,
  });

  const onChange = useCallback(
    e => {
      selectItem(model, e.currentTarget.checked);
      handleModelApplicability();
      onEditLimitedDataStatus();
    },
    [selectItem, handleModelApplicability, onEditLimitedDataStatus, model]
  );

  const displayLimitedDataStatus =
    teamStore.team.showLimitedData &&
    process.env.REACT_APP_LIMITED_DATA === 'true' &&
    fieldStatus?.modelApplicability?.[grade.id];

  return (
    <div
      className={cx(styles.menuItems, {
        [styles.menuItemsLimitedDataStatus]: displayLimitedDataStatus,
      })}
    >
      <Checkbox
        id={`chbox${model.name}`}
        className={cx({
          [styles.menuItemsLimitedDataStatusListItem]: displayLimitedDataStatus,
        })}
        checked={model.selected}
        onChange={onChange}
      >
        {model.name}
      </Checkbox>
      <LimitedDataStatus
        hideLimitedDataStatus={!displayLimitedDataStatus || !fieldStatus.id}
        status={fieldStatus?.modelApplicability?.[grade.id]?.[model.id]}
        onClick={onClickLimitedDataStatus}
      />
    </div>
  );
};

export default observer(ModelListItem);
