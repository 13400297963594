import cx from 'clsx';
import React from 'react';
import IconTextButton from '../../../../../../components/IconTextButton';
import {
  CommonLanguageItem,
  CommonLanguageVehicle,
} from '../../../../../../models/commonLanguage.model';
import styles from './vehiclesModalButton.module.scss';

interface VehiclesModalButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  vehicleModels: CommonLanguageVehicle[];
  commonLanguageItem: CommonLanguageItem;
  readOnly?: boolean;
}

const VehiclesModalButton = ({
  commonLanguageItem: item,
  vehicleModels,
  readOnly,
  ...rest
}: VehiclesModalButtonProps) => {
  const renderDisplay = () => {
    const vehicleModelsCount = vehicleModels ? vehicleModels.length : 0;

    const allOrNoneSelected =
      !item.vehicles || !item.vehicles.length || item.vehicles.length === vehicleModelsCount;

    if (!readOnly && allOrNoneSelected) {
      return <IconTextButton smallIcon icon="plus" text="Model(s)" />;
    } else if (item.vehicles.length === 1) {
      return (
        <>
          <div className={styles.modelYear}>{item.vehicles[0].modelYear}</div>
          <div>{item.vehicles[0].seriesName}</div>
        </>
      );
    } else if (item.vehicles.length > 1) {
      return `(${item.vehicles.length}) Vehicles`;
    }
    return undefined;
  };

  return (
    <div
      className={cx({
        [styles.vehicleModalBtn]: true,
        [styles.disabled]: readOnly,
      })}
      {...rest}
    >
      {renderDisplay()}
    </div>
  );
};

export default VehiclesModalButton;
