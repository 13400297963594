import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Brand } from '../models/user.model';
import { VehicleDataVersionInfo, VehicleTeam } from '../models/vehicleData.model';
import {
  getFuelTypeIdByNameUtil,
  getFuelTypeNameByIdUtil,
  getSeriesIdByNameUtil,
  getSeriesNameByIdUtil,
} from '../utils/seriesSettingsUtils';
import { filterVersionInfoByLang } from '../utils/vehicleDataUtils';
import useStores from './useStores';

const useSeriesSettings = (
  brand: string,
  seriesId: string,
  param: VehicleTeam,
  year: string,
  versionInfo: VehicleDataVersionInfo,
  includeDeleted: boolean = false
) => {
  const { seriesSettingsStore, teamStore } = useStores();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(false);
    seriesSettingsStore.reset();

    // need to change this for TDPR
    const languages = teamStore.team.seriesSettingsLanguages;
    const filteredVersionInfo = filterVersionInfoByLang(languages, versionInfo);
    (async () => {
      try {
        await seriesSettingsStore.fetchData(
          brand as Brand,
          param,
          seriesId,
          year,
          teamStore.team.defaultSeriesSettingsLanguage,
          teamStore.team.seriesSettingsLanguages,
          filteredVersionInfo
        );
        setIsLoaded(true);
      } catch (e) {
        toast.error('Error loading series settings data');
        console.log(e.message);
        setIsLoaded(true);
      }
    })();
  }, [brand, seriesSettingsStore, seriesId, param, year, versionInfo, teamStore]);

  const getSeriesNameById = (id: string) => getSeriesNameByIdUtil(getSettings(), id);

  const getSeriesIdByName = (name: string) => getSeriesIdByNameUtil(getSettings(), name);

  const getSettings = () =>
    !includeDeleted
      ? seriesSettingsStore.getDefaultSeriesSettings(seriesSettingsStore.seriesSettingsLangMaps)
      : [
          ...seriesSettingsStore.getDefaultSeriesSettings(
            seriesSettingsStore.seriesSettingsLangMaps
          ),
          ...seriesSettingsStore.getDefaultSeriesSettings(
            seriesSettingsStore.deletedSeriesSettingsLangMaps
          ),
        ];

  const getFuelTypeNameById = (id: string) => {
    return getFuelTypeNameByIdUtil(seriesSettingsStore.fuelTypesList, id);
  };

  const getFuelTypeIdByName = (name: string) => {
    return getFuelTypeIdByNameUtil(seriesSettingsStore.fuelTypesList, name);
  };

  return {
    getSeriesNameById,
    getSeriesIdByName,
    isLoaded,
    getFuelTypeIdByName,
    getFuelTypeNameById,
    setIsLoaded,
  };
};

export default useSeriesSettings;
