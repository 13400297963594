import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { toast } from 'react-toastify';
import { Input } from 'vapi-ui-common';
import { TableRowBase } from '../../../../components/Table/components/TableRow/TableRow';
import { ColorFamilyItem } from '../../../../models/colorFamilies.model';
import styles from './colorFamiliesRow.module.scss';

interface IColorFamilyRow {
  colorFamily: ColorFamilyItem;
  deleteColorItem: (color: ColorFamilyItem) => void;
  saveColorFamilyItem: (color: ColorFamilyItem) => void;
  readOnly: boolean;
}

const ColorFamiliesRow = ({
  readOnly,
  colorFamily,
  deleteColorItem,
  saveColorFamilyItem,
}: IColorFamilyRow) => {
  const handleOnNameChange = (color: ColorFamilyItem, name: string) => {
    if (name !== color.name) {
      color.name = name;
      saveColorFamilyItem(color);
    }
  };

  const handleOnHexCodeChange = (color: ColorFamilyItem, hexCode: string) => {
    if (hexCode !== color.hexCode) {
      color.hexCode = hexCode;
      saveColorFamilyItem(color);
    }
  };

  return (
    <TableRowBase zebra className={styles.colorFamilyRow}>
      <div className={styles.row}>
        <div>
          <Input
            id={`color-name-${colorFamily.uid}`}
            defaultValue={colorFamily.name ? colorFamily.name : ''}
            onBlur={e => handleOnNameChange(colorFamily, e.currentTarget.value.trim())}
            className={styles.input}
            readOnly={readOnly}
            required
          />
        </div>
        <div>
          <Input
            id={`hex-code-${colorFamily.uid}`}
            defaultValue={colorFamily.hexCode ? colorFamily.hexCode : ''}
            onBlur={e => handleOnHexCodeChange(colorFamily, e.currentTarget.value.trim())}
            className={styles.input}
            readOnly={readOnly}
            required
          />
        </div>

        {!readOnly && (
          <div className={styles.actionButtons}>
            <div className={styles.deleteIcon}>
              <FontAwesomeIcon
                className={styles.edit}
                icon={faTrash}
                onClick={() => {
                  if (colorFamily.canDelete()) {
                    deleteColorItem(colorFamily);
                  } else {
                    toast.error(
                      'Colors assigned to color family.  Please unassign colors to delete this color family.'
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </TableRowBase>
  );
};

export default observer(ColorFamiliesRow);
