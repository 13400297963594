import { useState } from 'react';

const useInputValidation = (validations: ((value: string) => string)[]) => {
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const validate = (value: string) => {
    const messages: string[] = [];

    validations.forEach(validation => {
      const msg = validation(value);
      if (msg) {
        messages.push(validation(value));
      }
    });
    setErrorMessages(messages);
    setIsInvalid(!!messages.length);
  };

  return {
    isInvalid,
    validate,
    errorMessages,
  };
};

export default useInputValidation;
