import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import styles from './spanishDisclaimersTable.module.scss';

interface SpanishDisclaimersTableProps {
  renderRows: () => ReactNode;
}

const SpanishDisclaimersTable = observer(({
  renderRows,
}: SpanishDisclaimersTableProps) => {

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <td></td>
          <td>Version</td>
          <td>ES</td>
          <td>EN</td>
          <td></td>
        </tr>
      </thead>
      <tbody className={styles.body}>{renderRows()}</tbody>
    </table>
  );
});

export default SpanishDisclaimersTable;
