import { observer } from 'mobx-react-lite';
import React from 'react';
import { HeaderCell, HeaderRow, TableRow, Thead, TwoTableWrapper } from 'vapi-ui-common';
import {
  ChangeLogArray,
  ChangeLogBeforeAfter,
} from '../../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import useStores from '../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../models/vehicleData.model';
import LeftTable from '../../../components/LeftTable';
import AddedDeletedCell from '../../../components/tableCells/AddedDeletedCell';
import { ApplyCell } from '../../../components/tableCells/ApplyCell';
import VDTableCell from '../../../components/tableCells/VDTableCell';
import styles from '../seriesSettings.module.scss';

const SeriesSettingsReviewTable = () => {
  const { teamStore, seriesSettingsReviewStore } = useStores();

  return (
    <TwoTableWrapper>
      <LeftTable>
        <Thead>
          <HeaderRow style={{ height: '81' }}>
            <HeaderCell>Apply & Accept?</HeaderCell>
            <HeaderCell>Code Red?</HeaderCell>
            <HeaderCell>Series Name</HeaderCell>
            <HeaderCell>Fuel Type</HeaderCell>
            <HeaderCell>Series Category</HeaderCell>
            <HeaderCell>Seating</HeaderCell>
            <HeaderCell>Estimated Mileage</HeaderCell>
            <HeaderCell>Mpge</HeaderCell>
            <HeaderCell>Range</HeaderCell>
            <HeaderCell>Added/Deleted?</HeaderCell>
          </HeaderRow>
        </Thead>
        <tbody>
          {seriesSettingsReviewStore.filteredReviewItems.map(reviewMap => {
            return (
              <TableRow key={reviewMap.id} zebra>
                {/* apply */}
                <ApplyCell
                  id={reviewMap.id}
                  applyChecked={reviewMap.isApplied}
                  onApplyChecked={isApplied => {}}
                  notes={reviewMap.rejectNotes}
                  handleOnNotesChange={notes => {}}
                  hideRejectNotes={teamStore.team.param === VehicleTeam.AGENCY_TEAM}
                  disabled
                />
                {/* Code Red */}
                <VDTableCell colType="dropdown">
                  <Checkbox
                    id={`donotpublish-${reviewMap.id}`}
                    className={styles.isPublishableChbox}
                    disabled
                    defaultChecked={!reviewMap.isPublishable}
                  />
                </VDTableCell>
                {/* Series Name */}
                <VDTableCell colType="dropdown">
                  <ChangeLogBeforeAfter
                    before={reviewMap.name.before}
                    after={reviewMap.name.after}
                    styleAfter={reviewMap.name.hasChanged}
                  />
                </VDTableCell>
                {/* Fuel Type */}
                <VDTableCell colType="dropdown">
                  <ChangeLogArray
                    before={reviewMap.fuelTypes.before}
                    after={reviewMap.fuelTypes.after}
                    hasChanged={reviewMap.fuelTypes.hasChanged}
                  />
                </VDTableCell>
                {/* Series Category */}
                <VDTableCell colType="dropdown">
                  <ChangeLogArray
                    before={reviewMap.seriesCategories.before}
                    after={reviewMap.seriesCategories.after}
                    hasChanged={reviewMap.seriesCategories.hasChanged}
                  />
                </VDTableCell>
                {/* Seating */}
                <VDTableCell colType="dropdown">
                  <ChangeLogBeforeAfter
                    before={reviewMap.seating.before}
                    after={reviewMap.seating.after}
                    styleAfter={reviewMap.seating.hasChanged}
                  />
                </VDTableCell>
                {/* Estimated Mileage */}
                <VDTableCell colType="dropdown">
                  <ChangeLogBeforeAfter
                    before={reviewMap.estimatedMileage.before}
                    after={reviewMap.estimatedMileage.after}
                    styleAfter={reviewMap.estimatedMileage.hasChanged}
                  />
                </VDTableCell>
                {/* Mpge */}
                <VDTableCell colType="dropdown">
                  <ChangeLogBeforeAfter
                    before={reviewMap.mpge.before}
                    after={reviewMap.mpge.after}
                    styleAfter={reviewMap.mpge.hasChanged}
                  />
                </VDTableCell>
                {/* Range */}
                <VDTableCell colType="dropdown">
                  <ChangeLogBeforeAfter
                    before={reviewMap.range.before}
                    after={reviewMap.range.after}
                    styleAfter={reviewMap.range.hasChanged}
                  />
                </VDTableCell>
                <AddedDeletedCell isNew={reviewMap.isNew} isDeleted={reviewMap.isDeleted} />
              </TableRow>
            );
          })}
        </tbody>
      </LeftTable>
    </TwoTableWrapper>
  );
};

export default observer(SeriesSettingsReviewTable);
