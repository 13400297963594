import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Button, DropdownList } from 'vapi-ui-common';
import Header from '../../../../components/Header';
import SecondaryHeader from '../../../../components/SecondaryHeader';
import Spinner from '../../../../components/Spinner';
import { TabPanel } from '../../../../components/Tabs/Tabs';
import Wayfinding from '../../../../components/Wayfinding';
import useQuery from '../../../../hooks/useQuery';
import useStores from '../../../../hooks/useStores';
import { VDTab } from '../../../../models/vehicleData.model';
import BnPChangeLog from '../../../../routes/vehicleData/tabModules/bp/BnPChangeLog';
import ColorsChangeLogController from '../../../../routes/vehicleData/tabModules/colors/ColorsChangeLog';
import ColorsLexusChangeLogController from '../../../../routes/vehicleData/tabModules/colors/lexus/ColorsLexusChangeLog';
import CompareFeaturesChangeLog from '../../../../routes/vehicleData/tabModules/compareFeatures/CompareFeaturesChangeLog';
import FeaturesChangeLog from '../../../../routes/vehicleData/tabModules/features/FeaturesChangeLog';
import OptionsChangeLog from '../../../../routes/vehicleData/tabModules/options/OptionsChangeLog';
import OptionsLexusChangeLog from '../../../../routes/vehicleData/tabModules/optionsLexus/OptionsLexusChangeLog';
import SpecsChangeLog from '../../../../routes/vehicleData/tabModules/specs/SpecsChangeLog';
import ModelsChangeLog from '../../tabModules/models/ModelsChangeLog';
import styles from './ChangeLog.module.scss';
import useSeries from './hooks/useSeries';
import useVersions from './hooks/useVersions';

const ChangeLog = () => {
  const { vehicleModelsStore, teamStore } = useStores();

  const { team, seriesId, year, versionInfo } = useParams<{
    team: string;
    seriesId: string;
    year: string;
    versionInfo: string;
  }>();

  const history = useHistory();
  const location = useLocation();

  const query = useQuery();
  const tab = query.get('tab') || 'Features';

  const returnParam = query.get('return');

  const [selectedTab, setSelectedTab] = useState(decodeURIComponent(tab));
  const [readOnly, setReadOnly] = useState(true);

  let exitChangeLog = '';
  const tabs = teamStore.team.tabs;

  if (returnParam === 'published' && versionInfo) {
    exitChangeLog = `/vehicleData/${returnParam}/${team}/${seriesId}/${year}/${versionInfo}?team=${team}&tab=${encodeURIComponent(
      selectedTab
    )}`;
  } else if (returnParam === 'draft' && versionInfo) {
    exitChangeLog = `/vehicleData/${returnParam}/${team}/${seriesId}/${year}/${versionInfo}?team=${team}&tab=${encodeURIComponent(
      selectedTab
    )}`;
  }

  const { seriesInfo, isLoaded } = useSeries({ setReadOnly });
  const { logs, isVersionLoaded, currentVersionInfo, currentLog, onVersionChange } = useVersions({
    setReadOnly,
  });

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle={teamStore.team.pageTitle} moduleSubTitle="Change Log" />
      <Wayfinding year={year} seriesName={seriesInfo.name} to={exitChangeLog} />
      <SecondaryHeader
        tabs={
          selectedTab === VDTab.COMPARE_FEATURES
            ? [VDTab.COMPARE_FEATURES]
            : tabs.filter(tab => tab.name !== VDTab.SERIES_SETTINGS).map(tab => tab.name)
        }
        selectedTab={selectedTab}
        setSelectedTab={tab => {
          setSelectedTab(tab);
          history.push(
            `${location.pathname}?team=${team}&tab=${encodeURIComponent(tab)}&return=${returnParam}`
          );
        }}
        renderButtons={() => (
          <>
            <NavLink to={exitChangeLog}>
              <Button variant="transparent">Exit Change Log</Button>
            </NavLink>
            <DropdownList
              className={styles.dropdownList}
              value={currentLog}
              list={logs}
              onSelect={onVersionChange}
            />
          </>
        )}
      />

      {!isVersionLoaded ? (
        <Spinner />
      ) : (
        <>
          <TabPanel tab={VDTab.MODELS} selected={selectedTab === VDTab.MODELS}>
            <ModelsChangeLog
              seriesId={seriesId}
              year={year}
              version={currentLog}
              versionInfo={currentVersionInfo}
              readOnly={readOnly || !isNaN(Number(currentLog))}
            />
          </TabPanel>
          <TabPanel tab={VDTab.FEATURES} selected={selectedTab === VDTab.FEATURES}>
            <FeaturesChangeLog
              seriesId={seriesId}
              year={year}
              version={currentLog}
              readOnly={readOnly || !isNaN(Number(currentLog))}
            />
          </TabPanel>
          <TabPanel tab={VDTab.COMPARE_FEATURES} selected={selectedTab === VDTab.COMPARE_FEATURES}>
            <CompareFeaturesChangeLog
              seriesId={seriesId}
              year={year}
              version={currentLog}
              readOnly={readOnly || !isNaN(Number(currentLog))}
            />
          </TabPanel>
          <TabPanel tab={VDTab.OPTIONS} selected={selectedTab === VDTab.OPTIONS}>
            {teamStore.team.useOptionsPackages ? (
              <OptionsLexusChangeLog
                seriesId={seriesId}
                year={year}
                version={currentLog}
                readOnly={readOnly || !isNaN(Number(currentLog))}
                vehicleModels={vehicleModelsStore.vehicleModels}
              />
            ) : (
              <OptionsChangeLog
                seriesId={seriesId}
                year={year}
                version={currentLog}
                readOnly={readOnly || !isNaN(Number(currentLog))}
              />
            )}
          </TabPanel>
          <TabPanel tab={VDTab.SPECS} selected={selectedTab === VDTab.SPECS}>
            <SpecsChangeLog
              seriesId={seriesId}
              year={year}
              version={currentLog}
              readOnly={readOnly || !isNaN(Number(currentLog))}
            />
          </TabPanel>
          {teamStore.team.allowBnP && (
            <TabPanel tab={VDTab.BNP} selected={selectedTab === VDTab.BNP}>
              <BnPChangeLog
                seriesId={seriesId}
                year={year}
                version={currentLog}
                readOnly={readOnly || !isNaN(Number(currentLog))}
              />
            </TabPanel>
          )}
          <TabPanel tab={VDTab.COLORS} selected={selectedTab === VDTab.COLORS}>
            {teamStore.team.useColorsGroups ? (
              <ColorsLexusChangeLogController
                seriesId={seriesId}
                year={year}
                version={currentLog}
                readOnly={readOnly || !isNaN(Number(currentLog))}
                vehicleModels={vehicleModelsStore.vehicleModels}
              />
            ) : (
              <ColorsChangeLogController
                seriesId={seriesId}
                year={year}
                version={currentLog}
                readOnly={readOnly || !isNaN(Number(currentLog))}
              />
            )}
          </TabPanel>
        </>
      )}
    </>
  );
};

export default observer(ChangeLog);
