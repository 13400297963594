import React from 'react';
import styles from './header.module.scss';

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  moduleTitle: string;
  moduleSubTitle: string;
  versionNumber?: any;
}

const Header = (props: HeaderProps) => {
  const { children, moduleTitle, moduleSubTitle, versionNumber } = props;

  return (
    <header className={styles.header}>
      <section className={styles.headerSection} data-testid="header-comp">
        <h2 className={styles.title}>
          <span className={styles.moduleTitle}>
            {moduleTitle !== '' ? `${moduleTitle} > ` : ''}
          </span>
          <span className={styles.moduleSubtitle}>{moduleSubTitle}</span>
          <span>{versionNumber ? `: V${versionNumber}` : ''}</span>
        </h2>
      </section>
      <section className={styles.headerSection}>{children}</section>
    </header>
  );
};

export default Header;
