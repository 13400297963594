import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import Checkbox from '../../../../../../components/Checkbox';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { HeaderButton, HeaderRow, LeftTableTH, Thead } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';
import FeatureLimitedDataStatusHeader from './FeatureLimitedDataStatusHeader';
import styles from './featureHeaderRow.module.scss';

type FeatureHeaderRowProps = {
  showDescriptionToggles?: boolean;
  viewModelCodes: boolean;
  allowLinks: boolean;
  showSpecLinkModal: boolean;
  switchShortLongDescription?: boolean;
  readOnly?: boolean;
  onSort?: (field: string, lang?: string) => void;
  sortMode: boolean;
  languages: string[];
  setLastUpdated: React.Dispatch<React.SetStateAction<Date>>;
};

const FeatureHeaderRow: React.FC<FeatureHeaderRowProps> = ({
  showDescriptionToggles,
  allowLinks,
  showSpecLinkModal,
  switchShortLongDescription,
  readOnly = false,
  onSort = () => {},
  sortMode,
  languages,
  setLastUpdated,
}) => {
  const {
    featuresStore,
    tableSizeStore: { tableRowHeight },
    teamStore,
  } = useStores();

  const sortByDesc = useCallback(() => onSort('description'), [onSort]);

  const getDescriptionHeaders = () => {
    if (!languages.length) {
      return <HeaderButton onClick={sortByDesc}>Description</HeaderButton>;
    }
    return languages.map(lang => {
      return (
        <LeftTableTH
          key={`${lang}-desc`}
          style={{ padding: 0 }}
          onClick={sortByDesc}
        >{`${lang} Description`}</LeftTableTH>
      );
    });
  };

  const displayLimitedDataStatus =
    teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';

  return (
    <Thead>
      <HeaderRow
        style={{
          height: `${tableRowHeight}px`,
        }}
      >
        {featuresStore.hasChangedAttributes() && teamStore.team.showAcceptChanges && (
          <LeftTableTH className={styles.category}>Accept Changes</LeftTableTH>
        )}
        {!readOnly && teamStore.team.name !== VehicleTeam.AGENCY_SPANISH && (
          <LeftTableTH></LeftTableTH>
        )}
        {sortMode && <LeftTableTH className={inputStyles.smallInputHeader}>#</LeftTableTH>}
        <LeftTableTH className={styles.category} onClick={() => onSort('category.value')}>
          Category
        </LeftTableTH>
        <LeftTableTH className={styles.category} onClick={() => onSort('subCategory.value')}>
          Sub Category
        </LeftTableTH>
        {allowLinks && <LeftTableTH></LeftTableTH>}
        {showSpecLinkModal && !sortMode && <LeftTableTH></LeftTableTH>}
        {getDescriptionHeaders()}
        <LeftTableTH className={styles.description} colSpan={4}>
          <FeatureLimitedDataStatusHeader
            hideLimitedDataStatus={!displayLimitedDataStatus}
            readOnly={readOnly}
            setLastUpdated={setLastUpdated}
          />
          {!displayLimitedDataStatus && (
            <span style={{ whiteSpace: 'nowrap' }}>
              {showDescriptionToggles && !displayLimitedDataStatus && (
                <>
                  <Checkbox
                    id={`requiredDescriptionChbox`}
                    className={styles.checkbox}
                    checked={featuresStore.showRequiredDescription}
                    onChange={e =>
                      (featuresStore.showRequiredDescription = e.currentTarget.checked)
                    }
                  >
                    <span className={styles.checkboxLabel}>
                      {switchShortLongDescription ? 'Short' : 'Long'}
                    </span>
                  </Checkbox>
                  <Checkbox
                    id={`optionalDescriptionChbox`}
                    className={styles.checkbox}
                    checked={featuresStore.showOptionalDescription}
                    onChange={e =>
                      (featuresStore.showOptionalDescription = e.currentTarget.checked)
                    }
                  >
                    <span className={styles.checkboxLabel}>
                      {switchShortLongDescription ? 'Long' : 'Short'}
                    </span>
                  </Checkbox>
                </>
              )}
            </span>
          )}
        </LeftTableTH>
      </HeaderRow>
    </Thead>
  );
};

export default observer(FeatureHeaderRow);
