import { TableCell } from '../../../../../../../components/Table';
import { observer } from 'mobx-react-lite';
import { ChangeLogTypes } from '../../../../../../../models/changeLog.model';
import { OptionsPackage, OptionsPackageSpec, OptionsTabType } from '../../../../../../../models/optionsLexus.model';
import { ReviewChangeBaseItem } from '../../../../../../../models/review.model';
import React from 'react';
import { AcceptApplyCell } from '../../../../../../../routes/vehicleData/components/tableCells/AcceptApplyCell';
import { SuggestionType } from 'vapi-ui-common';
import OptionsLexusReviewChanges from '../../OptionsLexusChanges';
import PackageTableRow from '../../PackageTableRow';
import OptionsLexusPackageNameColumn from '../components/OptionsLexusPackageNameColumn';
import OptionsLexusPackageSpecFillerCell from '../components/OptionsLexusPackageSpecFillerCell';
import styles from '../optionsLexusPackageRow.module.scss';
import OptionsLexusPackageSpecReviewCells from './OptionsLexusPackageSpecReviewCells';

interface OptionsLexusPackageReviewRowProps {
  optionsPackage: OptionsPackage;
  optionsTab: OptionsTabType;
  spec?: OptionsPackageSpec;
  change?: ReviewChangeBaseItem;
  nameSpan: number;
  requiredSpan: number;
  tokens?: SuggestionType[];
  allowTokens?: boolean;
  zebra?: boolean;
  expandRow?: boolean;
  hideAccept?: boolean;
  onUpdate?(option: OptionsPackage, spec: OptionsPackageSpec, change: ReviewChangeBaseItem): void;
}

const OptionsLexusPackageReviewRow = ({
  optionsPackage,
  spec,
  change,
  optionsTab,
  nameSpan = 1,
  requiredSpan = 1,
  tokens,
  allowTokens,
  zebra,
  expandRow,
  hideAccept,
  onUpdate = () => void 0,
}: OptionsLexusPackageReviewRowProps) => {
  const disabled = true;
  const specItem = spec ? spec : optionsPackage.getSpecChanges()[0];
  const changeItem = change ? change : specItem ? specItem.changes[0] : undefined;

  const isDelete =
    changeItem &&
    [ChangeLogTypes.PACKAGE_SPEC_DELETED, ChangeLogTypes.OPTION_SPEC_DELETED].includes(
      changeItem.changes.changeType
    );

  return (
    <>
      <PackageTableRow
        className={styles.row}
        zebra={zebra && !isDelete}
        isDelete={isDelete}
        expandRow={expandRow}
        onFillRowHeightChange={height => {
          optionsPackage.specVarietyRowHeight = Math.max(
            optionsPackage.specVarietyRowHeight,
            height
          );
          if (changeItem) {
            changeItem.rowHeight = height;
          }
        }}
      >
        <TableCell colType="dropdown" rowSpan={nameSpan} className={styles.spannedCell} border>
          <OptionsLexusPackageNameColumn
            optionsPackage={optionsPackage}
            optionsTab={optionsTab}
            disabled={disabled}
            tokens={tokens}
          />
        </TableCell>

        {/* Code */}
        <TableCell rowSpan={nameSpan} className={styles.spannedCell} border>
          {optionsPackage.code}
        </TableCell>

        {specItem ? (
          <OptionsLexusPackageSpecReviewCells spec={specItem} />
        ) : (
          <OptionsLexusPackageSpecFillerCell count={4} />
        )}

        {/* Required */}
        <TableCell rowSpan={requiredSpan} className={styles.spannedCell} border>
          {optionsPackage.required}
        </TableCell>

        {/* Conflicts */}
        <TableCell rowSpan={requiredSpan} className={styles.spannedCell} border>
          {optionsPackage.conflicts}
        </TableCell>

        {!changeItem ? (
          <OptionsLexusPackageSpecFillerCell count={3} />
        ) : (
          <>
            <TableCell colType="changes" whiteBg border smallText>
              <OptionsLexusReviewChanges changes={changeItem.changes} />
              {specItem?.otherChanges.map(otherChange => (
                <React.Fragment key={otherChange.uid}>
                  <p />
                  <div>{otherChange.changeTypeId}</div>
                  <OptionsLexusReviewChanges changes={otherChange.changes} />
                </React.Fragment>
              ))}
            </TableCell>

            <AcceptApplyCell
              id={`change-row-${changeItem.uid}`}
              acceptChecked={changeItem.isAccepted}
              applyChecked={changeItem.isApplied}
              notes={changeItem.rejectNotes}
              isNewChange={changeItem.isNewChange}
              onAcceptChecked={
                hideAccept
                  ? undefined
                  : checked => {
                      changeItem.isAccepted = checked;
                      onUpdate(optionsPackage, specItem, changeItem);
                    }
              }
              onApplyChecked={checked => {
                changeItem.isApplied = checked;
                onUpdate(optionsPackage, specItem, changeItem);
              }}
              onNotesChange={notes => {
                changeItem.rejectNotes = notes;
                onUpdate(optionsPackage, specItem, changeItem);
              }}
            />
          </>
        )}
      </PackageTableRow>
    </>
  );
};

export default observer(OptionsLexusPackageReviewRow);
