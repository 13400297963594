import React from 'react';
import { observer } from 'mobx-react-lite';
import { DisclaimersChangeLogItem } from '../../../../models/disclaimers.model';

import { Table, Thead, TableRow, HeaderCell } from '../../../../components/Table';
import DisclaimersChangeLogRow from './ChangeLogRow';
import { REGION_GST } from '../../../../models/user.model';

interface DisclaimersChangeLogTableProps {
  data: DisclaimersChangeLogItem[];
  onSort: (key: string) => void;
  onRevertChange: (item: DisclaimersChangeLogItem) => void;
  region?: string;
}

const DisclaimersChangeLogTable = observer(
  ({ data, onSort, onRevertChange, region }: DisclaimersChangeLogTableProps) => {
    const gstTeam = region === REGION_GST;

    return (
      <Table fullWidth>
        <Thead>
          <TableRow>
            <HeaderCell>Short Code</HeaderCell>
            <HeaderCell>Description</HeaderCell>
            {gstTeam && <HeaderCell>Description Spanish</HeaderCell>}
            <HeaderCell onClick={() => onSort('changeType')}>Change Type</HeaderCell>
            <HeaderCell>Changes</HeaderCell>
            <HeaderCell onClick={() => onSort('modifiedBy')}>Modified By</HeaderCell>
            <HeaderCell onClick={() => onSort('modifiedDate')}>Modified Date</HeaderCell>
            <HeaderCell>Notes</HeaderCell>
            <HeaderCell />
          </TableRow>
        </Thead>
        <tbody>
          {data.map(item => (
            <DisclaimersChangeLogRow
              key={item.uid}
              log={item}
              onRevertChange={onRevertChange}
              region={region}
            />
          ))}
        </tbody>
      </Table>
    );
  }
);

export default DisclaimersChangeLogTable;
