import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, Slide } from 'react-toastify';
import styles from './toast.module.scss';

export const Toast = () => 
  <section className={styles.toast}>
    <ToastContainer
      closeButton={<CloseToastButton />}
      draggable={false}
      position="top-center"
      hideProgressBar
      transition={Slide}
    />
  </section>
;

const CloseToastButton = ({ closeToast }:any ) => (
  <button className={styles.toastClose} onClick={closeToast} />
);

export const CheckMessageToast = ({message}: {message: string}) => {
  return (
    <>
      <FontAwesomeIcon
        style={{ marginRight: "8px" }}
        icon={faCheckCircle}
      />
      {message}
    </>
  );
}; 
