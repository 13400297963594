import { observer } from 'mobx-react-lite';
import React from 'react';
import { ChangeLogBeforeAfter } from '../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import {
  HeaderCell,
  HeaderRow,
  TableRow,
  Thead,
  TwoTableWrapper,
} from '../../../../components/Table';
import useStores from '../../../../hooks/useStores';
import { ExteriorColorsChangeTypeMap } from '../../../../models/colors.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../models/vehicleModel.model';
import LeftTable from '../../components/LeftTable';
import AddedDeletedCell from '../../components/tableCells/AddedDeletedCell/AddedDeletedCell';
import { ApplyCell } from '../../components/tableCells/ApplyCell/ApplyCell';
import FlagsCell from '../../components/tableCells/FlagsCell/FlagsCell';
import VDTableCell from '../../components/tableCells/VDTableCell';
import ColorAccessibilityTable from './components/ColorAccessibilityTable';

interface IColorsExteriorReviewTable {
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  handleOnApplyChange: (item: ExteriorColorsChangeTypeMap, value: boolean) => void;
  handleOnNotesChange: (item: ExteriorColorsChangeTypeMap, value: string) => void;
}

const ColorsExteriorReviewTable = ({
  handleOnApplyChange,
  handleOnNotesChange,
  vehicleModels,
}: IColorsExteriorReviewTable) => {
  const {
    teamStore,
    colorsReviewStore,
    colorsExteriorReviewStore,
    tableSizeStore: { tableRowHeight },
  } = useStores();

  return (
    <TwoTableWrapper>
      <LeftTable>
        <Thead>
          <HeaderRow style={{ height: `${tableRowHeight}px` }}>
            <HeaderCell>Apply & Accept?</HeaderCell>
            <HeaderCell onClick={() => colorsExteriorReviewStore.setSort('code.after')}>
              Code
            </HeaderCell>
            <HeaderCell onClick={() => colorsExteriorReviewStore.setSort('name.after')}>
              Exterior
            </HeaderCell>
            <HeaderCell>MSRP</HeaderCell>
            <HeaderCell>Added/Deleted?</HeaderCell>
            <HeaderCell />
          </HeaderRow>
        </Thead>
        <tbody>
          {colorsExteriorReviewStore.filteredReviewItems.map((reviewMap, index) => (
            <TableRow
              key={`review-row#${index}`}
              onFillRowHeightChange={height => {
                reviewMap.rowHeight = height;
              }}
            >
              <ApplyCell
                id={reviewMap.id}
                applyChecked={reviewMap.isApplied}
                onApplyChecked={isApplied => {
                  handleOnApplyChange(reviewMap, isApplied);
                }}
                notes={reviewMap.rejectNotes}
                handleOnNotesChange={notes => handleOnNotesChange(reviewMap, notes)}
                hideRejectNotes={teamStore.team.param === VehicleTeam.AGENCY_TEAM}
              />
              <VDTableCell colType="dropdown">
                <ChangeLogBeforeAfter
                  before={reviewMap.code.before}
                  after={reviewMap.code.after}
                  styleAfter={reviewMap.code.hasChanged}
                />
              </VDTableCell>
              <VDTableCell colType="dropdown">
                <ChangeLogBeforeAfter
                  before={reviewMap.name.before}
                  after={reviewMap.name.after}
                  styleAfter={reviewMap.name.hasChanged}
                />
              </VDTableCell>
              <VDTableCell colType="dropdown">
                <ChangeLogBeforeAfter
                  before={reviewMap.isExtraCost.before}
                  after={reviewMap.isExtraCost.after}
                  styleAfter={reviewMap.isExtraCost.hasChanged}
                />
              </VDTableCell>
              <AddedDeletedCell isNew={reviewMap.isNew} isDeleted={reviewMap.isDeleted} />
              <FlagsCell
                index={0}
                disabled
                highlighted={false}
                inProgress={false}
                notes={reviewMap.notes}
                onNotesChange={notes => handleOnNotesChange(reviewMap, notes)}
              />
            </TableRow>
          ))}
        </tbody>
      </LeftTable>
      <ColorAccessibilityTable
        gradeItems={colorsReviewStore.colorGradeItems}
        vehicleModels={vehicleModels}
        maps={colorsExteriorReviewStore.filteredReviewItems}
      />
    </TwoTableWrapper>
  );
};

export default observer(ColorsExteriorReviewTable);
