import { observable } from 'mobx';
import {
  BRAND_LEXUS,
  BRAND_TDPR,
  BRAND_TOYOTA,
  Language,
  LanguagePermissions,
} from '../../models/user.model';
import { VDTab, VehicleTeam } from '../../models/vehicleData.model';

type Tab = {
  name: VDTab;
  readOnly?: boolean;
};

type Team = {
  pageTitle: string;
  param: VehicleTeam;
  name: VehicleTeam;
  allowShowSpecLinkModal: boolean;
  tabs: Tab[];
  reviewTabs: Tab[];
  reviewCLTabs: Tab[];
  reviewTeamParam: VehicleTeam;
  reviewTeamTitle: string;
  allowBnP: boolean;
  allowSplits: boolean;
  allowCommonLanguageData: boolean;
  allowDescriptionToggles: boolean;
  allowFeatureTooltips: boolean;
  allowOptionTooltips: boolean;
  allowSpecType: boolean;
  allowLinks: boolean;
  allowHexCode: boolean;
  allowCompareFeatures: boolean;
  allowAddDeleteModels: boolean;
  allowCopyModels: boolean;
  allowDeleteCopiedModels: boolean;
  allowAddDeleteSeries: boolean;
  allowEditSereisName: boolean;
  allowDisclaimerTokens: boolean;
  allowPublish: boolean;
  allowSubmit: boolean;
  allowCodeRed: boolean;
  allowEditGoLiveDate: boolean;
  allowPreviewPublishSeries: boolean;
  allowInProgressFilter: boolean;
  allowAddStandardSpecs: boolean;
  switchShortLongDescription: boolean;
  useOptionsPackages: boolean;
  useColorsGroups: boolean;
  useNewDashboardStatus: boolean;
  useLargeSpecApplicability: boolean;
  isHexCodeRequired: boolean;
  allowDisclaimerTokensInGradeApplicability: boolean;
  allowGradeApplicability: boolean;
  languages: Language[];
  defaultSeriesSettingsLanguage: Language;
  seriesSettingsLanguages: Language[];
  canAddNewSeriesSettings: boolean;
  langPermissions: LanguagePermissions;
  allowAddDeleteData: boolean;
  showModelDetailsForPublish: boolean;
  defaultLanguage: Language;
  changeLogLanguages: Language[];
  submitActionLabel?: 'Submit' | 'Certify';
  canAddFromDropdown: boolean;
  canSyncUpdates: boolean;
  showAcceptChanges?: boolean;
  showLimitedData?: boolean;
  showKeyFeature?: boolean;
  allowAddModelYear: boolean;
  showTimePicker?: boolean;
};

export const ProductTeamToyota: Team = {
  pageTitle: 'Product Team',
  param: VehicleTeam.PRODUCT_TEAM,
  name: VehicleTeam.PRODUCT_TEAM,
  allowShowSpecLinkModal: true,
  tabs: [
    { name: VDTab.SERIES_SETTINGS },
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    { name: VDTab.BNP, readOnly: true },
    { name: VDTab.COLORS },
    { name: VDTab.COMPARE_FEATURES },
  ],
  reviewTabs: [
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    { name: VDTab.BNP },
    { name: VDTab.COLORS },
    { name: VDTab.COMPARE_FEATURES },
  ],
  reviewCLTabs: [{ name: VDTab.FEATURES }, { name: VDTab.SPECS }],
  reviewTeamParam: VehicleTeam.AGENCY_TEAM,
  reviewTeamTitle: 'Agency',
  allowBnP: true,
  allowSplits: false,
  allowCompareFeatures: true,
  allowCommonLanguageData: true,
  allowDescriptionToggles: false,
  allowFeatureTooltips: false,
  allowOptionTooltips: false,
  allowSpecType: true,
  allowLinks: false,
  allowHexCode: false,
  allowAddDeleteModels: true,
  allowCopyModels: true,
  allowDeleteCopiedModels: true,
  allowAddDeleteSeries: true,
  allowEditSereisName: true,
  allowDisclaimerTokens: false,
  allowPublish: true,
  allowSubmit: false,
  allowCodeRed: true,
  allowEditGoLiveDate: true,
  allowPreviewPublishSeries: false,
  allowInProgressFilter: false,
  allowAddStandardSpecs: true,
  switchShortLongDescription: false,
  useOptionsPackages: false,
  useColorsGroups: false,
  useLargeSpecApplicability: false,
  useNewDashboardStatus: false,
  isHexCodeRequired: false,
  allowDisclaimerTokensInGradeApplicability: false,
  allowGradeApplicability: false,
  languages: [Language.EN],
  defaultSeriesSettingsLanguage: Language.EN,
  seriesSettingsLanguages: [Language.EN],
  canAddNewSeriesSettings: true,
  langPermissions: {},
  allowAddDeleteData: true,
  showModelDetailsForPublish: false,
  defaultLanguage: Language.EN,
  changeLogLanguages: [Language.EN],
  canAddFromDropdown: true,
  canSyncUpdates: false,
  showKeyFeature: true,
  allowAddModelYear: true,
  showTimePicker: true,
};

export const AgencyTeamToyota: Team = {
  pageTitle: 'Agency',
  param: VehicleTeam.AGENCY_TEAM,
  name: VehicleTeam.AGENCY_TEAM,
  allowShowSpecLinkModal: false,
  tabs: [
    { name: VDTab.SERIES_SETTINGS, readOnly: true },
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    { name: VDTab.BNP },
    { name: VDTab.COLORS },
    { name: VDTab.COMPARE_FEATURES },
  ],
  reviewTabs: [
    { name: VDTab.SERIES_SETTINGS },
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    { name: VDTab.BNP },
    { name: VDTab.COLORS },
    { name: VDTab.COMPARE_FEATURES },
  ],
  reviewCLTabs: [],
  reviewTeamParam: VehicleTeam.PRODUCT_TEAM,
  reviewTeamTitle: 'Product Team',
  allowBnP: true,
  allowSplits: true,
  allowCompareFeatures: true,
  allowCommonLanguageData: false,
  allowDescriptionToggles: true,
  allowFeatureTooltips: false,
  allowOptionTooltips: false,
  allowSpecType: true,
  allowLinks: true,
  allowHexCode: true,
  allowAddDeleteModels: false,
  allowCopyModels: false,
  allowDeleteCopiedModels: false,
  allowAddDeleteSeries: true,
  allowEditSereisName: false,
  allowDisclaimerTokens: true,
  allowPublish: false,
  allowSubmit: true,
  allowCodeRed: false,
  allowEditGoLiveDate: true,
  allowPreviewPublishSeries: true,
  allowInProgressFilter: true,
  allowAddStandardSpecs: true,
  switchShortLongDescription: false,
  useOptionsPackages: false,
  useLargeSpecApplicability: false,
  useColorsGroups: false,
  useNewDashboardStatus: true,
  isHexCodeRequired: true,
  allowDisclaimerTokensInGradeApplicability: false,
  allowGradeApplicability: false,
  languages: [Language.EN],
  defaultSeriesSettingsLanguage: Language.EN,
  seriesSettingsLanguages: [Language.EN],
  canAddNewSeriesSettings: false,
  langPermissions: {},
  allowAddDeleteData: true,
  showModelDetailsForPublish: true,
  defaultLanguage: Language.EN,
  changeLogLanguages: [Language.EN],
  submitActionLabel: 'Submit',
  canAddFromDropdown: true,
  canSyncUpdates: false,
  showLimitedData: true,
  showKeyFeature: true,
  allowAddModelYear: false,
  showTimePicker: true,
};

export const ProductTeamLexus: Team = {
  pageTitle: 'Product Team',
  param: VehicleTeam.PRODUCT_TEAM,
  name: VehicleTeam.PRODUCT_TEAM,
  allowShowSpecLinkModal: true,
  tabs: [
    { name: VDTab.SERIES_SETTINGS },
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    { name: VDTab.COLORS },
  ],
  reviewTabs: [
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    { name: VDTab.COLORS },
  ],
  reviewCLTabs: [{ name: VDTab.FEATURES }, { name: VDTab.SPECS }],
  reviewTeamParam: VehicleTeam.AGENCY_TEAM,
  reviewTeamTitle: 'Agency',
  allowBnP: false,
  allowSplits: false,
  allowCompareFeatures: false,
  allowCommonLanguageData: true,
  allowDescriptionToggles: false,
  allowFeatureTooltips: false,
  allowOptionTooltips: false,
  allowSpecType: false,
  allowLinks: false,
  allowHexCode: false,
  allowAddDeleteModels: true,
  allowCopyModels: true,
  allowDeleteCopiedModels: true,
  allowAddDeleteSeries: true,
  allowEditSereisName: false,
  allowDisclaimerTokens: false,
  allowPublish: true,
  allowSubmit: false,
  allowCodeRed: true,
  allowEditGoLiveDate: false,
  allowPreviewPublishSeries: false,
  allowInProgressFilter: false,
  allowAddStandardSpecs: true,
  switchShortLongDescription: true,
  useOptionsPackages: true,
  useColorsGroups: true,
  useLargeSpecApplicability: true,
  useNewDashboardStatus: false,
  isHexCodeRequired: false,
  allowDisclaimerTokensInGradeApplicability: false,
  allowGradeApplicability: true,
  languages: [Language.EN],
  defaultSeriesSettingsLanguage: Language.EN,
  seriesSettingsLanguages: [Language.EN],
  canAddNewSeriesSettings: true,
  langPermissions: {},
  allowAddDeleteData: true,
  showModelDetailsForPublish: false,
  defaultLanguage: Language.EN,
  changeLogLanguages: [Language.EN],
  canAddFromDropdown: true,
  canSyncUpdates: false,
  allowAddModelYear: true,
};

export const AgencyTeamLexus: Team = {
  pageTitle: 'Agency',
  param: VehicleTeam.AGENCY_TEAM,
  name: VehicleTeam.AGENCY_TEAM,
  allowShowSpecLinkModal: true,
  tabs: [
    { name: VDTab.SERIES_SETTINGS },
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    { name: VDTab.COLORS },
  ],
  reviewTabs: [
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    { name: VDTab.COLORS },
  ],
  reviewCLTabs: [{ name: VDTab.FEATURES }, { name: VDTab.SPECS }],
  reviewTeamParam: VehicleTeam.PRODUCT_TEAM,
  reviewTeamTitle: 'Product Team',
  allowBnP: false,
  allowSplits: true,
  allowSpecType: false,
  allowCompareFeatures: false,
  allowCommonLanguageData: true,
  allowDescriptionToggles: true,
  allowFeatureTooltips: true,
  allowOptionTooltips: true,
  allowLinks: true,
  allowHexCode: true,
  allowAddDeleteModels: false,
  allowCopyModels: false,
  allowDeleteCopiedModels: false,
  allowAddDeleteSeries: true,
  allowEditSereisName: true,
  allowDisclaimerTokens: true,
  allowPublish: false,
  allowSubmit: true,
  allowCodeRed: false,
  allowEditGoLiveDate: false,
  allowPreviewPublishSeries: true,
  allowInProgressFilter: true,
  allowAddStandardSpecs: true,
  switchShortLongDescription: true,
  useOptionsPackages: true,
  useLargeSpecApplicability: true,
  useColorsGroups: true,
  useNewDashboardStatus: true,
  isHexCodeRequired: false,
  allowDisclaimerTokensInGradeApplicability: true,
  allowGradeApplicability: true,
  languages: [Language.EN],
  defaultSeriesSettingsLanguage: Language.EN,
  seriesSettingsLanguages: [Language.EN],
  canAddNewSeriesSettings: true,
  langPermissions: {},
  allowAddDeleteData: true,
  showModelDetailsForPublish: false,
  defaultLanguage: Language.EN,
  changeLogLanguages: [Language.EN],
  canAddFromDropdown: true,
  canSyncUpdates: false,
  allowAddModelYear: true,
  submitActionLabel: 'Certify',
};

export const AgencySpanish: Team = {
  ...AgencyTeamToyota,
  pageTitle: 'TMNA Spanish',
  name: VehicleTeam.AGENCY_SPANISH,
  allowAddDeleteSeries: false,
  languages: [Language.EN, Language.ES],
  defaultSeriesSettingsLanguage: Language.ES,
  seriesSettingsLanguages: [Language.ES],
  canAddNewSeriesSettings: false,
  langPermissions: {},
  allowAddDeleteData: false,
  allowEditGoLiveDate: false,
  allowInProgressFilter: true,
  allowAddStandardSpecs: false,
  showModelDetailsForPublish: true,
  defaultLanguage: Language.ES,
  changeLogLanguages: [Language.ES],
  submitActionLabel: 'Certify',
  canSyncUpdates: true,
  showAcceptChanges: true,
  showLimitedData: false,
} as Team;

export const AgencyTeamTdpr: Team = {
  pageTitle: 'TdPR Agency Team',
  param: VehicleTeam.AGENCY_TEAM,
  name: VehicleTeam.AGENCY_TEAM,
  allowShowSpecLinkModal: false,
  tabs: [
    { name: VDTab.SERIES_SETTINGS },
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    { name: VDTab.COLORS },
  ],
  reviewTabs: [],
  reviewCLTabs: [],
  reviewTeamParam: VehicleTeam.PRODUCT_TEAM,
  reviewTeamTitle: 'Product Team',
  allowBnP: false,
  allowSplits: false,
  allowCompareFeatures: false,
  allowCommonLanguageData: false,
  allowDescriptionToggles: false,
  allowFeatureTooltips: false,
  allowOptionTooltips: false,
  allowSpecType: true,
  allowLinks: true,
  allowHexCode: true,
  allowAddDeleteModels: false,
  allowCopyModels: true,
  allowDeleteCopiedModels: true,
  allowAddDeleteSeries: false,
  allowEditSereisName: false,
  allowDisclaimerTokens: false,
  allowPublish: false,
  allowSubmit: true,
  allowCodeRed: false,
  allowEditGoLiveDate: true,
  allowPreviewPublishSeries: true,
  allowInProgressFilter: true,
  allowAddStandardSpecs: false,
  switchShortLongDescription: false,
  useOptionsPackages: false,
  useLargeSpecApplicability: false,
  useColorsGroups: false,
  useNewDashboardStatus: true,
  isHexCodeRequired: true,
  allowDisclaimerTokensInGradeApplicability: false,
  allowGradeApplicability: false,
  languages: [Language.EN, Language.ES],
  defaultSeriesSettingsLanguage: Language.EN,
  seriesSettingsLanguages: [Language.EN, Language.ES],
  canAddNewSeriesSettings: false,
  langPermissions: {},
  allowAddDeleteData: true,
  showModelDetailsForPublish: true,
  defaultLanguage: Language.EN,
  changeLogLanguages: [Language.EN, Language.ES],
  submitActionLabel: 'Certify',
  canAddFromDropdown: true,
  canSyncUpdates: true,
  allowAddModelYear: false,
} as Team;

const getProductTeamByBrand = (brand: string) => {
  if (brand === BRAND_LEXUS) {
    return ProductTeamLexus;
  }
  return ProductTeamToyota;
};

const getAgencyTeamByBrand = (brand: string) => {
  if (brand === BRAND_LEXUS) {
    return AgencyTeamLexus;
  } else if (brand === BRAND_TDPR) {
    return AgencyTeamTdpr;
  }
  return AgencyTeamToyota;
};

export class TeamStore {
  @observable team = {} as Team;

  setTeam = (team: VehicleTeam, brand: string, langPermissions: LanguagePermissions) => {
    if (brand === BRAND_TOYOTA && team === VehicleTeam.AGENCY_SPANISH) {
      this.team = AgencySpanish;
    } else if (team === VehicleTeam.PRODUCT_TEAM) {
      this.team = getProductTeamByBrand(brand);
    } else {
      this.team = getAgencyTeamByBrand(brand);
    }

    // update langPermissions
    this.team.languages.forEach(language => {
      this.team.langPermissions[language] = langPermissions[language];
    });
    // reset EN for AgencySpanish
    if (team === VehicleTeam.AGENCY_SPANISH) {
      this.team.langPermissions[Language.EN] = { canView: true };
    }
  };
}
