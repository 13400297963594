import { action, observable } from 'mobx';
import { Status } from '../gql/generated';
import {
  CFExteriorColorItem,
  CFInteriorColorItem,
  ColorFamilyItem,
} from '../models/colorFamilies.model';
import { RefItem, RefItemObject, RefItemResponse } from '../models/refItem.model';
import { VDColorFamiliesTab } from '../models/vehicleData.model';
import { sortBy, toLowerCase } from '../utils';
import { colorFamilyXform } from '../utils/colorUtils';
import { toGqlBrand } from '../utils/graphqlUtils';
import { refItemsXForm } from '../utils/refItemUtils';
import {
  getCFExteriorColors,
  getCFInteriorColors,
  getColorFamilies,
  getMaterials,
} from '../webservices/vehicleColorsApi';

class ColorFamiliesStore {
  searchText = '';
  @observable tabs = [
    VDColorFamiliesTab.COLOR_FAMILIES,
    VDColorFamiliesTab.INTERIOR,
    VDColorFamiliesTab.EXTERIOR,
  ];
  @observable selectedTab = VDColorFamiliesTab.COLOR_FAMILIES;
  @observable colorFamilies: ColorFamilyItem[] = [];
  @observable materials: RefItem[] = [];
  @observable interiorColors: CFInteriorColorItem[] = [];
  @observable exteriorColors: CFExteriorColorItem[] = [];
  @observable filteredColorFamilies: ColorFamilyItem[] = [];
  @observable filteredInteriorColors: CFInteriorColorItem[] = [];
  @observable filteredExteriorColors: CFExteriorColorItem[] = [];

  @action fetchData = async (brand: string) => {
    const [
      colorFamiliesResponse,
      materialsResponse,
      interiorColorsResponse,
      exteriorColorsResponse,
    ] = await Promise.all([
      getColorFamilies({ brand: toGqlBrand(brand), filter: { status: Status.Draft } }),
      getMaterials({ brand: toGqlBrand(brand) }),
      getCFInteriorColors({ brand: toGqlBrand(brand), filter: { status: Status.Draft } }),
      getCFExteriorColors({ brand: toGqlBrand(brand), filter: { status: Status.Draft } }),
    ]);
    const { colorFamilies, materials, interiorColors, exteriorColors } = colorFamilyXform(
      colorFamiliesResponse,
      materialsResponse,
      interiorColorsResponse,
      exteriorColorsResponse
    );

    this.colorFamilies = colorFamilies;
    this.materials = materials;
    this.interiorColors = interiorColors;
    this.exteriorColors = exteriorColors;
    this.filteredColorFamilies = colorFamilies;
    this.filteredInteriorColors = interiorColors;
    this.filteredExteriorColors = exteriorColors;
  };

  addItem = () => {
    const newItem = new ColorFamilyItem();
    this.colorFamilies = [newItem, ...this.colorFamilies];
    this.filteredColorFamilies = [newItem, ...this.filteredColorFamilies];
  };

  addInteriorItem = () => {
    const newItem = new CFInteriorColorItem();
    this.interiorColors = [newItem, ...this.interiorColors];
    this.filteredInteriorColors = [newItem, ...this.filteredInteriorColors];
  };

  addExteriorItem = () => {
    const newItem = new CFExteriorColorItem();
    this.exteriorColors = [newItem, ...this.exteriorColors];
    this.filteredExteriorColors = [newItem, ...this.filteredExteriorColors];
  };

  deleteItem = (colorFamilyItem: ColorFamilyItem) => {
    this.colorFamilies = this.colorFamilies.filter(item => item.uid !== colorFamilyItem.uid);
    this.filteredColorFamilies = this.filteredColorFamilies.filter(
      item => item.uid !== colorFamilyItem.uid
    );
  };

  deleteInteriorItem = (interiorColorItem: CFInteriorColorItem) => {
    this.interiorColors = this.interiorColors.filter(item => item.uid !== interiorColorItem.uid);
    this.filteredInteriorColors = this.filteredInteriorColors.filter(
      item => item.uid !== interiorColorItem.uid
    );
  };

  deleteExteriorItem = (exteriorColorItem: CFExteriorColorItem) => {
    this.exteriorColors = this.exteriorColors.filter(item => item.uid !== exteriorColorItem.uid);
    this.filteredExteriorColors = this.filteredExteriorColors.filter(
      item => item.uid !== exteriorColorItem.uid
    );
  };

  setMaterials = (response: RefItemResponse<RefItemObject>[]) => {
    this.materials = refItemsXForm(response);
  };

  onFilter = (filterAction: () => void, tab: string) => {
    filterAction();
    if (tab === VDColorFamiliesTab.COLOR_FAMILIES) {
      this.filteredColorFamilies = this.filterColorFamilies();
    } else if (tab === VDColorFamiliesTab.INTERIOR) {
      this.filteredInteriorColors = this.filterInteriorColors();
    } else if (tab === VDColorFamiliesTab.EXTERIOR) {
      this.filteredExteriorColors = this.filterExteriorColors();
    }
  };

  @action resetFilters = () => {
    this.searchText = '';
    this.filteredColorFamilies = this.colorFamilies;
    this.filteredInteriorColors = this.interiorColors;
    this.filteredExteriorColors = this.exteriorColors;
  };

  filterColorFamilies = () => {
    const sorted = this.colorFamilies.slice();
    const lowerSearchText = toLowerCase(this.searchText);
    return sorted.filter(
      color =>
        !lowerSearchText ||
        toLowerCase(color.name).includes(lowerSearchText) ||
        toLowerCase(color.hexCode).includes(lowerSearchText)
    );
  };

  filterInteriorColors = () => {
    const sorted = this.interiorColors.slice();
    const lowerSearchText = toLowerCase(this.searchText);
    return sorted.filter(
      color =>
        !lowerSearchText ||
        toLowerCase(color.name).includes(lowerSearchText) ||
        toLowerCase(color.code).includes(lowerSearchText) ||
        color.colorFamilies.map(item => toLowerCase(item.name)).includes(lowerSearchText) ||
        toLowerCase(color.material.value).includes(lowerSearchText)
    );
  };

  filterExteriorColors = () => {
    const sorted = this.exteriorColors.slice();
    const lowerSearchText = toLowerCase(this.searchText);
    return sorted.filter(
      color =>
        !lowerSearchText ||
        toLowerCase(color.name).includes(lowerSearchText) ||
        toLowerCase(color.code).includes(lowerSearchText) ||
        toLowerCase(color.hexCode).includes(lowerSearchText) ||
        color.colorFamilies.map(item => toLowerCase(item.name)).includes(lowerSearchText)
    );
  };

  onSortColorFamilies = (field: string, isReverse: boolean) => {
    this.filteredColorFamilies = this.filteredColorFamilies.sort(sortBy(field, isReverse));
  };

  onSortInteriorColors = (field: string, isReverse: boolean) => {
    this.filteredInteriorColors = this.filteredInteriorColors.sort(sortBy(field, isReverse));
  };

  onSortExteriorColors = (field: string, isReverse: boolean) => {
    this.filteredExteriorColors = this.filteredExteriorColors.sort(sortBy(field, isReverse));
  };

  @action reset() {
    this.colorFamilies = [];
    this.interiorColors = [];
    this.materials = [];
    this.exteriorColors = [];
    this.selectedTab = VDColorFamiliesTab.COLOR_FAMILIES;
    this.searchText = '';
  }
}

export default ColorFamiliesStore;
