import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input } from 'vapi-ui-common';
import useStores from '../../../../../../../../hooks/useStores';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../../models/vehicleModel.model';
import styles from '../../../../modelsModal.module.scss';
import Label from '../Label';

interface EngineProps {
  vmId: string;
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  children?: JSX.Element | JSX.Element[];
}

const Engine = observer(({ vmId, model, children }: EngineProps) => {
  const {
    vehicleModelsStore: { defaultLanguage },
    modelTabStore: {
      languages: checkboxLanguages,
      readOnly,
      getEngine,
      setEngine,
      getFuelType,
      getLangVehicleModel,
    },
    userStore: { langPermissions },
    teamStore: {
      team: { languages: teamLanguages },
    },
  } = useStores();

  return (
    <div className={styles.column}>
      <div className={styles.label}>
        <Label htmlFor={`amm-engine-${vmId}`}>
          {getFuelType(vmId, defaultLanguage)?.value === 'Battery Electric'
            ? 'Motor/Engine'
            : 'Engine'}
        </Label>
      </div>
      <div className={cx(styles.content)}>
        {teamLanguages.map(language => {
          if (checkboxLanguages[language].selected && langPermissions[language]?.canView) {
            let changedAttributes: string[] = [];
            if (langPermissions[language]?.canEdit) {
              changedAttributes =
                getLangVehicleModel(model, language).getVal('changedAttributes') ?? [];
            }
            return (
              <Input
                required
                id={`amm-engine-${vmId}-${language}`}
                key={`amm-engine-${vmId}-${language}`}
                value={getEngine(vmId, language)}
                disabled={!langPermissions[language]?.canEdit || readOnly}
                onChange={e => setEngine(vmId, language, e.currentTarget.value)}
                placeholder={checkboxLanguages[language].description}
                className={cx(
                  { [styles.errorText]: changedAttributes.includes('engine') },
                  styles.input
                )}
              />
            );
          }

          return null;
        })}
      </div>
      {children}
    </div>
  );
});

export default Engine;
