import React, { useCallback } from 'react';
import { GoLiveTimeChangeLog } from '../../../../../components/ChangeLogTable/ChangeLogChanges';
import ChangeLogChanges, {
  ChangeLogBeforeAfter,
} from '../../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import { LIMITED_DATA_STATUS } from '../../../../../constants/vehicleData/VDConstants';
import useStores from '../../../../../hooks/useStores';
import {
  ChangeLogModelItem,
  ChangeLogTypes,
  SYNCED_CHANGE_TYPES_MAP,
} from '../../../../../models/changeLog.model';

interface UseChangeLogChangesProps {
  getSeriesNameById: (id: string) => string;
  getFuelTypeNameById: (id: string) => string;
}

const useChangeLogChanges = ({
  getSeriesNameById,
  getFuelTypeNameById,
}: UseChangeLogChangesProps) => {
  const {
    teamStore: {
      team: { changeLogLanguages },
    },
  } = useStores();

  const convertLogIdToName = (log: ChangeLogModelItem): ChangeLogModelItem => {
    if (log.changeType === ChangeLogTypes.SERIES_SETTING) {
      log.before = getSeriesNameById(log.before);
      log.after = getSeriesNameById(log.after);
    } else if (log.changeType === ChangeLogTypes.FUEL_TYPE) {
      log.before = getFuelTypeNameById(log.before);
      log.after = getFuelTypeNameById(log.after);
    }
    return log;
  };

  const limitedDataStatusToString = (status: number) => {
    switch (status) {
      case LIMITED_DATA_STATUS.READY_TO_PUBLISH:
        return 'Ready to Publish';
      case LIMITED_DATA_STATUS.IN_PROGRESS:
        return 'In Progress';
      default:
        return '';
    }
  };

  const limitedDataStatusChangeLogChanges = (log: ChangeLogModelItem) => {
    const before = Number(log.before);
    const after = Number(log.after);

    switch (after) {
      case LIMITED_DATA_STATUS.READY_TO_PUBLISH:
      case LIMITED_DATA_STATUS.IN_PROGRESS:
        return (
          <ChangeLogBeforeAfter
            before={limitedDataStatusToString(before)}
            after={limitedDataStatusToString(after)}
            styleAfter
            inline
          />
        );
    }
  };

  const filterChangeLogChanges = useCallback(
    (logs: ChangeLogModelItem[]) =>
      logs.filter(log => {
        switch (log.changeType) {
          case ChangeLogTypes.ALL_MODEL_STATUS:
          case ChangeLogTypes.TRIM_TITLE_STATUS:
          case ChangeLogTypes.GRADE_STATUS:
          case ChangeLogTypes.DRIVE_STATUS:
          case ChangeLogTypes.CAB_STATUS:
          case ChangeLogTypes.BED_STATUS:
          case ChangeLogTypes.DESCRIPTION_STATUS:
          case ChangeLogTypes.HORSEPOWER_STATUS:
          case ChangeLogTypes.TRANSMISSION_STATUS:
          case ChangeLogTypes.ENGINE_STATUS:
          case ChangeLogTypes.SEATING_STATUS:
            if (Number(log.after) === LIMITED_DATA_STATUS.PUBLISHED) return false;
            return true;
          default:
            return true;
        }
      }),
    []
  );

  const changeLogChanges = (log: ChangeLogModelItem) => {
    switch (log.changeType) {
      case ChangeLogTypes.ALL_MODEL_STATUS:
      case ChangeLogTypes.TRIM_TITLE_STATUS:
      case ChangeLogTypes.GRADE_STATUS:
      case ChangeLogTypes.DRIVE_STATUS:
      case ChangeLogTypes.CAB_STATUS:
      case ChangeLogTypes.BED_STATUS:
      case ChangeLogTypes.DESCRIPTION_STATUS:
      case ChangeLogTypes.HORSEPOWER_STATUS:
      case ChangeLogTypes.TRANSMISSION_STATUS:
      case ChangeLogTypes.ENGINE_STATUS:
      case ChangeLogTypes.SEATING_STATUS:
        return limitedDataStatusChangeLogChanges(log);
      case ChangeLogTypes.GO_LIVE_DATE:
        return <GoLiveTimeChangeLog log={log} />;
      default:
        return <ChangeLogChanges changeItem={convertLogIdToName(log)} />;
    }
  };

  const canRevert = (log: ChangeLogModelItem) => {
    switch (log.changeType) {
      case ChangeLogTypes.ALL_MODEL_STATUS:
      case ChangeLogTypes.TRIM_TITLE_STATUS:
      case ChangeLogTypes.GRADE_STATUS:
      case ChangeLogTypes.DRIVE_STATUS:
      case ChangeLogTypes.CAB_STATUS:
      case ChangeLogTypes.BED_STATUS:
      case ChangeLogTypes.DESCRIPTION_STATUS:
      case ChangeLogTypes.HORSEPOWER_STATUS:
      case ChangeLogTypes.TRANSMISSION_STATUS:
      case ChangeLogTypes.ENGINE_STATUS:
      case ChangeLogTypes.SEATING_STATUS:
      case ChangeLogTypes.IS_NOT_PUBLISHABLE: {
        return false;
      }
      default: {
        if (changeLogLanguages.length > 1 && SYNCED_CHANGE_TYPES_MAP[log.changeType]) {
          return false;
        }
        return log.canRevert;
      }
    }
  };

  return { filterChangeLogChanges, changeLogChanges, canRevert };
};

export default useChangeLogChanges;
