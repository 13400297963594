import cx from 'clsx';
import React, { useContext, useMemo } from 'react';
import { KeyFeatureContext } from '.';
import useStores from '../../hooks/useStores';
import { KeyFeatureType } from '../../models/features.model';
import styles from './KeyFeature.module.scss';

interface Props {
  keyFeature: KeyFeatureType;
}

const KeyFeatureListItemReadOnly = ({ keyFeature }: Props) => {
  const { readOnly, keyFeaturesMap } = useContext(KeyFeatureContext);
  const {
    featuresStore: { allLangs, selectedLangsMap },
    teamStore: {
      team: { defaultLanguage },
    },
  } = useStores();

  const translations = useMemo(() => {
    const selectedLangs = allLangs.filter(lang => selectedLangsMap[lang]);
    return selectedLangs.filter(lang => lang !== defaultLanguage);
  }, [allLangs, selectedLangsMap, defaultLanguage]);

  if (!readOnly) return null;

  return (
    <div
      className={cx(styles.readOnly, styles.statusMessage, {
        [styles.hasChanges]: keyFeature.hasChanges,
      })}
      data-testid={`key-feature-list-item-read-only-${keyFeature.uid}`}
    >
      <div
        className={cx(styles.readOnlyDefaultLanguage)}
        data-testid={`key-feature-list-item-read-only-${defaultLanguage}-${keyFeature.uid}`}
      >
        {keyFeaturesMap[keyFeature.id ?? '']?.name[defaultLanguage] ?? keyFeature.description}
      </div>

      {translations.map(lang => (
        <div
          className={cx(styles[`readOnly${lang}`])}
          data-testid={`key-feature-list-item-read-only-${lang}-${keyFeature.uid}`}
          key={`key-feature-list-item-read-only-${lang}-${keyFeature.uid}`}
        >
          {keyFeaturesMap[keyFeature.id ?? '']?.name[lang] ?? keyFeature.description}
        </div>
      ))}
    </div>
  );
};

export default KeyFeatureListItemReadOnly;
