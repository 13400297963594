import React from 'react';
import { TableRow } from '../../../../../components/Table';
import { OptionsPackageSpec } from '../../../../../models/optionsLexus.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../models/vehicleModel.model';
import SettingsCell from '../../../../../routes/vehicleData/components/ModelTable/components/SettingsCell';

/**
 * Used to render readonly spec applicabillty rows
 */
export const renderSpecApplicabilityReview = (
  specs: OptionsPackageSpec[],
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => (
  <>
    {specs.map(spec => (
      <TableRow key={spec.uid} rowHeight={spec.rowHeight}>
        {vehicleModels.map(
          (model, index) =>
            model.show &&
            spec.models[index] && (
              <SettingsCell
                key={`${spec.uid}${model.uid}`}
                disabled
                model={spec.models[index]}
                oddRow={false}
                rowSpan={1}
                onUpdate={() => void 0}
              />
            )
        )}
      </TableRow>
    ))}
  </>
);
