import { ChangeLogChanges } from '../../../../components/ChangeLogTable';
import IconTextButton from '../../../../components/IconTextButton';
import Notes from '../../../../components/Notes';
import Popover from '../../../../components/Popover';
import { DescriptionText, TableCell, TableRow } from '../../../../components/Table';
import { CommonLanguageChangeLogItem } from '../../../../models/commonLanguage.model';
import React, { useState } from 'react';
import { DateComponent, Modal, MultiLineRTEditor } from 'vapi-ui-common';
import RevertChangeModal from '../CommonLanguageTable/components/RevertChangeModal';
import styles from './changelogTableCommon.module.scss';

interface CommonLanguageChangeLogRowProps {
  log: CommonLanguageChangeLogItem;
  onRevertChange: (log: CommonLanguageChangeLogItem) => void;
}

const CommonLanguageChangeLogRow = ({ log, onRevertChange }: CommonLanguageChangeLogRowProps) => {
  const [openRevertModal, setOpenRevertModal] = useState(false);

  const shortId = log.id?.substring(log.id.length - 4);

  return (
    <TableRow zebra key={log.uid}>
      {/* ID */}
      <TableCell large className={styles.id}>
        {log.id && <div>{shortId}</div>}
      </TableCell>
      <TableCell large className={styles.description}>
        <MultiLineRTEditor value={log.description} disabled />
      </TableCell>
      <TableCell large className={styles.changeType}>
        {log.changeType}
      </TableCell>
      <TableCell large className={styles.changes}>
        <DescriptionText size="lg">
          <ChangeLogChanges changeItem={log} />
        </DescriptionText>
      </TableCell>
      <TableCell large className={styles.modifiedBy}>
        {log.modifiedBy}
      </TableCell>
      <TableCell large className={styles.modifiedDate}>
        <DateComponent format="MM/DD/YYYY hh:mm:ss A">{log.modifiedDate}</DateComponent>
      </TableCell>
      <TableCell large className={styles.notes}>
        {log.notes && (
          <Popover
            toggleElement={<IconTextButton smallIcon icon="circle" text="Notes" />}
            popoverElement={<Notes readOnly notes={log.notes} />}
            align="right"
          />
        )}
      </TableCell>
      <TableCell large className={styles.canRevert}>
        {log.canRevert && (
          <IconTextButton icon="undo" text="Revert" onClick={() => setOpenRevertModal(true)} />
        )}
        <Modal open={openRevertModal} onClose={() => setOpenRevertModal(false)}>
          <RevertChangeModal
            onRevertChange={onRevertChange}
            close={() => setOpenRevertModal(false)}
            changeLogItem={log}
          />
        </Modal>
      </TableCell>
    </TableRow>
  );
};

export default CommonLanguageChangeLogRow;
