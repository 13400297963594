import React from 'react';
import { convertToRichTextObject } from 'vapi-ui-common';
import { IDValueType, KeyValueType } from '../models/common.model';

export const toLowerCase = (text?: string) => {
  return !text ? '' : text.toLowerCase();
};

export const isSortReverse = (oldField: string, newField: string, isReverse: boolean) => {
  return oldField !== newField ? false : !isReverse;
};

export const removeNulls = (obj: any) => {
  for (var key in obj) {
    if (obj[key] === null) {
      obj[key] = '';
    }
  }

  return obj;
};

export const sortBy = (prop: any, reverse?: boolean, isDate?: boolean) => {
  return function (a: any, b: any) {
    const aProp = dotToObject(a, prop);
    const bProp = dotToObject(b, prop);

    let aItem, bItem;

    if (prop === 'vehicles') {
      const aStr = aProp.vehicles
        ? `${aProp.vehicles[0].modelYear}${aProp.vehicles[0].seriesId}`
        : '';
      const bStr = bProp.vehicles
        ? `${bProp.vehicles[0].modelYear}${bProp.vehicles[0].seriesId}`
        : '';
      return aStr < bStr ? -1 : aStr > bStr ? 1 : 0;
    } else if (isDate) {
      aItem = Math.round(new Date(aProp).getTime() / 1000);
      bItem = Math.round(new Date(bProp).getTime() / 1000);
    } else if (typeof aProp === 'string' && typeof bProp === 'string') {
      const aText = convertToRichTextObject(aProp);
      const bText = convertToRichTextObject(bProp);
      aItem = aText.text.toLowerCase();
      bItem = bText.text.toLowerCase();
    } else {
      aItem = aProp;
      bItem = bProp;
    }
    let aGreater = aItem > bItem;
    let bGreater = aItem < bItem;
    if (!aItem && bItem) {
      aGreater = true;
      bGreater = false;
    } else if (aItem && !bItem) {
      aGreater = false;
      bGreater = true;
    } else if (!aItem && !bItem) {
      aGreater = false;
      bGreater = false;
    }
    if (aGreater) {
      return reverse ? -1 : 1;
    } else if (bGreater) {
      return reverse ? 1 : -1;
    }
    return 0;
  };
};

export const dotToObject = (obj: any, prop: any) => {
  return prop.split('.').reduce((o: any, i: any) => o[i], obj);
};

export const convertKeyValuesToIDValues = (keyVals: KeyValueType = {}): IDValueType[] => {
  return Object.entries(keyVals).map(([id, value]) => new IDValueType(id, value));
};

/**
 * Add word break opportunities to certain tokens ('/' by default)
 * @param value
 */
export const addWBR = (value: string): JSX.Element => {
  if (!value) {
    return <></>;
  }

  const split = value.split('/');
  const items: JSX.Element[] = [];
  split.forEach((item, index) => {
    items.push(<>{item}</>);
    if (index < split.length - 1) {
      items.push(
        <>
          /<wbr />
        </>
      );
    }
  });

  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}
    </>
  );
};
