import { useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { KeyFeatureContext } from '../components/KeyFeature';
import { KeyFeatureType } from '../models/features.model';
import { getKeyFeatureOptionByDesc } from '../utils/featuresUtils';

const useKeyFeature = () => {
  const { keyFeatureList, setKeyFeatureList, keyFeatureOptions } = useContext(KeyFeatureContext);

  const onSplit = useCallback(() => {
    const dKeyFeature: KeyFeatureType = { uid: uuidv4(), description: '' };
    setKeyFeatureList([...keyFeatureList, dKeyFeature]);
  }, [keyFeatureList, setKeyFeatureList]);

  const onDelete = useCallback(
    (keyFeature: KeyFeatureType) => {
      const dKeyFeature: KeyFeatureType = { uid: uuidv4(), description: '' };
      const kFeatureList = keyFeatureList.filter(kf => kf.uid !== keyFeature.uid);
      const list = kFeatureList.length ? kFeatureList : [dKeyFeature];
      setKeyFeatureList(list);
    },
    [keyFeatureList, setKeyFeatureList]
  );

  const onChange = useCallback(
    (keyFeature: KeyFeatureType) => {
      const kOption = getKeyFeatureOptionByDesc(keyFeature.description, keyFeatureOptions);
      setKeyFeatureList(
        keyFeatureList.map(kFeature =>
          kFeature.uid === keyFeature.uid ? { ...keyFeature, id: kOption?.id } : kFeature
        )
      );
    },
    [keyFeatureList, setKeyFeatureList, keyFeatureOptions]
  );

  return { keyFeatureList, onDelete, onSplit, onChange };
};

export default useKeyFeature;
