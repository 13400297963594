import { observer } from 'mobx-react-lite';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ColorItemLangMap } from '../../../../../../models/colors.model';
import ColorsFormRow from './ColorsFormRow';
import { IColorsFormRow } from './IColorsFormRow';

interface Props extends IColorsFormRow {
  colorMaps: ColorItemLangMap[];
}

/**
 * Wraps the Droppable component so updates to colors, categories will
 * trigger a re-render
 */
const ColorsFormRowsContainer = ({ colorMaps, sortMode, ...rest }: Props) => {
  return (
    <Droppable droppableId="colorsDroppable" isDropDisabled={!sortMode}>
      {provided => (
        <tbody ref={provided.innerRef} {...provided.droppableProps}>
          {colorMaps.map((colorMap, index) => {
            const uid = colorMap.langs[Object.keys(colorMap.langs)[0]].uid;
            return (
              <Draggable key={uid} draggableId={uid} index={index}>
                {(provided, snapshot) => (
                  <ColorsFormRow
                    key={uid}
                    index={index}
                    colorMap={colorMap}
                    draggableProvided={provided}
                    sortMode={sortMode}
                    {...rest}
                  />
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
};

export default observer(ColorsFormRowsContainer);
