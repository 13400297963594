import cx from 'clsx';
import React, { useEffect, useRef } from 'react';
import {
  Checkbox,
  Dropdown,
  DropdownButton,
  DropdownContent,
  useComponentVisible,
} from 'vapi-ui-common';
import useChecklist, { ChecklistItem } from '../../hooks/useChecklist';
import styles from './DropdownCheckbox.module.scss';

interface DropdownCheckboxProps {
  value: any;
  onSelect?: (item: string) => void;
  contentElement?(ref: any, open: boolean, closeDropdown: () => void): JSX.Element;
  list?: ChecklistItem[];
  onClose?: () => void;
  placeholder?: string;
  required?: boolean;
  btnClass?: string;
  align?: string;
  readOnly?: boolean;
  handleOnSelectColorFamilies?: (selectedColorFamilies: { [name: string]: string }) => void;
}

const DropdownCheckbox = ({
  value,
  onSelect = () => {},
  contentElement,
  list = [],
  onClose = () => {},
  placeholder,
  required,
  btnClass,
  align,
  readOnly = false,
  handleOnSelectColorFamilies,
}: DropdownCheckboxProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const wasVisible = useRef<boolean>(false);

  const { checklist, setChecklist, selectItem } = useChecklist();

  useEffect(() => {
    setChecklist(list);
  }, [list, setChecklist]);

  useEffect(() => {
    if (wasVisible.current && !isComponentVisible) {
      if (handleOnSelectColorFamilies) {
        handleOnSelectColorFamilies(concatValues(checklist));
      }
    }
    wasVisible.current = isComponentVisible;
  }, [checklist, isComponentVisible, handleOnSelectColorFamilies]);

  const onEnterPress = (event: React.KeyboardEvent<HTMLElement>, callbackFn: () => void) => {
    if (event.key === 'Enter') {
      callbackFn();
    }
  };

  const concatValues = (list: ChecklistItem[]) => {
    const map: { [name: string]: string } = {};
    list.forEach(item => {
      if (item.selected) {
        map[item.name] = item.name;
      }
    });
    return map;
  };

  const hasError = () => {
    if (required && !value) {
      return true;
    }
    return false;
  };

  const handleOnSelect = (item: string) => {
    onSelect(item);
    onClose();
    // setIsComponentVisible(false);
  };

  return (
    <Dropdown>
      <DropdownButton
        onClick={() => {
          if (!readOnly) setIsComponentVisible(true);
        }}
        variant={hasError() ? 'error' : undefined}
        className={cx(hasError() && styles.error, !value && styles.placeholder, btnClass)}
        disabled={readOnly}
      >
        {!value ? placeholder : value}
      </DropdownButton>
      {contentElement ? (
        contentElement(ref, isComponentVisible, () => setIsComponentVisible(false))
      ) : (
        <DropdownContent align={align} open={isComponentVisible} ref={ref}>
          <ul className={styles.dropdownList}>
            {checklist &&
              checklist.map(item => {
                return (
                  <li
                    key={item.name}
                    role="menuitem"
                    onClick={() => handleOnSelect(item.name)}
                    onKeyPress={e => onEnterPress(e, () => handleOnSelect(item.name))}
                  >
                    <Checkbox
                      id={`chbox}${item.name}`}
                      checked={item.selected}
                      onChange={e => {
                        selectItem(item, e.currentTarget.checked);
                      }}
                    >
                      {item.name}
                    </Checkbox>
                  </li>
                );
              })}
          </ul>
        </DropdownContent>
      )}
    </Dropdown>
  );
};

export default DropdownCheckbox;
