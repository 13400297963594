import { action, observable } from 'mobx';
import { IDValueType } from '../models/common.model';
import { CommonLanguageItem, CommonLanguageVehicle } from '../models/commonLanguage.model';
import { toast } from 'react-toastify';
import { isSortReverse, sortBy } from '../utils';
import { filterSpecs } from '../utils/commonLanguageUtils';
import { getSpecs } from '../webservices/commonLanguageApi';
import { toGqlBrand, toGqlFilter } from '../utils/graphqlUtils';

export class CommonLanguageStore {
  data: CommonLanguageItem[] = [];
  reverseSort = false;
  sortField = 'id';
  searchText = '';
  @observable filteredData: CommonLanguageItem[] = [];
  @observable categories: IDValueType[] = [];
  @observable vehicleModels: CommonLanguageVehicle[] = [];
  @observable categoryFilters: string[] = [];
  @observable vehicleFilters: string[] = [];
  @observable versions: string[] = [];

  isFetching = false;
  isLoaded = false;

  setData = (data: CommonLanguageItem[]) => {
    this.data = data;
    this.filteredData = data;
  };

  addItem = () => {
    const newItem = new CommonLanguageItem();
    this.data = [newItem, ...this.data];
    this.filteredData = [newItem, ...this.filteredData];
  };

  deleteItem = (clItem: CommonLanguageItem) => {
    this.data = this.data.filter(item => item.uid !== clItem.uid);
    this.filteredData = this.filteredData.filter(item => item.uid !== clItem.uid);
  };

  setFilters = (categoryFilters: string[], vehicleFilters: string[]) => {
    this.categoryFilters = categoryFilters;
    this.vehicleFilters = vehicleFilters;
    this.filterData();
  };

  setSort = (sortField: string) => {
    this.reverseSort = isSortReverse(this.sortField, sortField, this.reverseSort);
    this.sortField = sortField;
    this.filterData();
  };

  updateDataCatagories(categoryId: string) {
    const category = this.categories.filter(item => item.id === categoryId)[0];
    this.data.forEach(item => {
      if (item.categoryId === categoryId && item.category !== category.value) {
        item.setCategory(categoryId, category.value);
      }
    });
  }

  setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };
  setIsLoaded = (isLoaded: boolean) => {
    this.isLoaded = isLoaded;
  };

  filterData = () => {
    const sorted = this.data
      .slice()
      .sort(sortBy(this.sortField, this.reverseSort, this.sortField === 'lastPublishedDate'));
    this.filteredData = filterSpecs(
      this.searchText,
      this.vehicleFilters,
      this.categoryFilters,
      sorted
    );
  };

  @action fetchData = async (brand: string, status: string, seriesId: string, year: string) => {
    (async () => {
      try {
        const response = await getSpecs({
          brand: toGqlBrand(brand),
          filter: toGqlFilter(status),
          modelYear: year,
          seriesId,
        });
        this.data = response.map(item => new CommonLanguageItem(item));
      } catch (e) {
        toast.error('Error fetching common language data');
      }
    })();
  };

  @action reset() {
    this.data = [];
    this.reverseSort = false;
    this.sortField = 'id';
    this.searchText = '';
    this.filteredData = [];
    this.categories = [];
    this.vehicleModels = [];
    this.categoryFilters = [];
    this.vehicleFilters = [];
    this.versions = [];
  }
}
