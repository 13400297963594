import React from 'react';
import { TableRowBase } from '../../../components/Table/components/TableRow/TableRow';
import styles from './dphSeries.module.scss';

interface SeriesListProps {
  list: string[];
}

const DphSeries = ({ list }: SeriesListProps) => {
  return (
    <>
      {!list.length ? (
        <>
          <TableRowBase zebra className={styles.row}>
            <div className={styles.empty}>No Series In Category</div>
          </TableRowBase>
        </>
      ) : (
        <>
          {list.map((item, ind) => (
            <TableRowBase zebra key={ind} className={styles.row}>
              <div className={styles.series}>{item}</div>
            </TableRowBase>
          ))}
        </>
      )}
    </>
  );
};

export default DphSeries;
