import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Header, Spinner } from 'vapi-ui-common';
import { uiBlock } from '../../components/UiBlocker/uiBlock';
import useStores from '../../hooks/useStores';
import { handleErrorResponse } from '../../utils/errorHandlingUtils';
import {
  getDisclaimersDocumentLink,
  publishDisclaimers,
  uploadDisclaimersDocument,
} from '../../webservices/disclaimersApi';
import SpanishDisclaimersTable from './components/SpanishDisclaimersTable';
import SpanishDisclaimerRow from './components/SpanishDisclaimersTable/components/SpanishDisclaimerRow';

const SpanishDisclaimers = observer(() => {
  const {
    userStore: { brand, modules, region },
    spanishDisclaimersStore,
  } = useStores();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    spanishDisclaimersStore.reset();

    (async () => {
      try {
        await spanishDisclaimersStore.fetchData(brand, region);
      } catch (e) {
        toast.error('Error loading spanish disclaimers data.');
        console.log(e.message);
      }

      setIsLoaded(true);
    })();
  }, [brand, spanishDisclaimersStore, region]);

  const handleOnDownload = async (version: number) => {
    try {
      const fileName = `disclaimers-version-${version}`;
      const getDocLink = await trackPromise(
        getDisclaimersDocumentLink(brand, region, version, 'EN')
      );
      const json = JSON.stringify(getDocLink, undefined, 4);
      const blob = new Blob([json], { type: 'application/json' });
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = `${fileName}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      handleErrorResponse(e, 'Error downloading document');
    }
  };

  const handleOnUpload = async (file?: File, resultJSON?: object) => {
    try {
      uiBlock.start();
      resultJSON && (await uploadDisclaimersDocument(brand, region, 'ES', resultJSON));
      await spanishDisclaimersStore.fetchData(brand, region);
      toast.success('Successfully uploaded file');
    } catch (e) {
      handleErrorResponse(e, 'Error uploading document');
    }
    uiBlock.stop();
  };

  const handleOnPublish = async () => {
    try {
      const lang = 'ES';
      await trackPromise(publishDisclaimers(brand, region, lang));
      toast.success('Successfully published disclaimers.');
    } catch (e) {
      handleErrorResponse(e, 'Error publishing disclaimers.');
    }
  };

  useEffect(() => {
    spanishDisclaimersStore.reset();
    setIsLoaded(true);
  }, [spanishDisclaimersStore]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="Spanish" moduleSubTitle="Disclaimers" />
      <SpanishDisclaimersTable
        renderRows={() => (
          <SpanishDisclaimerRow
            item={spanishDisclaimersStore.versionInfo}
            onPublish={handleOnPublish}
            onFileUpload={handleOnUpload}
            onDownload={handleOnDownload}
            canEdit={modules.Spanish.canEdit}
          />
        )}
      />
    </>
  );
});

export default SpanishDisclaimers;
