import { observer } from 'mobx-react-lite';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import FeatureRow from '.';
import { FeatureLangMap } from '../../../../../../models/features.model';
import { IFeatureRow } from './IFeatureRow';

interface FeatureRowsContainerProps extends IFeatureRow {
  featureLangMaps: FeatureLangMap[];
}

/**
 * Wraps the Droppable component so updates to features, categories will
 * trigger a re-render
 */
const FeatureRowsContainer = ({
  sortMode,
  featureLangMaps,
  ...rest
}: FeatureRowsContainerProps) => {
  return (
    <Droppable droppableId="featuresDroppable" isDropDisabled={!sortMode}>
      {provided => (
        <tbody ref={provided.innerRef} {...provided.droppableProps}>
          {featureLangMaps.map((feature, index) => {
            const uid = feature.langs[Object.keys(feature.langs)[0]].uid;
            return (
              <Draggable key={uid} draggableId={uid} index={index}>
                {(provided, snapshot) => (
                  <FeatureRow
                    key={uid}
                    index={index}
                    featureLangMap={feature}
                    draggableProvided={provided}
                    sortMode={sortMode}
                    {...rest}
                  />
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
};

export default observer(FeatureRowsContainer);
