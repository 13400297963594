import { SeriesCategories } from './vehicleData.model';

export type Brand = 'toyota' | 'lexus' | 'tdpr';
export const BRAND_TOYOTA: Brand = 'toyota';
export const BRAND_LEXUS: Brand = 'lexus';
export const BRAND_TDPR: Brand = 'tdpr';
export const VALID_BRANDS: Brand[] = [BRAND_TOYOTA, BRAND_LEXUS, BRAND_TDPR];

export type RegionalTeam = 'GSTTeam' | 'NationalTeam';
export const Region_GST_TEAM: RegionalTeam = 'GSTTeam';
export const Region_NATIONAL_TEAM: RegionalTeam = 'NationalTeam';

export type Region = 'GST' | 'NATIONAL' | 'TDPR' | 'USVI';
export const REGION_GST: Region = 'GST';
export const REGION_NATIONAL: Region = 'NATIONAL';
export const REGION_TDPR: Region = 'TDPR';
export const REGION_USVI: Region = 'USVI';

export enum Language {
  EN = 'EN',
  ES = 'ES',
}

export interface UserInfo {
  brand: string;
  groups: UserGroups;
  langPermissions: LanguagePermissions;
  modules: UserModules;
  series: string[];
  objectId: string;
}

export enum UserGroupsOptions {
  VAPI_toyota_user = 'VAPI_toyota_user',
  VAPI_pr_user = 'VAPI_pr_user',
  VAPI_common_language_editor = 'VAPI_common_language_editor',
  VAPI_product_team_editor = 'VAPI_product_team_editor',
  VAPI_product_team_reviewer = 'VAPI_product_team_reviewer',
  VAPI_product_team_read_only = 'VAPI_product_team_read_only',
  VAPI_agency_team_editor = 'VAPI_agency_team_editor',
  VAPI_lcv_team = 'VAPI_lcv_team',
  VAPI_sc_team = 'VAPI_sc_team',
  VAPI_cuv_team = 'VAPI_cuv_team',
  VAPI_tsuv_team = 'VAPI_tsuv_team',
  VAPI_spanish_editor = 'VAPI_spanish_editor',
}

export type UserGroups =
  | UserGroupsOptions.VAPI_toyota_user
  | UserGroupsOptions.VAPI_pr_user
  | UserGroupsOptions.VAPI_common_language_editor
  | UserGroupsOptions.VAPI_product_team_editor
  | UserGroupsOptions.VAPI_product_team_reviewer
  | UserGroupsOptions.VAPI_product_team_read_only
  | UserGroupsOptions.VAPI_agency_team_editor
  | UserGroupsOptions.VAPI_lcv_team
  | UserGroupsOptions.VAPI_sc_team
  | UserGroupsOptions.VAPI_cuv_team
  | UserGroupsOptions.VAPI_tsuv_team
  | UserGroupsOptions.VAPI_spanish_editor;

export type UserModules = {
  Accessories: AccessoriesUserPermissions;
  CommonLanguage: UserPermissions;
  ProductTeam: UserVDPermissions;
  AgencyTeam: UserVDPermissions;
  Disclaimers: UserPermissions;
  SeriesManager: UserPermissions;
  SeriesSelections: UserPermissions;
  Spanish: UserPermissions;
  Dph: UserPermissions;
};

export interface UserVDPermissions extends UserPermissions {
  series: {
    [SeriesCategories.LARGE_SEDANS_VANS]: UserPermissions;
    [SeriesCategories.SEDANS_SPORTS_CARS]: UserPermissions;
    [SeriesCategories.CROSSOVERS]: UserPermissions;
    [SeriesCategories.TRUCKS_SUVS]: UserPermissions;
  };
}

export type LanguagePermissions = {
  [k in Language]?: UserPermissions;
};

export type UserPermissions = {
  canView?: boolean;
  canViewDraft?: boolean;
  canEdit?: boolean;
  canReview?: boolean;
};

export interface AccessoriesUserPermissions extends UserPermissions {
  teams: {
    [Region_GST_TEAM]: UserPermissions;
    [Region_NATIONAL_TEAM]: UserPermissions;
  };
}
