import { KeyValueType } from '../models/common.model';
import { Language, LanguagePermissions } from '../models/user.model';

export default function parseLangWriteMap(langWriteMap: LanguagePermissions) {
  const allLangs = Object.keys(langWriteMap).filter(
    lang => langWriteMap[lang as Language]?.canView
  ) as Language[];
  const selectedLangsMap: KeyValueType<boolean> = {};
  allLangs.forEach(lang => {
    selectedLangsMap[lang] = true;
  });
  const editableLangs = allLangs.filter(lang => langWriteMap[lang as Language]?.canEdit);

  let defaultLang = allLangs[0];
  if (langWriteMap.EN != null) {
    defaultLang = Language.EN;
  } else if (editableLangs.length) {
    defaultLang = editableLangs[0];
  }

  let defaultEditLang = allLangs[0];
  if (editableLangs.includes(Language.EN)) {
    defaultEditLang = Language.EN;
  } else if (editableLangs.length) {
    defaultEditLang = editableLangs[0];
  }

  // if no write or read permissions for english then the user can edit model applicability by using their default lang
  const fullEditPermissions = langWriteMap.EN == null ? true : !!langWriteMap.EN?.canEdit;
  const modelApplicabilityLang = langWriteMap.EN == null ? defaultLang : Language.EN;
  const hasEnglishWritePerms = langWriteMap.EN == null ? false : !!langWriteMap.EN?.canEdit;

  return {
    defaultLang,
    allLangs,
    selectedLangsMap,
    editableLangs,
    fullEditPermissions,
    modelApplicabilityLang,
    defaultEditLang,
    hasEnglishWritePerms,
  };
}
