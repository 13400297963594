import { action, observable } from 'mobx';
import { SeriesCategoryItem } from '../models/seriesCategories.model';
import { seriesCategoriesXForm } from '../utils/seriesCategoryUtils';
import { getSeriesCategories } from '../webservices/adminApi';
import { toGqlBrand } from '../utils/graphqlUtils';

class SeriesCategoriesStore {
  @observable seriesCategories: SeriesCategoryItem[] = [];

  @action fetchData = async (brand: string) => {
    const response = await getSeriesCategories({
      brand: toGqlBrand(brand),
      getLatestVersion: true,
    });
    this.setSeriesCategories(seriesCategoriesXForm(response));
  };

  setSeriesCategories = (categories: SeriesCategoryItem[]) => {
    this.seriesCategories = categories;
  };

  addItem = () => {
    const newItem = new SeriesCategoryItem();
    this.seriesCategories = [newItem, ...this.seriesCategories];
  };

  deleteItem = (seriesCategory: SeriesCategoryItem) => {
    this.seriesCategories = this.seriesCategories.filter(item => item.uid !== seriesCategory.uid);
  };

  @action reset() {
    this.seriesCategories = [];
  }
}

export default SeriesCategoriesStore;
