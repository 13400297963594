import { CategoriesMap, CategoryResponse } from '../models/category.model';
import { IDValueType, KeyValueType } from '../models/common.model';

export const categoriesXForm = (
  categories: CategoryResponse[],
  sortFunction?: (item1: IDValueType, item2: IDValueType) => number
): IDValueType[] => {
  const returnArray = categories ? categories.map(cat => new IDValueType(cat.id, cat.name)) : [];
  if (sortFunction) {
    returnArray.sort(sortFunction);
  }
  return returnArray;
};

/**
 * Old xform that transforms from object to array
 * @param categories
 */
export const categoriesObjectXForm = (categories: KeyValueType): IDValueType[] => {
  const returnArray = categories
    ? Object.entries(categories).map(([key, val]) => new IDValueType(key, val))
    : [];
  return returnArray;
};

export const fillOutCategoriesMap = (categoriesMap: CategoriesMap, lang: string[]) => {
  Object.values(categoriesMap.categories).forEach(cat => {
    const defaultId = cat.en ? cat.en.id : Object.values(cat)[0].id;
    lang.forEach(lang => {
      if (!cat[lang]) {
        cat[lang] = new IDValueType<string>(defaultId, '');
      }
    });
  });
};

export const getCategoriesForLang = (lang: string, categoriesMap: CategoriesMap) => {
  const cats: IDValueType<string>[] = [];
  Object.keys(categoriesMap.categories).forEach(catId => {
    if (categoriesMap.categories[catId][lang]) {
      cats.push(categoriesMap.categories[catId][lang]);
    }
  });
  return cats;
};
