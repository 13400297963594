import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FuelTypeItemResponse } from '../../../../../../models/fuelType.model';
import {
  FieldStatus,
  SeriesCategoryItem,
  SeriesSettingsLangMap,
  UpdateSeriesSettingsStatusRequest,
} from '../../../../../../models/seriesSettings.model';
import { Brand, Language } from '../../../../../../models/user.model';
import SeriesSettingsEditPanel from './SeriesSettingsEditPanel';
import SeriesSettingsRowBase from './SeriesSettingsRowBase';

interface SeriesSettingsRowProps {
  brand: Brand;
  index: number;
  saveSeriesSetting?: (seriesSettingsLangMap: SeriesSettingsLangMap) => void;
  onDelete?: (seriesSetting: SeriesSettingsLangMap) => void;
  saveSubSeriesSetting?: (seriesSettingsLangMap: SeriesSettingsLangMap) => void;
  onDeleteSubSeriesSetting?: (seriesSetting: SeriesSettingsLangMap) => void;
  readOnly?: boolean;
  fuelTypesList: FuelTypeItemResponse[];
  seriesCategoriesList: SeriesCategoryItem[];
  canDeleteSetting: boolean;
  seriesSettingsLangMap: SeriesSettingsLangMap;
  defaultLang: Language;
  languages: Language[];
  compareSettings?: (settings: SeriesSettingsLangMap) => void;
  canAddNewSeriesSettings: boolean;
  updateFieldStatus?: (
    payload: UpdateSeriesSettingsStatusRequest,
    setFieldStatus: (val: FieldStatus) => void
  ) => void;
  isPublished?: boolean;
  isSubmitted?: boolean;
}

const SeriesSettingsRow = ({
  brand,
  index,
  fuelTypesList,
  saveSeriesSetting = () => {},
  onDelete = () => void 0,
  saveSubSeriesSetting = () => {},
  onDeleteSubSeriesSetting = () => void 0,
  readOnly,
  seriesCategoriesList,
  canDeleteSetting,
  seriesSettingsLangMap,
  defaultLang,
  languages,
  compareSettings,
  canAddNewSeriesSettings,
  updateFieldStatus,
  isPublished,
  isSubmitted,
}: SeriesSettingsRowProps) => {
  const defaultSeriesSettings = seriesSettingsLangMap[defaultLang];
  const [isEditOpen, setIsEditOpen] = useState(defaultSeriesSettings.isNewSubSeries);

  return !isEditOpen ? (
    <SeriesSettingsRowBase
      setIsEditOpen={setIsEditOpen}
      brand={brand}
      index={index}
      fuelTypesList={fuelTypesList}
      saveSeriesSetting={saveSeriesSetting}
      onDelete={onDelete}
      saveSubSeriesSetting={saveSubSeriesSetting}
      onDeleteSubSeriesSetting={onDeleteSubSeriesSetting}
      readOnly={readOnly}
      seriesCategoriesList={seriesCategoriesList}
      canDeleteSetting={canDeleteSetting}
      seriesSettingsLangMap={seriesSettingsLangMap}
      defaultLang={defaultLang}
      languages={languages}
      compareSettings={compareSettings}
      updateFieldStatus={updateFieldStatus}
      isPublished={isPublished}
      isSubmitted={isSubmitted}
    />
  ) : (
    <SeriesSettingsEditPanel
      brand={brand}
      fuelTypesList={fuelTypesList}
      onClose={() => setIsEditOpen(false)}
      saveSeriesSetting={saveSeriesSetting}
      saveSubSeriesSetting={saveSubSeriesSetting}
      seriesCategoriesList={seriesCategoriesList}
      seriesSettingsLangMap={seriesSettingsLangMap}
      defaultLang={defaultLang}
      compareSettings={compareSettings}
      canAddNewSeriesSettings={canAddNewSeriesSettings}
      readOnly={readOnly}
    />
  );
};

export default observer(SeriesSettingsRow);
