import { observable } from 'mobx';
import * as colorsLexusModel from '../../../models/colorsLexus.model';
import { IDValueType } from '../../../models/common.model';
import { VehicleTeam } from '../../../models/vehicleData.model';
import { sortBy, toLowerCase } from '../../../utils';
import {
  colorLexusExteriorXform,
  colorLexusInteriorReviewXform,
  colorLexusInteriorXform,
  interiorColorTypesLexusXForm,
  mapColorLexusExteriorApplicabilityReviewXform,
  mapColorLexusInteriorGroupApplicabilityReviewXform,
} from '../../../utils/colorLexusUtils';
import {
  getReviewExteriorColorsLexus,
  getReviewInteriorColorsLexus,
  getReviewInteriorColorTypes,
} from '../../../webservices/vehicleColorsApi';
import { toGqlBrand, toGqlFilter, toGqlTeam } from '../../../utils/graphqlUtils';
import { Status } from 'vapi-ui-common';

class ColorsLexusExteriorReviewStore {
  exteriorItems: colorsLexusModel.ColorsLexusExteriorItem[] = [];
  reverse = false;
  sortField = 'id';
  searchText = '';
  isInProgressFilter = false;
  isExtraCostFilter = false;
  isNewChangeFilter = false;
  @observable heightSync = [{}, {}, {}, {}, {}, {}, {}, {}] as colorsLexusModel.HeightSyncItem[];
  @observable interiorGroups: colorsLexusModel.InteriorGroup[] = [];
  @observable filteredExteriors: colorsLexusModel.ColorsLexusExteriorItem[] = [];
  @observable viewModelCodes = false;
  @observable interiorColorTypes: colorsLexusModel.InteriorLexusTypes = {
    groups: [],
    materials: [],
    ornaments: [],
    interiorTypes: [],
  };

  setExteriorColors = (exteriorItems: colorsLexusModel.ColorsLexusExteriorItem[]) => {
    this.exteriorItems = exteriorItems;
    this.filteredExteriors = exteriorItems;
  };

  setInteriorGroups = (groups: colorsLexusModel.InteriorGroup[]) => {
    this.interiorGroups = groups;
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredExteriors = this.filterColors();
  };

  setSort = (sortField: string) => {
    this.reverse = this.sortField !== sortField ? false : !this.reverse;
    this.sortField = sortField;
  };

  filterColors = () => {
    const sorted = this.exteriorItems.slice().sort(sortBy(this.sortField, this.reverse));
    const lowerSearchText = toLowerCase(this.searchText);

    return sorted.filter(
      item =>
        (!lowerSearchText ||
          toLowerCase(item.code).includes(lowerSearchText) ||
          toLowerCase(item.hexCode).includes(lowerSearchText) ||
          toLowerCase(item.name).includes(lowerSearchText) ||
          toLowerCase(item.notes).includes(lowerSearchText)) &&
        (!this.isInProgressFilter || item.isInProgress) &&
        (!this.isExtraCostFilter || item.isExtraCost) &&
        (!this.isNewChangeFilter || item.changes.some(change => change.isNewChange))
    );
  };
}
class ColorsLexusInteriorReviewStore {
  interiorItems: colorsLexusModel.ColorLexusInteriorReviewItem[] = [];
  reverse = false;
  sortField = 'id';
  searchText = '';
  isNewChangeFilter = false;
  isExtraCostFilter = false;
  @observable interiorGroups: colorsLexusModel.InteriorGroup[] = [];
  @observable filteredInteriors: colorsLexusModel.ColorLexusInteriorReviewItem[] = [];
  @observable viewModelCodes = false;
  @observable interiorColorTypes = {
    groups: [] as IDValueType[],
    materials: [] as IDValueType[],
    ornaments: [] as IDValueType[],
    interiorTypes: [] as IDValueType[],
  };

  setInteriorColors = (interiorItems: colorsLexusModel.ColorLexusInteriorReviewItem[]) => {
    this.interiorItems = interiorItems;
    this.filteredInteriors = interiorItems;
  };

  setInteriorGroups = (groups: colorsLexusModel.InteriorGroup[]) => {
    this.interiorGroups = groups;
  };

  setSort = (sortField: string) => {
    this.reverse = this.sortField !== sortField ? false : !this.reverse;
    this.sortField = sortField;
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredInteriors = this.filterColors();
  };

  filterColors = () => {
    const sorted = this.interiorItems.slice().sort(sortBy(this.sortField, this.reverse));
    const lowerSearchText = toLowerCase(this.searchText);

    return sorted.filter(
      item =>
        (!lowerSearchText ||
          toLowerCase(item.material).includes(lowerSearchText) ||
          toLowerCase(item.interiorType).includes(lowerSearchText) ||
          toLowerCase(item.interiorType_subId).includes(lowerSearchText)) &&
        (!this.isExtraCostFilter || item.isExtraCost) &&
        (!this.isNewChangeFilter || item.changes.some(change => change.isNewChange))
    );
  };
}

class ColorsLexusReviewStore {
  exteriorStore = new ColorsLexusExteriorReviewStore();
  interiorStore = new ColorsLexusInteriorReviewStore();

  fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    version?: string
  ) => {
    const [
      responseReviewInteriorColorTypes,
      responseReviewInteriorColors,
      responseReviewExteriorColors,
    ] = await Promise.all([
      getReviewInteriorColorTypes({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId,
        modelYear: Number(year),
        filter: toGqlFilter(version ?? Status.DRAFT),
      }),
      getReviewInteriorColorsLexus({
        team: toGqlTeam(team),
        seriesId,
        modelYear: Number(year),
        filter: toGqlFilter(version ?? Status.DRAFT),
      }),
      getReviewExteriorColorsLexus({
        team: toGqlTeam(team),
        seriesId,
        modelYear: Number(year),
        filter: toGqlFilter(version ?? Status.DRAFT),
      }),
    ]);

    this.exteriorStore.interiorColorTypes = interiorColorTypesLexusXForm(
      responseReviewInteriorColorTypes
    );

    const interiorGroups = colorLexusInteriorXform(
      this.exteriorStore.interiorColorTypes,
      responseReviewInteriorColors
    );
    const exteriorItems = colorLexusExteriorXform(responseReviewExteriorColors, interiorGroups);
    mapColorLexusExteriorApplicabilityReviewXform(
      exteriorItems,
      responseReviewInteriorColors,
      this.exteriorStore.interiorColorTypes.groups
    );
    this.exteriorStore.setExteriorColors(exteriorItems);
    this.exteriorStore.setInteriorGroups(interiorGroups);

    const interiorItems = colorLexusInteriorReviewXform(
      responseReviewInteriorColors,
      this.exteriorStore.interiorColorTypes
    );
    mapColorLexusInteriorGroupApplicabilityReviewXform(
      interiorItems,
      this.exteriorStore.interiorColorTypes.groups
    );
    this.interiorStore.setInteriorColors(interiorItems);
  };

  reset = () => {
    this.exteriorStore.isNewChangeFilter = false;
    this.exteriorStore.isExtraCostFilter = false;
    this.exteriorStore.isInProgressFilter = false;
    this.interiorStore.isNewChangeFilter = false;
    this.interiorStore.isExtraCostFilter = false;
  };
}

export default ColorsLexusReviewStore;
