import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, DropdownButton, DropdownEdit, DropdownEditItem, Input } from 'vapi-ui-common';
import DropdownCheckbox from '../../../../components/DropdownCheckbox';
import { TableRowBase } from '../../../../components/Table/components/TableRow/TableRow';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import useInputValidation from '../../../../hooks/useInputValidation';
import { CFColorItem, ColorFamilyItem } from '../../../../models/colorFamilies.model';
import { RefItem } from '../../../../models/refItem.model';
import { addWBR } from '../../../../utils';
import { getSortedListByString } from '../../../../utils/sortUtils';
import styles from './cfColorRow.module.scss';

interface ICFColorsRow {
  colorFamilies: ColorFamilyItem[];
  color: CFColorItem;
  deleteColorItem: (color: CFColorItem) => void;
  saveColorItem: (color: CFColorItem) => void;
  readOnly: boolean;
  materials?: RefItem[];
  onAddMaterial?: (value: string) => void;
  onUpdateMaterial?: (material: RefItem) => void;
  hideMaterial?: boolean;
  setValidationMessage: (value: string) => void;
}

const CFColorsRow = ({
  readOnly,
  deleteColorItem,
  saveColorItem,
  colorFamilies,
  color,
  onAddMaterial,
  onUpdateMaterial,
  materials = [],
  hideMaterial = false,
  setValidationMessage,
}: ICFColorsRow) => {
  const COLOR_CODE_MAX_LENGTH = 4;
  const HEX_CODE_MAX_LENGTH = 6;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [value, setValue] = useState('');
  const [data, setData] = useState<{ name: string; selected: boolean }[]>([]);

  const { isInvalid, validate, errorMessages } = useInputValidation([
    (value: string) =>
      value.length < 4 ? 'Color code must be at least 4 characters at length.' : '',
    (value: string) =>
      !value.length ? 'Please finish filling out all items of the new color' : '',
  ]);

  const {
    isInvalid: isInvalidHexCode,
    validate: validateHexCode,
    errorMessages: errorMessagesHexCode,
  } = useInputValidation([
    (value: string) =>
      value.length < 6 ? 'Hex code must be at least 6 characters at length.' : '',
    (value: string) =>
      !value.length ? 'Please finish filling out all items of the new color' : '',
  ]);

  useEffect(() => {
    if (setValidationMessage) {
      setValidationMessage(isInvalid ? errorMessages[0] : '');
    }
  }, [isInvalid, errorMessages, setValidationMessage]);

  useEffect(() => {
    (() => {
      const selectedColorFamilies: { [name: string]: string } = {};
      color.colorFamilies.forEach(color => {
        selectedColorFamilies[color.name] = color.name;
      });
      setValue(Object.keys(selectedColorFamilies).join(', '));
      setData(
        colorFamilies.map(colorFamily => {
          return {
            name: colorFamily.name,
            selected: !!selectedColorFamilies[colorFamily.name],
          };
        })
      );
      if (!hideMaterial && color.material.value) {
        setSelectedMaterial(color.material.value);
      }
    })();
  }, [color, colorFamilies, hideMaterial]);

  const handleOnNameChange = (color: CFColorItem, name: string) => {
    if (name !== color.name) {
      color.name = name;
      saveColorItem(color);
    }
  };

  const handleOnCodeChange = (color: CFColorItem, code: string) => {
    if (isInvalid) {
      toast.error(errorMessages[0]);
    } else {
      if (code !== color.code) {
        color.code = code;
        saveColorItem(color);
      }
    }
  };

  const handleOnHexCodeChange = (color: CFColorItem, hexCode: string) => {
    if (isInvalidHexCode) {
      toast.error(errorMessagesHexCode[0]);
    } else {
      if (hexCode !== color.hexCode) {
        color.hexCode = hexCode;
        saveColorItem(color);
      }
    }
  };

  const handleOnSelectColorFamilies = (selectedColorFamilies: { [name: string]: string }) => {
    const colorFams: ColorFamilyItem[] = [];
    colorFamilies.forEach(colorFamily => {
      if (selectedColorFamilies[colorFamily.name]) {
        colorFams.push(colorFamily);
      }
    });
    color.colorFamilies = colorFams;
    color.setColorFamilyNames();
    setValue(Object.keys(selectedColorFamilies).join(', '));
    saveColorItem(color);
  };

  const handleOnSelectMaterial = (material: RefItem) => {
    if (material.id !== color.material.id) {
      color.material = material;
      setSelectedMaterial(material.value);
      saveColorItem(color);
    }
  };

  return (
    <TableRowBase zebra className={styles.interiorColorRow}>
      <div className={styles.row}>
        <div>
          <Input
            id={`code-${color.uid}`}
            defaultValue={color.code ? color.code : ''}
            onBlur={e => handleOnCodeChange(color, e.currentTarget.value.trim())}
            className={cx(styles.input, styles.codeInput)}
            readOnly={readOnly}
            maxLength={COLOR_CODE_MAX_LENGTH}
            isInvalid={isInvalid}
            validate={validate}
          />
        </div>
        <div>
          <Input
            required={true}
            id={`color-name-${color.uid}`}
            defaultValue={color.name ? color.name : ''}
            onBlur={e => handleOnNameChange(color, e.currentTarget.value.trim())}
            className={styles.input}
            readOnly={readOnly}
          />
        </div>
        {process.env.REACT_APP_COLOR_FAMILY_V2 === 'true' && hideMaterial && (
          <div>
            <Input
              id={`hex-code-${color.uid}`}
              defaultValue={color.hexCode ? color.hexCode : ''}
              onBlur={e => handleOnHexCodeChange(color, e.currentTarget.value.trim())}
              className={cx(styles.input, styles.codeInput)}
              readOnly={readOnly}
              minLength={HEX_CODE_MAX_LENGTH}
              isInvalid={isInvalidHexCode}
              validate={validateHexCode}
            />
          </div>
        )}
        <div className={styles.colorFamilyDropdown}>
          <DropdownCheckbox
            required={!color.colorFamilies.length}
            btnClass={styles.dropdown}
            value={value}
            list={data}
            handleOnSelectColorFamilies={handleOnSelectColorFamilies}
            readOnly={readOnly}
          />
        </div>
        {!hideMaterial && (
          <div className={styles.materialDropodwn}>
            <Dropdown>
              <DropdownButton
                error={!color.material.id}
                className={styles.dropdown}
                disabled={readOnly}
                onClick={() => setIsComponentVisible(true)}
              >
                {addWBR(selectedMaterial)}
              </DropdownButton>
              <DropdownEdit
                ref={ref}
                open={isComponentVisible}
                addBtnText={'Add Material'}
                onAdd={value => {
                  if (onAddMaterial) {
                    onAddMaterial(value);
                  }
                }}
                renderList={() => (
                  <>
                    {getSortedListByString(materials, 'value').map(item => (
                      <DropdownEditItem
                        key={item.id}
                        value={item.value}
                        isSelected={false}
                        onEdit={(before, after) => {
                          if (onUpdateMaterial) {
                            item.value = after;
                            onUpdateMaterial(item);
                            if (color.material.id === item.id) {
                              setSelectedMaterial(item.value);
                            }
                          }
                        }}
                        onClose={() => setIsComponentVisible(false)}
                        onSelect={() => handleOnSelectMaterial(item)}
                      />
                    ))}
                  </>
                )}
              />
            </Dropdown>
          </div>
        )}
        {/* Action buttons */}
        {!readOnly && (
          <div className={styles.actionButtons}>
            <div className={styles.deleteIcon}>
              <FontAwesomeIcon
                className={styles.edit}
                icon={faTrash}
                onClick={() => deleteColorItem(color)}
              />
            </div>
          </div>
        )}
      </div>
    </TableRowBase>
  );
};

export default observer(CFColorsRow);
