import { observer } from 'mobx-react-lite';
import React from 'react';
import { ActionBar } from 'vapi-ui-common';
import ActionBarFiltersSection from '../ActionBarFiltersSection';
import { ActionBarPropsModel } from './actionBarVehicleData.model';

const ActionBarVehicleData: React.FC<ActionBarPropsModel> = ({
  readOnly,
  toggleViewModelCodes,
  onSearchTextChange,
  viewModelCodes,
  renderFilter,
  searchText,
  buttons,
  renderButtons,
}) => {
  return (
    <ActionBar>
      <ActionBarFiltersSection
        searchText={searchText}
        onSearchTextChange={onSearchTextChange}
        renderFilter={renderFilter}
        toggleViewModelCodes={toggleViewModelCodes}
        viewModelCodes={viewModelCodes}
        readOnly={readOnly}
        buttons={buttons}
        renderButtons={renderButtons}
      />
    </ActionBar>
  );
};

export default observer(ActionBarVehicleData);
