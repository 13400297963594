import React from 'react';
import { TableRow } from '../../../../../../components/Table';
import styles from './optionsLexusDividerRow.module.scss';

interface OptionsLexusDividerRowProps {
}

const OptionsLexusDividerRow = (props: OptionsLexusDividerRowProps) => {
  return (
    <TableRow className={styles.divider} />
  );
};

export default OptionsLexusDividerRow;
