import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useComponentVisible from '../../../hooks/useComponentVisible';
import React from 'react';
import { Dropdown, DropdownContent } from 'vapi-ui-common';
import SortButton from '../SortButton';
import styles from './sortDropdown.module.scss';

export interface DropdownListProps {
  buttonText: string;
  list: Array<string>;
  onSelect: (item: string) => void;
}

const SortDropdown = ({ buttonText, list = [], onSelect = () => void 0 }: DropdownListProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <Dropdown>
      <SortButton
        toggled={isComponentVisible}
        onClick={() => setIsComponentVisible(true)}
        data-testid="sort-dropdown-comp"
      >
        {buttonText} <FontAwesomeIcon className={styles.caret} icon={faAngleDown} />
      </SortButton>
      <DropdownContent open={isComponentVisible} ref={ref}>
        <ul className={styles.list}>
          {list &&
            list.map(item => (
              <li
                key={item}
                onClick={() => {
                  onSelect(item);
                  setIsComponentVisible(false);
                }}
              >
                {item}
              </li>
            ))}
        </ul>
      </DropdownContent>
    </Dropdown>
  );
};

export default SortDropdown;
