import React, { HTMLAttributes } from 'react';
import styles from './stickyHeaderSection.module.scss';

export interface stickyProps extends HTMLAttributes<HTMLDivElement> {
  hasFilters?: boolean;
}
const StickyHeaderSection = (props: stickyProps) => {
  if (props.hasFilters) {
    return (
      <div
        className={styles.stickyHeaderSectionWithFilters}
        data-testid="sticky-header-section"
        {...props}
      />
    );
  } else {
    return (
      <div className={styles.stickyHeaderSection} data-testid="sticky-header-section" {...props} />
    );
  }
};

export default StickyHeaderSection;
