import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import {
  ActionBar,
  ActionBarSection,
  Button,
  convertToRichTextObject,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NotesPopover,
  ScrollableContainer,
  SearchInput,
  TextArea,
} from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox';
import Spinner from '../../../../components/Spinner';
import {
  DescriptionText,
  HeaderRow,
  Table,
  TableCell,
  TableRow,
} from '../../../../components/Table';
import useStores from '../../../../hooks/useStores';
import { CommonLanguageCheckItem, ModalState } from './SpecLinkModal.models';
import styles from './SpecLinkModal.module.scss';

interface SpecLinkModalProps {
  tabName?: string;
  onAddItem: (id: string) => void;
  onClose: () => void;
  comLangId?: string;
}

const state = new ModalState();

const SpecLinkModal = ({ tabName, onAddItem, onClose, comLangId }: SpecLinkModalProps) => {
  const {
    commonLanguageStore,
    userStore: { brand },
    vehicleSeriesInfoStore: { seriesId, year },
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    return () => {
      state.searchText = '';
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        state.items = commonLanguageStore.data.map(item => {
          const clItem = new CommonLanguageCheckItem(item);
          clItem.checked = clItem.id === comLangId;
          return clItem;
        });
      } catch (e) {
        toast.error('Error fetching common language data');
      }
      setIsLoaded(true);
    })();
  }, [brand, seriesId, year, comLangId, commonLanguageStore]);

  const handleOnSelect = (item: CommonLanguageCheckItem) => {
    state.items.forEach(i => {
      if (item.id === i.id) {
        i.checked = !i.checked;
      } else {
        i.checked = false;
      }
    });
    state.items = [...state.items];
  };

  const handleOnAdd = () => {
    const itemId = state.items.filter(item => item.checked && !item.disabled).map(item => item.id);
    onAddItem(itemId[0]);
    onClose();
  };

  return (
    <>
      <ModalHeader onClose={onClose}>
        Add {tabName} {'>'} Common Language
      </ModalHeader>
      {!isLoaded ? (
        <Spinner short />
      ) : (
        <>
          <ModalBody>
            <ActionBar>
              <ActionBarSection>
                <SearchInput
                  value={state.searchText}
                  onSearch={text => (state.searchText = text)}
                />
              </ActionBarSection>
            </ActionBar>
            <ScrollableContainer>
              <Table fullWidth className={styles.specTable}>
                <HeaderRow className={styles.stickyNav}>
                  <TableCell></TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Sub Category</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Published</TableCell>
                  <TableCell>Notes</TableCell>
                </HeaderRow>
                <tbody className={styles.specTbody}>
                  {state.filteredItems.map(item => (
                    <TableRow zebra key={item.id} className={styles.specModalTableRow}>
                      <TableCell center>
                        {!item.disabled && (
                          <Checkbox
                            id={`addCl-chbox-${item.id}`}
                            checked={item.checked}
                            onChange={() => handleOnSelect(item)}
                          />
                        )}
                      </TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>
                        <DescriptionText size="sm">{item.subCategory}</DescriptionText>
                      </TableCell>
                      <TableCell large>
                        <TextArea
                          className={cx(
                            styles.specLinkDescription,
                            styles.tableTextArea,
                            styles.noResize
                          )}
                          defaultValue={
                            convertToRichTextObject(item.description).text || item.description
                          }
                          readOnly
                          disabled
                        />
                      </TableCell>

                      <TableCell>
                        {item.lastPublishedDate && (
                          <Moment format="MM/DD/YY">{item.lastPublishedDate}</Moment>
                        )}
                      </TableCell>
                      <TableCell>
                        <NotesPopover notes={item.notes} handleUpdateItem={() => {}} readOnly />
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </ScrollableContainer>
          </ModalBody>
          <ModalFooter>
            <Button variant="transparent" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => handleOnAdd()}
              disabled={!state.selectCount && !comLangId}
            >
              Add Feature(s)
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default observer(SpecLinkModal);
