import { TableCell } from '../../../../../components/Table';
import { ChangeLogTypes } from '../../../../../models/changeLog.model';
import { OptionsPackage } from '../../../../../models/optionsLexus.model';
import { ReviewChangeBaseItem } from '../../../../../models/review.model';
import React from 'react';
import { AcceptApplyCell } from '../../../../../routes/vehicleData/components/tableCells/AcceptApplyCell';
import OptionsLexusReviewChanges from '../components/OptionsLexusChanges';

export const renderPackageReviewCells = (
  option: OptionsPackage,
  change: ReviewChangeBaseItem,
  updatePackage: (option: OptionsPackage, change: ReviewChangeBaseItem) => void,
  changeType?: ChangeLogTypes,
  hideAccept = false
) => {
  const isAddDelType = [ChangeLogTypes.PACKAGE_ADDED, ChangeLogTypes.PACKAGE_DELETED].includes(
    change.changes.changeType
  );
  return (!changeType && !isAddDelType) || change.changes.changeType === changeType ? (
    <>
      <td colSpan={4} />
      <TableCell smallText whiteBg border>
        <OptionsLexusReviewChanges changes={change.changes} />
      </TableCell>
      <AcceptApplyCell
        id={`package-change-${change.uid}`}
        applyChecked={change.isApplied}
        acceptChecked={change.isAccepted}
        isNewChange={change.isNewChange}
        notes={change.rejectNotes}
        onApplyChecked={checked => {
          change.isApplied = checked;
          updatePackage(option, change);
        }}
        onAcceptChecked={
          hideAccept
            ? undefined
            : checked => {
                change.isAccepted = checked;
                updatePackage(option, change);
              }
        }
        onNotesChange={notes => {
          change.rejectNotes = notes;
          updatePackage(option, change);
        }}
      />
    </>
  ) : null;
};
