import React, { useState } from 'react';
import { Button } from 'vapi-ui-common';
import NumberFormat from '../../../../components/NumberFormat';
import Popover from '../../../../components/Popover';
import ExtraCostContent from './ExtraCostContent';
import styles from './extraCostPopover.module.scss';

interface CostProps {
  msrp: string;
  readOnly?: boolean;
  handleUpdateItem?: (msrp: string) => void;
  popoverClass?: string;
  defaultOpen?: boolean;
  toggleElement?: JSX.Element; // Overrides default toggle Element
}

const ExtraCostPopover = (props: CostProps) => {
  const { msrp, readOnly, popoverClass, handleUpdateItem, defaultOpen = false } = props;

  const [ctaStyling, setCtaStyling] = useState(false);

  return (
    <>
      {!readOnly && (
        <Popover
          popoverClass={popoverClass}
          defaultOpen={defaultOpen}
          toggleElement={
            msrp && Number(msrp) > 0 && msrp !== '' ? (
              <label htmlFor="msrp" className={styles.msrpLabel}>
                <NumberFormat value={msrp} />
              </label>
            ) : (
              <Button variant="extraCost" toggled={ctaStyling} disabled={readOnly}>
                $$$
              </Button>
            )
          }
          popoverElement={
            <ExtraCostContent
              msrp={msrp}
              onUpdate={msrp => {
                handleUpdateItem && handleUpdateItem(msrp);
              }}
              onCostChange={cost => {
                setCtaStyling(Number(cost) > 0);
              }}
            />
          }
        />
      )}
      {readOnly && (
        <label htmlFor="msrp" className={styles.msrpLabel}>
          <NumberFormat value={msrp} />
        </label>
      )}
    </>
  );
};

export default ExtraCostPopover;
