import React from 'react';
import { observer } from 'mobx-react-lite';
import { CommonLanguageChangeLogItem } from '../../../../models/commonLanguage.model';

import { Table, Thead, TableRow, HeaderCell } from '../../../../components/Table';
import CommonLanguageChangeLogRow from './ChangeLogRow';

interface CommonLanguageChangeLogTableProps {
  data: CommonLanguageChangeLogItem[];
  onSort: (key: string) => void;
  onRevertChange: (item: CommonLanguageChangeLogItem) => void;
}

const CommonLanguageChangeLogTable = observer(
  ({ data, onSort, onRevertChange }: CommonLanguageChangeLogTableProps) => {
    return (
      <Table fullWidth>
        <Thead>
          <TableRow>
            <HeaderCell>ID</HeaderCell>
            <HeaderCell>Description</HeaderCell>
            <HeaderCell onClick={() => onSort('changeType')}>Change Type</HeaderCell>
            <HeaderCell>Changes</HeaderCell>
            <HeaderCell onClick={() => onSort('modifiedBy')}>Modified By</HeaderCell>
            <HeaderCell onClick={() => onSort('modifiedDate')}>Modified Date</HeaderCell>
            <HeaderCell>Notes</HeaderCell>
            <HeaderCell />
          </TableRow>
        </Thead>
        <tbody>
          {data.map(item => (
            <CommonLanguageChangeLogRow key={item.uid} log={item} onRevertChange={onRevertChange} />
          ))}
        </tbody>
      </Table>
    );
  }
);

export default CommonLanguageChangeLogTable;
