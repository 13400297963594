import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Input, Modal, areRichTextValuesDifferent, convertToRichTextObject } from 'vapi-ui-common';
import Checkbox, { CheckboxLabel } from '../../../../../../components/Checkbox';
import DropdownEditItem from '../../../../../../components/DropdownEdit/DropdownEditItem';
import IconTextButton from '../../../../../../components/IconTextButton';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import KeyFeature from '../../../../../../components/KeyFeature';
import { TableCell, TableRowWithShadow } from '../../../../../../components/Table';
import TableDragIcon from '../../../../../../components/Table/components/TableDragIcon';
import Tooltip from '../../../../../../components/Tooltip';
import useFieldStatusModelUpdate from '../../../../../../hooks/useFieldStatusModelUpdate';
import { useCategoriesMapSort } from '../../../../../../hooks/useLanguageMapSort';
import useStores from '../../../../../../hooks/useStores';
import { CategoryLangMap } from '../../../../../../models/category.model';
import {
  FeatureItem,
  FeatureLangMap,
  GradeApplicability,
  KeyFeatureType,
} from '../../../../../../models/features.model';
import { BRAND_TDPR, Language } from '../../../../../../models/user.model';
import SpecLinkModal from '../../../../../../routes/vehicleData/components/SpecLinkModal';
import RichTextCell from '../../../../../../routes/vehicleData/components/tableCells/RichTextCell';
import { handleOnLangMapSortNumberUpdate } from '../../../../../../utils/sortUtils';
import { updateFeatureStatus } from '../../../../../../webservices/vehicleFeaturesApi';
import GradeApplicabilityModal from '../../../../components/GradesApplicabilityModal';
import SyncTdPRButton from '../../../../components/SyncTdPRButton/SyncTdPRButton';
import ContextMenuCell from '../../../../components/tableCells/ContextMenuCell';
import DropdownEditorCell from '../../../../components/tableCells/DropdownEditorCell';
import FlagsCell from '../../../../components/tableCells/FlagsCell';
import LinkCell from '../../../../components/tableCells/LinkCell';
import { IFeatureRow } from './IFeatureRow';
import styles from './featureRow.module.scss';

// VAPI-743 Helper function to determine if flag should be highlighted
const getIsHighlighted = (feature: FeatureItem) => {
  return feature.gradeApplicability && Object.keys(feature.gradeApplicability).length > 0;
};

interface FeatureRowProps extends IFeatureRow {
  featureLangMap: FeatureLangMap;
  index: number;
  draggableProvided: DraggableProvided;
}

const FeatureRow = ({
  index,
  featureLangMap,
  saveFeatureLangMap,
  deleteFeatureLangMap,
  copyFeatureLangMap,
  addCategoryItem,
  updateCategoryItem,
  addSubCategoryItem,
  updateSubCategoryItem,
  handleCompareFeatureHighlighted,
  draggableProvided,
  readOnly,
  showSplits,
  showLink,
  showSpecLinkModal,
  showRequiredDescription,
  showOptionalDescription,
  showTooltip,
  showGradeApplicability,
  sortMode,
  switchShortLongDescription,
  disclaimerTokens,
  brand,
  compareFeature,
}: FeatureRowProps) => {
  const { commonLanguageStore, teamStore, featuresStore } = useStores();

  const [openGradeAppModal, setOpenGradeAppModal] = useState(false);
  const [openSpecLinkModal, setSpecLinkModal] = useState(false);
  const defaultFeature = featureLangMap.langs[featuresStore.defaultLang];
  const selectedLangs = featuresStore.allLangs.filter(lang => featuresStore.selectedLangsMap[lang]);
  const index2 = featuresStore.filteredFeatureLangMaps.findIndex(
    x => x.langs[featuresStore.defaultLang].id === defaultFeature.id
  );
  const hasEnglishWritePerms = !!featuresStore.langWriteMap.EN?.canEdit;
  const [highlightedLangs, setHighlightedLangs] = useState<{ [lang: string]: boolean }>({});
  const { onClick: onClickLimitedDataStatus } = useFieldStatusModelUpdate({
    store: defaultFeature,
    callBack: updateFeatureStatus,
  });

  useEffect(() => {
    const highlighted: { [lang: string]: boolean } = {};
    featuresStore.allLangs.forEach(lang => {
      const feature = featureLangMap.langs[lang];
      highlighted[lang] = showGradeApplicability
        ? getIsHighlighted(feature)
        : feature.isHighlighted;
    });
    setHighlightedLangs(highlighted);
  }, [showGradeApplicability, featuresStore.allLangs, featureLangMap.langs]);

  /** Feature change handlers */
  const handleOnHyperLinkChange = (value: string) => {
    if (defaultFeature.link !== value) {
      featuresStore.allLangs.forEach(lang => {
        featureLangMap.langs[lang].link = value;
      });
      saveFeatureLangMap(featureLangMap);
    }
  };

  const handleOnCategorySelect = (
    categoryMap: CategoryLangMap,
    compareChangeMessageRequest: boolean,
    lang: string
  ) => {
    const newCategory = categoryMap[lang];
    const currentCategory = featureLangMap.langs[lang].category;
    if (
      newCategory.value &&
      (currentCategory.value !== newCategory.value || currentCategory.id !== newCategory.id)
    ) {
      featuresStore.editableLangs.forEach(lang => {
        featureLangMap.langs[lang].category = categoryMap[lang];
      });
      saveFeatureLangMap(featureLangMap, compareChangeMessageRequest);
    }
  };

  const handleOnSubCategorySelect = (
    subCategoryMap: CategoryLangMap,
    compareChangeMessageRequest: boolean,
    lang: string
  ) => {
    const newSubCategory = subCategoryMap[lang];
    const currentSubCategory = featureLangMap.langs[lang].subCategory;
    if (
      currentSubCategory.value !== newSubCategory.value ||
      currentSubCategory.id !== newSubCategory.id
    ) {
      featuresStore.editableLangs.forEach(lang => {
        featureLangMap.langs[lang].subCategory = subCategoryMap[lang];
      });
      saveFeatureLangMap(featureLangMap, compareChangeMessageRequest);
    }
  };

  const handleOnDescriptionChange = (value: string, lang: string) => {
    const feature = featureLangMap.langs[lang];
    if (areRichTextValuesDifferent(feature.description, value)) {
      feature.description = value;
      saveFeatureLangMap(featureLangMap, false, lang);
    }
  };

  const handleOnOptionalDescriptionChange = (value: string, lang: string) => {
    const feature = featureLangMap.langs[lang];
    if (switchShortLongDescription) {
      if (areRichTextValuesDifferent(feature.longDescription, value)) {
        feature.longDescription = value;
        saveFeatureLangMap(featureLangMap, false, lang);
      }
    } else {
      if (feature.shortDescription !== value) {
        feature.shortDescription = value;
        saveFeatureLangMap(featureLangMap, false, lang);
      }
    }
  };

  const handleInProgressChange = () => {
    featuresStore.editableLangs.forEach(lang => {
      featureLangMap.langs[lang].isInProgress = !featureLangMap.langs[lang].isInProgress;
    });
    saveFeatureLangMap(featureLangMap, false);
  };

  const handleIsHighlightedChange = (lang: string) => {
    if (showGradeApplicability) {
      setOpenGradeAppModal(!openGradeAppModal);
    } else {
      featureLangMap.langs[lang].isHighlighted = !featureLangMap.langs[lang].isHighlighted;
      setHighlightedLangs({
        ...highlightedLangs,
        [lang]: featureLangMap.langs[lang].isHighlighted,
      });
      saveFeatureLangMap(featureLangMap, false, lang);
    }
  };

  const handleOnNotesChange = (value: string) => {
    let shouldUpdate = false;
    featuresStore.editableLangs.forEach(lang => {
      if (featureLangMap.langs[lang].notes !== value) {
        featureLangMap.langs[lang].notes = value;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveFeatureLangMap(featureLangMap, false);
    }
  };

  const handleOnTooltipChange = (lang: string) => {
    featureLangMap.langs[lang].tooltip = !featureLangMap.langs[lang].tooltip;
    saveFeatureLangMap(featureLangMap, false, lang);
  };

  const handleGradeApplicabilitySave = (gradeApplicability: GradeApplicability) => {
    featuresStore.allLangs.forEach(lang => {
      featureLangMap.langs[lang].gradeApplicability = gradeApplicability;
    });
    const highlights: { [lang: string]: boolean } = {};
    featuresStore.allLangs.forEach(lang => {
      highlights[lang] = getIsHighlighted(featureLangMap.langs[lang]);
    });
    setHighlightedLangs(highlights);
    saveFeatureLangMap(featureLangMap);
  };

  const handleOnComLangChange = (feature: FeatureItem, comLangId: string) => {
    if (comLangId !== feature.comLangId) {
      feature.comLangId = comLangId;
      saveFeatureLangMap(featureLangMap);
    }
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    featuresStore.editableLangs.forEach(lang => {
      const feature = featureLangMap.langs[lang];
      if (feature) {
        changed.push(...feature.changedAttributes);
      }
    });
    return changed;
  };

  const onSaveKeyFeatures = useCallback(
    (keyFeatureList: KeyFeatureType[]) =>
      saveFeatureLangMap(
        featureLangMap,
        undefined,
        Language.EN,
        undefined,
        undefined,
        keyFeatureList
      ),
    [saveFeatureLangMap, featureLangMap]
  );

  const data = commonLanguageStore.data
    .filter(item => item.id === defaultFeature.comLangId)
    .map(el => el.description);

  const comLangDescription = data.length ? convertToRichTextObject(data[0]).text : '';

  const isAcceptChangesVisible =
    !readOnly &&
    !!featuresStore.editableLangs.filter(lang => {
      let hasChange = false;
      for (const change of featureLangMap.langs[lang].changedAttributes) {
        if (change !== 'new' && change !== 'delete') {
          hasChange = true;
          break;
        }
      }
      return hasChange;
    }).length;

  const showSyncTdPR =
    brand === BRAND_TDPR && defaultFeature.fromTMNA && !readOnly && selectedLangs.length >= 1;

  const disabled = !hasEnglishWritePerms || readOnly;

  const { sortedList: sortedCat } = useCategoriesMapSort(featuresStore.categoriesMap, Language.EN);
  const { sortedList: sortedSubCat } = useCategoriesMapSort(
    featuresStore.subCategoriesMap,
    Language.EN
  );

  return (
    <>
      <TableRowWithShadow
        onFillRowHeightChange={(rowHeight: number) => {
          featuresStore.setRowHeight(featureLangMap, rowHeight);
        }}
        innerRef={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        className={cx(styles.tableRow)}
      >
        {!readOnly && sortMode && (
          <>
            <TableCell {...draggableProvided.dragHandleProps} border center>
              <TableDragIcon />
            </TableCell>
            <TableCell border center>
              <input
                className={cx(inputStyles.input, inputStyles.smallInput)}
                value={defaultFeature.sortOrder}
                onBlur={newIndex => {
                  handleOnLangMapSortNumberUpdate(
                    featuresStore.filteredFeatureLangMaps,
                    newIndex.target.value,
                    index2
                  );
                  featuresStore.filteredFeatureLangMaps = featuresStore.filteredFeatureLangMaps.slice();
                }}
                onChange={e =>
                  (defaultFeature.sortOrder =
                    parseInt(e.currentTarget.value, 10) > 0
                      ? parseInt(e.currentTarget.value, 10)
                      : '')
                }
              />
            </TableCell>
          </>
        )}
        {!readOnly && !sortMode && teamStore.team.allowAddDeleteData && (
          <ContextMenuCell
            itemType="Feature"
            description={convertToRichTextObject(defaultFeature.description).text}
            deleteItem={() => deleteFeatureLangMap(featureLangMap)}
            copyItem={() => copyFeatureLangMap(featureLangMap)}
          />
        )}
        {teamStore.team.showAcceptChanges && isAcceptChangesVisible && (
          <TableCell>
            <div id={`acceptChanges-${defaultFeature.id}`} className={styles.acceptChangesThumbsUp}>
              <IconTextButton
                id={`acceptChanges-checkbox-${defaultFeature.id}`}
                icon="thumbsUp"
                text=""
                onClick={() => {
                  saveFeatureLangMap(featureLangMap, false, undefined, true);
                }}
              />
            </div>
          </TableCell>
        )}
        {teamStore.team.showAcceptChanges &&
          !isAcceptChangesVisible &&
          featuresStore.hasChangedAttributes() && <TableCell></TableCell>}
        <TableCell className={styles.categoryColumn} spanClass={styles.flexColumn}>
          {showSyncTdPR && (
            <SyncTdPRButton
              selectedLangs={selectedLangs}
              id={defaultFeature.id}
              changedAttributes={changedAttributes()}
              onClick={() => {
                compareFeature(featureLangMap);
              }}
              className={styles.tdprButton}
            />
          )}
          {selectedLangs.map(lang => {
            const feature = featureLangMap.langs[lang];
            const currentCategory = feature.category;
            const currentCategoryValue: string = currentCategory.value;

            if (lang === Language.EN) {
              return (
                <DropdownEditorCell
                  key={`${lang}-${feature.id}-cat-ddec`}
                  error={(() => {
                    if (!featuresStore.langWriteMap[lang]?.canEdit || currentCategoryValue) {
                      // if you dont have write permissions or the current feature category has a value
                      return false;
                    }
                    return true;
                  })()}
                  disabled={readOnly || !featuresStore.langWriteMap[lang]?.canEdit}
                  onAdd={
                    !teamStore.team.canAddFromDropdown
                      ? undefined
                      : value => {
                          const payload: { [lang: string]: string } = { [lang]: value };
                          featuresStore.editableLangs.forEach(lang => {
                            if (!payload[lang]) {
                              payload[lang] = value;
                            }
                          });
                          addCategoryItem(payload);
                        }
                  }
                  value={currentCategoryValue}
                  renderList={onClose => (
                    <>
                      {sortedCat?.map(item => {
                        const categoryMap = item.categoryMap;
                        const category = item.category;

                        return (
                          <DropdownEditItem
                            key={category.id}
                            value={category.value}
                            isSelected={currentCategoryValue === category.value}
                            onEdit={(from, to) => {
                              if (to.length) {
                                const payload: { [lang: string]: string } = { [lang]: to };
                                if (!category.value) {
                                  addCategoryItem(payload, category.id);
                                } else {
                                  updateCategoryItem(categoryMap, payload);
                                }
                              }
                            }}
                            onClose={() => onClose()}
                            onSelect={() => {
                              handleOnCategorySelect(
                                categoryMap,
                                currentCategoryValue !== category.value,
                                lang
                              );
                              onClose();
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                />
              );
            }

            return (
              <div key={currentCategoryValue}>
                <Input
                  defaultValue={currentCategoryValue}
                  onBlur={e => {
                    const value = e.currentTarget.value.trim();
                    if (value.length && value !== currentCategoryValue) {
                      const payload: { [lang: string]: string } = { [lang]: value };
                      if (!currentCategoryValue) {
                        // if the category doesnt have a value then we are going to add it
                        addCategoryItem(payload, currentCategory.id);
                      } else {
                        updateCategoryItem(
                          featuresStore.categoriesMap.categories[currentCategory.id],
                          payload
                        );
                      }
                    }
                  }}
                  name="name"
                  disabled={readOnly || !currentCategory.id}
                  placeholder=""
                  className={
                    feature.changedAttributes.includes('categoryId') || !currentCategoryValue
                      ? styles.errorText
                      : ''
                  }
                />
              </div>
            );
          })}
        </TableCell>
        <TableCell
          className={cx(styles.categoryColumn)}
          spanClass={cx(
            styles.flexColumn,
            (showSyncTdPR || defaultFeature.fromTMNA) &&
              !readOnly &&
              styles.alignCategorySubCategory
          )}
        >
          {showSyncTdPR && defaultFeature.fromTMNA && (
            /* align the subCat Dropdown with the Cat dropdown with a NAT CTA */
            <div className={defaultFeature.fromTMNA && styles.natBtnContainer}></div>
          )}
          {selectedLangs.map(lang => {
            const feature = featureLangMap.langs[lang];
            const currentSubCategory = feature.subCategory;
            const currentSubCategoryValue: string = currentSubCategory.value;

            if (lang === Language.EN) {
              return (
                <DropdownEditorCell
                  key={`${lang}-${feature.id}-subcat-ddec`}
                  error={(() => {
                    if (!featuresStore.langWriteMap[lang]?.canEdit || currentSubCategoryValue) {
                      return false;
                    }
                    return true;
                  })()}
                  disabled={readOnly || !featuresStore.langWriteMap[lang]?.canEdit}
                  onAdd={
                    !teamStore.team.canAddFromDropdown
                      ? undefined
                      : value => {
                          const payload: { [lang: string]: string } = { [lang]: value };
                          featuresStore.editableLangs.forEach(lang => {
                            if (!payload[lang]) {
                              payload[lang] = value;
                            }
                          });
                          addSubCategoryItem(payload);
                        }
                  }
                  value={currentSubCategoryValue}
                  renderList={onClose => (
                    <>
                      {sortedSubCat?.map(item => {
                        const subCategoryLangMap = item.categoryMap;
                        const subCategory = item.category;

                        return (
                          <DropdownEditItem
                            key={subCategory.id}
                            value={subCategory.value}
                            isSelected={currentSubCategoryValue === subCategory.value}
                            onEdit={(from, to) => {
                              if (to.length) {
                                const payload: { [lang: string]: string } = { [lang]: to };
                                if (!subCategory.value) {
                                  // if the subcategory doesnt have a value then we are going to have to add it in the backend
                                  addSubCategoryItem(payload, subCategory.id);
                                } else {
                                  updateSubCategoryItem(subCategoryLangMap, payload);
                                }
                              }
                            }}
                            onClose={() => onClose()}
                            onSelect={() => {
                              handleOnSubCategorySelect(
                                subCategoryLangMap,
                                currentSubCategoryValue !== subCategory.value,
                                lang
                              );
                              onClose();
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                />
              );
            }
            return (
              <div key={currentSubCategoryValue}>
                <Input
                  required={
                    !currentSubCategoryValue || feature.changedAttributes.includes('subCategoryId')
                  }
                  defaultValue={currentSubCategoryValue}
                  onBlur={e => {
                    const value = e.currentTarget.value.trim();
                    if (value.length && value !== currentSubCategoryValue) {
                      const payload: { [lang: string]: string } = { [lang]: value };
                      if (!currentSubCategoryValue) {
                        addSubCategoryItem(payload, currentSubCategory.id);
                      } else {
                        updateSubCategoryItem(
                          featuresStore.subCategoriesMap.categories[currentSubCategory.id],
                          payload
                        );
                      }
                    }
                  }}
                  name="name"
                  disabled={readOnly || !currentSubCategory.id}
                  placeholder=""
                  className={
                    feature.changedAttributes.includes('subCategoryId') || !currentSubCategoryValue
                      ? styles.errorText
                      : ''
                  }
                />
              </div>
            );
          })}
        </TableCell>
        {showSpecLinkModal && !sortMode && (
          <TableCell colType="flags" className={styles.linkSpec}>
            <Tooltip
              toggleElement={
                <div>
                  <FontAwesomeIcon
                    onClick={() => setSpecLinkModal(!readOnly && !openSpecLinkModal)}
                    className={cx(styles.iconFlag, defaultFeature.comLangId && styles.iconBlue)}
                    icon={faLink}
                  />
                </div>
              }
              popoverElement={
                <div className={styles.modelPopover}>
                  <h4>Linked Common Language</h4>
                  <div className={styles.ClDescription}>{comLangDescription}</div>
                </div>
              }
              hide={!comLangDescription}
            />
          </TableCell>
        )}
        {showLink && (
          <LinkCell
            popoverType={brand === 'toyota' ? 'logos' : 'hyperlink'}
            disabled={disabled}
            link={defaultFeature.link}
            onClick={handleOnHyperLinkChange}
          />
        )}
        {showRequiredDescription &&
          selectedLangs.map(lang => {
            const feature = featureLangMap.langs[lang];
            return (
              <RichTextCell
                key={`${lang}-${feature.id}-req-desc-rtc`}
                required={!feature.description || feature.changedAttributes.includes('description')}
                colType="description"
                suggestionTypes={disclaimerTokens}
                disabled={readOnly || !featuresStore.langWriteMap[lang]?.canEdit}
                value={feature.description}
                onBlur={value => {
                  handleOnDescriptionChange(value, lang);
                }}
              />
            );
          })}
        {showOptionalDescription &&
          selectedLangs.map(lang => {
            const feature = featureLangMap.langs[lang];
            return (
              <RichTextCell
                key={`${lang}-${feature.id}-opt-desc-rtc`}
                colType="description"
                suggestionTypes={disclaimerTokens}
                disabled={readOnly || !featuresStore.langWriteMap[lang]?.canEdit}
                value={
                  switchShortLongDescription ? feature.longDescription : feature.shortDescription
                }
                onBlur={value => {
                  handleOnOptionalDescriptionChange(value, lang);
                }}
              />
            );
          })}
        {showOptionalDescription &&
          showTooltip &&
          selectedLangs.map(lang => {
            const feature = featureLangMap.langs[lang];
            return (
              <TableCell colType="flags" key={`${lang}-${feature.id}-tooltip-tc`}>
                {!readOnly && featuresStore.langWriteMap[lang]?.canEdit && (
                  <Checkbox
                    id={`tooltipChbox${feature.uid}`}
                    small
                    checked={feature.tooltip}
                    onChange={() => handleOnTooltipChange(lang)}
                  >
                    <CheckboxLabel small>Tooltip</CheckboxLabel>
                  </Checkbox>
                )}
                {(readOnly || !featuresStore.langWriteMap[lang]?.canEdit) && feature.tooltip && (
                  <IconTextButton smallIcon icon="circle" text="Tooltip" />
                )}
              </TableCell>
            );
          })}
        <FlagsCell
          allowGradeApplicability={teamStore.team.allowGradeApplicability}
          disabled={disabled}
          index={`${index}-flagcell`}
          notes={defaultFeature.notes}
          rejectNotes={defaultFeature.rejectNotes}
          highlighted={defaultFeature.isHighlighted}
          inProgress={defaultFeature.isInProgress}
          toggleInProgress={() => {
            if (!disabled) {
              handleInProgressChange();
            }
          }}
          toggleHighlighted={() => {
            if (!disabled) {
              handleIsHighlightedChange(featuresStore.defaultLang);
            }
          }}
          toggleCompareArrow={() => {
            if (!disabled) {
              handleCompareFeatureHighlighted(featureLangMap);
            }
          }}
          isCompareHighlighted={!!defaultFeature.compareFeatureId}
          onNotesChange={notes => {
            handleOnNotesChange(notes);
          }}
          className={cx({
            [styles.flagsCellLarger]: !showOptionalDescription && !showRequiredDescription,
          })}
          allowCompareFeature={teamStore.team.allowCompareFeatures}
          displayLimitedDataStatus={
            teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true'
          }
          limitedDataStatus={defaultFeature.fieldStatus?.status}
          onClickLimitedDataStatus={onClickLimitedDataStatus}
          hideInProgress={process.env.REACT_APP_LIMITED_DATA === 'true' && brand !== BRAND_TDPR}
          keyFeatureComponent={
            <KeyFeature
              readOnly={disabled}
              featureLangMap={featureLangMap}
              onSave={onSaveKeyFeatures}
            />
          }
        />
      </TableRowWithShadow>

      <Modal size="xl" open={openSpecLinkModal} onClose={() => setSpecLinkModal(false)}>
        <SpecLinkModal
          tabName="Link Feature"
          comLangId={defaultFeature.comLangId}
          onAddItem={comLangId => handleOnComLangChange(defaultFeature, comLangId)}
          onClose={() => setSpecLinkModal(false)}
        />
      </Modal>

      <Modal open={openGradeAppModal} onClose={() => setOpenGradeAppModal(false)}>
        <GradeApplicabilityModal
          gradeApplicability={defaultFeature.gradeApplicability}
          onConfirm={handleGradeApplicabilitySave}
          onClose={() => setOpenGradeAppModal(false)}
          readOnly={readOnly}
        />
      </Modal>
    </>
  );
};

export default observer(FeatureRow);
