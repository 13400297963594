import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Spinner from '../../../../components/Spinner';
import { TableRow, TwoTableWrapper } from '../../../../components/Table';
import { TableTabs } from '../../../../components/Table/components/TableTabs';
import { uiBlock } from '../../../../components/UiBlocker/uiBlock';
import useQuery from '../../../../hooks/useQuery';
import useStores from '../../../../hooks/useStores';
import { ChangeLogTypes } from '../../../../models/changeLog.model';
import {
  OptionsPackage,
  OptionsPackageSpec,
  OptionsPackageVariety,
  OptionsTabType,
  VDPackageLexus,
} from '../../../../models/optionsLexus.model';
import { ReviewChangeBaseItem, ReviewChangeRequest } from '../../../../models/review.model';
import ActionBarVehicleData from '../../../../routes/vehicleData/components/ActionBarVehicleData';
import LeftTable from '../../../../routes/vehicleData/components/LeftTable';
import ModelTable from '../../../../routes/vehicleData/components/ModelTable';
import SettingsCell from '../../../../routes/vehicleData/components/ModelTable/components/SettingsCell';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { optionsLexusXForm, optionsOptionLexusXForm } from '../../../../utils/optionsLexusUtils';
import { updateRevIdById } from '../../../../utils/reviewUtils';
import {
  getReviewOptions,
  updateReviewOption,
  updateReviewOptionSpec,
  updateReviewOptionVariety,
} from '../../../../webservices/vehicleOptionsApi';
import OptionsLexusDividerRow from './components/OptionsLexusDividerRow';
import OptionsLexusFilters from './components/OptionsLexusFilters';
import OptionsLexusReviewHeaderRow from './components/OptionsLexusHeaderRow/OptionsLexusReviewHeaderRow';
import OptionsLexusPackageAddDeleteReviewRow from './components/OptionsLexusPackageRow/reviewRows/OptionsLexusPackageAddDeleteReviewRow';
import OptionsLexusPackageReviewRow from './components/OptionsLexusPackageRow/reviewRows/OptionsLexusPackageReviewRow';
import OptionsLexusPackageSpecReviewCells from './components/OptionsLexusPackageRow/reviewRows/OptionsLexusPackageSpecReviewCells';
import OptionsLexusSpacerRow from './components/OptionsLexusSpacerRow';
import PackageTableRow from './components/PackageTableRow';
import { renderTotalApplicabilityReview } from './renderFunctions/renderApplicabilityReview';
import { renderSpecApplicabilityReview } from './renderFunctions/renderModelApplicabililtyReview';
import { renderPackageReviewCells } from './renderFunctions/renderPackageReviewCells';
import { renderSpecReviewRows } from './renderFunctions/renderSpecReviewRows';
import { renderTotalReviewRows } from './renderFunctions/renderTotalReviewRows';
import { toGqlBrand, toGqlTeam, toGqlFilter } from '../../../../utils/graphqlUtils';

const OptionsLexusReview = ({
  readOnly,
  team,
  seriesId,
  year,
  version = '',
  vehicleModels,
}: ProductDataControllerProps) => {
  const {
    optionsLexusReviewStore,
    reviewStore: { hideAccept },
    teamStore,
    userStore: { brand },
  } = useStores();

  const query = useQuery();
  const optionsTabParam = (query.get('optionsTab') || 'packages') as OptionsTabType;
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [tabDisplay, setTabDisplay] = useState(
    optionsTabParam === 'options' ? 'Options' : 'Packages'
  );
  const [optionsTab, setOptionsTab] = useState<OptionsTabType>(optionsTabParam);
  const history = useHistory();

  useEffect(() => {
    setIsLoaded(false);
    optionsLexusReviewStore.reset();

    (async () => {
      try {
        const response = await getReviewOptions<VDPackageLexus>({
          brand: toGqlBrand(brand),
          team: toGqlTeam(teamStore.team.param),
          seriesId: seriesId,
          modelYear: parseInt(year),
          filter: toGqlFilter(version),
          optionTab: optionsTab,
        });
        optionsLexusReviewStore.setItems(
          optionsTab === 'options'
            ? optionsOptionLexusXForm(response, vehicleModels)
            : optionsLexusXForm(response, vehicleModels)
        );
        setHasLoadingError(false);
      } catch (e) {
        toast.error('Error loading options data');
        setHasLoadingError(true);
      }

      setIsLoaded(true);
    })();
  }, [
    optionsTab,
    brand,
    optionsLexusReviewStore,
    seriesId,
    teamStore,
    vehicleModels,
    year,
    version,
  ]);

  const updatePackage = async (option: OptionsPackage, change: ReviewChangeBaseItem) => {
    try {
      if (change.isValid()) {
        uiBlock.start();

        const payload: ReviewChangeRequest = {
          ...change.getPayload(),
          id: option.id,
          revId: option.revId,
        };

        const response = await trackPromise(
          updateReviewOption({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId: seriesId,
            modelYear: parseInt(year),
            filter: toGqlFilter(version),
            optionTab: optionsTab,
            payload: {
              id: payload.id,
              revId: payload.revId,
              changeTypeId: payload.changeTypeId,
              isAccepted: payload.isAccepted,
              isApplied: payload.isApplied,
              rejectNotes: payload.rejectNotes,
            },
          })
        );
        const { id, revId } = response;
        updateRevIdById(id, revId, option.specs);
        option.revId = response.revId;
        toast.success('Successfully updated option');
      } else {
        toast.error('Please fill in the required fields for the option');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating option');
    }

    uiBlock.stop();
  };

  const updateSpec = async (
    option: OptionsPackage,
    spec: OptionsPackageSpec,
    change: ReviewChangeBaseItem
  ) => {
    try {
      if (change.isValid()) {
        uiBlock.start();

        const payload: ReviewChangeRequest = {
          ...change.getPayload(),
          id: spec.id,
          revId: spec.revId,
          parentId: option.id,
          parentRevId: option.revId,
        };

        const response = await trackPromise(
          updateReviewOptionSpec({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId: seriesId,
            modelYear: parseInt(year),
            filter: toGqlFilter(version),
            optionTab: optionsTab,
            payload: {
              parentId: payload.parentId!,
              parentRevId: payload.parentRevId!,
              id: payload.id,
              revId: payload.revId,
              changeTypeId: payload.changeTypeId,
              isAccepted: payload.isAccepted,
              isApplied: payload.isApplied,
              rejectNotes: payload.rejectNotes,
            },
          })
        );
        const { id, revId } = response;
        updateRevIdById(id, revId, option.specs);
        spec.revId = response.revId;
        toast.success('Successfully updated spec');
      } else {
        toast.error('Please fill in the required fields for the spec');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating spec');
    }

    uiBlock.stop();
  };

  const updateTotalApplicability = async (option: OptionsPackage, change: ReviewChangeBaseItem) => {
    try {
      if (change.isValid()) {
        uiBlock.start();

        const payload: ReviewChangeRequest = {
          ...change.getPayload(),
          id: option.id,
          revId: option.revId,
        };

        const response = await trackPromise(
          updateReviewOption({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId: seriesId,
            modelYear: parseInt(year),
            filter: toGqlFilter(version),
            optionTab: optionsTab,
            payload: {
              id: payload.id,
              revId: payload.revId,
              changeTypeId: payload.changeTypeId,
              isAccepted: payload.isAccepted,
              isApplied: payload.isApplied,
              rejectNotes: payload.rejectNotes,
            },
          })
        );
        const { id, revId } = response;
        updateRevIdById(id, revId, optionsLexusReviewStore.reviewItems);
        option.revId = response.revId;
        toast.success('Successfully updated option');
      } else {
        toast.error('Please fill in the required fields for the option');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating option');
    }

    uiBlock.stop();
  };

  const updateVariety = async (
    option: OptionsPackage,
    variety: OptionsPackageVariety,
    change: ReviewChangeBaseItem
  ) => {
    try {
      if (change.isValid()) {
        uiBlock.start();

        const payload: ReviewChangeRequest = {
          ...change.getPayload(),
          id: variety.id,
          revId: variety.revId,
          parentId: option.id,
          parentRevId: option.revId,
        };

        const response = await trackPromise(
          updateReviewOptionVariety({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId: seriesId,
            modelYear: parseInt(year),
            filter: toGqlFilter(version),
            optionTab: optionsTab,
            payload: {
              parentId: payload.parentId!,
              parentRevId: payload.parentRevId!,
              id: payload.id,
              revId: payload.revId,
              changeTypeId: payload.changeTypeId,
              isAccepted: payload.isAccepted,
              isApplied: payload.isApplied,
              rejectNotes: payload.rejectNotes,
            },
          })
        );
        const { id, revId } = response;
        updateRevIdById(id, revId, option.packageVarieties);
        variety.revId = response.revId;
        toast.success('Successfully updated variety');
      } else {
        toast.error('Please fill in the required fields for the variety');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating variety');
    }

    uiBlock.stop();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBarVehicleData
        readOnly={readOnly}
        toggleViewModelCodes={() =>
          (optionsLexusReviewStore.viewModelCodes = !optionsLexusReviewStore.viewModelCodes)
        }
        viewModelCodes={optionsLexusReviewStore.viewModelCodes}
        searchText={optionsLexusReviewStore.searchText}
        onSearchTextChange={text =>
          optionsLexusReviewStore.onFilter(() => (optionsLexusReviewStore.searchText = text))
        }
        renderFilter={onClose => (
          <OptionsLexusFilters
            onClose={onClose}
            isInProgressFilter={optionsLexusReviewStore.isInProgressFilter}
            setIsInProgressFilter={value =>
              optionsLexusReviewStore.onFilter(
                () => (optionsLexusReviewStore.isInProgressFilter = value)
              )
            }
          />
        )}
      />
      <TableTabs
        tabs={['Packages', 'Options']}
        currentTab={tabDisplay}
        style={{ zIndex: 1 }}
        onSelectTab={value => {
          const valLower = value.toLowerCase();
          const version = query.get('version') ? `&version=${query.get('version')}` : '';
          setTabDisplay(value);
          setOptionsTab(valLower as OptionsTabType);
          history.push(
            `?team=${query.get('team')}${version}&tab=${query.get('tab')}&optionsTab=${valLower}`
          );
          optionsLexusReviewStore.clearFilters();
        }}
      />
      {hasLoadingError ? null : (
        <TwoTableWrapper>
          <LeftTable>
            <OptionsLexusReviewHeaderRow hideAccept={hideAccept} />
            <tbody>
              {optionsLexusReviewStore.filteredReviewItems.map(option => (
                <React.Fragment key={option.uid}>
                  {
                    // SPEC, TOTALS, VARIETIES CHANGES
                    (option.getSpecChangesCount() > 0 ||
                      option.getVarietyChangesCount() > 0 ||
                      option.packageTotals.changes.length > 0 ||
                      option.packageTotalReqs.changes.length > 0) && (
                      <>
                        <OptionsLexusPackageReviewRow
                          key={`specs-${option.uid}`}
                          nameSpan={
                            option.getVarietyChangesCount() > 0
                              ? option.getSpecChangesCount() || 1
                              : (option.getSpecChangesCount() || 1) +
                                (option.packageTotals.changes.length +
                                  option.packageTotalReqs.changes.length)
                          }
                          requiredSpan={option.getSpecChangesCount() || 1}
                          optionsTab={optionsTab}
                          optionsPackage={option}
                          expandRow={option.getSpecChangesCount() < 2}
                          zebra={option.getSpecChangesCount() > 0}
                          onUpdate={updateSpec}
                          hideAccept={hideAccept}
                        />
                        {renderSpecReviewRows(option, updateSpec, hideAccept)}
                        {renderTotalReviewRows(
                          option,
                          updateTotalApplicability,
                          updateVariety,
                          hideAccept
                        )}
                        <OptionsLexusDividerRow />
                      </>
                    )
                  }

                  {
                    // PACKAGE CHANGES
                    option.getPackageCountByChangeTypes() > 0 && (
                      <>
                        <OptionsLexusPackageReviewRow
                          key={`option-update-${option.uid}`}
                          change={option.changes[0]}
                          nameSpan={option.changes.length || 1}
                          requiredSpan={option.changes.length || 1}
                          optionsTab={optionsTab}
                          optionsPackage={option}
                          onUpdate={(optionItem, specItem, changeItem) =>
                            updatePackage(optionItem, changeItem)
                          }
                          hideAccept={hideAccept}
                        />
                        {option.changes.map((change, index) =>
                          index === 0 ? null : (
                            <TableRow
                              key={change.uid}
                              onFillRowHeightChange={height => {
                                change.rowHeight = height;
                              }}
                            >
                              {renderPackageReviewCells(
                                option,
                                change,
                                updatePackage,
                                undefined,
                                hideAccept
                              )}
                            </TableRow>
                          )
                        )}
                        <OptionsLexusDividerRow />
                      </>
                    )
                  }

                  {
                    // PACKAGED ADDED
                    option.getPackageCountByChangeTypes([
                      ChangeLogTypes.PACKAGE_ADDED,
                      ChangeLogTypes.OPTION_ADDED,
                    ]) > 0 && (
                      <>
                        <OptionsLexusPackageAddDeleteReviewRow
                          key={`option-added-${option.uid}`}
                          change={option.changes[0]}
                          spec={option.specs[0]}
                          nameSpan={option.specs.length || 1}
                          requiredSpan={option.specs.length || 1}
                          optionsTab={optionsTab}
                          optionsPackage={option}
                          zebra={option.getSpecChangesCount() > 0}
                          onUpdate={updatePackage}
                          hideAccept={hideAccept}
                        />
                        {option.specs.map((spec, index) =>
                          index === 0 ? null : (
                            <PackageTableRow
                              key={spec.uid}
                              zebra
                              onFillRowHeightChange={height => (spec.rowHeight = height)}
                            >
                              <OptionsLexusPackageSpecReviewCells spec={spec} />
                            </PackageTableRow>
                          )
                        )}
                        <OptionsLexusDividerRow />
                      </>
                    )
                  }

                  {
                    // PACKAGED DELETED
                    option.getPackageCountByChangeTypes([
                      ChangeLogTypes.PACKAGE_DELETED,
                      ChangeLogTypes.OPTION_DELETED,
                    ]) > 0 && (
                      <>
                        <OptionsLexusPackageAddDeleteReviewRow
                          key={`option-delete-${option.uid}`}
                          change={option.changes[0]}
                          spec={option.specs[0]}
                          nameSpan={option.specs.length || 1}
                          requiredSpan={option.specs.length || 1}
                          optionsTab={optionsTab}
                          optionsPackage={option}
                          zebra={option.getSpecChangesCount() > 0}
                          onUpdate={updatePackage}
                          hideAccept={hideAccept}
                          isDelete
                        />
                        {option.specs.map((spec, index) =>
                          index === 0 ? null : (
                            <PackageTableRow
                              key={spec.uid}
                              isDelete
                              onFillRowHeightChange={height => (spec.rowHeight = height)}
                            >
                              <OptionsLexusPackageSpecReviewCells spec={spec} />
                            </PackageTableRow>
                          )
                        )}
                        <OptionsLexusDividerRow />
                      </>
                    )
                  }
                </React.Fragment>
              ))}
            </tbody>
          </LeftTable>

          <ModelTable
            showFeatureSplits={false}
            viewModelCodes={optionsLexusReviewStore.viewModelCodes}
            models={vehicleModels}
            headerStyle={{ top: 0 }}
            renderRows={() => (
              <>
                {optionsLexusReviewStore.filteredReviewItems.map(option => {
                  return (
                    <React.Fragment key={option.uid}>
                      {
                        // Spec changes
                        option.getSpecChangesCount() > 0 && (
                          <>
                            {option.getSpecChanges().map(spec => (
                              <React.Fragment key={spec.uid}>
                                {spec.changes.map(change => (
                                  <TableRow key={change.uid} rowHeight={change.rowHeight}>
                                    {vehicleModels.map(
                                      (model, index) =>
                                        model.show &&
                                        spec.models[index] && (
                                          <SettingsCell
                                            key={`${option.uid}${model.uid}`}
                                            disabled={true}
                                            model={spec.models[index]}
                                            oddRow={false}
                                            rowSpan={1}
                                            onUpdate={() => void 0}
                                          />
                                        )
                                    )}
                                  </TableRow>
                                ))}
                              </React.Fragment>
                            ))}
                          </>
                        )
                      }

                      {option.getSpecChangesCount() === 0 &&
                        (option.getVarietyChangesCount() > 0 ||
                          option.packageTotals.changes.length > 0 ||
                          option.packageTotalReqs.changes.length > 0) && (
                          <TableRow rowHeight={option.specVarietyRowHeight}>
                            <td>
                              <div style={{ width: '160px' }} />
                            </td>
                          </TableRow>
                        )}

                      {
                        // total applicabililty
                        option.packageTotals.changes.length > 0 &&
                          option.getVarietyChangesCount() === 0 &&
                          renderTotalApplicabilityReview(
                            option.packageTotals.changes,
                            option.packageTotals.applicability,
                            option.isOption,
                            option.packageTotals.rowHeight
                          )
                      }

                      {
                        // total required applicatbility
                        option.packageTotalReqs.changes.length > 0 &&
                          option.getVarietyChangesCount() === 0 &&
                          renderTotalApplicabilityReview(
                            option.packageTotalReqs.changes,
                            option.packageTotalReqs.applicability,
                            true,
                            option.packageTotalReqs.rowHeight
                          )
                      }

                      {
                        // package variety
                        option.packageVarieties.map(variety => (
                          <React.Fragment key={variety.uid}>
                            {renderTotalApplicabilityReview(
                              variety.changes,
                              variety.applicability,
                              true,
                              variety.rowHeight
                            )}
                          </React.Fragment>
                        ))
                      }

                      {(option.packageTotals.changes.length > 0 ||
                        option.packageTotalReqs.changes.length > 0 ||
                        option.getSpecChangesCount() > 0 ||
                        option.getVarietyChangesCount() > 0) && <OptionsLexusDividerRow />}

                      {
                        // Package changes
                        option.getPackageCountByChangeTypes() > 0 && (
                          <React.Fragment key={`package-changes-default${option.uid}`}>
                            {option.changes.map(change => (
                              <OptionsLexusSpacerRow rowHeight={change.rowHeight} count={1} />
                            ))}
                            <OptionsLexusDividerRow />
                          </React.Fragment>
                        )
                      }

                      {
                        // Package added
                        option.getPackageCountByChangeTypes([
                          ChangeLogTypes.PACKAGE_ADDED,
                          ChangeLogTypes.OPTION_ADDED,
                        ]) > 0 && (
                          <React.Fragment key={`package-changes-added${option.uid}`}>
                            {option.specs.length > 0 ? (
                              renderSpecApplicabilityReview(option.specs, vehicleModels)
                            ) : (
                              <TableRow rowHeight={option.rowHeight}>
                                <td>
                                  <div style={{ width: '160px' }} />
                                </td>
                              </TableRow>
                            )}
                            <OptionsLexusDividerRow />
                          </React.Fragment>
                        )
                      }

                      {
                        //  Package deleted
                        option.getPackageCountByChangeTypes([
                          ChangeLogTypes.PACKAGE_DELETED,
                          ChangeLogTypes.OPTION_DELETED,
                        ]) > 0 && (
                          <React.Fragment key={`package-changes-del${option.uid}`}>
                            {option.specs.length > 0 ? (
                              renderSpecApplicabilityReview(option.specs, vehicleModels)
                            ) : (
                              <TableRow rowHeight={option.rowHeight}>
                                <td>
                                  <div style={{ width: '160px' }} />
                                </td>
                              </TableRow>
                            )}
                            <OptionsLexusDividerRow />
                          </React.Fragment>
                        )
                      }
                    </React.Fragment>
                  );
                })}
              </>
            )}
          />
        </TwoTableWrapper>
      )}
    </>
  );
};

export default observer(OptionsLexusReview);
