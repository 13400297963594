import React, { useCallback } from 'react';
import {
  ChangeLogItem,
  ChangeLogTypes,
  ModelApplicabilityChanges,
} from '../models/changeLog.model';
import { KeyFeatureOptioneMap } from '../models/features.model';
import KeyFeaturesChangeLog from '../routes/vehicleData/tabModules/features/components/KeyFeaturesChangeLog';
import useChangeLogChanges from './useChangeLogChanges';
import useKeyFeatureOptionsLoad from './useKeyFeatureOptionsLoad';

export enum KEY_FEATURE_CHANGELOG_CHANGE_TITLE {
  ADDED = 'Added',
  DESCRIPTION = 'Description',
  DELETED = 'Deleted',
}

const useFeaturesChangeLogChanges = () => {
  const { showKeyFeature } = useKeyFeatureOptionsLoad();

  const {
    filterChangeLogChanges: filterChangeLogChangesDefault,
    changeLogChanges: changeLogChangesDefault,
    canRevert: canRevertDefault,
  } = useChangeLogChanges(
    ChangeLogTypes.FEATURE_STATUS,
    ChangeLogTypes.MODEL_APPLICABILITY_STATUS,
    ChangeLogTypes.ALL_FEATURE_STATUS
  );

  const changeLogChanges = useCallback(
    (log: ChangeLogItem) => {
      switch (log.changeType) {
        case ChangeLogTypes.KEY_FEATURE:
          return <KeyFeaturesChangeLog log={log} />;
        default:
          return changeLogChangesDefault(log);
      }
    },
    [changeLogChangesDefault]
  );

  const canRevert = useCallback(
    (log: ChangeLogItem) => {
      switch (log.changeType) {
        case ChangeLogTypes.KEY_FEATURE: {
          const { before = {}, after = {} } = log;
          const dKeys = Object.keys(before ?? {}).filter(key => !(after as any)?.[key]);
          return !dKeys.length && log.canRevert;
        }
        default: {
          return canRevertDefault(log);
        }
      }
    },
    [canRevertDefault]
  );

  const mapKeyFeatureLogAdd = useCallback(
    (log: ChangeLogItem, keyFeaturesMap: KeyFeatureOptioneMap) => {
      const { before = {}, after = {} } = log;

      const aKeys = Object.keys(after ?? {}).filter(key => !(before as any)?.[key]);
      aKeys.forEach(key => {
        const aChange = keyFeaturesMap[key];

        const change: ModelApplicabilityChanges = {
          title: KEY_FEATURE_CHANGELOG_CHANGE_TITLE.ADDED,
          after: aChange?.name.EN,
        };

        log.applicabilityChanges.push(change);
      });
    },
    []
  );

  const mapKeyFeatureLogDelete = useCallback(
    (log: ChangeLogItem, keyFeaturesMap: KeyFeatureOptioneMap) => {
      const { before = {}, after = {} } = log;

      const dKeys = Object.keys(before ?? {}).filter(key => !(after as any)?.[key]);
      let description: string[] = [];

      dKeys.forEach(key => {
        const bChange = keyFeaturesMap[key];

        const change: ModelApplicabilityChanges = {
          title: KEY_FEATURE_CHANGELOG_CHANGE_TITLE.DELETED,
        };

        log.applicabilityChanges.push(change);
        description.push(bChange?.name.EN);
      });

      return description.join(', ');
    },
    []
  );

  const changeLogKeyFeatureModelApplicabilityMapper = useCallback(
    (log: ChangeLogItem, keyFeaturesMap: KeyFeatureOptioneMap) => {
      if ((log.changeType as string) !== ChangeLogTypes.KEY_FEATURE) return;
      if (!log.before) log.before = '';

      mapKeyFeatureLogAdd(log, keyFeaturesMap);
      const dDesc = mapKeyFeatureLogDelete(log, keyFeaturesMap);

      if (dDesc) {
        log.description = dDesc;
      }
    },
    [mapKeyFeatureLogAdd, mapKeyFeatureLogDelete]
  );

  const filterChangeLogChanges = useCallback(
    (log: ChangeLogItem) => {
      switch (log.changeType) {
        case ChangeLogTypes.KEY_FEATURE: {
          return showKeyFeature;
        }
        default: {
          return filterChangeLogChangesDefault(log);
        }
      }
    },
    [showKeyFeature, filterChangeLogChangesDefault]
  );

  return {
    filterChangeLogChanges,
    changeLogChanges,
    canRevert,
    changeLogKeyFeatureModelApplicabilityMapper,
  };
};

export default useFeaturesChangeLogChanges;
