import React from 'react';
import { FilterTabColumn, FilterTabColumnList, FilterTabColumns } from 'vapi-ui-common';
import { ChecklistItem } from '../../hooks/useChecklist';
import Checkbox from '../Checkbox';
import styles from './VehicleFilter.module.scss';

export interface IVehicleFilter {
  isAllSelected: boolean;
  handleOnAllChecked: (checked: boolean) => void;
  checklist: ChecklistItem[];
  handleOnChecked: (item: any, checked: boolean) => void;
}

const VehicleFilter = ({
  isAllSelected,
  handleOnAllChecked,
  checklist,
  handleOnChecked,
}: IVehicleFilter) => {
  return (
    <>
      <FilterTabColumnList className={styles.columnList}>
        <Checkbox
          id="chboxAll"
          checked={isAllSelected}
          onChange={e => handleOnAllChecked(e.currentTarget.checked)}
          bold
        >
          All Vehicles
        </Checkbox>
      </FilterTabColumnList>
      <FilterTabColumns>
        {/* All Vehicles Checkbox */}
        {checklist.map(category => (
          <FilterTabColumn key={category.name}>
            {/* Category checkbox */}
            <Checkbox
              id={`chbox${category.name}`}
              checked={category.selected}
              onChange={e => handleOnChecked(category, e.currentTarget.checked)}
            >
              {category.name}
            </Checkbox>
            <FilterTabColumnList className={styles.columnList}>
              {/* Vehicle Checkbox */}
              {category?.items?.map(item => (
                <li key={item.name}>
                  <Checkbox
                    id={`chbox${item.name}`}
                    checked={item.selected}
                    onChange={e => handleOnChecked(item, e.currentTarget.checked)}
                  >
                    {item.name}
                  </Checkbox>
                </li>
              ))}
            </FilterTabColumnList>
          </FilterTabColumn>
        ))}
      </FilterTabColumns>
    </>
  );
};

export default VehicleFilter;
