import { observer } from 'mobx-react-lite';
import React from 'react';
import Checkbox from '../../../../../../../../../components/Checkbox';
import { TableRow } from '../../../../../../../../../components/Table';
import { ColorsLexusExteriorItem } from '../../../../../../../../../models/colorsLexus.model';
import styles from './colorsLexusInteriorRow.module.scss';

interface ColorsLexusInteriorRowProps {
  exteriorItem: ColorsLexusExteriorItem;
  rowHeight?: number;
  readOnly?: boolean;
  saveExterior?(exteriorItem: ColorsLexusExteriorItem): void;
}

const ColorsLexusInteriorRow = ({
  exteriorItem,
  rowHeight,
  readOnly,
  saveExterior = () => void 0,
}: ColorsLexusInteriorRowProps) => {
  return (
    <TableRow zebra className={styles.row} rowHeight={rowHeight}>
      {exteriorItem.colorApplicability.map((group, index) => (
        <React.Fragment key={group.uid}>
          {group.interiors.map(interior => (
            <td key={interior.uid} className={styles.cell}>
              <div className={styles.cellContent}>
                <Checkbox
                  id={`chbox-${exteriorItem.uid}${interior.uid}`}
                  checked={interior.value}
                  disabled={readOnly || !interior.id}
                  onChange={() => {
                    interior.value = !interior.value;
                    saveExterior(exteriorItem);
                  }}
                  theme="greenCell"
                />
              </div>
            </td>
          ))}
          {index < exteriorItem.colorApplicability.length - 1 && (
            <td className={styles.spacerCell} />
          )}
        </React.Fragment>
      ))}
    </TableRow>
  );
};

export default observer(ColorsLexusInteriorRow);
