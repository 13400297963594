import React, { HTMLAttributes } from 'react';
import styles from './colorsLexusHeaderRow.module.scss';

interface TableDivRowProps extends HTMLAttributes<HTMLDivElement> {
  rowHeight?: number;
}

export const TableDivRow = ({ rowHeight, ...rest }: TableDivRowProps) => {
  return <div className={styles.headerRow} style={{ height: `${rowHeight}px` }} {...rest} />;
};
