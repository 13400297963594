import React from 'react';
import useStores from '../../../../../../hooks/useStores';
import {
  CompareFeatureItem,
  CompareFeatureLangMap,
  CompareGradeSettingsCell,
} from '../../../../../../models/compareFeatures.model';
import HighlightSortOrderCell from '../../../../components/GradeTable/components/HighlightSortOrderCell/HighlightSortOrderCell';
import SettingsCell from '../../../../components/ModelTable/components/SettingsCell';
import { Language } from '../../../../../../models/user.model';
import useFieldStatusGradeUpdate from '../../../../../../hooks/useFieldStatusGradeUpdate';
import { updateCompareFeatureStatus } from '../../../../../../webservices/vehicleCompareFeaturesApi';

interface CompareFeatureCellProps {
  highlightSortMode: boolean;
  gradeToggledArr: string[];
  grade: CompareGradeSettingsCell;
  index: number;
  numApplicability: number;
  defaultCompareFeature: CompareFeatureItem;
  idx: number;
  onUpdateSortOrder: (sortOrder: number, gradeId: string, targetCompareFeatureId: string) => void;
  readOnly?: boolean;
  changedGradeIds: string[];
  compareFeatureLangMap: CompareFeatureLangMap;
  saveCompareFeatureLangMap: (
    compareFeatureLangMap: CompareFeatureLangMap,
    compareChangeMessageRequest?: boolean,
    lang?: string,
    acceptChanges?: boolean
  ) => Promise<void>;
  setGradeCellStatusChange?: (val: boolean) => void;
}

const CompareFeatureCell = ({
  highlightSortMode,
  gradeToggledArr,
  grade,
  index,
  numApplicability,
  defaultCompareFeature,
  idx,
  onUpdateSortOrder,
  readOnly,
  changedGradeIds,
  compareFeatureLangMap,
  saveCompareFeatureLangMap,
  setGradeCellStatusChange,
}: CompareFeatureCellProps) => {
  const { compareFeatureStore, teamStore } = useStores();
  const { onClickGradeApplicability: onClickLimitedDataStatus } = useFieldStatusGradeUpdate({
    store: defaultCompareFeature,
    grade,
    callBack: updateCompareFeatureStatus,
    setGradeCellStatusChange,
  });

  if (highlightSortMode) {
    const isActive = gradeToggledArr.includes(grade.id);
    const leftBorder = isActive && index > 0;
    const rightBorder = isActive && index < numApplicability - 1;
    // max sort value is the amount of highlighted compare features for the current grade
    const maxSortValue = !isActive
      ? 0
      : Object.keys(compareFeatureStore.highlightSortList[grade.id].sortList).length;
    return (
      <HighlightSortOrderCell
        key={`${defaultCompareFeature.uid}${grade.id}`}
        grade={grade}
        oddRow={idx % 2 === 1 && isActive}
        rowSpan={1}
        onUpdateSortOrder={onUpdateSortOrder}
        isActive={isActive}
        leftBorder={leftBorder}
        rightBorder={rightBorder}
        maxSortValue={maxSortValue}
      />
    );
  }

  const canAddAppText =
    compareFeatureStore.fullEditPermissions ||
    !!defaultCompareFeature.gradeApplicability[grade.id].text;
  return (
    <SettingsCell
      key={`${defaultCompareFeature.uid}${grade.id}`}
      disabled={readOnly}
      disableInput={!compareFeatureStore.fullEditPermissions}
      disableHighlight={!compareFeatureStore.fullEditPermissions}
      disableApplicabilityText={!canAddAppText}
      model={grade}
      syncValueChanged={changedGradeIds.includes(grade.id)}
      oddRow={idx % 2 === 1}
      rowSpan={1}
      onUpdateApplicabilityText={applicabilityTextMap => {
        let shouldSave = false;
        const langs = Object.keys(applicabilityTextMap.text) as Language[];
        langs.forEach(lang => {
          const textMap = applicabilityTextMap.text[lang]!;
          if (textMap.canEdit) {
            const compareFeature = compareFeatureLangMap.langs[lang];
            const curTxt = compareFeature.gradeApplicability[grade.id].text;
            if (curTxt !== textMap.text) {
              shouldSave = true;
              compareFeature.gradeApplicability[grade.id].text = textMap.text;
            }
          }
        });
        if (shouldSave) {
          saveCompareFeatureLangMap(compareFeatureLangMap);
        }
      }}
      onClickHighlight={() => {
        const isHighlighted = defaultCompareFeature.gradeApplicability[grade.id].highlighted;
        for (const lang of compareFeatureStore.editableLangs) {
          compareFeatureLangMap.langs[lang].gradeApplicability[
            grade.id
          ].highlighted = !isHighlighted;
        }
        saveCompareFeatureLangMap(compareFeatureLangMap);
      }}
      onUpdate={value => {
        const applicability = defaultCompareFeature.gradeApplicability[grade.id].applicability
          ? defaultCompareFeature.gradeApplicability[grade.id].applicability
          : '';
        if (applicability !== value) {
          for (const lang of compareFeatureStore.editableLangs) {
            const compareFeature = compareFeatureLangMap.langs[lang];
            compareFeature.gradeApplicability[grade.id].applicability = value;
            if (!value) {
              compareFeature.gradeApplicability[grade.id].text = '';
              compareFeature.gradeApplicability[grade.id].highlighted = false;
            }
          }
          saveCompareFeatureLangMap(compareFeatureLangMap);
        }
      }}
      displayLimitedDataStatus={
        teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true'
      }
      limitedDataStatus={defaultCompareFeature.fieldStatus?.gradeApplicability[grade.id]}
      onClickLimitedDataStatus={onClickLimitedDataStatus}
    />
  );
};

export default CompareFeatureCell;
