import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { ChangeLogTypes } from './changeLog.model';
import type { KeyValueType } from './common.model';
import { ReviewChangeMap, ReviewChangeResponse, ReviewChangeTypeMap } from './review.model';
import {
  UpdateBnpCategoryApplicabilityInput,
  UpdateBnpItemDescriptionInput,
  UpdateBnpItemTitleCopyInput,
} from '../gql/generated';

export type BnPItemsResponse = {
  cabs: BnPItemsResponseItem;
  transmission: BnPItemsResponseItem;
  engine: BnPItemsResponseItem;
  grade: BnPItemsResponseItem;
  beds: BnPItemsResponseItem;
  drive: BnPItemsResponseItem;
  seats: BnPItemsResponseItem;
};

export type BnPItemsResponseItem = {
  notes: string;
  sortOrder: number;
  label: string;
  isInProgress: boolean;
  fieldStatus?: BnPFieldStatus;
  categories: { [categoryId: string]: BnpCategory };
};

export type BnpCategory = null | {
  title?: string;
  copy?: string;
  description?: string;
  changedAttributes?: string[];
  key?: string;
  applicability?: { [modelId: string]: string };
  splits?: KeyValueType<BnpCategorySplit>;
  categoryValue?: string;
  rejectNotes?: string;
};

export type BnpCategorySplit = {
  isDeleted?: boolean;
  description?: string;
  splitNumber: number;
  id: string;
};

export type BnPFieldStatus = {
  status: number;
};

export type BnPFieldStatusResponse = {
  [category in keyof BnPItemsResponse]: BnPFieldStatus;
};

export type BnPFieldStatusRequest = BnPFieldStatusResponse;

export class BnPCategories {
  uid = uuidv4();
  categoryItemMap: BnPCategoryItemMap = {};
  items: BnPCategoryItem[] = [];

  @observable name = '';
  @observable label = '';
  @observable sortOrder = -1;
  @observable isInProgress = false;
  @observable notes = '';
  @observable langMaps: BnPCategoryItemLangMap[] = [];

  constructor(name: string, response: BnPItemsResponseItem, items: BnPCategoryItem[]) {
    this.name = name;
    this.label = response.label;
    this.items = items;
    this.notes = response.notes || '';
    this.sortOrder = response.sortOrder;
    this.isInProgress = response.isInProgress;
  }
}

export interface BnPCategoriesMap {
  [category: string]: BnPCategories;
}

export class BnPCategoryItem {
  @observable name = '';
  @observable label = '';
  @observable categoryId = '';
  @observable categoryValue = '';
  @observable description = '';
  @observable title = '';
  @observable copy = '';
  @observable changedAttributes: string[] = [];
  @observable applicability: KeyValueType<string> = {};
  @observable splits: BnpCategorySplit[] = [];
  @observable acceptChanges: boolean = false;
  @observable splitsMap: KeyValueType<BnpCategorySplit> = {};
  @observable rejectNotes: string = '';

  constructor(
    name: string,
    label: string,
    categoryId: string,
    categoryValue: string,
    description?: string,
    title?: string,
    copy?: string,
    changedAttributes?: string[],
    applicability?: KeyValueType<string>,
    splitsMap?: KeyValueType<BnpCategorySplit>,
    rejectNotes?: string
  ) {
    this.name = name;
    this.label = label;
    this.categoryId = categoryId;
    this.categoryValue = categoryValue;
    this.description = description || '';
    this.title = title || '';
    this.copy = copy || '';
    this.changedAttributes = changedAttributes || [];
    this.applicability = applicability || {};
    this.setSplits(splitsMap || {});
    this.rejectNotes = rejectNotes || '';
  }

  setSplits = (splitsMap: KeyValueType<BnpCategorySplit>) => {
    this.splitsMap = splitsMap;
    this.splits = Object.values(splitsMap).sort((a, b) => a.splitNumber - b.splitNumber);
  };

  getDescriptionPayload = (
    split?: BnpCategorySplit,
    acceptChanges?: boolean
  ): UpdateBnpItemDescriptionInput => {
    return {
      name: this.name,
      category: this.categoryId,
      description: split ? split.description ?? '' : this.description,
      splitId: split?.id,
      acceptChanges,
    };
  };

  getGradeDetailPayload = (): UpdateBnpItemTitleCopyInput => {
    return {
      name: this.name,
      category: this.categoryId,
      title: this.title,
      copy: this.copy,
    };
  };

  getApplicabilityPayload = (): UpdateBnpCategoryApplicabilityInput => {
    return {
      name: this.name,
      category: this.categoryId,
      applicability: Object.entries(this.applicability).map(([modelId, splitId]) => ({
        modelId,
        applicability: splitId,
      })),
    };
  };
}

export interface BnPCategoryItemMap {
  [categoryId: string]: BnPCategoryItemLangMap;
}

export interface BnPCategoryItemLangMap {
  [lang: string]: BnPCategoryItem;
}

export interface BnPReviewRequest {
  id: string;
  revId: string;
  changeType: ChangeLogTypes;
  isAccepted: boolean;
  isApplied: boolean;
  rejectNotes: string;
}

export interface BnpCategoryReviewResponse {
  itemKey: string;
  categoryKey: string;
  categoryName: string;
  title?: string;
  copy?: string;
  description?: string;
  applicability?: { [modelId: string]: string };
  splits?: KeyValueType<BnpCategorySplit>;
  changes: KeyValueType<ReviewChangeResponse>;
  revId: string;
  splitChanges: KeyValueType<KeyValueType<ReviewChangeResponse>>; // map of {[splitId: string]: {[changeType: string]: ReviewChangeResponse}}
}

export interface BnPItemsReviewResponse {
  label: string;
  sortOrder?: number;
  notes?: string;
  isInProgress: boolean;
  categories: { [categoryKey: string]: BnpCategoryReviewResponse };
}

export interface BnPReviewItem {
  itemKey: string;
  sortOrder: number;
  categories: KeyValueType<BnPChangeTypeMap>;
}

export interface BnPReviewMap {
  [itemKey: string]: KeyValueType<BnPChangeTypeMap>;
}

export type BnpCategorySplitReview = {
  description: ReviewChangeMap<string>;
  splitNumber: number;
  isNew: boolean;
  isDeleted: boolean;
  id: string;
};

export interface BnPChangeTypeMap extends ReviewChangeTypeMap {
  itemKey: string;
  categoryKey: string;
  categoryName: string;
  itemLabel: string; // get from BnPItemsReviewResponse.label
  title: ReviewChangeMap<string>; // only applicable to grade bnp
  copy: ReviewChangeMap<string>; // only applicable to grade bnp
  description: ReviewChangeMap<string>; // applicable to all except grade bnp
  applicability: ReviewChangeMap<KeyValueType<string>>; // applicable to all except grade bnp, map of modelId to splitId
  // this data structure needs to be finished to accomodate the split changes
  splits: KeyValueType<BnpCategorySplitReview>;
  hasApplicabilityChange: boolean; // if applicability has changed then this flag will tell us whether we want to show the default category row (i.e. if this is true then the default category's applicability has changed)
}

export type BnPReviewType =
  | 'title'
  | 'copy'
  | 'description'
  | 'applicability'
  | 'added'
  | 'splitAdd' // before => undefined; after => id of split that was added; bnpSplitId => split.id
  | 'splitDescription'; // before => prev description; after => current description; bnpSplitId => split.id
