import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { LIMITED_DATA_STATUS } from '../../constants/vehicleData/VDConstants';
import useStores from '../../hooks/useStores';
import { VehicleTeam } from '../../models/vehicleData.model';
import styles from './limitedDataStatus.module.scss';

export interface LimitedDataStatusProps {
  className?: string;
  status?: LIMITED_DATA_STATUS;
  total?: number;
  onClick?: (val: LIMITED_DATA_STATUS) => void;
  hideLimitedDataStatus?: boolean;
  hideTotal?: boolean;
  clickOverride?: boolean;
}

const LimitedDataStatus = ({
  className,
  status,
  total,
  onClick,
  hideLimitedDataStatus,
  hideTotal,
  clickOverride,
}: LimitedDataStatusProps) => {
  const {
    limitedDataStatusStore: { disabled },
    userStore,
    teamStore,
  } = useStores();

  if (hideLimitedDataStatus) return null;

  const iconStyles = cx({
    [styles.purpleCircle]: status === 0,
    [styles.orangeCircle]: status === 1,
    [styles.greenCircle]: status === 2,
  });

  const getText = () => {
    let message = '';

    switch (status) {
      case LIMITED_DATA_STATUS.READY_TO_PUBLISH:
        message = 'Ready to Publish';
        break;
      case LIMITED_DATA_STATUS.IN_PROGRESS:
        message = 'In Progress';
        break;
      case LIMITED_DATA_STATUS.PUBLISHED:
        message = 'Published';
        break;

      default:
    }

    if (total) {
      message = `${hideTotal ? '' : total.toString().concat(' ')}${message}`;
    }

    return message;
  };

  const canEditLimitedDataStatus =
    userStore.modules.AgencyTeam.canEdit && teamStore.team.name === VehicleTeam.AGENCY_TEAM;

  const isClickable =
    canEditLimitedDataStatus &&
    !disabled &&
    !total &&
    onClick &&
    status !== undefined &&
    (clickOverride || status !== LIMITED_DATA_STATUS.PUBLISHED);

  const handleClick = () => {
    if (isClickable) {
      onClick(status);
    }
  };

  return status === undefined ? null : (
    <div
      className={cx(styles.container, { [styles.clickable]: isClickable }, className)}
      onClick={handleClick}
      data-testid="limited-data-status-comp"
    >
      <span className={styles.iconContainer}>
        <FontAwesomeIcon className={iconStyles} icon={faCircle} />
      </span>
      <span className={styles.statusMessage}>{getText()}</span>
    </div>
  );
};

export default observer(LimitedDataStatus);
