import { computed, observable } from 'mobx';
import { CommonLanguageItem } from '../../../../models/commonLanguage.model';
import { toLowerCase } from '../../../../utils';

export class ModalState {
  @observable items: CommonLanguageCheckItem[] = [];
  @observable searchText = '';

  @computed get filteredItems() {
    const text = toLowerCase(this.searchText);
    return this.items.filter(
      item =>
        !text ||
        toLowerCase(item.category).includes(text) ||
        toLowerCase(item.subCategory).includes(text) ||
        toLowerCase(item.description).includes(text)
    );
  }

  @computed get selectCount() {
    return this.items.filter(item => item.checked).length;
  }
}

export class CommonLanguageCheckItem extends CommonLanguageItem {
  checked = false;
  disabled = false;
}
