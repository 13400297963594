import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { removeNulls } from '../utils';
import { ChangeLogTypes } from './changeLog.model';
import { IDValueType, KeyValueType, ModelApplicabilityType } from './common.model';
import { ReviewChangeBaseItem, ReviewChangeResponse } from './review.model';
import {
  CreateVehicleOptionLexusInput,
  CreateVehicleOptionSpecInput,
  CreateVehiclePackageLexusInput,
  CreateVehiclePackageVarietyLexusInput,
  UpdateVehicleOptionLexusInput,
  UpdateVehicleOptionSpecInput,
  UpdateVehiclePackageLexusInput,
  UpdateVehiclePackageVarietyLexusInput,
} from '../gql/generated';

export class OptionsPackageTotal {
  uid = uuidv4();
  @observable rowHeight = 0;
  applicability: IDValueType[] = [];
  changes: ReviewChangeBaseItem[] = [];
  otherChanges: ReviewChangeBaseItem[] = [];
}

export class OptionsPackage {
  uid = uuidv4();
  revId = '';
  isOption = false;
  @observable id = '';
  @observable notes = '';
  @observable rejectNotes = '';
  @observable isInProgress = false;
  @observable tooltip = false;
  @observable name = '';
  @observable description = '';
  @observable code = '';
  @observable required = '';
  @observable conflicts = '';
  @observable rowHeight = 0;
  @observable specVarietyRowHeight = 0;
  @observable specs: OptionsPackageSpec[] = [];
  @observable packageTotals = new OptionsPackageTotal();
  @observable packageTotalReqs = new OptionsPackageTotal();
  @observable packageVarieties: OptionsPackageVariety[] = [];
  @observable changes: ReviewChangeBaseItem[] = [];
  @observable otherChanges: ReviewChangeBaseItem[] = [];
  @observable sortOrder = 0;

  constructor(option?: VDPackageLexus) {
    if (option) {
      const {
        changes,
        packageSpecs,
        packageTotalApplicabiity,
        packageTotalReqApplicabiity,
        packageVarieties,
        ...rest
      } = option;
      Object.assign(this, removeNulls(rest));
    }
  }

  // default is without added/deleted packages
  getPackageCountByChangeTypes = (changeTypes?: ChangeLogTypes[]) => {
    return this.changes.reduce((sum, change) => {
      const isAddDeleteType = [
        ChangeLogTypes.PACKAGE_ADDED,
        ChangeLogTypes.PACKAGE_DELETED,
        ChangeLogTypes.OPTION_ADDED,
        ChangeLogTypes.OPTION_DELETED,
      ].includes(change.changes.changeType);

      if (changeTypes) {
        return changeTypes.includes(change.changes.changeType) ? sum + 1 : sum;
      } else {
        return !isAddDeleteType ? sum + 1 : sum;
      }
    }, 0);
  };

  getSpecChanges = () => {
    const specs: OptionsPackageSpec[] = [];

    this.specs.forEach(spec => {
      if (spec.changes.length > 0) {
        specs.push(spec);
      }
    });

    return specs;
  };

  getSpecChangesCount = () => {
    return this.specs.reduce((sum, spec) => {
      return sum + spec.changes.length;
    }, 0);
  };

  getVarietyChangesCount = () => {
    return this.packageVarieties.reduce((sum, spec) => {
      return sum + spec.changes.length;
    }, 0);
  };

  getTotalApplicability = (applicability: IDValueType<any>[]): KeyValueType => {
    return applicability.reduce<KeyValueType>((retObj, model) => {
      if (model.value) {
        retObj[model.id] = model.value;
      }
      return retObj;
    }, {});
  };

  getCreateOptionPayload = (): CreateVehicleOptionLexusInput => {
    return {
      name: this.name,
      code: this.code,
      description: this.description,
      notes: this.notes,
      isInProgress: this.isInProgress,
      tooltip: this.tooltip,
      required: this.required,
      conflicts: this.conflicts,
      optionTotalApplicability: this.getTotalApplicability(this.packageTotals.applicability),
    };
  };

  getCreatePackagePayload = (): CreateVehiclePackageLexusInput => {
    return {
      name: this.name,
      code: this.code,
      description: this.description,
      notes: this.notes,
      isInProgress: this.isInProgress,
      required: this.required,
      conflicts: this.conflicts,
      packageTotalApplicability: this.getTotalApplicability(this.packageTotals.applicability),
      packageTotalReqApplicability: this.getTotalApplicability(this.packageTotalReqs.applicability),
    };
  };

  getUpdateOptionPayload = (): UpdateVehicleOptionLexusInput => {
    return {
      ...this.getCreateOptionPayload(),
      id: this.id,
      revId: this.revId,
    };
  };

  getUpdatePackagePayload = (): UpdateVehiclePackageLexusInput => {
    return {
      ...this.getCreatePackagePayload(),
      id: this.id,
      revId: this.revId,
    };
  };

  hasApplicability = (applicability: IDValueType<any>[]) => {
    let hasApp = true;
    if (this.specs.length) {
      applicability.forEach((value, index) => {
        for (let val of this.specs) {
          if (val.models[index].setting) {
            if (!value.value || value.value.length === 0) {
              hasApp = false;
              break;
            }
          }
        }
      });
    }
    return hasApp;
  };
  isValid = () => {
    let applicabilityPasses = this.hasApplicability(this.packageTotals.applicability);
    if (applicabilityPasses) {
      applicabilityPasses = this.hasApplicability(this.packageTotalReqs.applicability);
    }
    return this.name && this.code && applicabilityPasses;
  };
}

export class OptionsPackageSpec {
  uid = uuidv4();
  id = '';
  revId = '';
  @observable toms = '';
  @observable sms = '';
  @observable specs = '';
  @observable rejectNotes = '';
  @observable isExclusive = false;
  @observable rowHeight = 0;
  @observable models: ModelApplicabilityType[] = [];
  @observable changes: ReviewChangeBaseItem[] = [];
  @observable otherChanges: ReviewChangeBaseItem[] = [];

  constructor(spec?: VDPackageSpecLexus) {
    if (spec) {
      const { modelApplicability, ...rest } = spec;
      Object.assign(this, removeNulls(rest));
    }
  }

  isValid = () => {
    return this.specs;
  };

  getCreatePayload = (parentId: string, parentRevId: string): CreateVehicleOptionSpecInput => {
    const modelApplicability = this.models.reduce((retObj, model) => {
      if (model.setting) {
        retObj[model.id] = model.setting;
      }
      return retObj;
    }, {} as KeyValueType);

    return {
      parentId,
      parentRevId,
      specs: this.specs,
      toms: this.toms,
      sms: this.sms,
      modelApplicability: modelApplicability,
      isExclusive: this.isExclusive,
    };
  };

  getUpdatePayload = (parentId: string, parentRevId: string): UpdateVehicleOptionSpecInput => {
    return {
      ...this.getCreatePayload(parentId, parentRevId),
      id: this.id,
      revId: this.revId,
    };
  };
}

export class OptionsPackageVariety {
  uid = uuidv4();
  id = '';
  revId = '';
  @observable name = '';
  @observable code = '';
  @observable required = '';
  @observable conflicts = '';
  @observable rejectNotes = '';
  @observable applicability: IDValueType[] = [];
  @observable changes: ReviewChangeBaseItem[] = [];
  @observable otherChanges: ReviewChangeBaseItem[] = [];
  @observable rowHeight = 0;

  constructor(item?: VDPackageVarietyLexus) {
    if (item) {
      Object.assign(this, removeNulls(item));
    }
  }

  isValid = () => {
    return this.name && this.code;
  };

  getCreatePayload = (
    parentId: string,
    parentRevId: string
  ): CreateVehiclePackageVarietyLexusInput => {
    const modelApplicability = this.applicability.reduce((retObj, model) => {
      if (model.value) {
        retObj[model.id] = model.value;
      }
      return retObj;
    }, {} as KeyValueType);

    return {
      parentId,
      parentRevId,
      name: this.name,
      code: this.code,
      required: this.required,
      conflicts: this.conflicts,
      modelApplicability,
    };
  };

  getUpdatePayload = (
    parentId: string,
    parentRevId: string
  ): UpdateVehiclePackageVarietyLexusInput => {
    return {
      ...this.getCreatePayload(parentId, parentRevId),
      id: this.id,
      revId: this.revId,
    };
  };
}

export type OptionsPackageSpecModel = {
  id: string;
  setting: string;
};

// Response data
export interface VDOptionLexusBase {
  id: string;
  revId: string;
  name: string;
  description?: string;
  code: string;
  isInProgress: boolean;
  notes?: string;
  required?: string;
  conflicts?: string;
  isDeleted: boolean;
  changes?: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
}

export interface VDPackageLexus extends VDOptionLexusBase {
  packageSpecs?: KeyValueType<VDPackageSpecLexus>;
  packageVarieties?: KeyValueType<VDPackageVarietyLexus>;
  packageTotalApplicabiity?: KeyValueType;
  packageTotalReqApplicabiity?: KeyValueType;
}

export interface VDOptionLexus extends VDOptionLexusBase {
  optionSpecs?: { [key: string]: VDPackageSpecLexus };
  optionTotalApplicabiity?: KeyValueType;
}

export interface VDPackageVarietyLexus {
  id: string;
  revId: string;
  name: string;
  code: string;
  required?: string;
  conflicts?: string;
  modelApplicability?: KeyValueType;
  changes?: ReviewChangeResponse[];
  otherChanges?: ReviewChangeResponse[];
}

export interface VDPackageSpecLexus {
  id: string;
  revId: string;
  specs: string;
  toms?: string;
  sms?: string;
  modelApplicability?: KeyValueType;
  isDeleted: boolean;
  isExclusive: boolean;
  changes?: ReviewChangeResponse;
  otherChanges?: ReviewChangeResponse[];
}

export interface VDPackageSpecLexus {
  id: string;
  revId: string;
  specs: string;
  toms?: string;
  sms?: string;
  modelApplicability?: KeyValueType;
  isDeleted: boolean;
  isExclusive: boolean;
}

export interface VDOptionLexusRequestBase {
  id: string;
  revId: string;
  name: string;
  code: string;
  description?: string;
  isInProgress?: boolean;
  notes?: string;
  link?: string;
  required?: string;
  conflicts?: string;
}

export interface VDPackageRequest extends VDOptionLexusRequestBase {
  packageTotalApplicabiity?: KeyValueType;
  packageTotalReqApplicabiity?: KeyValueType;
}

export interface VDOptionsRequest extends VDOptionLexusRequestBase {
  optionTotalApplicabiity?: KeyValueType;
}

export interface VDPackageSpecRequest {
  id: string;
  revId: string;
  parentId: string;
  parentRevId: string;
  specs: string;
  toms?: string;
  sms?: string;
  modelApplicability?: KeyValueType;
  isExclusive: boolean;
}

export interface VDPackageVarietyRequest {
  id: string;
  revId: string;
  parentId: string;
  parentRevId: string;
  name: string;
  code: string;
  required?: string;
  conflicts?: string;
  modelApplicability?: KeyValueType;
}

export type OptionsTabType = 'packages' | 'options';

export interface OptionsPackageReviewResponse {
  id: string;
  revId: string;
  name: string;
  notes: string;
  description: string;
  isInProgress: boolean;
  conflicts: string;
  required: string;
  code: string;
  packageSpecs: KeyValueType<OptionsPackageSpecReviewResponse>;
  packageTotalApplicatbility: KeyValueType;
  packageTotalReqApplicatbility: KeyValueType;
  packageVarieties: KeyValueType;
  optionSpecs: KeyValueType<OptionsPackageSpecReviewResponse>;
  optionTotalApplicabiity: KeyValueType;
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
}

export interface OptionsPackageSpecReviewResponse {
  id: string;
  revId: string;
  sms: string;
  specs: string;
  toms: string;
  modelApplicability: KeyValueType;
  isExclusive: boolean;
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
}
