import { TableCell } from '../../../../../../../components/Table';
import { TableCellProps } from '../../../../../../../components/Table/components/TableCell/TableCell';
import React from 'react';
import styles from '../optionsLexusPackageRow.module.scss';

interface OptionsLexusPackageSpecFillerCellProps extends TableCellProps {
  count: number;
}

const OptionsLexusPackageSpecFillerCell = ({ count }: OptionsLexusPackageSpecFillerCellProps) => {
  const cells = [];
  for (let i = 0; i < count; i++) {
    cells.push(<TableCell key={i} className={styles.fillerCell} />);
  }

  return <>{cells.map(item => item)}</>;
};

export default OptionsLexusPackageSpecFillerCell;
