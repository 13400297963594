import { useCallback, useContext } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { LIMITED_DATA_STATUS } from '../../../../../constants/vehicleData/VDConstants';
import useStores from '../../../../../hooks/useStores';
import { BnPFieldStatusResponse } from '../../../../../models/buildAndPrice.model';
import { Language } from '../../../../../models/user.model';
import { VehicleTeam } from '../../../../../models/vehicleData.model';
import { handleErrorResponse } from '../../../../../utils/errorHandlingUtils';
import { updateFieldStatus } from '../../../../../webservices/vehicleBnPApi';
import { BuildAndPriceRowItemContext } from '../components/BuildAndPriceRow/BuildAndPriceRow';
import { toGqlBrand, toGqlLanguage, toGqlTeam } from '../../../../../utils/graphqlUtils';

const useFieldStatus = () => {
  const context = useContext(BuildAndPriceRowItemContext);

  const {
    userStore: { brand },
    limitedDataStatusStore: { setDisabled },
    bnpStore,
  } = useStores();

  const { team, seriesId, year: modelYear } = useParams<{
    team: VehicleTeam;
    seriesId: string;
    year: string;
  }>();

  const onSave = useCallback(
    async (catName: string, status: LIMITED_DATA_STATUS) => {
      try {
        setDisabled(true);
        bnpStore.fieldStatus = await trackPromise(
          updateFieldStatus({
            brand: toGqlBrand(brand),
            team: toGqlTeam(team),
            seriesId,
            modelYear: parseInt(modelYear),
            language: toGqlLanguage(Language.EN),
            payload: {
              ...bnpStore.fieldStatus,
              [catName]: { status },
            },
          })
        );
        toast.success(`BnP Field Status updated successfully`);
        setDisabled(false);
      } catch (e) {
        setDisabled(false);
        handleErrorResponse(e, 'BnP Field Status failed update');
      }
    },
    [brand, modelYear, seriesId, setDisabled, team, bnpStore]
  );

  const onClick = useCallback(() => {
    if (!context?.categories) return;

    const { name } = context.categories;
    const fieldStatus = bnpStore.fieldStatus[name as keyof BnPFieldStatusResponse];

    if (!fieldStatus) return;

    if (fieldStatus.status === LIMITED_DATA_STATUS.IN_PROGRESS) {
      return onSave(name, LIMITED_DATA_STATUS.READY_TO_PUBLISH);
    }

    if (fieldStatus.status === LIMITED_DATA_STATUS.READY_TO_PUBLISH) {
      return onSave(name, LIMITED_DATA_STATUS.IN_PROGRESS);
    }
  }, [context?.categories, onSave, bnpStore.fieldStatus]);

  return {
    onClick,
  };
};

export default useFieldStatus;
