import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import styles from './dphHeader.module.scss';

const DphHeader = () => {
  return (
    <div className={styles.headerContainer}>
      <FontAwesomeIcon className={styles.caret} icon={faAngleDown} onClick={() => ({})} />
      <span className={styles.title1}>DPH Category</span>
      <span className={styles.title1}>Assigned Series</span>
      <span className={styles.title2}>Live Date</span>
      <span className={styles.title2}>Live DPH Value</span>
      <span className={styles.title3}>Next Live Date & DPH Value</span>
    </div>
  );
};

export default DphHeader;
