import { computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { cleanUpRte } from 'vapi-ui-common';
import { LIMITED_DATA_STATUS } from '../constants/vehicleData/VDConstants';
import { removeNulls } from '../utils';
import { toKeyFeatureMap } from '../utils/featuresUtils';
import { ChangeLogTypes } from './changeLog.model';
import { BaseReviewItem, IDValueType, KeyValueType } from './common.model';
import { ReviewChangeMap, ReviewChangeResponse, ReviewChangeTypeMap } from './review.model';
import { VehicleTeam } from './vehicleData.model';
import { ModelFieldStatus } from './vehicleModel.model';

export class FeatureItem {
  uid = '';
  id = '';
  revId = '';
  switchShortLongDescription = false;
  @observable comLangId = '';
  @observable modelsMap: KeyValueTypeFeatureModel = {};
  @observable gradeApplicability = {} as GradeApplicability;
  @observable link = '';
  @observable category = {} as IDValueType;
  @observable subCategory = {} as IDValueType;
  @observable description = '';
  @observable shortDescription = '';
  @observable longDescription = '';
  @observable isInProgress = false;
  @observable isHighlighted = false;
  @observable notes = '';
  @observable rejectNotes = '';
  @observable splits: FeatureSplitItem[] = [];
  @observable tooltip = false;
  @observable sortOrder: string | number = 0;
  @observable compareFeatureId: string = '';
  @observable changedAttributes: string[] = [];
  @observable changedModelIds: string[] = [];
  @observable fromTMNA: boolean = false;
  @observable fieldStatus: FeatureFieldStatusRequest = {
    id: '',
    status: LIMITED_DATA_STATUS.READY_TO_PUBLISH,
    modelApplicability: {},
  };
  @observable keyFeatures?: VDKeyFeatures;

  constructor(feature?: FeatureResponse) {
    this.uid = uuidv4();
    if (feature) {
      Object.assign(this, removeNulls(feature));
      this.changedAttributes.forEach(attr => {
        if (attr.includes('modelApplicability#')) {
          const splt = attr.split('#');
          if (splt.length > 1) {
            this.changedModelIds.push(splt[1]);
          }
        }
      });
    }
  }

  @computed get splitCount() {
    return this.splits.length;
  }

  getPayload = (keyFeaturesList?: KeyFeatureType[]) => {
    const modelApplicability = {} as FeatureModelApplicability;
    Object.values(this.modelsMap).forEach(mdl => {
      if (!mdl.setting) {
        modelApplicability[mdl.id] = null;
      } else {
        modelApplicability[mdl.id] = mdl.setting;
      }
    });

    const gradeApplicability = JSON.parse(
      JSON.stringify(this.gradeApplicability)
    ) as GradeApplicability;

    Object.values(gradeApplicability).forEach(gradeApp => {
      Object.values(gradeApp.applicabilityText).forEach(appText => {
        const curAppText = appText;
        curAppText.text = curAppText.text === '' ? null : curAppText.text;
      });
    });

    const keyFeatures = toKeyFeatureMap(keyFeaturesList) ?? this.keyFeatures;

    return {
      id: this.id,
      revId: this.revId,
      categoryId: this.category.id,
      subCategoryId: this.subCategory.id,
      comLangId: this.comLangId,
      description: cleanUpRte(this.description),
      shortDescription: cleanUpRte(this.shortDescription),
      longDescription: cleanUpRte(this.longDescription),
      isInProgress: this.isInProgress,
      isHighlighted: this.isHighlighted,
      notes: this.notes,
      link: this.link,
      tooltip: this.tooltip,
      modelApplicability,
      gradeApplicability: this.gradeApplicability,
      keyFeatures,
    } as FeatureRequest;
  };

  isFeatureValid = () =>
    !!(
      this.category.id &&
      this.category.value &&
      (this.shortDescription || this.longDescription || this.description)
    );

  areSplitsValid = () =>
    !this.splits.length || !this.splits.filter(item => !item.description).length;

  isValid = (team: string) => {
    if (team === VehicleTeam.AGENCY_TEAM) {
      return this.isFeatureValid() && this.areSplitsValid();
    } else {
      return (
        !!(this.category.id && this.subCategory.id && this.description) && this.areSplitsValid()
      );
    }
  };
}

export class FeatureSplitItem {
  id = '';
  uid = '';
  name = '';
  featureId = '';
  featureRevId = '';
  @observable notes = '';
  @observable description = '';
  @observable shortDescription = '';
  @observable longDescription = '';
  @observable isHighlighted = false;

  constructor(split?: FeatureSplitResponse) {
    this.uid = uuidv4();
    if (split) {
      Object.assign(this, removeNulls(split));
    }
  }

  isValid = () => {
    return !!this.description;
  };

  getPayload = () => {
    return {
      id: this.id,
      featureId: this.featureId,
      featureRevId: this.featureRevId,
      description: this.description,
      shortDescription: this.shortDescription,
      longDescription: this.longDescription,
      isHighlighted: this.isHighlighted,
      notes: this.notes,
    } as FeatureSplitRequest;
  };
}

export type KeyValueTypeFeatureModel = KeyValueType<FeatureModel>;

export type FeatureFieldStatusRequest = ModelFieldStatus;

export interface FeatureResponse {
  id: string;
  revId: string;
  modelApplicability: FeatureModelApplicability;
  gradeApplicability: GradeApplicability;
  categoryId: string;
  subCategoryId: string | undefined;
  description: string; // also referred to as long description in agency page
  shortDescription: string;
  longDescription: string;
  isInProgress: boolean;
  isHighlighted: boolean;
  notes: string | undefined;
  comLangId?: string;
  splits?: FeatureSplitResponse[];
  changedAttributes?: string[];
  fromTMNA?: boolean;
  fieldStatus: ModelFieldStatus;
  keyFeatures?: VDKeyFeatures;
}

export type FeatureRequest = Omit<FeatureResponse, 'fieldStatus'>;

export enum FeatureSettings {
  STANDARD = 'S',
  OPTIONAL = 'O',
  PACKAGE = 'P',
  UNDEFINED = '',
}

export interface FeatureSplitRequest extends FeatureSplitResponse {}

export type FeatureSplitResponse = {
  id: string;
  featureId: string;
  featureRevId: string;
  notes?: string;
  description: string;
  shortDescription?: string; // unclear if feature splits have short descriptions
  longDescription?: string;
  isHighlighted?: boolean;
};

type FeatureModelApplicability = {
  [modelCode: string]: FeatureSettings | null;
};

export type FeatureModel = {
  id: string;
  setting: FeatureSettings | null;
};

export type GradeApplicability = {
  [modelCode: string]: GradeApplicabilityItem;
};
export type GradeApplicabilityItem = {
  highlighted: boolean;
  isComparable: boolean;
  applicabilityText: GradeApplicabilityText;
};
export type GradeApplicabilityText = {
  [key: string]: {
    text: string | null;
    availability: string;
  };
};

export type KeyValueTypeFeatureSettings = KeyValueType<FeatureSettings>;

/**
 * Features Review Item
 */
export class FeaturesReviewItem extends BaseReviewItem {
  @observable subCategory = {} as IDValueType;
  @observable models: FeatureModel[] = [];
  @observable modelApplicability: KeyValueTypeFeatureSettings = {};
  @observable gradeApplicability = {} as GradeApplicability;

  constructor(feature?: FeaturesReviewResponse, change?: ReviewChangeResponse) {
    super();
    this.uid = uuidv4();
    if (feature) {
      Object.assign(this, removeNulls(feature));
    }
    this.destructureReviewChangeResponse(change);
    this.createOtherChanges(feature);
  }
}
export interface FeaturesReviewResponse {
  isHighlighted: string;
  notes: string;
  modelApplicability: FeatureModelApplicability;
  gradeApplicability: GradeApplicability;
  link: string;
  revId: string;
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
  description: string;
  subCategoryId: string;
  subCategory?: string;
  shortDescription: string;
  isDeleted: boolean;
  isAccepted: boolean;
  isApplied: boolean;
  id: string;
  isInProgress: boolean;
  categoryId: string;
  category?: string;
  keyFeatures?: VDKeyFeatures;
}

export interface FeaturesReviewRequest {
  id: string;
  revId: string;
  changeType: ChangeLogTypes;
  isAccepted: boolean;
  isApplied: boolean;
  rejectNotes: string;
}

export interface FeaturesReviewMap {
  [id: string]: FeaturesChangeTypeMap;
}

export interface FeaturesChangeTypeMap extends ReviewChangeTypeMap {
  category: ReviewChangeMap<string>;
  subCategory: ReviewChangeMap<string>;
  description: ReviewChangeMap<string>;
  keyFeatures: ReviewChangeMap<string>[];
  modelApplicability: ReviewChangeMap<KeyValueType<FeatureSettings>>;
}

export type FeatureReviewType =
  | 'category'
  | 'subCategory'
  | 'description'
  | 'keyFeatures'
  | 'modelApplicability'
  | 'added'
  | 'deleted';

export interface FeaturesMap {
  features: {
    [id: string]: FeatureLangMap;
  };
  order: string[];
}

export interface FeatureLangMap {
  langs: {
    [lang: string]: FeatureItem;
  };
  data?: FeatureResponse;
}

export type KeyFeatureType = {
  id?: string;
  uid: string;
  description: string;
  hasChanges?: boolean;
};

export type KeyFeatureTypeMap = KeyValueType<KeyFeatureType>;

export type VDKeyFeatures = KeyValueType<boolean>;

export type VDKeyFeatureOption = {
  name: string;
  nameES: string;
  id: string;
  category: string;
};

export type VDKeyFeatureOptionResponse = {
  keyFeatures: KeyValueType<VDKeyFeatureOption[]>;
};

export type KeyFeatureOption = {
  name: KeyValueType<string>;
  id: string;
};

export type KeyFeatureOptioneMap = KeyValueType<KeyFeatureOption>;
