import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { BRAND_LEXUS } from './user.model'

export class ReportItem {
  uid = uuidv4();
  @observable fileName = '';
  @observable bucketKey = '';
  @observable generatedDate = '';
  @observable modelYear = '';
  @observable generatedBy = '';
  @observable seriesId = '';
  @observable version = '';
  @observable reportType = '';
  @observable series = '';
  @observable year = '';

  constructor(report?: ReportResponse) {
    if (report) {
      Object.assign(this, report);
    }
  }
}

export interface ReportResponse {
  fileName: string;
  bucketKey: string;
  generatedDate: string;
  modelYear: string;
  generatedBy: string;
  seriesId: string;
  version: string;
  reportType: string;
}

export enum ReportTypes {
  COMMON_LANGUAGE = 'common-language',
  DISCLAIMERS = 'disclaimers',
  PRODUCT_TEAM = 'product-team',
  AGENCY_TEAM = 'agency-team',
  COMPARE_MODEL = 'compare-model',
  INFORMATION_LAYER = 'information-layer',
  VSD = 'vsd',
  TOOL_TIPS = 'tool-tips'
}

export const getReportTypesByBrand = (brand: string) => {
  if (brand === BRAND_LEXUS) {
    return [
      ReportTypes.INFORMATION_LAYER,
      ReportTypes.COMMON_LANGUAGE,
      ReportTypes.TOOL_TIPS,
      ReportTypes.DISCLAIMERS,
      ReportTypes.VSD,
    ]
  }
  return [
    ReportTypes.COMMON_LANGUAGE ,
    ReportTypes.DISCLAIMERS,
    ReportTypes.PRODUCT_TEAM,
    ReportTypes.AGENCY_TEAM,
    ReportTypes.COMPARE_MODEL,
  ]
}

export interface VehicleModel {
  modelYear: string;
  seriesId: string;
  seriesName: string;
  group?: string;
}
