import { useCallback } from 'react';
import { useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import { GradeFieldStatus } from '../models/vehicleModel.model';
import useStores from './useStores';
import { VehicleTeam } from '../models/vehicleData.model';
import { Language } from '../models/user.model';
import { handleErrorResponse } from '../utils/errorHandlingUtils';
import { LIMITED_DATA_STATUS } from '../constants/vehicleData/VDConstants';
import { CompareFeatureItem, CompareGradeSettingsCell } from '../models/compareFeatures.model';

interface IUseFieldStatusGradeUpdateProps {
  store: CompareFeatureItem;
  grade?: CompareGradeSettingsCell;
  callBack?: (
    brand: string,
    team: VehicleTeam,
    serieId: string,
    modelYear: string,
    language: Language,
    payload: GradeFieldStatus
  ) => Promise<AxiosResponse<GradeFieldStatus, any>>;
  setGradeCellStatusChange?: (val: boolean) => void;
}

const useFieldStatusGradeUpdate = ({
  store,
  grade,
  callBack,
  setGradeCellStatusChange,
}: IUseFieldStatusGradeUpdateProps) => {
  const {
    userStore: { brand },
    limitedDataStatusStore: { setDisabled },
  } = useStores();
  const { team, seriesId, year: modelYear } = useParams<{
    team: VehicleTeam;
    seriesId: string;
    year: string;
  }>();

  const onSave = useCallback(
    async (payload: GradeFieldStatus) => {
      if (!callBack) return;

      try {
        setDisabled(true);
        const res = await trackPromise(
          callBack(brand, team, seriesId, modelYear, Language.EN, payload)
        );

        store.fieldStatus = res.data;
        if (setGradeCellStatusChange) {
          setGradeCellStatusChange(true);
        }
        toast.success(`Feature Field Status updated successfully`);
        setDisabled(false);
      } catch (e) {
        setDisabled(false);
        handleErrorResponse(e, 'Feature Field Status failed update');
      }
    },
    [store, brand, modelYear, seriesId, setDisabled, team, callBack, setGradeCellStatusChange]
  );

  const onClick = useCallback(() => {
    const fieldStatus = store.fieldStatus ?? { gradeApplicability: {} };
    const payload: GradeFieldStatus = { ...fieldStatus };

    if (payload.status === LIMITED_DATA_STATUS.IN_PROGRESS) {
      payload.status = LIMITED_DATA_STATUS.READY_TO_PUBLISH;
      return onSave(payload);
    }

    if (payload.status === LIMITED_DATA_STATUS.READY_TO_PUBLISH) {
      payload.status = LIMITED_DATA_STATUS.IN_PROGRESS;
      return onSave(payload);
    }
  }, [store, onSave]);

  const onClickGradeApplicability = useCallback(() => {
    if (!grade) return;

    const gradeId = grade.id;
    const fieldStatus = store.fieldStatus ?? { gradeApplicability: {} };
    const status = fieldStatus?.gradeApplicability?.[gradeId];
    const payload: GradeFieldStatus = {
      ...fieldStatus,
      gradeApplicability: { ...fieldStatus.gradeApplicability },
    };

    if (status === LIMITED_DATA_STATUS.IN_PROGRESS) {
      payload.gradeApplicability[gradeId] = LIMITED_DATA_STATUS.READY_TO_PUBLISH;
      return onSave(payload);
    }

    if (status === LIMITED_DATA_STATUS.READY_TO_PUBLISH) {
      payload.gradeApplicability[gradeId] = LIMITED_DATA_STATUS.IN_PROGRESS;
      return onSave(payload);
    }
  }, [store, grade, onSave]);

  return { onClick, onClickGradeApplicability };
};

export default useFieldStatusGradeUpdate;
