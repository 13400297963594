import { IDValueType, KeyValueType } from '../models/common.model';

export const getSortPayload = (list: any) => {
  const payload = {
    sortList: {} as KeyValueType<number>,
  };

  if (Array.isArray(list)) {
    list.forEach((item, index) => {
      payload.sortList[item.id] = index;
    });
  }

  return payload;
};

export const sortHelperFunction = <T>(itemFn: (item: T) => string, list: IDValueType[]) => {
  return (item1: T, item2: T) => {
    const item1Id = itemFn(item1);
    const item2Id = itemFn(item2);

    const item1Index = list.findIndex(listItem => listItem.id === item1Id);
    const item2Index = list.findIndex(listItem => listItem.id === item2Id);

    if (item1Index === -1 && item2Index === -1) {
      return 0;
    } else if (item1Index === -1) {
      return 1;
    } else if (item2Index === -1) {
      return -1;
    } else {
      return item1Index - item2Index;
    }
  };
};

export const getSortedCopy = <T>(items: T[], itemFn: (item: T) => string, list: IDValueType[]) => {
  const copy = items.slice();
  copy.sort(sortHelperFunction(itemFn, list));

  return copy;
};

export const sortCategoriesFunction = (sortList: KeyValueType<number>) => {
  return (item1: IDValueType, item2: IDValueType) => {
    const sort1 = sortList ? sortList[item1.id] : 0;
    const sort2 = sortList ? sortList[item2.id] : 0;
    if (sort1 == null && sort2 == null) {
      return 0;
    }
    if (sort1 == null) {
      return -1;
    }
    if (sort2 == null) {
      return 1;
    }
    return sort1 - sort2;
  };
};

export const handleOnSortNumberUpdate = (dataset: any, newIndex: string, oldIndex: number) => {
  var parsedIndex = parseInt(newIndex, 10);
  if (parsedIndex < 0) {
    parsedIndex = oldIndex;
  }

  if (Number.isInteger(parsedIndex)) {
    const [removed] = dataset.splice(oldIndex, 1);
    dataset.splice(parsedIndex - 1, 0, removed);
  }

  dataset.forEach((item: any, index: number) => {
    item.sortOrder = index + 1;
  });

  dataset = dataset.slice();
};

interface LangMap {
  langs: {
    [lang: string]: {
      [key: string]: any;
      sortOrder: number | string;
    };
  };
}

export const handleOnLangMapSortNumberUpdate = (
  dataset: LangMap[],
  newIndex: string,
  oldIndex: number
) => {
  var parsedIndex = parseInt(newIndex, 10);
  if (parsedIndex < 0) {
    parsedIndex = oldIndex;
  }

  if (Number.isInteger(parsedIndex)) {
    const [removed] = dataset.splice(oldIndex, 1);
    dataset.splice(parsedIndex - 1, 0, removed);
  }

  const langs = Object.keys(dataset[0].langs);
  dataset.forEach((item, index: number) => {
    langs.forEach(lang => {
      item.langs[lang].sortOrder = index + 1;
    });
  });

  dataset = dataset.slice();
};

export const getSortedListByString = <T>(list: T[], prop: string) => {
  return list.sort((a: T, b: T) => {
    const x = a[prop as keyof T];
    const y = b[prop as keyof T];
    let first = '';
    let second = '';

    if (typeof x === 'string') {
      first = x.toLowerCase();
    }

    if (typeof y === 'string') {
      second = y.toLocaleLowerCase();
    }

    if (first < second) {
      return -1;
    }
    if (first > second) {
      return 1;
    }
    return 0;
  });
};
