import ColorFamilyController from '../routes/colorFamily';
import CommonLanguageArchive from '../routes/commonLanguage/modules/Archive';
import CommonLanguageChangeLog from '../routes/commonLanguage/modules/ChangeLog';
import CommonLanguageDraft from '../routes/commonLanguage/modules/Draft';
import CommonLanguagePublished from '../routes/commonLanguage/modules/Published';
import Decks from '../routes/decks/decks';
import DeliveryProcessingHandling from '../routes/deliveryProcessingHandling';
import DisclaimersArchive from '../routes/disclaimers/modules/Archive';
import DisclaimersChangeLog from '../routes/disclaimers/modules/ChangeLog';
import DisclaimersDraft from '../routes/disclaimers/modules/Draft';
import DisclaimersPublished from '../routes/disclaimers/modules/Published';
import Help from '../routes/help';
import Reports from '../routes/reports';
import SeriesCategories from '../routes/seriesCategories';
import Settings from '../routes/settings/settings';
import SpanishDisclaimers from '../routes/spanishDisclaimers';
import ChangeLog from '../routes/vehicleData/modules/ChangeLog';
import Draft from '../routes/vehicleData/modules/Draft/Draft';
import Published from '../routes/vehicleData/modules/Published';
import Review from '../routes/vehicleData/modules/Review';
import SeriesManager from '../routes/vehicleData/modules/SeriesManager';
import VehicleData from '../routes/vehicleData/VehicleData';

const CHANGE_LOG = 'Change Log';

export interface AppRoute {
  path: string;
  text: string;
  exact?: boolean;
  hide?: boolean;
  component: any; // make this required when there are more components
  childRoutes?: Array<AppRoute>;
}

export const CommonLanguageRoutes: Array<AppRoute> = [
  {
    path: '/commonLanguage/published',
    text: 'Common Language',
    component: CommonLanguagePublished,
    childRoutes: [
      {
        path: '/commonLanguage/draft',
        text: 'Draft',
        component: CommonLanguageDraft,
      },
      {
        path: '/commonLanguage/archived',
        text: 'Archive',
        component: CommonLanguageArchive,
      },
      {
        path: '/commonLanguage/changeLog',
        text: CHANGE_LOG,
        hide: true,
        component: CommonLanguageChangeLog,
      },
    ],
  },
];

export const VehicleDataRoutes: Array<AppRoute> = [
  {
    path: '/vehicleData/:team',
    text: '',
    component: VehicleData,
    childRoutes: [
      {
        path: '/vehicleData/draft/:team/:seriesId/:year/:versionInfo',
        text: 'Draft',
        hide: true,
        component: Draft,
      },
      {
        path: '/vehicleData/published/:team/:seriesId/:year/:versionInfo',
        text: 'Published',
        hide: true,
        component: Published,
      },
      {
        path: '/vehicleData/changeLog/:team/:seriesId/:year/:versionInfo',
        text: CHANGE_LOG,
        hide: true,
        component: ChangeLog,
      },
      {
        path: '/vehicleData/review/:team/:seriesId/:year/:version',
        text: 'Review',
        hide: true,
        component: Review,
      },
    ],
  },
];

export const DisclaimerRoutes: Array<AppRoute> = [
  {
    path: '/disclaimers/published',
    text: 'Disclaimers',
    component: DisclaimersPublished,
    childRoutes: [
      {
        path: '/disclaimers/draft',
        text: 'Draft',
        component: DisclaimersDraft,
      },
      {
        path: '/disclaimers/archive',
        text: 'Archive',
        component: DisclaimersArchive,
      },
      {
        path: '/disclaimers/changeLog',
        text: CHANGE_LOG,
        hide: true,
        component: DisclaimersChangeLog,
      },
    ],
  },
];

export const DPHRoutes: AppRoute[] = [
  {
    path: '/dph',
    text: 'DPH',
    component: DeliveryProcessingHandling,
  },
];

export const GSTAccessoriesDashboardRoute: AppRoute[] = [
  {
    path: `${process.env.REACT_APP_ACCESSORIES_URL}/commonLanguage/published?collapsed=false`,
    text: 'Common Language',
    component: () => {},
    childRoutes: [
      {
        path: `${process.env.REACT_APP_ACCESSORIES_URL}/commonLanguage/draft?collapsed=false`,
        text: 'Draft',
        component: () => {},
      },
      {
        path: `${process.env.REACT_APP_ACCESSORIES_URL}/commonLanguage/archived?collapsed=false`,
        text: 'Archive',
        component: () => {},
      },
    ],
  },
  {
    path: `${process.env.REACT_APP_ACCESSORIES_URL}/gst/dashboard?collapsed=false`,
    text: 'GST',
    component: () => {},
  },
];

export const GSTAccessoriesOtherRoutes: AppRoute[] = [
  {
    path: `${process.env.REACT_APP_ACCESSORIES_URL}/reports?collapsed=false`,
    text: 'Reports',
    component: () => {},
  },
  {
    path: `${process.env.REACT_APP_ACCESSORIES_URL}/help?collapsed=false`,
    text: 'Help',
    component: () => {},
  },
];

export const SeriesCategoriesRoutes: AppRoute[] = [
  {
    path: '/seriesCategories',
    text: 'Series Categories',
    component: SeriesCategories,
  },
];

export const SpanishDisclaimersRoutes: AppRoute[] = [
  {
    path: '/spanishDisclaimers',
    text: 'Spanish Disclaimers',
    component: SpanishDisclaimers,
  },
];

export const ColorFamiliesRoutes: AppRoute[] = [
  {
    path: '/colorFamilies',
    text: 'Color Families',
    component: ColorFamilyController,
  },
];

export const RestOfRoutes: Array<AppRoute> = [
  {
    path: '/user/seriesManager',
    text: 'Series Manager',
    hide: true,
    component: SeriesManager,
  },
  { path: '/reports', text: 'Reports', component: Reports },
  { path: '/decks', text: 'Decks', hide: true, component: Decks },
  { path: '/settings', text: 'Settings', hide: true, component: Settings },
  { path: '/help', text: 'Help', component: Help },
];
