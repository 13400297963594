import React, { useCallback } from 'react';
import { LIMITED_DATA_STATUS } from '../../../../../constants/vehicleData/VDConstants';
import { ChangeLogItem, ChangeLogTypes } from '../../../../../models/changeLog.model';
import ChangeLogChanges, {
  ChangeLogBeforeAfter,
} from '../../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';

const useChangeLogChanges = () => {
  const limitedDataStatusToString = (status: number) => {
    switch (status) {
      case LIMITED_DATA_STATUS.READY_TO_PUBLISH:
        return 'Ready to Publish';
      case LIMITED_DATA_STATUS.IN_PROGRESS:
        return 'In Progress';
      case LIMITED_DATA_STATUS.PUBLISHED:
        return 'Published';
      default:
        return '';
    }
  };

  const limitedDataStatusChangeLogChanges = useCallback((log: ChangeLogItem) => {
    const before = Number(log.before);
    const after = Number(log.after);

    switch (after) {
      case LIMITED_DATA_STATUS.READY_TO_PUBLISH:
      case LIMITED_DATA_STATUS.IN_PROGRESS:
      case LIMITED_DATA_STATUS.PUBLISHED:
        return (
          <ChangeLogBeforeAfter
            before={limitedDataStatusToString(before)}
            after={limitedDataStatusToString(after)}
            styleAfter
            inline
          />
        );
    }
  }, []);

  const filterChangeLogChanges = useCallback((log: ChangeLogItem) => {
    switch (log.changeType) {
      case ChangeLogTypes.BEDS_STATUS:
      case ChangeLogTypes.CABS_STATUS:
      case ChangeLogTypes.DRIVE_STATUS:
      case ChangeLogTypes.ENGINE_STATUS:
      case ChangeLogTypes.GRADE_STATUS:
      case ChangeLogTypes.SEATS_STATUS:
      case ChangeLogTypes.TRANSMISSION_STATUS:
        return Number(log.after) !== LIMITED_DATA_STATUS.PUBLISHED;
      default:
        return true;
    }
  }, []);

  const changeLogChanges = useCallback(
    (log: ChangeLogItem) => {
      switch (log.changeType) {
        case ChangeLogTypes.BEDS_STATUS:
        case ChangeLogTypes.CABS_STATUS:
        case ChangeLogTypes.DRIVE_STATUS:
        case ChangeLogTypes.ENGINE_STATUS:
        case ChangeLogTypes.GRADE_STATUS:
        case ChangeLogTypes.SEATS_STATUS:
        case ChangeLogTypes.TRANSMISSION_STATUS:
          return limitedDataStatusChangeLogChanges(log);
        default:
          return <ChangeLogChanges changeItem={log} />;
      }
    },
    [limitedDataStatusChangeLogChanges]
  );

  const canRevert = (log: ChangeLogItem) => {
    switch (log.changeType) {
      case ChangeLogTypes.BEDS_STATUS:
      case ChangeLogTypes.CABS_STATUS:
      case ChangeLogTypes.DRIVE_STATUS:
      case ChangeLogTypes.ENGINE_STATUS:
      case ChangeLogTypes.GRADE_STATUS:
      case ChangeLogTypes.SEATS_STATUS:
      case ChangeLogTypes.TRANSMISSION_STATUS: {
        return false;
      }
      default: {
        return log.canRevert;
      }
    }
  };

  return { filterChangeLogChanges, changeLogChanges, canRevert };
};

export default useChangeLogChanges;
