import { action, computed, observable } from 'mobx';
import { convertToRichTextObject } from 'vapi-ui-common';
import { DisclaimersChangeLogItem } from '../models/disclaimers.model';
import { sortBy, toLowerCase } from '../utils';

class CommonLanguageChangeLogStore {
  @observable logs: DisclaimersChangeLogItem[] = [];
  @observable versions: string[] = [];
  @observable searchText = '';
  @observable reverse = true;
  @observable sortField = 'modifiedDate';

  setSort = (sortField: string) => {
    this.reverse = this.sortField !== sortField ? false : !this.reverse;
    this.sortField = sortField;
  };

  @computed get filteredLogs() {
    const logs = this.logs
      .slice()
      .sort(sortBy(this.sortField, this.reverse, this.sortField === 'modifiedDate'));
    const lowerSearchText = toLowerCase(this.searchText);
    return logs.filter(
      log =>
        !lowerSearchText ||
        toLowerCase(convertToRichTextObject(log.description).text).includes(lowerSearchText) ||
        (log.description_es &&
          toLowerCase(convertToRichTextObject(log.description_es).text).includes(
            lowerSearchText
          )) ||
        toLowerCase(log.changeType).includes(lowerSearchText) ||
        toLowerCase(log.modifiedBy).includes(lowerSearchText) ||
        toLowerCase(log.modifiedDate).includes(lowerSearchText) ||
        toLowerCase(log.notes).includes(lowerSearchText)
    );
  }

  @action reset() {
    this.logs = [];
    this.versions = [];
    this.searchText = '';
    this.reverse = true;
    this.sortField = 'modifiedDate';
  }
}

export default CommonLanguageChangeLogStore;
