import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { BnPReviewRowContext } from '..';
import { TableCell } from '../../../../../../../components/Table';
import styles from '../bnPReviewTable.module.scss';

interface NameCellProps {
  rowSpan: number;
}

const NameCell = ({ rowSpan }: NameCellProps) => {
  const context = useContext(BnPReviewRowContext);

  if (!context) {
    return null;
  }

  const { category } = context;

  return (
    <TableCell className={styles.nameCell} rowSpan={rowSpan}>
      {category.itemLabel}
    </TableCell>
  );
};

export default observer(NameCell);
