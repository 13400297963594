import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { TableCell } from '../../../../../../../components/Table';
import useModelApplicabilityMap, {
  ModelApplicabilityMap,
  ModelApplicabilityModel,
} from '../../../../../../../hooks/useModelApplicability';
import useStores from '../../../../../../../hooks/useStores';
import { BnpCategorySplit } from '../../../../../../../models/buildAndPrice.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../models/vehicleModel.model';
import { getModelApplicabilityMetadataMap } from '../../../../../../../utils/vehicleDataUtils';
import { BuildAndPriceRowItemContext } from '../BuildAndPriceRow';
import styles from '../buildAndPriceRow.module.scss';

interface ApplicableModelCellProps {
  split?: BnpCategorySplit;
}

const ApplicableModelCell = ({ split }: ApplicableModelCellProps) => {
  const {
    bnpStore: { defaultEditLang },
  } = useStores();
  const context = useContext(BuildAndPriceRowItemContext);
  const {
    generateModelApplicabilityMap,
    isCheckedGrade,
    allCheckedInfo,
  } = useModelApplicabilityMap();
  const {
    vehicleModelsStore: { vehicleModels },
  } = useStores();

  if (!context) {
    return null;
  }

  const { langMap } = context;
  const category = langMap[defaultEditLang];

  /**
   * @description this checks if a specific model is checked for the default category
   * @param model
   * @returns true if model is not checked for default category
   */
  const isCheckedModelAll = (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => {
    const { applicability } = category;
    return !!applicability[model.id] && applicability[model.id] !== 'default';
  };

  /**
   * @description this checks if a specific model is checked for a BnpCategorySplit
   * @param model
   * @returns true if model is checked for the split
   */
  const isCheckedModelSplit = (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => {
    if (!split) {
      return false;
    }

    const { applicability } = category;
    return applicability[model.id] === split.id;
  };

  const modelApplicabilityMapAll = generateModelApplicabilityMap({
    isCheckedModel: isCheckedModelAll,
    category,
  });

  const modelApplicabilityMapSplit = generateModelApplicabilityMap({
    isCheckedModel: isCheckedModelSplit,
    category,
  });

  /**
   * @returns JSX list of grades selected (with their corresponding selected model codes) for the default category
   */
  const getDefaultGradesList = () => {
    const { numCheckedModels } = allCheckedInfo(modelApplicabilityMapAll);
    if (category.name === 'grade' || !numCheckedModels) {
      return 'All';
    }

    const gradesMap: ModelApplicabilityMap = {};
    Object.values(modelApplicabilityMapAll).forEach(value => {
      const { grade } = value;
      if (!isCheckedGrade(modelApplicabilityMapAll, grade.id)) {
        gradesMap[grade.id] = value;
      }
    });

    const metadataList = Object.values(
      getModelApplicabilityMetadataMap(gradesMap, category, vehicleModels)
    ).filter(
      value =>
        !value.modelApplicability.numChecked ||
        value.includedModels.filter(({ isChecked }) => !isChecked).length
    );

    if (!metadataList.length) {
      return <div>None</div>;
    }

    return metadataList.map(value => {
      const { grade, numChecked } = value.modelApplicability;
      return (
        <li key={grade.id}>
          {grade.value} ({toModelListCodes(value.includedModels, value.excludedModels, numChecked)})
        </li>
      );
    });
  };

  /**
   *
   * @param includeModels models with the same grade and with the same value for the default category
   * @param excludedModels
   * @param numChecked
   * @returns list of model codes for a grade for the default category
   */
  const toModelListCodes = (
    includeModels: ModelApplicabilityModel[],
    excludedModels: ModelApplicabilityModel[],
    numChecked: number
  ) => {
    if (!numChecked && !excludedModels.length) {
      return 'All';
    }

    return includeModels
      .filter(({ isChecked }) => !numChecked || !isChecked)
      .map(({ model }) => model.getVal('code'))
      .join();
  };

  /**
   * @returns JSX list of grades selected (with their corresponding selected model codes) for a split
   */
  const getSplitGradesList = () => {
    const gradesMap: ModelApplicabilityMap = {};
    Object.values(modelApplicabilityMapSplit).forEach(value => {
      const { grade, numChecked } = value;
      if (numChecked) {
        gradesMap[grade.id] = value;
      }
    });
    const metadataList = Object.values(
      getModelApplicabilityMetadataMap(gradesMap, category, vehicleModels)
    ).filter(
      value =>
        isCheckedGrade(modelApplicabilityMapSplit, value.modelApplicability.grade.id) ||
        value.includedModels.filter(({ isChecked }) => isChecked).length
    );

    if (!metadataList.length) {
      return <div>None</div>;
    }

    return metadataList.map(value => {
      const { grade } = value.modelApplicability;
      return (
        <li key={grade.id}>
          {grade.value} (
          {toSplitModelListCodes(value.includedModels, value.excludedModels, grade.id)})
        </li>
      );
    });
  };

  /**
   *
   * @param includedModels
   * @param gradeId
   * @returns string of all the models currently checked for the split
   */
  const toSplitModelListCodes = (
    includedModels: ModelApplicabilityModel[],
    excludedModels: ModelApplicabilityModel[],
    gradeId: string
  ) => {
    const gradeChecked = isCheckedGrade(modelApplicabilityMapSplit, gradeId);
    if (gradeChecked && !excludedModels.length) {
      return 'All';
    }

    return includedModels
      .filter(({ isChecked }) => gradeChecked || isChecked)
      .map(({ model }) => model.getVal('code'))
      .join();
  };

  return (
    <TableCell
      colType="bpCategory"
      className={cx(
        styles.categoryCell,
        category.changedAttributes.includes('applicability') ? styles.errorText : ''
      )}
    >
      {!split && <ul>{getDefaultGradesList()}</ul>}

      {split && <ul>{getSplitGradesList()}</ul>}
    </TableCell>
  );
};

export default observer(ApplicableModelCell);
