import { action, computed, observable } from 'mobx';
import { convertToRichTextObject } from 'vapi-ui-common';
import {
  BnPCategories,
  BnPCategoriesMap,
  BnPFieldStatusResponse,
} from '../../models/buildAndPrice.model';
import type { KeyValueType } from '../../models/common.model';
import { RefItem } from '../../models/refItem.model';
import { Language, LanguagePermissions } from '../../models/user.model';
import { VehicleDataVersionInfo, VehicleTeam } from '../../models/vehicleData.model';
import type { ModelLangMap } from '../../models/vehicleModel.model';
import { toLowerCase } from '../../utils';
import parseLangWriteMap from '../../utils/languageUtils';
import { bnpItemMapXForm } from '../../utils/vehicleDataUtils';
import { getItems } from '../../webservices/vehicleBnPApi';
import { getModelMaps } from '../../webservices/vehicleModelsApi';
import { toGqlBrand, toGqlFilter, toGqlLanguage, toGqlTeam } from '../../utils/graphqlUtils';
import { Status } from '../../gql/generated';

export class BnPStore {
  // langs
  langWriteMap: LanguagePermissions = {};
  allLangs: Language[] = [];
  editableLangs: Language[] = [];
  defaultLang: Language = Language.EN;
  defaultEditLang: Language = Language.EN;
  fullEditPermissions: boolean = false;

  @observable selectedLangsMap: KeyValueType<boolean> = {};
  @observable modelLangMap: ModelLangMap = {};

  @observable data: BnPCategories[] = [];
  @observable categories: string[] = [];
  @observable searchText = '';
  @observable categoryFilters: string[] = [];
  @observable viewModelCodes = false;
  @observable isInProgressFilter = false;
  @observable fieldStatus = {} as BnPFieldStatusResponse;

  @action fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    grades: RefItem[],
    langWriteMap: LanguagePermissions,
    versionInfo: VehicleDataVersionInfo
  ) => {
    this.reset();
    const {
      allLangs,
      editableLangs,
      defaultLang,
      selectedLangsMap,
      fullEditPermissions,
      defaultEditLang,
    } = parseLangWriteMap(langWriteMap);
    this.langWriteMap = langWriteMap;
    this.allLangs = allLangs;
    this.editableLangs = editableLangs;
    this.defaultLang = defaultLang;
    this.selectedLangsMap = selectedLangsMap;
    this.fullEditPermissions = fullEditPermissions;
    this.defaultEditLang = defaultEditLang;

    const [modelMapsResponse, ...bnpItemsResponses] = await Promise.all([
      getModelMaps({
        brand: toGqlBrand(brand),
        team: toGqlTeam(team),
        seriesId,
        modelYear: parseInt(year),
        versionMap: versionInfo,
      }),
      ...allLangs.map(lang =>
        getItems({
          brand: toGqlBrand(brand),
          team: toGqlTeam(team),
          seriesId,
          modelYear: parseInt(year),
          language: toGqlLanguage(lang),
          filter: toGqlFilter(versionInfo[lang]?.toString() ?? Status.Draft),
        })
      ),
    ]);
    this.modelLangMap = modelMapsResponse;

    const bnpCatMap: BnPCategoriesMap = {};
    let index = 0;
    for (const lang of this.allLangs) {
      bnpItemMapXForm(
        bnpItemsResponses[index],
        bnpCatMap,
        this.modelLangMap,
        lang,
        grades,
        this.fieldStatus
      );
      index += 1;
    }

    this.data = Object.values(bnpCatMap).map(cat => {
      return {
        ...cat,
        langMaps: Object.values(cat.categoryItemMap),
        categoryItemMap: {},
        items: [],
      };
    });
  };

  @computed get filteredData() {
    const lowerSearchText = toLowerCase(this.searchText);
    return this.data.filter(category => {
      if (
        toLowerCase(category.name).includes(lowerSearchText) ||
        toLowerCase(category.notes).includes(lowerSearchText)
      ) {
        return true;
      }
      for (const langMap of category.langMaps) {
        for (const lang of this.allLangs) {
          const item = langMap[lang];
          if (lowerSearchText) {
            const valuesToCheck: string[] = [
              item.categoryValue,
              item.label,
              convertToRichTextObject(item.description).text,
            ];
            for (const val of valuesToCheck) {
              if (toLowerCase(val).includes(lowerSearchText)) {
                return true;
              }
            }
          }
        }
      }
      return !lowerSearchText;
    });
  }

  updateSelectedLangs = (lang: string, isSelected: boolean) => {
    const selectedMap = JSON.parse(JSON.stringify(this.selectedLangsMap));
    if (selectedMap[lang] != null) {
      selectedMap[lang] = isSelected;
    }
    this.selectedLangsMap = selectedMap;
  };

  @action reset() {
    this.data = [];
    this.categories = [];
    this.searchText = '';
    this.categoryFilters = [];
    this.viewModelCodes = false;
    this.isInProgressFilter = false;
    this.modelLangMap = {};
    this.selectedLangsMap = {};
    this.fieldStatus = {} as BnPFieldStatusResponse;
  }
}
