import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  ChangeLogArray,
  ChangeLogBeforeAfter,
} from '../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import {
  HeaderCell,
  HeaderRow,
  TableRow,
  Thead,
  TwoTableWrapper,
} from '../../../../components/Table';
import useStores from '../../../../hooks/useStores';
import { InteriorColorsChangeTypeMap } from '../../../../models/colors.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import LeftTable from '../../components/LeftTable';
import AddedDeletedCell from '../../components/tableCells/AddedDeletedCell/AddedDeletedCell';
import { ApplyCell } from '../../components/tableCells/ApplyCell';
import FlagsCell from '../../components/tableCells/FlagsCell/FlagsCell';
import VDTableCell from '../../components/tableCells/VDTableCell';

interface IColorsInteriorReviewTable {
  handleOnApplyChange: (item: InteriorColorsChangeTypeMap, value: boolean) => void;
  handleOnNotesChange: (item: InteriorColorsChangeTypeMap, value: string) => void;
}

const ColorsInteriorReviewTable = ({
  handleOnApplyChange,
  handleOnNotesChange,
}: IColorsInteriorReviewTable) => {
  const { colorsInteriorReviewStore, teamStore } = useStores();

  return (
    <TwoTableWrapper>
      <LeftTable>
        <Thead>
          <HeaderRow>
            <HeaderCell>Apply & Accept?</HeaderCell>
            <HeaderCell onClick={() => colorsInteriorReviewStore.setSort('code.after')}>
              Code
            </HeaderCell>
            <HeaderCell onClick={() => colorsInteriorReviewStore.setSort('name.after')}>
              Interior
            </HeaderCell>
            <HeaderCell>Applicability</HeaderCell>
            <HeaderCell>Added/Deleted?</HeaderCell>
            <HeaderCell />
          </HeaderRow>
        </Thead>
        <tbody>
          {colorsInteriorReviewStore.filteredReviewItems.map((reviewMap, index) => (
            <TableRow
              key={`review-row#${index}`}
              onFillRowHeightChange={height => {
                reviewMap.rowHeight = height;
              }}
            >
              <ApplyCell
                id={reviewMap.id}
                applyChecked={reviewMap.isApplied}
                onApplyChecked={isApplied => {
                  handleOnApplyChange(reviewMap, isApplied);
                }}
                notes={reviewMap.rejectNotes}
                handleOnNotesChange={notes => handleOnNotesChange(reviewMap, notes)}
                hideRejectNotes={teamStore.team.param === VehicleTeam.AGENCY_TEAM}
              />
              <VDTableCell colType="dropdown">
                <ChangeLogBeforeAfter
                  before={reviewMap.code.before}
                  after={reviewMap.code.after}
                  styleAfter={reviewMap.code.hasChanged}
                />
              </VDTableCell>
              <VDTableCell colType="dropdown">
                <ChangeLogBeforeAfter
                  before={reviewMap.name.before}
                  after={reviewMap.name.after}
                  styleAfter={reviewMap.name.hasChanged}
                />
              </VDTableCell>
              <VDTableCell colType="dropdown">
                <ChangeLogArray
                  before={reviewMap.modelApplicability.before}
                  after={reviewMap.modelApplicability.after}
                  hasChanged={reviewMap.modelApplicability.hasChanged}
                />
              </VDTableCell>
              <AddedDeletedCell isNew={reviewMap.isNew} isDeleted={reviewMap.isDeleted} />
              <FlagsCell
                index={0}
                disabled
                highlighted={false}
                inProgress={false}
                notes={reviewMap.notes}
                onNotesChange={notes => handleOnNotesChange(reviewMap, notes)}
              />
            </TableRow>
          ))}
        </tbody>
      </LeftTable>
    </TwoTableWrapper>
  );
};

export default observer(ColorsInteriorReviewTable);
