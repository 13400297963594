import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { HeaderCell, HeaderRow, TwoTableWrapper } from 'vapi-ui-common';
import { ChangeLogBeforeAfter } from '../../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import { TableRow } from '../../../../../components/Table';
import useStores from '../../../../../hooks/useStores';
import { OptionsChangeTypeMap } from '../../../../../models/options.model';
import { VehicleTeam } from '../../../../../models/vehicleData.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../models/vehicleModel.model';
import ModelTable, { StickyTable } from '../../../components/ModelTable';
import ModelApplicabilityReviewCell from '../../../components/ModelTable/components/ModelApplicabilityReviewCell';
import AddedDeletedCell from '../../../components/tableCells/AddedDeletedCell';
import { ApplyCell } from '../../../components/tableCells/ApplyCell';
import FlagsCell from '../../../components/tableCells/FlagsCell';
import VDTableCell from '../../../components/tableCells/VDTableCell';
import styles from './optionsReviewTable.module.scss';

interface IOptionsReviewTable {
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  handleOnApplyChange: (item: OptionsChangeTypeMap, value: boolean) => void;
  handleOnNotesChange: (item: OptionsChangeTypeMap, value: string) => void;
}

const OptionsReviewTable = ({
  vehicleModels,
  handleOnApplyChange,
  handleOnNotesChange,
}: IOptionsReviewTable) => {
  const { optionsReviewStore, teamStore, tableSizeStore } = useStores();

  const renderHeadersLeftTable = useCallback(
    () => (
      <>
        <HeaderRow>
          <HeaderCell cellClassName={styles.applyAccept}>Apply & Accept?</HeaderCell>
          <HeaderCell
            onClick={() => optionsReviewStore.setSort('category.after')}
            cellClassName={styles.category}
          >
            Category
          </HeaderCell>
          <HeaderCell
            onClick={() => optionsReviewStore.setSort('name.after')}
            cellClassName={styles.name}
          >
            Name
          </HeaderCell>
          <HeaderCell
            onClick={() => optionsReviewStore.setSort('description.after')}
            cellClassName={styles.description}
          >
            Description
          </HeaderCell>
          <HeaderCell cellClassName={styles.code}>Code</HeaderCell>
          <HeaderCell cellClassName={styles.msrp}>MSRP</HeaderCell>
          <HeaderCell cellClassName={styles.addedDeleted}>Added / Deleted?</HeaderCell>
          <HeaderCell cellClassName={styles.flags} />
        </HeaderRow>
      </>
    ),
    [optionsReviewStore]
  );

  const renderRowsLeftTable = useCallback(
    () =>
      optionsReviewStore.filteredReviewItems.map((reviewMap, index) => {
        return (
          <TableRow
            key={`review-row#${index}`}
            onFillRowHeightChange={height => {
              reviewMap.rowHeight = height;
            }}
          >
            <ApplyCell
              id={reviewMap.id}
              applyChecked={reviewMap.isApplied}
              onApplyChecked={isApplied => {
                handleOnApplyChange(reviewMap, isApplied);
              }}
              notes={reviewMap.rejectNotes}
              handleOnNotesChange={notes => handleOnNotesChange(reviewMap, notes)}
              hideRejectNotes={teamStore.team.param === VehicleTeam.AGENCY_TEAM}
              className={styles.applyAccept}
              attachTo={document.body}
            />
            <VDTableCell colType="dropdown" className={styles.category}>
              <ChangeLogBeforeAfter
                before={reviewMap.category.before}
                after={reviewMap.category.after}
                styleAfter={reviewMap.category.hasChanged}
              />
            </VDTableCell>
            <VDTableCell size="maxWidth" className={styles.name}>
              <ChangeLogBeforeAfter
                before={reviewMap.name.before}
                after={reviewMap.name.after}
                styleAfter={reviewMap.name.hasChanged}
              />
            </VDTableCell>
            <VDTableCell size="maxWidth" className={styles.description}>
              <ChangeLogBeforeAfter
                before={reviewMap.description.before}
                after={reviewMap.description.after}
                styleAfter={reviewMap.description.hasChanged}
              />
            </VDTableCell>
            <VDTableCell className={styles.code}>
              <ChangeLogBeforeAfter
                before={reviewMap.code.before}
                after={reviewMap.code.after}
                styleAfter={reviewMap.code.hasChanged}
              />
            </VDTableCell>
            <VDTableCell className={styles.msrp}>
              <ChangeLogBeforeAfter
                before={reviewMap.isExtraCost.before}
                after={reviewMap.isExtraCost.after}
                styleAfter={reviewMap.isExtraCost.hasChanged}
              />
            </VDTableCell>

            <AddedDeletedCell
              isNew={reviewMap.isNew}
              isDeleted={reviewMap.isDeleted}
              className={styles.addedDeleted}
            />

            <FlagsCell
              index={0}
              disabled
              highlighted={false}
              inProgress={false}
              notes={reviewMap.notes}
              onNotesChange={notes => handleOnNotesChange(reviewMap, notes)}
              className={styles.flags}
            />
          </TableRow>
        );
      }),
    [
      optionsReviewStore.filteredReviewItems,
      handleOnNotesChange,
      handleOnApplyChange,
      teamStore.team.param,
    ]
  );

  const [letfTableRowHeight, setLetfTableRowHeight] = useState(0);
  const onLeftTableHeaderHeightChange = useCallback(
    (height: number) => {
      const { tableRowHeight, scrollBarHeight } = tableSizeStore;

      if (height < tableRowHeight) {
        setLetfTableRowHeight(tableRowHeight + scrollBarHeight);
      }

      if (height > tableRowHeight) {
        setLetfTableRowHeight(tableRowHeight - scrollBarHeight);
      }
    },
    [tableSizeStore]
  );

  const onRightTableHeaderHeightChange = useCallback(
    (height: number) => {
      const { scrollBarHeight } = tableSizeStore;
      tableSizeStore.tableRowHeight = height;
      setLetfTableRowHeight(height - scrollBarHeight);
    },
    [tableSizeStore]
  );

  return (
    <TwoTableWrapper className={styles.twoTableWrapper}>
      <StickyTable
        className={styles.leftTable}
        renderHeaders={renderHeadersLeftTable}
        renderRows={renderRowsLeftTable}
        onTableHeaderHeightChange={onLeftTableHeaderHeightChange}
        tableRowHeight={letfTableRowHeight}
      />

      <ModelTable
        showFeatureSplits={teamStore.team.allowSplits}
        viewModelCodes={optionsReviewStore.viewModelCodes}
        models={vehicleModels}
        headerStyle={{ top: 0 }}
        onTableHeaderHeightChange={onRightTableHeaderHeightChange}
        renderRows={() => (
          <>
            {optionsReviewStore.filteredReviewItems.map((reviewMap, idx) => (
              <React.Fragment key={idx}>
                <TableRow
                  rowHeight={reviewMap.rowHeight}
                  key={`model-app#${idx}`}
                  style={{
                    display: 'flex',
                    height: reviewMap.rowHeight ?? '',
                  }}
                >
                  {vehicleModels.map(
                    (model, index) =>
                      model.show && (
                        <ModelApplicabilityReviewCell
                          key={`model-app-cell#${idx}#${index}`}
                          modelId={model.id}
                          map={reviewMap.modelApplicability}
                          isNew={reviewMap.isNew}
                          rowHeight={reviewMap.rowHeight ?? 0}
                          isDeleted={reviewMap.isDeleted}
                          className={styles.modelApplicability}
                        />
                      )
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </>
        )}
      />
    </TwoTableWrapper>
  );
};

export default observer(OptionsReviewTable);
