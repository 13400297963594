import apolloClient from '../apolloClient';
import {
  CreateReportDocument,
  CreateReportMutation,
  CreateReportMutationVariables,
  CreateSignedUrlDocument,
  CreateSignedUrlMutation,
  CreateSignedUrlMutationVariables,
  ReadReportLogsDocument,
  ReadReportLogsQuery,
  ReadReportLogsQueryVariables,
  S3Action,
} from '../gql/generated';
import { ReportResponse } from '../models/reports.model';

export type GenerateReportPayload = {
  version: string;
  seriesId?: string;
  modelYear?: number;
  region?: string;
};

export const getAvailableReports = async (
  variables: ReadReportLogsQueryVariables
): Promise<ReportResponse[]> => {
  const { data } = await apolloClient.query<ReadReportLogsQuery, ReadReportLogsQueryVariables>({
    query: ReadReportLogsDocument,
    variables,
  });

  return data.readReportLogs.body;
};

export const generateReport = async (
  variables: CreateReportMutationVariables
): Promise<ReportResponse[]> => {
  const { data } = await apolloClient.mutate<CreateReportMutation, CreateReportMutationVariables>({
    mutation: CreateReportDocument,
    variables,
  });

  if (!data) {
    throw new Error('Failed to generate report');
  }

  return data.createReport.body;
};

export const downloadReport = async (
  variables: Omit<CreateSignedUrlMutationVariables, 'action'>
): Promise<string> => {
  const { data } = await apolloClient.mutate<
    CreateSignedUrlMutation,
    CreateSignedUrlMutationVariables
  >({
    mutation: CreateSignedUrlDocument,
    variables: {
      ...variables,
      action: S3Action.Download,
    },
  });

  if (!data) {
    throw new Error('Failed to generate signed url');
  }

  return data.createSignedUrl.body;
};
