import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ActionBar, ActionBarSection, Modal, SearchInput } from 'vapi-ui-common';
import IconTextButton from '../../../../components/IconTextButton';
import ResetModal from '../../../../components/ResetModal';
import { DisclaimersVehicle } from '../../../../models/disclaimers.model';
import { REGION_GST } from '../../../../models/user.model';

interface ActionBarDisclaimersProps {
  readOnly?: boolean;
  searchText: string;
  setSearchText: (value: string) => void;
  vehicleModels: DisclaimersVehicle[];
  vehicleFilters: string[];
  setFilters: (vehicleFilters: string[]) => void;
  onResetDraft: () => void;
  onAddDisclaimerItem?: () => void;
  getUpdateCount?: () => Promise<number>;
  region?: string;
}

const ActionBarDisclaimers = ({
  readOnly,
  searchText,
  setSearchText,
  onResetDraft,
  getUpdateCount,
  onAddDisclaimerItem = () => {},
  region,
}: ActionBarDisclaimersProps) => {
  const [openResetModal, setOpenResetModal] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);

  const handleOnOpenResetDraft = async () => {
    if (getUpdateCount) {
      const count = await getUpdateCount();
      setUpdateCount(count);
      setOpenResetModal(true);
    }
  };

  return (
    <ActionBar>
      <ActionBarSection>
        <SearchInput value={searchText} onSearch={value => setSearchText(value)} />
      </ActionBarSection>
      <ActionBarSection>
        {!readOnly && region !== REGION_GST && (
          <IconTextButton
            icon="plus"
            text="Add Disclaimer"
            onClick={() => {
              onAddDisclaimerItem();
              toast.error('Please finish filling out all items for the new disclaimer.');
            }}
          />
        )}
        {!readOnly && (
          <IconTextButton icon="undo" text="Reset Draft" onClick={() => handleOnOpenResetDraft()} />
        )}
        <Modal open={openResetModal} onClose={() => setOpenResetModal(false)}>
          <ResetModal
            changeLogLocation="/disclaimers/changeLog?return=draft"
            close={() => setOpenResetModal(false)}
            pageName="Disclaimers"
            updateCount={updateCount}
            resetDraft={onResetDraft}
          />
        </Modal>
      </ActionBarSection>
    </ActionBar>
  );
};

export default ActionBarDisclaimers;
