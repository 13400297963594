import { observer } from 'mobx-react-lite';
import React from 'react';
import useStores from '../../../../../../hooks/useStores';
import { ApplicabilityTextLangMap } from '../../../../../../models/compareFeatures.model';
import { SpecItem, SpecSettings, SpecsLangMap } from '../../../../../../models/specs.model';
import { Language } from '../../../../../../models/user.model';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../models/vehicleModel.model';
import { tokensXForm } from '../../../../../../utils/disclaimersUtils';
import SettingsCell from '../../../../components/ModelTable/components/SettingsCell';
import useFieldStatusModelUpdate from '../../../../../../hooks/useFieldStatusModelUpdate';
import { updateSpecFeatureStatus } from '../../../../../../webservices/vehicleSpecsApi';

interface SpecsModelCellProps {
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  defaultSpec: SpecItem;
  changedModelIds: string[];
  readOnly: boolean | undefined;
  idx: number;
  saveSpecLangMap: (
    specLangMap: SpecsLangMap,
    compareChangeMessageRequest?: boolean,
    lang?: string,
    acceptChanges?: boolean,
    unlinkFromTMNA?: boolean
  ) => Promise<void>;
  specLangMap: SpecsLangMap;
}

const SpecsModelCell = ({
  model,
  defaultSpec,
  changedModelIds,
  readOnly,
  idx,
  saveSpecLangMap,
  specLangMap,
}: SpecsModelCellProps) => {
  const {
    specsStore,
    teamStore,
    userStore: { brand },
    disclaimersStore: { tokens },
  } = useStores();
  const { onClickModelApplicability: onClickLimitedDataStatus } = useFieldStatusModelUpdate({
    store: defaultSpec,
    model,
    callBack: updateSpecFeatureStatus,
  });

  const disclaimerTokens = tokensXForm(tokens);

  if (model.show && defaultSpec.modelsMap[model.id]) {
    const syncValueChanged = changedModelIds.includes(model.id);
    let defaultSetting = '';
    let setEditableText = false;
    const applicabilityTextMap: ApplicabilityTextLangMap = { text: {} };
    for (const lang of specsStore.allLangs) {
      const canEdit = specsStore.editableLangs.includes(lang);
      const text = specLangMap.langs[lang].modelsMap[model.id].setting;
      if (canEdit && text && !setEditableText) {
        defaultSetting = text;
        setEditableText = true;
      } else if (!canEdit && !defaultSetting) {
        defaultSetting = text;
      }
      applicabilityTextMap.text[lang] = {
        text,
        canEdit,
      };
      if (syncValueChanged && !canEdit) {
        applicabilityTextMap.text[lang]!.syncValueChange = syncValueChanged;
      }
    }
    const modelData = {
      id: defaultSpec.modelsMap[model.id].id,
      applicabilityTextMap,
      setting: defaultSetting,
    };

    return (
      <SettingsCell
        disableApplicabilityText={specsStore.allLangs.length <= 1 || readOnly}
        disableInput={!specsStore.editableLangs.includes(Language.EN)}
        allowDisclaimerTokensInGradeApplicability={
          teamStore.team.allowDisclaimerTokensInGradeApplicability
        }
        disabled={readOnly}
        model={modelData}
        syncValueChanged={changedModelIds.includes(model.id)}
        oddRow={idx % 2 === 1}
        onUpdate={value => {
          let shouldSave = false;
          specsStore.editableLangs.forEach(lang => {
            const setting = specLangMap.langs[lang].modelsMap[model.id].setting || '';
            shouldSave = shouldSave || setting !== value;
            specLangMap.langs[lang].modelsMap[model.id].setting = (value as string) as SpecSettings;
          });
          if (shouldSave) {
            saveSpecLangMap(specLangMap);
          }
        }}
        onUpdateApplicabilityText={applicabilityTextMap => {
          let shouldSave = false;
          const langs = Object.keys(applicabilityTextMap.text) as Language[];
          langs.forEach(lang => {
            const textMap = applicabilityTextMap.text[lang]!;
            if (textMap.canEdit) {
              const spec = specLangMap.langs[lang];
              const curTxt = spec.modelsMap[model.id].setting;
              if (curTxt !== textMap.text) {
                shouldSave = true;
                spec.modelsMap[model.id].setting = textMap.text as SpecSettings;
              }
            }
          });
          if (shouldSave) {
            saveSpecLangMap(specLangMap);
          }
        }}
        allowTokens={teamStore.team.useLargeSpecApplicability}
        disclaimerTokens={disclaimerTokens}
        rowSpan={1}
        isRequired={defaultSpec.isRequired}
        invalid={defaultSpec.isRequired && !defaultSpec.modelsMap[model.id].setting}
        lexusAgencySpecs={teamStore.team.param === VehicleTeam.AGENCY_TEAM && brand === 'lexus'}
        allowFreeFormText
        displayLimitedDataStatus={
          teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true'
        }
        limitedDataStatus={defaultSpec.fieldStatus?.modelApplicability?.[model.id]}
        onClickLimitedDataStatus={onClickLimitedDataStatus}
      />
    );
  }

  return <></>;
};

export default observer(SpecsModelCell);
