import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader, Radio } from 'vapi-ui-common';
import { RadioGroup } from '../../../../../../components/Radio/RadioGroup';
import { CompareType } from '../../../../../../models/compareFeatures.model';
import styles from './addCompareFeatureModal.module.scss';

interface AddCompareFeatureModalProps {
  close: () => void;
  onAddCompareFeature: (type: CompareType) => void;
}

const items = [
  {
    value: CompareType.Feature,
    name: 'Feature',
  },
  {
    value: CompareType.Spec,
    name: 'Spec',
  },
];

const AddCompareFeatureModal = observer(
  ({ close, onAddCompareFeature }: AddCompareFeatureModalProps) => {
    const [selectedValue, setSelectedValue] = useState(CompareType.Feature);

    return (
      <>
        <ModalHeader onClose={close}>Add Compare Feature</ModalHeader>
        <ModalBody>
          <div>Select type of compare feature to add:</div>
          <RadioGroup
            name="actionBarRadioGroup"
            selectedValue={selectedValue}
            onChange={value => setSelectedValue(value as CompareType)}
            className={styles.radioParent}
          >
            {items.map(item => (
              <Radio
                className={styles.radio}
                key={item.value}
                label={item.name}
                value={item.value}
              />
            ))}
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Button variant="transparent" onClick={() => close()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              close();
              onAddCompareFeature(selectedValue);
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </>
    );
  }
);

export default AddCompareFeatureModal;
