import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../components/ChangeLogInner';
import useChangeLogChanges from '../../../../hooks/useChangeLogChanges';
import useStores from '../../../../hooks/useStores';
import {
  ChangeLogItem,
  ChangeLogLangMap,
  ChangeLogResponse,
  ChangeLogTypes,
  SYNCED_CHANGE_TYPES_MAP,
} from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import {
  changeLogColorApplicabilityMapper,
  changeLogColorApplicabilityStatusMapper,
  changeLogHandleDeleteTypes,
  changeLogModelApplicabilityMapper,
} from '../../../../utils/changeLogUtils';
import { toGqlBrand, toGqlFilter, toGqlLanguage, toGqlTeam } from '../../../../utils/graphqlUtils';
import {
  getChangeLog,
  getInteriorColorsToyota,
  revertChange,
} from '../../../../webservices/vehicleColorsApi';
import { InteriorColorResponse } from '../../../../models/colors.model';

interface ColorsChangeLogProps {
  seriesId: string;
  year: string;
  version: string;
  readOnly?: boolean;
  loadData?: () => Promise<void>;
}

const ColorsChangeLogController = (props: ColorsChangeLogProps) => {
  const { seriesId, year, version, readOnly } = props;

  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
    vehicleModelsStore,
  } = useStores();

  const { filterChangeLogChanges } = useChangeLogChanges(
    ChangeLogTypes.EXT_COLOR_STATUS,
    ChangeLogTypes.MODEL_APPLICABILITY_STATUS,
    ChangeLogTypes.ALL_COLOR_STATUS
  );

  const loadData = async () => {
    let versionNum = version;
    if (version && isNaN(Number(version))) {
      versionNum = '';
    }

    const selectedLangs: KeyValueType<boolean> = {};
    teamStore.team.changeLogLanguages.forEach(lang => {
      selectedLangs[lang] = true;
    });
    changeLogStore.selectedLangsMap = selectedLangs;

    const promisesChangelog: Promise<ChangeLogResponse[]>[] = [];
    const promisesIntColors: Promise<InteriorColorResponse[]>[] = [];

    for (const lang of teamStore.team.changeLogLanguages) {
      promisesChangelog.push(
        getChangeLog({
          brand: toGqlBrand(brand),
          team: toGqlTeam(teamStore.team.param),
          seriesId,
          modelYear: parseInt(year),
          language: toGqlLanguage(lang),
          filter: toGqlFilter(version),
        })
      );

      promisesIntColors.push(
        getInteriorColorsToyota({
          brand: toGqlBrand(brand),
          team: toGqlTeam(teamStore.team.param),
          seriesId,
          modelYear: Number(year),
          language: toGqlLanguage(lang),
          filter: toGqlFilter(versionNum),
          includeAll: true,
        })
      );
    }

    const responsesChangelog = await Promise.all(promisesChangelog);
    const responsesIntColors = await Promise.all(promisesIntColors);

    let index = 0;
    const changeLogLangMap: ChangeLogLangMap = {};

    for (const lang of teamStore.team.changeLogLanguages) {
      const logs = (responsesChangelog[index] as ChangeLogResponse[])
        .map(item => {
          const changeLogItem = new ChangeLogItem(item, !readOnly, lang);

          if (
            SYNCED_CHANGE_TYPES_MAP[changeLogItem.changeType] &&
            teamStore.team.changeLogLanguages.length > 1
          ) {
            changeLogItem.canRevert = false;
          }
          return changeLogItem;
        })
        .filter(filterChangeLogChanges);

      changeLogColorApplicabilityMapper(
        responsesIntColors[index],
        logs,
        ChangeLogTypes.EXT_COLOR_APPLICABILITY,
        vehicleModelsStore.grades
      );

      changeLogHandleDeleteTypes(logs, ChangeLogTypes.INT_COLOR_DELETED);
      changeLogHandleDeleteTypes(logs, ChangeLogTypes.EXT_COLOR_DELETED);
      changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
      changeLogColorApplicabilityStatusMapper(
        vehicleModelsStore.grades,
        vehicleModelsStore.vehicleModels,
        logs
      );
      changeLogLangMap[lang] = logs;

      index += 1;
    }

    changeLogStore.changeLogLangMap = changeLogLangMap;
  };

  return <ColorsChangeLog {...props} loadData={loadData} />;
};

const ColorsChangeLog = observer(
  ({ seriesId, year, readOnly, version, loadData = async () => {} }: ColorsChangeLogProps) => {
    const {
      userStore: { brand },
      teamStore,
      changeLogStore,
      colorsStore,
    } = useStores();

    const [isLoaded, setIsLoaded] = useState(false);

    const { changeLogChanges, canRevert } = useChangeLogChanges(
      ChangeLogTypes.EXT_COLOR_STATUS,
      ChangeLogTypes.MODEL_APPLICABILITY_STATUS,
      ChangeLogTypes.ALL_COLOR_STATUS
    );

    useEffect(() => {
      changeLogStore.reset();

      (async () => {
        setIsLoaded(false);

        try {
          await loadData();
        } catch (e) {
          toast.error('Error loading colors change log');
        }

        setIsLoaded(true);
      })();
    }, [
      loadData,
      brand,
      changeLogStore,
      readOnly,
      seriesId,
      teamStore,
      year,
      colorsStore,
      version,
    ]);

    const handleOnRevert = async (item: ChangeLogItem) => {
      setIsLoaded(false);
      try {
        const lang = item.language ?? Language.EN;

        await trackPromise(
          revertChange({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId,
            modelYear: parseInt(year),
            language: toGqlLanguage(lang),
            payload: {
              changeType: item.payload.changeType,
              from: item.payload.from,
              to: item.payload.to ?? '',
              id: item.payload.id,
            },
          })
        );

        await loadData();

        toast.success('Change log reverted');
      } catch {
        toast.error('Error reverting change log');
      }
      setIsLoaded(true);
    };

    return (
      <ChangeLogInner
        module={'Colors'}
        isLoaded={isLoaded}
        handleOnRevert={handleOnRevert}
        changeLogChanges={changeLogChanges}
        canRevert={canRevert}
      />
    );
  }
);

export default observer(ColorsChangeLogController);
