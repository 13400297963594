import React, { useState } from 'react';
import { Button } from 'vapi-ui-common';
import styles from './notes.module.scss';

export interface NotesProps {
  notes: string;
  readOnly?: boolean;
  onUpdate?: (notes: string) => void;
  closePopover?: () => void;
  title?: string;
}

const Notes = (props: NotesProps) => {
  const { notes, readOnly, closePopover, onUpdate, title = 'Notes' } = props;
  const [formNotes, setFormNotes] = useState(notes || '');

  return (
    <section className={styles.notes} data-testid="notes-comp">
      <header className={styles.header}>{title}</header>
      {readOnly && <div>{formNotes}</div>}
      {!readOnly && (
        <>
          <div className={styles.body}>
            <textarea
              placeholder="Placeholder"
              className={styles.textarea}
              onChange={e => setFormNotes(e.currentTarget.value)}
              value={formNotes}
            />
          </div>
          <footer className={styles.footer}>
            <Button
              variant="transparent"
              onClick={() => {
                closePopover && closePopover();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onUpdate && onUpdate(formNotes.trim());
                closePopover && closePopover();
              }}
            >
              Save
            </Button>
          </footer>
        </>
      )}
    </section>
  );
};

export default Notes;
