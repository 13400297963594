import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  Button,
  convertToRichTextObject,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'vapi-ui-common';
import { DisclaimersChangeLogItem } from '../../../../../models/disclaimers.model';

interface RevertChangeModalProps {
  onRevertChange: (item: DisclaimersChangeLogItem) => void;
  close: () => void;
  changeLogItem: DisclaimersChangeLogItem;
}

const RevertChangeModal = observer(
  ({ onRevertChange, close, changeLogItem }: RevertChangeModalProps) => {
    const convertedDescription = convertToRichTextObject(changeLogItem.description).text;

    const description =
      convertedDescription && convertedDescription.length > 120
        ? `${convertedDescription.substring(0, 120)}...`
        : convertedDescription;

    return (
      <>
        <ModalHeader onClose={close}>Revert Disclaimer</ModalHeader>
        <ModalBody>
          Are you sure you want to revert the change for the disclaimer "{description}"?
        </ModalBody>
        <ModalFooter>
          <Button variant="transparent" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onRevertChange(changeLogItem);
              close();
            }}
          >
            Revert
          </Button>
        </ModalFooter>
      </>
    );
  }
);

export default RevertChangeModal;
