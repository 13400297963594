import React from 'react';
import { getSortedListByString } from '../utils/sortUtils';

export interface ChecklistItem {
  name: string;
  selected?: boolean;
  items?: ChecklistItem[];
  hide?: boolean;
  [key: string]: any;
}

const useChecklist = () => {
  const [checklist, updateChecklist] = React.useState([] as ChecklistItem[]);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [hasSelection, setHasSelection] = React.useState(false);

  const selectAll = (selected: boolean) => {
    selectChildren(checklist, selected);
    updateChecklist([...checklist]);
  };

  const selectItem = (item: ChecklistItem, selected: boolean) => {
    item.selected = selected;
    if (item.items) {
      selectChildren(item.items, selected);
    }
    processSelection(checklist);
    updateChecklist([...checklist]);
  };

  const selectChildren = (items: ChecklistItem[], selected: boolean) => {
    items.forEach(item => {
      item.selected = selected;
      if (item.items) {
        selectChildren(item.items, selected);
      }
    });
  };

  const processSelection = (items: ChecklistItem[]) => {
    items.forEach(item => {
      if (item.items) {
        item.selected = item.items.filter(item => !item.selected).length === 0;
        processSelection(item.items);
      }
    });
  };

  const setChecklist = React.useCallback(
    (items: ChecklistItem[]) => {
      updateChecklist(getSortedListByString<ChecklistItem>(items, 'name'));
    },
    [updateChecklist]
  );

  React.useEffect(() => {
    setIsAllSelected(checklist.filter(item => !item.selected).length === 0);

    let hasSelection = false;
    checklist.forEach(item => {
      if (item.selected) {
        hasSelection = true;
      }
      item.items &&
        item.items.forEach(item => {
          if (item.selected) {
            hasSelection = true;
          }
        });
    });
    setHasSelection(hasSelection);
  }, [setIsAllSelected, checklist]);

  return {
    checklist,
    setChecklist,
    selectItem,
    selectAll,
    isAllSelected,
    hasSelection,
  };
};

export default useChecklist;
