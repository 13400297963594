import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { ActionBar, ActionBarDivider, Modal, Spinner } from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import SyncUpdatesPopover from '../../../../components/SyncUpdatesPopover/SyncUpdatesPopover';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useSeriesSettings from '../../../../hooks/useSeriesSettings';
import useStores from '../../../../hooks/useStores';
import { AppliedChangesResponse, ChangeLogTypes } from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { BRAND_LEXUS, BRAND_TDPR, Language } from '../../../../models/user.model';
import { SortModel, VDTab, VehicleTeam } from '../../../../models/vehicleData.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../models/vehicleModel.model';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { refItemsXForm } from '../../../../utils/refItemUtils';
import { getSortModels } from '../../../../utils/vehicleDataUtils';
import { syncSpanishUpdates } from '../../../../webservices/vehicleAdminApi';
import {
  addGrades,
  addModel as addModelApi,
  addModelLexus,
  deleteModel,
  sortModels,
  updateGoLiveDate as updateGoLiveDateApi,
  updateGrade,
  updateModel as updateModelApi,
  updateModelLexus,
} from '../../../../webservices/vehicleModelsApi';
import ActionBarFiltersSection from '../../components/ActionBarFiltersSection';
import SyncTMNAChangesModal from '../../components/SyncTMNAChangesModal/SyncTMNAChangesModal';
import { ProductDataControllerProps } from '../../models/controllers.model';
import ModelsFilters from './components/Filters';
import ModalModalTable from './components/ModelsModalTable';
import useModelsFieldStatusApi from './hooks/useModelsFieldStatusApi';
import styles from './modelsModal.module.scss';
import { displaySyncMessage } from './utils/utils';
import { toGqlBrand, toGqlLanguage, toGqlTeam } from '../../../../utils/graphqlUtils';

const ModelsController = ({
  readOnly,
  seriesId,
  year,
  version,
  versionInfo,
  isPublished,
  reloadDraft,
}: ProductDataControllerProps) => {
  const {
    userStore: { brand, langPermissions },
    teamStore: {
      team: {
        param,
        allowAddDeleteModels,
        allowEditGoLiveDate,
        languages: teamLangs,
        canAddFromDropdown,
        canSyncUpdates,
        allowCopyModels,
        allowDeleteCopiedModels,
      },
    },
    vehicleSeriesInfoStore: { seriesGroup, seriesName },
    vehicleModelsStore,
    seriesSettingsStore,
    modelTabStore: {
      setVehicleModels,
      setReadOnly,
      setTeamLanguages,
      languages: modelTabLangs,
      setLanguageSelected,
      getLangVehicleModel,
      getLangVehicleModelById,
    },
  } = useStores();
  const { isLoaded: seriesLoaded } = useSeriesSettings(brand, seriesId, param, year, versionInfo);

  const [models, setModels] = useState<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]>(
    vehicleModelsStore.filteredVehicleModels
  );
  const [isSyncing, setIsSyncing] = useState(false);
  const [showSyncChangesModal, setShowSyncChangesModal] = useState(false);
  const [syncChangesModel, setSyncChangesModel] = useState<
    VehicleModelItem<VehicleModelLexus | VehicleModelToyota> | undefined
  >(undefined);

  const { updateFieldStatus, updateAllFieldStatuses } = useModelsFieldStatusApi(
    seriesId,
    year,
    models
  );

  useEffect(() => {
    setTeamLanguages(teamLangs);
  }, [teamLangs, setTeamLanguages]);

  useEffect(() => {
    setReadOnly(readOnly);
  }, [readOnly, setReadOnly]);

  useEffect(() => {
    setVehicleModels(vehicleModelsStore.dataLang);
  }, [vehicleModelsStore.dataLang, setVehicleModels]);

  useEffect(() => {
    const sortedModels = vehicleModelsStore.filteredVehicleModels.slice().sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    setModels(sortedModels);
  }, [setModels, vehicleModelsStore.filteredVehicleModels]);

  const updateModel = async (
    vehicleModel: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>,
    language: Language,
    unlinkFromTMNA: boolean
  ) => {
    try {
      const response = await trackPromise(
        brand === BRAND_LEXUS
          ? updateModelLexus({
              brand: toGqlBrand(brand),
              team: toGqlTeam(param),
              seriesId,
              modelYear: parseInt(year),
              payload: vehicleModel.getUpdatePayloadLexus(),
            })
          : updateModelApi({
              brand: toGqlBrand(brand),
              team: toGqlTeam(param),
              seriesId,
              modelYear: parseInt(year),
              language: toGqlLanguage(language),
              payload: {
                ...vehicleModel.getUpdatePayload(brand, unlinkFromTMNA),
              },
            })
      );

      const mdl = response;
      const mdlItem = getLangVehicleModelById(mdl.id, language);
      mdlItem.revId = mdl.revId;
      mdlItem.updateDynamicProps({ fieldStatus: { ...mdl.fieldStatus } });

      // update changed attributes for spanish if accept changes is true
      if (!!mdlItem.getPayload(brand).acceptChanges) {
        getLangVehicleModel(mdlItem, Language.ES).updateDynamicProps({
          changedAttributes: [],
        });
      }

      // update the revId in the store
      vehicleModelsStore.vehicleModels.forEach(item => {
        if (item.id === mdlItem.id) {
          getLangVehicleModel(item, language).revId = getLangVehicleModel(mdlItem, language).revId;
        }
      });

      if (unlinkFromTMNA) {
        getLangVehicleModel(vehicleModel, language).updateDynamicProps({
          fromTMNA: false,
          changedAttributes: [],
          sourceId: '',
        });
      }

      vehicleModel.acceptChanges = !!mdlItem.getPayload(brand).acceptChanges;
      vehicleModelsStore.setModelForLang(vehicleModel, language);
      toast.success(`Updated ${langNameMap[language]} model successfully`);
    } catch (e) {
      handleErrorResponse(e, `Error updating ${langNameMap[language]} model`);
    }
  };

  const updateGoLiveDate = async (language: Language, date: string) => {
    try {
      const payload: KeyValueType<string> = {};
      vehicleModelsStore.vehicleModels.forEach(model => {
        if (!payload[model.id]) {
          payload[model.id] = model.revId;
        }
      });
      const response = await trackPromise(
        updateGoLiveDateApi({
          brand: toGqlBrand(brand),
          team: toGqlTeam(param),
          seriesId,
          modelYear: parseInt(year),
          language: toGqlLanguage(language),
          goLiveDate: date,
          payload,
        })
      );

      response.forEach(item => {
        const mdlItem = getLangVehicleModelById(item.id, language);
        mdlItem.revId = item.revId;
      });

      vehicleModelsStore.vehicleModels.forEach(mdl => {
        getLangVehicleModel(mdl, language).updateDynamicProps({
          goLiveDate: date,
        });
        vehicleModelsStore.setModelForLang(mdl, language);
      });

      toast.success(`Updated all ${langNameMap[language]} go live dates successfully`);
    } catch (e) {
      handleErrorResponse(e, `Error updating all ${langNameMap[language]} go live dates`);
    }
  };

  const handleUpdateVehicleModel = async (
    modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>,
    unlinkFromTMNA: boolean = false
  ) => {
    try {
      const promises: Promise<any>[] = [];
      Object.keys(modelLangMap).forEach(lang => {
        if (vehicleModelsStore.languagePermissions[lang as Language]?.canEdit) {
          promises.push(updateModel(modelLangMap[lang], lang as Language, unlinkFromTMNA));
        }
      });
      await Promise.all(promises);
    } catch (e) {
      handleErrorResponse(e, 'Error updating model');
    }
  };

  const handleUpdateAllGoLiveDates = async (date: string) => {
    try {
      const promises: Promise<any>[] = [];
      teamLangs.forEach(lang => {
        if (langPermissions[lang]?.canEdit) {
          promises.push(trackPromise(updateGoLiveDate(lang, date)));
        }
      });
      await Promise.all(promises);
    } catch (e) {
      handleErrorResponse(e, 'Error updating all go live dates');
    }
  };

  const addModel = async (
    vehicleModel: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>,
    language: Language
  ) => {
    try {
      const response = await trackPromise(
        brand === BRAND_LEXUS
          ? addModelLexus({
              brand: toGqlBrand(brand),
              team: toGqlTeam(param),
              seriesId,
              modelYear: parseInt(year),
              payload: vehicleModel.getUpdatePayloadLexus(),
            })
          : addModelApi({
              brand: toGqlBrand(brand),
              team: toGqlTeam(param),
              seriesId,
              modelYear: parseInt(year),
              language: toGqlLanguage(language),
              payload: vehicleModel.getCreatePayload(brand),
            })
      );
      vehicleModel.revId = response.revId;
      vehicleModel.id = response.id;
      toast.success(`Added ${langNameMap[language]} model successfully`);
    } catch (e) {
      handleErrorResponse(e, `Error adding ${langNameMap[language]} model`);
    }
  };

  const handleAddVehicleModel = async (
    modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>
  ) => {
    const promises: Promise<any>[] = [];
    Object.keys(modelLangMap).forEach(lang => {
      const l = lang as Language;
      if (vehicleModelsStore.languagePermissions[l]?.canEdit) {
        promises.push(addModel(modelLangMap[l], l));
      }
    });
    await Promise.all(promises);

    const vehicleModels = [modelLangMap[Language.EN], ...models];
    await handleOnSortModels(getSortModels(vehicleModels), vehicleModels, modelLangMap);
  };

  const handleDeleteVehicleModel = async (
    vehicleModel: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>
  ) => {
    try {
      const promises: Promise<any>[] = [];
      teamLangs.forEach(lang => {
        if (langPermissions[lang]?.canEdit) {
          promises.push(
            trackPromise(
              deleteModel({
                brand: toGqlBrand(brand),
                team: toGqlTeam(param),
                seriesId,
                modelYear: parseInt(year),
                language: toGqlLanguage(lang),
                deleteVehicleModelId: vehicleModel.id,
              })
            )
          );
        }
      });

      await Promise.all(promises);
      vehicleModelsStore.setModels(models.filter(item => item.id !== vehicleModel.id));
      toast.success('Deleted model successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting model');
    }
  };

  const handleAddGrade = async (grade: string) => {
    const payload: KeyValueType<string> = {};
    teamLangs.forEach(lang => {
      if (langPermissions[lang]?.canEdit) {
        payload[lang] = grade;
      }
    });
    const response = await trackPromise(
      addGrades({
        brand: toGqlBrand(brand),
        team: toGqlTeam(param),
        seriesId,
        modelYear: parseInt(year),
        payload: {
          grades: payload,
        },
      })
    );
    vehicleModelsStore.grades = refItemsXForm(response.EN);
    try {
      toast.success('Added grade successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding grade');
    }
  };

  const handleUpdateGrade = async (gradeId: string, gradeValue: string) => {
    try {
      const promises: Promise<any>[] = [];
      teamLangs.forEach(lang => {
        if (langPermissions[lang]?.canEdit) {
          promises.push(
            trackPromise(
              updateGrade({
                brand: toGqlBrand(brand),
                team: toGqlTeam(param),
                seriesId,
                modelYear: parseInt(year),
                language: toGqlLanguage(lang),
                payload: {
                  gradeId,
                  name: gradeValue,
                },
              })
            )
          );
        }
      });
      await Promise.all(promises);
      vehicleModelsStore.grades = vehicleModelsStore.grades.map(grade => {
        if (grade.id === gradeId) {
          grade.value = gradeValue;
        }
        return grade;
      });
      toast.success('Update grade successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error updating grade');
    }
  };

  const handleSetModels = (models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]) =>
    setModels(models);

  const handleOnSortModels = async (
    sortedModels: SortModel[],
    vehicleModelsToUpdate?: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
    modelLangMap?: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>
  ) => {
    try {
      const vehicleModels = vehicleModelsToUpdate || models;
      const response = await trackPromise(
        sortModels({
          brand: toGqlBrand(brand),
          team: toGqlTeam(param),
          seriesId,
          modelYear: parseInt(year),
          payload: sortedModels,
        })
      );
      response.forEach(responseItem => {
        vehicleModels.forEach(item => {
          if (responseItem.id === item.id) {
            item.sortOrder = responseItem.sortOrder;
            item.revId = responseItem.revId;
          }
        });
      });

      vehicleModelsStore.setModels(vehicleModels, modelLangMap);
    } catch (e) {
      handleErrorResponse(e, 'Error sorting models');
    }
  };

  const handleOnShow = () => {
    vehicleModelsStore.setLocalStorage(param);
  };

  const syncUpdates = async () => {
    setIsSyncing(true);
    try {
      const res = await syncSpanishUpdates(brand, param, seriesId, year);
      displaySyncMessage(res.data.onlyStatusSyncUpdates);

      if (reloadDraft) {
        setIsSyncing(false);
        const teamParam = VehicleTeam.AGENCY_SPANISH;
        const url =
          brand !== BRAND_TDPR
            ? `/vehicleData/draft/${teamParam}/${seriesId}/${year}/EN:${res.data.sourceVersion}|ES:DRAFT?team=${teamParam}&tab=${VDTab.MODELS}`
            : '';
        reloadDraft(url);
      }
      toast.success('Sync Successful');
    } catch (e) {
      handleErrorResponse(e, 'Error with Sync');
      setIsSyncing(false);
    }
  };

  const compareModel = (vehicleModel: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => {
    setSyncChangesModel(vehicleModel);
    setShowSyncChangesModal(true);
  };

  const applyChanges = async (response: AppliedChangesResponse) => {
    if (!syncChangesModel) {
      return;
    }
    const updateMap: {
      [lang: string]: {
        [fieldToUpdate: string]: any;
      };
    } = {};
    const changeLogTypes: ChangeLogTypes[] = Object.keys(response.applied) as ChangeLogTypes[];
    changeLogTypes.forEach(changeType => {
      const langMap = response.applied[changeType];
      if (langMap) {
        Object.entries(langMap).forEach(([lang, after]) => {
          if (!updateMap[lang]) {
            updateMap[lang] = {};
          }
          const modelVM = getLangVehicleModel(syncChangesModel, lang as Language);
          if (modelVM) {
            switch (changeType) {
              case ChangeLogTypes.CODE:
                updateMap[lang].code = after;
                break;
              case ChangeLogTypes.GO_LIVE_DATE:
                updateMap[lang].goLiveDate = after;
                break;
              case ChangeLogTypes.DRIVE:
                updateMap[lang].drive = after;
                break;
              case ChangeLogTypes.TRIM_TITLE:
                updateMap[lang].trimTitle = after;
                break;
              case ChangeLogTypes.DESCRIPTION:
                updateMap[lang].description = after;
                break;
              case ChangeLogTypes.ENGINE:
                updateMap[lang].engine = after;
                break;
              case ChangeLogTypes.TRANSMISSION:
                updateMap[lang].transmission = after;
                break;
              case ChangeLogTypes.BED:
                updateMap[lang].bed = after;
                break;
              case ChangeLogTypes.CAB:
                updateMap[lang].cab = after;
                break;
              case ChangeLogTypes.SEATING:
                updateMap[lang].seating = after;
                break;
              case ChangeLogTypes.KATASHIKI:
                updateMap[lang].katashiki = after;
                break;
              case ChangeLogTypes.HORSEPOWER:
                updateMap[lang].horsepower = after;
                break;
              case ChangeLogTypes.FUEL_TYPE:
                const newFuelType = vehicleModelsStore.fuelTypes.find(
                  fuelType => fuelType.id === after
                );
                if (newFuelType) {
                  updateMap[lang].fuelType = newFuelType;
                }
                break;
              default:
                break;
            }
          }
        });
      }
    });

    const modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>> = {};
    for (const lang of teamLangs) {
      if (langPermissions[lang]?.canEdit) {
        const modelVM = getLangVehicleModel(syncChangesModel, lang);
        let uMap: { [key: string]: any } = { changedAttributes: [], acceptChanges: true };
        if (updateMap[lang] && Object.keys(updateMap[lang]).length) {
          uMap = {
            ...uMap,
            ...updateMap[lang],
          };
        }
        modelVM.updateDynamicProps(uMap);
        modelLangMap[lang] = modelVM;
      }
    }

    await handleUpdateVehicleModel(modelLangMap);
    for (const lang of teamLangs) {
      if (langPermissions[lang]?.canEdit) {
        getLangVehicleModel(syncChangesModel, lang).updateDynamicProps({ changedAttributes: [] });
      }
    }
  };

  const getActionBarButtons = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];

    if (teamLangs.length > 1) {
      for (const lang of teamLangs) {
        actionBarButtons.push(
          <Checkbox
            id={`${lang}-select-checkbox`}
            checked={modelTabLangs[lang].selected}
            onChange={e => setLanguageSelected(lang, e.currentTarget.checked)}
          >
            <span>{langNameMap[lang]}</span>
          </Checkbox>
        );
      }
    }

    if (showActionButtons && canSyncUpdates) {
      actionBarButtons.push(
        <SyncUpdatesPopover
          sourceEnVersion={versionInfo.EN?.toString()}
          brand={brand}
          team={param}
          seriesId={seriesId}
          year={year}
          syncUpdates={syncUpdates}
        />
      );
    }

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <>
      <div className={cx(styles.actionBar)}>
        <ActionBar>
          <ActionBarFiltersSection
            renderButtons={getActionBarButtons(!readOnly)}
            renderFilter={onClose => {
              if (param === VehicleTeam.AGENCY_TEAM && version == null) {
                return (
                  <ModelsFilters
                    onClose={onClose}
                    isReviewNotesFilter={vehicleModelsStore.isReviewNotesFilter}
                    setIsReviewNotesFilter={value =>
                      vehicleModelsStore.onFilter(
                        () => (vehicleModelsStore.isReviewNotesFilter = value)
                      )
                    }
                    isSyncUpdateFilter={vehicleModelsStore.isSyncUpdateFilter}
                    setIsSyncUpdateFilter={value =>
                      vehicleModelsStore.onFilter(
                        () => (vehicleModelsStore.isSyncUpdateFilter = value)
                      )
                    }
                    isPublished={isPublished}
                  />
                );
              } else {
                return <></>;
              }
            }}
          />
          {/* <LanguageCheckbox /> */}
        </ActionBar>
      </div>

      {!seriesLoaded || isSyncing ? (
        <Spinner />
      ) : (
        <ModalModalTable
          readOnly={readOnly}
          brand={brand}
          seriesGroup={seriesGroup}
          seriesName={seriesName}
          version={version}
          onShow={handleOnShow}
          onAddModel={handleAddVehicleModel}
          onDeleteModel={handleDeleteVehicleModel}
          onUpdateModel={handleUpdateVehicleModel}
          onUpdateAllGoLiveDates={handleUpdateAllGoLiveDates}
          onSortModels={handleOnSortModels}
          vehicleModels={models}
          grades={vehicleModelsStore.grades}
          fuelTypes={vehicleModelsStore.fuelTypes}
          setModels={handleSetModels}
          onAddGrade={handleAddGrade}
          onUpdateGrade={handleUpdateGrade}
          canAddDelete={allowAddDeleteModels}
          canCopy={allowCopyModels}
          canDeleteCopiedModels={allowDeleteCopiedModels}
          seriesSettings={seriesSettingsStore.getDefaultSeriesSettings(
            seriesSettingsStore.seriesSettingsLangMaps
          )}
          canEditGoLiveDate={allowEditGoLiveDate}
          canAddFromDropdown={canAddFromDropdown}
          compareModel={compareModel}
          editableLanguages={teamLangs.filter(lang => !!langPermissions[lang]?.canEdit)}
          updateFieldStatus={updateFieldStatus}
          updateAllFieldStatuses={updateAllFieldStatuses}
        />
      )}
      <Modal open={showSyncChangesModal} size="auto" onClose={() => setShowSyncChangesModal(false)}>
        <SyncTMNAChangesModal
          brand={brand}
          team={param}
          seriesId={seriesId}
          year={year}
          itemId={syncChangesModel ? syncChangesModel.id : ''}
          entityType={'models'}
          isNew={!!syncChangesModel?.getVal('changedAttributes').includes('new')}
          isDelete={!!syncChangesModel?.getVal('changedAttributes').includes('delete')}
          close={(response, shouldDelete, unlinkFromTMNA) => {
            setShowSyncChangesModal(false);
            if (syncChangesModel) {
              if (response) {
                applyChanges(response);
              } else if (shouldDelete) {
                handleDeleteVehicleModel(syncChangesModel);
              } else if (unlinkFromTMNA) {
                const modelLangMap: KeyValueType<VehicleModelItem<
                  VehicleModelLexus | VehicleModelToyota
                >> = {};
                teamLangs.forEach(lang => {
                  if (langPermissions[lang]?.canEdit) {
                    modelLangMap[lang] = getLangVehicleModel(syncChangesModel, lang);
                  }
                });
                handleUpdateVehicleModel(modelLangMap, true);
              }
            }
          }}
          fuelTypes={vehicleModelsStore.fuelTypes}
        />
      </Modal>
    </>
  );
};

export default observer(ModelsController);
