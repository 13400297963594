import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../components/ChangeLogInner';
import useFeaturesChangeLogChanges from '../../../../hooks/useFeaturesChangeLogChanges';
import useKeyFeatureOptionsLoad from '../../../../hooks/useKeyFeatureOptionsLoad';
import useStores from '../../../../hooks/useStores';
import {
  ChangeLogItem,
  ChangeLogLangMap,
  ChangeLogResponse,
  ChangeLogTypes,
  SYNCED_CHANGE_TYPES_MAP,
} from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import {
  changeLogHandleDeleteTypes,
  changeLogIdMapper,
  changeLogModelApplicabilityMapper,
} from '../../../../utils/changeLogUtils';
import { filterOutUnsuableChanges } from '../../../../utils/gradeApplicabilityUtils';
import {
  getCategoriesByLang,
  getChangeLog,
  getSubCategoriesByLang,
  revertChange,
} from '../../../../webservices/vehicleFeaturesApi';

interface FeaturesChangeLogProps {
  seriesId: string;
  year: string;
  readOnly?: boolean;
  version: string;
}

const FeaturesChangeLog = ({ seriesId, year, readOnly, version }: FeaturesChangeLogProps) => {
  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
    featuresStore,
    vehicleModelsStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);
  const { loadKeyFeatures } = useKeyFeatureOptionsLoad();
  const {
    filterChangeLogChanges,
    changeLogChanges,
    canRevert,
    changeLogKeyFeatureModelApplicabilityMapper,
  } = useFeaturesChangeLogChanges();

  useEffect(() => {
    changeLogStore.reset();

    (async () => {
      setIsLoaded(false);

      try {
        await loadKeyFeatures();
        const selectedLangs: KeyValueType<boolean> = {};
        teamStore.team.changeLogLanguages.forEach(lang => {
          selectedLangs[lang] = true;
        });
        changeLogStore.selectedLangsMap = selectedLangs;

        const promises: Promise<AxiosResponse<any>>[] = [];
        for (const lang of teamStore.team.changeLogLanguages) {
          const l = lang.toUpperCase();
          promises.push(
            getChangeLog(brand, teamStore.team.param, seriesId, year, l, version, 'feature'),
            getCategoriesByLang(brand, teamStore.team.param, seriesId, year, l, version), // fetch deleted records as well
            getSubCategoriesByLang(brand, teamStore.team.param, seriesId, year, l, version) // fetch deleted records as well
          );
        }

        const responses = await Promise.all(promises);
        let index = 0;
        const changeLogLangMap: ChangeLogLangMap = {};
        for (const lang of teamStore.team.changeLogLanguages) {
          const baseIndex = index * 3;
          const logs = (responses[baseIndex].data as ChangeLogResponse[])
            .map(item => {
              const changeLogItem = new ChangeLogItem(item, !readOnly, lang);
              // cant revert changes for category and subcategory changes for tdpr
              if (
                SYNCED_CHANGE_TYPES_MAP[changeLogItem.changeType] &&
                teamStore.team.changeLogLanguages.length > 1
              ) {
                changeLogItem.canRevert = false;
              }

              changeLogKeyFeatureModelApplicabilityMapper(
                changeLogItem,
                featuresStore.keyFeaturesMap ?? {}
              );
              return changeLogItem;
            })
            .filter(filterOutUnsuableChanges)
            .filter(filterChangeLogChanges);

          featuresStore.updateCategoriesLangMap(
            lang,
            featuresStore.categoriesMap,
            responses[baseIndex + 1].data
          );
          featuresStore.updateCategoriesLangMap(
            lang,
            featuresStore.subCategoriesMap,
            responses[baseIndex + 2].data
          );

          changeLogIdMapper(
            featuresStore.getCategoriesForLang(lang, featuresStore.categoriesMap),
            logs,
            ChangeLogTypes.CATEGORY
          );
          changeLogIdMapper(
            featuresStore.getCategoriesForLang(lang, featuresStore.subCategoriesMap),
            logs,
            ChangeLogTypes.SUB_CATEGORY
          );

          changeLogHandleDeleteTypes(logs, ChangeLogTypes.FEATURE_DELETED);
          changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
          changeLogLangMap[lang] = logs;

          index += 1;
        }

        changeLogStore.changeLogLangMap = changeLogLangMap;
      } catch (e) {
        toast.error('Error loading features change log');
      }

      setIsLoaded(true);
    })();
  }, [
    brand,
    changeLogStore,
    readOnly,
    seriesId,
    teamStore,
    year,
    featuresStore,
    version,
    vehicleModelsStore,
    filterChangeLogChanges,
    changeLogKeyFeatureModelApplicabilityMapper,
    loadKeyFeatures,
  ]);

  const handleOnRevert = async (item: ChangeLogItem) => {
    setIsLoaded(false);
    try {
      const changeLogLangMap: ChangeLogLangMap = JSON.parse(
        JSON.stringify(changeLogStore.changeLogLangMap)
      );
      const lang = item.language ?? Language.EN;
      await trackPromise(
        revertChange(brand, teamStore.team.param, seriesId, year, lang, 'feature', item.payload)
      );
      const response = await getChangeLog(
        brand,
        teamStore.team.param,
        seriesId,
        year,
        lang,
        version,
        'feature'
      );
      const logs = response.data
        .map(item => {
          const changeLogItem = new ChangeLogItem(item, !readOnly, lang);
          changeLogKeyFeatureModelApplicabilityMapper(
            changeLogItem,
            featuresStore.keyFeaturesMap ?? {}
          );
          return changeLogItem;
        })
        .filter(filterOutUnsuableChanges)
        .filter(filterChangeLogChanges);
      changeLogIdMapper(
        featuresStore.getCategoriesForLang(lang, featuresStore.categoriesMap),
        logs,
        ChangeLogTypes.CATEGORY
      );
      changeLogIdMapper(
        featuresStore.getCategoriesForLang(lang, featuresStore.subCategoriesMap),
        logs,
        ChangeLogTypes.SUB_CATEGORY
      );
      changeLogHandleDeleteTypes(logs, ChangeLogTypes.FEATURE_DELETED);
      changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
      changeLogLangMap[lang] = logs;
      changeLogStore.changeLogLangMap = changeLogLangMap;

      toast.success('Change log reverted');
    } catch {
      toast.error('Error reverting change log');
    }
    setIsLoaded(true);
  };
  return (
    <ChangeLogInner
      module={'Feature'}
      isLoaded={isLoaded}
      handleOnRevert={handleOnRevert}
      changeLogChanges={changeLogChanges}
      canRevert={canRevert}
    />
  );
};

export default observer(FeaturesChangeLog);
