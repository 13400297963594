import { action, computed, observable } from 'mobx';
import { convertToRichTextObject } from 'vapi-ui-common';
import { ChangeLogItem, ChangeLogLangMap } from '../models/changeLog.model';
import { IDValueType, KeyValueType } from '../models/common.model';
import { sortBy, toLowerCase } from '../utils';

class ChangeLogStore {
  @observable logs: ChangeLogItem[] = [];
  @observable changeLogLangMap: ChangeLogLangMap = {};
  @observable categories: IDValueType[] = [];
  @observable subCategories: IDValueType[] = [];
  @observable searchText = '';
  @observable categoryFilters: string[] = [];
  @observable isInProgressFilter = false;
  @observable isHighlightedFilter = false;
  @observable viewModelCodes = false;
  @observable showLongDescription = true;
  @observable showShortDescription = false;
  @observable reverse = true;
  @observable sortField = 'modifiedDate';
  @observable selectedLangsMap: KeyValueType<boolean> = {};

  setSort = (sortField: string) => {
    this.reverse = this.sortField !== sortField ? false : !this.reverse;
    this.sortField = sortField;
  };

  @computed get filteredLogs() {
    const baseLogs: ChangeLogItem[] = [];
    const langs = Object.keys(this.selectedLangsMap);
    if (langs.length) {
      langs.forEach(lang => {
        if (this.selectedLangsMap[lang] && this.changeLogLangMap[lang]) {
          baseLogs.push(...this.changeLogLangMap[lang]);
        }
      });
    } else {
      baseLogs.push(...this.logs);
    }
    const logs = baseLogs
      .slice()
      .sort(sortBy(this.sortField, this.reverse, this.sortField === 'modifiedDate'));

    const lowerSearchText = toLowerCase(this.searchText);

    return logs.filter(feature => {
      let after = convertToRichTextObject(feature.after).text;
      if (typeof after !== 'string') {
        after = '';
      }
      return (
        !lowerSearchText ||
        toLowerCase(after).includes(lowerSearchText) ||
        toLowerCase(convertToRichTextObject(feature.description).text).includes(lowerSearchText) ||
        toLowerCase(convertToRichTextObject(feature.changeType).text).includes(lowerSearchText) ||
        toLowerCase(convertToRichTextObject(feature.modifiedBy).text).includes(lowerSearchText) ||
        toLowerCase(convertToRichTextObject(feature.modifiedDate).text).includes(lowerSearchText) ||
        toLowerCase(feature.name).includes(lowerSearchText)
      );
    });
  }

  @action reset() {
    this.logs = [];
    this.changeLogLangMap = {};
    this.categories = [];
    this.subCategories = [];
    this.searchText = '';
    this.categoryFilters = [];
    this.isInProgressFilter = false;
    this.isHighlightedFilter = false;
    this.viewModelCodes = false;
    this.showLongDescription = true;
    this.showShortDescription = false;
    this.reverse = true;
    this.sortField = 'modifiedDate';
    this.selectedLangsMap = {};
  }
}

export default ChangeLogStore;
