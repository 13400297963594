import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { removeNulls } from '../utils';
import { ChangeLogItem, ChangeLogTypes } from './changeLog.model';
import { KeyValueType } from './common.model';
import { Language } from './user.model';
import { VehicleTeam } from './vehicleData.model';
import { VehicleModelItem, VehicleModelLexus, VehicleModelToyota } from './vehicleModel.model';

export interface ReviewChangeResponse {
  after: string | KeyValueType;
  before: string | KeyValueType;
  changeTypeId: string;
  changeType: ChangeLogTypes;
  isAccepted: boolean;
  isApplied: boolean;
  isNewChange: boolean;
  rejectNotes: string;
  bnpSplitId?: string;
}
export interface ReviewProps {
  brand: string;
  team: VehicleTeam;
  series: string;
  year: string;
  version: string;
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
}
export interface ReviewChangeRequest {
  id: string;
  revId: string;
  parentId?: string;
  parentRevId?: string;
  changeTypeId: string;
  isAccepted: boolean;
  isApplied: boolean;
  rejectNotes: string;
  itemKey?: string;
  categoryKey?: string;
}

export interface ReviewChangeMap<T1 = any, T2 = boolean> {
  before: T1;
  after: T1;
  hasChanged: T2;
}

export interface ReviewChangeTypeMap {
  id: string;
  revId: string;
  isApplied: boolean;
  isNew: boolean;
  isDeleted: boolean;
  rejectNotes: string;
  rowHeight?: number;
  notes: string;
  isInProgress: boolean;
}

export class ReviewChangeBaseItem {
  uid = uuidv4();
  @observable id = '';
  @observable revId = '';
  @observable changes = new ChangeLogItem();
  @observable changeTypeId = '';
  @observable isAccepted = false;
  @observable isApplied = false;
  @observable isNewChange = false;
  @observable rejectNotes = '';
  @observable rowHeight = 0;

  constructor(change?: ReviewChangeResponse, changeTypeId?: string) {
    if (change) {
      const { after, before, changeType, ...rest } = change;
      Object.assign(this, removeNulls(rest));
      Object.assign(this.changes, removeNulls({ after, before, changeType }));
      this.changeTypeId = changeTypeId ? changeTypeId : '';
    }
  }

  getPayload = (): ReviewChangeRequest => {
    return {
      id: this.id,
      revId: this.revId,
      changeTypeId: this.changeTypeId,
      isAccepted: this.isAccepted,
      isApplied: this.isApplied,
      rejectNotes: this.rejectNotes,
    };
  };

  isValid = () => {
    return this.isAccepted || !!this.rejectNotes;
  };
}

export class ReviewSyncItem {
  uid = uuidv4();
  // @observable id = '';
  // @observable revId = '';
  @observable changes = new ChangeLogItem();
  @observable changeTypeId = '';
  @observable notes = '';
  @observable rowHeight = 0;
  @observable language: Language = Language.EN;
  @observable description: string = '';
  @observable publishedDate: string = '';

  constructor(
    lang: Language,
    response: ReviewSyncResponse,
    change: ReviewChangeResponse,
    changeTypeId?: string
  ) {
    const { after, before, changeType, ...rest } = change;
    Object.assign(this, removeNulls(rest));
    Object.assign(this.changes, removeNulls({ after, before, changeType }));
    this.changeTypeId = changeTypeId ? changeTypeId : '';

    this.language = lang;
    // this.id = response.id;
    // this.revId = response.revId;
    this.description = response.description ?? response.name ?? '';
    this.notes = response.notes ?? '';
    this.publishedDate = response.publishedDate ?? '';
  }
}

export interface ReviewSyncResponse {
  id: string;
  revId: string;
  description?: string;
  name?: string;
  publishedDate?: string;
  notes?: string;
  changes: KeyValueType<ReviewChangeResponse>;
}

export interface TMNASyncReviewResponse {
  langs: {
    [k in Language]?: ReviewSyncResponse;
  };
}

export interface TMNASyncReviewResponseRedux {
  [changeType: string]: {
    [k in Language]?: ReviewSyncResponse;
  };
}
