import cx from 'clsx';
import React, { useContext, useState } from 'react';
import { Checkbox } from 'vapi-ui-common';
import { SplitModelApplicabilityModalContext } from '.';
import IconTextButton from '../../../../../../components/IconTextButton';
import {
  ModelApplicability,
  ModelApplicabilityModel,
} from '../../../../../../hooks/useModelApplicability';
import styles from './splitModelApplicabilityModal.module.scss';

interface ModelApplicabilityListProps {
  splitModelApplicability: ModelApplicability;
  includedModels: ModelApplicabilityModel[];
}

const ModelApplicabilityList = ({
  splitModelApplicability: { grade, numChecked, setIsChecked },
  includedModels,
}: ModelApplicabilityListProps) => {
  const context = useContext(SplitModelApplicabilityModalContext);
  const [isModelApplicabilityListVisible, setIsModelApplicabilityListVisible] = useState(false);

  if (!context) {
    return null;
  }

  const { isCheckedGrade } = context;

  const splitModelApplicabilityModels: ModelApplicabilityModel[] = includedModels;
  const isChecked = isCheckedGrade(grade.id);

  return (
    <>
      <div className={styles.modelApplicabilityItem}>
        <Checkbox
          labelClassName={cx({ [styles.checkboxSquare]: numChecked && !isChecked })}
          id={grade.id}
          checked={isChecked}
          onChange={e => setIsChecked(!isChecked)}
        >
          {grade.value}
        </Checkbox>
        <IconTextButton
          className={styles.iconTextButton}
          icon={isModelApplicabilityListVisible ? 'chevronUp' : 'chevronDown'}
          text=""
          onClick={() => setIsModelApplicabilityListVisible(!isModelApplicabilityListVisible)}
        />
      </div>
      {isModelApplicabilityListVisible && (
        <div className={cx(styles.modelApplicabilityList, styles.modelApplicabilityGrades)}>
          {splitModelApplicabilityModels.map(({ model, isChecked, setIsChecked }) => (
            <Checkbox
              key={model.id}
              className={styles.modelApplicabilityItem}
              id={model.id}
              checked={isChecked}
              onChange={e => setIsChecked(!isChecked)}
            >
              {model.getVal('code')}
            </Checkbox>
          ))}
        </div>
      )}
    </>
  );
};

export default ModelApplicabilityList;
