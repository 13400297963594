import cx from 'clsx';
import React from 'react';
import { KeyValueType } from '../../../../../../models/common.model';
import { FeatureSettings } from '../../../../../../models/features.model';
import { ReviewChangeMap } from '../../../../../../models/review.model';
import styles from './modelApplicabilityReviewCell.module.scss';

interface IModelApplicabilityReviewCell {
  modelId: string;
  map: ReviewChangeMap<KeyValueType<FeatureSettings | string>>;
  isNew: boolean;
  rowHeight: number;
  isDeleted: boolean;
  className?: string;
}
const ModelApplicabilityReviewCell = ({
  modelId,
  map,
  isNew,
  rowHeight,
  isDeleted,
  className,
}: IModelApplicabilityReviewCell) => {
  const addedClass = styles.greenText;
  const removedClass = styles.redText;
  const hasChanged = map.hasChanged;

  const before = map.before[modelId] || FeatureSettings.UNDEFINED;
  const after = map.after[modelId] || FeatureSettings.UNDEFINED;

  const applicabilityRow = (
    applicability: string | null,
    className: string,
    defaultValue: string = '--'
  ) => {
    return (
      <div className={cx(styles.autoMargin, className)}>
        {applicability?.length ? applicability : defaultValue}
      </div>
    );
  };

  return (
    <td
      className={cx(styles.td, className)}
      style={{ height: rowHeight > 0 ? rowHeight : '' }}
      key={modelId}
      rowSpan={1}
    >
      {(isDeleted || (!isNew && hasChanged && before && before !== after)) &&
        applicabilityRow(before, removedClass, '')}
      {!isDeleted &&
        applicabilityRow(after, isNew || (hasChanged && before !== after) ? addedClass : '')}
    </td>
  );
};
export default ModelApplicabilityReviewCell;
