import * as differ from 'diff';
import { ChangeLogTypes } from '../models/changeLog.model';
import {
  DisclaimersChangeLogItem,
  DisclaimersChangeLogResponse,
  DisclaimersChangeLogResponseItem,
  DisclaimersItem,
} from '../models/disclaimers.model';
import { toLowerCase } from '../utils';
import { v4 as uuidv4 } from 'uuid';
import { SuggestionType } from 'vapi-ui-common';

export const changeLogXForm = (version: string, data: DisclaimersChangeLogResponse) => {
  const newData: DisclaimersChangeLogItem[] = [];
  data.changes.forEach((item: DisclaimersChangeLogResponseItem) => {
    item.changes.forEach(change => {
      const { id, revId, description, notes } = item;
      const changeType = change.changeType as ChangeLogTypes;
      newData.push({
        uid: uuidv4(),
        id,
        revId,
        description,
        description_es: ChangeLogTypes.DESCRIPTION_ES ? change.after : change.afterValue,
        notes,
        changeType,
        before: change.before,
        beforeValue:
          change.changeType === ChangeLogTypes.SUB_CATEGORY ? change.before : change.beforeValue,
        afterValue:
          change.changeType === ChangeLogTypes.SUB_CATEGORY ? change.after : change.afterValue,
        after: change.after,
        changes: differ.diffChars(change.before || '', change.after || ''),
        modelsAdded: change.modelsAdded,
        modelsRemoved: change.modelsRemoved,
        modifiedDate: change.modifiedDate,
        modifiedBy: change.modifiedBy,
        canRevert:
          version.toUpperCase() === 'DRAFT' &&
          changeType !== ChangeLogTypes.CATEGORY &&
          changeType !== ChangeLogTypes.DRAFT_RESET,
        token: item.token,
      });
    });
  });
  return newData;
};

export const filterDisclaimers = (
  searchText: string,
  vehicleFilters: string[],
  data: DisclaimersItem[]
) => {
  const loweredSearchText = toLowerCase(searchText);
  return data.filter(
    item =>
      // test search string
      (!loweredSearchText ||
        toLowerCase(item.token).includes(loweredSearchText) ||
        toLowerCase(item.description).includes(loweredSearchText) ||
        toLowerCase(item.description_es).includes(loweredSearchText) ||
        toLowerCase(item.notes).includes(loweredSearchText)) &&
      // test filter vehicle box
      (!vehicleFilters.length ||
        vehicleFilters.filter(
          filterItem =>
            item.vehicles &&
            item.vehicles.filter(
              vehicle =>
                `${vehicle.seriesId}${vehicle.modelYear}${vehicle.seriesName}` === filterItem
            ).length
        ).length)
  );
};

export const tokensXForm = (tokens?: string[]): SuggestionType[] => {
  const suggestions: SuggestionType[] = [];

  if (tokens && tokens.length) {
    tokens.forEach(token => suggestions.push({ name: token }));
  }

  return suggestions;
};
