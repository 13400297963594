import React from 'react';
import { NavLink } from 'react-router-dom';
import { SideMenuIcon } from 'vapi-ui-common';
import useStores from '../../hooks/useStores';
import { REGION_GST } from '../../models/user.model';

type Props = {
  collapsed: boolean;
};

const CollapsedFooterMenu = ({ collapsed }: Props) => {
  const {
    userStore: { region },
  } = useStores();

  if (!collapsed) return null;

  if (region !== REGION_GST) {
    return (
      <ul data-testid="collapsed-footer-menu">
        <NavLink to={'/help'}>
          <SideMenuIcon icon="help" />
        </NavLink>
      </ul>
    );
  }

  return (
    <ul data-testid="collapsed-footer-menu">
      <a href={`${process.env.REACT_APP_ACCESSORIES_URL}/help?collapsed=true`}>
        <SideMenuIcon icon="help" />
      </a>
    </ul>
  );
};

export default CollapsedFooterMenu;
