import React, { useState } from 'react';
import { Button } from 'vapi-ui-common';
import Input from '../../../../components/Input';
import styles from './extraCostPopover.module.scss';

interface MsrpProps {
  msrp: string;
  onUpdate?: (cost: string) => void;
  closePopover?: () => void;
  onCostChange?: (cost: string) => void;
}

const ExtraCostContent = (props: MsrpProps) => {
  const {
    msrp,
    closePopover,
    onUpdate,
    onCostChange, // readOnly
  } = props;
  const [formMsrp, setFormMsrp] = useState(msrp);

  return (
    <section className={styles.msrpSection}>
      <header className={styles.header}>Extra Cost Color</header>
      <div>
        <label htmlFor="msrp" className={styles.label}>
          MSRP
        </label>
        <span className={styles.currencyInput}>
          <Input
            className={styles.input}
            onChange={e => {
              setFormMsrp(e.currentTarget.value.trim());
              onCostChange && onCostChange(e.currentTarget.value.trim());
            }}
            value={formMsrp}
          />
        </span>
      </div>
      <footer className={styles.footer}>
        <Button
          variant="transparent"
          onClick={() => {
            closePopover && closePopover();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onUpdate && onUpdate(formMsrp.trim());
            closePopover && closePopover();
          }}
        >
          Save
        </Button>
      </footer>
    </section>
  );
};

export default ExtraCostContent;
