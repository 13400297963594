import { useCallback } from 'react';
import { BnPCategoryItem } from '../models/buildAndPrice.model';
import { KeyValueType } from '../models/common.model';
import { RefItem } from '../models/refItem.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../models/vehicleModel.model';
import useStores from './useStores';

export interface ModelApplicabilityModel {
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
}

export interface ModelApplicability {
  grade: RefItem;
  numChecked: number;
  setIsChecked: (isChecked: boolean) => void;
  models: KeyValueType<ModelApplicabilityModel>;
}

export interface ModelApplicabilityMap {
  [gradId: string]: ModelApplicability;
}

export interface ModelApplicabilityMetadataMap {
  [gradeId: string]: ModelApplicabilityMetadata;
}

export interface ModelApplicabilityMetadata {
  modelApplicability: ModelApplicability;
  includedModels: ModelApplicabilityModel[];
  excludedModels: ModelApplicabilityModel[];
}

export interface AllCheckedInfo {
  isChecked: boolean;
  numCheckedGrades: number;
  numCheckedModels: number;
  checkedModels: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>;
}

interface GenerateModelApplicabilityMapProps {
  setGradeIsChecked?: (isChecked: boolean, gradeId: string) => void;
  setModelIsChecked?: (isChecked: boolean, gradeId: string, modelId: string) => void;
  isCheckedModel: (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => boolean;
  category?: BnPCategoryItem;
}

const useModelApplicabilityMap = () => {
  const {
    vehicleModelsStore: { grades, vehicleModels },
  } = useStores();

  const isCheckedGrade = (mApplicabilityMap: ModelApplicabilityMap, gradeId: string) => {
    const { numChecked, models } = mApplicabilityMap[gradeId];
    return numChecked >= Object.values(models).length;
  };

  const setGrades = useCallback(
    (
      mApplicabilityMap: ModelApplicabilityMap,
      setGradeIsChecked: (isChecked: boolean, gradeId: string) => void
    ) => {
      grades.forEach(grade => {
        const gradeId = grade.id;

        mApplicabilityMap[gradeId] = {
          grade,
          numChecked: 0,
          setIsChecked: (isChecked: boolean) => setGradeIsChecked(isChecked, gradeId),
          models: {},
        };
      });
    },
    [grades]
  );

  const setModels = useCallback(
    (
      mApplicabilityMap: ModelApplicabilityMap,
      setModelIsChecked: (isChecked: boolean, gradeId: string, modelId: string) => void,
      isCheckedModel: (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => boolean,
      category: BnPCategoryItem | undefined
    ) => {
      vehicleModels
        .filter(mdl => !category || category.applicability[mdl.id])
        .forEach(model => {
          const gradeId = model.getVal('grade').id;
          const modelId = model.id;
          const isChecked = isCheckedModel(model);
          const { numChecked } = mApplicabilityMap[gradeId];

          const mApplicabilityModel: ModelApplicabilityModel = {
            model,
            isChecked: isChecked,
            setIsChecked: (isChecked: boolean) => setModelIsChecked(isChecked, gradeId, modelId),
          };

          mApplicabilityMap[gradeId].models[modelId] = mApplicabilityModel;
          mApplicabilityMap[gradeId].numChecked = isChecked ? numChecked + 1 : numChecked;
        });
    },
    [vehicleModels]
  );

  const generateModelApplicabilityMap = useCallback(
    ({
      setGradeIsChecked = () => undefined,
      setModelIsChecked = () => undefined,
      isCheckedModel,
      category,
    }: GenerateModelApplicabilityMapProps) => {
      const sModelApplicabilityMap: ModelApplicabilityMap = {};

      setGrades(sModelApplicabilityMap, setGradeIsChecked);
      setModels(sModelApplicabilityMap, setModelIsChecked, isCheckedModel, category);

      return sModelApplicabilityMap;
    },
    [setGrades, setModels]
  );

  const allCheckedInfo = (mApplicabilityMap: ModelApplicabilityMap): AllCheckedInfo => {
    const mApplicabilityList: ModelApplicability[] = Object.values(mApplicabilityMap);
    let numCheckedGrades = 0;
    let numCheckedModels = 0;
    const checkedModels: KeyValueType<VehicleModelItem<
      VehicleModelLexus | VehicleModelToyota
    >> = {};

    mApplicabilityList.forEach(({ numChecked, models }) => {
      const modelList: ModelApplicabilityModel[] = Object.values(models);

      if (numChecked >= modelList.length) {
        numCheckedGrades++;
      }

      modelList.forEach(({ isChecked, model }) => {
        if (isChecked) {
          numCheckedModels++;
          checkedModels[model.id] = model;
        }
      });
    });

    return {
      isChecked: numCheckedGrades >= mApplicabilityList.length,
      numCheckedGrades,
      numCheckedModels,
      checkedModels,
    };
  };

  return {
    isCheckedGrade,
    generateModelApplicabilityMap,
    allCheckedInfo,
  };
};

export default useModelApplicabilityMap;
