import React, { useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabColumn,
  FilterTabColumns,
  FilterTabFooter,
} from 'vapi-ui-common';
import Checkbox from '../../../../../../components/Checkbox';

type Props = {
  isInProgressFilter: boolean;
  setIsInProgressFilter: (value: boolean) => void;
  onClose: () => void;
  isReviewNotesFilter?: boolean;
  setIsReviewNotesFilter?: (value: boolean) => void;
};

const OptionsLexusFilters: React.FC<Props> = ({
  isInProgressFilter,
  setIsInProgressFilter,
  onClose,
  isReviewNotesFilter,
  setIsReviewNotesFilter,
}) => {
  const [isInProgress, setIsInProgress] = useState(isInProgressFilter);
  const [isReviewNotes, setIsReviewNotes] = useState(isReviewNotesFilter || false);

  const applyFilters = () => {
    setIsInProgressFilter(isInProgress);
    setIsReviewNotesFilter && setIsReviewNotesFilter(isReviewNotes);
    onClose();
  };

  return (
    <FilterTab>
      <FilterTabColumns>
        <FilterTabColumn>
          <Checkbox
            id="InProgress"
            defaultChecked={isInProgress}
            onChange={e => setIsInProgress(e.currentTarget.checked)}
          >
            In Progress
          </Checkbox>
        </FilterTabColumn>
        {setIsReviewNotesFilter && (
          <FilterTabColumn>
            <Checkbox
              id="ReviewNotes"
              defaultChecked={isReviewNotes}
              onChange={e => setIsReviewNotes(e.currentTarget.checked)}
            >
              Review Notes
            </Checkbox>
          </FilterTabColumn>
        )}
      </FilterTabColumns>
      <FilterTabFooter>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </FilterTabFooter>
    </FilterTab>
  );
};

export default OptionsLexusFilters;
