import { observer } from 'mobx-react-lite';
import React from 'react';
import { ChecklistItem } from '../../../../hooks/useChecklist';
import ModelListItem from './ModelListItem';

interface IModelListProps {
  selectedGrade: string;
  grade: ChecklistItem;
  handleModelApplicability: () => void;
  selectItem: (item: ChecklistItem, selected: boolean) => void;
}

const ModelList = ({
  selectedGrade,
  grade,
  handleModelApplicability,
  selectItem,
}: IModelListProps) => {
  return (
    <>
      {selectedGrade === grade.name &&
        grade.items?.map(
          model =>
            !model.hide && (
              <ModelListItem
                key={model.name}
                grade={grade}
                model={model}
                handleModelApplicability={handleModelApplicability}
                selectItem={selectItem}
              />
            )
        )}
    </>
  );
};

export default observer(ModelList);
