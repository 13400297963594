import Checkbox from '../../../../components/Checkbox';
import useChecklist from '../../../../hooks/useChecklist';
import { CommonLanguageCategory } from '../../../../models/commonLanguage.model';
import React, { useEffect } from 'react';
import { FilterTabColumn, FilterTabColumnList } from 'vapi-ui-common';

interface CategoryFilterProps {
  categories: CommonLanguageCategory[];
  categoryFilters: string[];
  setCategories: (filters: string[]) => void;
}

const CategoryFilter = ({ categories, categoryFilters, setCategories }: CategoryFilterProps) => {
  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } = useChecklist();

  useEffect(() => {
    (async () => {
      setChecklist(
        categories.map(cat => ({
          name: cat.value,
          selected: categoryFilters.indexOf(cat.value) !== -1,
        }))
      );
    })();
  }, [setChecklist, categories, categoryFilters]);

  const handleOnChecked = (item: any, checked: boolean) => {
    selectItem(item, checked);
    setCategories(processFilters());
  };

  const handleOnAllChecked = (checked: boolean) => {
    selectAll(checked);
    setCategories(processFilters());
  };

  const processFilters = () => {
    return checklist.filter(item => item.selected).map(item => item.name);
  };

  return (
    <FilterTabColumn>
      <Checkbox
        id="chboxCatAll"
        // className={styles.all}
        checked={isAllSelected}
        onChange={e => handleOnAllChecked(e.currentTarget.checked)}
        bold
      >
        All Categories
      </Checkbox>

      <FilterTabColumnList>
        {checklist &&
          checklist.map(item => (
            <li key={item.name}>
              <Checkbox
                id={`chboxCat${item.name}`}
                checked={item.selected}
                onChange={e => handleOnChecked(item, e.currentTarget.checked)}
              >
                {item.name}
              </Checkbox>
            </li>
          ))}
      </FilterTabColumnList>
    </FilterTabColumn>
  );
};

export default CategoryFilter;
