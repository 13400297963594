import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import Popover from '../../../../../../../components/Popover';
import UnlinkCompareModal from '../../../../../tabModules/compareFeatures/components/Modals/UnlinkCompareModal/UnlinkCompareModal';
import styles from './compareFeatureAction.module.scss';

type Props = {
  disabled?: boolean;
  highlighted: boolean;
  allowUnlinkDeleteModal?: boolean;
  onClick: () => void;
  readOnly?: boolean;
  handleUpdateItem?: (notes: string) => void;
  unlinkCompareFeatureItem?: (shouldDelete: boolean) => void;
  popoverClass?: string;
  align?: 'right' | 'left';
  defaultOpen?: boolean;
  icon?: 'circle' | 'purpleCircle';
  toggleElement?: JSX.Element; // Overrides default toggle Element
  type: string;
  description: string;
};
const CompareFeatureAction: React.FC<Props> = ({
  highlighted,
  onClick,
  unlinkCompareFeatureItem,
  disabled,
  allowUnlinkDeleteModal,
  readOnly,
  popoverClass,
  align = 'right',
  defaultOpen = false,
  toggleElement,
  type,
  description,
}) => {
  if (allowUnlinkDeleteModal) {
    return (
      <Popover
        popoverClass={popoverClass}
        align={align}
        defaultOpen={defaultOpen}
        toggleElement={
          toggleElement ? (
            toggleElement
          ) : (
            <button
              disabled={disabled}
              onClick={onClick}
              className={styles.root}
              title="Compare This Feature"
            >
              <FontAwesomeIcon
                icon={faExchangeAlt}
                className={cx(styles.flagIcon, {
                  [styles.active]: highlighted,
                })}
              />
            </button>
          )
        }
        popoverElement={
          unlinkCompareFeatureItem && (
            <UnlinkCompareModal
              unlinkCompareFeatureItem={unlinkCompareFeatureItem}
              type={type}
              description={description}
            />
          )
        }
      />
    );
  }
  return (
    <>
      <button
        disabled={disabled}
        onClick={onClick}
        className={styles.root}
        title="Compare This Feature"
      >
        <FontAwesomeIcon
          icon={faExchangeAlt}
          className={cx(styles.flagIcon, {
            [styles.active]: highlighted,
          })}
        />
      </button>
    </>
  );
};

export default CompareFeatureAction;
