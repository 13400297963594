import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionBar, ActionBarSection, Button, DropdownList, SearchInput } from 'vapi-ui-common';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';
import useQuery from '../../../hooks/useQuery';
import useStores from '../../../hooks/useStores';
import { CommonLanguageChangeLogItem } from '../../../models/commonLanguage.model';
import { changeLogXForm } from '../../../utils/commonLanguageUtils';
import { handleErrorResponse } from '../../../utils/errorHandlingUtils';
import {
  getChangeLog,
  getCommonLanguageVersions,
  revertChange,
} from '../../../webservices/commonLanguageApi';
import CommonLanguageChangeLogTable from '../components/ChangeLogTable';
import styles from './ChangeLog.module.scss';
import { toGqlBrand, toGqlFilter } from '../../../utils/graphqlUtils';

const CommonLanguageChangeLog = observer(() => {
  const {
    userStore: { brand },
    commonLanguageChangeLogStore,
  } = useStores();

  const returnParam = useQuery().get('return');

  const [isLoaded, setIsLoaded] = useState(false);
  const [isVersionLoaded, setIsVersionLoaded] = useState(false);
  const [logs, setLogs] = useState<string[]>([]);
  const [currentLog, setCurrentLog] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const versionsResponse = await getCommonLanguageVersions({ brand: toGqlBrand(brand) });
        const versions = versionsResponse;
        commonLanguageChangeLogStore.versions = versions;

        setLogs(commonLanguageChangeLogStore.versions);
        setCurrentLog(commonLanguageChangeLogStore.versions[0]);
      } catch (error) {
        toast.error('Error loading versions');
      }

      setIsLoaded(true);
    })();
  }, [brand, commonLanguageChangeLogStore]);

  useEffect(() => {
    // fix so cl users can only see draft
    setIsVersionLoaded(false);
    commonLanguageChangeLogStore.reset();

    (async () => {
      try {
        if (currentLog) {
          const changeLogResponse = await getChangeLog({
            brand: toGqlBrand(brand),
            filter: toGqlFilter(currentLog),
          });
          commonLanguageChangeLogStore.logs = changeLogXForm(currentLog, changeLogResponse);
        }
      } catch (error) {
        toast.error('Error loading change logs');
      }

      setIsVersionLoaded(true);
    })();
  }, [brand, commonLanguageChangeLogStore, currentLog]);

  const handleOnRevert = async (item: CommonLanguageChangeLogItem) => {
    var { id, revId, before = '', after = '', changeType } = item;

    try {
      await trackPromise(
        revertChange({
          brand: toGqlBrand(brand),
          payload: { id, revId, before, after, changeType },
        })
      );

      setIsLoaded(false);
      const changeLogResponse = await getChangeLog({
        brand: toGqlBrand(brand),
        filter: toGqlFilter(currentLog),
      });
      commonLanguageChangeLogStore.logs = changeLogXForm(currentLog, changeLogResponse);
    } catch (e) {
      handleErrorResponse(e, 'Error reverting change log');
    }

    setIsLoaded(true);
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="Common Language" moduleSubTitle="Change Log">
        <NavLink to={`/commonLanguage/${returnParam}`}>
          <Button variant="transparent">Exit Change Log</Button>
        </NavLink>
        <DropdownList
          className={styles.dropdownList}
          value={currentLog}
          list={logs}
          onSelect={(item: string) => setCurrentLog(item)}
        />
      </Header>
      <ActionBar>
        <ActionBarSection>
          <SearchInput
            value={commonLanguageChangeLogStore.searchText}
            onSearch={value => (commonLanguageChangeLogStore.searchText = value)}
          />
        </ActionBarSection>
      </ActionBar>
      {!isVersionLoaded ? (
        <Spinner />
      ) : (
        <CommonLanguageChangeLogTable
          data={commonLanguageChangeLogStore.filteredLogs}
          onSort={commonLanguageChangeLogStore.setSort}
          onRevertChange={handleOnRevert}
        />
      )}
    </>
  );
});

export default CommonLanguageChangeLog;
