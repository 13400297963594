import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { Button } from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox';
import IconTextButton from '../../../../components/IconTextButton';
import Input from '../../../../components/Input';
import useStores from '../../../../hooks/useStores';
import { SeriesManagerVehicleChecklistItem } from '../../../../models/vehicleData.model';
import styles from './editSeriesName.module.scss';

export interface EditSeriesNameProps {
  onChange?: (value: string) => void;
  onUpdateSeries: (series: string, item: SeriesManagerVehicleChecklistItem) => void;
  selectItem: (item: SeriesManagerVehicleChecklistItem, selected: boolean) => void;
  checkboxID: string;
  isChecked?: boolean;
  item: SeriesManagerVehicleChecklistItem;
}

const EditSeriesName = observer(
  ({ checkboxID, isChecked, item, selectItem, onUpdateSeries }: EditSeriesNameProps) => {
    const {
      userStore: { modules },
    } = useStores();

    const [editSeriesName, setEditSeriesName] = useState(false);
    const [series, setSeries] = useState(item.name);
    const canEdit = modules.SeriesSelections.canEdit;

    const handleOnSave = useCallback(() => onUpdateSeries(series.trim(), item), [
      onUpdateSeries,
      series,
      item,
    ]);

    return (
      <>
        {editSeriesName && (
          <Input
            className={styles.editSeriesinput}
            value={series}
            onChange={e => setSeries(e.currentTarget.value)}
          />
        )}

        {!editSeriesName && (
          <Checkbox
            id={checkboxID}
            className={styles.checkbox}
            disabled={!canEdit}
            checked={isChecked}
            onChange={e => selectItem(item, e.currentTarget.checked)}
          >
            {series}
          </Checkbox>
        )}
        {!editSeriesName && (
          <IconTextButton
            icon="edit"
            className={styles.editButton}
            text=""
            onClick={() => setEditSeriesName(!editSeriesName)}
          />
        )}
        {editSeriesName && (
          <div className={styles.saveEdit}>
            <Button variant="primary" onClick={handleOnSave}>
              Save
            </Button>
          </div>
        )}
      </>
    );
  }
);

export default EditSeriesName;
