import React from "react";
import { observer } from "mobx-react-lite";
import styles from "./reportsTable.module.scss";
import { ReportItem } from '../../../../models/reports.model';
import { Table, Thead, TableRow, HeaderCell, } from '../../../../components/Table';
import ReportRow from './ReportsRow';

interface ReportsTableProps {
  reports: ReportItem[];
  onDownload: (item: ReportItem) => void;
}

const ReportsTable = observer(({
  reports,
  onDownload
}: ReportsTableProps) => {

  return (
    <Table fullWidth className={styles.table}>
      <Thead>
        <TableRow>
          <HeaderCell>Download Status</HeaderCell>
          <HeaderCell>Type</HeaderCell>
          <HeaderCell>Series</HeaderCell>
          <HeaderCell>Year</HeaderCell>
          <HeaderCell>Version</HeaderCell>
          <HeaderCell>Date</HeaderCell>
          <HeaderCell>User</HeaderCell>
        </TableRow>
      </Thead>
      <tbody className={styles.body}>
        { reports.map(report => 
          <ReportRow
            key={report.uid}
            report={report}
            onDownload={onDownload}
          />
        )}
      </tbody>
    </Table>
  );
});

export default ReportsTable;
