import React, { Ref, useEffect, useState } from 'react';
import { Button, DropdownContent } from 'vapi-ui-common';
import Checkbox from '../../components/Checkbox';
import useStores from '../../hooks/useStores';
import { DphItem } from '../../models/dph.model';
import { Series } from '../../models/vehicleData.model';
import styles from './dropdownAssign.module.scss';

interface DropdownAssignProps {
  open: boolean;
  name: string;
  seriesMap: string[];
  seriesInfo: Series;
  updateSeries: (series: string[]) => void;
  onClose: () => void;
}

const DropdownAssign = React.forwardRef(
  (
    { open, seriesMap, name, seriesInfo, updateSeries, onClose }: DropdownAssignProps,
    ref: Ref<any>
  ) => {
    const { dphStore } = useStores();

    const [series, setSeries] = useState<Record<string, boolean>>({});
    const [assigned, setAssigned] = useState<Record<string, string>>({});

    useEffect(() => {
      const seriesCategories: Record<string, boolean> = {};

      Object.keys(seriesInfo).forEach(item => {
        seriesCategories[item] = seriesMap.includes(item);
      });

      setSeries(seriesCategories);
    }, [seriesMap, seriesInfo]);

    useEffect(() => {
      const assignedList = dphStore.dphItems.reduce(
        (acc: Record<string, string>, item: DphItem) => {
          item.series.forEach(id => {
            acc = { ...acc, [id]: item.name };
          });

          return acc;
        },
        {}
      );

      setAssigned(assignedList);
    }, [dphStore.dphItems]);

    const onAssign = () => {
      updateSeries(Object.keys(series).filter(item => series[item]));
      onClose();
    };

    const getSeriesName = (item: string) => {
      return seriesInfo[item]?.name ?? 'N/A';
    };

    const getAssigned = () => {
      return seriesMap.map(item => (
        <Checkbox
          id={`chboxVehicle${item}`}
          key={item}
          className={styles.checkBox}
          defaultChecked={true}
          onChange={e => {
            setSeries({ ...series, [item]: e.target.checked });
          }}
          data-testid="assigned-cat"
        >
          {`${getSeriesName(item)} (${name})`}
        </Checkbox>
      ));
    };

    const getUnassigned = () => {
      const unassigned = Object.keys(seriesInfo).filter(
        item => !Object.keys(assigned).includes(item)
      );

      return unassigned.map(item => (
        <Checkbox
          id={`chboxVehicle${item}`}
          key={item}
          className={styles.checkBox}
          onChange={e => {
            setSeries({ ...series, [item]: e.target.checked });
          }}
          data-testid="unassigned-cat"
        >
          {`${getSeriesName(item)} (UNASSIGNED)`}
        </Checkbox>
      ));
    };

    const getReassign = () => {
      const otherAssigtned = Object.keys(seriesInfo).filter(
        item => Object.keys(assigned).includes(item) && !seriesMap.includes(item)
      );

      return otherAssigtned.map(item => (
        <Checkbox
          id={`chboxVehicle${item}`}
          key={item}
          className={styles.checkBox}
          onChange={e => {
            setSeries({ ...series, [item]: e.target.checked });
          }}
          data-testid="other-assigned-cat"
        >
          {`${getSeriesName(item)} (${assigned[item]})`}
        </Checkbox>
      ));
    };

    return (
      <DropdownContent open={open} ref={ref}>
        <div className={styles.dropdownContainer}>
          <div className={styles.checkBoxList}>{getAssigned()}</div>
          <div className={styles.checkBoxList}>{getUnassigned()}</div>
          <div className={styles.checkBoxList}>{getReassign()}</div>
          <div className={styles.buttonContainer}>
            <Button className={styles.cancelButton} variant="transparent" onClick={onClose}>
              Cancel
            </Button>
            <Button className={styles.saveButton} variant="primary" onClick={onAssign}>
              Assign
            </Button>
          </div>
        </div>
      </DropdownContent>
    );
  }
);

export default DropdownAssign;
