import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { removeNulls } from '../utils';
import { ChangeLogBase } from './changeLog.model';
import { FeaturesReviewResponse } from './features.model';
import { ReviewChangeBaseItem, ReviewChangeRequest, ReviewChangeResponse } from './review.model';
import { SpecsReviewResponse } from './specs.model';

/**
 * T = Return type
 */
export type KeyValueType<T = string> = {
  [key: string]: T;
};

export type ModelApplicabilityType<T = string> = {
  id: string;
  setting: T;
};

/**
 * T = Return type
 */
export class IDValueType<T = any> {
  uid = uuidv4();
  id = '';
  @observable value: T;

  constructor(id: string, value: T) {
    this.id = id;
    this.value = value;
  }
}

export class BaseReviewItem {
  @observable uid = uuidv4();
  @observable id = '';
  @observable revId = '';
  @observable category = {} as IDValueType;
  @observable description = '';
  @observable changes = {} as ChangeLogBase;
  @observable otherChanges: ReviewChangeBaseItem[] = [];
  @observable changeTypeId = '';
  @observable isAccepted = true;
  @observable isApplied = true;
  @observable isNewChange = false;
  @observable rejectNotes = '';
  @observable notes = '';
  @observable isInProgress = false;
  @observable isHighlighted = false;
  @observable rowHeight = undefined as number | undefined;

  destructureReviewChangeResponse(change?: ReviewChangeResponse) {
    if (change) {
      const { after, before, changeTypeId, changeType, ...rest } = change;
      Object.assign(this, removeNulls(rest));
      Object.assign(this.changes, removeNulls({ after, before, changeTypeId, changeType }));
    }
  }

  createOtherChanges(feature?: SpecsReviewResponse | FeaturesReviewResponse) {
    if (feature?.otherChanges) {
      this.otherChanges = Object.entries(feature.otherChanges).map(
        ([changeTypeId, otherChange]) => {
          return new ReviewChangeBaseItem(otherChange, changeTypeId);
        }
      );
    }
  }

  getPayload = (): ReviewChangeRequest => {
    return {
      id: this.id,
      revId: this.revId,
      isAccepted: this.isAccepted,
      isApplied: this.isApplied,
      changeTypeId: this.changeTypeId,
      rejectNotes: this.rejectNotes,
    };
  };

  isValid = (): boolean => {
    return this.isAccepted || !!this.rejectNotes;
  };
}
