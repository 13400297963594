import React from 'react';
import { Checkbox, HeaderCell, TableRow, Thead, Table } from 'vapi-ui-common';
import styles from './limitedDataTable.module.scss';

export interface LimitedDataTableProps {
  headers: string[];
  children: JSX.Element | JSX.Element[];
  isChecked?: boolean;
  onClick?: () => void;
}

export const LimitedDataTable = ({
  headers,
  children,
  isChecked,
  onClick,
}: LimitedDataTableProps) => {
  return (
    <div className={styles.tableContainer} data-testid="limited-data-table">
      <Table fullWidth>
        <Thead>
          <TableRow>
            <HeaderCell>
              <div onClick={onClick}>
                <Checkbox checked={isChecked} onChange={() => ({})} />
              </div>
            </HeaderCell>
            {headers.map((item: string, i: number) => (
              <HeaderCell key={i} cellClassName={styles.headerCellText}>
                {item}
              </HeaderCell>
            ))}
          </TableRow>
        </Thead>
        <tbody>{children}</tbody>
      </Table>
    </div>
  );
};
