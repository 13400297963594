import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { areRichTextValuesDifferent, DateComponent, MultiLineRTEditor } from 'vapi-ui-common';
import ActionButtonsTableCell from '../../../../../../components/ActionButtonsTableCell';
import Input from '../../../../../../components/Input';
import NotesPopover from '../../../../../../components/NotesPopover';
import { TableCell, TableRow } from '../../../../../../components/Table';
import {
  DisclaimersConfig,
  DisclaimersItem,
  DisclaimersVehicle,
} from '../../../../../../models/disclaimers.model';
import { REGION_GST } from '../../../../../../models/user.model';
import styles from './disclaimerRow.module.scss';

interface DisclaimerRowProps {
  item: DisclaimersItem;
  config: DisclaimersConfig;
  onDeleteItem?: (disclaimer: DisclaimersItem) => void;
  onUpdateItem?: (disclaimer: DisclaimersItem) => void;
  onAddItem?: (disclaimer: DisclaimersItem) => Promise<boolean>;
  onArchiveItem?: (disclaimer: DisclaimersItem) => void;
  vehicleModels: DisclaimersVehicle[];
  region?: string;
}

const DisclaimerRow = observer(
  ({
    item,
    config,
    onDeleteItem = () => null,
    onUpdateItem = () => null,
    onAddItem = () => new Promise(() => false),
    onArchiveItem = () => null,
    region,
  }: DisclaimerRowProps) => {
    const [isNew, setNew] = useState(!item.id);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openArchiveModal, setOpenArchiveModal] = useState(false);

    const gstTeam = region === REGION_GST;

    // trigger validation and save
    const handleOnIdChange = (token: string) => {
      if (token !== item.token) {
        item.token = token;
        saveItem(item);
      }
    };

    const handleOnDescriptionChange = (description: string) => {
      if (areRichTextValuesDifferent(item.description, description)) {
        item.description = description;
        saveItem(item);
      }
    };

    const handleOnESDescriptionChange = (descriptionEs: string) => {
      if (areRichTextValuesDifferent(item.description_es, descriptionEs)) {
        item.description_es = descriptionEs;
        saveItem(item);
      }
    };

    const handleOnNotesChange = (notes: string) => {
      if (notes !== item.notes) {
        item.notes = notes;
        saveItem(item);
      }
    };

    const saveItem = async (item: DisclaimersItem) => {
      if (!item.id) {
        const isValid = await onAddItem(item);
        if (isValid) {
          setNew(false);
        }
      } else {
        onUpdateItem(item);
      }
    };

    const fullEditPermissions = !config.readOnly && region !== REGION_GST;

    return (
      <>
        <TableRow zebra className={cx({ [styles.newItem]: isNew })}>
          <TableCell
            large
            className={cx({
              [styles.id]: true,
            })}
          >
            {fullEditPermissions && (
              <Input
                name="id"
                className={cx(styles.inputId, { [styles.errItem]: !item.token })}
                defaultValue={item.token}
                onBlur={event => handleOnIdChange(event.currentTarget.value.trim())}
              />
            )}
            {/* && <div>{renderId(item.id)}</div> */}
            {!fullEditPermissions && item.token}
          </TableCell>
          {/* Description */}
          <TableCell large className={cx(styles.description, !item.description && styles.error)}>
            <MultiLineRTEditor
              value={item.description}
              disableMentions
              disabled={config.readOnly}
              onBlur={value => handleOnDescriptionChange(value.trim())}
            />
          </TableCell>
          {/* Spanish Description */}
          {gstTeam && (
            <TableCell large className={styles.description}>
              <MultiLineRTEditor
                value={item.description_es}
                disableMentions
                disabled={config.readOnly}
                onBlur={value => handleOnESDescriptionChange(value.trim())}
              />
            </TableCell>
          )}
          {/* Notes  */}
          <TableCell large>
            <NotesPopover
              notes={item.notes}
              handleUpdateItem={handleOnNotesChange}
              readOnly={config.readOnly || false}
            />
          </TableCell>
          <TableCell large className={styles.published}>
            <DateComponent format="MM/DD/YY">{item.lastPublishedDate}</DateComponent>
          </TableCell>
          {/* Action buttons */}
          <ActionButtonsTableCell
            readOnly={!fullEditPermissions}
            item={item}
            setOpenDeleteModal={setOpenDeleteModal}
            openDeleteModal={openDeleteModal}
            setOpenArchiveModal={setOpenArchiveModal}
            openArchiveModal={openArchiveModal}
            onDeleteItem={() => onDeleteItem(item)}
            onArchiveItem={() => onArchiveItem(item)}
            itemType="Disclaimer"
          />
          {config.isArchived && (
            <TableCell large className={cx(styles.tableText, styles.archived)}>
              V{item.lastPublishedVersion}
            </TableCell>
          )}
        </TableRow>
      </>
    );
  }
);

export default DisclaimerRow;
