import { observer } from 'mobx-react-lite';
import React from 'react';
import { TableCell, TableRow } from '../../../../../../../components/Table';
import { BRAND_TDPR } from '../../../../../../../models/user.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../models/vehicleModel.model';
import GoLiveDateText from '../../../../../tabModules/models/components/GoLiveDateText';
import styles from './confirmPublishModalTable.module.scss';

interface ConfirmPublishModalTableRowProps {
  brand: string;
  vehicleModel: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  isCodeRed?: boolean | undefined;
  isLive?: boolean;
  isFutureLive: boolean;
  noGoLiveDate: boolean;
  goLiveDate: string;
  timePicker?: boolean;
}

const ConfirmPublishModalTableRow = observer(
  ({
    brand,
    vehicleModel,
    isFutureLive,
    isLive,
    isCodeRed,
    noGoLiveDate,
    goLiveDate,
    timePicker,
  }: ConfirmPublishModalTableRowProps) => {
    return (
      <TableRow zebra>
        <TableCell center className={styles.tableText}>
          {vehicleModel.getVal(brand === BRAND_TDPR ? 'tdprCode' : 'code')}
        </TableCell>
        <TableCell className={styles.tableText}>{vehicleModel.getVal('description')}</TableCell>
        {/* Models with no live date set */}
        {noGoLiveDate && (
          <TableCell className={styles.tableText}>
            <span className={styles.goLive}>No Live Date {timePicker && '& Time'} Set</span>
          </TableCell>
        )}
        {/* Models with go live date in future */}
        {isFutureLive && (
          <TableCell className={styles.tableText}>
            <span className={styles.goLive}>
              <span>Go Live:</span>
              <GoLiveDateText timePicker={timePicker} goLiveDate={goLiveDate} />
            </span>
          </TableCell>
        )}
        {/* Models that are live */}
        {isLive && (
          <TableCell className={styles.tableText}>
            <span className={styles.isLive}>LIVE</span>
          </TableCell>
        )}
        {/* Models with code red */}
        {isCodeRed && (
          <TableCell className={styles.tableText}>
            <span className={styles.codeRed}>Code Red</span>
          </TableCell>
        )}
      </TableRow>
    );
  }
);

export default ConfirmPublishModalTableRow;
