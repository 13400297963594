import { observer } from 'mobx-react-lite';
import React from 'react';
import { HeaderRow, LeftTableTH, Thead } from '../../../../components/Table';
import styles from './colorFamilyHeaderRow.module.scss';

type ColorFamiliesHeaderRowProps = {
  // readOnly?: boolean;
  onSort?: (field: string, isReverse: boolean) => void;
};

const ColorFamilyHeaderRow: React.FC<ColorFamiliesHeaderRowProps> = ({ onSort = () => {} }) => {
  let isReverse = false;

  return (
    <Thead>
      <HeaderRow>
        <LeftTableTH
          className={styles.columnHeaderWidth}
          onClick={() => {
            onSort('name', isReverse);
            isReverse = !isReverse;
          }}
        >
          Color Family
        </LeftTableTH>
        <LeftTableTH
          className={styles.hexCode}
          onClick={() => {
            onSort('hexCode', isReverse);
            isReverse = !isReverse;
          }}
        >
          Hex Code
        </LeftTableTH>
      </HeaderRow>
    </Thead>
  );
};

export default observer(ColorFamilyHeaderRow);
