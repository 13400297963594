import { action, observable } from 'mobx';
import { convertToRichTextObject } from 'vapi-ui-common';
import { OptionsPackage } from '../../../models/optionsLexus.model';
import { isSortReverse, sortBy, toLowerCase } from '../../../utils';

class OptionsLexusReviewStore {
  reviewItems: OptionsPackage[] = [];
  tab = 'packages';
  reverseSort = false;
  sortField = 'id';
  searchText = '';
  isInProgressFilter = false;
  isNewChangeFilter = false;
  @observable filteredReviewItems: OptionsPackage[] = [];
  @observable viewModelCodes = false;
  @observable showLongDescription = true;
  @observable showShortDescription = false;
  @observable reverse = true;

  @action setItems = (items: OptionsPackage[]) => {
    this.reviewItems = items;
    this.filteredReviewItems = items;
  };

  setSort = (field: string) => {
    this.reverseSort = isSortReverse(this.sortField, field, this.reverseSort);
    this.sortField = field;
    this.filteredReviewItems = this.filterOptions();
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredReviewItems = this.filterOptions();
  };

  @action clearFilters = () => {
    this.searchText = '';
    this.isInProgressFilter = false;
  };

  filterOptions = () => {
    const reviewItems = this.reviewItems
      .slice()
      .sort(sortBy(this.sortField, this.reverse, this.sortField === 'modifiedDate'));
    const lowerSearchText = toLowerCase(this.searchText);

    return (this.filteredReviewItems = reviewItems.filter(
      item =>
        (!lowerSearchText ||
          toLowerCase(convertToRichTextObject(item.name).text).includes(lowerSearchText) ||
          toLowerCase(convertToRichTextObject(item.description).text).includes(lowerSearchText)) &&
        (!this.isInProgressFilter || item.isInProgress) &&
        (!this.isNewChangeFilter || item.changes.some(change => change.isNewChange))
    ));
  };

  @action reset() {
    this.reviewItems = [];
    this.tab = 'packages';
    this.reverseSort = false;
    this.sortField = 'id';
    this.searchText = '';
    this.isInProgressFilter = false;
    this.filteredReviewItems = [];
    this.viewModelCodes = false;
    this.showLongDescription = true;
    this.showShortDescription = false;
    this.reverse = true;
  }
}

export default OptionsLexusReviewStore;
