import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, DropdownContent } from 'vapi-ui-common';
import Checkbox from '../../../../../../../../../components/Checkbox';
import useComponentVisible from '../../../../../../../../../hooks/useComponentVisible';
import styles from '../../../../../seriesSettings.module.scss';

interface FuelTypeSelectionComponentProps {
  checklist: any[];
  selectItem: any;
  isAdding?: boolean;
}

const FuelTypeSelectionComponent = ({
  checklist,
  selectItem,
  isAdding,
}: FuelTypeSelectionComponentProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [fuelLabel, setFuelLabel] = useState('');
  const isEmpty = !fuelLabel;

  // Responsive label name based off fuel types selected
  useEffect(
    () =>
      setFuelLabel(
        checklist
          .filter(item => item.selected === true)
          .map(item => item.name)
          .join(', ')
      ),
    [selectItem, checklist]
  );

  return (
    <Dropdown className={styles.dropdown}>
      <DropdownButton
        className={cx(styles.dropdownButton, {
          [styles.errorText]: isAdding && isEmpty,
        })}
        onClick={() => setIsComponentVisible(true)}
        error={isAdding && isEmpty}
      >
        {fuelLabel}
      </DropdownButton>
      <DropdownContent open={isComponentVisible} ref={ref}>
        {checklist.map(item => (
          <li key={item.name}>
            <Checkbox
              id={`chboxfuel${item.name}`}
              checked={item.selected}
              onChange={e => selectItem(item, e.currentTarget.checked)}
            >
              {item.name}
            </Checkbox>
          </li>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

export default FuelTypeSelectionComponent;
