import React, { useCallback, useContext } from 'react';
import { Button } from 'vapi-ui-common';
import { KeyFeatureContext } from '.';
import styles from './KeyFeature.module.scss';

interface Props {
  disabled: boolean;
  closePopover: () => void;
}

const KeyFeatureDialogFooter = ({ disabled, closePopover = () => undefined }: Props) => {
  const { keyFeatureList, onSave } = useContext(KeyFeatureContext);

  const onConfirm = useCallback(() => {
    onSave(keyFeatureList);
    closePopover();
  }, [onSave, closePopover, keyFeatureList]);

  return (
    <footer className={styles.footer} data-testid="key-feature-dialog-footer">
      <Button
        variant="transparent"
        onClick={closePopover}
        data-testid="key-feature-dialog-footer-cancel-cta"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={onConfirm}
        disabled={disabled}
        data-testid="key-feature-dialog-footer-save-cta"
      >
        Save
      </Button>
    </footer>
  );
};

export default KeyFeatureDialogFooter;
