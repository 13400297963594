import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Accordion, AccordionItem, Button, Modal, PublishModal } from 'vapi-ui-common';
import Header from '../../components/Header/Header';
import IconTextButton from '../../components/IconTextButton';
import Spinner from '../../components/Spinner';
import useDebounce from '../../hooks/useDebounce';
import useStores from '../../hooks/useStores';
import { SeriesCategoryItem } from '../../models/seriesCategories.model';
import { handleErrorResponse } from '../../utils/errorHandlingUtils';
import {
  addSeriesCategory,
  deleteSeriesCategory,
  publishSeriesCategory,
  updateSeriesCategory,
} from '../../webservices/adminApi';
import SeriesCategoryHeader from './components/SeriesCategoryHeader/SeriesCategroyHeader';
import SeriesDetails from './components/SeriesDetails';
import styles from './seriesCategories.module.scss';
import { toGqlBrand } from '../../utils/graphqlUtils';

const SeriesCategories = observer(() => {
  const {
    userStore: {
      brand,
      modules: {
        AgencyTeam: { canEdit },
      },
    },
    seriesCategoriesStore,
  } = useStores();

  const { debounce } = useDebounce({ delay: 2000 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    seriesCategoriesStore.reset();

    (async () => {
      try {
        await seriesCategoriesStore.fetchData(brand);
      } catch (e) {
        toast.error('Error loading series categories data.');
        console.log(e.message);
      }

      setIsLoaded(true);
    })();
  }, [brand, seriesCategoriesStore]);

  const addEmptySeriesCategoryItem = () => {
    seriesCategoriesStore.addItem();
  };

  const addSeriesCategoryItem = async (seriesCategory: SeriesCategoryItem) => {
    try {
      debounce(async () => {
        const response = await trackPromise(
          addSeriesCategory({
            brand: toGqlBrand(brand),
            payload: {
              name: seriesCategory.name,
              nameES: seriesCategory.nameES,
              seriesId: seriesCategory.seriesId,
            },
          })
        );
        seriesCategory.id = response.id;
        seriesCategory.revId = response.revId;
        toast.success('Series category added successfully');
      }, seriesCategory.uid);
    } catch (e) {
      handleErrorResponse(e, 'Series category failed add');
    }
  };

  const updateSeriesCategoryItem = async (seriesCategory: SeriesCategoryItem) => {
    try {
      debounce(async () => {
        const response = await trackPromise(
          updateSeriesCategory({
            brand: toGqlBrand(brand),
            payload: {
              id: seriesCategory.id,
              revId: seriesCategory.revId,
              name: seriesCategory.name,
              nameES: seriesCategory.nameES,
              seriesId: seriesCategory.seriesId,
            },
          })
        );
        seriesCategory.revId = response.revId;
        toast.success('Series category updated successfully');
      }, seriesCategory.uid);
    } catch (e) {
      handleErrorResponse(e, 'Series category failed add');
    }
  };

  const onSaveSeriesCategory = (
    seriesCategory: SeriesCategoryItem,
    name: string,
    nameES: string,
    seriesId: string
  ) => {
    seriesCategory.name = name;
    seriesCategory.nameES = nameES;
    seriesCategory.seriesId = seriesId;
    saveSeriesCategoryItem(seriesCategory);
  };

  const onDeleteCategory = async (seriesCategory: SeriesCategoryItem, del: boolean) => {
    try {
      await trackPromise(
        deleteSeriesCategory({ brand: toGqlBrand(brand), seriesCategoryId: seriesCategory.id })
      );

      seriesCategoriesStore.deleteItem(seriesCategory);
      toast.success('Series category deleted sucessfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting Series category');
    }
  };

  const saveSeriesCategoryItem = (seriesCategory: SeriesCategoryItem) => {
    if (seriesCategory.isValid()) {
      seriesCategory.id
        ? updateSeriesCategoryItem(seriesCategory)
        : addSeriesCategoryItem(seriesCategory);
    } else {
      toast.error('Please fill out all fields');
    }
  };

  const publishDraft = async () => {
    setIsLoaded(false);
    try {
      let isValid = true;
      seriesCategoriesStore.seriesCategories.forEach(category => {
        if (!category.isValid()) {
          isValid = false;
        }
      });
      if (isValid) {
        await trackPromise(publishSeriesCategory({ brand: toGqlBrand(brand) }));
        toast.success('Successfully published draft');
      } else {
        toast.error('Please ensure all series categories have a spanish name and id');
      }
    } catch (e) {
      handleErrorResponse(e, 'Failed to publish draft');
    }
    setIsLoaded(true);
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="Agency" moduleSubTitle="Series Categories" />
      <section className={styles.body}>
        <div className={styles.CTAHeader}>
          {canEdit && (
            <>
              <div>
                <IconTextButton
                  icon="plus"
                  text="Add Category"
                  onClick={() => addEmptySeriesCategoryItem()}
                />
              </div>
              <div className={styles.publishButton}>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowPublishModal(true);
                  }}
                >
                  Publish
                </Button>
                <Modal open={showPublishModal} onClose={() => setShowPublishModal(false)}>
                  <PublishModal
                    close={() => setShowPublishModal(false)}
                    publishData={() => {
                      publishDraft();
                    }}
                  />
                </Modal>
              </div>
            </>
          )}
        </div>
        <SeriesCategoryHeader />
        <Accordion>
          {seriesCategoriesStore.seriesCategories.map((category, index) => (
            <AccordionItem
              key={index}
              seriesCategory={category.name ? category.name : ''}
              seriesCategoryES={category.nameES ? category.nameES : ''}
              seriesCategorySeriesId={category.seriesId ? category.seriesId : ''}
              className={styles.accordianItem}
              hasSeriesDetails={category.series.length > 0}
              onEdit={
                canEdit
                  ? (name: string, nameES: string, seriesId: string) =>
                      onSaveSeriesCategory(category, name, nameES, seriesId)
                  : undefined
              }
              onDelete={
                canEdit && !category.series.length
                  ? value => onDeleteCategory(category, value)
                  : undefined
              }
              modalHeaderText={`Delete Series Category`}
              modalBodyText={`Are you sure you want to delete series category '${category.name}'?`}
              confirmButtonText={`Delete`}
              section={<SeriesDetails list={category.series} />}
            />
          ))}
        </Accordion>
      </section>
    </>
  );
});

export default SeriesCategories;
