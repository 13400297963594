import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import { ActionItemModal, Modal } from 'vapi-ui-common';
import styles from './deleteCell.module.scss';

type Props = {
  itemType?: 'Feature' | 'Split' | 'Spec' | 'Option' | 'Color';
  className?: string;
  deleteItem: () => void;
  description: string;
};
const DeleteCell: React.FC<Props> = ({
                                       className,
                                       deleteItem,
                                       description,
                                       itemType = 'Feature',
                                     }) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  return (
    <td className={cx(styles.root, className)}>
      <button onClick={() => setShowDeleteModal(true)} className={styles.button}>
        <FontAwesomeIcon icon={faTrashAlt} className={styles.trashIcon} />
      </button>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <ActionItemModal
          itemType={itemType}
          onConfirm={deleteItem}
          description={description}
          onClose={() => setShowDeleteModal(false)}
        />
      </Modal>
    </td>
  );
};

export default DeleteCell;
