import { computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { ChangeLogBase, ChangeLogTypes } from './changeLog.model';
import { CreateCommonLanguageSpecInput, UpdateCommonLanguageSpecInput } from '../gql/generated';

export class CommonLanguageItem {
  uid = '';
  id = '';
  revId = '';
  categoryId = '';
  @observable category = '';
  @observable subCategory = '';
  @observable description = '';
  @observable isInProgress = false;
  @observable notes = '';
  @observable lastPublishedVersion: undefined | number;
  @observable lastPublishedDate = '';
  @observable vehicles: CommonLanguageVehicle[] = [];

  constructor(item?: CommonLanguageResponse) {
    this.uid = uuidv4();
    if (item) {
      Object.assign(this, item);
    }
  }

  setCategory = (categoryId: string, category: string) => {
    this.categoryId = categoryId;
    this.category = category;
  };

  @computed get isValid() {
    return !!(this.category && this.description && this.subCategory);
  }

  toCreatePayload = (): CreateCommonLanguageSpecInput => {
    return {
      categoryId: this.categoryId,
      description: this.description,
      isInProgress: this.isInProgress,
      notes: this.notes,
      subCategory: this.subCategory,
      vehicles: this.vehicles.map(vehicle => ({
        modelYear: vehicle.modelYear,
        seriesId: vehicle.seriesId,
        seriesName: vehicle.seriesName,
      })),
    };
  };

  toUpdatePayload = (): UpdateCommonLanguageSpecInput => {
    return {
      id: this.id,
      revId: this.revId,
      categoryId: this.categoryId,
      description: this.description,
      isInProgress: this.isInProgress,
      notes: this.notes,
      subCategory: this.subCategory,
      vehicles: this.vehicles.map(vehicle => ({
        modelYear: vehicle.modelYear,
        seriesId: vehicle.seriesId,
        seriesName: vehicle.seriesName,
      })),
    };
  };
}

export interface CommonLanguageResponse {
  id: string;
  revId: string;
  categoryId?: string;
  category: string;
  subCategory: string;
  description: string;
  lastPublishedVersion?: number;
  lastPublishedDate?: string;
  publishedDate?: string;
  vehicles?: CommonLanguageVehicle[];
  notes: string;
}

export interface CommonLanguageRequest {
  id: string;
  revId: string;
  description: string;
  isInProgress: boolean;
  subCategory: string;
  notes: string;
  category: string;
  categoryId: string;
  vehicles: CommonLanguageVehicle[];
}

export interface CommonLanguageCategory {
  id: string;
  value: string;
}

export interface CommonLanguageChangeLogResponse {
  currentChangeCount?: number;
  lastResetDate?: string;
  changes: CommonLanguageChangeLogResponseItem[];
}

export interface CommonLanguageChangeLogResponseItem {
  id: string;
  revId: string;
  isInProgress: boolean;
  description: string;
  notes: string;
  changes: CommonLanguageChangeLogResponseChange[];
}

export interface CommonLanguageChangeLogResponseChange {
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
  changeType: string;
  modelsAdded?: string[];
  modelsRemoved?: string[];
  modifiedDate: string;
  modifiedBy: string;
}

export interface CommonLanguageChangeLogItem extends ChangeLogBase {
  uid: string;
  id: string;
  revId: string;
  description: string;
  isInProgress: boolean;
  notes: string;
  changeType: ChangeLogTypes;
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
  changes: CommonLanguageChangeLogChange[];
  modelsAdded?: string[];
  modelsRemoved?: string[];
  modifiedDate: string;
  modifiedBy: string;
  canRevert: boolean;
}

export interface CommonLanguageChangeLogChange {
  added?: boolean;
  removed?: boolean;
  value?: string;
}

export interface CommonLanguageVehicle {
  modelYear: string;
  seriesId: string;
  seriesName: string;
  group?: string;
}

export interface CommonLanguageVehicleChecklist {
  name: string;
  selected: boolean;
  items: Array<{ id: string; name: string; selected: boolean }>;
}
