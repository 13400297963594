import { action, observable } from 'mobx';
import { DphItem } from '../models/dph.model';
import { Series } from '../models/vehicleData.model';
import { dphXForm } from '../utils/dphUtils';
import { getDph } from '../webservices/dphApi';
import { toGqlBrand, toGqlRegion } from '../utils/graphqlUtils';

class DphStore {
  @observable dphItems: DphItem[] = [];
  @observable series = {} as Series;

  @action fetchData = async (brand: string, region: string) => {
    const response = await getDph({
      brand: toGqlBrand(brand),
      region: toGqlRegion(region),
    });
    this.setDphItems(dphXForm(response));
  };

  setDphItems = (items: DphItem[]) => {
    this.dphItems = items;
  };

  addItem = () => {
    const newItem = new DphItem();
    this.dphItems = [newItem, ...this.dphItems];
  };

  @action reset() {
    this.dphItems = [];
  }
}

export default DphStore;
