import { action, computed, observable } from 'mobx';
import { convertToRichTextObject } from 'vapi-ui-common';
import { ModelsReviewMap } from '../../../models/vehicleModel.model';
import { sortBy, toLowerCase } from '../../../utils';
import BaseReviewStore from './baseReviewStore';

class ModelsReviewStore extends BaseReviewStore {
  @observable filterOutCodeRed = false;
  @observable map: ModelsReviewMap = {};

  @computed get filteredReviewItems() {
    const items = Object.values(this.map)
      .slice()
      .sort(sortBy(this.sortField, this.reverse, this.sortField === 'modifiedDate'));
    const lowerSearchText = toLowerCase(this.searchText);
    return items.filter(model => {
      if (lowerSearchText) {
        const valuesToCheck: string[] = [
          convertToRichTextObject(model.description.before).text,
          convertToRichTextObject(model.description.after).text,
          model.rejectNotes,
        ];
        for (let val of valuesToCheck) {
          if (toLowerCase(val).includes(lowerSearchText)) {
            return true;
          }
        }
      }

      return !lowerSearchText;
    });
  }

  @action reset() {
    super.reset();
    this.filterOutCodeRed = false;
    this.map = {};
  }
}
const modelsReviewStore = new ModelsReviewStore();
export default modelsReviewStore;
