import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionBar, ActionBarSection, Button, DropdownList, SearchInput } from 'vapi-ui-common';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';
import useQuery from '../../../hooks/useQuery';
import useStores from '../../../hooks/useStores';
import { DisclaimersChangeLogItem } from '../../../models/disclaimers.model';
import { changeLogXForm } from '../../../utils/disclaimersUtils';
import { handleErrorResponse } from '../../../utils/errorHandlingUtils';
import {
  getChangeLog,
  getDisclaimerVersions,
  revertChange,
} from '../../../webservices/disclaimersApi';
import DisclaimersChangeLogTable from '../components/ChangeLogTable';
import styles from './ChangeLog.module.scss';

const DisclaimersChangeLog = observer(() => {
  const {
    userStore: { brand, region },
    disclaimersChangeLogStore,
  } = useStores();

  const returnParam = useQuery().get('return');

  const [isLoaded, setIsLoaded] = useState(false);
  const [isVersionLoaded, setIsVersionLoaded] = useState(false);
  const [logs, setLogs] = useState<string[]>([]);
  const [currentLog, setCurrentLog] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const versions = await getDisclaimerVersions(brand, region);
        disclaimersChangeLogStore.versions = versions;

        setLogs(disclaimersChangeLogStore.versions);
        setCurrentLog(disclaimersChangeLogStore.versions[0]);
      } catch (error) {
        toast.error('Error loading versions');
      }

      setIsLoaded(true);
    })();
  }, [brand, disclaimersChangeLogStore, region]);

  useEffect(() => {
    // fix so disclaimers users can only see draft
    setIsVersionLoaded(false);
    disclaimersChangeLogStore.reset();

    (async () => {
      try {
        if (currentLog) {
          const changeLogResponse = await getChangeLog(brand, region, currentLog);
          disclaimersChangeLogStore.logs = changeLogXForm(currentLog, changeLogResponse);
        }
      } catch (error) {
        toast.error('Error loading change logs');
      }

      setIsVersionLoaded(true);
    })();
  }, [brand, disclaimersChangeLogStore, currentLog, region]);

  const handleOnRevert = async (item: DisclaimersChangeLogItem) => {
    const { id, revId, before = '', after = '', changeType } = item;
    try {
      await trackPromise(revertChange(brand, region, { id, revId, before, after, changeType }));

      setIsLoaded(false);
      const changeLogResponse = await getChangeLog(brand, region, currentLog);
      disclaimersChangeLogStore.logs = changeLogXForm(currentLog, changeLogResponse);
    } catch (e) {
      handleErrorResponse(e, 'Error reverting change log');
    }

    setIsLoaded(true);
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="Disclaimers" moduleSubTitle="Change Log">
        <NavLink to={`/disclaimers/${returnParam}`}>
          <Button variant="transparent">Exit Change Log</Button>
        </NavLink>
        <DropdownList
          className={styles.dropdownList}
          value={currentLog}
          list={logs}
          onSelect={(item: string) => setCurrentLog(item)}
        />
      </Header>
      <ActionBar>
        <ActionBarSection>
          <SearchInput
            value={disclaimersChangeLogStore.searchText}
            onSearch={value => (disclaimersChangeLogStore.searchText = value)}
          />
        </ActionBarSection>
      </ActionBar>
      {!isVersionLoaded ? (
        <Spinner />
      ) : (
        <DisclaimersChangeLogTable
          data={disclaimersChangeLogStore.filteredLogs}
          onSort={disclaimersChangeLogStore.setSort}
          onRevertChange={handleOnRevert}
          region={region}
        />
      )}
    </>
  );
});

export default DisclaimersChangeLog;
