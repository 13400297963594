import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FilterTab, FilterTabColumn, FilterTabColumnList, FilterTabColumns, Input, ModalBody, ModalFooter, ModalHeader, useChecklist } from 'vapi-ui-common';
import { InteriorGroupItem, InteriorGroupModels } from '../../../../../../../models/colorsLexus.model';
import { VehicleModelItem, VehicleModelLexus, VehicleModelToyota } from '../../../../../../../models/vehicleModel.model';
import styles from './colorsLexusInteriorGroupModal.module.scss';

interface ColorsLexusInteriorGroupModalProps {
  closeModal(): void;
  saveInteriorGroup?(groupItem: InteriorGroupItem, addGroup: boolean): void;
  deleteInteriorGroup?(groupItem: InteriorGroupItem): void;
  groupItem: InteriorGroupItem;
  vehicleModels?: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[];
  readOnly?: boolean;
}

const ColorsLexusInteriorGroupModal = (props: ColorsLexusInteriorGroupModalProps) => {
  const {
    closeModal,
    saveInteriorGroup = () => void 0,
    deleteInteriorGroup = () => void 0,
    groupItem,
    vehicleModels,
    readOnly,
  } = props;
  const addMode = !groupItem.id;
  const [groupName, setGroupName] = useState(groupItem.name);
  const {
    checklist,
    setChecklist,
    isAllSelected,
    selectAll,
    selectItem,
  } = useChecklist();

  const addUpdateInteriorGroup = () => {
    groupItem.name = groupName;
    if(groupItem.isValid()){
      groupItem.models = {} as InteriorGroupModels;
      checklist.forEach(item => {
        if(item.selected){
          groupItem.models[item.id] = true;
        }
      });
      saveInteriorGroup(groupItem, addMode);
      closeModal();
    }
  };

  const deleteGroup = () => {
    deleteInteriorGroup(groupItem);
    closeModal();
  }

  useEffect(() => {
    if(vehicleModels){
      setChecklist(
        vehicleModels.map(model => ({
          id: model.id,
          name: model.getVal('code'),
          selected: (Object.keys(groupItem.models).filter(key => key === model.id).length > 0)
        }))
      );
    }
  }, [vehicleModels, setChecklist, addMode, groupItem.models]);

  return (
    <>
      <ModalHeader onClose={closeModal}>{addMode ? 'Add' : 'Edit'} Group</ModalHeader>
      <ModalBody>
        <FilterTab>
          <FilterTabColumns>
            <FilterTabColumn>
              <div className={styles.groupName}>
                <Input 
                  readOnly={readOnly}
                  defaultValue={groupName}
                  placeholder="Group name"
                  onChange={e => setGroupName(e.currentTarget.value.trim())}
                />
              </div>
              <Checkbox
                id="AllCategories"
                checked={isAllSelected}
                onChange={e => selectAll(e.currentTarget.checked)}
              >
                All
              </Checkbox>
              <FilterTabColumnList>
                {checklist.map(item => (
                  <li key={item.name}>
                    <Checkbox
                      id={`chboxCat${item.id}`}
                      checked={item.selected}
                      onChange={e => selectItem(item, e.currentTarget.checked)}
                    >
                      {item.name}
                    </Checkbox>
                  </li>
                ))}
              </FilterTabColumnList>
            </FilterTabColumn>
          </FilterTabColumns>
        </FilterTab>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={closeModal}>
          Cancel
        </Button>
        {!addMode && (
          <Button variant="primary" onClick={deleteGroup}>
            Delete
          </Button>
        )}
        <Button variant={addMode ? 'primary' : 'secondary'} onClick={addUpdateInteriorGroup}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
}

export default ColorsLexusInteriorGroupModal;
