import { observer } from 'mobx-react-lite';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { ActionBar, Status } from 'vapi-ui-common';
import {
  HeaderCell,
  HeaderRow,
  TableRow,
  Thead,
  TwoTableWrapper,
} from '../../../../../components/Table';
import { TableTabs } from '../../../../../components/Table/components/TableTabs';
import { uiBlock } from '../../../../../components/UiBlocker/uiBlock';
import useStores from '../../../../../hooks/useStores';
import { COLORS_TAB } from '../../../../../models/colors.model';
import { ColorLexusInteriorReviewItem } from '../../../../../models/colorsLexus.model';
import { ReviewChangeBaseItem } from '../../../../../models/review.model';
import ActionBarVehicleData from '../../../../../routes/vehicleData/components/ActionBarVehicleData';
import LeftTable from '../../../../../routes/vehicleData/components/LeftTable';
import {
  AcceptApplyCell,
  AcceptApplyHeaderCell,
} from '../../../../../routes/vehicleData/components/tableCells/AcceptApplyCell';
import VDTableCell from '../../../../../routes/vehicleData/components/tableCells/VDTableCell';
import { ProductDataControllerProps } from '../../../../../routes/vehicleData/models/controllers.model';
import { handleErrorResponse } from '../../../../../utils/errorHandlingUtils';
import { updateRevIdById } from '../../../../../utils/reviewUtils';
import { updateReviewInteriorColors } from '../../../../../webservices/vehicleColorsApi';
import ColorsFilters from '../components/ColorsFilters';
import ColorsLexusReviewChanges from './components/ColorsLexusReviewChanges';
import { toGqlBrand, toGqlFilter, toGqlTeam } from '../../../../../utils/graphqlUtils';

interface ColorsLexusExteriorReviewProps extends ProductDataControllerProps {
  colorTab: COLORS_TAB;
  setColorTab(tab: COLORS_TAB): void;
}

const ColorsLexusInteriorReview = ({
  seriesId,
  year,
  version = '',
  colorTab,
  setColorTab,
}: ColorsLexusExteriorReviewProps) => {
  const {
    reviewStore,
    colorsLexusReviewStore: { interiorStore },
    teamStore,
    userStore: { brand },
  } = useStores();

  const handleOnAcceptChange = async (
    interiorItem: ColorLexusInteriorReviewItem,
    change: ReviewChangeBaseItem,
    value: boolean
  ) => {
    change.isAccepted = value;
    saveItem(interiorItem, change);
  };

  const handleOnApplyChange = async (
    interiorItem: ColorLexusInteriorReviewItem,
    change: ReviewChangeBaseItem,
    value: boolean
  ) => {
    change.isApplied = value;
    saveItem(interiorItem, change);
  };

  const handleOnNotesChange = async (
    interiorItem: ColorLexusInteriorReviewItem,
    change: ReviewChangeBaseItem,
    value: string
  ) => {
    change.rejectNotes = value;
    saveItem(interiorItem, change);
  };

  const saveItem = async (
    interiorItem: ColorLexusInteriorReviewItem,
    change: ReviewChangeBaseItem
  ) => {
    try {
      if (change.isValid()) {
        uiBlock.start();
        const payload = {
          ...change.getPayload(),
          id: interiorItem.id,
          revId: interiorItem.revId,
        };

        const response = await trackPromise(
          updateReviewInteriorColors({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId,
            modelYear: Number(year),
            filter: toGqlFilter(version ?? Status.DRAFT),
            payload: {
              id: payload.id,
              revId: payload.revId,
              isAccepted: payload.isAccepted,
              isApplied: payload.isApplied,
              changeTypeId: payload.changeTypeId,
              rejectNotes: payload.rejectNotes,
            },
          })
        );
        const { id, revId } = response;
        updateRevIdById(id, revId, interiorStore.interiorItems);
        toast.success('Successfully updated color item');
      } else {
        toast.error('Please fill in the required fields for the color');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating color item');
    }

    uiBlock.stop();
  };

  return (
    <>
      <ActionBar>
        <ActionBarVehicleData
          readOnly
          searchText={interiorStore.searchText}
          onSearchTextChange={text =>
            interiorStore.onFilter(() => (interiorStore.searchText = text))
          }
          renderFilter={onClose => (
            <ColorsFilters
              isExtraCostColorFilter={interiorStore.isExtraCostFilter}
              setIsExtraCostColorFilter={value =>
                interiorStore.onFilter(() => (interiorStore.isExtraCostFilter = value))
              }
              onClose={onClose}
            />
          )}
        />
      </ActionBar>
      <TableTabs
        style={{ zIndex: 1 }}
        tabs={[COLORS_TAB.EXTERIOR, COLORS_TAB.INTERIOR]}
        currentTab={colorTab}
        onSelectTab={value => setColorTab(value as COLORS_TAB)}
      />
      <TwoTableWrapper>
        <LeftTable>
          <Thead>
            <HeaderRow>
              <HeaderCell onClick={() => interiorStore.setSort('material')}>Material</HeaderCell>
              <HeaderCell>Interior Type</HeaderCell>
              <HeaderCell onClick={() => interiorStore.setSort('interiorType')}>
                Ornament #1
              </HeaderCell>
              <HeaderCell onClick={() => interiorStore.setSort('onranmentType1')}>
                Ornament #2
              </HeaderCell>
              <HeaderCell colType="changes" border>
                Changes
              </HeaderCell>
              <AcceptApplyHeaderCell hideAccept={reviewStore.hideAccept} />
            </HeaderRow>
          </Thead>
          <tbody>
            {interiorStore.filteredInteriors.map(interiorItem =>
              interiorItem.changes.map(change => (
                <TableRow key={change.uid}>
                  <VDTableCell>{interiorItem.material}</VDTableCell>
                  <VDTableCell>
                    {interiorItem.interiorType} {interiorItem.interiorType_subId}
                  </VDTableCell>
                  <VDTableCell>
                    {interiorItem.ornamentType1} {interiorItem.ornamentType1_subId}
                  </VDTableCell>
                  <VDTableCell>
                    {interiorItem.ornamentType2} {interiorItem.ornamentType2_subId}
                  </VDTableCell>
                  <VDTableCell colType="changes" border>
                    <ColorsLexusReviewChanges changes={change.changes} />
                    {interiorItem.otherChanges.map(otherChange => (
                      <React.Fragment key={otherChange.uid}>
                        <p />
                        <ColorsLexusReviewChanges changes={otherChange.changes} />
                      </React.Fragment>
                    ))}
                  </VDTableCell>
                  <AcceptApplyCell
                    id={`int-rev-${change.uid}`}
                    applyChecked={change.isApplied}
                    acceptChecked={change.isAccepted}
                    onAcceptChecked={
                      !reviewStore.hideAccept
                        ? value => handleOnAcceptChange(interiorItem, change, value)
                        : undefined
                    }
                    onApplyChecked={value => handleOnApplyChange(interiorItem, change, value)}
                    notes={change.rejectNotes}
                    onNotesChange={value => handleOnNotesChange(interiorItem, change, value)}
                    isNewChange={change.isNewChange}
                  />
                </TableRow>
              ))
            )}
          </tbody>
        </LeftTable>
      </TwoTableWrapper>
    </>
  );
};

export default observer(ColorsLexusInteriorReview);
