import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../components/ChangeLogInner';
import useChangeLogChanges from '../../../../hooks/useChangeLogChanges';
import useStores from '../../../../hooks/useStores';
import {
  ChangeLogItem,
  ChangeLogLangMap,
  ChangeLogResponse,
  ChangeLogTypes,
} from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import {
  changeLogCompareFeatureApplicabilityStatusMapper,
  changeLogHandleDeleteTypes,
  changeLogIdMapper,
  changeLogModelApplicabilityMapper,
} from '../../../../utils/changeLogUtils';
import { filterOutUnsuableChanges } from '../../../../utils/gradeApplicabilityUtils';
import { getChangeLog, revertChange } from '../../../../webservices/vehicleCompareFeaturesApi';
import {
  getCategoriesByLang as getFeatureCategories,
  getSubCategoriesByLang as getFeatureSubCategories,
} from '../../../../webservices/vehicleFeaturesApi';
import {
  getCategories as getSpecCategories,
  getSpecTypes,
} from '../../../../webservices/vehicleSpecsApi';

interface FeaturesChangeLogProps {
  seriesId: string;
  year: string;
  readOnly?: boolean;
  version: string;
}

const CompareFeaturesChangeLog = ({
  seriesId,
  year,
  readOnly,
  version,
}: FeaturesChangeLogProps) => {
  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
    compareFeatureStore,
    vehicleModelsStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);
  const { filterChangeLogChanges, changeLogChanges, canRevert } = useChangeLogChanges(
    ChangeLogTypes.COMPARE_FEATURE_STATUS,
    ChangeLogTypes.GRADE_APPLICABILITY_STATUS,
    ChangeLogTypes.ALL_COMPARE_FEATURE_STATUS
  );

  useEffect(() => {
    changeLogStore.reset();

    (async () => {
      setIsLoaded(false);

      try {
        const selectedLangs: KeyValueType<boolean> = {};
        teamStore.team.changeLogLanguages.forEach(lang => {
          selectedLangs[lang] = true;
        });
        changeLogStore.selectedLangsMap = selectedLangs;

        const promises: Promise<AxiosResponse<any>>[] = [];
        const changeLogLangMap: ChangeLogLangMap = {};
        for (const lang of teamStore.team.changeLogLanguages) {
          promises.push(
            getChangeLog(
              brand,
              teamStore.team.param,
              seriesId,
              year,
              lang,
              version,
              'compare-features'
            ),
            getFeatureCategories(brand, teamStore.team.param, seriesId, year, lang, version, true), // fetch deleted records as well
            getFeatureSubCategories(
              brand,
              teamStore.team.param,
              seriesId,
              year,
              lang,
              version,
              true
            ), // fetch deleted records as well
            getSpecCategories(brand, teamStore.team.param, seriesId, year, lang, version, true), // fetch deleted records as well
            getSpecTypes(brand, teamStore.team.param, seriesId, year, lang, version, true) // fetch deleted records as well
          );
        }

        const responses = await Promise.all(promises);
        let index = 0;
        for (const lang of teamStore.team.changeLogLanguages) {
          const baseIndex = index * 5;
          const logs = (responses[baseIndex].data as ChangeLogResponse[])
            .map(item => new ChangeLogItem(item, !readOnly, lang))
            .filter(filterOutUnsuableChanges)
            .filter(filterChangeLogChanges);

          compareFeatureStore.updateFeatureCategoriesMap(
            lang,
            compareFeatureStore.featureCategoriesMap,
            responses[baseIndex + 1].data
          );
          compareFeatureStore.updateFeatureCategoriesMap(
            lang,
            compareFeatureStore.featureSubCategoriesMap,
            responses[baseIndex + 2].data
          );
          compareFeatureStore.updateSpecCategoriesMap(
            lang,
            compareFeatureStore.specCategoriesMap,
            responses[baseIndex + 3].data
          );
          compareFeatureStore.updateFeatureCategoriesMap(
            lang,
            compareFeatureStore.specTypesMap,
            responses[baseIndex + 4].data
          );

          const cats = [
            ...compareFeatureStore.getCategoriesForLang(
              lang,
              compareFeatureStore.featureCategoriesMap
            ),
            ...compareFeatureStore.getCategoriesForLang(
              lang,
              compareFeatureStore.specCategoriesMap
            ),
          ];
          const subCats = [
            ...compareFeatureStore.getCategoriesForLang(
              lang,
              compareFeatureStore.featureSubCategoriesMap
            ),
            ...compareFeatureStore.getCategoriesForLang(lang, compareFeatureStore.specTypesMap),
          ];

          changeLogIdMapper(cats, logs, ChangeLogTypes.CATEGORY);
          changeLogIdMapper(subCats, logs, ChangeLogTypes.SUB_CATEGORY);

          changeLogHandleDeleteTypes(logs, ChangeLogTypes.COMPARE_FEATURE_DELETED);
          changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
          changeLogCompareFeatureApplicabilityStatusMapper(vehicleModelsStore.grades, logs);
          changeLogLangMap[lang] = logs;

          index += 1;
        }

        changeLogStore.changeLogLangMap = changeLogLangMap;
      } catch (e) {
        toast.error('Error loading compare features change log');
      }
      setIsLoaded(true);
    })();
  }, [
    brand,
    changeLogStore,
    readOnly,
    seriesId,
    teamStore,
    year,
    compareFeatureStore,
    version,
    vehicleModelsStore,
    filterChangeLogChanges,
  ]);

  const handleOnRevert = async (item: ChangeLogItem) => {
    setIsLoaded(false);
    try {
      const changeLogLangMap: ChangeLogLangMap = JSON.parse(
        JSON.stringify(changeLogStore.changeLogLangMap)
      );
      const lang = item.language ?? Language.EN;

      await trackPromise(
        revertChange(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          lang,
          'compare-features',
          item.payload
        )
      );
      const response = await getChangeLog(
        brand,
        teamStore.team.param,
        seriesId,
        year,
        lang,
        version,
        'compare-features'
      );
      const logs = response.data
        .map(item => new ChangeLogItem(item, !readOnly, lang))
        .filter(filterOutUnsuableChanges)
        .filter(filterChangeLogChanges);

      const cats = [
        ...compareFeatureStore.getCategoriesForLang(lang, compareFeatureStore.featureCategoriesMap),
        ...compareFeatureStore.getCategoriesForLang(lang, compareFeatureStore.specCategoriesMap),
      ];
      const subCats = [
        ...compareFeatureStore.getCategoriesForLang(
          lang,
          compareFeatureStore.featureSubCategoriesMap
        ),
        ...compareFeatureStore.getCategoriesForLang(lang, compareFeatureStore.specTypesMap),
      ];

      changeLogIdMapper(cats, logs, ChangeLogTypes.CATEGORY);
      changeLogIdMapper(subCats, logs, ChangeLogTypes.SUB_CATEGORY);

      changeLogHandleDeleteTypes(logs, ChangeLogTypes.COMPARE_FEATURE_DELETED);
      changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
      changeLogLangMap[lang] = logs;
      changeLogStore.changeLogLangMap = changeLogLangMap;

      toast.success('Change log reverted');
    } catch {
      toast.error('Error reverting change log');
    }
    setIsLoaded(true);
  };

  return (
    <ChangeLogInner
      module={'Compare Features'}
      isLoaded={isLoaded}
      handleOnRevert={handleOnRevert}
      changeLogChanges={changeLogChanges}
      canRevert={canRevert}
    />
  );
};

export default observer(CompareFeaturesChangeLog);
