import React, { useEffect } from 'react';
import { FilterTabColumn, FilterTabColumnList } from 'vapi-ui-common';
import useChecklist from '../../hooks/useChecklist';
import Checkbox from '../Checkbox';

export interface IFiltersColumn {
  list: string[];
  filters: string[];
  setFilters: (filter: string[]) => void;
  title: string;
}

const FiltersColumn = ({ list, filters, setFilters, title }: IFiltersColumn) => {
  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } = useChecklist();

  useEffect(() => {
    (() => {
      setChecklist(
        list.map((cat: string) => ({
          name: cat,
          selected: filters.indexOf(cat) !== -1,
        }))
      );
    })();
  }, [list, setChecklist, filters]);

  return (
    <FilterTabColumn>
      <Checkbox
        id={title}
        checked={isAllSelected}
        onChange={e => {
          selectAll(e.currentTarget.checked);
          if (e.currentTarget.checked) {
            setFilters(checklist.map(item => item.name));
          } else {
            setFilters([]);
          }
        }}
        data-testid="filters-column-comp"
      >
        {title}
      </Checkbox>
      <FilterTabColumnList>
        {checklist.map(item => (
          <li key={item.name}>
            <Checkbox
              id={`${title}chbox${item.name}`}
              checked={item.selected}
              onChange={e => {
                selectItem(item, e.currentTarget.checked);
                const selectedFilters: string[] = [];
                checklist.forEach(cat => {
                  if (cat.selected && cat.name !== item.name) {
                    selectedFilters.push(cat.name);
                  }
                });
                if (e.currentTarget.checked) {
                  selectedFilters.push(item.name);
                }
                setFilters(selectedFilters);
              }}
            >
              {item.name}
            </Checkbox>
          </li>
        ))}
      </FilterTabColumnList>
    </FilterTabColumn>
  );
};

export default FiltersColumn;
