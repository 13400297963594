import { useParams } from "react-router";
import { VehicleTeam } from '../models/vehicleData.model';

type ParamProps = {
  team: VehicleTeam;
  [key: string]: string
};

const useRouteParams = ():ParamProps => {
  const params = useParams<ParamProps>();
  const returnParams = {} as ParamProps;
  for (const prop in params) {
    if (prop === 'team') {
      returnParams.team = params[prop] as VehicleTeam;
    } else {
      returnParams[prop] = params[prop];
    }
  }
  return returnParams;
};

export default useRouteParams;
