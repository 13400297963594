import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { HeaderRow, LeftTableTH, Thead } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';
import OptionsLimitedDataStatusHeader from './OptionsLimitedDataStatusHeader';
import styles from './optionsHeaderRow.module.scss';

type OptionsHeaderRowProps = {
  viewModelCodes: boolean;
  allowLinks: boolean;
  readOnly?: boolean;
  onSort?: (field: string) => void;
  sortMode: boolean;
  setLastUpdated: React.Dispatch<React.SetStateAction<Date>>;
};

const OptionsHeaderRow = ({
  allowLinks,
  onSort = () => {},
  sortMode,
  readOnly,
  setLastUpdated,
}: OptionsHeaderRowProps) => {
  const {
    optionsStore,
    tableSizeStore: { tableRowHeight },
    teamStore,
  } = useStores();

  const selectedLangs = optionsStore.allLangs.filter(lang => optionsStore.selectedLangsMap[lang]);

  const displayLimitedDataStatus =
    teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';

  return (
    <Thead>
      <HeaderRow
        style={{
          height: `${tableRowHeight}px`,
        }}
      >
        {teamStore.team.showAcceptChanges && optionsStore.hasChangedAttributes() && (
          <LeftTableTH className={styles.category}>Accept Changes</LeftTableTH>
        )}
        <LeftTableTH className={cx(styles.leftTableTH)}>&nbsp;</LeftTableTH>

        {sortMode && (
          <LeftTableTH className={cx(styles.leftTableTH, inputStyles.smallInputHeader)}>
            #
          </LeftTableTH>
        )}

        <LeftTableTH className={cx(styles.leftTableTH)} onClick={() => onSort('category.value')}>
          Category
        </LeftTableTH>

        {selectedLangs.map(lang => (
          <LeftTableTH
            key={`HeaderRow-Name-${lang}`}
            className={cx(styles.leftTableTH)}
            onClick={() => onSort('name')}
          >
            {`${lang} Name`}
          </LeftTableTH>
        ))}

        {allowLinks && <LeftTableTH className={cx(styles.leftTableTH)}></LeftTableTH>}

        {selectedLangs.map((lang, index) => {
          const colSpan = index === selectedLangs.length - 1 && !displayLimitedDataStatus ? 2 : 1;

          return (
            <LeftTableTH
              key={`HeaderRow-Description-${lang}`}
              className={cx(styles.leftTableTH)}
              colSpan={colSpan}
              onClick={() => onSort('description')}
            >
              {`${lang} Description`}
            </LeftTableTH>
          );
        })}

        {displayLimitedDataStatus && (
          <LeftTableTH className={cx(styles.leftTableTH)}>
            <OptionsLimitedDataStatusHeader
              hideLimitedDataStatus={!displayLimitedDataStatus}
              readOnly={readOnly}
              setLastUpdated={setLastUpdated}
            />
          </LeftTableTH>
        )}

        <LeftTableTH className={cx(styles.leftTableTH)} onClick={() => onSort('code')}>
          Code
        </LeftTableTH>
        <LeftTableTH className={cx(styles.leftTableTH)} onClick={() => onSort('isExtraCost')}>
          MSRP
        </LeftTableTH>
        <LeftTableTH className={cx(styles.leftTableTH)}>&nbsp;</LeftTableTH>
      </HeaderRow>
    </Thead>
  );
};

export default observer(OptionsHeaderRow);
