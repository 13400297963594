import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';
import useStores from '../../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { CommonLanguageItem } from '../../../models/commonLanguage.model';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { categoriesObjectXForm } from '../../../utils/categoryUtils';
import { getCategories, getSpecs } from '../../../webservices/commonLanguageApi';
import { getAvailableModels } from '../../../webservices/vehicleAdminApi';
import ActionBar from '../components/ActionBarCommonLanguage';
import CommonLanguageTable from '../components/CommonLanguageTable';
import SpecRow from '../components/CommonLanguageTable/components/SpecRow';
import { toGqlBrand, toGqlFilter } from '../../../utils/graphqlUtils';
import { Status } from '../../../gql/generated';

const CommonLanguageArchive = observer(() => {
  const {
    userStore: { brand },
    commonLanguageStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    commonLanguageStore.reset();

    (async () => {
      try {
        const [dataResponse, categoriesResponse, modelsResponse] = await Promise.all([
          getSpecs({
            brand: toGqlBrand(brand),
            filter: toGqlFilter(Status.Archived),
          }),
          getCategories({ brand: toGqlBrand(brand) }),
          getAvailableModels(brand),
        ]);

        commonLanguageStore.setData(dataResponse.map(item => new CommonLanguageItem(item)));
        commonLanguageStore.categories = categoriesObjectXForm(categoriesResponse.categories);
        commonLanguageStore.vehicleModels = modelsResponse.data;
      } catch (e) {
        toast.error('Error loading arhived data');
      }

      setIsLoaded(true);
    })();

    return () => {
      commonLanguageStore.data = [];
    };
  }, [brand, commonLanguageStore]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="Common Language" moduleSubTitle="Archive" />
      <ActionBar
        readOnly
        searchText={commonLanguageStore.searchText}
        setSearchText={value => {
          commonLanguageStore.searchText = value;
          commonLanguageStore.filterData();
        }}
        categories={commonLanguageStore.categories}
        categoryFilters={commonLanguageStore.categoryFilters}
        vehicleModels={commonLanguageStore.vehicleModels}
        vehicleFilters={commonLanguageStore.vehicleFilters}
        setFilters={commonLanguageStore.setFilters}
        onResetDraft={() => {}}
        onAddSpec={() => {}}
      />
      <CommonLanguageTable
        isArchived
        onSort={commonLanguageStore.setSort}
        renderRows={() => (
          <>
            {commonLanguageStore.filteredData.map((item, index) => (
              <SpecRow
                index={index}
                key={item.uid}
                item={item}
                readOnly
                categories={commonLanguageStore.categories}
                vehicleModels={commonLanguageStore.vehicleModels}
              />
            ))}
          </>
        )}
      />
    </>
  );
});

export default CommonLanguageArchive;
