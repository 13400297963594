import cx from 'clsx';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './tooltipPortal.module.scss';

export interface TooltipProps {
  popoverClass?: string;
  toggleElement: React.ReactElement;
  popoverElement?: React.ReactElement;
  popoverText?: string;
  align?: 'right' | 'left';
  popoverElementClass?: string;
  defaultOpen?: boolean;
  leftInititalPosition?: number;
  ignoreCalculatedPosition?: boolean;
  hide?: boolean;
}

const TooltipPortal = ({
  popoverClass,
  toggleElement,
  popoverElement,
  popoverText,
  hide,
  popoverElementClass,
  align,
  defaultOpen = false,
}: TooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [coords, setCoords] = useState({ top: 0, left: 0 });

  const handlePosition = () => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      setCoords({ left: rect.x + rect.width / 2 - 5, top: rect.y + window.scrollY + 18 });
    }
    setOpen(true);
  };

  const handleExitPosition = () => {
    setOpen(false);
  };

  const ModalContent = () => (
    <div
      className={styles.popoverContainer}
      style={{ color: 'red', top: `${coords.top}px`, left: `${coords.left}px` }}
    >
      {open && !hide && (
        <div className={styles.popoverElementContainer}>
          <div
            className={cx(
              {
                [styles.popoverElement]: true,
                [styles.popoverDefault]: true,
              },
              styles[`align-${align}`],
              popoverElementClass
            )}
          >
            {popoverElement}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div ref={ref} className={cx([popoverClass, styles.popover])} data-testid="tooltip-portal">
        {React.cloneElement(toggleElement, {
          onMouseOver: handlePosition,
          onMouseLeave: () => handleExitPosition(),
        })}
      </div>
      {open ? ReactDOM.createPortal(<ModalContent />, document.body) : null}
    </>
  );
};

export default TooltipPortal;
