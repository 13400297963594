import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import styles from './confirmPublishModalTable.module.scss';

interface ConfirmPublishModalTableProps {
  renderRows: () => ReactNode;
}

const ConfirmPublishModalTable = observer(({ renderRows }: ConfirmPublishModalTableProps) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <td></td>
          <td>Model Name</td>
          <td>Status</td>
        </tr>
      </thead>
      <tbody className={styles.body}>{renderRows()}</tbody>
    </table>
  );
});

export default ConfirmPublishModalTable;
