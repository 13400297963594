import cx from 'clsx';
import React from 'react';
import styles from './tableCell.module.scss';
import { TableColType } from './tableTypes';

export interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  large?: boolean;
  center?: boolean;
  border?: boolean;
  redStrike?: boolean;
  smallText?: boolean;
  whiteBg?: boolean;
  colType?: TableColType;
  noSpan?: boolean;
  spanClass?: string;
}

export const TableCell: React.FC<TableCellProps> = ({
  className,
  large,
  center,
  border,
  redStrike,
  smallText,
  whiteBg,
  children,
  colType,
  noSpan,
  spanClass,
  ...rest
}) => (
  <td
    className={cx(
      styles.tableCell,
      large && styles.large,
      center && styles.center,
      border && styles.border,
      redStrike && styles.redStrike,
      whiteBg && styles.whiteBg,
      smallText && styles.smallText,
      colType && styles[`colType-${colType}`],
      className
    )}
    data-testid="table-cell-comp"
    {...rest}
  >
    {noSpan ? children : <span className={spanClass}>{children}</span>}
  </td>
);
