import React, { useState } from 'react';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import NotesPopover from '../../../../../components/NotesPopover/NotesPopover';
import { TableCell } from '../../../../../components/Table';
import styles from './applyCell.module.scss';

interface AcceptApplyCellProps {
  rowSpan?: number;
  id: string;
  applyChecked: boolean;
  onApplyChecked: (value: boolean) => void;
  notes: string;
  handleOnNotesChange: (notes: string) => void;
  hideRejectNotes?: boolean;
  disabled?: boolean;
  className?: string;
  attachTo?: Element;
}

export const ApplyCell = ({
  rowSpan = 1,
  id,
  applyChecked,
  onApplyChecked,
  notes,
  handleOnNotesChange,
  hideRejectNotes = false,
  disabled = false,
  className,
  attachTo,
}: AcceptApplyCellProps) => {
  const [openNotes, setOpenNotes] = useState(false);

  const handleOnApplyChecked = (isChecked: boolean) => {
    onApplyChecked(isChecked);
    if (!isChecked) {
      setOpenNotes(true);
    }
  };

  return (
    <>
      <TableCell colType="apply" center rowSpan={rowSpan} className={className}>
        <div className={!applyChecked && !hideRejectNotes ? styles.applyRejectContainer : ''}>
          <Checkbox
            id={`chbox-accept-${id}`}
            checked={applyChecked}
            onChange={e => handleOnApplyChecked(e.currentTarget.checked)}
            disabled={disabled}
            theme={disabled ? 'grayOutline' : undefined}
          />
          {!applyChecked && !hideRejectNotes && (
            <div className={styles.popoverIcon}>
              <NotesPopover
                defaultOpen={openNotes}
                notes={notes}
                icon="circle"
                readOnly={disabled}
                handleUpdateItem={handleOnNotesChange}
                title="Reject Notes"
                align="left"
                attachTo={attachTo}
              />
            </div>
          )}
        </div>
      </TableCell>
    </>
  );
};
