import React, { Ref, useState } from 'react';
import Calendar from 'react-calendar';
import { DropdownContent, Button } from 'vapi-ui-common';
import styles from './dropdownDatepicker.module.scss';

interface DropdownAssignProps {
  open: boolean;
  onClose: () => void;
  updateDate: (date: Date) => void;
  onClear?: () => void;
  currentDate?: Date;
}

const DropdownDatepicker = React.forwardRef(
  ({ open, updateDate, onClose, onClear, currentDate }: DropdownAssignProps, ref: Ref<any>) => {
    const [goLiveDate, setGoLiveDate] = useState<Date>(currentDate || new Date());

    const onSave = () => {
      updateDate(goLiveDate);
      onClose();
    };

    const getTomorrow = () => {
      const today: Date = new Date();
      today.setDate(today.getDate() + 1);
      return new Date(today);
    };

    return (
      <DropdownContent open={open} ref={ref}>
        <div className={styles.calendarContainer}>
          <Calendar
            className={styles.calendar}
            value={goLiveDate}
            onChange={setGoLiveDate}
            minDate={getTomorrow()}
          />
          <div className={styles.buttonContainer}>
            <Button className={styles.cancelButton} variant="transparent" onClick={onClose}>
              Cancel
            </Button>
            <Button className={styles.saveButton} variant="primary" onClick={onSave}>
              Queue Live Date
            </Button>
            <Button className={styles.saveButton} variant="primary" onClick={onClear}>
              Clear Queued Live Date
            </Button>
          </div>
        </div>
      </DropdownContent>
    );
  }
);

export default DropdownDatepicker;
