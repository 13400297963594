import React, { Ref, RefObject, useCallback, useLayoutEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import { DropdownContent } from 'vapi-ui-common';
import {
  GO_LIVE_DATE_TIME,
  GoLiveDateTime,
  goLiveDateTimeMap,
} from '../../../../../utils/dateUtils';
import styles from '../modelsModal.module.scss';

interface IGoLiveDateTimePickerList {
  currentTime: GoLiveDateTime;
  onChange: (time: GoLiveDateTime) => void;
  open: boolean;
  closeDropdown: () => void;
}

const GoLiveDateTimePickerList = React.forwardRef(
  ({ currentTime, onChange, open, closeDropdown }: IGoLiveDateTimePickerList, ref: Ref<any>) => {
    const list: GoLiveDateTime[] = [
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_12],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_1],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_2],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_3],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_4],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_5],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_6],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_7],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_8],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_9],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_10],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.AM_11],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_12],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_1],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_2],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_3],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_4],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_5],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_6],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_7],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_8],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_9],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_10],
      goLiveDateTimeMap[GO_LIVE_DATE_TIME.PM_11],
    ];

    useLayoutEffect(() => {
      if (open && ref) {
        const refObject = ref as RefObject<HTMLDivElement>;

        if (!refObject.current) return;

        const liElements = refObject.current.querySelectorAll('li[role="menuitem"]');
        const targetLi = Array.from(liElements).find(li => li.textContent === currentTime.text);
        targetLi?.scrollIntoView({ behavior: 'instant', block: 'start' });
      }
    }, [currentTime, open, ref]);

    const CListItem = ({ item }: { item: GoLiveDateTime }) => {
      const onSelect = useCallback(() => {
        onChange(item);
        closeDropdown();
      }, [item]);

      return (
        <li role="menuitem" onClick={onSelect}>
          {item.text}
        </li>
      );
    };

    return (
      <DropdownContent open={open} ref={ref}>
        <ul className={styles.dropdownList}>
          {list.map(item => (
            <CListItem key={item.text} item={item} />
          ))}
        </ul>
      </DropdownContent>
    );
  }
);

export default GoLiveDateTimePickerList;
