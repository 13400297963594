import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { areRichTextValuesDifferent, convertToRichTextObject, Input } from 'vapi-ui-common';
import DropdownEditItem from '../../../../../../components/DropdownEdit/DropdownEditItem';
import IconTextButton from '../../../../../../components/IconTextButton';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { TableCell, TableRowWithShadow } from '../../../../../../components/Table';
import TableDragIcon from '../../../../../../components/Table/components/TableDragIcon';
import useFieldStatusModelUpdate from '../../../../../../hooks/useFieldStatusModelUpdate';
import { useCategoriesMapSort } from '../../../../../../hooks/useLanguageMapSort';
import useStores from '../../../../../../hooks/useStores';
import { CategoryLangMap } from '../../../../../../models/category.model';
import { OptionLangMap } from '../../../../../../models/options.model';
import { BRAND_TDPR, Language } from '../../../../../../models/user.model';
import RichTextCell from '../../../../../../routes/vehicleData/components/tableCells/RichTextCell';
import { handleOnLangMapSortNumberUpdate } from '../../../../../../utils/sortUtils';
import { updateOptionFeatureStatus } from '../../../../../../webservices/vehicleOptionsApi';
import SyncTdPRButton from '../../../../components/SyncTdPRButton/SyncTdPRButton';
import CenteredTextAreaCell from '../../../../components/tableCells/CenteredTextAreaCell';
import ContextMenuCell from '../../../../components/tableCells/ContextMenuCell';
import DropdownEditorCell from '../../../../components/tableCells/DropdownEditorCell';
import FlagsCell from '../../../../components/tableCells/FlagsCell';
import LinkCell from '../../../../components/tableCells/LinkCell';
import { IOptionsRow } from './IOptionsRow';
import styles from './optionRow.module.scss';

interface Props extends IOptionsRow {
  optionLangMap: OptionLangMap;
  index: number;
  draggableProvided: DraggableProvided;
}

const OptionsRow: React.FC<Props> = ({
  index,
  optionLangMap,
  draggableProvided,
  sortMode,
  saveOptionLangMap,
  deleteOptionLangMap,
  copyOptionLangMap,
  addCategoryItem,
  updateCategoryItem,
  showLink,
  readOnly,
  disclaimerTokens,
  brand,
  compareOption,
}) => {
  /** Option change handlers */
  const { optionsStore, teamStore } = useStores();

  const defaultOption = optionLangMap.langs[optionsStore.defaultLang];
  const selectedLangs = optionsStore.allLangs.filter(lang => optionsStore.selectedLangsMap[lang]);
  const index2 = optionsStore.filteredOptionLangMaps.findIndex(
    x => x.langs[optionsStore.defaultLang].id === defaultOption.id
  );
  const hasEnglishWritePerms = !!optionsStore.langWriteMap.EN?.canEdit;

  const { onClick: onClickLimitedDataStatus } = useFieldStatusModelUpdate({
    store: defaultOption,
    callBackGql: updateOptionFeatureStatus,
  });

  const handleOnCategorySelect = (categoryMap: CategoryLangMap, lang: string) => {
    const newCategory = categoryMap[lang];
    const currentCategory = optionLangMap.langs[lang].category;
    if (
      newCategory.value &&
      (currentCategory.value !== newCategory.value || currentCategory.id !== newCategory.id)
    ) {
      optionsStore.editableLangs.forEach(lang => {
        optionLangMap.langs[lang].category = categoryMap[lang];
      });
      saveOptionLangMap(optionLangMap);
    }
  };

  const handleOnNameChange = (value: string, lang: string) => {
    const option = optionLangMap.langs[lang];
    if (areRichTextValuesDifferent(option.name, value)) {
      option.name = value;
      saveOptionLangMap(optionLangMap, lang);
    }
  };

  const handleOnDescriptionChange = (value: string, lang: string) => {
    const option = optionLangMap.langs[lang];
    if (areRichTextValuesDifferent(option.description, value)) {
      option.description = value;
      saveOptionLangMap(optionLangMap, lang);
    }
  };

  const handleOnCodeChange = (value: string) => {
    let shouldUpdate = false;
    optionsStore.editableLangs.forEach(lang => {
      if (optionLangMap.langs[lang].code !== value) {
        optionLangMap.langs[lang].code = value;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveOptionLangMap(optionLangMap);
    }
  };

  const handleOnNotesChange = (value: string) => {
    let shouldUpdate = false;
    optionsStore.editableLangs.forEach(lang => {
      if (optionLangMap.langs[lang].notes !== value) {
        optionLangMap.langs[lang].notes = value;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveOptionLangMap(optionLangMap);
    }
  };

  const handleInProgressChange = () => {
    optionsStore.editableLangs.forEach(lang => {
      optionLangMap.langs[lang].isInProgress = !optionLangMap.langs[lang].isInProgress;
    });
    saveOptionLangMap(optionLangMap);
  };

  const handleOnLinkChange = (link: string) => {
    let shouldUpdate = false;
    optionsStore.editableLangs.forEach(lang => {
      if (optionLangMap.langs[lang].link !== link) {
        optionLangMap.langs[lang].link = link;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveOptionLangMap(optionLangMap);
    }
  };

  const handleOnMsrpChange = (isExtraCost: string) => {
    let shouldUpdate = false;
    optionsStore.editableLangs.forEach(lang => {
      if (optionLangMap.langs[lang].isExtraCost !== isExtraCost) {
        optionLangMap.langs[lang].isExtraCost = isExtraCost;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveOptionLangMap(optionLangMap);
    }
  };

  const isAcceptChangesVisible =
    !readOnly &&
    !!optionsStore.editableLangs.filter(
      lang => !!optionLangMap.langs[lang].changedAttributes.length
    ).length;

  const { sortedList } = useCategoriesMapSort(optionsStore.categoriesMap, Language.EN);
  const displayLimitedDataStatus =
    teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';

  const showSyncTdPR =
    brand === BRAND_TDPR && defaultOption.fromTMNA && !readOnly && selectedLangs.length >= 1;

  const changedAttributes = () => {
    const changed: string[] = [];
    optionsStore.editableLangs.forEach(lang => {
      const option = optionLangMap.langs[lang];
      if (option) {
        changed.push(...option.changedAttributes);
      }
    });
    return changed;
  };

  return (
    <>
      <TableRowWithShadow
        onFillRowHeightChange={(rowHeight: number) => {
          optionsStore.setOptionsRowHeight(optionLangMap, rowHeight);
        }}
        innerRef={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        className={styles.row}
      >
        {!readOnly && sortMode && (
          <>
            <TableCell {...draggableProvided.dragHandleProps} border center>
              <TableDragIcon />
            </TableCell>
            <TableCell border center>
              <input
                className={cx(inputStyles.input, inputStyles.smallInput)}
                value={defaultOption.sortOrder}
                onBlur={newIndex => {
                  handleOnLangMapSortNumberUpdate(
                    optionsStore.filteredOptionLangMaps,
                    newIndex.target.value,
                    index2
                  );
                  optionsStore.filteredOptionLangMaps = optionsStore.filteredOptionLangMaps.slice();
                }}
                onChange={e =>
                  (defaultOption.sortOrder =
                    parseInt(e.currentTarget.value, 10) > 0
                      ? parseInt(e.currentTarget.value, 10)
                      : '')
                }
              />
            </TableCell>
          </>
        )}

        {!readOnly && !sortMode && teamStore.team.allowAddDeleteData && (
          <ContextMenuCell
            itemType="Option"
            description={convertToRichTextObject(defaultOption.description).text}
            deleteItem={() => deleteOptionLangMap(optionLangMap)}
            copyItem={() => copyOptionLangMap(optionLangMap)}
          />
        )}

        {teamStore.team.showAcceptChanges && isAcceptChangesVisible && (
          <TableCell>
            <div id={`acceptChanges-${defaultOption.id}`} className={styles.acceptChangesThumbsUp}>
              <IconTextButton
                id={`acceptChanges-checkbox-${defaultOption.id}`}
                icon="thumbsUp"
                text=""
                onClick={() => {
                  saveOptionLangMap(optionLangMap, '', true);
                }}
              />
            </div>
          </TableCell>
        )}
        {teamStore.team.showAcceptChanges &&
          !isAcceptChangesVisible &&
          optionsStore.hasChangedAttributes() && <TableCell></TableCell>}

        {!readOnly && !sortMode && !teamStore.team.allowAddDeleteData && <td></td>}
        {readOnly && <td></td>}

        <TableCell
          className={styles.categoryColumn}
          spanClass={cx(
            styles.flexColumn,
            (showSyncTdPR || defaultOption.fromTMNA) && !readOnly && styles.alignCategorySubCategory
          )}
        >
          {showSyncTdPR && (
            <SyncTdPRButton
              selectedLangs={selectedLangs}
              id={defaultOption.id}
              changedAttributes={changedAttributes()}
              onClick={() => {
                compareOption(optionLangMap);
              }}
              className={styles.tdprButton}
            />
          )}
          {selectedLangs.map(lang => {
            const option = optionLangMap.langs[lang];
            const currentCategory = option.category;
            const currentCategoryValue: string = currentCategory.value;

            if (lang === Language.EN) {
              return (
                <DropdownEditorCell
                  key={`OptionRow-Category-${lang}`}
                  error={(() => {
                    if (!optionsStore.langWriteMap[lang]?.canEdit || currentCategoryValue) {
                      // if you dont have write permissions or the current option category has a value
                      return false;
                    }
                    return true;
                  })()}
                  disabled={readOnly || !optionsStore.langWriteMap[lang]?.canEdit}
                  onAdd={value => {
                    const payload: { [lang: string]: string } = { [lang]: value };
                    optionsStore.editableLangs.forEach(lang => {
                      if (!payload[lang]) {
                        payload[lang] = value;
                      }
                    });
                    addCategoryItem(payload);
                  }}
                  value={currentCategoryValue}
                  renderList={onClose =>
                    sortedList?.map(item => {
                      const categoryMap = item.categoryMap;
                      const category = item.category;

                      return (
                        <DropdownEditItem
                          key={category.id}
                          value={category.value}
                          isSelected={currentCategoryValue === category.value}
                          onEdit={(from, to) => {
                            if (to.length) {
                              const payload: { [lang: string]: string } = { [lang]: to };
                              if (!category.value) {
                                addCategoryItem(payload, category.id);
                              } else {
                                updateCategoryItem(categoryMap, payload);
                              }
                            }
                          }}
                          onClose={() => onClose()}
                          onSelect={() => {
                            handleOnCategorySelect(categoryMap, lang);
                            onClose();
                          }}
                        />
                      );
                    })
                  }
                />
              );
            }
            return (
              <div key={currentCategoryValue}>
                <Input
                  defaultValue={currentCategoryValue}
                  onBlur={e => {
                    const value = e.currentTarget.value.trim();
                    if (value.length && value !== currentCategoryValue) {
                      const payload: { [lang: string]: string } = { [lang]: value };
                      if (!currentCategoryValue) {
                        // if the category doesnt have a value then we are going to add it
                        addCategoryItem(payload, currentCategory.id);
                      } else {
                        updateCategoryItem(
                          optionsStore.categoriesMap.categories[currentCategory.id],
                          payload
                        );
                      }
                    }
                  }}
                  name="name"
                  disabled={readOnly}
                  placeholder=""
                  className={
                    option.changedAttributes.includes('categoryId') || !currentCategoryValue
                      ? styles.errorText
                      : ''
                  }
                />
              </div>
            );
          })}
        </TableCell>

        {selectedLangs.map(lang => {
          const option = optionLangMap.langs[lang];
          return (
            <RichTextCell
              cellStyles={cx(styles.short)}
              key={`OptionRow-Name-${lang}`}
              required={!option.name || option.changedAttributes.includes('name')}
              colType="description"
              suggestionTypes={disclaimerTokens}
              disabled={readOnly || !optionsStore.langWriteMap[lang]?.canEdit}
              value={option.name}
              onBlur={value => handleOnNameChange(value, lang)}
            />
          );
        })}

        {showLink && (
          <LinkCell
            disabled={readOnly || !hasEnglishWritePerms}
            link={defaultOption.link}
            onClick={handleOnLinkChange}
          />
        )}

        {/* not sure if it needs a contiondal check like features  */}
        {selectedLangs.map(lang => {
          const option = optionLangMap.langs[lang];
          return (
            <RichTextCell
              cellStyles={cx(styles.short)}
              key={`OptionRow-Description-${lang}`}
              required={!option.description || option.changedAttributes.includes('description')}
              colType="description"
              suggestionTypes={disclaimerTokens}
              disabled={readOnly || !optionsStore.langWriteMap[lang]?.canEdit}
              value={option.description}
              onBlur={value => {
                handleOnDescriptionChange(value, lang);
              }}
            />
          );
        })}

        <FlagsCell
          disabled={readOnly || !hasEnglishWritePerms}
          index={index}
          notes={defaultOption.notes}
          rejectNotes={defaultOption.rejectNotes}
          highlighted={false}
          inProgress={defaultOption.isInProgress}
          toggleInProgress={() => {
            if (hasEnglishWritePerms) {
              handleInProgressChange();
            }
          }}
          displayHighlighted={false}
          toggleHighlighted={() => {}}
          onNotesChange={notes => {
            handleOnNotesChange(notes);
          }}
          className={cx({
            [styles.flagsCellLarger]: displayLimitedDataStatus,
          })}
          displayLimitedDataStatus={displayLimitedDataStatus}
          limitedDataStatus={defaultOption.fieldStatus?.status}
          onClickLimitedDataStatus={onClickLimitedDataStatus}
          hideInProgress={process.env.REACT_APP_LIMITED_DATA === 'true'}
        />
        <CenteredTextAreaCell
          required
          disabled={readOnly || !hasEnglishWritePerms || brand === BRAND_TDPR}
          value={defaultOption.code}
          onBlurCallback={value => handleOnCodeChange(value)}
          size="xsmall"
          singleLine={true}
        />
        <CenteredTextAreaCell
          required
          disabled={readOnly || !hasEnglishWritePerms || brand === BRAND_TDPR}
          value={defaultOption.isExtraCost}
          onBlurCallback={value => handleOnMsrpChange(value)}
          size="xsmall"
          singleLine={true}
        />
      </TableRowWithShadow>
    </>
  );
};

export default observer(OptionsRow);
