import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import Checkbox from '../../../../../../../components/Checkbox';
import NotesPopover from '../../../../../../../components/NotesPopover/NotesPopover';
import { TableCell } from '../../../../../../../components/Table';
import useStores from '../../../../../../../hooks/useStores';
import { BnpCategorySplit } from '../../../../../../../models/buildAndPrice.model';
import { BuildAndPriceRowItemContext } from '../BuildAndPriceRow';
import styles from '../buildAndPriceRow.module.scss';
interface CategoryCellProps {
  split?: BnpCategorySplit;
}

const CategoryCell = ({ split }: CategoryCellProps) => {
  const context = useContext(BuildAndPriceRowItemContext);
  const {
    bnpStore: { allLangs, editableLangs, defaultLang },
  } = useStores();

  if (!context) {
    return null;
  }

  const { langMap, readOnly, onUpdateDescription } = context;

  const defaultItem = langMap[defaultLang];
  const isAcceptChangesVisible =
    !readOnly && !!allLangs.filter(lang => !!langMap[lang].changedAttributes.length).length;
  const rejectNotes = defaultItem.rejectNotes;

  return (
    <TableCell colType="bpCategory" className={styles.categoryCell}>
      {allLangs.map(lang => {
        const item = langMap[lang];
        return (
          <div className={styles.category} key={`${item.categoryId}-${lang}`}>
            {item.categoryValue}
          </div>
        );
      })}
      {rejectNotes && !split && (
        <NotesPopover
          notes={rejectNotes}
          icon="purpleCircle"
          readOnly
          handleUpdateItem={() => {}}
          title="Reject Notes"
        />
      )}
      {isAcceptChangesVisible && (
        <div className={cx([styles.acceptChanges])}>
          <Checkbox
            id={`acceptChanges-${defaultItem.name}`}
            checked={!!allLangs.filter(lang => !!langMap[lang].acceptChanges).length}
            onChange={e => {
              allLangs.forEach(lang => {
                langMap[lang].acceptChanges = !!e.target.checked;
              });
              editableLangs.forEach(lang => {
                onUpdateDescription({ item: langMap[lang], lang, acceptChanges: true });
              });
            }}
          >
            <span className={styles.checkboxLabel}>Accept Changes</span>
          </Checkbox>
        </div>
      )}
    </TableCell>
  );
};

export default observer(CategoryCell);
