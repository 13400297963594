import { useState, useEffect, useCallback } from 'react';
import { ISortList, VDSortableEntity } from '../models/sort.model';
import { VehicleTeam } from '../models/vehicleData.model';
import { Language } from '../models/user.model';
import { toast } from 'react-toastify';
import { getSortList } from '../webservices/vehicleAdminApi';
import { OPPOSITE_TEAM_SORT } from '../constants/vehicleData/VDConstants';

interface ILangMap {
  langs: {
    [lang: string]: { id: string; sortOrder: string | number };
  };
}

const useOppositeTeamSort = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  year: string,
  tab: VDSortableEntity
) => {
  const ERROR_MESSAGE = 'No sort order to apply';

  enum OppositeTeamSort {
    APPLY_AGENCY_SORT = 'Apply Agency Sort',
    APPLY_PRODUCT_SORT = 'Apply Product Sort',
  }

  const [orderList, setOrderlist] = useState<ISortList>({
    sortList: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const oppositeTeamResponse = await getSortList(
          brand,
          team === VehicleTeam.PRODUCT_TEAM ? VehicleTeam.AGENCY_TEAM : VehicleTeam.PRODUCT_TEAM,
          seriesId,
          year,
          tab,
          OPPOSITE_TEAM_SORT
        );

        if (Object.keys(oppositeTeamResponse?.data.sortList || {}).length) {
          setOrderlist(oppositeTeamResponse.data);
        }
      } catch (e) {}
    };
    fetchData();
  }, [brand, team, seriesId, year, tab]);

  const getSortListForOppositeTeam = (teanName: VehicleTeam, list: string[]) => {
    if (process.env.REACT_APP_APPLY_TEAM_SORT === 'true') {
      if (teanName === VehicleTeam.PRODUCT_TEAM) {
        list = [...list, OppositeTeamSort.APPLY_AGENCY_SORT];
      } else if (teanName === VehicleTeam.AGENCY_TEAM) {
        list = [...list, OppositeTeamSort.APPLY_PRODUCT_SORT];
      }
    }

    return list;
  };

  const setListOrder = <T extends ILangMap>(list: T[]) => {
    list.forEach((item, index: number) => {
      Object.values(item.langs).forEach(dataItem => {
        dataItem.sortOrder = index + 1;
      });
    });
  };

  const turnOnOppositeTeamSort = useCallback(
    <T extends ILangMap>(
      list: T[],
      resetFilters: () => void,
      setList: (val: T[]) => void,
      setSortMode: (val: React.SetStateAction<boolean>) => void
    ) => {
      if (!Object.keys(orderList.sortList).length) {
        toast.error(ERROR_MESSAGE);
        return;
      }

      const sortedList = list.sort((a, b) => {
        const prevOrder: number | undefined = orderList.sortList[a.langs[Language.EN].id];
        const currOrder: number | undefined = orderList.sortList[b.langs[Language.EN].id];

        if (
          (!isNaN(prevOrder) && !isNaN(currOrder) && prevOrder < currOrder) ||
          (isNaN(prevOrder) && !isNaN(currOrder))
        ) {
          return -1;
        } else {
          return 1;
        }
      });

      setListOrder(sortedList);
      resetFilters();
      setList(sortedList);
      setSortMode(true);

      return sortedList;
    },
    [orderList]
  );

  return {
    OppositeTeamSort,
    getSortListForOppositeTeam,
    turnOnOppositeTeamSort,
  };
};

export default useOppositeTeamSort;
