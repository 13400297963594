import { ChangeLogTypes } from '../../../../../models/changeLog.model';
import { OptionsPackage, OptionsPackageSpec } from '../../../../../models/optionsLexus.model';
import { ReviewChangeBaseItem } from '../../../../../models/review.model';
import React from 'react';
import OptionsLexusPackageSpecReviewRow from '../components/OptionsLexusPackageRow/reviewRows/OptionsLexusPackageSpecReviewRow';

export const renderSpecReviewRows = (
  option: OptionsPackage,
  revertSpec: (
    option: OptionsPackage,
    spec: OptionsPackageSpec,
    change: ReviewChangeBaseItem
  ) => void,
  hideAccept = false
) => {
  const specItems = option.getSpecChanges();

  return (
    <>
      {specItems.map((spec, specIndex) => (
        <React.Fragment key={spec.uid}>
          {spec.changes.map((change, changeIndex) =>
            specIndex === 0 && changeIndex === 0 ? null : (
              <OptionsLexusPackageSpecReviewRow
                key={change.uid}
                option={option}
                spec={spec}
                change={change}
                isDelete={[
                  ChangeLogTypes.PACKAGE_SPEC_DELETED,
                  ChangeLogTypes.OPTION_SPEC_DELETED,
                ].includes(change.changes.changeType)}
                revertSpec={revertSpec}
                hideAccept={hideAccept}
              />
            )
          )}
        </React.Fragment>
      ))}
    </>
  );
};
