import { action, computed, observable } from 'mobx';
import { sortBy } from '../utils';
import { ReportItem, VehicleModel } from '../models/reports.model';

export class ReportsStore {
  @observable reportType = '';
  @observable allReports: ReportItem[] = [];
  @observable models: VehicleModel[] = [];
  @observable seriesList: string[] = [];
  @observable seriesYearList: string[] = [];
  @observable versionList: string[] = [];
  @observable reverse = true;
  @observable sortField = 'generatedDate';
  loaded = false;

  @computed get sortedReports() {
    const reports = this.allReports
      .slice()
      .sort(sortBy(this.sortField, this.reverse, this.sortField === 'generatedDate'));

    return reports;
  }

  @action reset() {
    this.reportType = '';
    this.allReports = [];
    this.models = [];
    this.seriesList = [];
    this.seriesYearList = [];
    this.versionList = [];
    this.reverse = true;
    this.sortField = 'generatedDate';
  }
}
