import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';

interface RemoveDraftModalProps {
  close: () => void;
  onSubmit: () => void;
}

const RemoveDraftModal = observer(({ close, onSubmit }: RemoveDraftModalProps) => {
  const body = `Are you sure you want to remove Draft? This action cannot be undone.`;

  return (
    <>
      <ModalHeader onClose={close}>Remove Draft</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button
          variant="transparent"
          onClick={() => {
            close();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            close();
            onSubmit();
          }}
        >
          Remove
        </Button>
      </ModalFooter>
    </>
  );
});

export default RemoveDraftModal;
