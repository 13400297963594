import IconTextButton from '../../../../components/IconTextButton';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import { CommonLanguageCategory, CommonLanguageVehicle } from '../../../../models/commonLanguage.model';
import React, { useState } from 'react';
import {
  ActionBar,
  ActionBarFilterButton,
  ActionBarSection,
  Dropdown,
  DropdownContent,
  Modal,
  SearchInput,
} from 'vapi-ui-common';
import ResetModal from '../../../../components/ResetModal';
import CommonLanguageFilter from '../CommonLanguageFilter';

interface ActionBarCommonLanguageProps {
  readOnly?: boolean;
  searchText: string;
  setSearchText: (value: string) => void;
  categories: CommonLanguageCategory[];
  categoryFilters: string[];
  vehicleModels: CommonLanguageVehicle[];
  vehicleFilters: string[];
  setFilters: (categoryFilters: string[], vehicleFilters: string[]) => void;
  onResetDraft: () => void;
  onAddSpec?: () => void;
  getUpdateCount?: () => Promise<number>;
}

const ActionBarCommonLanguage = ({
  readOnly,
  searchText,
  setSearchText,
  categories,
  categoryFilters,
  vehicleModels,
  vehicleFilters,
  setFilters,
  onResetDraft,
  getUpdateCount,
  onAddSpec = () => {},
}: ActionBarCommonLanguageProps) => {
  const [openResetModal, setOpenResetModal] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleOnOpenResetDraft = async () => {
    if (getUpdateCount) {
      const count = await getUpdateCount();
      setUpdateCount(count);
      setOpenResetModal(true);
    }
  };

  return (
    <ActionBar>
      <ActionBarSection>
        <SearchInput value={searchText} onSearch={value => setSearchText(value)} />
        <Dropdown>
          <ActionBarFilterButton onClick={() => setIsComponentVisible(true)}>
            Filter
          </ActionBarFilterButton>
          <DropdownContent ref={ref} open={isComponentVisible}>
            <CommonLanguageFilter
              onClose={() => setIsComponentVisible(false)}
              categories={categories}
              categoryFilters={categoryFilters}
              vehicles={vehicleModels}
              vehicleFilters={vehicleFilters}
              setFilters={setFilters}
            />
          </DropdownContent>
        </Dropdown>
        {!readOnly && <IconTextButton icon="plus" text="Add Spec" onClick={() => onAddSpec()} />}
      </ActionBarSection>
      <ActionBarSection>
        {!readOnly && (
          <IconTextButton icon="undo" text="Reset Draft" onClick={() => handleOnOpenResetDraft()} />
        )}
        <Modal open={openResetModal} onClose={() => setOpenResetModal(false)}>
          <ResetModal
            changeLogLocation="/commonLanguage/changeLog?return=draft"
            close={() => setOpenResetModal(false)}
            pageName="Common Language"
            updateCount={updateCount}
            resetDraft={onResetDraft}
          />
        </Modal>
      </ActionBarSection>
    </ActionBar>
  );
};

export default ActionBarCommonLanguage;
