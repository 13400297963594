import { useCallback, useEffect, useState } from 'react';
import { BnPCategoryItem, BnpCategorySplit } from '../models/buildAndPrice.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../models/vehicleModel.model';
import { getModelApplicabilityMetadataMap } from '../utils/vehicleDataUtils';
import useModelApplicabilityMap, {
  ModelApplicability,
  ModelApplicabilityMap,
  ModelApplicabilityModel,
} from './useModelApplicability';
import useStores from './useStores';

interface UseSplitModelApplicabilityProps {
  category?: BnPCategoryItem;
  split?: BnpCategorySplit;
}

const useSplitModelApplicability = ({ category, split }: UseSplitModelApplicabilityProps) => {
  const {
    vehicleModelsStore: { vehicleModels },
  } = useStores();

  const [splitModelApplicabilityMap, setSplitModelApplicabilityMap] = useState<
    ModelApplicabilityMap
  >({});

  const setGradeIsChecked = (isChecked: boolean, gradeId: string) => {
    setSplitModelApplicabilityMap((pValue: ModelApplicabilityMap) => {
      const pModelApplicability: ModelApplicability = pValue[gradeId];
      const { models } = pModelApplicability;
      const pModelApplicabilityModel: ModelApplicabilityModel[] = Object.values(models);

      const nModelApplicabilityMap: ModelApplicabilityMap = {
        ...pValue,
        [gradeId]: {
          ...pModelApplicability,
          numChecked: isChecked ? pModelApplicabilityModel.length : 0,
          models: pModelApplicabilityModel.reduce(
            (acc, model) => ({ ...acc, [model.model.id]: { ...model, isChecked } }),
            {}
          ),
        },
      };

      return nModelApplicabilityMap;
    });
  };

  const setModelIsChecked = (isChecked: boolean, gradeId: string, modelId: string) => {
    setSplitModelApplicabilityMap((pValue: ModelApplicabilityMap) => {
      const pModelApplicability: ModelApplicability = pValue[gradeId];
      const { numChecked, models } = pModelApplicability;
      const pModelApplicabilityModel: ModelApplicabilityModel = models[modelId];

      const nModelApplicabilityMap: ModelApplicabilityMap = {
        ...pValue,
        [gradeId]: {
          ...pModelApplicability,
          numChecked: isChecked ? numChecked + 1 : numChecked - 1,
          models: {
            ...pModelApplicability.models,
            [modelId]: { ...pModelApplicabilityModel, isChecked },
          },
        },
      };

      return nModelApplicabilityMap;
    });
  };

  const isCheckedModel = useCallback(
    (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => {
      if (!category) {
        return false;
      }

      if (split) {
        return category.applicability[model.id] === split.id;
      }

      switch (category.name) {
        case 'cabs':
          return category.categoryValue === model.getVal('cab');
        case 'beds':
          return category.categoryValue === model.getVal('bed');
        case 'seats':
          return category.categoryValue === model.getVal('seating');
        default:
          return category.categoryValue === model.getVal(category.name);
      }
    },
    [category, split]
  );

  const setCheckedAll = (isChecked: boolean) => {
    setSplitModelApplicabilityMap((pValue: ModelApplicabilityMap) => {
      const modelApplicabilityList: ModelApplicability[] = Object.values(pValue);

      const nModelApplicabilityMap: ModelApplicabilityMap = {
        ...modelApplicabilityList.reduce((map, mApplicability) => {
          const gradeId = mApplicability.grade.id;
          const models: ModelApplicabilityModel[] = Object.values(mApplicability.models);

          return {
            ...map,
            [gradeId]: {
              ...mApplicability,
              numChecked: isChecked ? models.length : 0,
              models: models.reduce(
                (mapModel, model) => ({ ...mapModel, [model.model.id]: { ...model, isChecked } }),
                {}
              ),
            },
          };
        }, {}),
      };

      return nModelApplicabilityMap;
    });
  };

  const {
    isCheckedGrade,
    generateModelApplicabilityMap,
    allCheckedInfo,
  } = useModelApplicabilityMap();

  useEffect(() => {
    const modAppMap = generateModelApplicabilityMap({
      setGradeIsChecked,
      setModelIsChecked,
      isCheckedModel,
      category,
    });
    if (category) {
      const metadataMap = getModelApplicabilityMetadataMap(
        modAppMap,
        category,
        vehicleModels,
        split ? [] : ['default'] // for bnp category splits, set the array for split to ['default', split.id] if you want to only want to show the models the split is already assigned to and the models assigned to the default split on the save modal
      );

      const mAppMap: ModelApplicabilityMap = {};
      Object.entries(metadataMap).forEach(([gradeId, metadata]) => {
        mAppMap[gradeId] = metadata.modelApplicability;
      });
      setSplitModelApplicabilityMap(mAppMap);
    } else {
      setSplitModelApplicabilityMap(modAppMap);
    }
  }, [category, split, isCheckedModel, generateModelApplicabilityMap, vehicleModels]);

  return {
    splitModelApplicabilityMap,
    isCheckedGrade: (gradeId: string) => isCheckedGrade(splitModelApplicabilityMap, gradeId),
    allCheckedInfo: () => allCheckedInfo(splitModelApplicabilityMap),
    setCheckedAll,
  };
};

export default useSplitModelApplicability;
