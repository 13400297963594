import React from 'react';
import styles from './DropdownAdd.module.scss';
import useComponentVisible from '../../hooks/useComponentVisible';
import { Dropdown, DropdownContent } from 'vapi-ui-common';
import IconTextButton from '../IconTextButton';

export interface FeatureAddDropdownProps {
  btnText: string;
  children: JSX.Element | JSX.Element[];
}

const DropdownAdd = ({ btnText, children }: FeatureAddDropdownProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <Dropdown>
      <IconTextButton
        className={styles.iconBtn}
        icon="plus"
        text={btnText}
        onClick={() => setIsComponentVisible(true)}
      />
      <DropdownContent menuClass={styles.dropdownContent} ref={ref} open={isComponentVisible}>
        {children}
      </DropdownContent>
    </Dropdown>
  );
};

export default DropdownAdd;
