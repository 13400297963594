import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ActionBar, ActionBarDivider, IconTextButton, Modal, Spinner } from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import SyncUpdatesPopover from '../../../../components/SyncUpdatesPopover';
import { TableRow, TwoTableWrapper } from '../../../../components/Table';
import SortButton from '../../../../components/sortModule/SortButton';
import SortDropdown from '../../../../components/sortModule/SortDropdown';
import SortModal from '../../../../components/sortModule/SortModal/SortModal';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useDebounce from '../../../../hooks/useDebounce';
import useStores from '../../../../hooks/useStores';
import { IDValueType, KeyValueType } from '../../../../models/common.model';
import {
  ApplicabilityTextLangMap,
  CompareFeatureItem,
  CompareFeatureLangMap,
  CompareGradeApplicabilityMap,
  CompareGradeSettingsCell,
  CompareType,
  HighlightSortList,
} from '../../../../models/compareFeatures.model';
import { FeatureSettings } from '../../../../models/features.model';
import { VDSortableEntity } from '../../../../models/sort.model';
import { BRAND_TDPR, Language } from '../../../../models/user.model';
import { VDTab, VehicleTeam } from '../../../../models/vehicleData.model';
import { tokensXForm } from '../../../../utils/disclaimersUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { getSortPayload, getSortedCopy } from '../../../../utils/sortUtils';
import { syncSpanishUpdates, updateSortList } from '../../../../webservices/vehicleAdminApi';
import {
  addCompareFeature,
  deleteCompareFeature,
  updateCompareFeature,
  updateDoubleSortList,
} from '../../../../webservices/vehicleCompareFeaturesApi';
import ActionBarFiltersSection from '../../components/ActionBarFiltersSection';
import GradeTable from '../../components/GradeTable';
import LeftTable from '../../components/LeftTable';
import { ProductDataControllerProps } from '../../models/controllers.model';
import { displaySyncMessage } from '../models/utils/utils';
import styles from './compareFeature.module.scss';
import CompareFeatureCell from './components/CompareFeatureCell/CompareFeatureCell';
import CompareFeatureFilters from './components/CompareFeatureFilters';
import CompareFeatureHeaderRow from './components/CompareFeatureHeaderRow/CompareFeatureHeaderRow';
import CompareFeatureRowsContainer from './components/CompareFeatureRow/CompareFeatureRowsContainer';
import AddCompareFeatureModal from './components/Modals/AddCompareFeatureModal';
import useOppositeTeamSort from '../../../../hooks/useOppositeTeamSort';

const CompareFeatureController = ({
  team,
  readOnly,
  seriesId,
  year,
  grades,
  versionInfo,
  reloadDraft,
  isPublished,
}: ProductDataControllerProps) => {
  const {
    compareFeatureStore,
    userStore: { brand },
    disclaimersStore: { tokens },
    teamStore,
  } = useStores();

  const { debounce } = useDebounce({ delay: 2000 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortMode, setSortMode] = useState(false);
  const [gradeToggledArr, setGradeToggled] = useState<string[]>([]);
  const [highlightSortMode, setHighlightSortMode] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [sortCategoryModal, setSortCategoryModal] = useState(false);
  const [sortSubCategoryModal, setSortSubCategoryModal] = useState(false);
  const [gradeCellStatusChange, setGradeCellStatusChange] = useState<boolean>(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const disclaimerTokens = tokensXForm(tokens);

  useEffect(() => {
    if (gradeCellStatusChange) {
      setGradeCellStatusChange(false);
    }
  }, [gradeCellStatusChange]);

  useEffect(() => {
    if (grades) {
      compareFeatureStore.reset();
      setIsLoaded(false);
      (async () => {
        try {
          await compareFeatureStore.fetchData(
            brand,
            team,
            seriesId,
            year,
            grades,
            teamStore.team.langPermissions,
            versionInfo
          );
        } catch (e) {
          toast.error('Error loading compare features data');
          console.log(e.message);
        }

        setIsLoaded(true);
      })();
    }
  }, [
    compareFeatureStore,
    brand,
    team,
    seriesId,
    year,
    grades,
    versionInfo,
    teamStore,
    lastUpdated,
  ]);

  useEffect(() => {
    if (
      isLoaded &&
      !compareFeatureStore.filteredCompareFeatures.every(subCat => subCat.subCategory.value)
    ) {
      toast.error('Sub Category is a required field, please input a value');
    }
  }, [compareFeatureStore.filteredCompareFeatures, isLoaded]);

  const {
    OppositeTeamSort,
    getSortListForOppositeTeam,
    turnOnOppositeTeamSort,
  } = useOppositeTeamSort(brand, team, seriesId, year, VDSortableEntity.COMPARE_FEATURES);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = compareFeatureStore.filteredCompareFeatureLangMaps.splice(
      result.source.index,
      1
    );
    compareFeatureStore.filteredCompareFeatureLangMaps.splice(result.destination.index, 0, removed);
    compareFeatureStore.filteredCompareFeatureLangMaps.forEach((item, index: number) => {
      Object.values(item.langs).forEach(compareFeatureItem => {
        compareFeatureItem.sortOrder = index + 1;
      });
    });
    return compareFeatureStore.filteredCompareFeatureLangMaps;
  };

  const showFormFieldError = () => {
    toast.error('Please finish filling out all items of the new compare feature.');
  };

  const addEmptyCompareFeatureLangMap = (type: CompareType) => {
    compareFeatureStore.addItem(type);
  };

  const convertGradeApplicability = (
    gradeApplLangMap: { [k in Language]?: CompareGradeApplicabilityMap },
    featureId: string,
    changedGradeIds?: string[]
  ): CompareGradeSettingsCell[] => {
    if (!grades?.length) {
      return [];
    }
    return grades.map(grade => {
      const gradeId = grade.id;

      const applicabilityTextMap: ApplicabilityTextLangMap = { text: {} };
      const langs = Object.keys(gradeApplLangMap) as Language[];
      langs.forEach(lang => {
        applicabilityTextMap.text[lang] = {
          text: gradeApplLangMap[lang]![gradeId].text || '',
          canEdit: !!compareFeatureStore.langWriteMap[lang]?.canEdit,
          syncValueChange: changedGradeIds && changedGradeIds.includes(gradeId),
        };
      });

      const defaultText =
        gradeApplLangMap[compareFeatureStore.defaultEditLang]![gradeId].text ||
        gradeApplLangMap[compareFeatureStore.gradeApplicabilityLang]![gradeId].text ||
        '';

      return {
        id: gradeId,
        setting:
          gradeApplLangMap[compareFeatureStore.gradeApplicabilityLang]![gradeId].applicability ||
          FeatureSettings.UNDEFINED,
        highlighted: gradeApplLangMap[compareFeatureStore.gradeApplicabilityLang]![gradeId]
          .highlighted,
        defaultText,
        value: grade?.value || '',
        featureId,
        applicabilityTextMap,
        highlightSortOrder: !gradeApplLangMap[compareFeatureStore.gradeApplicabilityLang]![gradeId]
          .highlighted
          ? 0
          : compareFeatureStore.highlightSortList[gradeId]?.sortList[featureId],
      };
    });
  };

  const updateCompareFeatureItem = async (
    compareFeature: CompareFeatureItem,
    compareChangeMessageRequest: boolean,
    lang: string,
    acceptChanges: boolean = false
  ) => {
    try {
      debounce(async () => {
        const response = await trackPromise(
          updateCompareFeature(
            brand,
            team,
            seriesId,
            year,
            lang,
            compareFeature.getPayload(),
            acceptChanges
          )
        );
        if (acceptChanges) {
          compareFeature.changedAttributes = [];
          compareFeature.changedGradeIds = [];
        }
        compareFeature.revId = response.data.revId;
        compareFeature.fieldStatus = response.data.fieldStatus;
        setGradeCellStatusChange(true);
        toast.success(`${langNameMap[lang]} Compare Feature updated successfully`);
        if (compareChangeMessageRequest) {
          toast.success(`Changes have been updated on the ${compareFeature.compareType} Page`);
        }
      }, compareFeature.uid);
    } catch (e) {
      handleErrorResponse(e, 'Feature failed update');
    }
  };

  const addCompareFeatureItem = async (compareFeature: CompareFeatureItem, lang: string) => {
    try {
      debounce(async () => {
        const response = await trackPromise(
          addCompareFeature(brand, team, seriesId, year, lang, compareFeature.getPayload())
        );
        compareFeature.id = response.data.id;
        compareFeature.revId = response.data.revId;
        compareFeature.fieldStatus = response.data.fieldStatus;
        setGradeCellStatusChange(true);
        toast.success('Compare Feature added successfully');
      }, compareFeature.uid);
    } catch (e) {
      handleErrorResponse(e, 'Compare Feature failed add');
    }
  };

  const saveCompareFeatureLangMap = async (
    compareFeatureLangMap: CompareFeatureLangMap,
    compareChangeMessageRequest: boolean = false,
    lang?: string,
    acceptChanges: boolean = false
  ) => {
    if (lang && !compareFeatureStore.langWriteMap[lang as Language]?.canEdit) {
      toast.error(`You do not have permissions to update ${langNameMap[lang]} features.`);
      return;
    }
    const langs = lang ? [lang] : compareFeatureStore.editableLangs;
    const promises: Promise<any>[] = [];
    let numValid = 0;
    for (const lang of langs) {
      const compareFeature = compareFeatureLangMap.langs[lang];
      if (compareFeature.isValid()) {
        numValid++;
        if (compareFeature.revId) {
          promises.push(
            updateCompareFeatureItem(
              compareFeature,
              compareChangeMessageRequest,
              lang,
              acceptChanges
            )
          );
        } else {
          promises.push(addCompareFeatureItem(compareFeature, lang));
        }
      }
    }
    if (!numValid) {
      showFormFieldError();
    } else {
      await Promise.all(promises);
    }
  };

  const deleteCompareFeatureLangMap = async (compareFeatureLangMap: CompareFeatureLangMap) => {
    try {
      const uid = compareFeatureLangMap.langs[compareFeatureStore.defaultLang].uid;
      // only delete the compare features on the backend that the user has write permissions for
      for (const lang of compareFeatureStore.editableLangs) {
        const feature = compareFeatureLangMap.langs[lang];
        if (feature.revId) {
          await trackPromise(deleteCompareFeature(brand, team, seriesId, year, lang, feature.id));
        }
      }
      compareFeatureStore.deleteItem(uid);
      toast.success('Feature deleted sucessfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting feature');
    }
  };

  const copyCompareFeatureLangMap = async (compareFeatureLangMap: CompareFeatureLangMap) => {
    try {
      const copiedCompareFeatureLangMap = compareFeatureStore.copyMap(
        JSON.parse(JSON.stringify(compareFeatureLangMap))
      );
      const compareFeatureId = uuidv4();

      for (const lang of compareFeatureStore.allLangs) {
        copiedCompareFeatureLangMap.langs[lang].id = compareFeatureId;
        if (!copiedCompareFeatureLangMap.langs[lang].isValid()) {
          showFormFieldError();
          return;
        }
      }

      for (const lang of compareFeatureStore.allLangs) {
        const copiedFeature = copiedCompareFeatureLangMap.langs[lang];
        if (compareFeatureStore.langWriteMap[lang]?.canEdit) {
          // if the user has write permissions for this language then we should create the feature on the backend too
          const response = await trackPromise(
            addCompareFeature(brand, team, seriesId, year, lang, copiedFeature.getPayload())
          );
          copiedFeature.revId = response.data.revId;
        }
      }
      const sortPayload = getSortPayload(
        compareFeatureStore.compareFeatureLangMaps.map(
          langMap => langMap.langs[compareFeatureStore.editableLangs[0]]
        )
      );
      await trackPromise(
        updateSortList(brand, team, seriesId, year, VDSortableEntity.COMPARE_FEATURES, sortPayload)
      );
      toast.success('Compare Feature copied successfully');
    } catch (e) {
      handleErrorResponse(e, 'Feature failed copy');
    }
  };

  const unlinkCompareFeatureItem = async (
    compareFeatureLangMap: CompareFeatureLangMap,
    shouldDelete: boolean
  ) => {
    if (shouldDelete) {
      await deleteCompareFeatureLangMap(compareFeatureLangMap);
    } else {
      for (const lang of compareFeatureStore.editableLangs) {
        compareFeatureLangMap.langs[lang].parentId = '';
        await updateCompareFeatureItem(compareFeatureLangMap.langs[lang], true, lang);
      }
    }
  };

  const updateHighlightSortList = async () => {
    try {
      compareFeatureStore.updateHighlightSortList(compareFeatureStore.highlightSortList);
      await trackPromise(
        updateDoubleSortList(
          brand,
          team,
          seriesId,
          year,
          compareFeatureStore.gradeApplicabilityLang,
          {
            doubleSortList: compareFeatureStore.highlightSortList,
          }
        )
      );
      toast.success('Sort List updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Failed to update sort list');
    }
  };

  const filteredCompareFeatureMaps = () => {
    if (!highlightSortMode) {
      return compareFeatureStore.filteredCompareFeatureLangMaps;
    }
    const filterMap: KeyValueType<boolean> = {};
    const filter: CompareFeatureLangMap[] = [];
    gradeToggledArr.forEach(e => {
      compareFeatureStore.filteredCompareFeatureLangMaps.forEach(langMap => {
        for (const lang of compareFeatureStore.allLangs) {
          const item = langMap.langs[lang];
          if (
            !filterMap[item.id] &&
            item.gradeApplicability &&
            item.gradeApplicability[e] &&
            item.gradeApplicability[e].highlighted
          ) {
            filter.push(langMap);
            filterMap[item.id] = true;
            break;
          }
        }
      });
    });

    return filter.filter((value, index) => {
      const duplicate = JSON.stringify(value);
      return (
        index ===
        filter.findIndex(obj => {
          return JSON.stringify(obj) === duplicate;
        })
      );
    });
  };

  const syncUpdates = async () => {
    setIsLoaded(true);
    try {
      const res = await syncSpanishUpdates(brand, teamStore.team.param, seriesId, year);
      displaySyncMessage(res.data.onlyStatusSyncUpdates);

      if (reloadDraft) {
        setIsLoaded(false);
        const teamParam = VehicleTeam.AGENCY_SPANISH;
        const url =
          brand !== BRAND_TDPR
            ? `/vehicleData/draft/${teamParam}/${seriesId}/${year}/EN:${res.data.sourceVersion}|ES:DRAFT?team=${teamParam}&tab=${VDTab.COMPARE_FEATURES}`
            : '';
        reloadDraft(url);
      }
      toast.success('Sync Successful');
    } catch (e) {
      handleErrorResponse(e, 'Error with Sync');
      setIsLoaded(false);
    }
  };

  const onUpdateSortOrder = (
    sortOrder: number,
    gradeId: string,
    targetCompareFeatureId: string
  ) => {
    const copiedSortList: HighlightSortList = JSON.parse(
      JSON.stringify(compareFeatureStore.highlightSortList)
    );
    const gradeSortList = copiedSortList[gradeId].sortList;

    // if sortOrder < 1 then sortOrder = 1
    // if sortOrder > maxSortOrder then sortOrder = maxSortOrder
    const newSortOrder = Math.max(1, Math.min(sortOrder, Object.keys(gradeSortList).length));
    const currentSortOrder = gradeSortList[targetCompareFeatureId];
    if (newSortOrder > currentSortOrder) {
      // if the target compare feature is being moved back in the sort order (i.e. sortOrder is increasing)
      Object.keys(gradeSortList).forEach(compareFeatureId => {
        if (
          compareFeatureId !== targetCompareFeatureId &&
          gradeSortList[compareFeatureId] <= newSortOrder &&
          gradeSortList[compareFeatureId] > currentSortOrder
        ) {
          // if the current compare feature isnt the target, the current compare feature comes before the new sort order, and the current compare feature came after the old sort order => decrease the sortOrder
          gradeSortList[compareFeatureId]--;
        }
      });
    } else if (newSortOrder < currentSortOrder) {
      // if the target compare feature is being moved forward in the sort order
      Object.keys(gradeSortList).forEach(compareFeatureId => {
        if (
          compareFeatureId !== targetCompareFeatureId &&
          gradeSortList[compareFeatureId] >= newSortOrder &&
          gradeSortList[compareFeatureId] < currentSortOrder
        ) {
          // if the current compare feature isnt the target, the current compare feature comes after the new sort order, and the current compare feature came before the old sort order => increase the sort order
          gradeSortList[compareFeatureId]++;
        }
      });
    }
    gradeSortList[targetCompareFeatureId] = newSortOrder;
    compareFeatureStore.highlightSortList = copiedSortList;
  };

  const onSaveSortCategories = async (list: IDValueType[], dataType: VDSortableEntity) => {
    try {
      const newList = compareFeatureStore.combineCategoryEntity(list, dataType);

      const catPayload = getSortPayload(newList);
      await trackPromise(updateSortList(brand, team, seriesId, year, dataType, catPayload));

      let compareFeaturesPayload;
      if (
        dataType === VDSortableEntity.COMPARE_FEATURES_CATEGORIES ||
        dataType === VDSortableEntity.COMPARE_FEATURES_SUBCATEGORIES
      ) {
        const categories =
          dataType === VDSortableEntity.COMPARE_FEATURES_CATEGORIES
            ? newList
            : compareFeatureStore.combineCategoryEntity(
                compareFeatureStore.getDefaultCategories(compareFeatureStore.categoriesMap),
                VDSortableEntity.COMPARE_FEATURES_CATEGORIES
              );
        const subCategories =
          dataType === VDSortableEntity.COMPARE_FEATURES_SUBCATEGORIES
            ? newList
            : compareFeatureStore.combineCategoryEntity(
                compareFeatureStore.getDefaultCategories(compareFeatureStore.subCategoriesMap),
                VDSortableEntity.COMPARE_FEATURES_SUBCATEGORIES
              );
        const compareFeaturesCopy = getSortedCopy<CompareFeatureItem>(
          compareFeatureStore.getDefaultCompareFeatures(compareFeatureStore.compareFeatureLangMaps),
          item => item.subCategory.id,
          subCategories
        );
        // create lookup map of all the category values to their id's
        const catVal2IdMap: KeyValueType<string> = {};
        Object.keys(compareFeatureStore.categoriesMap.categories).forEach(id => {
          const value =
            compareFeatureStore.categoriesMap.categories[id][compareFeatureStore.defaultLang].value;
          catVal2IdMap[value] = id;
        });
        // create lookup map of compareFeature.id to its category id
        const compareViewSpecs: KeyValueType<string> = {};
        compareFeaturesCopy.forEach(item => {
          if (item.compareType === CompareType.Spec && catVal2IdMap[item.category.value]) {
            compareViewSpecs[item.id] = item.category.id;
            item.category.id = catVal2IdMap[item.category.value];
          }
        });
        const compareFeaturesCopy2 = getSortedCopy<CompareFeatureItem>(
          compareFeaturesCopy,
          item => item.category.id,
          categories
        );
        // reset each compareFeature's category id
        compareFeatureStore
          .getDefaultCompareFeatures(compareFeatureStore.compareFeatureLangMaps)
          .forEach(item => {
            if (compareViewSpecs[item.id]) {
              item.category.id = compareViewSpecs[item.id];
            }
          });
        compareFeaturesPayload = getSortPayload(compareFeaturesCopy2);
      }

      if (compareFeaturesPayload) {
        await trackPromise(
          updateSortList(
            brand,
            team,
            seriesId,
            year,
            VDSortableEntity.COMPARE_FEATURES,
            compareFeaturesPayload
          )
        );
        setIsLoaded(false);
        compareFeatureStore.resetFilters();
        await trackPromise(
          compareFeatureStore.fetchData(
            brand,
            team,
            seriesId,
            year,
            grades ?? [],
            teamStore.team.langPermissions,
            versionInfo
          )
        );
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating category sort');
    }
    setIsLoaded(true);
  };

  const onStopSorting = async () => {
    setSortMode(false);
    try {
      const compareFeatureItems = compareFeatureStore.getDefaultCompareFeatures(
        compareFeatureStore.filteredCompareFeatureLangMaps
      );
      const compareFeaturesPayload = getSortPayload(compareFeatureItems);
      await trackPromise(
        updateSortList(
          brand,
          team,
          seriesId,
          year,
          VDSortableEntity.COMPARE_FEATURES,
          compareFeaturesPayload
        )
      );
      setIsLoaded(false);
      await trackPromise(
        compareFeatureStore.fetchData(
          brand,
          team,
          seriesId,
          year,
          grades ?? [],
          teamStore.team.langPermissions,
          versionInfo
        )
      );
      setIsLoaded(true);
    } catch (e) {
      handleErrorResponse(e, 'Error updating feature sort');
    }
  };

  const getActionBarButtons = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];

    if (teamStore.team.allowAddDeleteData && showActionButtons) {
      actionBarButtons.push(
        <IconTextButton
          icon="plus"
          text="Add Compare Feature"
          onClick={() => {
            if (!sortMode && !highlightSortMode && !sortCategoryModal && !sortSubCategoryModal) {
              setOpenAddModal(true);
            }
          }}
        />
      );
      actionBarButtons.push(
        sortMode || highlightSortMode ? (
          <SortButton
            toggled
            onClick={() => {
              setSortMode(false);
              if (highlightSortMode) {
                updateHighlightSortList();
              } else {
                onStopSorting();
              }
              setHighlightSortMode(false);
            }}
          >
            Stop Sorting
          </SortButton>
        ) : (
          <>
            <SortDropdown
              buttonText="Sort"
              list={getSortListForOppositeTeam(teamStore.team.name, [
                'Rows',
                'Categories',
                'Sub Categories',
                'Highlights',
              ])}
              onSelect={value => {
                switch (value) {
                  case 'Rows': {
                    compareFeatureStore.resetFilters();
                    setSortMode(true);
                    break;
                  }
                  case 'Categories': {
                    setSortCategoryModal(true);
                    break;
                  }
                  case 'Sub Categories': {
                    setSortSubCategoryModal(true);
                    break;
                  }
                  case 'Highlights': {
                    compareFeatureStore.updateHighlightSortList(
                      compareFeatureStore.highlightSortList
                    );
                    setGradeToggled(arr => {
                      if (compareFeatureStore.grades[0] !== undefined) {
                        return [...arr, compareFeatureStore.grades[0].id];
                      } else {
                        return [...arr];
                      }
                    });
                    setHighlightSortMode(true);
                    break;
                  }
                  case OppositeTeamSort.APPLY_PRODUCT_SORT:
                  case OppositeTeamSort.APPLY_AGENCY_SORT: {
                    turnOnOppositeTeamSort(
                      filteredCompareFeatureMaps(),
                      compareFeatureStore.resetFilters,
                      compareFeatureStore.setLangMapList,
                      setSortMode
                    );
                    break;
                  }
                }
              }}
            />
          </>
        )
      );
    }

    if (compareFeatureStore.allLangs.length > 1) {
      for (const lang of compareFeatureStore.allLangs) {
        actionBarButtons.push(
          <Checkbox
            id={`${lang}-select-checkbox`}
            checked={!!compareFeatureStore.selectedLangsMap[lang]}
            onChange={() =>
              compareFeatureStore.updateSelectedLangs(
                lang,
                !compareFeatureStore.selectedLangsMap[lang]
              )
            }
          >
            <span>{langNameMap[lang]}</span>
          </Checkbox>
        );
      }
      if (showActionButtons) {
        actionBarButtons.push(
          <SyncUpdatesPopover
            sourceEnVersion={versionInfo.EN?.toString()}
            brand={brand}
            team={teamStore.team.param}
            seriesId={seriesId}
            year={year}
            syncUpdates={syncUpdates}
          />
        );
      }
    }

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderCells = (compareFeatureLangMap: CompareFeatureLangMap, idx: number) => {
    const defaultCompareFeature =
      compareFeatureLangMap.langs[compareFeatureStore.gradeApplicabilityLang];
    const gradeAppLangMap: { [k in Language]?: CompareGradeApplicabilityMap } = {};
    const changedGradeIds: string[] = [];

    for (const lang of compareFeatureStore.allLangs) {
      const compareFeature = compareFeatureLangMap.langs[lang];
      gradeAppLangMap[lang] = compareFeature.gradeApplicability;
      changedGradeIds.push(...compareFeatureLangMap.langs[lang].changedGradeIds);
    }

    const convertedApplicability: CompareGradeSettingsCell[] = convertGradeApplicability(
      // converted values
      gradeAppLangMap,
      defaultCompareFeature.id,
      changedGradeIds
    );

    return convertedApplicability.map((grade, index) => (
      <CompareFeatureCell
        highlightSortMode={highlightSortMode}
        gradeToggledArr={gradeToggledArr}
        grade={grade}
        index={index}
        numApplicability={convertedApplicability.length}
        defaultCompareFeature={defaultCompareFeature}
        idx={idx}
        onUpdateSortOrder={onUpdateSortOrder}
        readOnly={readOnly}
        changedGradeIds={changedGradeIds}
        compareFeatureLangMap={compareFeatureLangMap}
        saveCompareFeatureLangMap={saveCompareFeatureLangMap}
        setGradeCellStatusChange={setGradeCellStatusChange}
      />
    ));
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly={readOnly}
          searchText={compareFeatureStore.searchText}
          onSearchTextChange={text => {
            compareFeatureStore.onFilter(() => (compareFeatureStore.searchText = text));
          }}
          renderButtons={getActionBarButtons(!readOnly)}
          renderFilter={onClose => (
            <CompareFeatureFilters
              onClose={onClose}
              categories={compareFeatureStore
                .getDefaultCategories(compareFeatureStore.categoriesMap)
                .map(cat => cat.value)}
              categoryFilters={compareFeatureStore.categoryFilters}
              setCategoryFilters={categoryFilters =>
                compareFeatureStore.onFilter(
                  () => (compareFeatureStore.categoryFilters = categoryFilters)
                )
              }
              subCategories={compareFeatureStore
                .getDefaultCategories(compareFeatureStore.subCategoriesMap)
                .map(subCat => subCat.value)}
              subCategoryFilters={compareFeatureStore.subCategoryFilters}
              setSubCategoryFilters={subCategoryFilters =>
                compareFeatureStore.onFilter(
                  () => (compareFeatureStore.subCategoryFilters = subCategoryFilters)
                )
              }
              isInProgressFilter={compareFeatureStore.isInProgressFilter}
              setIsInProgressFilter={value =>
                compareFeatureStore.onFilter(() => (compareFeatureStore.isInProgressFilter = value))
              }
              isPublished={isPublished}
              isSyncUpdateFilter={compareFeatureStore.isSyncUpdateFilter}
              setIsSyncUpdateFilter={value =>
                compareFeatureStore.onFilter(() => (compareFeatureStore.isSyncUpdateFilter = value))
              }
              isReviewNotesFilter={compareFeatureStore.isReviewNotesFilter}
              setIsReviewNotesFilter={
                team === VehicleTeam.AGENCY_TEAM
                  ? value =>
                      compareFeatureStore.onFilter(
                        () => (compareFeatureStore.isReviewNotesFilter = value)
                      )
                  : undefined
              }
            />
          )}
        />
      </ActionBar>
      <TwoTableWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <LeftTable>
            <CompareFeatureHeaderRow
              readOnly={readOnly}
              onSort={compareFeatureStore.onSort}
              sortMode={sortMode}
              setLastUpdated={setLastUpdated}
            />
            <CompareFeatureRowsContainer
              compareFeatureLangMaps={filteredCompareFeatureMaps()}
              readOnly={readOnly}
              sortMode={sortMode}
              featureCategoriesMap={compareFeatureStore.featureCategoriesMap}
              featureSubCategoriesMap={compareFeatureStore.featureSubCategoriesMap}
              saveCompareFeatureLangMap={saveCompareFeatureLangMap}
              deleteCompareFeatureLangMap={deleteCompareFeatureLangMap}
              copyCompareFeatureLangMap={copyCompareFeatureLangMap}
              specCategoriesMap={compareFeatureStore.specCategoriesMap}
              specTypesMap={compareFeatureStore.specTypesMap}
              unlinkCompareFeatureItem={unlinkCompareFeatureItem}
              disclaimerTokens={team === VehicleTeam.AGENCY_TEAM ? disclaimerTokens : undefined}
              setGradeCellStatusChange={setGradeCellStatusChange}
            />
          </LeftTable>
        </DragDropContext>
        <GradeTable
          grades={grades!}
          highlightSortMode={highlightSortMode}
          gradeToggledArr={gradeToggledArr}
          onSelectGrade={grade => {
            setGradeToggled(arr => {
              let newToggledArr = arr;
              const toggledGradeIndex = newToggledArr.indexOf(grade);
              if (toggledGradeIndex >= 0) {
                if (arr.length === 1) {
                  return [...arr];
                }
                newToggledArr.splice(toggledGradeIndex, 1);
              } else {
                newToggledArr.push(grade);
              }
              return [...newToggledArr];
            });
          }}
          headerStyle={{ top: 0 }}
          renderRows={() => (
            <>
              {filteredCompareFeatureMaps().map((compareFeatureMap, idx) => (
                <React.Fragment key={compareFeatureMap.langs[compareFeatureStore.defaultLang].uid}>
                  <TableRow
                    className={cx(
                      styles.flex,
                      compareFeatureStore.allLangs.filter(
                        lang => compareFeatureStore.selectedLangsMap[lang]
                      ).length > 1
                        ? styles.multiLang
                        : styles.singleLang
                    )}
                    rowHeight={compareFeatureStore.getRowHeight(compareFeatureMap)}
                  >
                    {renderCells(compareFeatureMap, idx)}
                  </TableRow>
                </React.Fragment>
              ))}
            </>
          )}
        />
      </TwoTableWrapper>
      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
        <AddCompareFeatureModal
          close={() => setOpenAddModal(false)}
          onAddCompareFeature={type => addEmptyCompareFeatureLangMap(type)}
        />
      </Modal>
      <SortModal
        open={sortCategoryModal}
        onClose={() => setSortCategoryModal(false)}
        onSave={list => onSaveSortCategories(list, VDSortableEntity.COMPARE_FEATURES_CATEGORIES)}
        idValueList={compareFeatureStore.filtedSortFeatures('category')}
        headerText="Sort Categories"
      />
      <SortModal
        open={sortSubCategoryModal}
        onClose={() => setSortSubCategoryModal(false)}
        onSave={list => onSaveSortCategories(list, VDSortableEntity.COMPARE_FEATURES_SUBCATEGORIES)}
        idValueList={compareFeatureStore.filtedSortFeatures('subCategory')}
        headerText="Sort Sub Categories"
      />
    </>
  );
};

export default observer(CompareFeatureController);
