import IconTextButton from '../../../../components/IconTextButton';
import useStores from '../../../../hooks/useStores';
import React from 'react';
import { ActionBar, ActionBarSection, SearchInput } from 'vapi-ui-common';
import styles from './actionBarSeriesManager.module.scss';

interface ActionBarSeriesManagerProps {
  onShowSeriesManager: () => void;
}

const ActionBarSeriesManager = ({ onShowSeriesManager }: ActionBarSeriesManagerProps) => {
  const { vehicleDataStore } = useStores();

  return (
    <ActionBar>
      <ActionBarSection>
        <SearchInput onSearch={value => (vehicleDataStore.searchText = value)} />
        <IconTextButton
          icon="plus"
          text="Add Vehicle(s) to Dashboard"
          className={styles.addVehicleCta}
          smallIcon
          onClick={() => onShowSeriesManager()}
        />
      </ActionBarSection>
    </ActionBar>
  );
};

export default ActionBarSeriesManager;
