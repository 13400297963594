import React from 'react';
import { FilterTabColumn, FilterTabColumnList } from 'vapi-ui-common';
import { ChecklistItem } from '../../hooks/useChecklist';
import Checkbox from '../Checkbox';

export interface ICategoryFiltersColumn {
  isAllSelected: boolean;
  selectAll: (selected: boolean) => void;
  checklist: ChecklistItem[];
  selectItem: (item: ChecklistItem, selected: boolean) => void;
}

const CategoryFiltersColumn = ({
  isAllSelected,
  selectAll,
  checklist,
  selectItem,
}: ICategoryFiltersColumn) => {
  return (
    <FilterTabColumn data-testid="category-filters-column">
      <Checkbox
        id="AllCategories"
        checked={isAllSelected}
        onChange={e => selectAll(e.currentTarget.checked)}
      >
        All Categories
      </Checkbox>
      <FilterTabColumnList>
        {checklist.map(item => (
          <li key={item.name}>
            <Checkbox
              id={`chboxCat${item.name}`}
              checked={item.selected}
              onChange={e => selectItem(item, e.currentTarget.checked)}
            >
              {item.name}
            </Checkbox>
          </li>
        ))}
      </FilterTabColumnList>
    </FilterTabColumn>
  );
};

export default CategoryFiltersColumn;
