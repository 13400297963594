import { HeaderRow, LeftTableTH, Thead } from '../../../../../../components/Table';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { AcceptApplyHeaderCell } from '../../../../../../routes/vehicleData/components/tableCells/AcceptApplyCell';
import useStores from '../../../../../../hooks/useStores';

type OptionsLexusHeaderRowProps = {
  onSort?: (field: string) => void;
  hideAccept?: boolean;
};
const OptionsLexusHeaderRow = ({ onSort = () => {}, hideAccept }: OptionsLexusHeaderRowProps) => {
  const {
    tableSizeStore: { tableRowHeight },
  } = useStores();

  return (
    <Thead>
      <HeaderRow
        style={{
          height: `${tableRowHeight}px`,
        }}
      >
        <LeftTableTH onClick={() => onSort('name')}>Name</LeftTableTH>
        <LeftTableTH onClick={() => onSort('code')}>Code</LeftTableTH>
        <LeftTableTH>TOMS</LeftTableTH>
        <LeftTableTH>SMS</LeftTableTH>
        <LeftTableTH>Specs</LeftTableTH>
        {/* Action Buttons */}
        <LeftTableTH></LeftTableTH>
        <LeftTableTH>Required</LeftTableTH>
        <LeftTableTH>Conflicts</LeftTableTH>
        {/* Changes */}
        <LeftTableTH>Changes</LeftTableTH>
        {/* Accept/Apply */}
        <AcceptApplyHeaderCell hideAccept={hideAccept} />
      </HeaderRow>
    </Thead>
  );
};

export default observer(OptionsLexusHeaderRow);
