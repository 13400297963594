import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../../../components/Spinner';
import useStores from '../../../../hooks/useStores';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import {
  colorGradeXform,
  colorsExteriorReviewItemXForm,
  colorsInteriorReviewItemXForm,
} from '../../../../utils/colorUtils';
import {
  getReviewExteriorColorsToyota,
  getReviewInteriorColorsToyota,
} from '../../../../webservices/vehicleColorsApi';
import ColorsExteriorReview from './ColorsExteriorReview';
import ColorsInteriorReview from './ColorsInteriorReview';
import { toGqlBrand, toGqlFilter, toGqlTeam } from '../../../../utils/graphqlUtils';
import { Status } from 'vapi-ui-common';

const ColorsReview = ({
  team,
  seriesId,
  year,
  version,
  versionInfo,
  vehicleModels,
  changeLogLink = '',
}: ProductDataControllerProps) => {
  const {
    colorsReviewStore,
    colorsExteriorReviewStore,
    colorsInteriorReviewStore,
    teamStore,
    vehicleModelsStore,
    userStore: { brand },
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    colorsExteriorReviewStore.reset();
    colorsInteriorReviewStore.reset();
    colorsReviewStore.reset();

    (async () => {
      try {
        const [reviewInteriorColorsResponse, reviewExteriorColorsResponse] = await Promise.all([
          getReviewInteriorColorsToyota({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId,
            modelYear: Number(year),
            filter: toGqlFilter(version ?? Status.DRAFT),
          }),
          getReviewExteriorColorsToyota({
            brand: toGqlBrand(brand),
            team: toGqlTeam(teamStore.team.param),
            seriesId,
            modelYear: Number(year),
            filter: toGqlFilter(version ?? Status.DRAFT),
          }),
        ]);

        colorsReviewStore.colorGradeItems = colorGradeXform(
          reviewInteriorColorsResponse,
          vehicleModels,
          vehicleModelsStore.grades
        );
        const { map: interiorMap } = colorsInteriorReviewItemXForm(
          reviewInteriorColorsResponse,
          colorsReviewStore.colorGradeItems,
          vehicleModelsStore.grades,
          vehicleModelsStore.vehicleModels
        );
        colorsInteriorReviewStore.map = interiorMap;

        const { map: exteriorMap } = colorsExteriorReviewItemXForm(
          reviewExteriorColorsResponse,
          colorsReviewStore.colorGradeItems,
          vehicleModelsStore.grades
        );
        colorsExteriorReviewStore.map = exteriorMap;
      } catch (e) {
        console.log(e);
        toast.error('Error loading colors review data');
      }
      setIsLoaded(true);
    })();
  }, [
    colorsReviewStore,
    colorsExteriorReviewStore,
    colorsInteriorReviewStore,
    brand,
    vehicleModels,
    seriesId,
    teamStore,
    version,
    year,
    vehicleModelsStore,
  ]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      {colorsReviewStore.selectedTab === 'Exterior' && (
        <ColorsExteriorReview
          team={team}
          seriesId={seriesId}
          year={year}
          version={version}
          versionInfo={versionInfo}
          vehicleModels={vehicleModels}
          changeLogLink={changeLogLink}
        />
      )}
      {colorsReviewStore.selectedTab === 'Interior' && (
        <ColorsInteriorReview
          team={team}
          seriesId={seriesId}
          year={year}
          version={version}
          versionInfo={versionInfo}
          vehicleModels={vehicleModels}
          changeLogLink={changeLogLink}
        />
      )}
    </>
  );
};

export default observer(ColorsReview);
