import React from 'react';
import { FlexContainer } from 'vapi-ui-common';
import styles from '../../../../../../components/ChangeLogTable/ChangeLogChanges/changeLogChanges.module.scss';
import { ChangeLogItem } from '../../../../../../models/changeLog.model';
import KeyFeaturesChangeLogChange from './KeyFeaturesChangeLogChange';

interface Props {
  log: ChangeLogItem;
}

const KeyFeaturesChangeLog = ({ log }: Props) => {
  const { applicabilityChanges } = log;
  if (!applicabilityChanges) return null;

  return (
    <>
      {log.applicabilityChanges.map((change, index) => (
        <FlexContainer key={index}>
          <span className={styles.changeModelTitle}>{change.title}: </span>
          <KeyFeaturesChangeLogChange change={change} />
        </FlexContainer>
      ))}
    </>
  );
};

export default KeyFeaturesChangeLog;
