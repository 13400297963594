import { action, observable } from 'mobx';
import { VersionInfo } from '../models/spanishDisclaimers.model';
import { getVersionInfo } from '../webservices/disclaimersApi';

export class SpanishDisclaimersStore {
  @observable versionInfo = {} as VersionInfo;

  @action fetchData = async (brand: string, region: string) => {
    this.versionInfo = await getVersionInfo(brand, region);
  };

  @action reset() {
    this.versionInfo = {} as VersionInfo;
  }
}
