import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ActionButton, ConfirmModal, Modal, NotesPopover } from 'vapi-ui-common';
import Checkbox from '../../../../../../components/Checkbox';
import MultiLimitedData from '../../../../../../components/MultiLimitedData';
import { TableRowBase } from '../../../../../../components/Table/components/TableRow/TableRow';
import { LIMITED_DATA_STATUS } from '../../../../../../constants/vehicleData/VDConstants';
import useStores from '../../../../../../hooks/useStores';
import { IDValueType, KeyValueType } from '../../../../../../models/common.model';
import { RefItem } from '../../../../../../models/refItem.model';
import { SeriesSettingsItem } from '../../../../../../models/seriesSettings.model';
import { BRAND_TDPR, BRAND_TOYOTA, Language } from '../../../../../../models/user.model';
import { SeriesCategories, VehicleTeam } from '../../../../../../models/vehicleData.model';
import {
  UpdateModelStatusRequest,
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../models/vehicleModel.model';
import {
  GO_LIVE_DATE_TIME,
  GoLiveDateTime,
  goLiveDateTimeMap,
} from '../../../../../../utils/dateUtils';
import { isModelValid } from '../../../../../../utils/modelsUtils';
import SyncTdPRButton from '../../../../components/SyncTdPRButton/SyncTdPRButton';
import styles from '../../modelsModal.module.scss';
import EditGoLiveDateModal from '../EditGoLiveDateModal';
import RemoveModelModal from '../RemoveModelModal';
import ModelsEditPanel from './ModelsModalEditPanel';

interface ModelsRowProps {
  brand: string;
  index: number;
  category: SeriesCategories;
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  grades: RefItem[];
  fuelTypes: IDValueType[];
  canAddDelete?: boolean;
  canCopy?: boolean;
  canDeleteCopiedModels?: boolean;
  onShow?: (brand: string) => void;
  onSave?: (
    modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>
  ) => void;
  onDelete?: (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => void;
  onCopy?: (
    modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>
  ) => void;
  onAddGrade?: (grade: string) => void;
  onUpdateGrade?: (gradeId: string, gradeValue: string) => void;
  readOnly?: boolean;
  seriesName: string;
  seriesSettings: SeriesSettingsItem[];
  canEditGoLiveDate: boolean;
  compareModel: (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => void;
  editableLanguages: Language[];
  updateFieldStatus?: (payload: UpdateModelStatusRequest) => void;
}

const ModelsRow = ({
  brand,
  index,
  category,
  model,
  grades,
  fuelTypes,
  canAddDelete,
  canCopy,
  canDeleteCopiedModels,
  onShow = () => void 0,
  onCopy = () => void 0,
  onSave = () => void 0,
  onDelete = () => void 0,
  onAddGrade = () => void 0,
  onUpdateGrade = () => void 0,
  readOnly,
  seriesName,
  seriesSettings,
  canEditGoLiveDate,
  compareModel,
  editableLanguages,
  updateFieldStatus,
}: ModelsRowProps) => {
  const {
    seriesSettingsStore,
    teamStore,
    vehicleModelsStore: { defaultLanguage },
    modelTabStore: { getLangVehicleModel },
    userStore: { langPermissions, isTdpr },
  } = useStores();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [isIconShowing, setisIconShowing] = useState(model.show);
  const [removeModel, setRemoveModel] = useState(false);
  const [editGoLiveDateModalOpen, setEditGoLiveDateModalOpen] = useState(false);
  const [confirmEditModal, setConfirmEditModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const defaultModel = getLangVehicleModel(model, defaultLanguage);
  const timePicker = process.env.REACT_APP_GO_LIVE_TIME === 'true' && teamStore.team.showTimePicker;

  const handleOnSave = (
    modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>
  ) => {
    if (isCopy) {
      onCopy(modelLangMap);
    } else {
      onSave(modelLangMap);
    }
    setIsEditOpen(false);
  };

  const handleOnAddGrade = (grade: string) => {
    onAddGrade(grade);
  };

  const handleOnUpdateGrade = (id: string, val: string) => {
    onUpdateGrade(id, val);
  };

  const handleOnDelete = () => {
    onDelete(model);
  };

  const handleOnCopy = () => {
    setIsCopy(true);
    setIsEditOpen(true);
  };

  const handleOnEdit = () => {
    setIsCopy(false);
    setIsEditOpen(!isEditOpen);
  };

  const handleOnShow = () => {
    setisIconShowing(!model.show);
    onShow(brand);
  };

  const renderKatashikis = (katashiki: string) => {
    const katashikis = katashiki?.split('|') || [''];
    return (
      <>
        {katashikis.map((val, index) => (
          <p key={index}>{val}</p>
        ))}
      </>
    );
  };

  const setGoLiveDate = (goLiveDate: string) => {
    let isValid = true;
    const modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>> = {};
    for (const lang of editableLanguages) {
      const langModel = getLangVehicleModel(model, lang);
      if (isModelValid(langModel, langModel.getPayload(brand), brand)) {
        modelLangMap[lang] = langModel;
      } else {
        isValid = false;
        break;
      }
    }

    if (isValid) {
      (Object.keys(modelLangMap) as Language[]).forEach(lang => {
        const langModel = modelLangMap[lang]!;
        langModel.updateDynamicProps({ goLiveDate });
      });
      onSave(modelLangMap);
    } else {
      toast.error('Please fill out all the required fields.');
    }
  };

  const goLiveDate = (): Date | null => {
    const gld = model.getVal('goLiveDate');
    return gld?.length ? new Date(gld) : null;
  };

  const goLiveDateText = (): JSX.Element | string => {
    const gld = goLiveDate();
    if (gld) {
      const time: GoLiveDateTime = goLiveDateTimeMap[gld.getHours() as GO_LIVE_DATE_TIME];
      return (
        <>
          <span>{gld.toLocaleDateString()}</span>
          {timePicker && <span className={styles.zone}>{`(${time.text} CST)`}</span>}
        </>
      );
    }

    const noGoLiveDateText = readOnly || !canEditGoLiveDate ? '' : 'Set Go Live Date';
    return noGoLiveDateText;
  };

  const isGoLiveDateInPast = () => {
    const gld = goLiveDate();
    if (gld != null) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      if (gld < now) {
        return true;
      }
    }
    return false;
  };

  const canClickGoLiveDate = (): boolean => {
    return !readOnly && canEditGoLiveDate;
  };

  const goLiveDateClass = (): string => {
    if (readOnly || !canEditGoLiveDate) {
      return isGoLiveDateInPast() ? styles.publishedPastGoLiveDate : styles.publishedGoLiveDate;
    }
    return styles.goLiveDate;
  };

  const isCodeRed = (model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>) => {
    return seriesSettingsStore.codeRedFuelTypes[model.getVal('fuelType')?.id] ?? false;
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    for (const lang of teamStore.team.languages) {
      if (!!langPermissions[lang]?.canEdit) {
        changed.push(...getLangVehicleModel(model, lang).getVal('changedAttributes'));
      }
    }
    return changed;
  };

  const mdlMap = () => {
    const modelMap: KeyValueType<VehicleModelItem<VehicleModelToyota | VehicleModelLexus>> = {};
    const id = uuidv4();
    teamStore.team.languages.forEach(lang => {
      const langMdl = getLangVehicleModel(model, lang);
      modelMap[lang] = isCopy ? langMdl.makeCopy(isTdpr) : langMdl;
      if (isCopy) {
        // if youre copying a model then you want all langs to have the same id
        modelMap[lang].id = id;
      }
    });
    return modelMap;
  };

  const getFieldStatusArray = () => {
    if (!model.getVal('fieldStatus')) {
      return [];
    }

    const list = Object.keys(model.getVal('fieldStatus')).reduce(
      (acc: LIMITED_DATA_STATUS[], key: string) => {
        const val = (model.getVal('fieldStatus') as any)[key];

        if (
          ((key === 'cab' || key === 'bed') && category !== SeriesCategories.TRUCKS_SUVS) ||
          (key === 'seating' && category !== SeriesCategories.LARGE_SEDANS_VANS)
        ) {
          return acc;
        }

        if (
          val === LIMITED_DATA_STATUS.READY_TO_PUBLISH ||
          val === LIMITED_DATA_STATUS.IN_PROGRESS ||
          val === LIMITED_DATA_STATUS.PUBLISHED
        ) {
          acc.push(val);
        }

        return acc;
      },
      []
    );

    return list;
  };

  const fromTMNA = defaultModel.getVal('fromTMNA');
  const displayLimitedDataStatus =
    teamStore.team.showLimitedData && process.env.REACT_APP_LIMITED_DATA === 'true';

  return (
    <Draggable draggableId={model.uid} index={index}>
      {(provided, snapshot) => (
        <>
          <TableRowBase
            zebra
            hoverShadow
            className={cx(styles.modelRow, styles.modelDrawerWrapper)}
            innerRef={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
          >
            <td className={styles.publishCol}>
              <div
                className={cx({
                  [styles.container]: isTdpr && !readOnly,
                  [styles.hasChanges]: isTdpr && !readOnly && fromTMNA,
                  [styles.hasNotChanges]: isTdpr && !readOnly && !fromTMNA,
                })}
              >
                {!readOnly && fromTMNA && (
                  <SyncTdPRButton
                    id={model.id}
                    changedAttributes={changedAttributes()}
                    onClick={() => {
                      compareModel(model);
                    }}
                  />
                )}
                <Checkbox
                  id={`donotpublish-${model.uid}`}
                  className={styles.isPublishableChbox}
                  disabled={readOnly || isCodeRed(model) || !teamStore.team.allowCodeRed}
                  defaultChecked={model.getVal('isNotPublishable') || isCodeRed(model)}
                  onChange={e => {
                    const modelLangMap: KeyValueType<VehicleModelItem<
                      VehicleModelLexus | VehicleModelToyota
                    >> = {};
                    for (const lang of editableLanguages) {
                      const langModel = getLangVehicleModel(model, lang);
                      langModel.updateDynamicProps({
                        ...langModel.getModelPropValues(),
                        isNotPublishable: e.currentTarget.checked,
                      });
                      modelLangMap[lang] = langModel;
                    }
                    handleOnSave(modelLangMap);
                  }}
                />
              </div>
            </td>
            <td className={styles.previewCol}>
              <ActionButton onClick={handleOnShow} icon={isIconShowing ? 'preview' : 'nopreview'} />
            </td>
            <td className={styles.trimCol}>
              {isTdpr ? model.getVal('tdprCode') : model.getVal('code')}
            </td>
            <td className={styles.descriptionCol}>
              {`${model.getVal('grade').value} ${model.getVal('description')}`}
              {model.isHybrid() && <FontAwesomeIcon className={styles.hybrid} icon={faFlag} />}
            </td>
            <td className={styles.fieldStatusCol}>
              <MultiLimitedData
                statuses={getFieldStatusArray()}
                hideLimitedDataStatus={!displayLimitedDataStatus}
              />
            </td>
            {(brand === BRAND_TOYOTA || brand === BRAND_TDPR) && (
              <td className={styles.goLiveDateCol}>
                <div
                  onClick={() => {
                    if (canClickGoLiveDate() && !isGoLiveDateInPast()) {
                      setEditGoLiveDateModalOpen(true);
                    } else if (canClickGoLiveDate() && isGoLiveDateInPast()) {
                      setConfirmEditModal(true);
                    }
                  }}
                  className={goLiveDateClass()}
                >
                  {goLiveDateText()}
                </div>
                <Modal
                  open={editGoLiveDateModalOpen}
                  onClose={() => setEditGoLiveDateModalOpen(false)}
                >
                  <EditGoLiveDateModal
                    headerText="Set Go Live Date"
                    close={() => setEditGoLiveDateModalOpen(false)}
                    onSubmit={(goLiveDate: string) => {
                      setGoLiveDate(goLiveDate);
                    }}
                    currentGoLiveDate={goLiveDate()}
                    timePicker={timePicker}
                  />
                </Modal>
                <ConfirmModal
                  headerText="Edit Live Date"
                  bodyText="This model is already published and live, are you sure you want to change the date?"
                  confirmButtonText={'Confirm'}
                  open={confirmEditModal}
                  onClose={() => setConfirmEditModal(false)}
                  onConfirm={() => setEditGoLiveDateModalOpen(true)}
                />
              </td>
            )}

            <td className={styles.katashikiCol}>{renderKatashikis(model.getVal('katashiki'))}</td>
            <td className={styles.ctaCol}>
              {!readOnly && (
                <>
                  {model.getVal('rejectNotes') && (
                    <NotesPopover
                      notes={model.getVal('rejectNotes')}
                      icon="purpleCircle"
                      iconText=""
                      readOnly
                      handleUpdateItem={() => {}}
                      popoverClass={styles.rejectNotesPopover}
                    />
                  )}
                  <Modal open={removeModel} onClose={() => setRemoveModel(false)}>
                    <RemoveModelModal
                      close={() => setRemoveModel(false)}
                      onSubmit={() => handleOnDelete()}
                    />
                  </Modal>
                  {(canAddDelete || (canDeleteCopiedModels && model.getVal('sourceId'))) && (
                    <ActionButton icon="trash" onClick={() => setRemoveModel(true)} />
                  )}
                  {(canAddDelete || canCopy) && (
                    <ActionButton icon="copy" onClick={() => handleOnCopy()} />
                  )}
                  <ActionButton
                    className={cx({ [styles.upArrow]: isEditOpen })}
                    icon={isEditOpen ? 'arrowUp' : 'arrowDown'}
                    onClick={() => handleOnEdit()}
                  />
                  {teamStore.team.name !== VehicleTeam.AGENCY_SPANISH && (
                    <ActionButton icon="arrows" {...provided.dragHandleProps} />
                  )}
                </>
              )}
              {readOnly && (
                <ActionButton
                  icon={showDetails ? 'arrowDown' : 'arrowRight'}
                  onClick={() => setShowDetails(!showDetails)}
                />
              )}
            </td>
          </TableRowBase>

          {isEditOpen && (
            <ModelsEditPanel
              brand={brand}
              seriesName={seriesName}
              category={category}
              modelMap={mdlMap()}
              grades={grades}
              fuelTypes={fuelTypes}
              onAddGrade={
                !teamStore.team.canAddFromDropdown ? undefined : grade => handleOnAddGrade(grade)
              }
              onUpdateGrade={(id, val) => handleOnUpdateGrade(id, val)}
              onClose={() => setIsEditOpen(false)}
              onSave={handleOnSave}
              seriesSettings={seriesSettings}
              compareModel={compareModel}
              fieldStatus={model.getVal('fieldStatus')}
              updateFieldStatus={updateFieldStatus}
            />
          )}

          <ModelsEditPanel
            readOnly
            rowClass={showDetails ? '' : styles.hide}
            brand={brand}
            seriesName={seriesName}
            category={category}
            modelMap={mdlMap()}
            grades={grades}
            fuelTypes={fuelTypes}
            onAddGrade={
              !teamStore.team.canAddFromDropdown ? undefined : grade => handleOnAddGrade(grade)
            }
            onUpdateGrade={(id, val) => handleOnUpdateGrade(id, val)}
            onClose={() => setIsEditOpen(false)}
            onSave={handleOnSave}
            seriesSettings={seriesSettings}
            compareModel={compareModel}
            fieldStatus={model.getVal('fieldStatus')}
            updateFieldStatus={updateFieldStatus}
          />
        </>
      )}
    </Draggable>
  );
};

export default observer(ModelsRow);
