export enum VDSortableEntity {
  FEATURES = 'features',
  FEATURES_CATEGORIES = 'features-categories',
  FEATURES_SUBCATEGORIES = 'features-subcategories',
  SPECS = 'specs',
  SPECS_SPECTYPE = 'specs-spectype',
  SPECS_CATEGORIES = 'specs-categories',
  OPTIONS = 'options',
  OPTIONS_CATEGORIES = 'options-categories',
  PACKAGES = 'packages',
  COLORS_EXTERIOR = 'colors-exterior',
  COMPARE_FEATURES = 'compareFeatures',
  COMPARE_FEATURES_CATEGORIES = 'compareFeatures-categories',
  COMPARE_FEATURES_SUBCATEGORIES = 'compareFeatures-subcategories',
}

export interface ISortList {
  sortList: { [key: string]: number };
}
