import { HeaderCell } from '../../../../../components/Table';
import React from 'react';
import styles from './acceptApplyCell.module.scss';

type AcceptApplyHeaderCellProps = {
  hideAccept?: boolean;
  rowSpan?: number;
};

export const AcceptApplyHeaderCell = ({ hideAccept, rowSpan }: AcceptApplyHeaderCellProps) => {
  return (
    <>
      {!hideAccept && (
        <HeaderCell cellClassName={styles.acceptApplyHeaderCell} rowSpan={rowSpan}>
          Accept?
        </HeaderCell>
      )}
      <HeaderCell cellClassName={styles.acceptApplyHeaderCell} rowSpan={rowSpan}>
        Apply?
      </HeaderCell>
    </>
  );
};
