import React from 'react';

interface NumberFormatProps {
  dollar?: boolean;
  value: string;
}

const NumberFormat = (props: NumberFormatProps) => {

  const { dollar, value } = props;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formattedValue = value ? formatter.format(Number(value)).replace(/\D00$/, '') : '';

  return (
    <>
      { dollar && <sup>$</sup> }
      {formattedValue}
    </>
  );
};

export default NumberFormat;
