import { action, observable } from 'mobx';
import { convertToRichTextObject } from 'vapi-ui-common';
import {
  OptionsPackage,
  OptionsPackageSpec,
  OptionsPackageVariety,
  OptionsTabType,
  VDOptionLexusBase,
} from '../../models/optionsLexus.model';
import { VehicleTeam } from '../../models/vehicleData.model';
import {
  VehicleModel,
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelPropsLexus,
  VehicleModelPropsToyota,
  VehicleModelToyota,
} from '../../models/vehicleModel.model';
import { isSortReverse, sortBy, toLowerCase } from '../../utils';
import { optionsLexusXForm, optionsOptionLexusXForm } from '../../utils/optionsLexusUtils';
import { mapEmptyModels, mapEmptyValueModels } from '../../utils/vehicleDataUtils';
import { getOptions } from '../../webservices/vehicleOptionsApi';
import { toGqlBrand, toGqlFilter, toGqlTeam } from '../../utils/graphqlUtils';
import { Language, Status } from '../../gql/generated';

class OptionsLexusStore {
  options: OptionsPackage[] = [];
  tab = 'packages';
  reverseSort = false;
  sortField = 'id';
  searchText = '';
  isInProgressFilter = false;
  isReviewNotesFilter = false;
  @observable showTooltips = false;
  @observable filteredOptions: OptionsPackage[] = [];
  @observable viewModelCodes = false;

  @action fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    vehicleModels: VehicleModelItem<
      VehicleModel<VehicleModelPropsLexus> | VehicleModel<VehicleModelPropsToyota>
    >[],
    optionsTab: OptionsTabType,
    setDataInOptionsStore: boolean,
    version?: string
  ) => {
    const optionsResponse = await getOptions<VDOptionLexusBase>({
      brand: toGqlBrand(brand),
      team: toGqlTeam(team),
      seriesId: seriesId,
      modelYear: parseInt(year),
      language: Language.En,
      filter: toGqlFilter(version ?? Status.Draft),
      optionTab: optionsTab,
    });
    const data =
      optionsTab === 'packages'
        ? optionsLexusXForm(optionsResponse, vehicleModels)
        : optionsOptionLexusXForm(optionsResponse, vehicleModels);

    if (setDataInOptionsStore) {
      this.setOptions(data);
    } else {
      return data;
    }
    return undefined;
  };

  setOptions = (options: OptionsPackage[]) => {
    this.options = [...options];
    this.filteredOptions = [...options];
  };

  addItem = (
    vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[],
    isOption = false
  ) => {
    const newItem = new OptionsPackage();
    newItem.isOption = isOption;
    newItem.packageTotals.applicability = mapEmptyValueModels(vehicleModels);
    newItem.packageTotalReqs.applicability = mapEmptyValueModels(vehicleModels);
    this.options = [newItem, ...this.options];
    this.filteredOptions = [newItem, ...this.filteredOptions];
  };

  addSpecItem = (
    option: OptionsPackage,
    vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
  ) => {
    const newItem = new OptionsPackageSpec();
    newItem.models = mapEmptyModels(vehicleModels);
    option.specs.push(newItem);
  };

  addVarietyItem = (
    option: OptionsPackage,
    vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
  ) => {
    const newItem = new OptionsPackageVariety();
    newItem.applicability = mapEmptyValueModels(vehicleModels);
    option.packageVarieties.push(newItem);

    return newItem;
  };

  @action deleteItem = (option: OptionsPackage) => {
    this.options = this.options.filter(item => item.uid !== option.uid);
    this.filteredOptions = this.filteredOptions.filter(item => item.uid !== option.uid);
  };

  deleteSpecItem = (option: OptionsPackage, spec: OptionsPackageSpec) => {
    option.specs = option.specs.filter(item => item.uid !== spec.uid);
  };

  deleteVarietyItem = (option: OptionsPackage, variety: OptionsPackageVariety) => {
    option.packageVarieties = option.packageVarieties.filter(item => item.uid !== variety.uid);
  };

  onSort = (field: string) => {
    this.reverseSort = isSortReverse(this.sortField, field, this.reverseSort);
    this.sortField = field;
    this.options = this.options.sort(sortBy(this.sortField, this.reverseSort));
    this.filteredOptions = this.filterOptions();
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredOptions = this.filterOptions();
  };

  @action clearFilters = () => {
    this.searchText = '';
    this.isInProgressFilter = false;
  };

  filterOptions = () => {
    const lowerSearchText = toLowerCase(this.searchText);

    const sorted = this.options.slice();
    return sorted.filter(
      option =>
        (!lowerSearchText ||
          toLowerCase(convertToRichTextObject(option.description).text).includes(lowerSearchText) ||
          toLowerCase(convertToRichTextObject(option.name).text).includes(lowerSearchText) ||
          toLowerCase(option.code).includes(lowerSearchText) ||
          toLowerCase(option.notes).includes(lowerSearchText)) &&
        (!this.isInProgressFilter || option.isInProgress) &&
        (!this.isReviewNotesFilter || option.rejectNotes.length > 0)
    );
  };

  @action reset() {
    this.options = [];
    this.tab = 'packages';
    this.reverseSort = false;
    this.sortField = 'id';
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isReviewNotesFilter = false;
    this.showTooltips = false;
    this.filteredOptions = [];
    this.viewModelCodes = false;
  }
}

export default OptionsLexusStore;
